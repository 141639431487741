import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {ConstObservable, Observable} from "../../../tink/state/Observable.js"
import {PromisesContainer} from "../../../tink/core/ext/Promises.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Promise as Promise__1} from "../../../tink/core/Promise.js"
import {Outcome} from "../../../tink/core/Outcome.js"
import {LazyConst} from "../../../tink/core/Lazy.js"
import {Future, SyncFuture} from "../../../tink/core/Future.js"
import {Callback} from "../../../tink/core/Callback.js"
import {Register} from "../../../genes/Register.js"
import {WorkersData} from "./WorkersData.js"
import {Updatable} from "../../../coconut/ds/Updatable.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const WorkergroupData = Register.global("$hxClasses")["dasloop.app.data.WorkergroupData"] = 
class WorkergroupData extends Register.inherits() {
	new(__coco_init) {
		var _gthis = this;
		this.project = __coco_init.project;
		this.id = __coco_init.id;
		this.remote = __coco_init.remote;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new Updatable({"loader": function () {
			var api = _gthis.remote.v2().projects().ofId(_gthis.project).workergroups().ofId(_gthis.id);
			var __obj_data;
			var __obj_contacts;
			__obj_data = api.get();
			__obj_contacts = api.contacts().list();
			return Promise__1.next(Future.irreversible(function (cb) {
				var __ctx = new PromisesContainer(cb, 2);
				__obj_contacts.handle(__ctx.handle(function (r, v) {
					r.contacts = v;
				}));
				__obj_data.handle(__ctx.handle(function (r, v) {
					r.data = v;
				}));
			}), function (o) {
				var __o0 = o.data;
				return new SyncFuture(new LazyConst(Outcome.Success({"contacts": o.contacts, "project": __o0.project, "name": __o0.name, "isDefault": __o0.isDefault, "id": __o0.id})));
			});
		}, "updater": function (current, patch) {
			var api = _gthis.remote.v2().projects().ofId(_gthis.project).workergroups().ofId(_gthis.id);
			var __obj_info;
			var __obj_contacts;
			__obj_info = api.update(patch);
			__obj_contacts = api.contacts().set(patch.contacts);
			return Promise__1.next(Future.irreversible(function (cb) {
				var __ctx = new PromisesContainer(cb, 2);
				__obj_contacts.handle(__ctx.handle(function (r, v) {
					r.contacts = v;
				}));
				__obj_info.handle(__ctx.handle(function (r, v) {
					r.info = v;
				}));
			}), function (o) {
				var __o0 = o.info;
				return new SyncFuture(new LazyConst(Outcome.Success({"contacts": o.contacts, "project": __o0.project, "name": __o0.name, "isDefault": __o0.isDefault, "id": __o0.id})));
			});
		}});
		AutoObservable.cur = before;
		this.data = ret;
		this.__coco_revision = State._new(0, null, null, null);
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new WorkersData({"remote": _gthis.remote, "group": _gthis.id, "project": _gthis.project});
		AutoObservable.cur = before;
		this.workers = ret;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"project": new ConstObservable(this.project), "id": new ConstObservable(this.id), "remote": new ConstObservable(this.remote), "data": new ConstObservable(this.data), "workers": new ConstObservable(this.workers), "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	addWorkers(workerIds) {
		var api = this.remote.v2().projects().ofId(this.project).workergroups().ofId(this.id);
		var _g = [];
		var _g1 = 0;
		while (_g1 < workerIds.length) _g.push(api.addWorker(workerIds[_g1++]));
		var c = (o=>Register.bind(o, o.refresh))(this.workers);
		var c1 = function (o) {
			if (o._hx_index == 0) {
				Callback.invoke(c, o.data);
			};
		};
		return Future.map(Promise__1.inParallel(_g), function (v) {
			Callback.invoke(c1, v);
			return v;
		});
	}
	removeWorkers(workerIds) {
		var api = this.remote.v2().projects().ofId(this.project).workergroups().ofId(this.id);
		var _g = [];
		var _g1 = 0;
		while (_g1 < workerIds.length) _g.push(api.removeWorker(workerIds[_g1++]));
		var c = (o=>Register.bind(o, o.refresh))(this.workers);
		var c1 = function (o) {
			if (o._hx_index == 0) {
				Callback.invoke(c, o.data);
			};
		};
		return Future.map(Promise__1.inParallel(_g), function (v) {
			Callback.invoke(c1, v);
			return v;
		});
	}
	static get __name__() {
		return "dasloop.app.data.WorkergroupData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return WorkergroupData
	}
}

