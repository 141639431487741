import {Register} from "../Register.js"

const $global = Register.$global

export const EsMap = Register.global("$hxClasses")["genes.util.EsMap"] = 
class EsMap extends Register.inherits() {
	new() {
		this.inst = new Map();
	}
	set(key, value) {
		this.inst.set(key, value);
	}
	get(key) {
		return this.inst.get(key);
	}
	exists(key) {
		return this.inst.has(key);
	}
	keys() {
		return EsMap.adaptIterator(this.inst.keys());
	}
	iterator() {
		return EsMap.adaptIterator(this.inst.values());
	}
	static adaptIterator(from) {
		var value;
		var done;
		var queue = function () {
			var data = from.next();
			value = data.value;
			done = data.done;
		};
		return {"hasNext": function () {
			if (done == null) {
				queue();
			};
			return !done;
		}, "next": function () {
			if (done == null) {
				queue();
			};
			var pending = value;
			queue();
			return pending;
		}};
	}
	static get __name__() {
		return "genes.util.EsMap"
	}
	get __class__() {
		return EsMap
	}
}

