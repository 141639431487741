import {Register} from "./genes/Register.js"
import {Declarations} from "./cix/css/Declarations.js"

const $global = Register.$global

export const Cix3f5d01c6c050fce1cf970e4de6cdf0ed62519da84f8064ad76adc2ff68977d49 = Register.global("$hxClasses")["Cix3f5d01c6c050fce1cf970e4de6cdf0ed62519da84f8064ad76adc2ff68977d49"] = 
class Cix3f5d01c6c050fce1cf970e4de6cdf0ed62519da84f8064ad76adc2ff68977d49 extends Register.inherits() {
	new() {
		this.css = Declarations.add("cxsc", function () {
			return ".cxsc {\n  min-height: 100vh;\n}";
		});
	}
	static get __name__() {
		return "Cix3f5d01c6c050fce1cf970e4de6cdf0ed62519da84f8064ad76adc2ff68977d49"
	}
	get __class__() {
		return Cix3f5d01c6c050fce1cf970e4de6cdf0ed62519da84f8064ad76adc2ff68977d49
	}
}


Cix3f5d01c6c050fce1cf970e4de6cdf0ed62519da84f8064ad76adc2ff68977d49.inst = new Cix3f5d01c6c050fce1cf970e4de6cdf0ed62519da84f8064ad76adc2ff68977d49()