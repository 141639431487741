import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const LanguageContext = Register.global("$hxClasses")["dasloop.app.LanguageContext"] = 
class LanguageContext extends Register.inherits() {
	new(v) {
		this.value = v;
	}
	static get __name__() {
		return "dasloop.app.LanguageContext"
	}
	get __class__() {
		return LanguageContext
	}
}

