import {DecoderBase} from "./Decoder.js"
import {MPair} from "../core/Pair.js"
import {TypedError} from "../core/Error.js"
import {Option} from "../../haxe/ds/Option.js"
import {Exception} from "../../haxe/Exception.js"
import {Register} from "../../genes/Register.js"
import {TaskAction, UpdateFirmwareAction, UpdateAudioAction, GetConfigAction, GetHardwareReportAction, UpdateConfigAction, LocateDeviceAction, PlayAudioAction, GenerateAlarmAction, GetAudioAction} from "../../dasloop/types/task/TaskAction.js"

const $global = Register.$global

export const Decoder0 = Register.global("$hxClasses")["tink.serialize.Decoder0"] = 
class Decoder0 extends Register.inherits(DecoderBase) {
	new() {
		super.new();
	}
	process0() {
		return {"action": this.process1(), "attempt": this.dynInt(), "device": this.string(), "force": this.src.bytes[this.pos++] == 1, "fresh": this.src.bytes[this.pos++] == 1, "id": this.string(), "priority": this.dynInt(), "progress": new MPair(this["float"](), this.process12()), "revision": this.dynInt(), "updated": new Date(this["float"]()), "wait": this.dynInt()};
	}
	process1() {
		switch (this.dynInt()) {
			case 0:
				return Option.Some(this.process2());
				break
			case 1:
				return Option.None;
				break
			default:
			throw Exception.thrown("assert");
			
		};
	}
	process2() {
		switch (this.dynInt()) {
			case 0:
				return TaskAction.UpdateFirmware(this.process3());
				break
			case 1:
				return TaskAction.UpdateAudio(this.process4());
				break
			case 2:
				return TaskAction.GetConfig(this.process5());
				break
			case 3:
				return TaskAction.GetHardwareReport(this.process6());
				break
			case 4:
				return TaskAction.UpdateConfig(this.process7());
				break
			case 5:
				return TaskAction.LocateDevice(this.process8());
				break
			case 6:
				return TaskAction.PlayAudio(this.process9());
				break
			case 7:
				return TaskAction.GenerateAlarm(this.process10());
				break
			case 8:
				return TaskAction.GetAudio(this.process11());
				break
			default:
			throw Exception.thrown("assert");
			
		};
	}
	process3() {
		switch (this.dynInt()) {
			case 0:
				return UpdateFirmwareAction.GetDeviceInfo;
				break
			case 1:
				return UpdateFirmwareAction.SetEngineeringMode;
				break
			case 2:
				return UpdateFirmwareAction.SendFirmware(this.dynInt(), this.dynInt());
				break
			case 3:
				return UpdateFirmwareAction.GetConfig;
				break
			case 4:
				return UpdateFirmwareAction.PredefineConfig;
				break
			case 5:
				return UpdateFirmwareAction.RequestUpdateFirmware;
				break
			case 6:
				return UpdateFirmwareAction.CheckFirmwareVersion;
				break
			case 7:
				return UpdateFirmwareAction.Recover(this.process3());
				break
			case 8:
				return UpdateFirmwareAction.Pause(this.process3());
				break
			case 9:
				return UpdateFirmwareAction.WaitForRecover(this.process3());
				break
			default:
			throw Exception.thrown("assert");
			
		};
	}
	process4() {
		switch (this.dynInt()) {
			case 0:
				return UpdateAudioAction.GetDeviceInfo;
				break
			case 1:
				return UpdateAudioAction.SetEngineeringMode;
				break
			case 2:
				return UpdateAudioAction.SendAudio(this.dynInt(), this.dynInt());
				break
			case 3:
				return UpdateAudioAction.PatchChecksum;
				break
			case 4:
				return UpdateAudioAction.RequestReset;
				break
			case 5:
				return UpdateAudioAction.Recover(this.process4());
				break
			case 6:
				return UpdateAudioAction.Pause(this.process4());
				break
			case 7:
				return UpdateAudioAction.WaitForRecover(this.process4());
				break
			default:
			throw Exception.thrown("assert");
			
		};
	}
	process5() {
		switch (this.dynInt()) {
			case 0:
				return GetConfigAction.GetFirmwareVersion;
				break
			case 1:
				return GetConfigAction.GetConfigSize;
				break
			case 2:
				return GetConfigAction.GetConfig(this.dynInt(), this.dynInt());
				break
			default:
			throw Exception.thrown("assert");
			
		};
	}
	process6() {
		if (this.dynInt() == 0) {
			return GetHardwareReportAction.GetHardwareReport;
		} else {
			throw Exception.thrown("assert");
		};
	}
	process7() {
		switch (this.dynInt()) {
			case 0:
				return UpdateConfigAction.GetFirmwareVersion;
				break
			case 1:
				return UpdateConfigAction.UpdateConfig(this.dynInt());
				break
			case 2:
				return UpdateConfigAction.SetEngineeringMode;
				break
			case 3:
				return UpdateConfigAction.RequestReset;
				break
			case 4:
				return UpdateConfigAction.Recover(this.process7());
				break
			default:
			throw Exception.thrown("assert");
			
		};
	}
	process8() {
		switch (this.dynInt()) {
			case 0:
				return LocateDeviceAction.SetEngineeringMode;
				break
			case 1:
				return LocateDeviceAction.SetLed;
				break
			case 2:
				return LocateDeviceAction.SetVibrator;
				break
			case 3:
				return LocateDeviceAction.SetBuzzer;
				break
			case 4:
				return LocateDeviceAction.SetUwbAlarm(this.src.bytes[this.pos++] == 1, this.src.bytes[this.pos++] == 1);
				break
			case 5:
				return LocateDeviceAction.UnsetEngineeringMode;
				break
			case 6:
				return LocateDeviceAction.Recover(this.process8());
				break
			default:
			throw Exception.thrown("assert");
			
		};
	}
	process9() {
		if (this.dynInt() == 0) {
			return PlayAudioAction.PlayAudio;
		} else {
			throw Exception.thrown("assert");
		};
	}
	process10() {
		if (this.dynInt() == 0) {
			return GenerateAlarmAction.GenerateAlarm;
		} else {
			throw Exception.thrown("assert");
		};
	}
	process11() {
		switch (this.dynInt()) {
			case 0:
				return GetAudioAction.SetEngineeringMode;
				break
			case 1:
				return GetAudioAction.GetAudio(this.dynInt(), this.dynInt());
				break
			case 2:
				return GetAudioAction.UnsetEngineeringMode;
				break
			default:
			throw Exception.thrown("assert");
			
		};
	}
	process12() {
		switch (this.dynInt()) {
			case 0:
				return Option.Some(this["float"]());
				break
			case 1:
				return Option.None;
				break
			default:
			throw Exception.thrown("assert");
			
		};
	}
	decode(data) {
		this.reset(data);
		return this.process0();
	}
	tryDecode(data) {
		var _gthis = this;
		return TypedError.catchExceptions(function () {
			return _gthis.decode(data);
		}, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_serialize/0.0.0-alpha.1/github/6e78bfe3e63be4afe907389bdd9954f3af111d41/src/tink/serialize/Decoder.macro.hx", "lineNumber": 182, "className": "tink.serialize.Decoder0", "methodName": "tryDecode"});
	}
	static get __name__() {
		return "tink.serialize.Decoder0"
	}
	static get __super__() {
		return DecoderBase
	}
	get __class__() {
		return Decoder0
	}
}

