import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const LocationSource = 
Register.global("$hxEnums")["dasloop.types.LocationSource"] = 
{
	__ename__: "dasloop.types.LocationSource",
	
	Gps: Object.assign((fix, satellites, strength, speed) => ({_hx_index: 0, __enum__: "dasloop.types.LocationSource", "fix": fix, "satellites": satellites, "strength": strength, "speed": speed}), {_hx_name: "Gps", __params__: ["fix", "satellites", "strength", "speed"]}),
	Beacon: {_hx_name: "Beacon", _hx_index: 1, __enum__: "dasloop.types.LocationSource"},
	AoaBle: {_hx_name: "AoaBle", _hx_index: 2, __enum__: "dasloop.types.LocationSource"}
}
LocationSource.__constructs__ = [LocationSource.Gps, LocationSource.Beacon, LocationSource.AoaBle]
LocationSource.__empty_constructs__ = [LocationSource.Beacon, LocationSource.AoaBle]
