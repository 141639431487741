import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const MPair = Register.global("$hxClasses")["tink.core.MPair"] = 
class MPair extends Register.inherits() {
	new(a, b) {
		this.a = a;
		this.b = b;
	}
	static get __name__() {
		return "tink.core.MPair"
	}
	get __class__() {
		return MPair
	}
}

