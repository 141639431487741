import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {Observable, ConstObservable} from "../../../tink/state/Observable.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Promise as Promise__1} from "../../../tink/core/Promise.js"
import {Outcome} from "../../../tink/core/Outcome.js"
import {Lazy, LazyConst} from "../../../tink/core/Lazy.js"
import {SyncFuture} from "../../../tink/core/Future.js"
import {Option} from "../../../haxe/ds/Option.js"
import {Register} from "../../../genes/Register.js"
import {FloatTools} from "../../types/util/FloatTools.js"
import {BatteryValueTools} from "../../types/util/BatteryValueTools.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const DasiotsData = Register.global("$hxClasses")["dasloop.app.data.DasiotsData"] = 
class DasiotsData extends Register.inherits() {
	new(__coco_init) {
		this._updatePerformed = Signal.trigger();
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var f = function (__tink_tmp3) {
			var _g = Observable.get_value(_gthis.__coco_latest);
			if (_g._hx_index == 1) {
				return _g.result;
			} else if (__tink_tmp3._hx_index == 0) {
				return __tink_tmp3.v;
			} else {
				return Lazy.get(new LazyConst([]));
			};
		};
		var last = Option.None;
		var ret = new AutoObservable(function (_, _1) {
			var ret = f(last);
			last = Option.Some(ret);
			return ret;
		}, null);
		AutoObservable.cur = before;
		this.__coco_latestInfo = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.async(function () {
			State.get_value(_gthis.__coco_revision);
			return Promise__1.next(_gthis.remote.v2().projects().ofId(_gthis.project).dasiots().readings().listPerDevice(1, {"after": new Date(new Date().getTime() + (-31557600000.)), "before": new Date()}), function (readings) {
				var _g = [];
				var _g1 = 0;
				var _g2 = readings;
				while (_g1 < _g2.length) {
					var each = _g2[_g1];
					++_g1;
					_g.push({"device": each.device, "date": each.date, "values": {"battery": (each.type == "battery_voltage") ? FloatTools.roundTo(BatteryValueTools.getBatteryValue(each.value), 0) : null}});
				};
				return new SyncFuture(new LazyConst(Outcome.Success(_g)));
			});
		}), null);
		AutoObservable.cur = before;
		this.__coco_latest = ret;
		this.project = __coco_init.project;
		this.remote = __coco_init.remote;
		this.__coco_revision = State._new(0, null, null, null);
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"project": new ConstObservable(this.project), "remote": new ConstObservable(this.remote), "revision": this.__coco_revision, "latestInfo": this.__coco_latestInfo, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	set_revision(param) {
		this._updatePerformed.handlers.invoke({"revision": param});
		this.__coco_revision.set(param);
		return param;
	}
	static get __name__() {
		return "dasloop.app.data.DasiotsData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return DasiotsData
	}
}

