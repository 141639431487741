import {BasicParser, JsonString} from "./Parser.js"
import {MPair} from "../core/Pair.js"
import {TypedError} from "../core/Error.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Parser59 = Register.global("$hxClasses")["tink.json.Parser59"] = 
class Parser59 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	pb(source) {
		if (this.e.length > 0) {
			this.e = [];
		};
		this.g(source);
		this.F("[");
		var __ret = [];
		if (!this.E("]")) {
			while (true) {
				this.F("[");
				var _e0 = new Date(JsonString.f(this.r()));
				this.F(",");
				var this1 = new MPair(_e0, JsonString.f(this.r()));
				this.F("]");
				__ret.push(this1);
				if (!this.E(",")) {
					break;
				};
			};
			this.F("]");
		};
		var ret = __ret;
		var _g = 0;
		var _g1 = this.e;
		while (_g < _g1.length) _g1[_g++]();
		if (this.e.length > 0) {
			this.e = [];
		};
		return ret;
	}
	pc(source) {
		var _gthis = this;
		return TypedError.catchExceptions(function () {
			var ret = _gthis.pb(source);
			_gthis.h();
			if (_gthis.c < _gthis.d) {
				_gthis.D("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser59", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser59"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser59
	}
}

