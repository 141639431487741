import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {Observable, ConstObservable} from "../../../tink/state/Observable.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Promise as Promise__1} from "../../../tink/core/Promise.js"
import {Option} from "../../../haxe/ds/Option.js"
import {Register} from "../../../genes/Register.js"
import {BimTools} from "../util/BimTools.js"
import {Updatable} from "../../../coconut/ds/Updatable.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const BimData = Register.global("$hxClasses")["dasloop.app.data.BimData"] = 
class BimData extends Register.inherits() {
	new(__coco_init) {
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return Observable.get_value(_gthis._data.__coco_data);
		}), null);
		AutoObservable.cur = before;
		this.__coco_data = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.async(function () {
			return _gthis.remote.v2().bims().ofId(_gthis.id).places().list();
		}), null);
		AutoObservable.cur = before;
		this.__coco_places = ret;
		this.id = __coco_init.id;
		this.project = __coco_init.project;
		this.remote = __coco_init.remote;
		var _g = __coco_init.initial;
		this.initial = (_g == null) ? Option.None : _g;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new Updatable({"loader": function () {
			return _gthis.remote.v2().bims().ofId(_gthis.id).get();
		}, "updater": function (_, patch) {
			return _gthis.remote.v2().bims().ofId(_gthis.id).update(patch);
		}, "cache": _gthis.initial});
		AutoObservable.cur = before;
		this._data = ret;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"id": new ConstObservable(this.id), "project": new ConstObservable(this.project), "remote": new ConstObservable(this.remote), "initial": new ConstObservable(this.initial), "_data": new ConstObservable(this._data), "places": this.__coco_places, "data": this.__coco_data, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	update(patch, file) {
		var _gthis = this;
		return Promise__1.next((file == null) ? Promise__1.NOISE : BimTools.uploadModel(_gthis.remote, _gthis.project, _gthis.id, file), function (_) {
			return _gthis._data.update(patch);
		});
	}
	static get __name__() {
		return "dasloop.app.data.BimData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return BimData
	}
}

