import {Host} from "../../tink/url/Host.js"
import {JsClient} from "../../tink/http/clients/JsClient.js"
import {Register} from "../../genes/Register.js"
import {StringTools} from "../../StringTools.js"

const $global = Register.$global

export const Config = Register.global("$hxClasses")["dasloop.core.Config"] = 
class Config {
	static get __name__() {
		return "dasloop.core.Config"
	}
	get __class__() {
		return Config
	}
}


Config.API_SERVER_HOSTNAME = StringTools.replace(window.location.hostname, "dashboard", "api")
Config.IMAGE_HOSTNAME = StringTools.replace(window.location.hostname, "dashboard", "image")
Config.API_SERVER_PORT = 443
Config.API_SERVER_HOST = Host._new(Config.API_SERVER_HOSTNAME, Config.API_SERVER_PORT)
Config.API_SERVER_URL = "https" + "://" + Config.API_SERVER_HOSTNAME
Config.IMAGE_URL = "https" + "://" + Config.IMAGE_HOSTNAME
Config.HTTP_CLIENT = new JsClient()