import {BasicWriter} from "./Writer.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Writer29 = Register.global("$hxClasses")["tink.json.Writer29"] = 
class Writer29 extends Register.inherits(BasicWriter) {
	new() {
		super.new();
	}
	wb(value) {
		this.f("{");
		var value1 = value._199;
		this.e("\"altitude\":");
		this.h(value1);
		var value1 = value._200;
		this.f(",");
		this.e("\"level\":");
		this.g(value1);
		var value1 = value._201;
		this.f(",");
		this.e("\"name\":");
		this.j(value1);
		var value1 = value._202;
		this.f(",");
		this.e("\"rectangle\":");
		var value = [value1[0][0], value1[0][1], value1[0][2], value1[0][3]];
		this.f("[");
		var first = true;
		var _g = 0;
		while (_g < value.length) {
			var value1 = value[_g++];
			if (first) {
				first = false;
			} else {
				this.f(",");
			};
			var value2 = value1;
			this.f("[");
			var first1 = true;
			var _g1 = 0;
			while (_g1 < value2.length) {
				var value3 = value2[_g1++];
				if (first1) {
					first1 = false;
				} else {
					this.f(",");
				};
				this.h(value3);
			};
			this.f("]");
		};
		this.f("]");
		this.f("}");
	}
	wc(value) {
		this.d();
		this.wb(value);
		return this.b.toString();
	}
	static get __name__() {
		return "tink.json.Writer29"
	}
	static get __super__() {
		return BasicWriter
	}
	get __class__() {
		return Writer29
	}
}

