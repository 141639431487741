import {Observable} from "../../../tink/state/Observable.js"
import {Register} from "../../../genes/Register.js"
import {Slot} from "../../../coconut/ui/internal/Slot.js"
import {View} from "../../../coconut/react/View.js"
import {Html} from "../../../coconut/react/Html.js"
import {Std} from "../../../Std.js"
import {Cix8c188ea2938884a4dbd527cf4fd887a52a1c1220796c5610b8ae61eaa90c4619} from "../../../Cix8c188ea2938884a4dbd527cf4fd887a52a1c1220796c5610b8ae61eaa90c4619.js"

const $global = Register.$global

export const ErrorScreen = Register.global("$hxClasses")["dasloop.ui.general.ErrorScreen"] = 
class ErrorScreen extends Register.inherits(View) {
	new(__coco_data_) {
		var _gthis = this;
		this.__coco_title = new Slot(this, null, null);
		this.__coco_error = new Slot(this, null, null);
		this.__coco_href = new Slot(this, null, null);
		this.__initAttributes(__coco_data_);
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	__coco_render() {
		var __r = [];
		var _g = Observable.get_value(this.__coco_title);
		if (_g != null) {
			__r.push(Html.h("h1", {"className": "ui grey header", "style": {"textTransform": "uppercase"}}, [_g]));
		};
		var _g = Observable.get_value(this.__coco_error);
		if (_g != null) {
			var _g1 = _g.data;
			if (_g1 == null) {
				__r.push(this.header());
			} else {
				var __r1 = [];
				var children = [this.header()];
				__r1.push(Html.h("summary", {}, children));
				__r1.push(Html.h("pre", {"style": {"whiteSpace": "pre-wrap", "maxHeight": "60vh", "overflowY": "auto"}}, [Std.string(_g1)]));
				var children = [Html.h("details", {"className": ErrorScreen.DETAILS}, __r1)];
				__r.push(Html.h("div", {"style": {"width": "80vw", "maxWidth": "480px", "marginBottom": "1rem"}}, children));
			};
		};
		var _g = Observable.get_value(this.__coco_href);
		if (_g != null) {
			__r.push(Html.h("a", {"href": _g}, ["Back to Safety"]));
		};
		return Html.h("div", {"style": {"backgroundImage": "url(/image/placeholder.png)", "backgroundPosition": "center center", "backgroundRepeat": "no-repeat", "backgroundColor": "#ebeced", "width": "100%", "height": "100%", "display": "flex", "flexDirection": "column", "alignItems": "center", "justifyContent": "flex-end", "paddingBottom": "10%"}}, __r);
	}
	header() {
		var children = [Observable.get_value(this.__coco_error).message];
		return Html.h("h3", {"className": "ui grey header", "style": {"marginTop": "0"}}, children);
	}
	__initAttributes(attributes) {
		this.__coco_title.setData(attributes.title);
		this.__coco_error.setData(attributes.error);
		this.__coco_href.setData(attributes.href);
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": ErrorScreen, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "dasloop.ui.general.ErrorScreen"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return ErrorScreen
	}
}


ErrorScreen.DETAILS = Cix8c188ea2938884a4dbd527cf4fd887a52a1c1220796c5610b8ae61eaa90c4619.inst.css