import {TypedError} from "../core/Error.js"
import {StringMap} from "../../haxe/ds/StringMap.js"
import {Exception} from "../../haxe/Exception.js"
import {Register} from "../../genes/Register.js"
import {Std} from "../../Std.js"
import {HxOverrides} from "../../HxOverrides.js"

const $global = Register.$global

export const ParserBase = Register.global("$hxClasses")["tink.querystring.ParserBase"] = 
class ParserBase extends Register.inherits() {
	new(onError, pos) {
		this.pos = pos;
		this.onError = (onError == null) ? Register.bind(this, this.abort) : onError;
	}
	init(input, name, value) {
		this.root = new DefaultNormalizer().normalize(input, name, value);
	}
	abort(e) {
		throw Exception.thrown(this.error("" + e.reason + " for " + e.name));
	}
	error(reason, data) {
		return TypedError.withData(422, reason, data, this.pos);
	}
	static get __name__() {
		return "tink.querystring.ParserBase"
	}
	get __class__() {
		return ParserBase
	}
}


export const Field = 
Register.global("$hxEnums")["tink.querystring.Field"] = 
{
	__ename__: "tink.querystring.Field",
	
	Sub: Object.assign((sub) => ({_hx_index: 0, __enum__: "tink.querystring.Field", "sub": sub}), {_hx_name: "Sub", __params__: ["sub"]}),
	Value: Object.assign((v) => ({_hx_index: 1, __enum__: "tink.querystring.Field", "v": v}), {_hx_name: "Value", __params__: ["v"]})
}
Field.__constructs__ = [Field.Sub, Field.Value]
Field.__empty_constructs__ = []

export const FieldTools = Register.global("$hxClasses")["tink.querystring.FieldTools"] = 
class FieldTools {
	static getSub(field, substitute) {
		if (substitute == null) {
			substitute = true;
		};
		if (substitute && field == null) {
			return new StringMap();
		} else {
			switch (field._hx_index) {
				case 0:
					return field.sub;
					break
				case 1:
					throw Exception.thrown("unexpected primitive");
					break
				
			};
		};
	}
	static getValue(field) {
		switch (field._hx_index) {
			case 0:
				throw Exception.thrown("unexpected object/array");
				break
			case 1:
				return field.v;
				break
			
		};
	}
	static get __name__() {
		return "tink.querystring.FieldTools"
	}
	get __class__() {
		return FieldTools
	}
}


export const Normalizer = function() {};
Normalizer.__isInterface__ = true;

export const DefaultNormalizer = Register.global("$hxClasses")["tink.querystring.DefaultNormalizer"] = 
class DefaultNormalizer extends Register.inherits() {
	new() {
	}
	normalize(input, name, value) {
		var root = new StringMap();
		while (input.hasNext()) {
			var entry = input.next();
			this.iterate(root, name(entry), value(entry));
		};
		return root;
	}
	iterate(tree, key, value, depth) {
		if (depth == null) {
			depth = 0;
		};
		if (depth == 0) {
			if (key == "") {
				return;
			};
			var _g = key.indexOf("[");
			var _g1 = key.indexOf(".");
			if (_g == -1) {
				if (_g1 == -1) {
					var _g2 = tree.inst.get(key);
					if (_g2 == null) {
						tree.inst.set(key, Field.Value(value));
					} else if (_g2._hx_index == 1) {
						if (_g2.v != value) {
							throw Exception.thrown("conflict: " + key + " is already defined as " + Std.string(_g2));
						};
					} else {
						throw Exception.thrown("conflict: " + key + " is already defined as " + Std.string(_g2));
					};
				} else {
					var field = HxOverrides.substr(key, 0, _g1);
					var sub;
					var _g2 = tree.inst.get(field);
					if (_g2 == null) {
						var sub1 = new StringMap();
						tree.inst.set(field, Field.Sub(sub1));
						sub = sub1;
					} else if (_g2._hx_index == 0) {
						sub = _g2.sub;
					} else {
						throw Exception.thrown("conflict: " + field + " is already defined as " + Std.string(_g2));
					};
					this.iterate(sub, HxOverrides.substr(key, _g1, null), value, depth + 1);
				};
			} else if (_g1 == -1) {
				var field = HxOverrides.substr(key, 0, _g);
				var sub;
				var _g2 = tree.inst.get(field);
				if (_g2 == null) {
					var sub1 = new StringMap();
					tree.inst.set(field, Field.Sub(sub1));
					sub = sub1;
				} else if (_g2._hx_index == 0) {
					sub = _g2.sub;
				} else {
					throw Exception.thrown("conflict: " + field + " is already defined as " + Std.string(_g2));
				};
				this.iterate(sub, HxOverrides.substr(key, _g, null), value, depth + 1);
			} else {
				var index = (_g > _g1) ? _g1 : _g;
				var field = HxOverrides.substr(key, 0, index);
				var sub;
				var _g = tree.inst.get(field);
				if (_g == null) {
					var sub1 = new StringMap();
					tree.inst.set(field, Field.Sub(sub1));
					sub = sub1;
				} else if (_g._hx_index == 0) {
					sub = _g.sub;
				} else {
					throw Exception.thrown("conflict: " + field + " is already defined as " + Std.string(_g));
				};
				this.iterate(sub, HxOverrides.substr(key, index, null), value, depth + 1);
			};
		} else if (HxOverrides.cca(key, 0) == 91) {
			var _g = key.indexOf("][");
			var _g1 = key.indexOf("].");
			if (_g == -1) {
				if (_g1 == -1) {
					var field = key.substring(1, key.length - 1);
					var _g2 = tree.inst.get(field);
					if (_g2 == null) {
						tree.inst.set(field, Field.Value(value));
					} else {
						throw Exception.thrown("conflict: " + field + " is already defined as " + Std.string(_g2));
					};
				} else {
					var field = key.substring(1, _g1);
					var sub;
					var _g2 = tree.inst.get(field);
					if (_g2 == null) {
						var sub1 = new StringMap();
						tree.inst.set(field, Field.Sub(sub1));
						sub = sub1;
					} else if (_g2._hx_index == 0) {
						sub = _g2.sub;
					} else {
						throw Exception.thrown("conflict: " + field + " is already defined as " + Std.string(_g2));
					};
					this.iterate(sub, HxOverrides.substr(key, _g1 + 1, null), value, depth + 1);
				};
			} else if (_g1 == -1) {
				var field = key.substring(1, _g);
				var sub;
				var _g2 = tree.inst.get(field);
				if (_g2 == null) {
					var sub1 = new StringMap();
					tree.inst.set(field, Field.Sub(sub1));
					sub = sub1;
				} else if (_g2._hx_index == 0) {
					sub = _g2.sub;
				} else {
					throw Exception.thrown("conflict: " + field + " is already defined as " + Std.string(_g2));
				};
				this.iterate(sub, HxOverrides.substr(key, _g + 1, null), value, depth + 1);
			} else {
				var index = (_g > _g1) ? _g1 : _g;
				var field = key.substring(1, index);
				var sub;
				var _g = tree.inst.get(field);
				if (_g == null) {
					var sub1 = new StringMap();
					tree.inst.set(field, Field.Sub(sub1));
					sub = sub1;
				} else if (_g._hx_index == 0) {
					sub = _g.sub;
				} else {
					throw Exception.thrown("conflict: " + field + " is already defined as " + Std.string(_g));
				};
				this.iterate(sub, HxOverrides.substr(key, index + 1, null), value, depth + 1);
			};
		} else {
			var _g = key.indexOf("[");
			var _g1 = key.indexOf(".", 1);
			if (_g == -1) {
				if (_g1 == -1) {
					var field = HxOverrides.substr(key, 1, null);
					var _g2 = tree.inst.get(field);
					if (_g2 == null) {
						tree.inst.set(field, Field.Value(value));
					} else {
						throw Exception.thrown("conflict: " + field + " is already defined as " + Std.string(_g2));
					};
				} else {
					console.log("/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_querystring/0.7.0/github/7333282fa0ac69c2096f1feb4ef7b9514155b582/src/tink/querystring/Parser.hx:238:","here");
					var field = key.substring(1, _g1);
					var sub;
					var _g2 = tree.inst.get(field);
					if (_g2 == null) {
						var sub1 = new StringMap();
						tree.inst.set(field, Field.Sub(sub1));
						sub = sub1;
					} else if (_g2._hx_index == 0) {
						sub = _g2.sub;
					} else {
						throw Exception.thrown("conflict: " + field + " is already defined as " + Std.string(_g2));
					};
					this.iterate(sub, HxOverrides.substr(key, _g1, null), value, depth + 1);
				};
			} else if (_g1 == -1) {
				console.log("/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_querystring/0.7.0/github/7333282fa0ac69c2096f1feb4ef7b9514155b582/src/tink/querystring/Parser.hx:238:","here");
				var field = key.substring(1, _g);
				var sub;
				var _g2 = tree.inst.get(field);
				if (_g2 == null) {
					var sub1 = new StringMap();
					tree.inst.set(field, Field.Sub(sub1));
					sub = sub1;
				} else if (_g2._hx_index == 0) {
					sub = _g2.sub;
				} else {
					throw Exception.thrown("conflict: " + field + " is already defined as " + Std.string(_g2));
				};
				this.iterate(sub, HxOverrides.substr(key, _g, null), value, depth + 1);
			} else {
				var index = (_g > _g1) ? _g1 : _g;
				var field = key.substring(1, index);
				var sub;
				var _g = tree.inst.get(field);
				if (_g == null) {
					var sub1 = new StringMap();
					tree.inst.set(field, Field.Sub(sub1));
					sub = sub1;
				} else if (_g._hx_index == 0) {
					sub = _g.sub;
				} else {
					throw Exception.thrown("conflict: " + field + " is already defined as " + Std.string(_g));
				};
				this.iterate(sub, HxOverrides.substr(key, index, null), value, depth + 1);
			};
		};
	}
	static get __name__() {
		return "tink.querystring.DefaultNormalizer"
	}
	static get __interfaces__() {
		return [Normalizer]
	}
	get __class__() {
		return DefaultNormalizer
	}
}

