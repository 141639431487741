import {WebStringSource} from "../../turnwing/source/WebStringSource.js"
import {CachedSource} from "../../turnwing/source/CachedSource.js"
import {StringMap} from "../../haxe/ds/StringMap.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const FluentManagerBase = Register.global("$hxClasses")["dasloop.ui._FluentManager.FluentManagerBase"] = 
class FluentManagerBase {
	static get(this1, lang) {
		return this1.get(lang);
	}
	static getSource(getPath) {
		var getPath1 = function (lang) {
			return new URL("/locale/" + getPath(lang) + "?ver=" + 1.722467039e+12, window.location.href).toString();
		};
		return new CachedSource(getPath1, new WebStringSource(getPath1), FluentManagerBase.SOURCE_CACHE);
	}
	static get __name__() {
		return "dasloop.ui._FluentManager.FluentManagerBase_Impl_"
	}
	get __class__() {
		return FluentManagerBase
	}
}


FluentManagerBase.SOURCE_CACHE = new StringMap()