import {BasicWriter} from "./Writer.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Writer13 = Register.global("$hxClasses")["tink.json.Writer13"] = 
class Writer13 extends Register.inherits(BasicWriter) {
	new() {
		super.new();
	}
	wb(value) {
		this.f("{");
		var value1 = value._117;
		this.e("\"age\":");
		if (value1 == null) {
			this.e("null");
		} else {
			this.g(value1);
		};
		var value1 = value._118;
		this.f(",");
		this.e("\"company\":");
		this.j(value1);
		var value1 = value._119;
		this.f(",");
		this.e("\"dateOfBirth\":");
		if (value1 == null) {
			this.e("null");
		} else {
			this.j(value1);
		};
		var value1 = value._120;
		this.f(",");
		this.e("\"division\":");
		this.j(value1);
		var value1 = value._121;
		this.f(",");
		this.e("\"email\":");
		if (value1 == null) {
			this.e("null");
		} else {
			this.j(value1);
		};
		var value1 = value._122;
		this.f(",");
		this.e("\"gender\":");
		this.j(value1);
		var value1 = value._123;
		this.f(",");
		this.e("\"name\":");
		this.j(value1);
		var value1 = value._124;
		this.f(",");
		this.e("\"phone\":");
		if (value1 == null) {
			this.e("null");
		} else {
			this.j(value1);
		};
		var value1 = value._125;
		this.f(",");
		this.e("\"registrationNumber\":");
		this.j(value1);
		var value1 = value._126;
		this.f(",");
		this.e("\"remarks\":");
		this.j(value1);
		var value1 = value._127;
		this.f(",");
		this.e("\"trade\":");
		this.j(value1);
		this.f("}");
	}
	wc(value) {
		this.d();
		this.wb(value);
		return this.b.toString();
	}
	static get __name__() {
		return "tink.json.Writer13"
	}
	static get __super__() {
		return BasicWriter
	}
	get __class__() {
		return Writer13
	}
}

