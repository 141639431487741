import {Portion} from "../url/Portion.js"
import {DefaultKeymaker} from "./Keymaker.js"
import {NamedWith} from "../core/Named.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Builder30 = Register.global("$hxClasses")["tink.querystring.Builder30"] = 
class Builder30 extends Register.inherits() {
	new() {
	}
	stringify(data, keymaker) {
		if (keymaker == null) {
			keymaker = new DefaultKeymaker();
		};
		var buffer = [];
		this.process0("", buffer, keymaker, data);
		return buffer;
	}
	process0(prefix, buffer, keymaker, data) {
		var prefix1 = (prefix == "") ? "mime" : keymaker.field(prefix, "mime");
		var data1 = data._198;
		buffer.push(new NamedWith(Portion.ofString(prefix1), Portion.ofString(data1)));
	}
	static get __name__() {
		return "tink.querystring.Builder30"
	}
	get __class__() {
		return Builder30
	}
}

