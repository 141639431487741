import {BasicParser, JsonString} from "./Parser.js"
import {OutcomeTools} from "../core/Outcome.js"
import {TypedError} from "../core/Error.js"
import {Register} from "../../genes/Register.js"
import {DeviceId} from "../../dasloop/types/DeviceId.js"

const $global = Register.$global

export const Parser13 = Register.global("$hxClasses")["tink.json.Parser13"] = 
class Parser13 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	pb() {
		var _gthis = this;
		var v_azimuth = .0;
		var hasv_azimuth = false;
		var v_environmentFactor = .0;
		var hasv_environmentFactor = false;
		var v_hash = null;
		var hasv_hash = false;
		var v_id = null;
		var hasv_id = false;
		var v_location = null;
		var hasv_location = false;
		var v_loraDevEui = null;
		var hasv_loraDevEui = false;
		var v_name = null;
		var hasv_name = false;
		var v_project = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "azimuth":
						v_azimuth = JsonString.f(this.r());
						hasv_azimuth = true;
						break
					case "environmentFactor":
						v_environmentFactor = JsonString.f(this.r());
						hasv_environmentFactor = true;
						break
					case "hash":
						v_hash = JsonString.s(this.m());
						hasv_hash = true;
						break
					case "id":
						v_id = OutcomeTools.sure(DeviceId.parse(JsonString.s(this.m())));
						hasv_id = true;
						break
					case "location":
						this.F("[");
						var __ret = [];
						if (!this.E("]")) {
							while (true) {
								__ret.push(JsonString.f(this.r()));
								if (!this.E(",")) {
									break;
								};
							};
							this.F("]");
						};
						v_location = __ret;
						hasv_location = true;
						break
					case "loraDevEui":
						v_loraDevEui = JsonString.s(this.m());
						hasv_loraDevEui = true;
						break
					case "name":
						v_name = JsonString.s(this.m());
						hasv_name = true;
						break
					case "project":
						v_project = (this.E("null")) ? null : JsonString.s(this.m());
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"azimuth": (hasv_azimuth) ? v_azimuth : __missing__("azimuth"), "environmentFactor": (hasv_environmentFactor) ? v_environmentFactor : __missing__("environmentFactor"), "hash": (hasv_hash) ? v_hash : __missing__("hash"), "id": (hasv_id) ? v_id : __missing__("id"), "location": (hasv_location) ? v_location : __missing__("location"), "loraDevEui": (hasv_loraDevEui) ? v_loraDevEui : __missing__("loraDevEui"), "name": (hasv_name) ? v_name : __missing__("name"), "project": v_project};
	}
	pc(source) {
		if (this.e.length > 0) {
			this.e = [];
		};
		this.g(source);
		var ret = this.pb();
		var _g = 0;
		var _g1 = this.e;
		while (_g < _g1.length) _g1[_g++]();
		if (this.e.length > 0) {
			this.e = [];
		};
		return ret;
	}
	pd(source) {
		var _gthis = this;
		return TypedError.catchExceptions(function () {
			var ret = _gthis.pc(source);
			_gthis.h();
			if (_gthis.c < _gthis.d) {
				_gthis.D("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser13", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser13"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser13
	}
}

