import {Outcome} from "../core/Outcome.js"
import {NamedWith} from "../core/Named.js"
import {TypedError} from "../core/Error.js"
import {Bytes} from "../../haxe/io/Bytes.js"
import {Base64} from "../../haxe/crypto/Base64.js"
import {Register} from "../../genes/Register.js"
import {StringTools} from "../../StringTools.js"
import {HxOverrides} from "../../HxOverrides.js"

const $global = Register.$global

export const Header = Register.global("$hxClasses")["tink.http.Header"] = 
class Header extends Register.inherits() {
	new(fields) {
		this.fields = (fields == null) ? [] : fields;
	}
	
	/**
	*  Get all headers of the given name
	*  @param name - Header name to retrieve
	*  @return Array of headers of the given name
	*/
	get(name) {
		var _g = [];
		var _g1 = 0;
		var _g2 = this.fields;
		while (_g1 < _g2.length) {
			var f = _g2[_g1];
			++_g1;
			if (f.name == name) {
				_g.push(f.value);
			};
		};
		return _g;
	}
	
	/**
	*  Get a header
	*  @param name - Header name to retrieve
	*  @return `Success(header)` if there is exactly one entry of the given header name, `Failure(err)` otherwise
	*/
	byName(name) {
		var _g = this.get(name);
		switch (_g.length) {
			case 0:
				return Outcome.Failure(new TypedError(422, "No " + name + " header found", {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_http/0.10.0/github/49088ee6bb9d69b1e0f57931a13e836132e3fc02/src/tink/http/Header.hx", "lineNumber": 91, "className": "tink.http.Header", "methodName": "byName"}));
				break
			case 1:
				return Outcome.Success(_g[0]);
				break
			default:
			return Outcome.Failure(new TypedError(422, "Multiple entries for " + name + " header", {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_http/0.10.0/github/49088ee6bb9d69b1e0f57931a13e836132e3fc02/src/tink/http/Header.hx", "lineNumber": 95, "className": "tink.http.Header", "methodName": "byName"}));
			
		};
	}
	static get __name__() {
		return "tink.http.Header"
	}
	get __class__() {
		return Header
	}
}


export const HeaderValue = Register.global("$hxClasses")["tink.http._Header.HeaderValue"] = 
class HeaderValue {
	static basicAuth(username, password) {
		return "Basic " + Base64.encode(Bytes.ofString("" + username + ":" + password)).toString();
	}
	static ofInt(i) {
		if (i == null) {
			return "null";
		} else {
			return "" + i;
		};
	}
	static get __name__() {
		return "tink.http._Header.HeaderValue_Impl_"
	}
	get __class__() {
		return HeaderValue
	}
}


export const HeaderField = Register.global("$hxClasses")["tink.http.HeaderField"] = 
class HeaderField extends Register.inherits(NamedWith) {
	new(name, value) {
		super.new(name, value);
	}
	static ofString(s) {
		var _g = s.indexOf(":");
		if (_g == -1) {
			return new HeaderField(s.toLowerCase(), null);
		} else {
			return new HeaderField(HxOverrides.substr(s, 0, _g).toLowerCase(), StringTools.trim(HxOverrides.substr(s, _g + 1, null)));
		};
	}
	static get __name__() {
		return "tink.http.HeaderField"
	}
	static get __super__() {
		return NamedWith
	}
	get __class__() {
		return HeaderField
	}
}

