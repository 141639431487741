import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const TaskAction = 
Register.global("$hxEnums")["dasloop.types.task.TaskAction"] = 
{
	__ename__: "dasloop.types.task.TaskAction",
	
	UpdateFirmware: Object.assign((action) => ({_hx_index: 0, __enum__: "dasloop.types.task.TaskAction", "action": action}), {_hx_name: "UpdateFirmware", __params__: ["action"]}),
	UpdateAudio: Object.assign((action) => ({_hx_index: 1, __enum__: "dasloop.types.task.TaskAction", "action": action}), {_hx_name: "UpdateAudio", __params__: ["action"]}),
	GetConfig: Object.assign((action) => ({_hx_index: 2, __enum__: "dasloop.types.task.TaskAction", "action": action}), {_hx_name: "GetConfig", __params__: ["action"]}),
	GetHardwareReport: Object.assign((action) => ({_hx_index: 3, __enum__: "dasloop.types.task.TaskAction", "action": action}), {_hx_name: "GetHardwareReport", __params__: ["action"]}),
	UpdateConfig: Object.assign((action) => ({_hx_index: 4, __enum__: "dasloop.types.task.TaskAction", "action": action}), {_hx_name: "UpdateConfig", __params__: ["action"]}),
	LocateDevice: Object.assign((action) => ({_hx_index: 5, __enum__: "dasloop.types.task.TaskAction", "action": action}), {_hx_name: "LocateDevice", __params__: ["action"]}),
	PlayAudio: Object.assign((action) => ({_hx_index: 6, __enum__: "dasloop.types.task.TaskAction", "action": action}), {_hx_name: "PlayAudio", __params__: ["action"]}),
	GenerateAlarm: Object.assign((action) => ({_hx_index: 7, __enum__: "dasloop.types.task.TaskAction", "action": action}), {_hx_name: "GenerateAlarm", __params__: ["action"]}),
	GetAudio: Object.assign((action) => ({_hx_index: 8, __enum__: "dasloop.types.task.TaskAction", "action": action}), {_hx_name: "GetAudio", __params__: ["action"]})
}
TaskAction.__constructs__ = [TaskAction.UpdateFirmware, TaskAction.UpdateAudio, TaskAction.GetConfig, TaskAction.GetHardwareReport, TaskAction.UpdateConfig, TaskAction.LocateDevice, TaskAction.PlayAudio, TaskAction.GenerateAlarm, TaskAction.GetAudio]
TaskAction.__empty_constructs__ = []

export const UpdateFirmwareAction = 
Register.global("$hxEnums")["dasloop.types.task.UpdateFirmwareAction"] = 
{
	__ename__: "dasloop.types.task.UpdateFirmwareAction",
	
	GetDeviceInfo: {_hx_name: "GetDeviceInfo", _hx_index: 0, __enum__: "dasloop.types.task.UpdateFirmwareAction"},
	SetEngineeringMode: {_hx_name: "SetEngineeringMode", _hx_index: 1, __enum__: "dasloop.types.task.UpdateFirmwareAction"},
	SendFirmware: Object.assign((offset, length) => ({_hx_index: 2, __enum__: "dasloop.types.task.UpdateFirmwareAction", "offset": offset, "length": length}), {_hx_name: "SendFirmware", __params__: ["offset", "length"]}),
	GetConfig: {_hx_name: "GetConfig", _hx_index: 3, __enum__: "dasloop.types.task.UpdateFirmwareAction"},
	PredefineConfig: {_hx_name: "PredefineConfig", _hx_index: 4, __enum__: "dasloop.types.task.UpdateFirmwareAction"},
	RequestUpdateFirmware: {_hx_name: "RequestUpdateFirmware", _hx_index: 5, __enum__: "dasloop.types.task.UpdateFirmwareAction"},
	CheckFirmwareVersion: {_hx_name: "CheckFirmwareVersion", _hx_index: 6, __enum__: "dasloop.types.task.UpdateFirmwareAction"},
	Recover: Object.assign((next) => ({_hx_index: 7, __enum__: "dasloop.types.task.UpdateFirmwareAction", "next": next}), {_hx_name: "Recover", __params__: ["next"]}),
	Pause: Object.assign((next) => ({_hx_index: 8, __enum__: "dasloop.types.task.UpdateFirmwareAction", "next": next}), {_hx_name: "Pause", __params__: ["next"]}),
	WaitForRecover: Object.assign((next) => ({_hx_index: 9, __enum__: "dasloop.types.task.UpdateFirmwareAction", "next": next}), {_hx_name: "WaitForRecover", __params__: ["next"]})
}
UpdateFirmwareAction.__constructs__ = [UpdateFirmwareAction.GetDeviceInfo, UpdateFirmwareAction.SetEngineeringMode, UpdateFirmwareAction.SendFirmware, UpdateFirmwareAction.GetConfig, UpdateFirmwareAction.PredefineConfig, UpdateFirmwareAction.RequestUpdateFirmware, UpdateFirmwareAction.CheckFirmwareVersion, UpdateFirmwareAction.Recover, UpdateFirmwareAction.Pause, UpdateFirmwareAction.WaitForRecover]
UpdateFirmwareAction.__empty_constructs__ = [UpdateFirmwareAction.GetDeviceInfo, UpdateFirmwareAction.SetEngineeringMode, UpdateFirmwareAction.GetConfig, UpdateFirmwareAction.PredefineConfig, UpdateFirmwareAction.RequestUpdateFirmware, UpdateFirmwareAction.CheckFirmwareVersion]

export const UpdateConfigAction = 
Register.global("$hxEnums")["dasloop.types.task.UpdateConfigAction"] = 
{
	__ename__: "dasloop.types.task.UpdateConfigAction",
	
	GetFirmwareVersion: {_hx_name: "GetFirmwareVersion", _hx_index: 0, __enum__: "dasloop.types.task.UpdateConfigAction"},
	UpdateConfig: Object.assign((index) => ({_hx_index: 1, __enum__: "dasloop.types.task.UpdateConfigAction", "index": index}), {_hx_name: "UpdateConfig", __params__: ["index"]}),
	SetEngineeringMode: {_hx_name: "SetEngineeringMode", _hx_index: 2, __enum__: "dasloop.types.task.UpdateConfigAction"},
	RequestReset: {_hx_name: "RequestReset", _hx_index: 3, __enum__: "dasloop.types.task.UpdateConfigAction"},
	Recover: Object.assign((next) => ({_hx_index: 4, __enum__: "dasloop.types.task.UpdateConfigAction", "next": next}), {_hx_name: "Recover", __params__: ["next"]})
}
UpdateConfigAction.__constructs__ = [UpdateConfigAction.GetFirmwareVersion, UpdateConfigAction.UpdateConfig, UpdateConfigAction.SetEngineeringMode, UpdateConfigAction.RequestReset, UpdateConfigAction.Recover]
UpdateConfigAction.__empty_constructs__ = [UpdateConfigAction.GetFirmwareVersion, UpdateConfigAction.SetEngineeringMode, UpdateConfigAction.RequestReset]

export const UpdateAudioAction = 
Register.global("$hxEnums")["dasloop.types.task.UpdateAudioAction"] = 
{
	__ename__: "dasloop.types.task.UpdateAudioAction",
	
	GetDeviceInfo: {_hx_name: "GetDeviceInfo", _hx_index: 0, __enum__: "dasloop.types.task.UpdateAudioAction"},
	SetEngineeringMode: {_hx_name: "SetEngineeringMode", _hx_index: 1, __enum__: "dasloop.types.task.UpdateAudioAction"},
	SendAudio: Object.assign((offset, length) => ({_hx_index: 2, __enum__: "dasloop.types.task.UpdateAudioAction", "offset": offset, "length": length}), {_hx_name: "SendAudio", __params__: ["offset", "length"]}),
	PatchChecksum: {_hx_name: "PatchChecksum", _hx_index: 3, __enum__: "dasloop.types.task.UpdateAudioAction"},
	RequestReset: {_hx_name: "RequestReset", _hx_index: 4, __enum__: "dasloop.types.task.UpdateAudioAction"},
	Recover: Object.assign((next) => ({_hx_index: 5, __enum__: "dasloop.types.task.UpdateAudioAction", "next": next}), {_hx_name: "Recover", __params__: ["next"]}),
	Pause: Object.assign((next) => ({_hx_index: 6, __enum__: "dasloop.types.task.UpdateAudioAction", "next": next}), {_hx_name: "Pause", __params__: ["next"]}),
	WaitForRecover: Object.assign((next) => ({_hx_index: 7, __enum__: "dasloop.types.task.UpdateAudioAction", "next": next}), {_hx_name: "WaitForRecover", __params__: ["next"]})
}
UpdateAudioAction.__constructs__ = [UpdateAudioAction.GetDeviceInfo, UpdateAudioAction.SetEngineeringMode, UpdateAudioAction.SendAudio, UpdateAudioAction.PatchChecksum, UpdateAudioAction.RequestReset, UpdateAudioAction.Recover, UpdateAudioAction.Pause, UpdateAudioAction.WaitForRecover]
UpdateAudioAction.__empty_constructs__ = [UpdateAudioAction.GetDeviceInfo, UpdateAudioAction.SetEngineeringMode, UpdateAudioAction.PatchChecksum, UpdateAudioAction.RequestReset]

export const LocateDeviceAction = 
Register.global("$hxEnums")["dasloop.types.task.LocateDeviceAction"] = 
{
	__ename__: "dasloop.types.task.LocateDeviceAction",
	
	SetEngineeringMode: {_hx_name: "SetEngineeringMode", _hx_index: 0, __enum__: "dasloop.types.task.LocateDeviceAction"},
	SetLed: {_hx_name: "SetLed", _hx_index: 1, __enum__: "dasloop.types.task.LocateDeviceAction"},
	SetVibrator: {_hx_name: "SetVibrator", _hx_index: 2, __enum__: "dasloop.types.task.LocateDeviceAction"},
	SetBuzzer: {_hx_name: "SetBuzzer", _hx_index: 3, __enum__: "dasloop.types.task.LocateDeviceAction"},
	SetUwbAlarm: Object.assign((light, sound) => ({_hx_index: 4, __enum__: "dasloop.types.task.LocateDeviceAction", "light": light, "sound": sound}), {_hx_name: "SetUwbAlarm", __params__: ["light", "sound"]}),
	UnsetEngineeringMode: {_hx_name: "UnsetEngineeringMode", _hx_index: 5, __enum__: "dasloop.types.task.LocateDeviceAction"},
	Recover: Object.assign((next) => ({_hx_index: 6, __enum__: "dasloop.types.task.LocateDeviceAction", "next": next}), {_hx_name: "Recover", __params__: ["next"]})
}
LocateDeviceAction.__constructs__ = [LocateDeviceAction.SetEngineeringMode, LocateDeviceAction.SetLed, LocateDeviceAction.SetVibrator, LocateDeviceAction.SetBuzzer, LocateDeviceAction.SetUwbAlarm, LocateDeviceAction.UnsetEngineeringMode, LocateDeviceAction.Recover]
LocateDeviceAction.__empty_constructs__ = [LocateDeviceAction.SetEngineeringMode, LocateDeviceAction.SetLed, LocateDeviceAction.SetVibrator, LocateDeviceAction.SetBuzzer, LocateDeviceAction.UnsetEngineeringMode]

export const PlayAudioAction = 
Register.global("$hxEnums")["dasloop.types.task.PlayAudioAction"] = 
{
	__ename__: "dasloop.types.task.PlayAudioAction",
	
	PlayAudio: {_hx_name: "PlayAudio", _hx_index: 0, __enum__: "dasloop.types.task.PlayAudioAction"}
}
PlayAudioAction.__constructs__ = [PlayAudioAction.PlayAudio]
PlayAudioAction.__empty_constructs__ = [PlayAudioAction.PlayAudio]

export const GenerateAlarmAction = 
Register.global("$hxEnums")["dasloop.types.task.GenerateAlarmAction"] = 
{
	__ename__: "dasloop.types.task.GenerateAlarmAction",
	
	GenerateAlarm: {_hx_name: "GenerateAlarm", _hx_index: 0, __enum__: "dasloop.types.task.GenerateAlarmAction"}
}
GenerateAlarmAction.__constructs__ = [GenerateAlarmAction.GenerateAlarm]
GenerateAlarmAction.__empty_constructs__ = [GenerateAlarmAction.GenerateAlarm]

export const GetConfigAction = 
Register.global("$hxEnums")["dasloop.types.task.GetConfigAction"] = 
{
	__ename__: "dasloop.types.task.GetConfigAction",
	
	GetFirmwareVersion: {_hx_name: "GetFirmwareVersion", _hx_index: 0, __enum__: "dasloop.types.task.GetConfigAction"},
	GetConfigSize: {_hx_name: "GetConfigSize", _hx_index: 1, __enum__: "dasloop.types.task.GetConfigAction"},
	GetConfig: Object.assign((offset, length) => ({_hx_index: 2, __enum__: "dasloop.types.task.GetConfigAction", "offset": offset, "length": length}), {_hx_name: "GetConfig", __params__: ["offset", "length"]})
}
GetConfigAction.__constructs__ = [GetConfigAction.GetFirmwareVersion, GetConfigAction.GetConfigSize, GetConfigAction.GetConfig]
GetConfigAction.__empty_constructs__ = [GetConfigAction.GetFirmwareVersion, GetConfigAction.GetConfigSize]

export const GetHardwareReportAction = 
Register.global("$hxEnums")["dasloop.types.task.GetHardwareReportAction"] = 
{
	__ename__: "dasloop.types.task.GetHardwareReportAction",
	
	GetHardwareReport: {_hx_name: "GetHardwareReport", _hx_index: 0, __enum__: "dasloop.types.task.GetHardwareReportAction"}
}
GetHardwareReportAction.__constructs__ = [GetHardwareReportAction.GetHardwareReport]
GetHardwareReportAction.__empty_constructs__ = [GetHardwareReportAction.GetHardwareReport]

export const GetAudioAction = 
Register.global("$hxEnums")["dasloop.types.task.GetAudioAction"] = 
{
	__ename__: "dasloop.types.task.GetAudioAction",
	
	SetEngineeringMode: {_hx_name: "SetEngineeringMode", _hx_index: 0, __enum__: "dasloop.types.task.GetAudioAction"},
	GetAudio: Object.assign((offset, length) => ({_hx_index: 1, __enum__: "dasloop.types.task.GetAudioAction", "offset": offset, "length": length}), {_hx_name: "GetAudio", __params__: ["offset", "length"]}),
	UnsetEngineeringMode: {_hx_name: "UnsetEngineeringMode", _hx_index: 2, __enum__: "dasloop.types.task.GetAudioAction"}
}
GetAudioAction.__constructs__ = [GetAudioAction.SetEngineeringMode, GetAudioAction.GetAudio, GetAudioAction.UnsetEngineeringMode]
GetAudioAction.__empty_constructs__ = [GetAudioAction.SetEngineeringMode, GetAudioAction.UnsetEngineeringMode]
