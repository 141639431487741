import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {PromisedTools} from "../../../tink/state/Promised.js"
import {Observable, ConstObservable} from "../../../tink/state/Observable.js"
import {Vector} from "../../../tink/pure/Vector.js"
import {BlobSource} from "../../../tink/io/js/BlobSource.js"
import {RealSourceTools, Source} from "../../../tink/io/Source.js"
import {FetchResponse, Fetch} from "../../../tink/http/Fetch.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Promise as Promise__1} from "../../../tink/core/Promise.js"
import {Outcome} from "../../../tink/core/Outcome.js"
import {LazyConst} from "../../../tink/core/Lazy.js"
import {SyncFuture} from "../../../tink/core/Future.js"
import {Path} from "../../../haxe/io/Path.js"
import {Option} from "../../../haxe/ds/Option.js"
import {Register} from "../../../genes/Register.js"
import {WorkerData} from "./WorkerData.js"
import {StringCollection} from "../../../coconut/ds/Collection.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const WorkersData = Register.global("$hxClasses")["dasloop.app.data.WorkersData"] = 
class WorkersData extends Register.inherits() {
	new(__coco_init) {
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return PromisedTools.map(PromisedTools.flatMap(Observable.get_value(_gthis.collection.__coco_list), function (list) {
				var result = new Array(list.length);
				var _g = 0;
				var _g1 = list.length;
				while (_g < _g1) {
					var i = _g++;
					result[i] = Observable.get_value(list[i].__coco_data);
				};
				return PromisedTools.all(result);
			}), Vector.fromArray);
		}), null);
		AutoObservable.cur = before;
		this.__coco_data = ret;
		this.project = __coco_init.project;
		var _g = __coco_init.group;
		this.group = (_g == null) ? null : _g;
		this.remote = __coco_init.remote;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new StringCollection({"fetch": function () {
			var api = _gthis.remote.v2().projects().ofId(_gthis.project);
			if (_gthis.group == null) {
				return api.workers().list();
			} else {
				return api.workergroups().ofId(_gthis.group).workers();
			};
		}, "extractKey": function (raw) {
			return raw.id;
		}, "createItem": function (id, init) {
			return new WorkerData({"id": id, "remote": _gthis.remote, "initial": init, "project": _gthis.project});
		}, "updateItem": function (data, raw) {
			data._data.refresh(Option.Some(raw));
		}});
		AutoObservable.cur = before;
		this.collection = ret;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"project": new ConstObservable(this.project), "group": new ConstObservable(this.group), "remote": new ConstObservable(this.remote), "collection": new ConstObservable(this.collection), "data": this.__coco_data, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	refresh() {
		this.collection.refresh();
	}
	
	/**
	1. create a new worker to server
	2. update this WorkersData's collection
	@param data init info for new worker
	@return Promise<WorkerData.WorkerInfo>
	*/
	create(data) {
		var _gthis = this;
		return Promise__1.next(Promise__1.next(this.remote.v2().projects().ofId(this.project).workers().create(data), function (worker) {
			if (data.avatar == null) {
				return new SyncFuture(new LazyConst(Outcome.Success(worker)));
			} else {
				var _g = Path.extension(data.avatar.name).toLowerCase();
				var v = worker;
				return Promise__1.next(Promise__1.next(_gthis.remote.v2().workers().ofId(worker.id).uploadImage("image/" + ((_g == "jpg") ? "jpeg" : _g)), function (header) {
					var url = header.url;
					var header1 = header.method;
					var _g = [];
					var _this = header.fields;
					var _g2_current = 0;
					while (_g2_current < _this.length) _g.push(_this[_g2_current++]);
					return FetchResponse.all(Fetch.fetch(url, {"method": header1, "headers": _g, "body": RealSourceTools.idealize(new BlobSource("Worker Avatar " + data.avatar.name, data.avatar, 0, 16777216), function (_) {
						return Source.EMPTY;
					})}));
				}), function (_) {
					return new SyncFuture(new LazyConst(Outcome.Success(v)));
				});
			};
		}), function (worker) {
			_gthis.collection.refresh();
			return new SyncFuture(new LazyConst(Outcome.Success(worker)));
		});
	}
	static get __name__() {
		return "dasloop.app.data.WorkersData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return WorkersData
	}
}

