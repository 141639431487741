import {Register} from "../../genes/Register.js"
import {HxOverrides} from "../../HxOverrides.js"

const $global = Register.$global

export const Topic = Register.global("$hxClasses")["why.mqtt._Topic.Topic"] = 
class Topic {
	static sanitize(v) {
		var start = 0;
		var end = v.length - 1;
		while (HxOverrides.cca(v, start) == 47) ++start;
		while (HxOverrides.cca(v, end) == 47) --end;
		return v.substring(start, end + 1);
	}
	static match(this1, v) {
		var filter = this1.split("/");
		var target = v.split("/");
		var _g = 0;
		var _g1 = filter.length;
		while (_g < _g1) {
			var i = _g++;
			var _g2 = filter[i];
			switch (_g2) {
				case "#":
					return target.length > i;
					break
				case "+":
					break
				default:
				if (_g2 != target[i]) {
					return false;
				};
				
			};
		};
		return filter.length == target.length;
	}
	static get __name__() {
		return "why.mqtt._Topic.Topic_Impl_"
	}
	get __class__() {
		return Topic
	}
}

