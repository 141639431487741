import {Register} from "../genes/Register.js"

const $global = Register.$global

export const ReactType = Register.global("$hxClasses")["react._ReactType.ReactType"] = 
class ReactType {
	static fromString(s) {
		return s;
	}
	static fromComp(cls) {
		if (cls.__jsxStatic != null) {
			return cls.__jsxStatic;
		};
		return cls;
	}
	static get __name__() {
		return "react._ReactType.ReactType_Impl_"
	}
	get __class__() {
		return ReactType
	}
}

