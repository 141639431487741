import {Outcome} from "../Outcome.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const PromisesContainer = Register.global("$hxClasses")["tink.core.ext.PromisesContainer"] = 
class PromisesContainer extends Register.inherits() {
	new(cb, count) {
		this.result = {};
		this.cb = cb;
		this.count = count;
	}
	handle(assign) {
		var _gthis = this;
		return function (o) {
			switch (o._hx_index) {
				case 0:
					assign(_gthis.result, o.data);
					if (--_gthis.count == 0) {
						_gthis.cb(Outcome.Success(_gthis.result));
					};
					break
				case 1:
					_gthis.cb(Outcome.Failure(o.failure));
					break
				
			};
		};
	}
	static get __name__() {
		return "tink.core.ext.PromisesContainer"
	}
	get __class__() {
		return PromisesContainer
	}
}

