import {Remote31} from "./Remote31.js"
import {RemoteBase, RemoteEndpoint, ResponseReader} from "./Remote.js"
import {Portion} from "../../url/Portion.js"
import {Single} from "../../streams/Stream.js"
import {Writer44} from "../../json/Writer44.js"
import {Parser23} from "../../json/Parser23.js"
import {Parser22} from "../../json/Parser22.js"
import {HeaderField, HeaderValue} from "../../http/Header.js"
import {LazyConst} from "../../core/Lazy.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote30 = Register.global("$hxClasses")["tink.web.proxy.Remote30"] = 
class Remote30 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	list() {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [], "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser22())));
	}
	create(body) {
		var __body__ = ByteChunk.of(Bytes.ofString(new Writer44().wc({"_128": body.expiryDate, "_129": body.expiryTimezone, "_130": body.name, "_131": body.number})));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [], "headers": [new HeaderField("content-type", "application/json"), new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "POST", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser23())));
	}
	ofId(id) {
		return new Remote31(this.client, RemoteEndpoint.sub(this.endpoint, {"path": [Portion.ofString(id)], "query": [], "headers": [].concat([])}));
	}
	static get __name__() {
		return "tink.web.proxy.Remote30"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote30
	}
}

