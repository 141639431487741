import {Exception} from "../../haxe/Exception.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Host = Register.global("$hxClasses")["tink.url._Host.Host"] = 
class Host {
	static _new(name, port) {
		var this1;
		if (port == null) {
			this1 = name;
		} else if (port > 65535 || port <= 0) {
			throw Exception.thrown("Invalid port");
		} else {
			this1 = "" + name + ":" + port;
		};
		return this1;
	}
	static get __name__() {
		return "tink.url._Host.Host_Impl_"
	}
	get __class__() {
		return Host
	}
}

