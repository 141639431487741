import {Generator as Generator__1, Step} from "../../streams/Stream.js"
import {Future} from "../../core/Future.js"
import {TypedError} from "../../core/Error.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const BlobSource = Register.global("$hxClasses")["tink.io.js.BlobSource"] = 
class BlobSource extends Register.inherits(Generator__1) {
	new(name, blob, pos, chunkSize) {
		this.name = name;
		super.new(Future.async(function (cb) {
			if (pos >= blob.size) {
				cb(Step.End);
			} else {
				var end = pos + chunkSize;
				if (end > blob.size) {
					end = blob.size;
				};
				var reader = new FileReader();
				reader.onload = function () {
					var chunk = ByteChunk.of(Bytes.ofData(reader.result));
					cb(Step.Link(chunk, new BlobSource(name, blob, end, chunkSize)));
				};
				reader.onerror = function (e) {
					cb(Step.Fail(TypedError.withData(500, e.message, e, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_io/0.9.0/github/6504c4d00f5f94ca035741d43323d6e460aca911/src/tink/io/js/BlobSource.hx", "lineNumber": 29, "className": "tink.io.js.BlobSource", "methodName": "new"})));
				};
				reader.readAsArrayBuffer(blob.slice(pos, end));
			};
		}, true));
	}
	static get __name__() {
		return "tink.io.js.BlobSource"
	}
	static get __super__() {
		return Generator__1
	}
	get __class__() {
		return BlobSource
	}
}

