import {Representation} from "../../tink/json/Representation.js"
import {Exception} from "../../haxe/Exception.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Coordinates = Register.global("$hxClasses")["geojson.util._Coordinates.Coordinates"] = 
class Coordinates {
	static interpolate(points) {
		var lat = 0.;
		var $long = 0.;
		var sum = 0.;
		var _g = 0;
		while (_g < points.length) {
			var point = points[_g];
			++_g;
			lat += point.coordinates[1] * point.weight;
			$long += point.coordinates[0] * point.weight;
			sum += point.weight;
		};
		return [$long / sum, lat / sum];
	}
	static average(coordinates) {
		var _g = [];
		var _g1 = 0;
		while (_g1 < coordinates.length) _g.push({"coordinates": coordinates[_g1++], "weight": 1});
		return Coordinates.interpolate(_g);
	}
	static fromRepresentation(rep) {
		var _g = Representation.get(rep);
		if (_g.length == 2) {
			return [_g[0], _g[1]];
		} else {
			throw Exception.thrown("Invalid coordinates");
		};
	}
	static is(v) {
		if (((v) instanceof Array) && v.length == 2 && typeof(v[0]) == "number") {
			return typeof(v[1]) == "number";
		} else {
			return false;
		};
	}
	static get __name__() {
		return "geojson.util._Coordinates.Coordinates_Impl_"
	}
	get __class__() {
		return Coordinates
	}
}

