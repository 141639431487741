import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Portion = Register.global("$hxClasses")["tink.url._Portion.Portion"] = 
class Portion {
	static toString(this1) {
		if (this1 == null) {
			return null;
		} else {
			try {
				return decodeURIComponent(this1.split("+").join(" "));
			}catch (_g) {
				return "";
			};
		};
	}
	static ofString(s) {
		return (s == null) ? "" : encodeURIComponent(s);
	}
	static get __name__() {
		return "tink.url._Portion.Portion_Impl_"
	}
	get __class__() {
		return Portion
	}
}

