import {Remote36} from "./Remote36.js"
import {RemoteBase, RemoteEndpoint} from "./Remote.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote35 = Register.global("$hxClasses")["tink.web.proxy.Remote35"] = 
class Remote35 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	readings() {
		return new Remote36(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["readings"], "query": [], "headers": [].concat([])}));
	}
	static get __name__() {
		return "tink.web.proxy.Remote35"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote35
	}
}

