import {BasicParser, JsonString} from "./Parser.js"
import {MPair} from "../core/Pair.js"
import {OutcomeTools} from "../core/Outcome.js"
import {TypedError} from "../core/Error.js"
import {Option} from "../../haxe/ds/Option.js"
import {Exception} from "../../haxe/Exception.js"
import {Register} from "../../genes/Register.js"
import {TaskAction, GenerateAlarmAction, GetAudioAction, GetConfigAction, GetHardwareReportAction, LocateDeviceAction, PlayAudioAction, UpdateAudioAction, UpdateConfigAction, UpdateFirmwareAction} from "../../dasloop/types/task/TaskAction.js"
import {DeviceId} from "../../dasloop/types/DeviceId.js"
import {Std} from "../../Std.js"

const $global = Register.$global

export const Parser96 = Register.global("$hxClasses")["tink.json.Parser96"] = 
class Parser96 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	pb() {
		var _gthis = this;
		var v_action = null;
		var hasv_action = false;
		var v_attempt = 0;
		var hasv_attempt = false;
		var v_device = null;
		var hasv_device = false;
		var v_force = false;
		var hasv_force = false;
		var v_fresh = false;
		var hasv_fresh = false;
		var v_id = null;
		var hasv_id = false;
		var v_priority = 0;
		var hasv_priority = false;
		var v_progress = null;
		var hasv_progress = false;
		var v_revision = 0;
		var hasv_revision = false;
		var v_updated = null;
		var hasv_updated = false;
		var v_wait = 0;
		var hasv_wait = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "action":
						v_action = Option.Some(this.pc());
						hasv_action = true;
						break
					case "attempt":
						v_attempt = JsonString.i(this.r());
						hasv_attempt = true;
						break
					case "device":
						v_device = OutcomeTools.sure(DeviceId.parse(JsonString.s(this.m())));
						hasv_device = true;
						break
					case "force":
						v_force = this.G();
						hasv_force = true;
						break
					case "fresh":
						v_fresh = this.G();
						hasv_fresh = true;
						break
					case "id":
						v_id = JsonString.s(this.m());
						hasv_id = true;
						break
					case "priority":
						v_priority = JsonString.i(this.r());
						hasv_priority = true;
						break
					case "progress":
						this.F("[");
						var _e0 = JsonString.f(this.r());
						this.F(",");
						var this1 = new MPair(_e0, this.pK());
						this.F("]");
						v_progress = this1;
						hasv_progress = true;
						break
					case "revision":
						v_revision = JsonString.i(this.r());
						hasv_revision = true;
						break
					case "updated":
						v_updated = new Date(JsonString.f(this.r()));
						hasv_updated = true;
						break
					case "wait":
						v_wait = JsonString.i(this.r());
						hasv_wait = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		var tmp = (hasv_attempt) ? v_attempt : __missing__("attempt");
		var tmp1 = (hasv_device) ? v_device : __missing__("device");
		var tmp2 = (hasv_force) ? v_force : __missing__("force");
		var tmp3 = (hasv_fresh) ? v_fresh : __missing__("fresh");
		var tmp4 = (hasv_id) ? v_id : __missing__("id");
		var tmp5 = (hasv_priority) ? v_priority : __missing__("priority");
		var tmp6 = (hasv_progress) ? v_progress : __missing__("progress");
		var tmp7 = (hasv_revision) ? v_revision : __missing__("revision");
		var tmp8 = (hasv_updated) ? v_updated : __missing__("updated");
		var tmp9 = (hasv_wait) ? v_wait : __missing__("wait");
		return {"action": (hasv_action) ? v_action : Option.None, "attempt": tmp, "device": tmp1, "force": tmp2, "fresh": tmp3, "id": tmp4, "priority": tmp5, "progress": tmp6, "revision": tmp7, "updated": tmp8, "wait": tmp9};
	}
	pc() {
		var __ret = this.pd();
		var _g = __ret.GenerateAlarm;
		var _g1 = __ret.GetAudio;
		var _g2 = __ret.GetConfig;
		var _g3 = __ret.GetHardwareReport;
		var _g4 = __ret.LocateDevice;
		var _g5 = __ret.PlayAudio;
		var _g6 = __ret.UpdateAudio;
		var _g7 = __ret.UpdateConfig;
		var _g8 = __ret.UpdateFirmware;
		if (_g8 != null) {
			return TaskAction.UpdateFirmware(_g8.action);
		} else if (_g6 != null) {
			return TaskAction.UpdateAudio(_g6.action);
		} else if (_g2 != null) {
			return TaskAction.GetConfig(_g2.action);
		} else if (_g3 != null) {
			return TaskAction.GetHardwareReport(_g3.action);
		} else if (_g7 != null) {
			return TaskAction.UpdateConfig(_g7.action);
		} else if (_g4 != null) {
			return TaskAction.LocateDevice(_g4.action);
		} else if (_g5 != null) {
			return TaskAction.PlayAudio(_g5.action);
		} else if (_g != null) {
			return TaskAction.GenerateAlarm(_g.action);
		} else if (_g1 != null) {
			return TaskAction.GetAudio(_g1.action);
		} else {
			throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser96", "methodName": "process1"}));
		};
	}
	pd() {
		var _gthis = this;
		var v_GenerateAlarm = null;
		var v_GetAudio = null;
		var v_GetConfig = null;
		var v_GetHardwareReport = null;
		var v_LocateDevice = null;
		var v_PlayAudio = null;
		var v_UpdateAudio = null;
		var v_UpdateConfig = null;
		var v_UpdateFirmware = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "GenerateAlarm":
						v_GenerateAlarm = (this.E("null")) ? null : this.pe();
						break
					case "GetAudio":
						v_GetAudio = (this.E("null")) ? null : this.ph();
						break
					case "GetConfig":
						v_GetConfig = (this.E("null")) ? null : this.pl();
						break
					case "GetHardwareReport":
						v_GetHardwareReport = (this.E("null")) ? null : this.po();
						break
					case "LocateDevice":
						v_LocateDevice = (this.E("null")) ? null : this.pq();
						break
					case "PlayAudio":
						v_PlayAudio = (this.E("null")) ? null : this.pv();
						break
					case "UpdateAudio":
						v_UpdateAudio = (this.E("null")) ? null : this.px();
						break
					case "UpdateConfig":
						v_UpdateConfig = (this.E("null")) ? null : this.pB();
						break
					case "UpdateFirmware":
						v_UpdateFirmware = (this.E("null")) ? null : this.pG();
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {"GenerateAlarm": v_GenerateAlarm, "GetAudio": v_GetAudio, "GetConfig": v_GetConfig, "GetHardwareReport": v_GetHardwareReport, "LocateDevice": v_LocateDevice, "PlayAudio": v_PlayAudio, "UpdateAudio": v_UpdateAudio, "UpdateConfig": v_UpdateConfig, "UpdateFirmware": v_UpdateFirmware};
	}
	pe() {
		var _gthis = this;
		var v_action = null;
		var hasv_action = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "action") {
					v_action = this.pf();
					hasv_action = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"action": (hasv_action) ? v_action : __missing__("action")};
	}
	pf() {
		if (this.E("\"")) {
			var _g = JsonString.s(this.n());
			if (_g == "GenerateAlarm") {
				return GenerateAlarmAction.GenerateAlarm;
			} else {
				throw Exception.thrown(new TypedError(422, "Invalid constructor " + _g, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 443, "className": "tink.json.Parser96", "methodName": "process4"}));
			};
		} else {
			throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(this.pg()), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser96", "methodName": "process4"}));
		};
	}
	pg() {
		var _gthis = this;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				JsonString.s(this.m());
				this.F(":");
				this.B();
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {};
	}
	ph() {
		var _gthis = this;
		var v_action = null;
		var hasv_action = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "action") {
					v_action = this.pi();
					hasv_action = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"action": (hasv_action) ? v_action : __missing__("action")};
	}
	pi() {
		if (this.E("\"")) {
			var _g = JsonString.s(this.n());
			switch (_g) {
				case "SetEngineeringMode":
					return GetAudioAction.SetEngineeringMode;
					break
				case "UnsetEngineeringMode":
					return GetAudioAction.UnsetEngineeringMode;
					break
				default:
				throw Exception.thrown(new TypedError(422, "Invalid constructor " + _g, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 443, "className": "tink.json.Parser96", "methodName": "process7"}));
				
			};
		} else {
			var __ret = this.pj();
			var _g = __ret.GetAudio;
			if (_g != null) {
				return GetAudioAction.GetAudio(_g.offset, _g.length);
			} else {
				throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser96", "methodName": "process7"}));
			};
		};
	}
	pj() {
		var _gthis = this;
		var v_GetAudio = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "GetAudio") {
					v_GetAudio = (this.E("null")) ? null : this.pk();
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {"GetAudio": v_GetAudio};
	}
	pk() {
		var _gthis = this;
		var v_length = 0;
		var hasv_length = false;
		var v_offset = 0;
		var hasv_offset = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "length":
						v_length = JsonString.i(this.r());
						hasv_length = true;
						break
					case "offset":
						v_offset = JsonString.i(this.r());
						hasv_offset = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"length": (hasv_length) ? v_length : __missing__("length"), "offset": (hasv_offset) ? v_offset : __missing__("offset")};
	}
	pl() {
		var _gthis = this;
		var v_action = null;
		var hasv_action = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "action") {
					v_action = this.pm();
					hasv_action = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"action": (hasv_action) ? v_action : __missing__("action")};
	}
	pm() {
		if (this.E("\"")) {
			var _g = JsonString.s(this.n());
			switch (_g) {
				case "GetConfigSize":
					return GetConfigAction.GetConfigSize;
					break
				case "GetFirmwareVersion":
					return GetConfigAction.GetFirmwareVersion;
					break
				default:
				throw Exception.thrown(new TypedError(422, "Invalid constructor " + _g, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 443, "className": "tink.json.Parser96", "methodName": "process11"}));
				
			};
		} else {
			var __ret = this.pn();
			var _g = __ret.GetConfig;
			if (_g != null) {
				return GetConfigAction.GetConfig(_g.offset, _g.length);
			} else {
				throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser96", "methodName": "process11"}));
			};
		};
	}
	pn() {
		var _gthis = this;
		var v_GetConfig = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "GetConfig") {
					v_GetConfig = (this.E("null")) ? null : this.pk();
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {"GetConfig": v_GetConfig};
	}
	po() {
		var _gthis = this;
		var v_action = null;
		var hasv_action = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "action") {
					v_action = this.pp();
					hasv_action = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"action": (hasv_action) ? v_action : __missing__("action")};
	}
	pp() {
		if (this.E("\"")) {
			var _g = JsonString.s(this.n());
			if (_g == "GetHardwareReport") {
				return GetHardwareReportAction.GetHardwareReport;
			} else {
				throw Exception.thrown(new TypedError(422, "Invalid constructor " + _g, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 443, "className": "tink.json.Parser96", "methodName": "process14"}));
			};
		} else {
			throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(this.pg()), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser96", "methodName": "process14"}));
		};
	}
	pq() {
		var _gthis = this;
		var v_action = null;
		var hasv_action = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "action") {
					v_action = this.pr();
					hasv_action = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"action": (hasv_action) ? v_action : __missing__("action")};
	}
	pr() {
		if (this.E("\"")) {
			var _g = JsonString.s(this.n());
			switch (_g) {
				case "SetBuzzer":
					return LocateDeviceAction.SetBuzzer;
					break
				case "SetEngineeringMode":
					return LocateDeviceAction.SetEngineeringMode;
					break
				case "SetLed":
					return LocateDeviceAction.SetLed;
					break
				case "SetVibrator":
					return LocateDeviceAction.SetVibrator;
					break
				case "UnsetEngineeringMode":
					return LocateDeviceAction.UnsetEngineeringMode;
					break
				default:
				throw Exception.thrown(new TypedError(422, "Invalid constructor " + _g, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 443, "className": "tink.json.Parser96", "methodName": "process16"}));
				
			};
		} else {
			var __ret = this.ps();
			var _g = __ret.Recover;
			var _g1 = __ret.SetUwbAlarm;
			if (_g1 != null) {
				return LocateDeviceAction.SetUwbAlarm(_g1.light, _g1.sound);
			} else if (_g != null) {
				return LocateDeviceAction.Recover(_g.next);
			} else {
				throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser96", "methodName": "process16"}));
			};
		};
	}
	ps() {
		var _gthis = this;
		var v_Recover = null;
		var v_SetUwbAlarm = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "Recover":
						v_Recover = (this.E("null")) ? null : this.pt();
						break
					case "SetUwbAlarm":
						v_SetUwbAlarm = (this.E("null")) ? null : this.pu();
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {"Recover": v_Recover, "SetUwbAlarm": v_SetUwbAlarm};
	}
	pt() {
		var _gthis = this;
		var v_next = null;
		var hasv_next = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "next") {
					v_next = this.pr();
					hasv_next = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"next": (hasv_next) ? v_next : __missing__("next")};
	}
	pu() {
		var _gthis = this;
		var v_light = false;
		var hasv_light = false;
		var v_sound = false;
		var hasv_sound = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "light":
						v_light = this.G();
						hasv_light = true;
						break
					case "sound":
						v_sound = this.G();
						hasv_sound = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"light": (hasv_light) ? v_light : __missing__("light"), "sound": (hasv_sound) ? v_sound : __missing__("sound")};
	}
	pv() {
		var _gthis = this;
		var v_action = null;
		var hasv_action = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "action") {
					v_action = this.pw();
					hasv_action = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"action": (hasv_action) ? v_action : __missing__("action")};
	}
	pw() {
		if (this.E("\"")) {
			var _g = JsonString.s(this.n());
			if (_g == "PlayAudio") {
				return PlayAudioAction.PlayAudio;
			} else {
				throw Exception.thrown(new TypedError(422, "Invalid constructor " + _g, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 443, "className": "tink.json.Parser96", "methodName": "process21"}));
			};
		} else {
			throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(this.pg()), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser96", "methodName": "process21"}));
		};
	}
	px() {
		var _gthis = this;
		var v_action = null;
		var hasv_action = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "action") {
					v_action = this.py();
					hasv_action = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"action": (hasv_action) ? v_action : __missing__("action")};
	}
	py() {
		if (this.E("\"")) {
			var _g = JsonString.s(this.n());
			switch (_g) {
				case "GetDeviceInfo":
					return UpdateAudioAction.GetDeviceInfo;
					break
				case "PatchChecksum":
					return UpdateAudioAction.PatchChecksum;
					break
				case "RequestReset":
					return UpdateAudioAction.RequestReset;
					break
				case "SetEngineeringMode":
					return UpdateAudioAction.SetEngineeringMode;
					break
				default:
				throw Exception.thrown(new TypedError(422, "Invalid constructor " + _g, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 443, "className": "tink.json.Parser96", "methodName": "process23"}));
				
			};
		} else {
			var __ret = this.pz();
			var _g = __ret.Pause;
			var _g1 = __ret.Recover;
			var _g2 = __ret.SendAudio;
			var _g3 = __ret.WaitForRecover;
			if (_g2 != null) {
				return UpdateAudioAction.SendAudio(_g2.offset, _g2.length);
			} else if (_g1 != null) {
				return UpdateAudioAction.Recover(_g1.next);
			} else if (_g != null) {
				return UpdateAudioAction.Pause(_g.next);
			} else if (_g3 != null) {
				return UpdateAudioAction.WaitForRecover(_g3.next);
			} else {
				throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser96", "methodName": "process23"}));
			};
		};
	}
	pz() {
		var _gthis = this;
		var v_Pause = null;
		var v_Recover = null;
		var v_SendAudio = null;
		var v_WaitForRecover = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "Pause":
						v_Pause = (this.E("null")) ? null : this.pA();
						break
					case "Recover":
						v_Recover = (this.E("null")) ? null : this.pA();
						break
					case "SendAudio":
						v_SendAudio = (this.E("null")) ? null : this.pk();
						break
					case "WaitForRecover":
						v_WaitForRecover = (this.E("null")) ? null : this.pA();
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {"Pause": v_Pause, "Recover": v_Recover, "SendAudio": v_SendAudio, "WaitForRecover": v_WaitForRecover};
	}
	pA() {
		var _gthis = this;
		var v_next = null;
		var hasv_next = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "next") {
					v_next = this.py();
					hasv_next = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"next": (hasv_next) ? v_next : __missing__("next")};
	}
	pB() {
		var _gthis = this;
		var v_action = null;
		var hasv_action = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "action") {
					v_action = this.pC();
					hasv_action = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"action": (hasv_action) ? v_action : __missing__("action")};
	}
	pC() {
		if (this.E("\"")) {
			var _g = JsonString.s(this.n());
			switch (_g) {
				case "GetFirmwareVersion":
					return UpdateConfigAction.GetFirmwareVersion;
					break
				case "RequestReset":
					return UpdateConfigAction.RequestReset;
					break
				case "SetEngineeringMode":
					return UpdateConfigAction.SetEngineeringMode;
					break
				default:
				throw Exception.thrown(new TypedError(422, "Invalid constructor " + _g, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 443, "className": "tink.json.Parser96", "methodName": "process27"}));
				
			};
		} else {
			var __ret = this.pD();
			var _g = __ret.Recover;
			var _g1 = __ret.UpdateConfig;
			if (_g1 != null) {
				return UpdateConfigAction.UpdateConfig(_g1.index);
			} else if (_g != null) {
				return UpdateConfigAction.Recover(_g.next);
			} else {
				throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser96", "methodName": "process27"}));
			};
		};
	}
	pD() {
		var _gthis = this;
		var v_Recover = null;
		var v_UpdateConfig = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "Recover":
						v_Recover = (this.E("null")) ? null : this.pE();
						break
					case "UpdateConfig":
						v_UpdateConfig = (this.E("null")) ? null : this.pF();
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {"Recover": v_Recover, "UpdateConfig": v_UpdateConfig};
	}
	pE() {
		var _gthis = this;
		var v_next = null;
		var hasv_next = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "next") {
					v_next = this.pC();
					hasv_next = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"next": (hasv_next) ? v_next : __missing__("next")};
	}
	pF() {
		var _gthis = this;
		var v_index = 0;
		var hasv_index = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "index") {
					v_index = JsonString.i(this.r());
					hasv_index = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"index": (hasv_index) ? v_index : __missing__("index")};
	}
	pG() {
		var _gthis = this;
		var v_action = null;
		var hasv_action = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "action") {
					v_action = this.pH();
					hasv_action = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"action": (hasv_action) ? v_action : __missing__("action")};
	}
	pH() {
		if (this.E("\"")) {
			var _g = JsonString.s(this.n());
			switch (_g) {
				case "CheckFirmwareVersion":
					return UpdateFirmwareAction.CheckFirmwareVersion;
					break
				case "GetConfig":
					return UpdateFirmwareAction.GetConfig;
					break
				case "GetDeviceInfo":
					return UpdateFirmwareAction.GetDeviceInfo;
					break
				case "PredefineConfig":
					return UpdateFirmwareAction.PredefineConfig;
					break
				case "RequestUpdateFirmware":
					return UpdateFirmwareAction.RequestUpdateFirmware;
					break
				case "SetEngineeringMode":
					return UpdateFirmwareAction.SetEngineeringMode;
					break
				default:
				throw Exception.thrown(new TypedError(422, "Invalid constructor " + _g, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 443, "className": "tink.json.Parser96", "methodName": "process32"}));
				
			};
		} else {
			var __ret = this.pI();
			var _g = __ret.Pause;
			var _g1 = __ret.Recover;
			var _g2 = __ret.SendFirmware;
			var _g3 = __ret.WaitForRecover;
			if (_g2 != null) {
				return UpdateFirmwareAction.SendFirmware(_g2.offset, _g2.length);
			} else if (_g1 != null) {
				return UpdateFirmwareAction.Recover(_g1.next);
			} else if (_g != null) {
				return UpdateFirmwareAction.Pause(_g.next);
			} else if (_g3 != null) {
				return UpdateFirmwareAction.WaitForRecover(_g3.next);
			} else {
				throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser96", "methodName": "process32"}));
			};
		};
	}
	pI() {
		var _gthis = this;
		var v_Pause = null;
		var v_Recover = null;
		var v_SendFirmware = null;
		var v_WaitForRecover = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "Pause":
						v_Pause = (this.E("null")) ? null : this.pJ();
						break
					case "Recover":
						v_Recover = (this.E("null")) ? null : this.pJ();
						break
					case "SendFirmware":
						v_SendFirmware = (this.E("null")) ? null : this.pk();
						break
					case "WaitForRecover":
						v_WaitForRecover = (this.E("null")) ? null : this.pJ();
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {"Pause": v_Pause, "Recover": v_Recover, "SendFirmware": v_SendFirmware, "WaitForRecover": v_WaitForRecover};
	}
	pJ() {
		var _gthis = this;
		var v_next = null;
		var hasv_next = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "next") {
					v_next = this.pH();
					hasv_next = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"next": (hasv_next) ? v_next : __missing__("next")};
	}
	pK() {
		if (this.E("\"")) {
			var _g = JsonString.s(this.n());
			if (_g == "None") {
				return Option.None;
			} else {
				throw Exception.thrown(new TypedError(422, "Invalid constructor " + _g, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 443, "className": "tink.json.Parser96", "methodName": "process35"}));
			};
		} else {
			var __ret = this.pL();
			var _g = __ret.Some;
			if (_g != null) {
				return Option.Some(_g.v);
			} else {
				throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser96", "methodName": "process35"}));
			};
		};
	}
	pL() {
		var _gthis = this;
		var v_Some = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "Some") {
					v_Some = (this.E("null")) ? null : this.pM();
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {"Some": v_Some};
	}
	pM() {
		var _gthis = this;
		var v_v = .0;
		var hasv_v = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "v") {
					v_v = JsonString.f(this.r());
					hasv_v = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"v": (hasv_v) ? v_v : __missing__("v")};
	}
	pN(source) {
		if (this.e.length > 0) {
			this.e = [];
		};
		this.g(source);
		var ret = this.pb();
		var _g = 0;
		var _g1 = this.e;
		while (_g < _g1.length) _g1[_g++]();
		if (this.e.length > 0) {
			this.e = [];
		};
		return ret;
	}
	pO(source) {
		var _gthis = this;
		return TypedError.catchExceptions(function () {
			var ret = _gthis.pN(source);
			_gthis.h();
			if (_gthis.c < _gthis.d) {
				_gthis.D("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser96", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser96"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser96
	}
}

