import {ToastObject} from "../Toast.js"
import Noty from "noty"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

/**
* <link rel="stylesheet" type="text/css" href="https://unpkg.com/noty@3.1.4/lib/noty.css">
* <script src="https://unpkg.com/noty@3.1.4/lib/noty.min.js"></script>
*/
export const NotyToast = Register.global("$hxClasses")["why.toast.NotyToast"] = 
class NotyToast extends Register.inherits() {
	new(defaults) {
		this.defaults = (defaults == null) ? {} : defaults;
	}
	show(options) {
		var opt = Object.assign({}, this.defaults);
		opt.text = options.title + ((options.details == null) ? "" : "<br>" + options.details);
		var _g = options.duration;
		var tmp;
		if (_g == null) {
			tmp = 1500;
		} else {
			switch (_g._hx_index) {
				case 0:
					tmp = 1500;
					break
				case 1:
					tmp = 3500;
					break
				case 2:
					tmp = _g.ms;
					break
				case 3:
					tmp = null;
					break
				
			};
		};
		opt.timeout = tmp;
		var tmp;
		switch (options.type._hx_index) {
			case 0:
				tmp = "success";
				break
			case 1:
				tmp = "warning";
				break
			case 2:
				tmp = "info";
				break
			case 3:
				tmp = "error";
				break
			
		};
		opt.type = tmp;
		new Noty(opt).show();
	}
	static get __name__() {
		return "why.toast.NotyToast"
	}
	static get __interfaces__() {
		return [ToastObject]
	}
	get __class__() {
		return NotyToast
	}
}

