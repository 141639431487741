import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {ConstObservable, Observable} from "../../../tink/state/Observable.js"
import {Signal} from "../../../tink/core/Signal.js"
import {OutcomeTools, OutcomeMapper, Outcome} from "../../../tink/core/Outcome.js"
import {TypedError} from "../../../tink/core/Error.js"
import {Register} from "../../../genes/Register.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"
import {Std} from "../../../Std.js"
import {EReg} from "../../../EReg.js"
import {DateTools} from "../../../DateTools.js"

const $global = Register.$global

export const DateModel = Register.global("$hxClasses")["dasloop.ui.data.DateModel"] = 
class DateModel extends Register.inherits() {
	new(__coco_init) {
		this._updatePerformed = Signal.trigger();
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return OutcomeTools.flatMap(_gthis.parse(State.get_value(_gthis.__coco_input)), OutcomeMapper.withSameError(_gthis.validator));
		}), null);
		AutoObservable.cur = before;
		this.__coco_output = ret;
		var _g = __coco_init.input;
		this.__coco_input = State._new((_g == null) ? "" : _g, null, null, null);
		this.mode = __coco_init.mode;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var _g = __coco_init.parse;
		var ret = (_g == null) ? function (v) {
			switch (_gthis.mode) {
				case 0:
					var regex = new EReg("^(\\d{4})-(\\d{2})-(\\d{2})$", "");
					if (!regex.match(State.get_value(_gthis.__coco_input))) {
						return Outcome.Failure(new TypedError(null, "Invalid Date", {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/dasloop-ui/0.0.0/git/6fa8443e2144bac1a02e0b9fd66593eb77a24a59/src/dasloop/ui/data/DateModel.hx", "lineNumber": 19, "className": "dasloop.ui.data.DateModel", "methodName": "new"}));
					} else {
						var yy = Std.parseInt(regex.matched(1));
						var mm = Std.parseInt(regex.matched(2));
						var dd = Std.parseInt(regex.matched(3));
						if (yy < 100 || mm > 12 || mm == 0 || dd == 0 || dd > DateTools.getMonthDays(new Date(yy, mm - 1, 1, 0, 0, 0))) {
							return Outcome.Failure(new TypedError(null, "Invalid Date", {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/dasloop-ui/0.0.0/git/6fa8443e2144bac1a02e0b9fd66593eb77a24a59/src/dasloop/ui/data/DateModel.hx", "lineNumber": 25, "className": "dasloop.ui.data.DateModel", "methodName": "new"}));
						} else {
							return Outcome.Success(new Date(yy, mm - 1, dd, 0, 0, 0));
						};
					};
					break
				case 1:
					var regex = new EReg("^(\\d{4})-(\\d{2})-(\\d{2}) (\\d{2}):(\\d{2}):(\\d{2})$", "");
					if (!regex.match(State.get_value(_gthis.__coco_input))) {
						return Outcome.Failure(new TypedError(null, "Invalid Date", {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/dasloop-ui/0.0.0/git/6fa8443e2144bac1a02e0b9fd66593eb77a24a59/src/dasloop/ui/data/DateModel.hx", "lineNumber": 32, "className": "dasloop.ui.data.DateModel", "methodName": "new"}));
					} else {
						var yy = Std.parseInt(regex.matched(1));
						var mm = Std.parseInt(regex.matched(2));
						var dd = Std.parseInt(regex.matched(3));
						var h = Std.parseInt(regex.matched(4));
						var m = Std.parseInt(regex.matched(5));
						var s = Std.parseInt(regex.matched(6));
						if (yy < 100 || mm > 12 || mm == 0 || dd == 0 || dd > DateTools.getMonthDays(new Date(yy, mm - 1, 1, 0, 0, 0)) || h > 23 || m > 59 || s > 59) {
							return Outcome.Failure(new TypedError(null, "Invalid Date", {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/dasloop-ui/0.0.0/git/6fa8443e2144bac1a02e0b9fd66593eb77a24a59/src/dasloop/ui/data/DateModel.hx", "lineNumber": 41, "className": "dasloop.ui.data.DateModel", "methodName": "new"}));
						} else {
							return Outcome.Success(new Date(yy, mm - 1, dd, h, m, s));
						};
					};
					break
				
			};
		} : _g;
		AutoObservable.cur = before;
		this.parse = ret;
		var _g = __coco_init.validator;
		this.validator = (_g == null) ? Outcome.Success : _g;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"input": this.__coco_input, "mode": new ConstObservable(this.mode), "parse": new ConstObservable(this.parse), "validator": new ConstObservable(this.validator), "output": this.__coco_output, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	set_input(param) {
		this._updatePerformed.handlers.invoke({"input": param});
		this.__coco_input.set(param);
		return param;
	}
	static get __name__() {
		return "dasloop.ui.data.DateModel"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return DateModel
	}
}

