import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {ConstObservable, Observable} from "../../../tink/state/Observable.js"
import {Vectorlike} from "../../../tink/pure/Vector.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Promise as Promise__1} from "../../../tink/core/Promise.js"
import {Outcome} from "../../../tink/core/Outcome.js"
import {LazyConst} from "../../../tink/core/Lazy.js"
import {SyncFuture} from "../../../tink/core/Future.js"
import {Option} from "../../../haxe/ds/Option.js"
import {Register} from "../../../genes/Register.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const MembersData = Register.global("$hxClasses")["dasloop.app.data.MembersData"] = 
class MembersData extends Register.inherits() {
	new(__coco_init) {
		this._updatePerformed = Signal.trigger();
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.async(function () {
			State.get_value(_gthis.__coco_revision);
			var _g = State.get_value(_gthis.__coco_cache);
			switch (_g._hx_index) {
				case 0:
					return new SyncFuture(new LazyConst(Outcome.Success(_g.v)));
					break
				case 1:
					return _gthis.remote.v2().projects().ofId(_gthis.project).members().list();
					break
				
			};
		}), null);
		AutoObservable.cur = before;
		this.__coco_list = ret;
		this.project = __coco_init.project;
		this.remote = __coco_init.remote;
		this.__coco_revision = State._new(0, null, null, null);
		this.__coco_cache = State._new(Option.None, null, null, null);
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"project": new ConstObservable(this.project), "remote": new ConstObservable(this.remote), "list": this.__coco_list, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	refresh(cache) {
		this.set_revision(State.get_value(this.__coco_revision) + 1);
		this.set_cache(cache);
	}
	add(email, role) {
		var _gthis = this;
		return Promise__1.next(this.remote.v2().projects().ofId(this.project).members().add(email, role), function (added) {
			var _g = Observable.get_value(_gthis.__coco_list);
			if (_g._hx_index == 1) {
				_gthis.refresh(Option.Some(_g.result.concat(Vectorlike.ofSingle(added))));
			} else {
				_gthis.refresh(Option.None);
			};
			return new SyncFuture(new LazyConst(Outcome.Success(added)));
		});
	}
	remove(id) {
		var _gthis = this;
		return Promise__1.next(this.remote.v2().projects().ofId(this.project).members()["delete"](id), function (_) {
			var _g = Observable.get_value(_gthis.__coco_list);
			if (_g._hx_index == 1) {
				var _gthis1 = _gthis;
				var _g1 = [];
				var _g2 = 0;
				var _g3 = _g.result;
				while (_g2 < _g3.length) {
					var v = _g3[_g2];
					++_g2;
					if (v.id != id) {
						_g1.push(v);
					};
				};
				_gthis1.refresh(Option.Some(_g1));
			} else {
				_gthis.refresh(Option.None);
			};
			return Promise__1.NOISE;
		});
	}
	set_revision(param) {
		this._updatePerformed.handlers.invoke({"revision": param});
		this.__coco_revision.set(param);
		return param;
	}
	set_cache(param) {
		this._updatePerformed.handlers.invoke({"cache": param});
		this.__coco_cache.set(param);
		return param;
	}
	static get __name__() {
		return "dasloop.app.data.MembersData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return MembersData
	}
}

