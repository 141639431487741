import {BasicWriter} from "./Writer.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Writer45 = Register.global("$hxClasses")["tink.json.Writer45"] = 
class Writer45 extends Register.inherits(BasicWriter) {
	new() {
		super.new();
	}
	wb(value) {
		this.f("{");
		var value1 = value._101;
		this.e("\"age\":");
		if (value1 == null) {
			this.e("null");
		} else {
			this.g(value1);
		};
		var value1 = value._102;
		this.f(",");
		this.e("\"company\":");
		this.j(value1);
		var value1 = value._103;
		this.f(",");
		this.e("\"contacts\":");
		var value2 = value1;
		this.f("[");
		var first = true;
		var _g = 0;
		while (_g < value2.length) {
			var value1 = value2[_g++];
			if (first) {
				first = false;
			} else {
				this.f(",");
			};
			this.wc(value1);
		};
		this.f("]");
		var value1 = value._104;
		this.f(",");
		this.e("\"dateOfBirth\":");
		if (value1 == null) {
			this.e("null");
		} else {
			this.j(value1);
		};
		var value1 = value._105;
		this.f(",");
		this.e("\"division\":");
		this.j(value1);
		var value1 = value._106;
		this.f(",");
		this.e("\"email\":");
		if (value1 == null) {
			this.e("null");
		} else {
			this.j(value1);
		};
		var value1 = value._107;
		this.f(",");
		this.e("\"gender\":");
		this.j(value1);
		var value1 = value._108;
		this.f(",");
		this.e("\"name\":");
		this.j(value1);
		var value1 = value._109;
		this.f(",");
		this.e("\"phone\":");
		if (value1 == null) {
			this.e("null");
		} else {
			this.j(value1);
		};
		var value1 = value._110;
		this.f(",");
		this.e("\"registrationNumber\":");
		this.j(value1);
		var value1 = value._111;
		this.f(",");
		this.e("\"remarks\":");
		this.j(value1);
		var value1 = value._112;
		this.f(",");
		this.e("\"trade\":");
		this.j(value1);
		this.f("}");
	}
	wc(value) {
		this.f("{");
		var value1 = value.language;
		this.e("\"language\":");
		this.j(value1);
		var value1 = value.name;
		this.f(",");
		this.e("\"name\":");
		this.j(value1);
		var value1 = value.role;
		this.f(",");
		this.e("\"role\":");
		this.j(value1);
		var _g = value.email;
		if (_g != null) {
			this.f(",");
			this.e("\"email\":");
			if (_g == null) {
				this.e("null");
			} else {
				this.j(_g);
			};
		};
		var _g = value.phone;
		if (_g != null) {
			this.f(",");
			this.e("\"phone\":");
			if (_g == null) {
				this.e("null");
			} else {
				this.j(_g);
			};
		};
		var _g = value.sms;
		if (_g != null) {
			this.f(",");
			this.e("\"sms\":");
			if (_g == null) {
				this.e("null");
			} else {
				this.j(_g);
			};
		};
		var _g = value.telegram;
		if (_g != null) {
			this.f(",");
			this.e("\"telegram\":");
			if (_g == null) {
				this.e("null");
			} else {
				this.j(_g);
			};
		};
		var _g = value.whatsapp;
		if (_g != null) {
			this.f(",");
			this.e("\"whatsapp\":");
			if (_g == null) {
				this.e("null");
			} else {
				this.j(_g);
			};
		};
		this.f("}");
	}
	wd(value) {
		this.d();
		this.wb(value);
		return this.b.toString();
	}
	static get __name__() {
		return "tink.json.Writer45"
	}
	static get __super__() {
		return BasicWriter
	}
	get __class__() {
		return Writer45
	}
}

