import {RemoteBase, RemoteEndpoint, ResponseReader} from "./Remote.js"
import {Single} from "../../streams/Stream.js"
import {Builder34} from "../../querystring/Builder34.js"
import {Parser74} from "../../json/Parser74.js"
import {HeaderField, HeaderValue} from "../../http/Header.js"
import {LazyConst} from "../../core/Lazy.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote75 = Register.global("$hxClasses")["tink.web.proxy.Remote75"] = 
class Remote75 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	get(region, language) {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [].concat(new Builder34().stringify({"_289": region, "_290": language})), "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pe))(new Parser74())));
	}
	static get __name__() {
		return "tink.web.proxy.Remote75"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote75
	}
}

