import {Portion} from "../url/Portion.js"
import {ParserBase, Field, FieldTools} from "./Parser.js"
import {Writer68} from "../json/Writer68.js"
import {TypedError} from "../core/Error.js"
import {Exception} from "../../haxe/Exception.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Parser5 = Register.global("$hxClasses")["tink.querystring.Parser5"] = 
class Parser5 extends Register.inherits(ParserBase) {
	new(onError, pos) {
		super.new(onError, pos);
	}
	getName(p) {
		return p.name;
	}
	getValue(p) {
		return p.value;
	}
	parse(input) {
		this.init(input, Register.bind(this, this.getName), Register.bind(this, this.getValue));
		return this.process0("", Field.Sub(this.root));
	}
	process0(name, field) {
		var tree = FieldTools.getSub(field);
		var field = tree.inst.get("assignment");
		var __o;
		if (field == null) {
			__o = null;
		} else {
			var v = Portion.toString(FieldTools.getValue(field));
			switch (v) {
				case "existing":case "new":case "none":
					__o = v;
					break
				default:
				throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer68().wb(["none", "new", "existing"]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_querystring/0.7.0/github/7333282fa0ac69c2096f1feb4ef7b9514155b582/src/tink/querystring/macros/GenParser.hx", "lineNumber": 273, "className": "tink.querystring.Parser5", "methodName": "process0"}));
				
			};
		};
		var field = tree.inst.get("id");
		return {"assignment": __o, "id": (field == null) ? null : Portion.toString(FieldTools.getValue(field))};
	}
	static get __name__() {
		return "tink.querystring.Parser5"
	}
	static get __super__() {
		return ParserBase
	}
	get __class__() {
		return Parser5
	}
}

