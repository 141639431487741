import {BasicWriter} from "./Writer.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Writer44 = Register.global("$hxClasses")["tink.json.Writer44"] = 
class Writer44 extends Register.inherits(BasicWriter) {
	new() {
		super.new();
	}
	wb(value) {
		this.f("{");
		var value1 = value._128;
		this.e("\"expiryDate\":");
		this.h(value1.getTime());
		var value1 = value._129;
		this.f(",");
		this.e("\"expiryTimezone\":");
		this.h(value1 / 60);
		var value1 = value._130;
		this.f(",");
		this.e("\"name\":");
		this.j(value1);
		var value1 = value._131;
		this.f(",");
		this.e("\"number\":");
		this.j(value1);
		this.f("}");
	}
	wc(value) {
		this.d();
		this.wb(value);
		return this.b.toString();
	}
	static get __name__() {
		return "tink.json.Writer44"
	}
	static get __super__() {
		return BasicWriter
	}
	get __class__() {
		return Writer44
	}
}

