import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const BatteryValueTools = Register.global("$hxClasses")["dasloop.types.util.BatteryValueTools"] = 
class BatteryValueTools {
	
	/**
	This is used to calculate the voltage of Daspower/Dastemp
	
	For the value of min/max functionable voltage on daspower and dastemp described on Discord
	Note that: Currently we are using battery mode, NOT recharble
	refs: https://discord.com/channels/829186002462703646/857832984790695936/933167073327206400
	*/
	static getBatteryValue(voltage) {
		if (voltage >= 3.273) {
			return 100;
		} else if (voltage <= 2.8) {
			return 0;
		} else {
			return (voltage - 2.8) * 100 / 0.473000000000000309;
		};
	}
	static get __name__() {
		return "dasloop.types.util.BatteryValueTools"
	}
	get __class__() {
		return BatteryValueTools
	}
}

