import {Revision} from "./internal/Revision.js"
import {ObservableObject} from "./internal/ObservableObject.js"
import {Binding} from "./internal/Binding.js"
import {AutoObservable, Computation} from "./internal/AutoObservable.js"
import {Scheduler} from "./Scheduler.js"
import {FutureTrigger} from "../core/Future.js"
import {Option} from "../../haxe/ds/Option.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const ConstObservable = Register.global("$hxClasses")["tink.state._Observable.ConstObservable"] = 
class ConstObservable extends Register.inherits() {
	new(value, toString) {
		this.revision = Revision._new();
		this.value = value;
	}
	getRevision() {
		return this.revision;
	}
	canFire() {
		return true;
	}
	getValue() {
		return this.value;
	}
	getComparator() {
		return null;
	}
	onInvalidate(i) {
		return null;
	}
	static get __name__() {
		return "tink.state._Observable.ConstObservable"
	}
	static get __interfaces__() {
		return [ObservableObject]
	}
	get __class__() {
		return ConstObservable
	}
}


export const Observable = Register.global("$hxClasses")["tink.state._Observable.Observable"] = 
class Observable {
	static get_value(this1) {
		var ret = this1.getValue();
		if (AutoObservable.cur != null && this1.canFire()) {
			AutoObservable.cur.subscribeTo(this1, ret);
		};
		return ret;
	}
	
	/**
	Bind a given `callback` to listen for changes of this observable. Returned `CallbackLink`
	object can be used to cancel the binding.
	
	The `callback` will be directly invoked with the current value for the first time and then
	will be invoked each time the binding is triggered by a value change.
	
	Note that the subsequent invokations of callbacks are done in batches, meaning that changed
	values are collected during an execution frame and are scheduled for processing at the end of
	the frame (the exact scheduling mechanism depends the platform).
	
	It is also doesn't matter how many times the value was changed before the callback is invoked,
	it will only be called once per batch if the final value is different from the previous one.
	
	You can customize this behaviour by passing a different `scheduler` and `comparator` instances
	to this function.
	*/
	static bind(this1, callback, comparator, scheduler) {
		if (scheduler == null) {
			scheduler = Observable.scheduler;
		};
		return Binding.create(this1, callback, scheduler, comparator);
	}
	static getNext(this1, options, select) {
		var ret = new FutureTrigger();
		var waiting = options != null && options.butNotNow;
		var _e = Observable.bind(this1, function (value) {
			var out = select(value);
			if (waiting) {
				waiting = out != Option.None;
			} else {
				switch (out._hx_index) {
					case 0:
						ret.trigger(out.v);
						break
					case 1:
						break
					
				};
			};
		}, null, (options != null && options.hires) ? Scheduler.direct : null);
		var tmp = function () {
			if (_e != null) {
				_e.cancel();
			};
		};
		ret.handle(tmp);
		return ret;
	}
	static map(this1, f) {
		return new AutoObservable(Computation.sync(function () {
			var value = Observable.get_value(this1);
			return f(value);
		}), null);
	}
	static updatePending(maxSeconds) {
		if (maxSeconds == null) {
			maxSeconds = .01;
		};
		if (!Observable.isUpdating) {
			return Observable.scheduler.progress(maxSeconds);
		} else {
			return false;
		};
	}
	static updateAll() {
		Observable.updatePending(Infinity);
	}
	static get __name__() {
		return "tink.state._Observable.Observable_Impl_"
	}
	get __class__() {
		return Observable
	}
}


Observable.MAX_ITERATIONS = 100
Register.createStatic(Observable, "scheduler", function () { return Scheduler.batched(Scheduler.batcher()) })
Observable.isUpdating = false