import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Revision = Register.global("$hxClasses")["tink.state.internal._Revision.Revision"] = 
class Revision {
	static _new() {
		return Revision.counter += 1.0;
	}
	static get __name__() {
		return "tink.state.internal._Revision.Revision_Impl_"
	}
	get __class__() {
		return Revision
	}
}


;Revision.counter = .0
