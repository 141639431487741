import {Register} from "./genes/Register.js"
import {Declarations} from "./cix/css/Declarations.js"

const $global = Register.$global

export const Cix89f36f5f2f90f8ce599858f1044b551f5079e395533981daeb4959b3241ff239 = Register.global("$hxClasses")["Cix89f36f5f2f90f8ce599858f1044b551f5079e395533981daeb4959b3241ff239"] = 
class Cix89f36f5f2f90f8ce599858f1044b551f5079e395533981daeb4959b3241ff239 extends Register.inherits() {
	new() {
		this.css = Declarations.add("cxrc", function () {
			return ".cxrc {\n  flex-direction: column;\n}";
		});
	}
	static get __name__() {
		return "Cix89f36f5f2f90f8ce599858f1044b551f5079e395533981daeb4959b3241ff239"
	}
	get __class__() {
		return Cix89f36f5f2f90f8ce599858f1044b551f5079e395533981daeb4959b3241ff239
	}
}


Cix89f36f5f2f90f8ce599858f1044b551f5079e395533981daeb4959b3241ff239.inst = new Cix89f36f5f2f90f8ce599858f1044b551f5079e395533981daeb4959b3241ff239()