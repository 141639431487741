import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {PromisedTools} from "../../../tink/state/Promised.js"
import {Observable, ConstObservable} from "../../../tink/state/Observable.js"
import {Vector} from "../../../tink/pure/Vector.js"
import {BlobSource} from "../../../tink/io/js/BlobSource.js"
import {RealSourceTools, Source} from "../../../tink/io/Source.js"
import {FetchResponse, Fetch} from "../../../tink/http/Fetch.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Promise as Promise__1} from "../../../tink/core/Promise.js"
import {Outcome} from "../../../tink/core/Outcome.js"
import {LazyConst} from "../../../tink/core/Lazy.js"
import {Future, SyncFuture} from "../../../tink/core/Future.js"
import {TypedError} from "../../../tink/core/Error.js"
import {Callback} from "../../../tink/core/Callback.js"
import {Path} from "../../../haxe/io/Path.js"
import {Option} from "../../../haxe/ds/Option.js"
import {Register} from "../../../genes/Register.js"
import {Config} from "../../util/Config.js"
import {Config as Config__1} from "../../core/Config.js"
import {ContactsData} from "./ContactsData.js"
import {Updatable} from "../../../coconut/ds/Updatable.js"
import {StringCollection} from "../../../coconut/ds/Collection.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const WorkerData = Register.global("$hxClasses")["dasloop.app.data.WorkerData"] = 
class WorkerData extends Register.inherits() {
	new(__coco_init) {
		this._updatePerformed = Signal.trigger();
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return "" + Config.API_SERVER_URL + "/workers/" + _gthis.id + "/image?v=" + State.get_value(_gthis.__coco_imageRevision);
		}), null);
		AutoObservable.cur = before;
		this.__coco_imageUrl = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return Observable.get_value(_gthis._data.__coco_data);
		}), null);
		AutoObservable.cur = before;
		this.__coco_data = ret;
		this.project = __coco_init.project;
		this.id = __coco_init.id;
		this.remote = __coco_init.remote;
		var _g = __coco_init.initial;
		this.initial = (_g == null) ? Option.None : _g;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new Updatable({"loader": function () {
			return _gthis.remote.v2().workers().ofId(_gthis.id).get();
		}, "updater": function (current, patch) {
			return _gthis.remote.v2().workers().ofId(_gthis.id).update(patch);
		}, "cache": _gthis.initial});
		AutoObservable.cur = before;
		this._data = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new ContactsData({"api": _gthis.remote.v2().workers().ofId(_gthis.id).contacts()});
		AutoObservable.cur = before;
		this.contacts = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new WorkerCertificatesData({"worker": _gthis.id, "remote": _gthis.remote});
		AutoObservable.cur = before;
		this.certificates = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new WorkerDevicesData({"remote": _gthis.remote, "worker": _gthis.id, "project": _gthis.project});
		AutoObservable.cur = before;
		this.devices = ret;
		this.__coco_imageRevision = State._new(new Date().getTime(), null, null, null);
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"project": new ConstObservable(this.project), "id": new ConstObservable(this.id), "remote": new ConstObservable(this.remote), "initial": new ConstObservable(this.initial), "_data": new ConstObservable(this._data), "data": this.__coco_data, "contacts": new ConstObservable(this.contacts), "certificates": new ConstObservable(this.certificates), "devices": new ConstObservable(this.devices), "imageUrl": this.__coco_imageUrl, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	updateAvatar(avatar) {
		var _gthis = this;
		var _g = Path.extension(avatar.name).toLowerCase();
		var c = function (_) {
			_gthis.set_imageRevision(new Date().getTime());
		};
		var c1 = function (o) {
			if (o._hx_index == 0) {
				Callback.invoke(c, o.data);
			};
		};
		return Promise__1.noise(Future.map(Promise__1.next(this.remote.v2().workers().ofId(this.id).uploadImage("image/" + ((_g == "jpg") ? "jpeg" : _g)), function (header) {
			var url = header.url;
			var header1 = header.method;
			var _g = [];
			var _this = header.fields;
			var _g2_current = 0;
			while (_g2_current < _this.length) _g.push(_this[_g2_current++]);
			return FetchResponse.all(Fetch.fetch(url, {"method": header1, "headers": _g, "body": RealSourceTools.idealize(new BlobSource("Worker Avatar " + avatar.name, avatar, 0, 16777216), function (_) {
				return Source.EMPTY;
			})}));
		}), function (v) {
			Callback.invoke(c1, v);
			return v;
		}));
	}
	set_imageRevision(param) {
		this._updatePerformed.handlers.invoke({"imageRevision": param});
		this.__coco_imageRevision.set(param);
		return param;
	}
	static get __name__() {
		return "dasloop.app.data.WorkerData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return WorkerData
	}
}


export const WorkerDevicesData = Register.global("$hxClasses")["dasloop.app.data.WorkerDevicesData"] = 
class WorkerDevicesData extends Register.inherits() {
	new(__coco_init) {
		this._updatePerformed = Signal.trigger();
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.async(function () {
			State.get_value(_gthis.__coco_revision);
			return Promise__1.next(_gthis.remote.v2().projects().ofId(_gthis.project).devices().list(), function (devices) {
				var _g = [];
				var _g1 = 0;
				var _g2 = devices;
				while (_g1 < _g2.length) {
					var v = _g2[_g1];
					++_g1;
					if (v.worker == _gthis.worker) {
						_g.push(v);
					};
				};
				return new SyncFuture(new LazyConst(Outcome.Success(_g)));
			});
		}), null);
		AutoObservable.cur = before;
		this.__coco_data = ret;
		this.project = __coco_init.project;
		this.remote = __coco_init.remote;
		this.worker = __coco_init.worker;
		this.__coco_revision = State._new(0, null, null, null);
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"project": new ConstObservable(this.project), "remote": new ConstObservable(this.remote), "worker": new ConstObservable(this.worker), "revision": this.__coco_revision, "data": this.__coco_data, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	set_revision(param) {
		this._updatePerformed.handlers.invoke({"revision": param});
		this.__coco_revision.set(param);
		return param;
	}
	static get __name__() {
		return "dasloop.app.data.WorkerDevicesData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return WorkerDevicesData
	}
}


export const WorkerCertificatesData = Register.global("$hxClasses")["dasloop.app.data.WorkerCertificatesData"] = 
class WorkerCertificatesData extends Register.inherits() {
	new(__coco_init) {
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return PromisedTools.map(PromisedTools.flatMap(Observable.get_value(_gthis.collection.__coco_list), function (list) {
				var result = new Array(list.length);
				var _g = 0;
				var _g1 = list.length;
				while (_g < _g1) {
					var i = _g++;
					result[i] = Observable.get_value(list[i].__coco_data);
				};
				return PromisedTools.all(result);
			}), Vector.fromArray);
		}), null);
		AutoObservable.cur = before;
		this.__coco_data = ret;
		this.worker = __coco_init.worker;
		this.remote = __coco_init.remote;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new StringCollection({"fetch": function () {
			return _gthis.remote.v2().workers().ofId(_gthis.worker).certificates().list();
		}, "extractKey": function (raw) {
			return raw.id;
		}, "createItem": function (id, init) {
			return new WorkerCertificateData({"id": id, "worker": _gthis.worker, "remote": _gthis.remote, "initial": init});
		}, "updateItem": function (data, raw) {
			data._data.refresh(Option.Some(raw));
		}});
		AutoObservable.cur = before;
		this.collection = ret;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"worker": new ConstObservable(this.worker), "remote": new ConstObservable(this.remote), "collection": new ConstObservable(this.collection), "data": this.__coco_data, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	add(o) {
		var _gthis = this;
		var mime;
		var _g = Path.extension(o.file.name).toLowerCase();
		switch (_g) {
			case "gif":
				mime = "image/gif";
				break
			case "jpeg":case "jpg":
				mime = "image/jpeg";
				break
			case "png":
				mime = "image/png";
				break
			default:
			return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(null, "Unsupported file extension: " + _g, {"fileName": "src/dasloop/app/data/WorkerData.hx", "lineNumber": 103, "className": "dasloop.app.data.WorkerCertificatesData", "methodName": "add"}))));
			
		};
		var c = function (_) {
			_gthis.collection.refresh();
		};
		var c1 = function (o) {
			if (o._hx_index == 0) {
				Callback.invoke(c, o.data);
			};
		};
		return Promise__1.noise(Future.map(Promise__1.next(Promise__1.next(this.remote.v2().workers().ofId(this.worker).certificates().create(o), function (cert) {
			return _gthis.remote.worker_certificates().ofId(cert.id).uploadImage(mime);
		}), function (header) {
			var url = header.url;
			var header1 = header.method;
			var _g = [];
			var _this = header.fields;
			var _g2_current = 0;
			while (_g2_current < _this.length) _g.push(_this[_g2_current++]);
			return FetchResponse.all(Fetch.fetch(url, {"method": header1, "headers": _g, "body": RealSourceTools.idealize(new BlobSource("Certificate Image " + o.file.name, o.file, 0, 16777216), function (_) {
				return Source.EMPTY;
			})}));
		}), function (v) {
			Callback.invoke(c1, v);
			return v;
		}));
	}
	delete(id) {
		var _gthis = this;
		var c = function (_) {
			_gthis.collection.refresh();
		};
		var c1 = function (o) {
			if (o._hx_index == 0) {
				Callback.invoke(c, o.data);
			};
		};
		return Future.map(this.remote.v2().workers().ofId(this.worker).certificates().ofId(id)["delete"](), function (v) {
			Callback.invoke(c1, v);
			return v;
		});
	}
	static get __name__() {
		return "dasloop.app.data.WorkerCertificatesData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return WorkerCertificatesData
	}
}


export const WorkerCertificateData = Register.global("$hxClasses")["dasloop.app.data.WorkerCertificateData"] = 
class WorkerCertificateData extends Register.inherits() {
	new(__coco_init) {
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return Observable.get_value(_gthis._data.__coco_data);
		}), null);
		AutoObservable.cur = before;
		this.__coco_data = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return "" + Config__1.API_SERVER_URL + "/worker_certificates/" + _gthis.id + "/image?v=" + State.get_value(_gthis.__coco_imageRevision);
		}), null);
		AutoObservable.cur = before;
		this.__coco_imageUrl = ret;
		this.id = __coco_init.id;
		this.worker = __coco_init.worker;
		this.remote = __coco_init.remote;
		var _g = __coco_init.initial;
		this.initial = (_g == null) ? Option.None : _g;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new Updatable({"loader": function () {
			return _gthis.remote.v2().workers().ofId(_gthis.worker).certificates().ofId(_gthis.id).get();
		}, "updater": function (current, patch) {
			switch (patch._hx_index) {
				case 0:
					return Promise__1.next(_gthis.remote.v2().workers().ofId(_gthis.worker).certificates().ofId(_gthis.id).update(patch.patch), function (_) {
						return _gthis.remote.v2().workers().ofId(_gthis.worker).certificates().ofId(_gthis.id).get();
					});
					break
				case 1:
					var file = patch.file;
					var v = current;
					return Promise__1.next(Promise__1.next(_gthis.remote.worker_certificates().ofId(_gthis.id).uploadImage(file.type), function (header) {
						var url = header.url;
						var header1 = header.method;
						var _g = [];
						var _this = header.fields;
						var _g1_current = 0;
						while (_g1_current < _this.length) _g.push(_this[_g1_current++]);
						return FetchResponse.all(Fetch.fetch(url, {"method": header1, "headers": _g, "body": RealSourceTools.idealize(new BlobSource("Certificate Image " + file.name, file, 0, 16777216), function (_) {
							return Source.EMPTY;
						})}));
					}), function (_) {
						return new SyncFuture(new LazyConst(Outcome.Success(v)));
					});
					break
				
			};
		}, "cache": _gthis.initial});
		AutoObservable.cur = before;
		this._data = ret;
		this.__coco_imageRevision = State._new(new Date().getTime(), null, null, null);
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"id": new ConstObservable(this.id), "worker": new ConstObservable(this.worker), "remote": new ConstObservable(this.remote), "initial": new ConstObservable(this.initial), "_data": new ConstObservable(this._data), "imageUrl": this.__coco_imageUrl, "data": this.__coco_data, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	static get __name__() {
		return "dasloop.app.data.WorkerCertificateData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return WorkerCertificateData
	}
}


export const WorkerCertificatePatch = 
Register.global("$hxEnums")["dasloop.app.data.WorkerCertificatePatch"] = 
{
	__ename__: "dasloop.app.data.WorkerCertificatePatch",
	
	Data: Object.assign((patch) => ({_hx_index: 0, __enum__: "dasloop.app.data.WorkerCertificatePatch", "patch": patch}), {_hx_name: "Data", __params__: ["patch"]}),
	Image: Object.assign((file) => ({_hx_index: 1, __enum__: "dasloop.app.data.WorkerCertificatePatch", "file": file}), {_hx_name: "Image", __params__: ["file"]})
}
WorkerCertificatePatch.__constructs__ = [WorkerCertificatePatch.Data, WorkerCertificatePatch.Image]
WorkerCertificatePatch.__empty_constructs__ = []
