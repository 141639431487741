import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const TaskDefinition = 
Register.global("$hxEnums")["dasloop.types.task.TaskDefinition"] = 
{
	__ename__: "dasloop.types.task.TaskDefinition",
	
	UpdateFirmware: Object.assign((params) => ({_hx_index: 0, __enum__: "dasloop.types.task.TaskDefinition", "params": params}), {_hx_name: "UpdateFirmware", __params__: ["params"]}),
	UpdateAudio: Object.assign((params) => ({_hx_index: 1, __enum__: "dasloop.types.task.TaskDefinition", "params": params}), {_hx_name: "UpdateAudio", __params__: ["params"]}),
	UpdateConfig: Object.assign((params) => ({_hx_index: 2, __enum__: "dasloop.types.task.TaskDefinition", "params": params}), {_hx_name: "UpdateConfig", __params__: ["params"]}),
	LocateDevice: Object.assign((params) => ({_hx_index: 3, __enum__: "dasloop.types.task.TaskDefinition", "params": params}), {_hx_name: "LocateDevice", __params__: ["params"]}),
	PlayAudio: Object.assign((params) => ({_hx_index: 4, __enum__: "dasloop.types.task.TaskDefinition", "params": params}), {_hx_name: "PlayAudio", __params__: ["params"]}),
	GenerateAlarm: Object.assign((params) => ({_hx_index: 5, __enum__: "dasloop.types.task.TaskDefinition", "params": params}), {_hx_name: "GenerateAlarm", __params__: ["params"]}),
	GetConfig: {_hx_name: "GetConfig", _hx_index: 6, __enum__: "dasloop.types.task.TaskDefinition"},
	GetHardwareReport: {_hx_name: "GetHardwareReport", _hx_index: 7, __enum__: "dasloop.types.task.TaskDefinition"},
	GetAudio: {_hx_name: "GetAudio", _hx_index: 8, __enum__: "dasloop.types.task.TaskDefinition"}
}
TaskDefinition.__constructs__ = [TaskDefinition.UpdateFirmware, TaskDefinition.UpdateAudio, TaskDefinition.UpdateConfig, TaskDefinition.LocateDevice, TaskDefinition.PlayAudio, TaskDefinition.GenerateAlarm, TaskDefinition.GetConfig, TaskDefinition.GetHardwareReport, TaskDefinition.GetAudio]
TaskDefinition.__empty_constructs__ = [TaskDefinition.GetConfig, TaskDefinition.GetHardwareReport, TaskDefinition.GetAudio]
