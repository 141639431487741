import {Remote46} from "./Remote46.js"
import {RemoteBase, RemoteEndpoint} from "./Remote.js"
import {Portion} from "../../url/Portion.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote45 = Register.global("$hxClasses")["tink.web.proxy.Remote45"] = 
class Remote45 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	ofId(id) {
		return new Remote46(this.client, RemoteEndpoint.sub(this.endpoint, {"path": [Portion.ofString(id)], "query": [], "headers": [].concat([])}));
	}
	static get __name__() {
		return "tink.web.proxy.Remote45"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote45
	}
}

