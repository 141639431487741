import {RemoteBase, RemoteEndpoint, ResponseReader} from "./Remote.js"
import {Single} from "../../streams/Stream.js"
import {Builder7} from "../../querystring/Builder7.js"
import {Writer71} from "../../json/Writer71.js"
import {Writer59} from "../../json/Writer59.js"
import {Writer58} from "../../json/Writer58.js"
import {Writer57} from "../../json/Writer57.js"
import {Writer56} from "../../json/Writer56.js"
import {Writer55} from "../../json/Writer55.js"
import {Parser71} from "../../json/Parser71.js"
import {Parser31} from "../../json/Parser31.js"
import {Parser30} from "../../json/Parser30.js"
import {RealSourceTools} from "../../io/Source.js"
import {HeaderField, HeaderValue} from "../../http/Header.js"
import {Promise as Promise__1} from "../../core/Promise.js"
import {Outcome} from "../../core/Outcome.js"
import {LazyConst} from "../../core/Lazy.js"
import {SyncFuture} from "../../core/Future.js"
import {TypedError} from "../../core/Error.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote41 = Register.global("$hxClasses")["tink.web.proxy.Remote41"] = 
class Remote41 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	get() {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [], "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser31())));
	}
	locations(query) {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["locations"], "query": [].concat(new Builder7().stringify({"_155": query.from, "_156": query.limit, "_157": query.to})), "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pf))(new Parser30())));
	}
	dismissAlert(date, type) {
		var __body__ = ByteChunk.of(Bytes.ofString(new Writer71().wc({"_158": date, "_159": type})));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["alerts", "dismissed"], "query": [], "headers": [new HeaderField("content-type", "application/json"), new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength()))].concat([])}), this.client, "POST", new Single(new LazyConst(__body__)), function (header, body) {
			if (header.statusCode >= 400) {
				return Promise__1.next(RealSourceTools.all(body), function (chunk) {
					return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(header.statusCode, chunk.toString(), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_web/0.3.0/github/5f481ef34ae04a352700ac2c9682e6c96c27bbf5/src/tink/web/macros/Proxify.hx", "lineNumber": 173, "className": "tink.web.proxy.Remote41", "methodName": "dismissAlert"}))));
				});
			} else {
				return Promise__1.NOISE;
			};
		});
	}
	setHeartbeatEnabled(enabled) {
		var __body__ = ByteChunk.of(Bytes.ofString(new Writer59().wc({"_160": enabled})));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["heartbeat_enabled"], "query": [], "headers": [new HeaderField("content-type", "application/json"), new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength()))].concat([])}), this.client, "POST", new Single(new LazyConst(__body__)), function (header, body) {
			if (header.statusCode >= 400) {
				return Promise__1.next(RealSourceTools.all(body), function (chunk) {
					return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(header.statusCode, chunk.toString(), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_web/0.3.0/github/5f481ef34ae04a352700ac2c9682e6c96c27bbf5/src/tink/web/macros/Proxify.hx", "lineNumber": 173, "className": "tink.web.proxy.Remote41", "methodName": "setHeartbeatEnabled"}))));
				});
			} else {
				return Promise__1.NOISE;
			};
		});
	}
	setProject(id) {
		var __body__ = ByteChunk.of(Bytes.ofString(new Writer58().wc({"_161": id})));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["project"], "query": [], "headers": [new HeaderField("content-type", "application/json"), new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength()))].concat([])}), this.client, "POST", new Single(new LazyConst(__body__)), function (header, body) {
			if (header.statusCode >= 400) {
				return Promise__1.next(RealSourceTools.all(body), function (chunk) {
					return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(header.statusCode, chunk.toString(), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_web/0.3.0/github/5f481ef34ae04a352700ac2c9682e6c96c27bbf5/src/tink/web/macros/Proxify.hx", "lineNumber": 173, "className": "tink.web.proxy.Remote41", "methodName": "setProject"}))));
				});
			} else {
				return Promise__1.NOISE;
			};
		});
	}
	setWorker(id) {
		var __body__ = ByteChunk.of(Bytes.ofString(new Writer57().wc({"_162": id})));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["worker"], "query": [], "headers": [new HeaderField("content-type", "application/json"), new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength()))].concat([])}), this.client, "POST", new Single(new LazyConst(__body__)), function (header, body) {
			if (header.statusCode >= 400) {
				return Promise__1.next(RealSourceTools.all(body), function (chunk) {
					return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(header.statusCode, chunk.toString(), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_web/0.3.0/github/5f481ef34ae04a352700ac2c9682e6c96c27bbf5/src/tink/web/macros/Proxify.hx", "lineNumber": 173, "className": "tink.web.proxy.Remote41", "methodName": "setWorker"}))));
				});
			} else {
				return Promise__1.NOISE;
			};
		});
	}
	unsetWorker() {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["worker"], "query": [], "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength()))].concat([])}), this.client, "DELETE", new Single(new LazyConst(__body__)), function (header, body) {
			if (header.statusCode >= 400) {
				return Promise__1.next(RealSourceTools.all(body), function (chunk) {
					return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(header.statusCode, chunk.toString(), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_web/0.3.0/github/5f481ef34ae04a352700ac2c9682e6c96c27bbf5/src/tink/web/macros/Proxify.hx", "lineNumber": 173, "className": "tink.web.proxy.Remote41", "methodName": "unsetWorker"}))));
				});
			} else {
				return Promise__1.NOISE;
			};
		});
	}
	setPlant(id) {
		var __body__ = ByteChunk.of(Bytes.ofString(new Writer56().wc({"_163": id})));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["plant"], "query": [], "headers": [new HeaderField("content-type", "application/json"), new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength()))].concat([])}), this.client, "POST", new Single(new LazyConst(__body__)), function (header, body) {
			if (header.statusCode >= 400) {
				return Promise__1.next(RealSourceTools.all(body), function (chunk) {
					return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(header.statusCode, chunk.toString(), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_web/0.3.0/github/5f481ef34ae04a352700ac2c9682e6c96c27bbf5/src/tink/web/macros/Proxify.hx", "lineNumber": 173, "className": "tink.web.proxy.Remote41", "methodName": "setPlant"}))));
				});
			} else {
				return Promise__1.NOISE;
			};
		});
	}
	unsetPlant() {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["plant"], "query": [], "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength()))].concat([])}), this.client, "DELETE", new Single(new LazyConst(__body__)), function (header, body) {
			if (header.statusCode >= 400) {
				return Promise__1.next(RealSourceTools.all(body), function (chunk) {
					return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(header.statusCode, chunk.toString(), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_web/0.3.0/github/5f481ef34ae04a352700ac2c9682e6c96c27bbf5/src/tink/web/macros/Proxify.hx", "lineNumber": 173, "className": "tink.web.proxy.Remote41", "methodName": "unsetPlant"}))));
				});
			} else {
				return Promise__1.NOISE;
			};
		});
	}
	setEquipment(id) {
		var __body__ = ByteChunk.of(Bytes.ofString(new Writer55().wc({"_164": id})));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["equipment"], "query": [], "headers": [new HeaderField("content-type", "application/json"), new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength()))].concat([])}), this.client, "POST", new Single(new LazyConst(__body__)), function (header, body) {
			if (header.statusCode >= 400) {
				return Promise__1.next(RealSourceTools.all(body), function (chunk) {
					return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(header.statusCode, chunk.toString(), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_web/0.3.0/github/5f481ef34ae04a352700ac2c9682e6c96c27bbf5/src/tink/web/macros/Proxify.hx", "lineNumber": 173, "className": "tink.web.proxy.Remote41", "methodName": "setEquipment"}))));
				});
			} else {
				return Promise__1.NOISE;
			};
		});
	}
	unsetEquipment() {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["equipment"], "query": [], "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength()))].concat([])}), this.client, "DELETE", new Single(new LazyConst(__body__)), function (header, body) {
			if (header.statusCode >= 400) {
				return Promise__1.next(RealSourceTools.all(body), function (chunk) {
					return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(header.statusCode, chunk.toString(), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_web/0.3.0/github/5f481ef34ae04a352700ac2c9682e6c96c27bbf5/src/tink/web/macros/Proxify.hx", "lineNumber": 173, "className": "tink.web.proxy.Remote41", "methodName": "unsetEquipment"}))));
				});
			} else {
				return Promise__1.NOISE;
			};
		});
	}
	getLiveCallCredentials() {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["live_call_credentials"], "query": [], "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pe))(new Parser71())));
	}
	static get __name__() {
		return "tink.web.proxy.Remote41"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote41
	}
}

