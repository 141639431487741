import {Writer4} from "./Writer4.js"
import {Writer3} from "./Writer3.js"
import {BasicParser, JsonString} from "./Parser.js"
import {TypedError} from "../core/Error.js"
import {StringMap} from "../../haxe/ds/StringMap.js"
import {Exception} from "../../haxe/Exception.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Parser5 = Register.global("$hxClasses")["tink.json.Parser5"] = 
class Parser5 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	pb(source) {
		if (this.e.length > 0) {
			this.e = [];
		};
		this.g(source);
		this.F("[");
		var __ret = new StringMap();
		if (!this.E("]")) {
			while (true) {
				this.F("[");
				var v = JsonString.s(this.m());
				var _e0;
				switch (v) {
					case "announcements":case "aoa-locators":case "areas":case "audios":case "beacons":case "bims":case "devices":case "equipments":case "floor-plans":case "info":case "logs":case "members":case "plants":case "tasks":case "workers":
						_e0 = v;
						break
					default:
					throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer3().wb(["info", "beacons", "devices", "equipments", "workers", "plants", "floor-plans", "bims", "areas", "announcements", "members", "logs", "tasks", "audios", "aoa-locators"]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 469, "className": "tink.json.Parser5", "methodName": "parse"}));
					
				};
				this.F(",");
				this.F("[");
				var __ret1 = [];
				if (!this.E("]")) {
					while (true) {
						var v1 = JsonString.s(this.m());
						var tmp;
						switch (v1) {
							case "create":case "read":case "update":
								tmp = v1;
								break
							default:
							throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v1 + ". Accepted values are: " + new Writer4().wb(["create", "read", "update"]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 469, "className": "tink.json.Parser5", "methodName": "parse"}));
							
						};
						__ret1.push(tmp);
						if (!this.E(",")) {
							break;
						};
					};
					this.F("]");
				};
				__ret.inst.set(_e0, __ret1);
				this.F("]");
				if (!this.E(",")) {
					break;
				};
			};
			this.F("]");
		};
		var ret = __ret;
		var _g = 0;
		var _g1 = this.e;
		while (_g < _g1.length) _g1[_g++]();
		if (this.e.length > 0) {
			this.e = [];
		};
		return ret;
	}
	pc(source) {
		var _gthis = this;
		return TypedError.catchExceptions(function () {
			var ret = _gthis.pb(source);
			_gthis.h();
			if (_gthis.c < _gthis.d) {
				_gthis.D("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser5", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser5"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser5
	}
}

