import {Register} from "./genes/Register.js"
import {Declarations} from "./cix/css/Declarations.js"

const $global = Register.$global

export const Cix3bbb14088371546da25f00113315cbf6aae79e68f763a53873241420a86c40b7 = Register.global("$hxClasses")["Cix3bbb14088371546da25f00113315cbf6aae79e68f763a53873241420a86c40b7"] = 
class Cix3bbb14088371546da25f00113315cbf6aae79e68f763a53873241420a86c40b7 extends Register.inherits() {
	new() {
		this.css = Declarations.add("cx5c", function () {
			return ".cx5c {\n  display: flex;\n  flex-wrap: wrap;\n  max-width: 100vw;\n  column-gap: 0.5em;\n  row-gap: 0.5em;\n}\n\n.cx5c > div {\n  flex: 1;\n}\n\n.cx5c > div.right {\n  text-align: right;\n}\n\n.cx5c > div.left {\n  text-align: left;\n}";
		});
	}
	static get __name__() {
		return "Cix3bbb14088371546da25f00113315cbf6aae79e68f763a53873241420a86c40b7"
	}
	get __class__() {
		return Cix3bbb14088371546da25f00113315cbf6aae79e68f763a53873241420a86c40b7
	}
}


Cix3bbb14088371546da25f00113315cbf6aae79e68f763a53873241420a86c40b7.inst = new Cix3bbb14088371546da25f00113315cbf6aae79e68f763a53873241420a86c40b7()