import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {Observable, ConstObservable} from "../../../tink/state/Observable.js"
import {PromisesContainer} from "../../../tink/core/ext/Promises.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Promise as Promise__1} from "../../../tink/core/Promise.js"
import {Outcome} from "../../../tink/core/Outcome.js"
import {LazyConst} from "../../../tink/core/Lazy.js"
import {SyncFuture, Future} from "../../../tink/core/Future.js"
import {Option} from "../../../haxe/ds/Option.js"
import {Register} from "../../../genes/Register.js"
import {DaspowerTools} from "./DaspowerTools.js"
import {Updatable} from "../../../coconut/ds/Updatable.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const EquipmentData = Register.global("$hxClasses")["dasloop.app.data.EquipmentData"] = 
class EquipmentData extends Register.inherits() {
	new(__coco_init) {
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.async(function () {
			return _gthis.remote.v2().equipments().ofId(_gthis.id).devices();
		}), null);
		AutoObservable.cur = before;
		this.__coco_devices = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return Observable.get_value(_gthis._data.__coco_data);
		}), null);
		AutoObservable.cur = before;
		this.__coco_data = ret;
		this.id = __coco_init.id;
		this.remote = __coco_init.remote;
		var _g = __coco_init.initial;
		this.initial = (_g == null) ? Option.None : _g;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new Updatable({"loader": function () {
			return _gthis.remote.v2().equipments().ofId(_gthis.id).get();
		}, "updater": function (current, patch) {
			return _gthis.remote.v2().equipments().ofId(_gthis.id).update(patch);
		}, "cache": _gthis.initial});
		AutoObservable.cur = before;
		this._data = ret;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"id": new ConstObservable(this.id), "remote": new ConstObservable(this.remote), "initial": new ConstObservable(this.initial), "_data": new ConstObservable(this._data), "data": this.__coco_data, "devices": this.__coco_devices, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	getPower(from, to, aggregate) {
		var timezone = -new Date().getTimezoneOffset() * 60;
		return Promise__1.next(this.remote.v2().equipments().ofId(this.id).statistics().getEnergyByTime({"from": from, "to": to, "resolution": aggregate, "timezone": timezone}), function (data) {
			return new SyncFuture(new LazyConst(Outcome.Success(DaspowerTools.toPowerChartData(from, to, aggregate, timezone, data))));
		});
	}
	getTemperature(from, to, aggregate) {
		var _gthis = this;
		var this1 = -new Date().getTimezoneOffset() * 60;
		return Promise__1.next(this.remote.v2().equipments().ofId(this.id).statistics().getTemperature({"from": from, "to": to, "resolution": aggregate, "timezone": this1}), function (stats) {
			var dates = stats.a;
			var data = stats.b.inst.get(_gthis.id);
			var _g = [];
			var _g1 = 0;
			var _g2 = dates.length;
			while (_g1 < _g2) {
				var i = _g1++;
				_g.push({"date": dates[i], "temperature": data[i]});
			};
			return new SyncFuture(new LazyConst(Outcome.Success(_g)));
		});
	}
	getStatistics(from, to, aggregate) {
		var __obj_temperature;
		var __obj_power = this.getPower(from, to, aggregate);
		__obj_temperature = this.getTemperature(from, to, aggregate);
		return Future.irreversible(function (cb) {
			var __ctx = new PromisesContainer(cb, 2);
			__obj_power.handle(__ctx.handle(function (r, v) {
				r.power = v;
			}));
			__obj_temperature.handle(__ctx.handle(function (r, v) {
				r.temperature = v;
			}));
		});
	}
	static get __name__() {
		return "dasloop.app.data.EquipmentData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return EquipmentData
	}
}

