import {Register} from "./genes/Register.js"

const $global = Register.$global

/**
The `Lambda` class is a collection of methods to support functional
programming. It is ideally used with `using Lambda` and then acts as an
extension to Iterable types.

On static platforms, working with the Iterable structure might be slower
than performing the operations directly on known types, such as Array and
List.

If the first argument to any of the methods is null, the result is
unspecified.

@see https://haxe.org/manual/std-Lambda.html
*/
export const Lambda = Register.global("$hxClasses")["Lambda"] = 
class Lambda {
	
	/**
	Tells if `it` contains an element for which `f` is true.
	
	This function returns true as soon as an element is found for which a
	call to `f` returns true.
	
	If no such element is found, the result is false.
	
	If `f` is null, the result is unspecified.
	*/
	static exists(it, f) {
		var x = Register.iter(it);
		while (x.hasNext()) if (f(x.next())) {
			return true;
		};
		return false;
	}
	
	/**
	Functional fold on Iterable `it`, using function `f` with start argument
	`first`.
	
	If `it` has no elements, the result is `first`.
	
	Otherwise the first element of `it` is passed to `f` alongside `first`.
	The result of that call is then passed to `f` with the next element of
	`it`, and so on until `it` has no more elements.
	
	If `it` or `f` are null, the result is unspecified.
	*/
	static fold(it, f, first) {
		var x = Register.iter(it);
		while (x.hasNext()) first = f(x.next(), first);
		return first;
	}
	
	/**
	Returns the number of elements in `it` for which `pred` is true, or the
	total number of elements in `it` if `pred` is null.
	
	This function traverses all elements.
	*/
	static count(it, pred) {
		var n = 0;
		if (pred == null) {
			var _ = Register.iter(it);
			while (_.hasNext()) {
				_.next();
				++n;
			};
		} else {
			var x = Register.iter(it);
			while (x.hasNext()) if (pred(x.next())) {
				++n;
			};
		};
		return n;
	}
	
	/**
	Returns the first element of `it` for which `f` is true.
	
	This function returns as soon as an element is found for which a call to
	`f` returns true.
	
	If no such element is found, the result is null.
	
	If `f` is null, the result is unspecified.
	*/
	static find(it, f) {
		var v = Register.iter(it);
		while (v.hasNext()) {
			var v1 = v.next();
			if (f(v1)) {
				return v1;
			};
		};
		return null;
	}
	
	/**
	Returns the index of the first element of `it` for which `f` is true.
	
	This function returns as soon as an element is found for which a call to
	`f` returns true.
	
	If no such element is found, the result is -1.
	
	If `f` is null, the result is unspecified.
	*/
	static findIndex(it, f) {
		var i = 0;
		var v = Register.iter(it);
		while (v.hasNext()) {
			if (f(v.next())) {
				return i;
			};
			++i;
		};
		return -1;
	}
	static get __name__() {
		return "Lambda"
	}
	get __class__() {
		return Lambda
	}
}

