import {ObjectMap} from "../../haxe/ds/ObjectMap.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Annex = Register.global("$hxClasses")["tink.core.Annex"] = 
class Annex extends Register.inherits() {
	new(target) {
		this.target = target;
		this.registry = new ObjectMap();
	}
	static get __name__() {
		return "tink.core.Annex"
	}
	get __class__() {
		return Annex
	}
}

