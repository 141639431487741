import {Transform} from "../../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../../tink/state/State.js"
import {Scheduler} from "../../../../tink/state/Scheduler.js"
import {ConstObservable, Observable} from "../../../../tink/state/Observable.js"
import {Signal} from "../../../../tink/core/Signal.js"
import {OutcomeTools} from "../../../../tink/core/Outcome.js"
import {LazyConst} from "../../../../tink/core/Lazy.js"
import {Register} from "../../../../genes/Register.js"
import {DateRangeModel} from "../../data/DateRangeModel.js"
import {RangeSlider} from "../../RangeSlider.js"
import {DatePicker2} from "../../DatePicker2.js"
import {DateTools} from "../../../types/util/DateTools.js"
import {Slot} from "../../../../coconut/ui/internal/Slot.js"
import {View} from "../../../../coconut/react/View.js"
import {Html} from "../../../../coconut/react/Html.js"
import {Annex} from "../../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../../coconut/data/Model.js"
import {DateTools as DateTools__1} from "../../../../DateTools.js"
import {Cix8a7a536db507182d422b08be93b69a2740cbb68222ab3d0141dae7690c5cc619} from "../../../../Cix8a7a536db507182d422b08be93b69a2740cbb68222ab3d0141dae7690c5cc619.js"
import {Cix3bbb14088371546da25f00113315cbf6aae79e68f763a53873241420a86c40b7} from "../../../../Cix3bbb14088371546da25f00113315cbf6aae79e68f763a53873241420a86c40b7.js"

const $global = Register.$global

export const DoubleRangeModel = Register.global("$hxClasses")["dasloop.ui.map.widget.DoubleRangeModel"] = 
class DoubleRangeModel extends Register.inherits() {
	new() {
		var now = new Date();
		var __coco_init_outer = new DateRangeModel(1, new Date(now.getTime() + (-86400000.)), now);
		var __coco_init_inner = new InnerRangeModel(new Date(now.getTime() + (-28800000.)), now);
		this.outer = __coco_init_outer;
		this.inner = __coco_init_inner;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"outer": new ConstObservable(this.outer), "inner": new ConstObservable(this.inner), "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	static get __name__() {
		return "dasloop.ui.map.widget.DoubleRangeModel"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return DoubleRangeModel
	}
}


export const InnerRangeModel = Register.global("$hxClasses")["dasloop.ui.map.widget.InnerRangeModel"] = 
class InnerRangeModel extends Register.inherits() {
	new(from, to) {
		this._updatePerformed = Signal.trigger();
		this.__coco_from = State._new(from, null, null, null);
		this.__coco_to = State._new(to, null, null, null);
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"from": this.__coco_from, "to": this.__coco_to, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	set_from(param) {
		this._updatePerformed.handlers.invoke({"from": param});
		this.__coco_from.set(param);
		return param;
	}
	set_to(param) {
		this._updatePerformed.handlers.invoke({"to": param});
		this.__coco_to.set(param);
		return param;
	}
	static get __name__() {
		return "dasloop.ui.map.widget.InnerRangeModel"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return InnerRangeModel
	}
}


export const DoubleRangeSelector = Register.global("$hxClasses")["dasloop.ui.map.widget.DoubleRangeSelector"] = 
class DoubleRangeSelector extends Register.inherits(View) {
	new(__coco_data_) {
		var _gthis = this;
		this.__coco_range = new Slot(this, null, new AutoObservable(Computation.sync(function () {
			return new DoubleRangeModel();
		}), null));
		this.__initAttributes(__coco_data_);
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, function (firstTime) {
			if (firstTime) {
				_gthis.viewDidMount();
			};
		});
	}
	__coco_render() {
		var _gthis = this;
		var __r = [];
		var __r1 = [];
		var tmp = new ConstObservable("left");
		var tmp1 = new AutoObservable(Computation.sync(function () {
			return DateTools.dayStart(new Date(new Date().getTime() + (-604800000.)));
		}), null);
		var tmp2 = new AutoObservable(Computation.sync(function () {
			return DateTools.dayEnd(new Date());
		}), null);
		var tmp3 = new AutoObservable(Computation.sync(function () {
			return Observable.get_value(_gthis.__coco_range).outer.from;
		}), null);
		__r1.push(DatePicker2.fromHxx({}, {"className": tmp, "minDate": tmp1, "maxDate": tmp2, "date": tmp3}));
		var tmp = new ConstObservable("right");
		var tmp1 = new AutoObservable(Computation.sync(function () {
			return DateTools.dayStart(new Date(new Date().getTime() + (-604800000.)));
		}), null);
		var tmp2 = new AutoObservable(Computation.sync(function () {
			return DateTools.dayEnd(new Date());
		}), null);
		var tmp3 = new AutoObservable(Computation.sync(function () {
			return Observable.get_value(_gthis.__coco_range).outer.to;
		}), null);
		__r1.push(DatePicker2.fromHxx({}, {"className": tmp, "minDate": tmp1, "maxDate": tmp2, "date": tmp3}));
		__r.push(Html.h("div", {"className": DoubleRangeSelector.DATES_CONTAINER}, __r1));
		var _g = Observable.get_value(Observable.get_value(this.__coco_range).outer.__coco_output);
		switch (_g._hx_index) {
			case 0:
				var _g1 = _g.data;
				var min = _g1.from.getTime();
				var max = _g1.to.getTime();
				var children = new ConstObservable("teal small");
				var children1 = new ConstObservable(60000);
				var children2 = new AutoObservable(Computation.sync(function () {
					return min;
				}), null);
				var children3 = new AutoObservable(Computation.sync(function () {
					return max;
				}), null);
				var children4 = State.compound(new AutoObservable(Computation.sync(function () {
					return State.get_value(Observable.get_value(_gthis.__coco_range).inner.__coco_from).getTime();
				}), null), function (v) {
					Observable.get_value(_gthis.__coco_range).inner.set_from(new Date(v));
				});
				var children5 = new AutoObservable(Computation.sync(function () {
					return State.get_value(Observable.get_value(_gthis.__coco_range).inner.__coco_to).getTime();
				}), null);
				var children6 = [RangeSlider.fromHxx({}, {"className": children, "step": children1, "min": children2, "max": children3, "lo": children4, "hi": State.compound(children5, function (v) {
					Observable.get_value(_gthis.__coco_range).inner.set_to(new Date(v));
				})})];
				__r.push(Html.h("div", {}, children6));
				var __r1 = [];
				var children = [DoubleRangeSelector.shortDate(State.get_value(Observable.get_value(this.__coco_range).inner.__coco_from))];
				__r1.push(Html.h("div", {"className": "datetime"}, children));
				__r1.push(Html.h("div", {"className": "separator"}, [" - "]));
				var children = [DoubleRangeSelector.shortDate(State.get_value(Observable.get_value(this.__coco_range).inner.__coco_to))];
				__r1.push(Html.h("div", {"className": "datetime"}, children));
				__r.push(Html.h("div", {"className": DoubleRangeSelector.TEXT_CONTAINER}, __r1));
				break
			case 1:
				break
			
		};
		return View.createFragment({}, __r);
	}
	viewDidMount() {
		var _gthis = this;
		var c = Observable.bind(new AutoObservable(Computation.sync(function () {
			return {"min": OutcomeTools.or(Observable.get_value(Observable.get_value(_gthis.__coco_range).outer.from.__coco_output), new LazyConst(new Date(new Date().getTime() + (-604800000.)))), "max": OutcomeTools.or(Observable.get_value(Observable.get_value(_gthis.__coco_range).outer.to.__coco_output), new LazyConst(new Date()))};
		}), null), function (o) {
			Scheduler.atomically(function () {
				if (State.get_value(Observable.get_value(_gthis.__coco_range).inner.__coco_from).getTime() < o.min.getTime()) {
					Observable.get_value(_gthis.__coco_range).inner.set_from(o.min);
				};
				if (State.get_value(Observable.get_value(_gthis.__coco_range).inner.__coco_to).getTime() > o.max.getTime()) {
					Observable.get_value(_gthis.__coco_range).inner.set_to(o.max);
				};
			});
		});
		this.__bu.push(c);
	}
	__initAttributes(attributes) {
		this.__coco_range.setData(attributes.range);
	}
	static shortDate(date) {
		return "" + date.getDate() + "/" + (date.getMonth() + 1) + " " + DateTools__1.format(date, "%H:%M");
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": DoubleRangeSelector, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "dasloop.ui.map.widget.DoubleRangeSelector"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return DoubleRangeSelector
	}
}


DoubleRangeSelector.DATES_CONTAINER = Cix3bbb14088371546da25f00113315cbf6aae79e68f763a53873241420a86c40b7.inst.css
DoubleRangeSelector.TEXT_CONTAINER = Cix8a7a536db507182d422b08be93b69a2740cbb68222ab3d0141dae7690c5cc619.inst.css