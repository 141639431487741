import {Exception} from "../haxe/Exception.js"
import {Register} from "../genes/Register.js"

const $global = Register.$global

export const Browser = Register.global("$hxClasses")["js.Browser"] = 
class Browser {
	static get_supported() {
		if (typeof(window) != "undefined" && typeof(window.location) != "undefined") {
			return typeof(window.location.protocol) == "string";
		} else {
			return false;
		};
	}
	
	/**
	* Creates an XMLHttpRequest, with a fallback to ActiveXObject for ancient versions of Internet
	* Explorer.
	*/
	static createXMLHttpRequest() {
		if (typeof XMLHttpRequest != "undefined") {
			return new XMLHttpRequest();
		};
		if (typeof ActiveXObject != "undefined") {
			return new "ActiveXObject"("Microsoft.XMLHTTP");
		};
		throw Exception.thrown("Unable to create XMLHttpRequest object.");
	}
	static get __name__() {
		return "js.Browser"
	}
	get __class__() {
		return Browser
	}
}

