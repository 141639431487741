import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {PromisedTools} from "../../../tink/state/Promised.js"
import {Observable, ConstObservable} from "../../../tink/state/Observable.js"
import {Vector} from "../../../tink/pure/Vector.js"
import {BlobSource} from "../../../tink/io/js/BlobSource.js"
import {RealSourceTools, Source} from "../../../tink/io/Source.js"
import {FetchResponse, Fetch} from "../../../tink/http/Fetch.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Promise as Promise__1} from "../../../tink/core/Promise.js"
import {Outcome} from "../../../tink/core/Outcome.js"
import {LazyConst} from "../../../tink/core/Lazy.js"
import {SyncFuture} from "../../../tink/core/Future.js"
import {TypedError} from "../../../tink/core/Error.js"
import {Path} from "../../../haxe/io/Path.js"
import {Option} from "../../../haxe/ds/Option.js"
import {Register} from "../../../genes/Register.js"
import {PlantData} from "./PlantData.js"
import {StringCollection} from "../../../coconut/ds/Collection.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const PlantsData = Register.global("$hxClasses")["dasloop.app.data.PlantsData"] = 
class PlantsData extends Register.inherits() {
	new(__coco_init) {
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return PromisedTools.map(PromisedTools.flatMap(Observable.get_value(_gthis.collection.__coco_list), function (list) {
				var result = new Array(list.length);
				var _g = 0;
				var _g1 = list.length;
				while (_g < _g1) {
					var i = _g++;
					result[i] = Observable.get_value(list[i].__coco_data);
				};
				return PromisedTools.all(result);
			}), Vector.fromArray);
		}), null);
		AutoObservable.cur = before;
		this.__coco_data = ret;
		this.project = __coco_init.project;
		this.remote = __coco_init.remote;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new StringCollection({"fetch": function () {
			return _gthis.remote.v2().projects().ofId(_gthis.project).plants().list();
		}, "extractKey": function (raw) {
			return raw.id;
		}, "createItem": function (id, init) {
			return new PlantData({"id": id, "remote": _gthis.remote, "initial": init});
		}, "updateItem": function (data, raw) {
			data._data.refresh(Option.Some(raw));
		}});
		AutoObservable.cur = before;
		this.collection = ret;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"project": new ConstObservable(this.project), "remote": new ConstObservable(this.remote), "collection": new ConstObservable(this.collection), "data": this.__coco_data, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	create(data) {
		var _gthis = this;
		var mime;
		var _g = Path.extension(data.image.name).toLowerCase();
		switch (_g) {
			case "gif":
				mime = "image/gif";
				break
			case "jpeg":case "jpg":
				mime = "image/jpeg";
				break
			case "png":
				mime = "image/png";
				break
			default:
			return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(null, "Unsupported file extension: " + _g, {"fileName": "src/dasloop/app/data/PlantsData.hx", "lineNumber": 36, "className": "dasloop.app.data.PlantsData", "methodName": "create"}))));
			
		};
		return Promise__1.noise(Promise__1.next(Promise__1.next(this.remote.v2().projects().ofId(this.project).plants().create(data), function (plant) {
			return _gthis.remote.v2().plants().ofId(plant.id).uploadImage(mime);
		}), function (header) {
			var url = header.url;
			var header1 = header.method;
			var _g = [];
			var _this = header.fields;
			var _g2_current = 0;
			while (_g2_current < _this.length) _g.push(_this[_g2_current++]);
			return FetchResponse.all(Fetch.fetch(url, {"method": header1, "headers": _g, "body": RealSourceTools.idealize(new BlobSource("Plant Image " + data.image.name, data.image, 0, 16777216), function (_) {
				return Source.EMPTY;
			})}));
		}));
	}
	static get __name__() {
		return "dasloop.app.data.PlantsData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return PlantsData
	}
}

