import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {Observable, ConstObservable} from "../../../tink/state/Observable.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Register} from "../../../genes/Register.js"
import {WeeklyData} from "./WeeklyData.js"
import {UwbTagsData} from "./UwbTagsData.js"
import {ProjectsData} from "./ProjectsData.js"
import {LanguageData} from "./LanguageData.js"
import {StringDict} from "../../../coconut/ds/Dict.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const AppData = Register.global("$hxClasses")["dasloop.app.data.AppData"] = 
class AppData extends Register.inherits() {
	new(__coco_init) {
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.async(function () {
			return _gthis.remote.me().summary();
		}), null);
		AutoObservable.cur = before;
		this.__coco_userSummary = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return new UwbTagsData({"remote": _gthis.remote});
		}), null);
		AutoObservable.cur = before;
		this.__coco_uwbTags = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return new ProjectsData({"remote": _gthis.remote, "now": new AutoObservable(Computation.sync(function () {
				return Observable.get_value(_gthis.__coco_now);
			}), null)});
		}), null);
		AutoObservable.cur = before;
		this.__coco_projects = ret;
		this.__coco_now = __coco_init.now;
		this.__coco_user = __coco_init.user;
		this.__coco_language = State._new(new LanguageData(), null, null, null);
		this.remote = __coco_init.remote;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new StringDict({"factory": function (id) {
			return new WeeklyData({"project": id, "remote": _gthis.remote});
		}});
		AutoObservable.cur = before;
		this.weekly = ret;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"user": this.__coco_user, "now": this.__coco_now, "language": this.__coco_language, "remote": new ConstObservable(this.remote), "projects": this.__coco_projects, "uwbTags": this.__coco_uwbTags, "weekly": new ConstObservable(this.weekly), "userSummary": this.__coco_userSummary, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	static get __name__() {
		return "dasloop.app.data.AppData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return AppData
	}
}

