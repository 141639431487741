import {Observable} from "../../../tink/state/Observable.js"
import {Lazy, LazyConst} from "../../../tink/core/Lazy.js"
import {MapKeyValueIterator} from "../../../haxe/iterators/MapKeyValueIterator.js"
import {ObjectMap} from "../../../haxe/ds/ObjectMap.js"
import {Register} from "../../../genes/Register.js"
import {Slot} from "./Slot.js"

const $global = Register.$global

export const ImplicitValues = Register.global("$hxClasses")["coconut.ui.internal._ImplicitContext.ImplicitValues"] = 
class ImplicitValues {
	static _new(a) {
		var _g = new ObjectMap();
		var _g1 = 0;
		while (_g1 < a.length) {
			var o = a[_g1];
			++_g1;
			_g.inst.set(o.key, o.val);
		};
		return _g;
	}
	static get __name__() {
		return "coconut.ui.internal._ImplicitContext.ImplicitValues_Impl_"
	}
	get __class__() {
		return ImplicitValues
	}
}


export const ImplicitContext = Register.global("$hxClasses")["coconut.ui.internal.ImplicitContext"] = 
class ImplicitContext extends Register.inherits() {
	new(parent) {
		this.slots = new ObjectMap();
		this.parent = (parent == null) ? ImplicitContext.ORPHAN : parent;
	}
	get(key) {
		var _g = Observable.get_value(this.getSlot(key));
		var _g1 = Lazy.get(this.parent);
		if (_g == null) {
			if (_g1 == null) {
				return null;
			} else {
				return _g1.get(key);
			};
		} else {
			return _g;
		};
	}
	getSlot(key) {
		var _g = this.slots.inst.get(key);
		if (_g == null) {
			var this1 = this.slots;
			var v = new Slot(this);
			this1.inst.set(key, v);
			return v;
		} else {
			return _g;
		};
	}
	update(values) {
		var _g = new MapKeyValueIterator(this.slots);
		while (_g.hasNext()) {
			var _g1 = _g.next();
			if (!values.inst.has(_g1.key)) {
				_g1.value.setData(null);
			};
		};
		var _g = new MapKeyValueIterator(values);
		while (_g.hasNext()) {
			var _g1 = _g.next();
			var v = _g1.value;
			this.getSlot(_g1.key).setData(v);
		};
	}
	static get __name__() {
		return "coconut.ui.internal.ImplicitContext"
	}
	get __class__() {
		return ImplicitContext
	}
}


ImplicitContext.ORPHAN = new LazyConst(null)
export const TypeKey = Register.global("$hxClasses")["coconut.ui.internal._ImplicitContext.TypeKey"] = 
class TypeKey {
	static ofClass(t) {
		return t;
	}
	static get __name__() {
		return "coconut.ui.internal._ImplicitContext.TypeKey_Impl_"
	}
	get __class__() {
		return TypeKey
	}
}


export const SingleImplicit = Register.global("$hxClasses")["coconut.ui.internal.SingleImplicit"] = 
class SingleImplicit extends Register.inherits() {
	new(key, val) {
		this.key = key;
		this.val = val;
	}
	static get __name__() {
		return "coconut.ui.internal.SingleImplicit"
	}
	get __class__() {
		return SingleImplicit
	}
}

