import {Observable} from "../../../tink/state/Observable.js"
import {Register} from "../../../genes/Register.js"
import {Slot} from "../../../coconut/ui/internal/Slot.js"
import {View} from "../../../coconut/react/View.js"
import {Html} from "../../../coconut/react/Html.js"

const $global = Register.$global

export const Footer = Register.global("$hxClasses")["dasloop.ui.dashboard.Footer"] = 
class Footer extends Register.inherits(View) {
	new(__coco_data_) {
		var _gthis = this;
		this.__coco_localizer = new Slot(this, null, null);
		this.__initAttributes(__coco_data_);
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	__coco_render() {
		var __r = [];
		var __r1 = [];
		__r1.push(Html.h("h4", {"className": "ui inverted header"}, ["BeeInventor IoT"]));
		var children = [Observable.get_value(this.__coco_localizer).productUrl()];
		var children1 = [Html.h("a", {"className": "item", "href": "https://www.beeinventor.com"}, children)];
		__r1.push(Html.h("div", {"className": "ui inverted link list"}, children1));
		__r.push(Html.h("div", {"className": "six wide column"}, __r1));
		var __r1 = [];
		var children = [Observable.get_value(this.__coco_localizer).contactUs()];
		__r1.push(Html.h("h4", {"className": "ui inverted header"}, children));
		var children = [Html.h("a", {"className": "item", "href": "mailto:support@beeinventor.com"}, ["support@beeinventor.com"])];
		__r1.push(Html.h("div", {"className": "ui inverted link list"}, children));
		__r.push(Html.h("div", {"className": "seven wide column"}, __r1));
		var children = [Html.h("div", {"className": "ui stackable inverted divided equal height stackable grid", "style": {"justifyContent": "center"}}, __r)];
		return Html.h("div", {"className": "ui container"}, children);
	}
	__initAttributes(attributes) {
		this.__coco_localizer.setData(attributes.localizer);
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": Footer, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "dasloop.ui.dashboard.Footer"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return Footer
	}
}

