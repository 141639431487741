import {Remote8} from "./Remote8.js"
import {RemoteBase, RemoteEndpoint, ResponseReader} from "./Remote.js"
import {Portion} from "../../url/Portion.js"
import {Single} from "../../streams/Stream.js"
import {Writer70} from "../../json/Writer70.js"
import {Parser6} from "../../json/Parser6.js"
import {Parser2} from "../../json/Parser2.js"
import {HeaderField, HeaderValue} from "../../http/Header.js"
import {NamedWith} from "../../core/Named.js"
import {LazyConst} from "../../core/Lazy.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote5 = Register.global("$hxClasses")["tink.web.proxy.Remote5"] = 
class Remote5 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	createExtended(body) {
		var __body__ = ByteChunk.of(Bytes.ofString(new Writer70().wd({"_0": body.boundary, "_1": body.client, "_2": body.contacts, "_3": body.contractor, "_4": body.endDate, "_5": body.name, "_6": body.number, "_7": body.region, "_8": body.startDate, "_9": body.timezone})));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [new NamedWith("extended", "")], "headers": [new HeaderField("content-type", "application/json"), new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "POST", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pe))(new Parser6())));
	}
	listExtended() {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [new NamedWith("extended", "")], "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pe))(new Parser2())));
	}
	ofId(id) {
		return new Remote8(this.client, RemoteEndpoint.sub(this.endpoint, {"path": [Portion.ofString(id)], "query": [], "headers": [].concat([])}));
	}
	static get __name__() {
		return "tink.web.proxy.Remote5"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote5
	}
}

