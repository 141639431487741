import {StringMap} from "../../haxe/ds/StringMap.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Cache = function() {};
Cache.__isInterface__ = true;

export const StringCache = Register.global("$hxClasses")["why.pubsub.StringCache"] = 
class StringCache extends Register.inherits() {
	new() {
		this.map = new StringMap();
	}
	get(k, factory) {
		if (!this.map.inst.has(k)) {
			var this1 = this.map;
			var value = factory(k);
			this1.inst.set(k, value);
		};
		return this.map.inst.get(k);
	}
	static get __name__() {
		return "why.pubsub.StringCache"
	}
	static get __interfaces__() {
		return [Cache]
	}
	get __class__() {
		return StringCache
	}
}

