import {Writer16} from "./Writer16.js"
import {Writer15} from "./Writer15.js"
import {BasicParser, JsonString} from "./Parser.js"
import {OutgoingRequestHeader} from "../http/Request.js"
import {HeaderField} from "../http/Header.js"
import {TypedError} from "../core/Error.js"
import {Url} from "../Url.js"
import {Exception} from "../../haxe/Exception.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Parser24 = Register.global("$hxClasses")["tink.json.Parser24"] = 
class Parser24 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	pb() {
		var _gthis = this;
		var v_fields = null;
		var hasv_fields = false;
		var v_method = null;
		var hasv_method = false;
		var v_protocol = null;
		var hasv_protocol = false;
		var v_url = null;
		var hasv_url = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "fields":
						this.F("[");
						var __ret = [];
						if (!this.E("]")) {
							while (true) {
								__ret.push(HeaderField.ofString(JsonString.s(this.m())));
								if (!this.E(",")) {
									break;
								};
							};
							this.F("]");
						};
						v_fields = __ret;
						hasv_fields = true;
						break
					case "method":
						var v = JsonString.s(this.m());
						switch (v) {
							case "DELETE":case "GET":case "HEAD":case "OPTIONS":case "PATCH":case "POST":case "PUT":
								v_method = v;
								break
							default:
							throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer16().wb(["GET", "HEAD", "OPTIONS", "POST", "PUT", "PATCH", "DELETE"]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 469, "className": "tink.json.Parser24", "methodName": "process0"}));
							
						};
						hasv_method = true;
						break
					case "protocol":
						var v1 = JsonString.s(this.m());
						switch (v1) {
							case "HTTP/1.0":case "HTTP/1.1":case "HTTP/2":
								v_protocol = v1;
								break
							default:
							throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v1 + ". Accepted values are: " + new Writer15().wb(["HTTP/1.0", "HTTP/1.1", "HTTP/2"]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 469, "className": "tink.json.Parser24", "methodName": "process0"}));
							
						};
						hasv_protocol = true;
						break
					case "url":
						v_url = Url.parse(JsonString.s(this.m()));
						hasv_url = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"fields": (hasv_fields) ? v_fields : __missing__("fields"), "method": (hasv_method) ? v_method : __missing__("method"), "protocol": (hasv_protocol) ? v_protocol : __missing__("protocol"), "url": (hasv_url) ? v_url : __missing__("url")};
	}
	pc(source) {
		if (this.e.length > 0) {
			this.e = [];
		};
		this.g(source);
		var json = this.pb();
		var ret = new OutgoingRequestHeader(json.method, json.url, json.protocol, json.fields);
		var _g = 0;
		var _g1 = this.e;
		while (_g < _g1.length) _g1[_g++]();
		if (this.e.length > 0) {
			this.e = [];
		};
		return ret;
	}
	pd(source) {
		var _gthis = this;
		return TypedError.catchExceptions(function () {
			var ret = _gthis.pc(source);
			_gthis.h();
			if (_gthis.c < _gthis.d) {
				_gthis.D("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser24", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser24"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser24
	}
}

