import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {PromisedTools} from "../../../tink/state/Promised.js"
import {Observable, ConstObservable} from "../../../tink/state/Observable.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Register} from "../../../genes/Register.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const StatisticsData = Register.global("$hxClasses")["dasloop.app.data.StatisticsData"] = 
class StatisticsData extends Register.inherits() {
	new(__coco_init) {
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return PromisedTools.map(Observable.get_value(_gthis.__coco_alertCountPerHour), function (list) {
				var start = State.get_value(_gthis.__coco_from).getTime();
				var _g = [];
				var _g1 = 0;
				var _g2 = Observable.get_value(_gthis.__coco_numWeeks);
				while (_g1 < _g2) {
					++_g1;
					_g.push(0);
				};
				var _g1 = 0;
				var _g2 = list;
				while (_g1 < _g2.length) {
					var entry = _g2[_g1];
					++_g1;
					_g[(entry.hour.getTime() - start) / 604800000. | 0] += entry.count;
				};
				var _g1 = [];
				var _g2 = 0;
				var _g3 = Observable.get_value(_gthis.__coco_numWeeks);
				while (_g2 < _g3) {
					var i = _g2++;
					_g1.push({"from": new Date(start + i * 604800000.), "to": new Date(start + (i + 1) * 604800000.), "value": _g[i]});
				};
				return _g1.slice();
			});
		}), null);
		AutoObservable.cur = before;
		this.__coco_weeklyAlertCount = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return PromisedTools.map(Observable.get_value(_gthis.__coco_readingPerHourPerWorker), function (list) {
				var start = State.get_value(_gthis.__coco_from).getTime();
				var _g = [];
				var _g1 = 0;
				var _g2 = Observable.get_value(_gthis.__coco_numWeeks);
				while (_g1 < _g2) {
					++_g1;
					_g.push(0);
				};
				var _g1 = 0;
				var _g2 = list;
				while (_g1 < _g2.length) _g[(_g2[_g1++].hour.getTime() - start) / 604800000. | 0] += 1;
				var _g1 = [];
				var _g2 = 0;
				var _g3 = Observable.get_value(_gthis.__coco_numWeeks);
				while (_g2 < _g3) {
					var i = _g2++;
					_g1.push({"from": new Date(start + i * 604800000.), "to": new Date(start + (i + 1) * 604800000.), "value": _g[i]});
				};
				return _g1.slice();
			});
		}), null);
		AutoObservable.cur = before;
		this.__coco_weeklyWorkHour = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.async(function () {
			return _gthis.remote.v2().projects().ofId(_gthis.project).statistics().readingPerHourPerWorker({"from": State.get_value(_gthis.__coco_from), "to": State.get_value(_gthis.__coco_to)});
		}), null);
		AutoObservable.cur = before;
		this.__coco_readingPerHourPerWorker = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.async(function () {
			return _gthis.remote.v2().projects().ofId(_gthis.project).statistics().alertCountPerHour({"from": State.get_value(_gthis.__coco_from), "to": State.get_value(_gthis.__coco_to)});
		}), null);
		AutoObservable.cur = before;
		this.__coco_alertCountPerHour = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return Math.ceil((State.get_value(_gthis.__coco_to).getTime() - State.get_value(_gthis.__coco_from).getTime()) / 604800000.);
		}), null);
		AutoObservable.cur = before;
		this.__coco_numWeeks = ret;
		this.project = __coco_init.project;
		this.remote = __coco_init.remote;
		var _g = __coco_init.from;
		this.__coco_from = State._new((_g == null) ? StatisticsData.weekStartOf(StatisticsData.previousMonthStartOf(new Date())) : _g, null, null, null);
		var _g = __coco_init.to;
		this.__coco_to = State._new((_g == null) ? StatisticsData.weekEndOf(new Date()) : _g, null, null, null);
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"project": new ConstObservable(this.project), "remote": new ConstObservable(this.remote), "from": this.__coco_from, "to": this.__coco_to, "numWeeks": this.__coco_numWeeks, "alertCountPerHour": this.__coco_alertCountPerHour, "readingPerHourPerWorker": this.__coco_readingPerHourPerWorker, "weeklyWorkHour": this.__coco_weeklyWorkHour, "weeklyAlertCount": this.__coco_weeklyAlertCount, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	static previousMonthStartOf(date) {
		return new Date(date.getFullYear(), date.getMonth() - 1, 1, 0, 0, 0);
	}
	static weekStartOf(date) {
		return new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay(), 0, 0, 0);
	}
	static weekEndOf(date) {
		return new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay() + 7, 0, 0, 0);
	}
	static get __name__() {
		return "dasloop.app.data.StatisticsData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return StatisticsData
	}
}

