import {BasicWriter} from "./Writer.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Writer71 = Register.global("$hxClasses")["tink.json.Writer71"] = 
class Writer71 extends Register.inherits(BasicWriter) {
	new() {
		super.new();
	}
	wb(value) {
		this.f("{");
		var value1 = value._158;
		this.e("\"date\":");
		this.h(value1.getTime());
		var value1 = value._159;
		this.f(",");
		this.e("\"type\":");
		this.g(value1);
		this.f("}");
	}
	wc(value) {
		this.d();
		this.wb(value);
		return this.b.toString();
	}
	static get __name__() {
		return "tink.json.Writer71"
	}
	static get __super__() {
		return BasicWriter
	}
	get __class__() {
		return Writer71
	}
}

