import {BasicWriter} from "./Writer.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Writer31 = Register.global("$hxClasses")["tink.json.Writer31"] = 
class Writer31 extends Register.inherits(BasicWriter) {
	new() {
		super.new();
	}
	wb(value) {
		this.f("{");
		var value1 = value._209;
		this.e("\"center\":");
		var value2 = value1;
		this.f("[");
		var first = true;
		var _g = 0;
		while (_g < value2.length) {
			var value1 = value2[_g++];
			if (first) {
				first = false;
			} else {
				this.f(",");
			};
			this.h(value1);
		};
		this.f("]");
		var value1 = value._210;
		this.f(",");
		this.e("\"name\":");
		this.j(value1);
		var value1 = value._211;
		this.f(",");
		this.e("\"rotation\":");
		this.h(value1);
		var value1 = value._212;
		this.f(",");
		this.e("\"scale\":");
		this.h(value1);
		var value1 = value._213;
		this.f(",");
		this.e("\"type\":");
		this.j(value1);
		this.f("}");
	}
	wc(value) {
		this.d();
		this.wb(value);
		return this.b.toString();
	}
	static get __name__() {
		return "tink.json.Writer31"
	}
	static get __super__() {
		return BasicWriter
	}
	get __class__() {
		return Writer31
	}
}

