import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Disposable = function() {};
Disposable.__isInterface__ = true;

export const OwnedDisposable = function() {};
OwnedDisposable.__isInterface__ = true;

/**
* A simple implementation of the OwnedDisposable,
* where actual disposal is passed in via the constructor.
*/
export const SimpleDisposable = Register.global("$hxClasses")["tink.core.SimpleDisposable"] = 
class SimpleDisposable extends Register.inherits() {
	new(dispose) {
		this.disposeHandlers = [];
		this.f = dispose;
	}
	dispose() {
		var _g = this.disposeHandlers;
		if (_g != null) {
			this.disposeHandlers = null;
			var f = this.f;
			this.f = SimpleDisposable.noop;
			f();
			var _g1 = 0;
			while (_g1 < _g.length) _g[_g1++]();
		};
	}
	static noop() {
	}
	static get __name__() {
		return "tink.core.SimpleDisposable"
	}
	static get __interfaces__() {
		return [OwnedDisposable]
	}
	get __class__() {
		return SimpleDisposable
	}
}

