import {Portion} from "./Portion.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const PortionArray = Register.global("$hxClasses")["tink.url._PortionArray.PortionArray"] = 
class PortionArray {
	static toStringArray(this1) {
		var _g = [];
		var _g1 = 0;
		while (_g1 < this1.length) _g.push(Portion.toString(this1[_g1++]));
		return _g;
	}
	static get __name__() {
		return "tink.url._PortionArray.PortionArray_Impl_"
	}
	get __class__() {
		return PortionArray
	}
}

