import {Remote30} from "./Remote30.js"
import {Remote12} from "./Remote12.js"
import {RemoteBase, RemoteEndpoint, ResponseReader} from "./Remote.js"
import {Single} from "../../streams/Stream.js"
import {Builder27} from "../../querystring/Builder27.js"
import {Writer13} from "../../json/Writer13.js"
import {Parser24} from "../../json/Parser24.js"
import {Parser21} from "../../json/Parser21.js"
import {HeaderField, HeaderValue} from "../../http/Header.js"
import {LazyConst} from "../../core/Lazy.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote29 = Register.global("$hxClasses")["tink.web.proxy.Remote29"] = 
class Remote29 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	uploadImage(mime) {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["image"], "query": [].concat(new Builder27().stringify({"_116": mime})), "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "PUT", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser24())));
	}
	get() {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [], "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser21())));
	}
	update(body) {
		var __body__ = ByteChunk.of(Bytes.ofString(new Writer13().wc({"_117": body.age, "_118": body.company, "_119": body.dateOfBirth, "_120": body.division, "_121": body.email, "_122": body.gender, "_123": body.name, "_124": body.phone, "_125": body.registrationNumber, "_126": body.remarks, "_127": body.trade})));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [], "headers": [new HeaderField("content-type", "application/json"), new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "PUT", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser21())));
	}
	contacts() {
		return new Remote12(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["contacts"], "query": [], "headers": [].concat([])}));
	}
	certificates() {
		return new Remote30(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["certificates"], "query": [], "headers": [].concat([])}));
	}
	static get __name__() {
		return "tink.web.proxy.Remote29"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote29
	}
}

