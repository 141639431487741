import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {PromisedTools, PromisedWith} from "../../../tink/state/Promised.js"
import {Observable, ConstObservable} from "../../../tink/state/Observable.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Promise as Promise__1} from "../../../tink/core/Promise.js"
import {Outcome} from "../../../tink/core/Outcome.js"
import {LazyConst} from "../../../tink/core/Lazy.js"
import {SyncFuture} from "../../../tink/core/Future.js"
import {Register} from "../../../genes/Register.js"
import {DeviceId} from "../../types/DeviceId.js"
import {Subscriber} from "../remote/Subscriber.js"
import {DeviceData} from "./DeviceData.js"
import {StringDict} from "../../../coconut/ds/Dict.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"
import {Reflect as Reflect__1} from "../../../Reflect.js"
import {Lambda} from "../../../Lambda.js"

const $global = Register.$global

export const DevicesData = Register.global("$hxClasses")["dasloop.app.data.DevicesData"] = 
class DevicesData extends Register.inherits() {
	new(__coco_init) {
		this._updatePerformed = Signal.trigger();
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return PromisedTools.map(Observable.get_value(_gthis.__coco_alerts), function (list) {
				var _g = [];
				var x = Register.iter(list);
				while (x.hasNext()) _g.push(x.next());
				return _g;
			});
		}), null);
		AutoObservable.cur = before;
		this.__coco_alertsList = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return PromisedTools.flatMap(Observable.get_value(_gthis.__coco_list), function (devices) {
				var arr = [];
				var _g = 0;
				var _g1 = devices;
				while (_g < _g1.length) {
					var device = _g1[_g];
					++_g;
					var _g2 = Observable.get_value(device.pulled.__coco_alerts);
					switch (_g2._hx_index) {
						case 0:
							return PromisedWith.Loading;
							break
						case 1:
							var _g3 = 0;
							var _g4 = Observable.get_value(device.__coco_alerts);
							while (_g3 < _g4.length) {
								var alert = _g4[_g3];
								++_g3;
								arr.push({"date": alert.date, "device": device.id, "worker": PromisedTools.orNull(PromisedTools.flatMap(Observable.get_value(device.__coco_data), function (data) {
									var _g = data.worker;
									if (_g == null) {
										return PromisedWith.Done(null);
									} else {
										return PromisedTools.map(Observable.get_value(_gthis.workers.collection.map.get(_g, null).__coco_data), function (worker) {
											return {"id": worker.id, "name": worker.name};
										});
									};
								})), "plant": PromisedTools.orNull(PromisedTools.flatMap(Observable.get_value(device.__coco_data), function (data) {
									var _g = data.plant;
									if (_g == null) {
										return PromisedWith.Done(null);
									} else {
										return PromisedTools.map(Observable.get_value(_gthis.plants.collection.map.get(_g, null).__coco_data), function (plant) {
											return {"id": plant.id, "name": plant.name};
										});
									};
								})), "value": alert.value, "dismissed": alert.dismissed, "ignored": alert.ignored});
							};
							break
						case 2:
							return PromisedWith.Failed(_g2.error);
							break
						
					};
				};
				arr.sort(function (o1, o2) {
					return Reflect__1.compare(o2.date, o1.date);
				});
				return PromisedWith.Done(arr.slice());
			});
		}), null);
		AutoObservable.cur = before;
		this.__coco_alerts = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.async(function () {
			State.get_value(_gthis.__coco_revision);
			return Promise__1.next(_gthis.remote.v2().projects().ofId(_gthis.project).devices().list(), function (devices) {
				var result = new Array(devices.length);
				var _g = 0;
				var _g1 = devices.length;
				while (_g < _g1) {
					var i = _g++;
					var device = devices[i];
					var item = _gthis.data.get(device.id, null);
					item.set_cache(device);
					item.set_revision(State.get_value(item.__coco_revision) + 1);
					result[i] = item;
				};
				return new SyncFuture(new LazyConst(Outcome.Success(result)));
			});
		}), null);
		AutoObservable.cur = before;
		this.__coco_list = ret;
		this.__coco_heartbeatTimeout = __coco_init.heartbeatTimeout;
		this.__coco_now = __coco_init.now;
		this.project = __coco_init.project;
		this.plants = __coco_init.plants;
		this.workers = __coco_init.workers;
		this.equipments = __coco_init.equipments;
		this.dasiots = __coco_init.dasiots;
		this.remote = __coco_init.remote;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new StringDict({"factory": function (id) {
			return new DeviceData({"id": id, "heartbeatTimeout": new AutoObservable(Computation.sync(function () {
				return Observable.get_value(_gthis.__coco_heartbeatTimeout);
			}), null), "project": _gthis.project, "remote": _gthis.remote, "push": Subscriber.subscribe2(_gthis.project, id, _gthis.remote), "dasiots": _gthis.dasiots});
		}});
		AutoObservable.cur = before;
		this.data = ret;
		this.__coco_revision = State._new(0, null, null, null);
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"project": new ConstObservable(this.project), "plants": new ConstObservable(this.plants), "workers": new ConstObservable(this.workers), "equipments": new ConstObservable(this.equipments), "dasiots": new ConstObservable(this.dasiots), "remote": new ConstObservable(this.remote), "now": this.__coco_now, "heartbeatTimeout": this.__coco_heartbeatTimeout, "data": new ConstObservable(this.data), "list": this.__coco_list, "alerts": this.__coco_alerts, "alertsList": this.__coco_alertsList, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	onlineCount(type) {
		var _gthis = this;
		var _g = Observable.get_value(this.__coco_list);
		if (_g._hx_index == 1) {
			return Lambda.count(_g.result, function (d) {
				if (DeviceId.get_deviceType(d.id) == type) {
					return d.isOnline(Observable.get_value(_gthis.__coco_now));
				} else {
					return false;
				};
			});
		} else {
			return 0;
		};
	}
	refresh() {
		this.set_revision(State.get_value(this.__coco_revision) + 1);
	}
	set_revision(param) {
		this._updatePerformed.handlers.invoke({"revision": param});
		this.__coco_revision.set(param);
		return param;
	}
	static get __name__() {
		return "dasloop.app.data.DevicesData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return DevicesData
	}
}

