import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {Observable, ConstObservable} from "../../../tink/state/Observable.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Outcome} from "../../../tink/core/Outcome.js"
import {TypedError} from "../../../tink/core/Error.js"
import {Register} from "../../../genes/Register.js"
import {DateModel} from "./DateModel.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"
import {HxOverrides} from "../../../HxOverrides.js"
import {DateTools} from "../../../DateTools.js"

const $global = Register.$global

export const InputError = Register.global("$hxClasses")["dasloop.ui.data._DateRangeModel.InputError"] = 
class InputError {
	static getAction(this1, localizer) {
		switch (this1) {
			case 0:
				return localizer.pleaseInputFromDate();
				break
			case 1:
				return localizer.pleaseInputToDate();
				break
			case 2:
				return localizer.pleaseInputCorrectDateRange();
				break
			
		};
	}
	static get __name__() {
		return "dasloop.ui.data._DateRangeModel.InputError_Impl_"
	}
	get __class__() {
		return InputError
	}
}


export const DateRangeModel = Register.global("$hxClasses")["dasloop.ui.data.DateRangeModel"] = 
class DateRangeModel extends Register.inherits() {
	new(mode, fromDate, toDate) {
		var _gthis = this;
		var __coco_init;
		switch (mode) {
			case 0:
				__coco_init = {"from": new DateModel({"mode": mode, "input": (fromDate == null) ? "" : DateTools.format(fromDate, "%F")}), "to": new DateModel({"mode": mode, "input": (toDate == null) ? "" : DateTools.format(toDate, "%F")})};
				break
			case 1:
				__coco_init = {"from": new DateModel({"mode": mode, "input": (fromDate == null) ? "" : HxOverrides.dateStr(fromDate)}), "to": new DateModel({"mode": mode, "input": (toDate == null) ? "" : HxOverrides.dateStr(toDate)})};
				break
			
		};
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			var errors = [];
			if (State.get_value(_gthis.from.__coco_input) == "") {
				errors.push(0);
			};
			if (State.get_value(_gthis.to.__coco_input) == "") {
				errors.push(1);
			};
			var dateRange;
			var _g = Observable.get_value(_gthis.from.__coco_output);
			var _g1 = Observable.get_value(_gthis.to.__coco_output);
			switch (_g._hx_index) {
				case 0:
					var _g2 = _g.data;
					switch (_g1._hx_index) {
						case 0:
							var _g = _g1.data;
							if (_g2.getTime() < _g.getTime()) {
								dateRange = {"from": _g2, "to": _g};
							} else {
								errors.push(2);
								dateRange = null;
							};
							break
						case 1:
							errors.push(2);
							dateRange = null;
							break
						
					};
					break
				case 1:
					errors.push(2);
					dateRange = null;
					break
				
			};
			if (errors.length == 0) {
				return Outcome.Success({"from": dateRange.from, "to": dateRange.to});
			} else {
				return Outcome.Failure(TypedError.typed(500, "Input error", errors.slice(), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/dasloop-ui/0.0.0/git/6fa8443e2144bac1a02e0b9fd66593eb77a24a59/src/dasloop/ui/data/DateRangeModel.hx", "lineNumber": 63, "className": "dasloop.ui.data.DateRangeModel", "methodName": "new"}));
			};
		}), null);
		AutoObservable.cur = before;
		this.__coco_output = ret;
		this.from = __coco_init.from;
		this.to = __coco_init.to;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"from": new ConstObservable(this.from), "to": new ConstObservable(this.to), "output": this.__coco_output, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	static get __name__() {
		return "dasloop.ui.data.DateRangeModel"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return DateRangeModel
	}
}

