import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {PromisedTools} from "../../../tink/state/Promised.js"
import {Observable, ConstObservable} from "../../../tink/state/Observable.js"
import {Vector} from "../../../tink/pure/Vector.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Promise as Promise__1} from "../../../tink/core/Promise.js"
import {Outcome} from "../../../tink/core/Outcome.js"
import {LazyConst} from "../../../tink/core/Lazy.js"
import {SyncFuture} from "../../../tink/core/Future.js"
import {TypedError} from "../../../tink/core/Error.js"
import {Option} from "../../../haxe/ds/Option.js"
import {Register} from "../../../genes/Register.js"
import {BeaconData} from "./BeaconData.js"
import {IntCollection} from "../../../coconut/ds/Collection.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const BeaconsData = Register.global("$hxClasses")["dasloop.app.data.BeaconsData"] = 
class BeaconsData extends Register.inherits() {
	new(__coco_init) {
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return PromisedTools.map(PromisedTools.flatMap(Observable.get_value(_gthis.collection.__coco_list), function (list) {
				var result = new Array(list.length);
				var _g = 0;
				var _g1 = list.length;
				while (_g < _g1) {
					var i = _g++;
					result[i] = Observable.get_value(list[i].__coco_data);
				};
				return PromisedTools.all(result);
			}), Vector.fromArray);
		}), null);
		AutoObservable.cur = before;
		this.__coco_data = ret;
		this.project = __coco_init.project;
		this.remote = __coco_init.remote;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new IntCollection({"fetch": function () {
			return _gthis.remote.v2().projects().ofId(_gthis.project).beacons().list();
		}, "extractKey": function (raw) {
			return raw.id;
		}, "createItem": function (id, init) {
			return new BeaconData({"id": id, "remote": _gthis.remote, "initial": init});
		}, "updateItem": function (data, raw) {
			data._data.refresh(Option.Some(raw));
		}});
		AutoObservable.cur = before;
		this.collection = ret;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"project": new ConstObservable(this.project), "remote": new ConstObservable(this.remote), "collection": new ConstObservable(this.collection), "data": this.__coco_data, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	init(patch) {
		var _gthis = this;
		return Promise__1.next(this.remote.v2().beacons().ofId(patch.id).get(), function (beacon) {
			if (beacon.project != null) {
				return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(null, "Beacon already assigned to a project", {"fileName": "src/dasloop/app/data/BeaconsData.hx", "lineNumber": 29, "className": "dasloop.app.data.BeaconsData", "methodName": "init"}))));
			};
			var data = _gthis.collection.map.get(beacon.id, null);
			return Promise__1.noise(Promise__1.inParallel([Promise__1.noise(data._data.update(patch)), Promise__1.noise(data.setProject(_gthis.project))]));
		});
	}
	getIdByHash(hash) {
		return Promise__1.next(this.remote.v2().beacons().ofHash(hash).get(), function (beacon) {
			return new SyncFuture(new LazyConst(Outcome.Success(beacon.id)));
		});
	}
	static get __name__() {
		return "dasloop.app.data.BeaconsData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return BeaconsData
	}
}

