import {BasicWriter} from "./Writer.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Writer46 = Register.global("$hxClasses")["tink.json.Writer46"] = 
class Writer46 extends Register.inherits(BasicWriter) {
	new() {
		super.new();
	}
	wb(value) {
		this.f("{");
		var value1 = value._56;
		this.e("\"expiry\":");
		this.h(value1.getTime());
		var value1 = value._57;
		this.f(",");
		this.e("\"mainContractor\":");
		if (value1 == null) {
			this.e("null");
		} else {
			this.j(value1);
		};
		var value1 = value._58;
		this.f(",");
		this.e("\"model\":");
		this.j(value1);
		var value1 = value._59;
		this.f(",");
		this.e("\"name\":");
		this.j(value1);
		var value1 = value._60;
		this.f(",");
		this.e("\"permitIssuer\":");
		this.j(value1);
		var value1 = value._61;
		this.f(",");
		this.e("\"permitNumber\":");
		this.j(value1);
		var value1 = value._62;
		this.f(",");
		this.e("\"remarks\":");
		if (value1 == null) {
			this.e("null");
		} else {
			this.j(value1);
		};
		var value1 = value._63;
		this.f(",");
		this.e("\"safeWorkingLoad\":");
		this.h(value1);
		var value1 = value._64;
		this.f(",");
		this.e("\"serialNumber\":");
		this.j(value1);
		var value1 = value._65;
		this.f(",");
		this.e("\"subContractor\":");
		if (value1 == null) {
			this.e("null");
		} else {
			this.j(value1);
		};
		this.f("}");
	}
	wc(value) {
		this.d();
		this.wb(value);
		return this.b.toString();
	}
	static get __name__() {
		return "tink.json.Writer46"
	}
	static get __super__() {
		return BasicWriter
	}
	get __class__() {
		return Writer46
	}
}

