import {FluentLocaleBase} from "./FluentProvider.js"
import {Register} from "../../genes/Register.js"
import {SidebarLocalizer} from "../../dasloop/app/localizer/SidebarLocalizer.js"

const $global = Register.$global

export const FluentLocale128 = Register.global("$hxClasses")["turnwing.provider.FluentLocale128"] = 
class FluentLocale128 extends Register.inherits(FluentLocaleBase) {
	new(__bundle__, __prefix__) {
		super.new(__bundle__, __prefix__);
	}
	selectProject() {
		return this.__exec__("selectProject", {});
	}
	switchProject() {
		return this.__exec__("switchProject", {});
	}
	loading() {
		return this.__exec__("loading", {});
	}
	logout() {
		return this.__exec__("logout", {});
	}
	overview() {
		return this.__exec__("overview", {});
	}
	devices() {
		return this.__exec__("devices", {});
	}
	plants() {
		return this.__exec__("plants", {});
	}
	workers() {
		return this.__exec__("workers", {});
	}
	equipments() {
		return this.__exec__("equipments", {});
	}
	aoaLocator() {
		return this.__exec__("aoaLocator", {});
	}
	announcement() {
		return this.__exec__("announcement", {});
	}
	beacons() {
		return this.__exec__("beacons", {});
	}
	floorPlans() {
		return this.__exec__("floorPlans", {});
	}
	bims() {
		return this.__exec__("bims", {});
	}
	areas() {
		return this.__exec__("areas", {});
	}
	statistics() {
		return this.__exec__("statistics", {});
	}
	reports() {
		return this.__exec__("reports", {});
	}
	settings() {
		return this.__exec__("settings", {});
	}
	cctv() {
		return this.__exec__("cctv", {});
	}
	logs() {
		return this.__exec__("logs", {});
	}
	static get __name__() {
		return "turnwing.provider.FluentLocale128"
	}
	static get __interfaces__() {
		return [SidebarLocalizer]
	}
	static get __super__() {
		return FluentLocaleBase
	}
	get __class__() {
		return FluentLocale128
	}
}

