import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {PromisedTools, PromisedWith} from "../../../tink/state/Promised.js"
import {Observable, ConstObservable} from "../../../tink/state/Observable.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Outcome} from "../../../tink/core/Outcome.js"
import {Lazy, LazyConst} from "../../../tink/core/Lazy.js"
import {SyncFuture} from "../../../tink/core/Future.js"
import {TypedError} from "../../../tink/core/Error.js"
import {Option} from "../../../haxe/ds/Option.js"
import {Register} from "../../../genes/Register.js"
import {Updatable} from "../../../coconut/ds/Updatable.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const TaskData = Register.global("$hxClasses")["dasloop.app.data.TaskData"] = 
class TaskData extends Register.inherits() {
	new(init) {
		this._updatePerformed = Signal.trigger();
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return PromisedTools.map(Observable.get_value(_gthis.__coco_retained), function (task) {
				if (State.get_value(_gthis.__coco_cache) != null && State.get_value(_gthis.__coco_cache).updated.getTime() > task.updateDate.getTime()) {
					return {"updateDate": State.get_value(_gthis.__coco_cache).updated, "action": State.get_value(_gthis.__coco_cache).action, "progress": State.get_value(_gthis.__coco_cache).progress, "status": task.status, "id": task.id, "group": task.group, "force": task.force, "device": task.device, "definition": task.definition, "createDate": task.createDate};
				} else {
					return task;
				};
			});
		}), null);
		AutoObservable.cur = before;
		this.__coco_snapshot = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var f = function (__tink_tmp0) {
			var _g = Observable.get_value(_gthis.data.__coco_data);
			if (_g._hx_index == 0) {
				if (__tink_tmp0._hx_index == 0) {
					return __tink_tmp0.v;
				} else {
					return Lazy.get(new LazyConst(PromisedWith.Loading));
				};
			} else {
				return _g;
			};
		};
		var last = Option.None;
		var ret = new AutoObservable(function (_, _1) {
			var ret = f(last);
			last = Option.Some(ret);
			return ret;
		}, null);
		AutoObservable.cur = before;
		this.__coco_retained = ret;
		this.id = init.id;
		this.remote = init.remote;
		this.pubsub = init.pubsub;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new Updatable({"loader": function () {
			return _gthis.remote.v2().tasks().ofId(_gthis.id).get();
		}, "updater": function (current, patch) {
			return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(null, "NotImplemented", {"fileName": "src/dasloop/app/data/TaskData.hx", "lineNumber": 17, "className": "dasloop.app.data.TaskData", "methodName": "new"}))));
		}});
		AutoObservable.cur = before;
		this.data = ret;
		this.__coco_cache = State._new(null, null, null, null);
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"id": new ConstObservable(this.id), "remote": new ConstObservable(this.remote), "pubsub": new ConstObservable(this.pubsub), "data": new ConstObservable(this.data), "retained": this.__coco_retained, "snapshot": this.__coco_snapshot, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var subscription = null;
		subscription = _gthis.pubsub.subscribers.task(_gthis.id, "tink").subscribe(function (envelop) {
			var _g = Lazy.get(envelop.content);
			switch (_g._hx_index) {
				case 0:
					var _g1 = _g.data;
					_gthis.set_cache(_g1);
					if (_g1.action == Option.None) {
						if (subscription != null) {
							subscription.cancel();
						};
						_gthis.data.refresh();
					};
					break
				case 1:
					break
				
			};
		});
		AutoObservable.cur = before;
	}
	set_cache(param) {
		this._updatePerformed.handlers.invoke({"cache": param});
		this.__coco_cache.set(param);
		return param;
	}
	static get __name__() {
		return "dasloop.app.data.TaskData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return TaskData
	}
}

