import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const GasReading = 
Register.global("$hxEnums")["dasloop.types.GasReading"] = 
{
	__ename__: "dasloop.types.GasReading",
	
	HC: Object.assign((percent) => ({_hx_index: 0, __enum__: "dasloop.types.GasReading", "percent": percent}), {_hx_name: "HC", __params__: ["percent"]}),
	O2: Object.assign((percent) => ({_hx_index: 1, __enum__: "dasloop.types.GasReading", "percent": percent}), {_hx_name: "O2", __params__: ["percent"]}),
	H2S: Object.assign((ppm) => ({_hx_index: 2, __enum__: "dasloop.types.GasReading", "ppm": ppm}), {_hx_name: "H2S", __params__: ["ppm"]}),
	CO: Object.assign((ppm) => ({_hx_index: 3, __enum__: "dasloop.types.GasReading", "ppm": ppm}), {_hx_name: "CO", __params__: ["ppm"]}),
	CO2: Object.assign((ppm) => ({_hx_index: 4, __enum__: "dasloop.types.GasReading", "ppm": ppm}), {_hx_name: "CO2", __params__: ["ppm"]})
}
GasReading.__constructs__ = [GasReading.HC, GasReading.O2, GasReading.H2S, GasReading.CO, GasReading.CO2]
GasReading.__empty_constructs__ = []

export const GasReadingTools = Register.global("$hxClasses")["dasloop.types.GasReadingTools"] = 
class GasReadingTools {
	static print(reading) {
		return GasReadingTools.printGasType(reading) + ": " + GasReadingTools.printValue(reading);
	}
	static printGasType(reading) {
		switch (reading._hx_index) {
			case 0:
				return "HC";
				break
			case 1:
				return "O2";
				break
			case 2:
				return "H2S";
				break
			case 3:
				return "CO";
				break
			case 4:
				return "CO2";
				break
			
		};
	}
	static printValue(reading) {
		switch (reading._hx_index) {
			case 0:
				return reading.percent + "%LEL";
				break
			case 1:
				return reading.percent + "%";
				break
			case 2:
				return reading.ppm + "ppm";
				break
			case 3:
				return reading.ppm + "ppm";
				break
			case 4:
				return reading.ppm + "ppm";
				break
			
		};
	}
	static get __name__() {
		return "dasloop.types.GasReadingTools"
	}
	get __class__() {
		return GasReadingTools
	}
}

