import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Keymaker = function() {};
Keymaker.__isInterface__ = true;

export const DefaultKeymaker = Register.global("$hxClasses")["tink.querystring.DefaultKeymaker"] = 
class DefaultKeymaker extends Register.inherits() {
	new() {
	}
	field(name, field) {
		return "" + name + "." + field;
	}
	static get __name__() {
		return "tink.querystring.DefaultKeymaker"
	}
	static get __interfaces__() {
		return [Keymaker]
	}
	get __class__() {
		return DefaultKeymaker
	}
}

