import {Representation} from "../tink/json/Representation.js"
import {Exception} from "../haxe/Exception.js"
import {Liness} from "./util/Liness.js"
import {Register} from "../genes/Register.js"

const $global = Register.$global

export const MultiPolygon = Register.global("$hxClasses")["geojson._MultiPolygon.MultiPolygon"] = 
class MultiPolygon {
	static fromRepresentation(rep) {
		var _g = Representation.get(rep);
		if (MultiPolygon.is(_g)) {
			return _g;
		} else {
			throw Exception.thrown("Invalid MultiPolygon");
		};
	}
	static is(v) {
		if (v.type == "MultiPolygon") {
			return Liness.is(v.coordinates);
		} else {
			return false;
		};
	}
	static get __name__() {
		return "geojson._MultiPolygon.MultiPolygon_Impl_"
	}
	get __class__() {
		return MultiPolygon
	}
}

