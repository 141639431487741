import {ExtendedFluentSource} from "../../../turnwing/source/ExtendedFluentSource.js"
import {FluentProvider63} from "../../../turnwing/provider/FluentProvider63.js"
import {FluentProvider2} from "../../../turnwing/provider/FluentProvider2.js"
import {FluentProvider1} from "../../../turnwing/provider/FluentProvider1.js"
import {FluentProvider0} from "../../../turnwing/provider/FluentProvider0.js"
import {Manager} from "../../../turnwing/Manager.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {Observable, ConstObservable} from "../../../tink/state/Observable.js"
import {ClassName} from "../../../tink/domspec/ClassName.js"
import {LazyFunc, Lazy} from "../../../tink/core/Lazy.js"
import {Future} from "../../../tink/core/Future.js"
import {TypedError} from "../../../tink/core/Error.js"
import {Url} from "../../../tink/Url.js"
import {Location} from "../../../spectatory/Location.js"
import {Dropdown} from "semantic-ui-react"
import {ReactType} from "../../../react/ReactType.js"
import * as React from "react"
import {Register} from "../../../genes/Register.js"
import {Genes} from "../../../genes/Genes.js"
import {Spinner} from "../../ui/general/Spinner.js"
import {ErrorScreen} from "../../ui/general/ErrorScreen.js"
import {ErrorMessage} from "../../ui/general/ErrorMessage.js"
import {Dashboard} from "../../ui/general/Dashboard.js"
import {Footer as Footer__1} from "../../ui/dashboard/Footer.js"
import {ViewLoader} from "../../ui/ViewLoader.js"
import {PromisedView} from "../../ui/PromisedView.js"
import {FluentManagerBase} from "../../ui/FluentManager.js"
import {ProjectExpiryGuard} from "./ProjectExpiryGuard.js"
import {RouteTools, AuthenticatedRouteTools, AuthenticatedRoute, ProjectRoute, AudioRoute, DeviceRoute, WorkerRoute, GenericSubRoute, EquipmentRoute, AoaLocatorRoute, BeaconRoute, CctvRoute} from "../data/Route.js"
import {LanguageContext} from "../LanguageContext.js"
import {Slot} from "../../../coconut/ui/internal/Slot.js"
import {ImplicitValues, SingleImplicit, TypeKey} from "../../../coconut/ui/internal/ImplicitContext.js"
import {Router} from "../../../coconut/router/Browser.js"
import {View} from "../../../coconut/react/View.js"
import {Implicit} from "../../../coconut/react/Implicit.js"
import {Html} from "../../../coconut/react/Html.js"
import {Lambda} from "../../../Lambda.js"

const $global = Register.$global

export const AppContainer = Register.global("$hxClasses")["dasloop.app.ui.AppContainer"] = 
class AppContainer extends Register.inherits(View) {
	new(__coco_data_) {
		var _gthis = this;
		this.__coco_app = new Slot(this, null, null);
		this.__coco_logout = new Slot(this, null, null);
		this.__coco_changePassword = new Slot(this, null, null);
		this.__coco_getToken = new Slot(this, null, null);
		this.__coco_localizer = new AutoObservable(Computation.async(function () {
			return FluentManagerBase.get(new Manager(new FluentProvider2(new ExtendedFluentSource("widget/app-container", FluentManagerBase.getSource), {"useIsolating": false})), State.get_value(State.get_value(Observable.get_value(_gthis.__coco_app).__coco_language).__coco_code));
		}), null);
		this.__initAttributes(__coco_data_);
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	logout() {
		(Observable.get_value(this.__coco_logout))();
	}
	changePassword(a0) {
		(Observable.get_value(this.__coco_changePassword))(a0);
	}
	getToken() {
		return (Observable.get_value(this.__coco_getToken))();
	}
	__coco_render() {
		var _gthis = this;
		var tmp = new AutoObservable(Computation.sync(function () {
			return Register.bind(_gthis, _gthis.renderScreen);
		}), null);
		var tmp1 = new AutoObservable(Computation.sync(function () {
			return RouteTools.fromUrl;
		}), null);
		return Router.fromHxx({}, {"renderScreen": tmp, "urlToRoute": tmp1});
	}
	renderScreen(route) {
		var _gthis = this;
		switch (route._hx_index) {
			case 0:
				var sub = route.sub;
				var _g = Observable.get_value(Observable.get_value(Observable.get_value(this.__coco_app).__coco_user).__coco_profile);
				switch (_g._hx_index) {
					case 0:
						var __r = [];
						__r.push(Html.h("div", {"className": "cube1"}, []));
						__r.push(Html.h("div", {"className": "cube2"}, []));
						var children = [Html.h("div", {"className": "spinner"}, __r)];
						return Html.h("div", {"className": "spinner-container"}, children);
						break
					case 1:
						var profile = _g.result;
						var tmp = ImplicitValues._new([new SingleImplicit(TypeKey.ofClass(LanguageContext), new AutoObservable(Computation.sync(function () {
							return new LanguageContext(State.get_value(State.get_value(Observable.get_value(_gthis.__coco_app).__coco_language).__coco_code));
						}), null))]);
						var tmp1 = new AutoObservable(Computation.sync(function () {
							return function (attr) {
								var __o0 = _gthis;
								var __o1 = attr;
								var compute = {"route": new AutoObservable(Computation.sync(function () {
									return sub;
								}), null), "logout": new AutoObservable(Computation.sync(function () {
									return Register.bind(__o0, __o0.logout);
								}), null), "app": new AutoObservable(Computation.sync(function () {
									return Observable.get_value(__o0.__coco_app);
								}), null), "hideSidebar": new AutoObservable(Computation.sync(function () {
									return __o1.hideSidebar;
								}), null)};
								return [SideBar.fromHxx({}, compute)];
							};
						}), null);
						var tmp2 = new AutoObservable(Computation.sync(function () {
							return function (attr) {
								var __o0 = _gthis;
								var __o1 = attr;
								var compute = {"route": new AutoObservable(Computation.sync(function () {
									return sub;
								}), null), "app": new AutoObservable(Computation.sync(function () {
									return Observable.get_value(__o0.__coco_app);
								}), null), "toggleSidebar": new AutoObservable(Computation.sync(function () {
									return __o1.toggleSidebar;
								}), null), "menuButtonClass": new AutoObservable(Computation.sync(function () {
									return ClassName.ofString(__o1.menuButtonClass);
								}), null)};
								return [NavBar.fromHxx({}, compute)];
							};
						}), null);
						var tmp3 = new AutoObservable(Computation.sync(function () {
							var __o0 = _gthis;
							var compute = {"route": new AutoObservable(Computation.sync(function () {
								return sub;
							}), null), "user": new AutoObservable(Computation.sync(function () {
								return profile;
							}), null), "getToken": new AutoObservable(Computation.sync(function () {
								return Register.bind(__o0, __o0.getToken);
							}), null), "changePassword": new AutoObservable(Computation.sync(function () {
								return Register.bind(__o0, __o0.changePassword);
							}), null), "app": new AutoObservable(Computation.sync(function () {
								return Observable.get_value(__o0.__coco_app);
							}), null)};
							return [AppContent.fromHxx({}, compute)];
						}), null);
						var tmp4 = new AutoObservable(Computation.sync(function () {
							return [Footer.fromHxx({}, {})];
						}), null);
						return Implicit.fromHxx({"defaults": tmp, "children": [Dashboard.fromHxx({}, {"sidebar": tmp1, "navbar": tmp2, "content": tmp3, "footer": tmp4})]});
						break
					case 2:
						var __r = [];
						__r.push("Failed to fetch user profile: ");
						__r.push(_g.error.toString());
						return Html.h("div", {"className": "ui error message"}, __r);
						break
					
				};
				break
			case 1:
				var _g = route.sub;
				switch (_g._hx_index) {
					case 0:
						var children = new AutoObservable(Computation.sync(function () {
							return Observable.get_value(_gthis.__coco_localizer);
						}), null);
						var children1 = new AutoObservable(Computation.sync(function () {
							return function (localizer) {
								var compute = new AutoObservable(Computation.sync(function () {
									return localizer.pageNotFound();
								}), null);
								return ErrorScreen.fromHxx({}, {"title": compute, "href": new ConstObservable("/")});
							};
						}), null);
						return Html.h("div", {"style": {"height": "100vh"}}, [PromisedView.fromHxx({}, {"value": children, "renderDone": children1})]);
						break
					case 1:
						var options = _g.options;
						var children = new AutoObservable(Computation.sync(function () {
							return Observable.get_value(_gthis.__coco_localizer);
						}), null);
						var children1 = new AutoObservable(Computation.sync(function () {
							return function (localizer) {
								var compute = new AutoObservable(Computation.sync(function () {
									return localizer.error();
								}), null);
								var compute1 = new AutoObservable(Computation.sync(function () {
									return new TypedError(null, (options.message == null) ? localizer.unknownError() : options.message, {"fileName": "src/dasloop/app/ui/AppContainer.hx", "lineNumber": 45, "className": "dasloop.app.ui.AppContainer", "methodName": "renderScreen"});
								}), null);
								return ErrorScreen.fromHxx({}, {"title": compute, "error": compute1, "href": new ConstObservable("/")});
							};
						}), null);
						return Html.h("div", {"style": {"height": "100vh"}}, [PromisedView.fromHxx({}, {"value": children, "renderDone": children1})]);
						break
					
				};
				break
			
		};
	}
	__initAttributes(attributes) {
		this.__coco_app.setData(attributes.app);
		this.__coco_logout.setData(attributes.logout);
		this.__coco_changePassword.setData(attributes.changePassword);
		this.__coco_getToken.setData(attributes.getToken);
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": AppContainer, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "dasloop.app.ui.AppContainer"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return AppContainer
	}
}


export const SideBar = Register.global("$hxClasses")["dasloop.app.ui.SideBar"] = 
class SideBar extends Register.inherits(View) {
	new(__coco_data_) {
		var _gthis = this;
		this.__coco_app = new Slot(this, null, null);
		this.__coco_route = new Slot(this, null, null);
		this.__coco_logout = new Slot(this, null, null);
		this.__coco_hideSidebar = new Slot(this, null, null);
		var fallback = new LazyFunc(function () {
			return new LanguageContext("en");
		});
		this.__coco_lang = new Slot(this, null, new AutoObservable(Computation.sync(function () {
			var _g = _gthis.context.get(TypeKey.ofClass(LanguageContext));
			if (_g == null) {
				return Lazy.get(fallback);
			} else {
				return _g;
			};
		}), null));
		this.__coco_localizer = new AutoObservable(Computation.async(function () {
			return FluentManagerBase.get(new Manager(new FluentProvider63(new ExtendedFluentSource("widget/sidebar", FluentManagerBase.getSource), {"useIsolating": false})), Observable.get_value(_gthis.__coco_lang).value);
		}), null);
		this.__coco_project = new AutoObservable(Computation.sync(function () {
			var _g = Observable.get_value(_gthis.__coco_route);
			if (_g._hx_index == 6) {
				return Observable.get_value(Observable.get_value(_gthis.__coco_app).__coco_projects).collection.map.get(_g.id, null);
			} else {
				return null;
			};
		}), null);
		this.__initAttributes(__coco_data_);
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	logout() {
		(Observable.get_value(this.__coco_logout))();
	}
	hideSidebar() {
		(Observable.get_value(this.__coco_hideSidebar))();
	}
	__coco_render() {
		var _gthis = this;
		var tmp = new AutoObservable(Computation.sync(function () {
			return Observable.get_value(_gthis.__coco_localizer);
		}), null);
		var tmp1 = new AutoObservable(Computation.sync(function () {
			return function (localizer) {
				var __r = [];
				var attributes = AuthenticatedRouteTools.toUrl(AuthenticatedRoute.Home);
				var attributes1 = {"className": "active teal title item", "style": {"borderRadius": "0"}, "href": Url.toString(attributes)};
				var __r1 = [];
				__r1.push(Html.h("div", {"style": {"backgroundColor": "rgba(0,0,0,.25)", "position": "absolute", "top": "0", "bottom": "0", "left": "0", "right": "0"}}, null));
				var children = [Html.h("strong", {}, ["BeeInventor IoT"])];
				__r1.push(Html.h("div", {"className": "content", "style": {"position": "relative"}}, children));
				__r.push(Html.h("a", attributes1, __r1));
				var __r1 = [];
				if (Observable.get_value(_gthis.__coco_project) != null) {
					var _g = Observable.get_value(Observable.get_value(_gthis.__coco_project).__coco_data);
					var children = (_g._hx_index == 1) ? _g.result.name : localizer.loading();
					__r1.push(Html.h("h4", {"className": "ui inverted centered header"}, [children]));
				};
				__r1.push(Html.h("a", {"className": "ui teal small fluid button", "href": Url.toString(AuthenticatedRouteTools.toUrl(AuthenticatedRoute.ProjectList)), "onClick": Register.bind(_gthis, _gthis.hideSidebar)}, [(Observable.get_value(_gthis.__coco_project) == null) ? localizer.selectProject() : localizer.switchProject()]));
				var children = [Html.h("div", {"className": "content"}, __r1)];
				__r.push(Html.h("div", {"className": "item", "style": {"paddingTop": "2em", "paddingBottom": "2em"}}, children));
				if (Observable.get_value(_gthis.__coco_project) != null) {
					var _g = Observable.get_value(_gthis.__coco_project).permissions.can("read", "workers");
					var _g1 = Observable.get_value(Observable.get_value(_gthis.__coco_project).features.__coco_features);
					switch (_g._hx_index) {
						case 1:
							var _g2 = _g.result;
							switch (_g1._hx_index) {
								case 1:
									var _g3 = _g1.result;
									var _gthis1 = _gthis;
									var compute = Observable.get_value(_gthis.__coco_project).id;
									var __r1 = [];
									__r1.push(Html.h("i", {"className": "pie chart icon"}, null));
									__r1.push(localizer.overview());
									__r.push(_gthis1.item({"to": AuthenticatedRoute.Project(compute, ProjectRoute.Project({}))}, __r1));
									var _gthis1 = _gthis;
									var compute = {"to": AuthenticatedRoute.Project(Observable.get_value(_gthis.__coco_project).id, ProjectRoute.Audio(AudioRoute.Announcement))};
									var __r1 = [];
									__r1.push(Html.h("i", {"className": "bullhorn icon"}, null));
									__r1.push(localizer.announcement());
									__r.push(_gthis1.item(compute, __r1));
									var _gthis1 = _gthis;
									var compute = Observable.get_value(_gthis.__coco_project).id;
									var __r1 = [];
									__r1.push(Html.h("i", {"className": "microchip icon"}, null));
									__r1.push(localizer.devices());
									__r.push(_gthis1.item({"to": AuthenticatedRoute.Project(compute, ProjectRoute.Device(DeviceRoute.List({})))}, __r1));
									if (_g2) {
										var _gthis1 = _gthis;
										var compute = {"to": AuthenticatedRoute.Project(Observable.get_value(_gthis.__coco_project).id, ProjectRoute.Worker(WorkerRoute.List))};
										var __r1 = [];
										__r1.push(Html.h("i", {"className": "users icon"}, null));
										__r1.push(localizer.workers());
										__r.push(_gthis1.item(compute, __r1));
									};
									var _gthis1 = _gthis;
									var compute = {"to": AuthenticatedRoute.Project(Observable.get_value(_gthis.__coco_project).id, ProjectRoute.Plant(GenericSubRoute.List))};
									var __r1 = [];
									__r1.push(Html.h("i", {"className": "tractor icon"}, null));
									__r1.push(localizer.plants());
									__r.push(_gthis1.item(compute, __r1));
									if (Lambda.exists(_g3, function (v) {
										return v.feature == 2;
									})) {
										var _gthis1 = _gthis;
										var compute = {"to": AuthenticatedRoute.Project(Observable.get_value(_gthis.__coco_project).id, ProjectRoute.Equipment(EquipmentRoute.Overview))};
										var __r1 = [];
										__r1.push(Html.h("i", {"className": "hdd icon"}, null));
										__r1.push(localizer.equipments());
										__r.push(_gthis1.item(compute, __r1));
									};
									var _gthis1 = _gthis;
									var compute = {"to": AuthenticatedRoute.Project(Observable.get_value(_gthis.__coco_project).id, ProjectRoute.AoaLocator(AoaLocatorRoute.List))};
									var __r1 = [];
									__r1.push(Html.h("i", {"className": "satellite dish icon"}, null));
									__r1.push(localizer.aoaLocator());
									__r.push(_gthis1.item(compute, __r1));
									var _gthis1 = _gthis;
									var compute = {"to": AuthenticatedRoute.Project(Observable.get_value(_gthis.__coco_project).id, ProjectRoute.Beacon(BeaconRoute.List))};
									var __r1 = [];
									__r1.push(Html.h("i", {"className": "satellite dish icon"}, null));
									__r1.push(localizer.beacons());
									__r.push(_gthis1.item(compute, __r1));
									var _gthis1 = _gthis;
									var compute = {"to": AuthenticatedRoute.Project(Observable.get_value(_gthis.__coco_project).id, ProjectRoute.Floorplan(GenericSubRoute.List))};
									var __r1 = [];
									__r1.push(Html.h("i", {"className": "layer group icon"}, null));
									__r1.push(localizer.floorPlans());
									__r.push(_gthis1.item(compute, __r1));
									var _gthis1 = _gthis;
									var compute = {"to": AuthenticatedRoute.Project(Observable.get_value(_gthis.__coco_project).id, ProjectRoute.Bim(GenericSubRoute.List))};
									var __r1 = [];
									__r1.push(Html.h("i", {"className": "building icon"}, null));
									__r1.push(localizer.bims());
									__r.push(_gthis1.item(compute, __r1));
									var _gthis1 = _gthis;
									var compute = {"to": AuthenticatedRoute.Project(Observable.get_value(_gthis.__coco_project).id, ProjectRoute.Area(GenericSubRoute.List))};
									var __r1 = [];
									__r1.push(Html.h("i", {"className": "draw polygon icon"}, null));
									__r1.push(localizer.areas());
									__r.push(_gthis1.item(compute, __r1));
									var _gthis1 = _gthis;
									var compute = {"to": AuthenticatedRoute.Project(Observable.get_value(_gthis.__coco_project).id, ProjectRoute.Statistics)};
									var __r1 = [];
									__r1.push(Html.h("i", {"className": "bar chart icon"}, null));
									__r1.push(localizer.statistics());
									__r.push(_gthis1.item(compute, __r1));
									var _gthis1 = _gthis;
									var compute = {"to": AuthenticatedRoute.Project(Observable.get_value(_gthis.__coco_project).id, ProjectRoute.Reports)};
									var __r1 = [];
									__r1.push(Html.h("i", {"className": "table icon"}, null));
									__r1.push(localizer.reports());
									__r.push(_gthis1.item(compute, __r1));
									var _gthis1 = _gthis;
									var compute = {"to": AuthenticatedRoute.Project(Observable.get_value(_gthis.__coco_project).id, ProjectRoute.Logs)};
									var __r1 = [];
									__r1.push(Html.h("i", {"className": "clipboard list icon"}, null));
									__r1.push(localizer.logs());
									__r.push(_gthis1.item(compute, __r1));
									if (Lambda.exists(_g3, function (v) {
										return v.feature == 3;
									})) {
										var _gthis1 = _gthis;
										var compute = {"to": AuthenticatedRoute.Project(Observable.get_value(_gthis.__coco_project).id, ProjectRoute.Cctv(CctvRoute.Root))};
										var __r1 = [];
										__r1.push(Html.h("i", {"className": "video icon"}, null));
										__r1.push(localizer.cctv());
										__r.push(_gthis1.item(compute, __r1));
									};
									var _gthis1 = _gthis;
									var compute = {"to": AuthenticatedRoute.Project(Observable.get_value(_gthis.__coco_project).id, ProjectRoute.Settings)};
									var __r1 = [];
									__r1.push(Html.h("i", {"className": "setting icon"}, null));
									__r1.push(localizer.settings());
									__r.push(_gthis1.item(compute, __r1));
									break
								case 2:
									var e = _g1.error;
									var compute = new AutoObservable(Computation.sync(function () {
										return e;
									}), null);
									__r.push(ErrorMessage.fromHxx({}, {"error": compute}));
									break
								default:
								__r.push(Spinner.fromHxx({}, {"inverted": new ConstObservable(true)}));
								__r.push(Html.h("br", {}));
								
							};
							break
						case 2:
							var e1 = _g.error;
							var compute = new AutoObservable(Computation.sync(function () {
								return e1;
							}), null);
							__r.push(ErrorMessage.fromHxx({}, {"error": compute}));
							break
						default:
						if (_g1._hx_index == 2) {
							var e2 = _g1.error;
							var compute = new AutoObservable(Computation.sync(function () {
								return e2;
							}), null);
							__r.push(ErrorMessage.fromHxx({}, {"error": compute}));
						} else {
							__r.push(Spinner.fromHxx({}, {"inverted": new ConstObservable(true)}));
							__r.push(Html.h("br", {}));
						};
						
					};
				} else {
					var _gthis1 = _gthis;
					var __r1 = [];
					__r1.push(Html.h("i", {"className": "setting icon"}, null));
					__r1.push(localizer.settings());
					__r.push(_gthis1.item({"to": AuthenticatedRoute.Settings}, __r1));
				};
				var children = [localizer.logout()];
				var children1 = [Html.h("div", {"className": "ui inverted basic teal small fluid button", "onClick": Register.bind(_gthis, _gthis.logout)}, children)];
				__r.push(Html.h("div", {"className": "item", "style": {"paddingTop": "5em"}}, children1));
				return View.createFragment({}, __r);
			};
		}), null);
		return PromisedView.fromHxx({}, {"value": tmp, "renderDone": tmp1});
	}
	item(attr, children) {
		var href = AuthenticatedRouteTools.toUrl(attr.to);
		var attributes = {"className": ClassName.ofString("item " + ((Observable.get_value(Location.url).path == Url.toString(href)) ? "active teal" : "")), "href": Url.toString(href), "onClick": Register.bind(this, this.hideSidebar)};
		var __r = [];
		var _g = 0;
		while (_g < ((children == null) ? 0 : children.length)) {
			var _0 = (children == null) ? null : children[_g];
			++_g;
			__r.push(_0);
		};
		return Html.h("a", attributes, __r);
	}
	__initAttributes(attributes) {
		this.__coco_app.setData(attributes.app);
		this.__coco_route.setData(attributes.route);
		this.__coco_logout.setData(attributes.logout);
		this.__coco_hideSidebar.setData(attributes.hideSidebar);
		this.__coco_lang.setData(attributes.lang);
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": SideBar, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "dasloop.app.ui.SideBar"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return SideBar
	}
}


SideBar.contextType = Implicit.contextType
export const AppContent = Register.global("$hxClasses")["dasloop.app.ui.AppContent"] = 
class AppContent extends Register.inherits(View) {
	new(__coco_data_) {
		var _gthis = this;
		this.__coco_app = new Slot(this, null, null);
		this.__coco_route = new Slot(this, null, null);
		this.__coco_user = new Slot(this, null, null);
		this.__coco_getToken = new Slot(this, null, null);
		this.__coco_changePassword = new Slot(this, null, null);
		var fallback = new LazyFunc(function () {
			return new LanguageContext("en");
		});
		this.__coco_lang = new Slot(this, null, new AutoObservable(Computation.sync(function () {
			var _g = _gthis.context.get(TypeKey.ofClass(LanguageContext));
			if (_g == null) {
				return Lazy.get(fallback);
			} else {
				return _g;
			};
		}), null));
		this.__coco_localizer = new AutoObservable(Computation.async(function () {
			return FluentManagerBase.get(new Manager(new FluentProvider2(new ExtendedFluentSource("widget/app-container", FluentManagerBase.getSource), {"useIsolating": false})), Observable.get_value(_gthis.__coco_lang).value);
		}), null);
		this.__initAttributes(__coco_data_);
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	getToken() {
		return (Observable.get_value(this.__coco_getToken))();
	}
	changePassword(a0) {
		(Observable.get_value(this.__coco_changePassword))(a0);
	}
	__coco_render() {
		var _gthis = this;
		var tmp = new AutoObservable(Computation.sync(function () {
			return Observable.get_value(_gthis.__coco_localizer);
		}), null);
		var tmp1 = new AutoObservable(Computation.sync(function () {
			return function (localizer) {
				var _g = Observable.get_value(_gthis.__coco_route);
				var children;
				switch (_g._hx_index) {
					case 1:
						var children1 = new AutoObservable(Computation.sync(function () {
							return function () {
								return Future.ofJsPromise(import("./page/SettingsPage.js").then(function (module) {
									var SettingsPage = module.SettingsPage;
									var promise = new AutoObservable(Computation.sync(function () {
										return Observable.get_value(Observable.get_value(_gthis.__coco_app).__coco_user);
									}), null);
									var promise1 = State.compound(State.get_value(Observable.get_value(_gthis.__coco_app).__coco_language).observables.code, (o=>Register.bind(o, o.setLanguage))(State.get_value(Observable.get_value(_gthis.__coco_app).__coco_language)));
									var promise2 = new AutoObservable(Computation.sync(function () {
										return function () {
											_gthis.changePassword();
										};
									}), null);
									return SettingsPage.fromHxx({}, {"user": promise, "languageCode": promise1, "onChangePasswordClick": promise2});
								}), null);
							};
						}), null);
						children = ViewLoader.fromHxx({"key": "SettingsPage"}, {"renderView": children1});
						break
					case 2:
						var children1 = new AutoObservable(Computation.sync(function () {
							return function () {
								return Future.ofJsPromise(import("./controller/UserSummaryController.js").then(function (module) {
									var UserSummaryController = module.UserSummaryController;
									var promise = new AutoObservable(Computation.sync(function () {
										return Observable.get_value(_gthis.__coco_app);
									}), null);
									return UserSummaryController.fromHxx({}, {"app": promise});
								}), null);
							};
						}), null);
						children = ViewLoader.fromHxx({"key": "UserSummaryController"}, {"renderView": children1});
						break
					case 0:case 3:
						var children1 = new AutoObservable(Computation.sync(function () {
							return function () {
								return Future.ofJsPromise(import("./page/project/ListPage.js").then(function (module) {
									var ListPage = module.ListPage;
									var promise = new AutoObservable(Computation.sync(function () {
										return Observable.get_value(Observable.get_value(_gthis.__coco_app).__coco_projects);
									}), null);
									return ListPage.fromHxx({}, {"projects": promise});
								}), null);
							};
						}), null);
						children = ViewLoader.fromHxx({"key": "ProjectListPage"}, {"renderView": children1});
						break
					case 4:
						var children1 = new AutoObservable(Computation.sync(function () {
							return function () {
								return Future.ofJsPromise(import("./page/project/CreatePage.js").then(function (module) {
									var CreatePage = module.CreatePage;
									var promise = new AutoObservable(Computation.sync(function () {
										return Observable.get_value(Observable.get_value(_gthis.__coco_app).__coco_projects);
									}), null);
									return CreatePage.fromHxx({}, {"projects": promise});
								}), null);
							};
						}), null);
						children = ViewLoader.fromHxx({"key": "ProjectCreatePage"}, {"renderView": children1});
						break
					case 5:
						var sub = _g.sub;
						var children1 = new AutoObservable(Computation.sync(function () {
							return function () {
								return Future.ofJsPromise(import("./page/AssignProjectPage.js").then(function (module) {
									var AssignProjectPage = module.AssignProjectPage;
									var promise = new AutoObservable(Computation.sync(function () {
										return Observable.get_value(Observable.get_value(_gthis.__coco_app).__coco_projects);
									}), null);
									var promise1 = new AutoObservable(Computation.sync(function () {
										return sub;
									}), null);
									return AssignProjectPage.fromHxx({}, {"projects": promise, "route": promise1});
								}), null);
							};
						}), null);
						children = ViewLoader.fromHxx({"key": "AssignProjectPage"}, {"renderView": children1});
						break
					case 6:
						var sub1 = _g.sub;
						var project = Observable.get_value(Observable.get_value(_gthis.__coco_app).__coco_projects).collection.map.get(_g.id, null);
						var children1 = new AutoObservable(Computation.sync(function () {
							return project;
						}), null);
						var children2 = new AutoObservable(Computation.sync(function () {
							return localizer.projectExpiryGuard;
						}), null);
						var children3 = new AutoObservable(Computation.sync(function () {
							var compute;
							switch (sub1._hx_index) {
								case 0:
									var options = sub1.options;
									var compute1 = new AutoObservable(Computation.sync(function () {
										return function () {
											return Future.ofJsPromise(import("./page/project/OverviewPage.js").then(function (module) {
												var OverviewPage = module.OverviewPage;
												var promise = new AutoObservable(Computation.sync(function () {
													return Observable.get_value(Observable.get_value(_gthis.__coco_app).__coco_uwbTags);
												}), null);
												var promise1 = new AutoObservable(Computation.sync(function () {
													return project;
												}), null);
												var promise2 = new AutoObservable(Computation.sync(function () {
													return options.view;
												}), null);
												return OverviewPage.fromHxx({}, {"uwbTags": promise, "project": promise1, "view": promise2});
											}), null);
										};
									}), null);
									compute = ViewLoader.fromHxx({"key": "ProjectOverviewPage"}, {"renderView": compute1});
									break
								case 1:
									var compute1 = new AutoObservable(Computation.sync(function () {
										return function () {
											return Future.ofJsPromise(import("./page/project/EditPage.js").then(function (module) {
												var EditPage = module.EditPage;
												var promise = new AutoObservable(Computation.sync(function () {
													return project;
												}), null);
												return EditPage.fromHxx({}, {"project": promise});
											}), null);
										};
									}), null);
									compute = ViewLoader.fromHxx({"key": "ProjectEditPage"}, {"renderView": compute1});
									break
								case 2:
									var sub = sub1.sub;
									var compute1 = new AutoObservable(Computation.sync(function () {
										return function () {
											return Future.ofJsPromise(import("./page/DevicePage.js").then(function (module) {
												var DevicePage = module.DevicePage;
												var promise = new AutoObservable(Computation.sync(function () {
													return Observable.get_value(Observable.get_value(_gthis.__coco_app).__coco_user);
												}), null);
												var promise1 = new AutoObservable(Computation.sync(function () {
													return Observable.get_value(Observable.get_value(_gthis.__coco_app).__coco_projects);
												}), null);
												var promise2 = new AutoObservable(Computation.sync(function () {
													return project;
												}), null);
												var promise3 = new AutoObservable(Computation.sync(function () {
													return sub;
												}), null);
												return DevicePage.fromHxx({}, {"user": promise, "projects": promise1, "project": promise2, "route": promise3});
											}), null);
										};
									}), null);
									compute = ViewLoader.fromHxx({"key": "DevicePage"}, {"renderView": compute1});
									break
								case 3:
									var sub2 = sub1.sub;
									var compute1 = new AutoObservable(Computation.sync(function () {
										return function () {
											return Future.ofJsPromise(import("./page/FloorplanPage.js").then(function (module) {
												var FloorplanPage = module.FloorplanPage;
												var promise = new AutoObservable(Computation.sync(function () {
													return project;
												}), null);
												var promise1 = new AutoObservable(Computation.sync(function () {
													return sub2;
												}), null);
												return FloorplanPage.fromHxx({}, {"project": promise, "route": promise1});
											}), null);
										};
									}), null);
									compute = ViewLoader.fromHxx({"key": "FloorplanPage"}, {"renderView": compute1});
									break
								case 4:
									var sub3 = sub1.sub;
									var compute1 = new AutoObservable(Computation.sync(function () {
										return function () {
											return Future.ofJsPromise(import("./page/BimPage.js").then(function (module) {
												var BimPage = module.BimPage;
												var promise = new AutoObservable(Computation.sync(function () {
													return project;
												}), null);
												var promise1 = new AutoObservable(Computation.sync(function () {
													return sub3;
												}), null);
												return BimPage.fromHxx({}, {"project": promise, "route": promise1});
											}), null);
										};
									}), null);
									compute = ViewLoader.fromHxx({"key": "BimPage"}, {"renderView": compute1});
									break
								case 5:
									var sub4 = sub1.sub;
									var compute1 = new AutoObservable(Computation.sync(function () {
										return function () {
											return Future.ofJsPromise(import("./page/AreaPage.js").then(function (module) {
												var AreaPage = module.AreaPage;
												var promise = new AutoObservable(Computation.sync(function () {
													return project;
												}), null);
												var promise1 = new AutoObservable(Computation.sync(function () {
													return sub4;
												}), null);
												return AreaPage.fromHxx({}, {"project": promise, "route": promise1});
											}), null);
										};
									}), null);
									compute = ViewLoader.fromHxx({"key": "AreaPage"}, {"renderView": compute1});
									break
								case 6:
									var sub5 = sub1.sub;
									var compute1 = new AutoObservable(Computation.sync(function () {
										return function () {
											return Future.ofJsPromise(import("./page/PlantPage.js").then(function (module) {
												var PlantPage = module.PlantPage;
												var promise = new AutoObservable(Computation.sync(function () {
													return project;
												}), null);
												var promise1 = new AutoObservable(Computation.sync(function () {
													return sub5;
												}), null);
												return PlantPage.fromHxx({}, {"project": promise, "route": promise1});
											}), null);
										};
									}), null);
									compute = ViewLoader.fromHxx({"key": "PlantPage"}, {"renderView": compute1});
									break
								case 7:
									var sub6 = sub1.sub;
									var compute1 = new AutoObservable(Computation.sync(function () {
										return function () {
											return Future.ofJsPromise(import("./page/AoaLocatorPage.js").then(function (module) {
												var AoaLocatorPage = module.AoaLocatorPage;
												var promise = new AutoObservable(Computation.sync(function () {
													return Observable.get_value(Observable.get_value(_gthis.__coco_app).__coco_projects);
												}), null);
												var promise1 = new AutoObservable(Computation.sync(function () {
													return project;
												}), null);
												var promise2 = new AutoObservable(Computation.sync(function () {
													return sub6;
												}), null);
												return AoaLocatorPage.fromHxx({}, {"projects": promise, "project": promise1, "route": promise2});
											}), null);
										};
									}), null);
									compute = ViewLoader.fromHxx({"key": "AoaLocatorPage"}, {"renderView": compute1});
									break
								case 8:
									var sub7 = sub1.sub;
									var compute1 = new AutoObservable(Computation.sync(function () {
										return function () {
											return Future.ofJsPromise(import("./page/BeaconPage.js").then(function (module) {
												var BeaconPage = module.BeaconPage;
												var promise = new AutoObservable(Computation.sync(function () {
													return Observable.get_value(Observable.get_value(_gthis.__coco_app).__coco_projects);
												}), null);
												var promise1 = new AutoObservable(Computation.sync(function () {
													return project;
												}), null);
												var promise2 = new AutoObservable(Computation.sync(function () {
													return sub7;
												}), null);
												return BeaconPage.fromHxx({}, {"projects": promise, "project": promise1, "route": promise2});
											}), null);
										};
									}), null);
									compute = ViewLoader.fromHxx({"key": "BeaconPage"}, {"renderView": compute1});
									break
								case 9:
									var sub8 = sub1.sub;
									var compute1 = new AutoObservable(Computation.sync(function () {
										return function () {
											return Future.ofJsPromise(import("./page/WorkerPage.js").then(function (module) {
												var WorkerPage = module.WorkerPage;
												var promise = new AutoObservable(Computation.sync(function () {
													return project;
												}), null);
												var promise1 = new AutoObservable(Computation.sync(function () {
													return sub8;
												}), null);
												return WorkerPage.fromHxx({}, {"project": promise, "route": promise1});
											}), null);
										};
									}), null);
									compute = ViewLoader.fromHxx({"key": "WorkerPage"}, {"renderView": compute1});
									break
								case 10:
									var sub9 = sub1.sub;
									var compute1 = new AutoObservable(Computation.sync(function () {
										return function () {
											return Future.ofJsPromise(import("./page/WorkergroupPage.js").then(function (module) {
												var WorkergroupPage = module.WorkergroupPage;
												var promise = new AutoObservable(Computation.sync(function () {
													return project;
												}), null);
												var promise1 = new AutoObservable(Computation.sync(function () {
													return sub9;
												}), null);
												return WorkergroupPage.fromHxx({}, {"project": promise, "route": promise1});
											}), null);
										};
									}), null);
									compute = ViewLoader.fromHxx({"key": "WorkergroupPage"}, {"renderView": compute1});
									break
								case 11:
									var sub10 = sub1.sub;
									var compute1 = new AutoObservable(Computation.sync(function () {
										return function () {
											return Future.ofJsPromise(import("./page/EquipmentPage.js").then(function (module) {
												var EquipmentPage = module.EquipmentPage;
												var promise = new AutoObservable(Computation.sync(function () {
													return project;
												}), null);
												var promise1 = new AutoObservable(Computation.sync(function () {
													return sub10;
												}), null);
												return EquipmentPage.fromHxx({}, {"project": promise, "route": promise1});
											}), null);
										};
									}), null);
									compute = ViewLoader.fromHxx({"key": "EquipmentPage"}, {"renderView": compute1});
									break
								case 12:
									var sub11 = sub1.sub;
									var compute1 = new AutoObservable(Computation.sync(function () {
										return function () {
											return Future.ofJsPromise(import("./page/EquipmentgroupPage.js").then(function (module) {
												var EquipmentgroupPage = module.EquipmentgroupPage;
												var promise = new AutoObservable(Computation.sync(function () {
													return project;
												}), null);
												var promise1 = new AutoObservable(Computation.sync(function () {
													return sub11;
												}), null);
												return EquipmentgroupPage.fromHxx({}, {"project": promise, "route": promise1});
											}), null);
										};
									}), null);
									compute = ViewLoader.fromHxx({"key": "EquipmentgroupPage"}, {"renderView": compute1});
									break
								case 13:
									var sub12 = sub1.sub;
									var compute1 = new AutoObservable(Computation.sync(function () {
										return function () {
											return Future.ofJsPromise(import("./page/TaskPage.js").then(function (module) {
												var TaskPage = module.TaskPage;
												var promise = new AutoObservable(Computation.sync(function () {
													return project;
												}), null);
												var promise1 = new AutoObservable(Computation.sync(function () {
													return sub12;
												}), null);
												return TaskPage.fromHxx({}, {"project": promise, "route": promise1});
											}), null);
										};
									}), null);
									compute = ViewLoader.fromHxx({"key": "TaskPage"}, {"renderView": compute1});
									break
								case 14:
									var sub13 = sub1.sub;
									var compute1 = new AutoObservable(Computation.sync(function () {
										return function () {
											return Future.ofJsPromise(import("./page/AudioPage.js").then(function (module) {
												var AudioPage = module.AudioPage;
												var promise = new AutoObservable(Computation.sync(function () {
													return project;
												}), null);
												var promise1 = new AutoObservable(Computation.sync(function () {
													return sub13;
												}), null);
												return AudioPage.fromHxx({}, {"project": promise, "route": promise1});
											}), null);
										};
									}), null);
									compute = ViewLoader.fromHxx({"key": "AudioPage"}, {"renderView": compute1});
									break
								case 15:
									var sub14 = sub1.sub;
									var compute1 = new AutoObservable(Computation.sync(function () {
										return function () {
											return Future.ofJsPromise(import("./page/CctvPage.js").then(function (module) {
												var CctvPage = module.CctvPage;
												var promise = new AutoObservable(Computation.sync(function () {
													return project;
												}), null);
												var promise1 = new AutoObservable(Computation.sync(function () {
													return sub14;
												}), null);
												return CctvPage.fromHxx({}, {"project": promise, "route": promise1});
											}), null);
										};
									}), null);
									compute = ViewLoader.fromHxx({"key": "CctvPage"}, {"renderView": compute1});
									break
								case 16:
									var compute1 = new AutoObservable(Computation.sync(function () {
										return function () {
											return Future.ofJsPromise(import("./page/StatisticsPage.js").then(function (module) {
												var StatisticsPage = module.StatisticsPage;
												var promise = new AutoObservable(Computation.sync(function () {
													return project;
												}), null);
												return StatisticsPage.fromHxx({}, {"project": promise});
											}), null);
										};
									}), null);
									compute = ViewLoader.fromHxx({"key": "StatisticsPage"}, {"renderView": compute1});
									break
								case 17:
									var compute1 = new AutoObservable(Computation.sync(function () {
										return function () {
											return Future.ofJsPromise(import("./page/ReportsPage.js").then(function (module) {
												var ReportsPage = module.ReportsPage;
												var promise = new AutoObservable(Computation.sync(function () {
													return project;
												}), null);
												var promise1 = new AutoObservable(Computation.sync(function () {
													return Register.bind(_gthis, _gthis.getToken);
												}), null);
												return ReportsPage.fromHxx({}, {"project": promise, "getToken": promise1});
											}), null);
										};
									}), null);
									compute = ViewLoader.fromHxx({"key": "ReportsPage"}, {"renderView": compute1});
									break
								case 18:
									var compute1 = new AutoObservable(Computation.sync(function () {
										return function () {
											return Future.ofJsPromise(import("./controller/ProjectLogsController.js").then(function (module) {
												var ProjectLogsController = module.ProjectLogsController;
												var promise = new AutoObservable(Computation.sync(function () {
													return project;
												}), null);
												var promise1 = new AutoObservable(Computation.sync(function () {
													return project.logs;
												}), null);
												return ProjectLogsController.fromHxx({}, {"project": promise, "logs": promise1});
											}), null);
										};
									}), null);
									compute = ViewLoader.fromHxx({"key": "ProjectLogsController"}, {"renderView": compute1});
									break
								case 19:
									var compute1 = new AutoObservable(Computation.sync(function () {
										return function () {
											return Future.ofJsPromise(import("./page/MaintenanceDevicePage.js").then(function (module) {
												var MaintenanceDevicePage = module.MaintenanceDevicePage;
												return MaintenanceDevicePage.fromHxx({}, {});
											}), null);
										};
									}), null);
									compute = ViewLoader.fromHxx({"key": "MaintenanceDevicePage"}, {"renderView": compute1});
									break
								case 20:
									var compute1 = new AutoObservable(Computation.sync(function () {
										return function () {
											return Future.ofJsPromise(import("./page/SettingsPage.js").then(function (module) {
												var SettingsPage = module.SettingsPage;
												var promise = new AutoObservable(Computation.sync(function () {
													return Observable.get_value(Observable.get_value(_gthis.__coco_app).__coco_user);
												}), null);
												var promise1 = new AutoObservable(Computation.sync(function () {
													return project;
												}), null);
												var promise2 = State.compound(State.get_value(Observable.get_value(_gthis.__coco_app).__coco_language).observables.code, (o=>Register.bind(o, o.setLanguage))(State.get_value(Observable.get_value(_gthis.__coco_app).__coco_language)));
												var promise3 = new AutoObservable(Computation.sync(function () {
													return function () {
														_gthis.changePassword();
													};
												}), null);
												return SettingsPage.fromHxx({}, {"user": promise, "project": promise1, "languageCode": promise2, "onChangePasswordClick": promise3});
											}), null);
										};
									}), null);
									compute = ViewLoader.fromHxx({"key": "SettingsPage"}, {"renderView": compute1});
									break
								
							};
							return [View.createFragment({}, [compute])];
						}), null);
						children = ProjectExpiryGuard.fromHxx({}, {"project": children1, "localizer": children2, "children": children3});
						break
					case 7:
						var sub2 = _g.sub;
						var children1 = new AutoObservable(Computation.sync(function () {
							return function () {
								return Future.ofJsPromise(import("./page/WellKnownPage.js").then(function (module) {
									var WellKnownPage = module.WellKnownPage;
									var promise = new AutoObservable(Computation.sync(function () {
										return sub2;
									}), null);
									var promise1 = new AutoObservable(Computation.sync(function () {
										return Register.bind(_gthis, _gthis.changePassword);
									}), null);
									return WellKnownPage.fromHxx({}, {"route": promise, "changePassword": promise1});
								}), null);
							};
						}), null);
						children = ViewLoader.fromHxx({"key": "WellKnownPage"}, {"renderView": children1});
						break
					
				};
				return Html.h("div", {"className": "ui basic segment", "style": {"flex": "1", "display": "flex", "flexDirection": "column", "maxWidth": "100vw"}}, [children]);
			};
		}), null);
		return PromisedView.fromHxx({}, {"value": tmp, "renderDone": tmp1});
	}
	__initAttributes(attributes) {
		this.__coco_app.setData(attributes.app);
		this.__coco_route.setData(attributes.route);
		this.__coco_user.setData(attributes.user);
		this.__coco_getToken.setData(attributes.getToken);
		this.__coco_changePassword.setData(attributes.changePassword);
		this.__coco_lang.setData(attributes.lang);
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": AppContent, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "dasloop.app.ui.AppContent"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return AppContent
	}
}


AppContent.contextType = Implicit.contextType
export const NavBar = Register.global("$hxClasses")["dasloop.app.ui.NavBar"] = 
class NavBar extends Register.inherits(View) {
	new(__coco_data_) {
		var _gthis = this;
		this.__coco_app = new Slot(this, null, null);
		this.__coco_route = new Slot(this, null, null);
		this.__coco_menuButtonClass = new Slot(this, null, null);
		this.__coco_toggleSidebar = new Slot(this, null, null);
		var fallback = new LazyFunc(function () {
			return new LanguageContext("en");
		});
		this.__coco_lang = new Slot(this, null, new AutoObservable(Computation.sync(function () {
			var _g = _gthis.context.get(TypeKey.ofClass(LanguageContext));
			if (_g == null) {
				return Lazy.get(fallback);
			} else {
				return _g;
			};
		}), null));
		this.__coco_localizer = new AutoObservable(Computation.async(function () {
			return FluentManagerBase.get(new Manager(new FluentProvider1(new ExtendedFluentSource("widget/navbar", FluentManagerBase.getSource), {"useIsolating": false})), Observable.get_value(_gthis.__coco_lang).value);
		}), null);
		this.__initAttributes(__coco_data_);
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	toggleSidebar() {
		(Observable.get_value(this.__coco_toggleSidebar))();
	}
	__coco_render() {
		var _gthis = this;
		var __r = [];
		__r.push(Html.h("a", {"className": ClassName.ofString("" + Observable.get_value(this.__coco_menuButtonClass) + " icon item"), "onClick": Register.bind(this, this.toggleSidebar)}, [Html.h("i", {"className": "sidebar icon", "style": {"marginRight": "0"}}, null)]));
		var tmp = new AutoObservable(Computation.sync(function () {
			return Observable.get_value(_gthis.__coco_localizer);
		}), null);
		var tmp1 = new AutoObservable(Computation.sync(function () {
			return function (localizer) {
				var __r = [];
				var _g = Observable.get_value(_gthis.__coco_route);
				if (_g._hx_index == 6) {
					var _g1 = Observable.get_value(Observable.get_value(Observable.get_value(_gthis.__coco_app).__coco_projects).collection.map.get(_g.id, null).__coco_data);
					var children = (_g1._hx_index == 1) ? _g1.result.name : localizer.loading();
					__r.push(Html.h("div", {"className": "tablet or lower visible item"}, [children]));
				};
				var _g = Observable.get_value(Observable.get_value(Observable.get_value(_gthis.__coco_app).__coco_user).__coco_profile);
				if (_g._hx_index == 1) {
					var _g1 = _g.result;
					var props = Html.h("img", {"className": "ui avatar image", "src": "https://fakeimg.pl/56x56/fbbe08/fff/?font_size=28&font=museo&text=" + _g1.email.charAt(0).toUpperCase() + _g1.email.charAt(1).toLowerCase()});
					var __r1 = [];
					var children = [ReactType.fromComp(Dropdown.Header), {"icon": "envelope", "content": _g1.email}].concat(null);
					__r1.push(React.createElement.apply(React, children));
					__r1.push(Html.h("a", {"className": "item", "href": Url.toString(AuthenticatedRouteTools.toUrl(AuthenticatedRoute.UserSummary))}, [localizer.summary()]));
					var children = [ReactType.fromComp(Dropdown.Menu), {}].concat(__r1);
					var children1 = [React.createElement.apply(React, children)];
					var compute = [ReactType.fromComp(Dropdown), {"className": "right icon", "item": true, "floating": true, "style": {"paddingTop": "0", "paddingBottom": "0"}, "trigger": props}].concat(children1);
					__r.push(React.createElement.apply(React, compute));
				};
				return View.createFragment({}, __r);
			};
		}), null);
		__r.push(PromisedView.fromHxx({}, {"value": tmp, "renderDone": tmp1}));
		return Html.h("div", {"className": "ui inverted teal sticky attached menu"}, __r);
	}
	__initAttributes(attributes) {
		this.__coco_app.setData(attributes.app);
		this.__coco_route.setData(attributes.route);
		this.__coco_menuButtonClass.setData(attributes.menuButtonClass);
		this.__coco_toggleSidebar.setData(attributes.toggleSidebar);
		this.__coco_lang.setData(attributes.lang);
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": NavBar, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "dasloop.app.ui.NavBar"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return NavBar
	}
}


NavBar.contextType = Implicit.contextType
export const Footer = Register.global("$hxClasses")["dasloop.app.ui.Footer"] = 
class Footer extends Register.inherits(View) {
	new(__coco_data_) {
		var _gthis = this;
		var fallback = new LazyFunc(function () {
			return new LanguageContext("en");
		});
		this.__coco_lang = new Slot(this, null, new AutoObservable(Computation.sync(function () {
			var _g = _gthis.context.get(TypeKey.ofClass(LanguageContext));
			if (_g == null) {
				return Lazy.get(fallback);
			} else {
				return _g;
			};
		}), null));
		this.__coco_localizer = new AutoObservable(Computation.async(function () {
			return FluentManagerBase.get(new Manager(new FluentProvider0(new ExtendedFluentSource("widget/footer", FluentManagerBase.getSource), {"useIsolating": false})), Observable.get_value(_gthis.__coco_lang).value);
		}), null);
		this.__initAttributes(__coco_data_);
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	__coco_render() {
		var _gthis = this;
		var tmp = new AutoObservable(Computation.sync(function () {
			return Observable.get_value(_gthis.__coco_localizer);
		}), null);
		var tmp1 = new AutoObservable(Computation.sync(function () {
			return function (localizer) {
				var compute = new AutoObservable(Computation.sync(function () {
					return localizer;
				}), null);
				return Footer__1.fromHxx({}, {"localizer": compute});
			};
		}), null);
		return PromisedView.fromHxx({}, {"value": tmp, "renderDone": tmp1});
	}
	__initAttributes(attributes) {
		this.__coco_lang.setData(attributes.lang);
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": Footer, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "dasloop.app.ui.Footer"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return Footer
	}
}


Footer.contextType = Implicit.contextType