import {RemoteBase, RemoteEndpoint, ResponseReader} from "./Remote.js"
import {Single} from "../../streams/Stream.js"
import {Builder6} from "../../querystring/Builder6.js"
import {Parser32} from "../../json/Parser32.js"
import {HeaderField, HeaderValue} from "../../http/Header.js"
import {LazyConst} from "../../core/Lazy.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote42 = Register.global("$hxClasses")["tink.web.proxy.Remote42"] = 
class Remote42 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	getLiveCallRecordingUrls(query) {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["live_call_recordings"], "query": [].concat(new Builder6().stringify({"_165": query.after, "_166": query.before})), "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pc))(new Parser32())));
	}
	static get __name__() {
		return "tink.web.proxy.Remote42"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote42
	}
}

