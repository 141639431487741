import {ReactType} from "../../react/ReactType.js"
import * as React from "react"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const CustomAttr = Register.global("$hxClasses")["coconut.react._Html.CustomAttr"] = 
class CustomAttr {
	static ofBool(b) {
		if (b != true) {
			return undefined;
		} else {
			return "";
		};
	}
	static get __name__() {
		return "coconut.react._Html.CustomAttr_Impl_"
	}
	get __class__() {
		return CustomAttr
	}
}


export const Html = Register.global("$hxClasses")["coconut.react.Html"] = 
class Html {
	static h(tag, attr, children) {
		if (children == null) {
			return React.createElement(ReactType.fromString(tag), attr);
		} else {
			var tmp = [tag, attr].concat(children);
			return React.createElement.apply(null, tmp);
		};
	}
	static get __name__() {
		return "coconut.react.Html"
	}
	get __class__() {
		return Html
	}
}

