import {FluentLocaleBase} from "./FluentProvider.js"
import {FluentLocale127} from "./FluentLocale127.js"
import {Register} from "../../genes/Register.js"
import {AppContentLocalizer} from "../../dasloop/app/localizer/AppContentLocalizer.js"

const $global = Register.$global

export const FluentLocale126 = Register.global("$hxClasses")["turnwing.provider.FluentLocale126"] = 
class FluentLocale126 extends Register.inherits(FluentLocaleBase) {
	new(__bundle__, __prefix__) {
		super.new(__bundle__, __prefix__);
		this.projectExpiryGuard = new FluentLocale127(__bundle__, (__prefix__ == "") ? "projectExpiryGuard" : __prefix__ + "-" + "projectExpiryGuard");
	}
	pageNotFound() {
		return this.__exec__("pageNotFound", {});
	}
	error() {
		return this.__exec__("error", {});
	}
	unknownError() {
		return this.__exec__("unknownError", {});
	}
	static get __name__() {
		return "turnwing.provider.FluentLocale126"
	}
	static get __interfaces__() {
		return [AppContentLocalizer]
	}
	static get __super__() {
		return FluentLocaleBase
	}
	get __class__() {
		return FluentLocale126
	}
}

