import {Remote12} from "./Remote12.js"
import {RemoteBase, RemoteEndpoint, ResponseReader} from "./Remote.js"
import {Portion} from "../../url/Portion.js"
import {Single} from "../../streams/Stream.js"
import {Writer17} from "../../json/Writer17.js"
import {Parser20} from "../../json/Parser20.js"
import {Parser19} from "../../json/Parser19.js"
import {RealSourceTools} from "../../io/Source.js"
import {HeaderField, HeaderValue} from "../../http/Header.js"
import {Promise as Promise__1} from "../../core/Promise.js"
import {Outcome} from "../../core/Outcome.js"
import {LazyConst} from "../../core/Lazy.js"
import {SyncFuture} from "../../core/Future.js"
import {TypedError} from "../../core/Error.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote27 = Register.global("$hxClasses")["tink.web.proxy.Remote27"] = 
class Remote27 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	get() {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [], "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser19())));
	}
	update(body) {
		var __body__ = ByteChunk.of(Bytes.ofString(new Writer17().wc({"_114": body.name})));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [], "headers": [new HeaderField("content-type", "application/json"), new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "PUT", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser19())));
	}
	workers() {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["workers"], "query": [], "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser20())));
	}
	contacts() {
		return new Remote12(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["contacts"], "query": [], "headers": [].concat([])}));
	}
	addWorker(id) {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["workers", Portion.ofString(id)], "query": [], "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength()))].concat([])}), this.client, "POST", new Single(new LazyConst(__body__)), function (header, body) {
			if (header.statusCode >= 400) {
				return Promise__1.next(RealSourceTools.all(body), function (chunk) {
					return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(header.statusCode, chunk.toString(), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_web/0.3.0/github/5f481ef34ae04a352700ac2c9682e6c96c27bbf5/src/tink/web/macros/Proxify.hx", "lineNumber": 173, "className": "tink.web.proxy.Remote27", "methodName": "addWorker"}))));
				});
			} else {
				return Promise__1.NOISE;
			};
		});
	}
	removeWorker(id) {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["workers", Portion.ofString(id)], "query": [], "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength()))].concat([])}), this.client, "DELETE", new Single(new LazyConst(__body__)), function (header, body) {
			if (header.statusCode >= 400) {
				return Promise__1.next(RealSourceTools.all(body), function (chunk) {
					return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(header.statusCode, chunk.toString(), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_web/0.3.0/github/5f481ef34ae04a352700ac2c9682e6c96c27bbf5/src/tink/web/macros/Proxify.hx", "lineNumber": 173, "className": "tink.web.proxy.Remote27", "methodName": "removeWorker"}))));
				});
			} else {
				return Promise__1.NOISE;
			};
		});
	}
	static get __name__() {
		return "tink.web.proxy.Remote27"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote27
	}
}

