import {BytesInput} from "../../haxe/io/BytesInput.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const DecoderBase = Register.global("$hxClasses")["tink.serialize.DecoderBase"] = 
class DecoderBase extends Register.inherits() {
	new() {
	}
	reset(data) {
		this.wrapped = data;
		this.input = new BytesInput(data);
		this.src = data.b.bufferValue;
		this.pos = 0;
		this.max = data.length;
	}
	string() {
		var end = this.pos;
		while (this.src.bytes[end] != 255) ++end;
		var ret = this.wrapped.getString(this.pos, end - this.pos);
		this.pos = end + 1;
		return ret;
	}
	float() {
		this.input.set_position(this.pos);
		var ret = this.input.readDouble();
		this.pos = this.input.pos;
		return ret;
	}
	int32() {
		this.input.set_position(this.pos);
		var ret = this.input.readInt32();
		this.pos = this.input.pos;
		return ret;
	}
	dynInt() {
		var ret = this.src.bytes[this.pos++];
		if (ret < 128) {
			return ret;
		} else if (ret < 192) {
			return (ret ^ 128) << 8 | this.src.bytes[this.pos++];
		} else if (ret < 224) {
			return (ret ^ 192) << 16 | this.src.bytes[this.pos++] << 8 | this.src.bytes[this.pos++];
		} else {
			return this.int32();
		};
	}
	static get __name__() {
		return "tink.serialize.DecoderBase"
	}
	get __class__() {
		return DecoderBase
	}
}

