import {BasicParser, JsonString} from "./Parser.js"
import {TypedError} from "../core/Error.js"
import {Option} from "../../haxe/ds/Option.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Parser75 = Register.global("$hxClasses")["tink.json.Parser75"] = 
class Parser75 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	pb() {
		var _gthis = this;
		var v_date = null;
		var hasv_date = false;
		var v_heading = null;
		var hasv_heading = false;
		var v_point = null;
		var hasv_point = false;
		var v_speed = .0;
		var hasv_speed = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "date":
						v_date = new Date(JsonString.f(this.r()));
						hasv_date = true;
						break
					case "heading":
						v_heading = Option.Some(JsonString.f(this.r()));
						hasv_heading = true;
						break
					case "point":
						this.F("[");
						var __ret = [];
						if (!this.E("]")) {
							while (true) {
								__ret.push(JsonString.f(this.r()));
								if (!this.E(",")) {
									break;
								};
							};
							this.F("]");
						};
						v_point = __ret;
						hasv_point = true;
						break
					case "speed":
						v_speed = JsonString.f(this.r());
						hasv_speed = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		var tmp = (hasv_date) ? v_date : __missing__("date");
		var tmp1 = (hasv_point) ? v_point : __missing__("point");
		var tmp2 = (hasv_speed) ? v_speed : __missing__("speed");
		return {"date": tmp, "heading": (hasv_heading) ? v_heading : Option.None, "point": tmp1, "speed": tmp2};
	}
	pc(source) {
		if (this.e.length > 0) {
			this.e = [];
		};
		this.g(source);
		var ret = this.pb();
		var _g = 0;
		var _g1 = this.e;
		while (_g < _g1.length) _g1[_g++]();
		if (this.e.length > 0) {
			this.e = [];
		};
		return ret;
	}
	pd(source) {
		var _gthis = this;
		return TypedError.catchExceptions(function () {
			var ret = _gthis.pc(source);
			_gthis.h();
			if (_gthis.c < _gthis.d) {
				_gthis.D("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser75", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser75"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser75
	}
}

