import {Register} from "./genes/Register.js"
import {Declarations} from "./cix/css/Declarations.js"

const $global = Register.$global

export const Cix0d44e2dbbc9ee6f4702bc6e9a25128e599e8eb379eac0192f855184f0f463a32 = Register.global("$hxClasses")["Cix0d44e2dbbc9ee6f4702bc6e9a25128e599e8eb379eac0192f855184f0f463a32"] = 
class Cix0d44e2dbbc9ee6f4702bc6e9a25128e599e8eb379eac0192f855184f0f463a32 extends Register.inherits() {
	new() {
		this.css = Declarations.add("cxwc", function () {
			return ".cxwc.ui.inverted.segment {\n  border-radius: 0;\n  padding-top: 3em;\n  padding-bottom: 3em;\n  margin: 0;\n}";
		});
	}
	static get __name__() {
		return "Cix0d44e2dbbc9ee6f4702bc6e9a25128e599e8eb379eac0192f855184f0f463a32"
	}
	get __class__() {
		return Cix0d44e2dbbc9ee6f4702bc6e9a25128e599e8eb379eac0192f855184f0f463a32
	}
}


Cix0d44e2dbbc9ee6f4702bc6e9a25128e599e8eb379eac0192f855184f0f463a32.inst = new Cix0d44e2dbbc9ee6f4702bc6e9a25128e599e8eb379eac0192f855184f0f463a32()