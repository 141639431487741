import {QueryStringParser} from "../../../tink/url/Query.js"
import {PortionArray} from "../../../tink/url/PortionArray.js"
import {Path} from "../../../tink/url/Path.js"
import {Parser7} from "../../../tink/querystring/Parser7.js"
import {Parser6} from "../../../tink/querystring/Parser6.js"
import {Parser5} from "../../../tink/querystring/Parser5.js"
import {Parser4} from "../../../tink/querystring/Parser4.js"
import {Parser3} from "../../../tink/querystring/Parser3.js"
import {Parser2} from "../../../tink/querystring/Parser2.js"
import {Parser1} from "../../../tink/querystring/Parser1.js"
import {Parser0} from "../../../tink/querystring/Parser0.js"
import {Builder16} from "../../../tink/querystring/Builder16.js"
import {Builder15} from "../../../tink/querystring/Builder15.js"
import {Builder14} from "../../../tink/querystring/Builder14.js"
import {Builder13} from "../../../tink/querystring/Builder13.js"
import {Builder12} from "../../../tink/querystring/Builder12.js"
import {Builder11} from "../../../tink/querystring/Builder11.js"
import {Builder10} from "../../../tink/querystring/Builder10.js"
import {Url} from "../../../tink/Url.js"
import {Exception} from "../../../haxe/Exception.js"
import {Register} from "../../../genes/Register.js"
import {Std} from "../../../Std.js"

const $global = Register.$global

export const Route = 
Register.global("$hxEnums")["dasloop.app.data.Route"] = 
{
	__ename__: "dasloop.app.data.Route",
	
	Authenticated: Object.assign((sub) => ({_hx_index: 0, __enum__: "dasloop.app.data.Route", "sub": sub}), {_hx_name: "Authenticated", __params__: ["sub"]}),
	Public: Object.assign((sub) => ({_hx_index: 1, __enum__: "dasloop.app.data.Route", "sub": sub}), {_hx_name: "Public", __params__: ["sub"]})
}
Route.__constructs__ = [Route.Authenticated, Route.Public]
Route.__empty_constructs__ = []

export const AuthenticatedRoute = 
Register.global("$hxEnums")["dasloop.app.data.AuthenticatedRoute"] = 
{
	__ename__: "dasloop.app.data.AuthenticatedRoute",
	
	Home: {_hx_name: "Home", _hx_index: 0, __enum__: "dasloop.app.data.AuthenticatedRoute"},
	Settings: {_hx_name: "Settings", _hx_index: 1, __enum__: "dasloop.app.data.AuthenticatedRoute"},
	UserSummary: {_hx_name: "UserSummary", _hx_index: 2, __enum__: "dasloop.app.data.AuthenticatedRoute"},
	ProjectList: {_hx_name: "ProjectList", _hx_index: 3, __enum__: "dasloop.app.data.AuthenticatedRoute"},
	CreateProject: {_hx_name: "CreateProject", _hx_index: 4, __enum__: "dasloop.app.data.AuthenticatedRoute"},
	CreateSelectProject: Object.assign((sub) => ({_hx_index: 5, __enum__: "dasloop.app.data.AuthenticatedRoute", "sub": sub}), {_hx_name: "CreateSelectProject", __params__: ["sub"]}),
	Project: Object.assign((id, sub) => ({_hx_index: 6, __enum__: "dasloop.app.data.AuthenticatedRoute", "id": id, "sub": sub}), {_hx_name: "Project", __params__: ["id", "sub"]}),
	WellKnown: Object.assign((sub) => ({_hx_index: 7, __enum__: "dasloop.app.data.AuthenticatedRoute", "sub": sub}), {_hx_name: "WellKnown", __params__: ["sub"]})
}
AuthenticatedRoute.__constructs__ = [AuthenticatedRoute.Home, AuthenticatedRoute.Settings, AuthenticatedRoute.UserSummary, AuthenticatedRoute.ProjectList, AuthenticatedRoute.CreateProject, AuthenticatedRoute.CreateSelectProject, AuthenticatedRoute.Project, AuthenticatedRoute.WellKnown]
AuthenticatedRoute.__empty_constructs__ = [AuthenticatedRoute.Home, AuthenticatedRoute.Settings, AuthenticatedRoute.UserSummary, AuthenticatedRoute.ProjectList, AuthenticatedRoute.CreateProject]

export const CreateSelectProjectRoute = 
Register.global("$hxEnums")["dasloop.app.data.CreateSelectProjectRoute"] = 
{
	__ename__: "dasloop.app.data.CreateSelectProjectRoute",
	
	Device: Object.assign((options) => ({_hx_index: 0, __enum__: "dasloop.app.data.CreateSelectProjectRoute", "options": options}), {_hx_name: "Device", __params__: ["options"]}),
	Beacon: Object.assign((options) => ({_hx_index: 1, __enum__: "dasloop.app.data.CreateSelectProjectRoute", "options": options}), {_hx_name: "Beacon", __params__: ["options"]}),
	AoaLocator: Object.assign((options) => ({_hx_index: 2, __enum__: "dasloop.app.data.CreateSelectProjectRoute", "options": options}), {_hx_name: "AoaLocator", __params__: ["options"]})
}
CreateSelectProjectRoute.__constructs__ = [CreateSelectProjectRoute.Device, CreateSelectProjectRoute.Beacon, CreateSelectProjectRoute.AoaLocator]
CreateSelectProjectRoute.__empty_constructs__ = []

export const PublicRoute = 
Register.global("$hxEnums")["dasloop.app.data.PublicRoute"] = 
{
	__ename__: "dasloop.app.data.PublicRoute",
	
	NotFound: Object.assign((path) => ({_hx_index: 0, __enum__: "dasloop.app.data.PublicRoute", "path": path}), {_hx_name: "NotFound", __params__: ["path"]}),
	Error: Object.assign((options) => ({_hx_index: 1, __enum__: "dasloop.app.data.PublicRoute", "options": options}), {_hx_name: "Error", __params__: ["options"]})
}
PublicRoute.__constructs__ = [PublicRoute.NotFound, PublicRoute.Error]
PublicRoute.__empty_constructs__ = []

export const ProjectRoute = 
Register.global("$hxEnums")["dasloop.app.data.ProjectRoute"] = 
{
	__ename__: "dasloop.app.data.ProjectRoute",
	
	Project: Object.assign((options) => ({_hx_index: 0, __enum__: "dasloop.app.data.ProjectRoute", "options": options}), {_hx_name: "Project", __params__: ["options"]}),
	EditProject: {_hx_name: "EditProject", _hx_index: 1, __enum__: "dasloop.app.data.ProjectRoute"},
	Device: Object.assign((sub) => ({_hx_index: 2, __enum__: "dasloop.app.data.ProjectRoute", "sub": sub}), {_hx_name: "Device", __params__: ["sub"]}),
	Floorplan: Object.assign((sub) => ({_hx_index: 3, __enum__: "dasloop.app.data.ProjectRoute", "sub": sub}), {_hx_name: "Floorplan", __params__: ["sub"]}),
	Bim: Object.assign((sub) => ({_hx_index: 4, __enum__: "dasloop.app.data.ProjectRoute", "sub": sub}), {_hx_name: "Bim", __params__: ["sub"]}),
	Area: Object.assign((sub) => ({_hx_index: 5, __enum__: "dasloop.app.data.ProjectRoute", "sub": sub}), {_hx_name: "Area", __params__: ["sub"]}),
	Plant: Object.assign((sub) => ({_hx_index: 6, __enum__: "dasloop.app.data.ProjectRoute", "sub": sub}), {_hx_name: "Plant", __params__: ["sub"]}),
	AoaLocator: Object.assign((sub) => ({_hx_index: 7, __enum__: "dasloop.app.data.ProjectRoute", "sub": sub}), {_hx_name: "AoaLocator", __params__: ["sub"]}),
	Beacon: Object.assign((sub) => ({_hx_index: 8, __enum__: "dasloop.app.data.ProjectRoute", "sub": sub}), {_hx_name: "Beacon", __params__: ["sub"]}),
	Worker: Object.assign((sub) => ({_hx_index: 9, __enum__: "dasloop.app.data.ProjectRoute", "sub": sub}), {_hx_name: "Worker", __params__: ["sub"]}),
	Workergroup: Object.assign((sub) => ({_hx_index: 10, __enum__: "dasloop.app.data.ProjectRoute", "sub": sub}), {_hx_name: "Workergroup", __params__: ["sub"]}),
	Equipment: Object.assign((sub) => ({_hx_index: 11, __enum__: "dasloop.app.data.ProjectRoute", "sub": sub}), {_hx_name: "Equipment", __params__: ["sub"]}),
	Equipmentgroup: Object.assign((sub) => ({_hx_index: 12, __enum__: "dasloop.app.data.ProjectRoute", "sub": sub}), {_hx_name: "Equipmentgroup", __params__: ["sub"]}),
	Task: Object.assign((sub) => ({_hx_index: 13, __enum__: "dasloop.app.data.ProjectRoute", "sub": sub}), {_hx_name: "Task", __params__: ["sub"]}),
	Audio: Object.assign((sub) => ({_hx_index: 14, __enum__: "dasloop.app.data.ProjectRoute", "sub": sub}), {_hx_name: "Audio", __params__: ["sub"]}),
	Cctv: Object.assign((sub) => ({_hx_index: 15, __enum__: "dasloop.app.data.ProjectRoute", "sub": sub}), {_hx_name: "Cctv", __params__: ["sub"]}),
	Statistics: {_hx_name: "Statistics", _hx_index: 16, __enum__: "dasloop.app.data.ProjectRoute"},
	Reports: {_hx_name: "Reports", _hx_index: 17, __enum__: "dasloop.app.data.ProjectRoute"},
	Logs: {_hx_name: "Logs", _hx_index: 18, __enum__: "dasloop.app.data.ProjectRoute"},
	MaintenanceDevice: {_hx_name: "MaintenanceDevice", _hx_index: 19, __enum__: "dasloop.app.data.ProjectRoute"},
	Settings: {_hx_name: "Settings", _hx_index: 20, __enum__: "dasloop.app.data.ProjectRoute"}
}
ProjectRoute.__constructs__ = [ProjectRoute.Project, ProjectRoute.EditProject, ProjectRoute.Device, ProjectRoute.Floorplan, ProjectRoute.Bim, ProjectRoute.Area, ProjectRoute.Plant, ProjectRoute.AoaLocator, ProjectRoute.Beacon, ProjectRoute.Worker, ProjectRoute.Workergroup, ProjectRoute.Equipment, ProjectRoute.Equipmentgroup, ProjectRoute.Task, ProjectRoute.Audio, ProjectRoute.Cctv, ProjectRoute.Statistics, ProjectRoute.Reports, ProjectRoute.Logs, ProjectRoute.MaintenanceDevice, ProjectRoute.Settings]
ProjectRoute.__empty_constructs__ = [ProjectRoute.EditProject, ProjectRoute.Statistics, ProjectRoute.Reports, ProjectRoute.Logs, ProjectRoute.MaintenanceDevice, ProjectRoute.Settings]

export const DeviceRoute = 
Register.global("$hxEnums")["dasloop.app.data.DeviceRoute"] = 
{
	__ename__: "dasloop.app.data.DeviceRoute",
	
	View: Object.assign((id) => ({_hx_index: 0, __enum__: "dasloop.app.data.DeviceRoute", "id": id}), {_hx_name: "View", __params__: ["id"]}),
	List: Object.assign((options) => ({_hx_index: 1, __enum__: "dasloop.app.data.DeviceRoute", "options": options}), {_hx_name: "List", __params__: ["options"]}),
	Create: Object.assign((options) => ({_hx_index: 2, __enum__: "dasloop.app.data.DeviceRoute", "options": options}), {_hx_name: "Create", __params__: ["options"]}),
	LiveCall: Object.assign((id) => ({_hx_index: 3, __enum__: "dasloop.app.data.DeviceRoute", "id": id}), {_hx_name: "LiveCall", __params__: ["id"]}),
	Download: Object.assign((id, sub) => ({_hx_index: 4, __enum__: "dasloop.app.data.DeviceRoute", "id": id, "sub": sub}), {_hx_name: "Download", __params__: ["id", "sub"]})
}
DeviceRoute.__constructs__ = [DeviceRoute.View, DeviceRoute.List, DeviceRoute.Create, DeviceRoute.LiveCall, DeviceRoute.Download]
DeviceRoute.__empty_constructs__ = []

export const DeviceDownloadRoute = 
Register.global("$hxEnums")["dasloop.app.data.DeviceDownloadRoute"] = 
{
	__ename__: "dasloop.app.data.DeviceDownloadRoute",
	
	LiveCall: {_hx_name: "LiveCall", _hx_index: 0, __enum__: "dasloop.app.data.DeviceDownloadRoute"}
}
DeviceDownloadRoute.__constructs__ = [DeviceDownloadRoute.LiveCall]
DeviceDownloadRoute.__empty_constructs__ = [DeviceDownloadRoute.LiveCall]

export const BeaconRoute = 
Register.global("$hxEnums")["dasloop.app.data.BeaconRoute"] = 
{
	__ename__: "dasloop.app.data.BeaconRoute",
	
	List: {_hx_name: "List", _hx_index: 0, __enum__: "dasloop.app.data.BeaconRoute"},
	Create: Object.assign((options) => ({_hx_index: 1, __enum__: "dasloop.app.data.BeaconRoute", "options": options}), {_hx_name: "Create", __params__: ["options"]}),
	View: Object.assign((id) => ({_hx_index: 2, __enum__: "dasloop.app.data.BeaconRoute", "id": id}), {_hx_name: "View", __params__: ["id"]}),
	Edit: Object.assign((id) => ({_hx_index: 3, __enum__: "dasloop.app.data.BeaconRoute", "id": id}), {_hx_name: "Edit", __params__: ["id"]})
}
BeaconRoute.__constructs__ = [BeaconRoute.List, BeaconRoute.Create, BeaconRoute.View, BeaconRoute.Edit]
BeaconRoute.__empty_constructs__ = [BeaconRoute.List]

export const AoaLocatorRoute = 
Register.global("$hxEnums")["dasloop.app.data.AoaLocatorRoute"] = 
{
	__ename__: "dasloop.app.data.AoaLocatorRoute",
	
	List: {_hx_name: "List", _hx_index: 0, __enum__: "dasloop.app.data.AoaLocatorRoute"},
	Create: Object.assign((options) => ({_hx_index: 1, __enum__: "dasloop.app.data.AoaLocatorRoute", "options": options}), {_hx_name: "Create", __params__: ["options"]}),
	View: Object.assign((id) => ({_hx_index: 2, __enum__: "dasloop.app.data.AoaLocatorRoute", "id": id}), {_hx_name: "View", __params__: ["id"]}),
	Edit: Object.assign((id) => ({_hx_index: 3, __enum__: "dasloop.app.data.AoaLocatorRoute", "id": id}), {_hx_name: "Edit", __params__: ["id"]})
}
AoaLocatorRoute.__constructs__ = [AoaLocatorRoute.List, AoaLocatorRoute.Create, AoaLocatorRoute.View, AoaLocatorRoute.Edit]
AoaLocatorRoute.__empty_constructs__ = [AoaLocatorRoute.List]

export const WorkerRoute = 
Register.global("$hxEnums")["dasloop.app.data.WorkerRoute"] = 
{
	__ename__: "dasloop.app.data.WorkerRoute",
	
	List: {_hx_name: "List", _hx_index: 0, __enum__: "dasloop.app.data.WorkerRoute"},
	Create: {_hx_name: "Create", _hx_index: 1, __enum__: "dasloop.app.data.WorkerRoute"},
	View: Object.assign((id) => ({_hx_index: 2, __enum__: "dasloop.app.data.WorkerRoute", "id": id}), {_hx_name: "View", __params__: ["id"]}),
	Edit: Object.assign((id) => ({_hx_index: 3, __enum__: "dasloop.app.data.WorkerRoute", "id": id}), {_hx_name: "Edit", __params__: ["id"]}),
	CreateCertificate: Object.assign((workerId) => ({_hx_index: 4, __enum__: "dasloop.app.data.WorkerRoute", "workerId": workerId}), {_hx_name: "CreateCertificate", __params__: ["workerId"]}),
	EditCertificate: Object.assign((workerId, certId) => ({_hx_index: 5, __enum__: "dasloop.app.data.WorkerRoute", "workerId": workerId, "certId": certId}), {_hx_name: "EditCertificate", __params__: ["workerId", "certId"]})
}
WorkerRoute.__constructs__ = [WorkerRoute.List, WorkerRoute.Create, WorkerRoute.View, WorkerRoute.Edit, WorkerRoute.CreateCertificate, WorkerRoute.EditCertificate]
WorkerRoute.__empty_constructs__ = [WorkerRoute.List, WorkerRoute.Create]

export const WorkergroupRoute = 
Register.global("$hxEnums")["dasloop.app.data.WorkergroupRoute"] = 
{
	__ename__: "dasloop.app.data.WorkergroupRoute",
	
	List: {_hx_name: "List", _hx_index: 0, __enum__: "dasloop.app.data.WorkergroupRoute"},
	Create: {_hx_name: "Create", _hx_index: 1, __enum__: "dasloop.app.data.WorkergroupRoute"},
	View: Object.assign((id) => ({_hx_index: 2, __enum__: "dasloop.app.data.WorkergroupRoute", "id": id}), {_hx_name: "View", __params__: ["id"]}),
	Edit: Object.assign((id) => ({_hx_index: 3, __enum__: "dasloop.app.data.WorkergroupRoute", "id": id}), {_hx_name: "Edit", __params__: ["id"]}),
	EditWorkers: Object.assign((id) => ({_hx_index: 4, __enum__: "dasloop.app.data.WorkergroupRoute", "id": id}), {_hx_name: "EditWorkers", __params__: ["id"]})
}
WorkergroupRoute.__constructs__ = [WorkergroupRoute.List, WorkergroupRoute.Create, WorkergroupRoute.View, WorkergroupRoute.Edit, WorkergroupRoute.EditWorkers]
WorkergroupRoute.__empty_constructs__ = [WorkergroupRoute.List, WorkergroupRoute.Create]

export const EquipmentRoute = 
Register.global("$hxEnums")["dasloop.app.data.EquipmentRoute"] = 
{
	__ename__: "dasloop.app.data.EquipmentRoute",
	
	Overview: {_hx_name: "Overview", _hx_index: 0, __enum__: "dasloop.app.data.EquipmentRoute"},
	List: {_hx_name: "List", _hx_index: 1, __enum__: "dasloop.app.data.EquipmentRoute"},
	Create: {_hx_name: "Create", _hx_index: 2, __enum__: "dasloop.app.data.EquipmentRoute"},
	View: Object.assign((id) => ({_hx_index: 3, __enum__: "dasloop.app.data.EquipmentRoute", "id": id}), {_hx_name: "View", __params__: ["id"]}),
	Edit: Object.assign((id) => ({_hx_index: 4, __enum__: "dasloop.app.data.EquipmentRoute", "id": id}), {_hx_name: "Edit", __params__: ["id"]})
}
EquipmentRoute.__constructs__ = [EquipmentRoute.Overview, EquipmentRoute.List, EquipmentRoute.Create, EquipmentRoute.View, EquipmentRoute.Edit]
EquipmentRoute.__empty_constructs__ = [EquipmentRoute.Overview, EquipmentRoute.List, EquipmentRoute.Create]

export const EquipmentgroupRoute = 
Register.global("$hxEnums")["dasloop.app.data.EquipmentgroupRoute"] = 
{
	__ename__: "dasloop.app.data.EquipmentgroupRoute",
	
	Create: {_hx_name: "Create", _hx_index: 0, __enum__: "dasloop.app.data.EquipmentgroupRoute"},
	View: Object.assign((id) => ({_hx_index: 1, __enum__: "dasloop.app.data.EquipmentgroupRoute", "id": id}), {_hx_name: "View", __params__: ["id"]}),
	Edit: Object.assign((id) => ({_hx_index: 2, __enum__: "dasloop.app.data.EquipmentgroupRoute", "id": id}), {_hx_name: "Edit", __params__: ["id"]}),
	EditEquipments: Object.assign((id) => ({_hx_index: 3, __enum__: "dasloop.app.data.EquipmentgroupRoute", "id": id}), {_hx_name: "EditEquipments", __params__: ["id"]})
}
EquipmentgroupRoute.__constructs__ = [EquipmentgroupRoute.Create, EquipmentgroupRoute.View, EquipmentgroupRoute.Edit, EquipmentgroupRoute.EditEquipments]
EquipmentgroupRoute.__empty_constructs__ = [EquipmentgroupRoute.Create]

export const TaskRoute = 
Register.global("$hxEnums")["dasloop.app.data.TaskRoute"] = 
{
	__ename__: "dasloop.app.data.TaskRoute",
	
	List: {_hx_name: "List", _hx_index: 0, __enum__: "dasloop.app.data.TaskRoute"},
	Create: Object.assign((options) => ({_hx_index: 1, __enum__: "dasloop.app.data.TaskRoute", "options": options}), {_hx_name: "Create", __params__: ["options"]}),
	View: Object.assign((id) => ({_hx_index: 2, __enum__: "dasloop.app.data.TaskRoute", "id": id}), {_hx_name: "View", __params__: ["id"]}),
	Taskgroup: Object.assign((id) => ({_hx_index: 3, __enum__: "dasloop.app.data.TaskRoute", "id": id}), {_hx_name: "Taskgroup", __params__: ["id"]})
}
TaskRoute.__constructs__ = [TaskRoute.List, TaskRoute.Create, TaskRoute.View, TaskRoute.Taskgroup]
TaskRoute.__empty_constructs__ = [TaskRoute.List]

export const AudioRoute = 
Register.global("$hxEnums")["dasloop.app.data.AudioRoute"] = 
{
	__ename__: "dasloop.app.data.AudioRoute",
	
	Create: {_hx_name: "Create", _hx_index: 0, __enum__: "dasloop.app.data.AudioRoute"},
	Manager: {_hx_name: "Manager", _hx_index: 1, __enum__: "dasloop.app.data.AudioRoute"},
	Announcement: {_hx_name: "Announcement", _hx_index: 2, __enum__: "dasloop.app.data.AudioRoute"}
}
AudioRoute.__constructs__ = [AudioRoute.Create, AudioRoute.Manager, AudioRoute.Announcement]
AudioRoute.__empty_constructs__ = [AudioRoute.Create, AudioRoute.Manager, AudioRoute.Announcement]

export const CctvRoute = 
Register.global("$hxEnums")["dasloop.app.data.CctvRoute"] = 
{
	__ename__: "dasloop.app.data.CctvRoute",
	
	Root: {_hx_name: "Root", _hx_index: 0, __enum__: "dasloop.app.data.CctvRoute"}
}
CctvRoute.__constructs__ = [CctvRoute.Root]
CctvRoute.__empty_constructs__ = [CctvRoute.Root]

export const GenericSubRoute = 
Register.global("$hxEnums")["dasloop.app.data.GenericSubRoute"] = 
{
	__ename__: "dasloop.app.data.GenericSubRoute",
	
	List: {_hx_name: "List", _hx_index: 0, __enum__: "dasloop.app.data.GenericSubRoute"},
	Create: {_hx_name: "Create", _hx_index: 1, __enum__: "dasloop.app.data.GenericSubRoute"},
	View: Object.assign((id) => ({_hx_index: 2, __enum__: "dasloop.app.data.GenericSubRoute", "id": id}), {_hx_name: "View", __params__: ["id"]}),
	Edit: Object.assign((id) => ({_hx_index: 3, __enum__: "dasloop.app.data.GenericSubRoute", "id": id}), {_hx_name: "Edit", __params__: ["id"]})
}
GenericSubRoute.__constructs__ = [GenericSubRoute.List, GenericSubRoute.Create, GenericSubRoute.View, GenericSubRoute.Edit]
GenericSubRoute.__empty_constructs__ = [GenericSubRoute.List, GenericSubRoute.Create]

export const WellKnownRoute = 
Register.global("$hxEnums")["dasloop.app.data.WellKnownRoute"] = 
{
	__ename__: "dasloop.app.data.WellKnownRoute",
	
	ChangePassword: {_hx_name: "ChangePassword", _hx_index: 0, __enum__: "dasloop.app.data.WellKnownRoute"}
}
WellKnownRoute.__constructs__ = [WellKnownRoute.ChangePassword]
WellKnownRoute.__empty_constructs__ = [WellKnownRoute.ChangePassword]

export const DeviceListMode = Register.global("$hxClasses")["dasloop.app.data._Route.DeviceListMode"] = 
class DeviceListMode {
	static or(this1, v) {
		if (this1 == null) {
			return v;
		} else {
			return this1;
		};
	}
	static get __name__() {
		return "dasloop.app.data._Route.DeviceListMode_Impl_"
	}
	get __class__() {
		return DeviceListMode
	}
}


export const CreateDeviceWorkerAssignment = Register.global("$hxClasses")["dasloop.app.data._Route.CreateDeviceWorkerAssignment"] = 
class CreateDeviceWorkerAssignment {
	static or(this1, v) {
		if (this1 == null) {
			return v;
		} else {
			return this1;
		};
	}
	static get __name__() {
		return "dasloop.app.data._Route.CreateDeviceWorkerAssignment_Impl_"
	}
	get __class__() {
		return CreateDeviceWorkerAssignment
	}
}


export const RouteTools = Register.global("$hxClasses")["dasloop.app.data.RouteTools"] = 
class RouteTools {
	static fromUrl(url) {
		var _g = PortionArray.toStringArray(Path.parts(url.path));
		switch (_g.length) {
			case 0:
				return Route.Authenticated(AuthenticatedRoute.Home);
				break
			case 1:
				var _g1 = _g[0];
				if (_g1 == null) {
					return Route.Public(PublicRoute.NotFound(Url.toString(url)));
				} else {
					switch (_g1) {
						case "error":
							return RouteTools.tryParse(function () {
								return Route.Public(PublicRoute.Error(new Parser7(null, {"fileName": "src/dasloop/app/data/Route.hx", "lineNumber": 285, "className": "dasloop.app.data.RouteTools", "methodName": "fromUrl"}).parse(new QueryStringParser(url.query, "&", "=", 0))));
							});
							break
						case "projects":
							return Route.Authenticated(AuthenticatedRoute.ProjectList);
							break
						case "settings":
							return Route.Authenticated(AuthenticatedRoute.Settings);
							break
						case "summary":
							return Route.Authenticated(AuthenticatedRoute.UserSummary);
							break
						default:
						return Route.Public(PublicRoute.NotFound(Url.toString(url)));
						
					};
				};
				break
			case 2:
				var _g1 = _g[0];
				var _g2 = _g[1];
				if (_g1 == null) {
					return Route.Public(PublicRoute.NotFound(Url.toString(url)));
				} else {
					switch (_g1) {
						case ".well-known":
							if (_g2 == null) {
								return Route.Public(PublicRoute.NotFound(Url.toString(url)));
							} else if (_g2 == "change-password") {
								return Route.Authenticated(AuthenticatedRoute.WellKnown(WellKnownRoute.ChangePassword));
							} else {
								return Route.Public(PublicRoute.NotFound(Url.toString(url)));
							};
							break
						case "aoa_locators":
							if (_g2 == null) {
								return Route.Public(PublicRoute.NotFound(Url.toString(url)));
							} else if (_g2 == "new") {
								return RouteTools.tryParse(function () {
									return Route.Authenticated(AuthenticatedRoute.CreateSelectProject(CreateSelectProjectRoute.AoaLocator(new Parser1(null, {"fileName": "src/dasloop/app/data/Route.hx", "lineNumber": 197, "className": "dasloop.app.data.RouteTools", "methodName": "fromUrl"}).parse(new QueryStringParser(url.query, "&", "=", 0)))));
								});
							} else {
								return Route.Public(PublicRoute.NotFound(Url.toString(url)));
							};
							break
						case "beacons":
							if (_g2 == null) {
								return Route.Public(PublicRoute.NotFound(Url.toString(url)));
							} else if (_g2 == "new") {
								return RouteTools.tryParse(function () {
									return Route.Authenticated(AuthenticatedRoute.CreateSelectProject(CreateSelectProjectRoute.Beacon(new Parser1(null, {"fileName": "src/dasloop/app/data/Route.hx", "lineNumber": 196, "className": "dasloop.app.data.RouteTools", "methodName": "fromUrl"}).parse(new QueryStringParser(url.query, "&", "=", 0)))));
								});
							} else {
								return Route.Public(PublicRoute.NotFound(Url.toString(url)));
							};
							break
						case "devices":
							if (_g2 == null) {
								return Route.Public(PublicRoute.NotFound(Url.toString(url)));
							} else if (_g2 == "new") {
								return RouteTools.tryParse(function () {
									return Route.Authenticated(AuthenticatedRoute.CreateSelectProject(CreateSelectProjectRoute.Device(new Parser0(null, {"fileName": "src/dasloop/app/data/Route.hx", "lineNumber": 195, "className": "dasloop.app.data.RouteTools", "methodName": "fromUrl"}).parse(new QueryStringParser(url.query, "&", "=", 0)))));
								});
							} else {
								return Route.Public(PublicRoute.NotFound(Url.toString(url)));
							};
							break
						case "projects":
							if (_g2 == null) {
								var id = _g2;
								return RouteTools.tryParse(function () {
									return Route.Authenticated(AuthenticatedRoute.Project(id, ProjectRoute.Project(new Parser2(null, {"fileName": "src/dasloop/app/data/Route.hx", "lineNumber": 201, "className": "dasloop.app.data.RouteTools", "methodName": "fromUrl"}).parse(new QueryStringParser(url.query, "&", "=", 0)))));
								});
							} else if (_g2 == "new") {
								return Route.Authenticated(AuthenticatedRoute.CreateProject);
							} else {
								var id1 = _g2;
								return RouteTools.tryParse(function () {
									return Route.Authenticated(AuthenticatedRoute.Project(id1, ProjectRoute.Project(new Parser2(null, {"fileName": "src/dasloop/app/data/Route.hx", "lineNumber": 201, "className": "dasloop.app.data.RouteTools", "methodName": "fromUrl"}).parse(new QueryStringParser(url.query, "&", "=", 0)))));
								});
							};
							break
						default:
						return Route.Public(PublicRoute.NotFound(Url.toString(url)));
						
					};
				};
				break
			case 3:
				var _g1 = _g[0];
				var _g2 = _g[1];
				var _g3 = _g[2];
				if (_g1 == null) {
					return Route.Public(PublicRoute.NotFound(Url.toString(url)));
				} else if (_g1 == "projects") {
					if (_g3 == null) {
						return Route.Public(PublicRoute.NotFound(Url.toString(url)));
					} else {
						switch (_g3) {
							case "announcement":
								return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Audio(AudioRoute.Announcement)));
								break
							case "aoa_locators":
								return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.AoaLocator(AoaLocatorRoute.List)));
								break
							case "areas":
								return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Area(GenericSubRoute.List)));
								break
							case "audios":
								return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Audio(AudioRoute.Manager)));
								break
							case "beacons":
								return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Beacon(BeaconRoute.List)));
								break
							case "bims":
								return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Bim(GenericSubRoute.List)));
								break
							case "cctvs":
								return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Cctv(CctvRoute.Root)));
								break
							case "devices":
								var id2 = _g2;
								return RouteTools.tryParse(function () {
									return Route.Authenticated(AuthenticatedRoute.Project(id2, ProjectRoute.Device(DeviceRoute.List(new Parser4(null, {"fileName": "src/dasloop/app/data/Route.hx", "lineNumber": 232, "className": "dasloop.app.data.RouteTools", "methodName": "fromUrl"}).parse(new QueryStringParser(url.query, "&", "=", 0))))));
								});
								break
							case "edit":
								return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.EditProject));
								break
							case "equipments":
								return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Equipment(EquipmentRoute.List)));
								break
							case "floorplans":
								return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Floorplan(GenericSubRoute.List)));
								break
							case "logs":
								return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Logs));
								break
							case "maintenance_device":
								return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.MaintenanceDevice));
								break
							case "plants":
								return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Plant(GenericSubRoute.List)));
								break
							case "reports":
								return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Reports));
								break
							case "settings":
								return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Settings));
								break
							case "statistics":
								return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Statistics));
								break
							case "tasks":
								return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Task(TaskRoute.List)));
								break
							case "workergroups":
								return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Workergroup(WorkergroupRoute.List)));
								break
							case "workers":
								return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Worker(WorkerRoute.List)));
								break
							default:
							return Route.Public(PublicRoute.NotFound(Url.toString(url)));
							
						};
					};
				} else {
					return Route.Public(PublicRoute.NotFound(Url.toString(url)));
				};
				break
			case 4:
				var _g1 = _g[0];
				var _g2 = _g[1];
				var _g3 = _g[2];
				var _g4 = _g[3];
				if (_g1 == null) {
					return Route.Public(PublicRoute.NotFound(Url.toString(url)));
				} else if (_g1 == "projects") {
					if (_g3 == null) {
						return Route.Public(PublicRoute.NotFound(Url.toString(url)));
					} else {
						switch (_g3) {
							case "aoa_locators":
								if (_g4 == null) {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.AoaLocator(AoaLocatorRoute.View(_g4))));
								} else if (_g4 == "new") {
									var id3 = _g2;
									return RouteTools.tryParse(function () {
										return Route.Authenticated(AuthenticatedRoute.Project(id3, ProjectRoute.AoaLocator(AoaLocatorRoute.Create(new Parser3(null, {"fileName": "src/dasloop/app/data/Route.hx", "lineNumber": 223, "className": "dasloop.app.data.RouteTools", "methodName": "fromUrl"}).parse(new QueryStringParser(url.query, "&", "=", 0))))));
									});
								} else {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.AoaLocator(AoaLocatorRoute.View(_g4))));
								};
								break
							case "areas":
								if (_g4 == null) {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Area(GenericSubRoute.View(_g4))));
								} else if (_g4 == "new") {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Area(GenericSubRoute.Create)));
								} else {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Area(GenericSubRoute.View(_g4))));
								};
								break
							case "audios":
								if (_g4 == null) {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								} else if (_g4 == "new") {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Audio(AudioRoute.Create)));
								} else {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								};
								break
							case "beacons":
								var _hx_tmp;
								if (_g4 == null) {
									_hx_tmp = Std.parseInt(_g4);
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Beacon(BeaconRoute.View(_hx_tmp))));
								} else if (_g4 == "new") {
									var id4 = _g2;
									return RouteTools.tryParse(function () {
										return Route.Authenticated(AuthenticatedRoute.Project(id4, ProjectRoute.Beacon(BeaconRoute.Create(new Parser3(null, {"fileName": "src/dasloop/app/data/Route.hx", "lineNumber": 228, "className": "dasloop.app.data.RouteTools", "methodName": "fromUrl"}).parse(new QueryStringParser(url.query, "&", "=", 0))))));
									});
								} else {
									_hx_tmp = Std.parseInt(_g4);
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Beacon(BeaconRoute.View(_hx_tmp))));
								};
								break
							case "bims":
								if (_g4 == null) {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Bim(GenericSubRoute.View(_g4))));
								} else if (_g4 == "new") {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Bim(GenericSubRoute.Create)));
								} else {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Bim(GenericSubRoute.View(_g4))));
								};
								break
							case "devices":
								if (_g4 == null) {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Device(DeviceRoute.View(_g4))));
								} else if (_g4 == "new") {
									var id5 = _g2;
									return RouteTools.tryParse(function () {
										return Route.Authenticated(AuthenticatedRoute.Project(id5, ProjectRoute.Device(DeviceRoute.Create(new Parser5(null, {"fileName": "src/dasloop/app/data/Route.hx", "lineNumber": 233, "className": "dasloop.app.data.RouteTools", "methodName": "fromUrl"}).parse(new QueryStringParser(url.query, "&", "=", 0))))));
									});
								} else {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Device(DeviceRoute.View(_g4))));
								};
								break
							case "equipmentgroups":
								if (_g4 == null) {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Equipmentgroup(EquipmentgroupRoute.View(_g4))));
								} else if (_g4 == "new") {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Equipmentgroup(EquipmentgroupRoute.Create)));
								} else {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Equipmentgroup(EquipmentgroupRoute.View(_g4))));
								};
								break
							case "equipments":
								if (_g4 == null) {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Equipment(EquipmentRoute.View(_g4))));
								} else {
									switch (_g4) {
										case "new":
											return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Equipment(EquipmentRoute.Create)));
											break
										case "overview":
											return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Equipment(EquipmentRoute.Overview)));
											break
										default:
										return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Equipment(EquipmentRoute.View(_g4))));
										
									};
								};
								break
							case "floorplans":
								if (_g4 == null) {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Floorplan(GenericSubRoute.View(_g4))));
								} else if (_g4 == "new") {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Floorplan(GenericSubRoute.Create)));
								} else {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Floorplan(GenericSubRoute.View(_g4))));
								};
								break
							case "plants":
								if (_g4 == null) {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Plant(GenericSubRoute.View(_g4))));
								} else if (_g4 == "new") {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Plant(GenericSubRoute.Create)));
								} else {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Plant(GenericSubRoute.View(_g4))));
								};
								break
							case "taskgroups":
								return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Task(TaskRoute.Taskgroup(_g4))));
								break
							case "tasks":
								if (_g4 == null) {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Task(TaskRoute.View(_g4))));
								} else if (_g4 == "new") {
									var id6 = _g2;
									return RouteTools.tryParse(function () {
										return Route.Authenticated(AuthenticatedRoute.Project(id6, ProjectRoute.Task(TaskRoute.Create(new Parser6(null, {"fileName": "src/dasloop/app/data/Route.hx", "lineNumber": 275, "className": "dasloop.app.data.RouteTools", "methodName": "fromUrl"}).parse(new QueryStringParser(url.query, "&", "=", 0))))));
									});
								} else {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Task(TaskRoute.View(_g4))));
								};
								break
							case "workergroups":
								if (_g4 == null) {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Workergroup(WorkergroupRoute.View(_g4))));
								} else if (_g4 == "new") {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Workergroup(WorkergroupRoute.Create)));
								} else {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Workergroup(WorkergroupRoute.View(_g4))));
								};
								break
							case "workers":
								if (_g4 == null) {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Worker(WorkerRoute.View(_g4))));
								} else if (_g4 == "new") {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Worker(WorkerRoute.Create)));
								} else {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Worker(WorkerRoute.View(_g4))));
								};
								break
							default:
							return Route.Public(PublicRoute.NotFound(Url.toString(url)));
							
						};
					};
				} else {
					return Route.Public(PublicRoute.NotFound(Url.toString(url)));
				};
				break
			case 5:
				var _g1 = _g[0];
				var _g2 = _g[1];
				var _g3 = _g[2];
				var _g4 = _g[3];
				var _g5 = _g[4];
				if (_g1 == null) {
					return Route.Public(PublicRoute.NotFound(Url.toString(url)));
				} else if (_g1 == "projects") {
					if (_g3 == null) {
						return Route.Public(PublicRoute.NotFound(Url.toString(url)));
					} else {
						switch (_g3) {
							case "aoa_locators":
								if (_g5 == null) {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								} else if (_g5 == "edit") {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.AoaLocator(AoaLocatorRoute.Edit(_g4))));
								} else {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								};
								break
							case "areas":
								if (_g5 == null) {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								} else if (_g5 == "edit") {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Area(GenericSubRoute.Edit(_g4))));
								} else {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								};
								break
							case "beacons":
								if (_g5 == null) {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								} else if (_g5 == "edit") {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Beacon(BeaconRoute.Edit(Std.parseInt(_g4)))));
								} else {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								};
								break
							case "bims":
								if (_g5 == null) {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								} else if (_g5 == "edit") {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Bim(GenericSubRoute.Edit(_g4))));
								} else {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								};
								break
							case "devices":
								if (_g5 == null) {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								} else if (_g5 == "live_call") {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Device(DeviceRoute.LiveCall(_g4))));
								} else {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								};
								break
							case "equipmentgroups":
								if (_g5 == null) {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								} else if (_g5 == "edit") {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Equipmentgroup(EquipmentgroupRoute.Edit(_g4))));
								} else {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								};
								break
							case "equipments":
								if (_g5 == null) {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								} else if (_g5 == "edit") {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Equipment(EquipmentRoute.Edit(_g4))));
								} else {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								};
								break
							case "floorplans":
								if (_g5 == null) {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								} else if (_g5 == "edit") {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Floorplan(GenericSubRoute.Edit(_g4))));
								} else {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								};
								break
							case "plants":
								if (_g5 == null) {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								} else if (_g5 == "edit") {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Plant(GenericSubRoute.Edit(_g4))));
								} else {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								};
								break
							case "workergroups":
								if (_g5 == null) {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								} else if (_g5 == "edit") {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Workergroup(WorkergroupRoute.Edit(_g4))));
								} else {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								};
								break
							case "workers":
								if (_g5 == null) {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								} else if (_g5 == "edit") {
									return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Worker(WorkerRoute.Edit(_g4))));
								} else {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								};
								break
							default:
							return Route.Public(PublicRoute.NotFound(Url.toString(url)));
							
						};
					};
				} else {
					return Route.Public(PublicRoute.NotFound(Url.toString(url)));
				};
				break
			case 6:
				var _g1 = _g[0];
				var _g2 = _g[1];
				var _g3 = _g[2];
				var _g4 = _g[3];
				var _g5 = _g[4];
				var _g6 = _g[5];
				if (_g1 == null) {
					return Route.Public(PublicRoute.NotFound(Url.toString(url)));
				} else if (_g1 == "projects") {
					if (_g3 == null) {
						return Route.Public(PublicRoute.NotFound(Url.toString(url)));
					} else {
						switch (_g3) {
							case "devices":
								if (_g5 == null) {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								} else if (_g5 == "live_call") {
									if (_g6 == null) {
										return Route.Public(PublicRoute.NotFound(Url.toString(url)));
									} else if (_g6 == "download") {
										return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Device(DeviceRoute.Download(_g4, DeviceDownloadRoute.LiveCall))));
									} else {
										return Route.Public(PublicRoute.NotFound(Url.toString(url)));
									};
								} else {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								};
								break
							case "equipmentgroups":
								if (_g5 == null) {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								} else if (_g5 == "equipments") {
									if (_g6 == null) {
										return Route.Public(PublicRoute.NotFound(Url.toString(url)));
									} else if (_g6 == "edit") {
										return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Equipmentgroup(EquipmentgroupRoute.EditEquipments(_g4))));
									} else {
										return Route.Public(PublicRoute.NotFound(Url.toString(url)));
									};
								} else {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								};
								break
							case "workergroups":
								if (_g5 == null) {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								} else if (_g5 == "workers") {
									if (_g6 == null) {
										return Route.Public(PublicRoute.NotFound(Url.toString(url)));
									} else if (_g6 == "edit") {
										return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Workergroup(WorkergroupRoute.EditWorkers(_g4))));
									} else {
										return Route.Public(PublicRoute.NotFound(Url.toString(url)));
									};
								} else {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								};
								break
							case "workers":
								if (_g5 == null) {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								} else if (_g5 == "certificates") {
									if (_g6 == null) {
										return Route.Public(PublicRoute.NotFound(Url.toString(url)));
									} else if (_g6 == "new") {
										return Route.Authenticated(AuthenticatedRoute.Project(_g2, ProjectRoute.Worker(WorkerRoute.CreateCertificate(_g4))));
									} else {
										return Route.Public(PublicRoute.NotFound(Url.toString(url)));
									};
								} else {
									return Route.Public(PublicRoute.NotFound(Url.toString(url)));
								};
								break
							default:
							return Route.Public(PublicRoute.NotFound(Url.toString(url)));
							
						};
					};
				} else {
					return Route.Public(PublicRoute.NotFound(Url.toString(url)));
				};
				break
			case 7:
				var _g1 = _g[0];
				var _g2 = _g[2];
				var _g3 = _g[4];
				var _g4 = _g[6];
				if (_g1 == null) {
					return Route.Public(PublicRoute.NotFound(Url.toString(url)));
				} else if (_g1 == "projects") {
					if (_g2 == null) {
						return Route.Public(PublicRoute.NotFound(Url.toString(url)));
					} else if (_g2 == "workers") {
						if (_g3 == null) {
							return Route.Public(PublicRoute.NotFound(Url.toString(url)));
						} else if (_g3 == "certificates") {
							if (_g4 == null) {
								return Route.Public(PublicRoute.NotFound(Url.toString(url)));
							} else if (_g4 == "edit") {
								return Route.Authenticated(AuthenticatedRoute.Project(_g[1], ProjectRoute.Worker(WorkerRoute.EditCertificate(_g[3], _g[5]))));
							} else {
								return Route.Public(PublicRoute.NotFound(Url.toString(url)));
							};
						} else {
							return Route.Public(PublicRoute.NotFound(Url.toString(url)));
						};
					} else {
						return Route.Public(PublicRoute.NotFound(Url.toString(url)));
					};
				} else {
					return Route.Public(PublicRoute.NotFound(Url.toString(url)));
				};
				break
			default:
			return Route.Public(PublicRoute.NotFound(Url.toString(url)));
			
		};
	}
	static tryParse(f) {
		try {
			return f();
		}catch (_g) {
			return Route.Public(PublicRoute.Error(Exception.caught(_g).unwrap()));
		};
	}
	static get __name__() {
		return "dasloop.app.data.RouteTools"
	}
	get __class__() {
		return RouteTools
	}
}


export const AuthenticatedRouteTools = Register.global("$hxClasses")["dasloop.app.data.AuthenticatedRouteTools"] = 
class AuthenticatedRouteTools {
	static toUrl(route) {
		switch (route._hx_index) {
			case 0:
				return Url.fromString("/");
				break
			case 1:
				return Url.fromString("/settings");
				break
			case 2:
				return Url.fromString("/summary");
				break
			case 3:
				return Url.fromString("/projects");
				break
			case 4:
				return Url.fromString("/projects/new");
				break
			case 5:
				var _g = route.sub;
				switch (_g._hx_index) {
					case 0:
						var _g1 = new Builder11().stringify(_g.options);
						return Url.fromString("/devices/new" + ((_g1 == "") ? "" : "?" + _g1));
						break
					case 1:
						var _g1 = new Builder10().stringify(_g.options);
						return Url.fromString("/beacons/new" + ((_g1 == "") ? "" : "?" + _g1));
						break
					case 2:
						var _g1 = new Builder10().stringify(_g.options);
						return Url.fromString("/aoa_locator/new" + ((_g1 == "") ? "" : "?" + _g1));
						break
					
				};
				break
			case 6:
				var _g = route.id;
				var _g1 = route.sub;
				switch (_g1._hx_index) {
					case 0:
						var _g2 = new Builder12().stringify(_g1.options);
						return Url.fromString("/projects/" + _g + ((_g2 == "") ? "" : "?" + _g2));
						break
					case 1:
						return Url.fromString("/projects/" + _g + "/edit");
						break
					case 2:
						var _g2 = _g1.sub;
						switch (_g2._hx_index) {
							case 0:
								return Url.fromString("/projects/" + _g + "/devices/" + _g2.id);
								break
							case 1:
								var _g3 = new Builder14().stringify(_g2.options);
								return Url.fromString("/projects/" + _g + "/devices" + ((_g3 == "") ? "" : "?" + _g3));
								break
							case 2:
								var _g3 = new Builder15().stringify(_g2.options);
								return Url.fromString("/projects/" + _g + "/devices/new" + ((_g3 == "") ? "" : "?" + _g3));
								break
							case 3:
								return Url.fromString("/projects/" + _g + "/devices/" + _g2.id + "/live_call");
								break
							case 4:
								return Url.fromString("/projects/" + _g + "/devices/" + _g2.id + "/live_call/download");
								break
							
						};
						break
					case 3:
						var _g2 = _g1.sub;
						switch (_g2._hx_index) {
							case 0:
								return Url.fromString("/projects/" + _g + "/floorplans");
								break
							case 1:
								return Url.fromString("/projects/" + _g + "/floorplans/new");
								break
							case 2:
								return Url.fromString("/projects/" + _g + "/floorplans/" + _g2.id);
								break
							case 3:
								return Url.fromString("/projects/" + _g + "/floorplans/" + _g2.id + "/edit");
								break
							
						};
						break
					case 4:
						var _g2 = _g1.sub;
						switch (_g2._hx_index) {
							case 0:
								return Url.fromString("/projects/" + _g + "/bims");
								break
							case 1:
								return Url.fromString("/projects/" + _g + "/bims/new");
								break
							case 2:
								return Url.fromString("/projects/" + _g + "/bims/" + _g2.id);
								break
							case 3:
								return Url.fromString("/projects/" + _g + "/bims/" + _g2.id + "/edit");
								break
							
						};
						break
					case 5:
						var _g2 = _g1.sub;
						switch (_g2._hx_index) {
							case 0:
								return Url.fromString("/projects/" + _g + "/areas");
								break
							case 1:
								return Url.fromString("/projects/" + _g + "/areas/new");
								break
							case 2:
								return Url.fromString("/projects/" + _g + "/areas/" + _g2.id);
								break
							case 3:
								return Url.fromString("/projects/" + _g + "/areas/" + _g2.id + "/edit");
								break
							
						};
						break
					case 6:
						var _g2 = _g1.sub;
						switch (_g2._hx_index) {
							case 0:
								return Url.fromString("/projects/" + _g + "/plants");
								break
							case 1:
								return Url.fromString("/projects/" + _g + "/plants/new");
								break
							case 2:
								return Url.fromString("/projects/" + _g + "/plants/" + _g2.id);
								break
							case 3:
								return Url.fromString("/projects/" + _g + "/plants/" + _g2.id + "/edit");
								break
							
						};
						break
					case 7:
						var _g2 = _g1.sub;
						switch (_g2._hx_index) {
							case 0:
								return Url.fromString("/projects/" + _g + "/aoa_locators");
								break
							case 1:
								var _g3 = new Builder13().stringify(_g2.options);
								return Url.fromString("/projects/" + _g + "/aoa_locators/new" + ((_g3 == "") ? "" : "?" + _g3));
								break
							case 2:
								return Url.fromString("/projects/" + _g + "/aoa_locators/" + _g2.id);
								break
							case 3:
								return Url.fromString("/projects/" + _g + "/aoa_locators/" + _g2.id + "/edit");
								break
							
						};
						break
					case 8:
						var _g2 = _g1.sub;
						switch (_g2._hx_index) {
							case 0:
								return Url.fromString("/projects/" + _g + "/beacons");
								break
							case 1:
								var _g3 = new Builder13().stringify(_g2.options);
								return Url.fromString("/projects/" + _g + "/beacons/new" + ((_g3 == "") ? "" : "?" + _g3));
								break
							case 2:
								return Url.fromString("/projects/" + _g + "/beacons/" + _g2.id);
								break
							case 3:
								return Url.fromString("/projects/" + _g + "/beacons/" + _g2.id + "/edit");
								break
							
						};
						break
					case 9:
						var _g2 = _g1.sub;
						switch (_g2._hx_index) {
							case 0:
								return Url.fromString("/projects/" + _g + "/workers");
								break
							case 1:
								return Url.fromString("/projects/" + _g + "/workers/new");
								break
							case 2:
								return Url.fromString("/projects/" + _g + "/workers/" + _g2.id);
								break
							case 3:
								return Url.fromString("/projects/" + _g + "/workers/" + _g2.id + "/edit");
								break
							case 4:
								return Url.fromString("/projects/" + _g + "/workers/" + _g2.workerId + "/certificates/new");
								break
							case 5:
								return Url.fromString("/projects/" + _g + "/workers/" + _g2.workerId + "/certificates/" + _g2.certId + "/edit");
								break
							
						};
						break
					case 10:
						var _g2 = _g1.sub;
						switch (_g2._hx_index) {
							case 0:
								return Url.fromString("/projects/" + _g + "/workergroups");
								break
							case 1:
								return Url.fromString("/projects/" + _g + "/workergroups/new");
								break
							case 2:
								return Url.fromString("/projects/" + _g + "/workergroups/" + _g2.id);
								break
							case 3:
								return Url.fromString("/projects/" + _g + "/workergroups/" + _g2.id + "/edit");
								break
							case 4:
								return Url.fromString("/projects/" + _g + "/workergroups/" + _g2.id + "/workers/edit");
								break
							
						};
						break
					case 11:
						var _g2 = _g1.sub;
						switch (_g2._hx_index) {
							case 0:
								return Url.fromString("/projects/" + _g + "/equipments/overview");
								break
							case 1:
								return Url.fromString("/projects/" + _g + "/equipments");
								break
							case 2:
								return Url.fromString("/projects/" + _g + "/equipments/new");
								break
							case 3:
								return Url.fromString("/projects/" + _g + "/equipments/" + _g2.id);
								break
							case 4:
								return Url.fromString("/projects/" + _g + "/equipments/" + _g2.id + "/edit");
								break
							
						};
						break
					case 12:
						var _g2 = _g1.sub;
						switch (_g2._hx_index) {
							case 0:
								return Url.fromString("/projects/" + _g + "/equipmentgroups/new");
								break
							case 1:
								return Url.fromString("/projects/" + _g + "/equipmentgroups/" + _g2.id);
								break
							case 2:
								return Url.fromString("/projects/" + _g + "/equipmentgroups/" + _g2.id + "/edit");
								break
							case 3:
								return Url.fromString("/projects/" + _g + "/equipmentgroups/" + _g2.id + "/equipments/edit");
								break
							
						};
						break
					case 13:
						var _g2 = _g1.sub;
						switch (_g2._hx_index) {
							case 0:
								return Url.fromString("/projects/" + _g + "/tasks");
								break
							case 1:
								var _g3 = new Builder16().stringify(_g2.options);
								return Url.fromString("/projects/" + _g + "/tasks/new" + ((_g3 == "") ? "" : "?" + _g3));
								break
							case 2:
								return Url.fromString("/projects/" + _g + "/tasks/" + _g2.id);
								break
							case 3:
								return Url.fromString("/projects/" + _g + "/taskgroups/" + _g2.id);
								break
							
						};
						break
					case 14:
						switch (_g1.sub._hx_index) {
							case 0:
								return Url.fromString("/projects/" + _g + "/audios/new");
								break
							case 1:
								return Url.fromString("/projects/" + _g + "/audios");
								break
							case 2:
								return Url.fromString("/projects/" + _g + "/announcement");
								break
							
						};
						break
					case 15:
						return Url.fromString("/projects/" + _g + "/cctvs");
						break
					case 16:
						return Url.fromString("/projects/" + _g + "/statistics");
						break
					case 17:
						return Url.fromString("/projects/" + _g + "/reports");
						break
					case 18:
						return Url.fromString("/projects/" + _g + "/logs");
						break
					case 19:
						return Url.fromString("/projects/" + _g + "/maintenance_device");
						break
					case 20:
						return Url.fromString("/projects/" + _g + "/settings");
						break
					
				};
				break
			case 7:
				return Url.fromString("/.well-known/change-password");
				break
			
		};
	}
	static get __name__() {
		return "dasloop.app.data.AuthenticatedRouteTools"
	}
	get __class__() {
		return AuthenticatedRouteTools
	}
}

