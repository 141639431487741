import {Boot} from "./js/Boot.js"
import {Register} from "./genes/Register.js"

const $global = Register.$global

export const ValueType = 
Register.global("$hxEnums")["ValueType"] = 
{
	__ename__: "ValueType",
	
	TNull: {_hx_name: "TNull", _hx_index: 0, __enum__: "ValueType"},
	TInt: {_hx_name: "TInt", _hx_index: 1, __enum__: "ValueType"},
	TFloat: {_hx_name: "TFloat", _hx_index: 2, __enum__: "ValueType"},
	TBool: {_hx_name: "TBool", _hx_index: 3, __enum__: "ValueType"},
	TObject: {_hx_name: "TObject", _hx_index: 4, __enum__: "ValueType"},
	TFunction: {_hx_name: "TFunction", _hx_index: 5, __enum__: "ValueType"},
	TClass: Object.assign((c) => ({_hx_index: 6, __enum__: "ValueType", "c": c}), {_hx_name: "TClass", __params__: ["c"]}),
	TEnum: Object.assign((e) => ({_hx_index: 7, __enum__: "ValueType", "e": e}), {_hx_name: "TEnum", __params__: ["e"]}),
	TUnknown: {_hx_name: "TUnknown", _hx_index: 8, __enum__: "ValueType"}
}
ValueType.__constructs__ = [ValueType.TNull, ValueType.TInt, ValueType.TFloat, ValueType.TBool, ValueType.TObject, ValueType.TFunction, ValueType.TClass, ValueType.TEnum, ValueType.TUnknown]
ValueType.__empty_constructs__ = [ValueType.TNull, ValueType.TInt, ValueType.TFloat, ValueType.TBool, ValueType.TObject, ValueType.TFunction, ValueType.TUnknown]

/**
The Haxe Reflection API allows retrieval of type information at runtime.

This class complements the more lightweight Reflect class, with a focus on
class and enum instances.

@see https://haxe.org/manual/types.html
@see https://haxe.org/manual/std-reflection.html
*/
export const Type = Register.global("$hxClasses")["Type"] = 
class Type {
	
	/**
	Creates an instance of class `cl`, using `args` as arguments to the
	class constructor.
	
	This function guarantees that the class constructor is called.
	
	Default values of constructors arguments are not guaranteed to be
	taken into account.
	
	If `cl` or `args` are null, or if the number of elements in `args` does
	not match the expected number of constructor arguments, or if any
	argument has an invalid type,  or if `cl` has no own constructor, the
	result is unspecified.
	
	In particular, default values of constructor arguments are not
	guaranteed to be taken into account.
	*/
	static createInstance(cl, args) {
		return new (Function.prototype.bind.apply(cl,[null].concat(args)));
	}
	
	/**
	Returns the runtime type of value `v`.
	
	The result corresponds to the type `v` has at runtime, which may vary
	per platform. Assumptions regarding this should be minimized to avoid
	surprises.
	*/
	static typeof(v) {
		switch (typeof(v)) {
			case "boolean":
				return ValueType.TBool;
				break
			case "function":
				if (v.__name__ || v.__ename__) {
					return ValueType.TObject;
				};
				return ValueType.TFunction;
				break
			case "number":
				if (Math.ceil(v) == v % 2147483648.0) {
					return ValueType.TInt;
				};
				return ValueType.TFloat;
				break
			case "object":
				if (v == null) {
					return ValueType.TNull;
				};
				var e = v.__enum__;
				if (e != null) {
					return ValueType.TEnum(Register.global("$hxEnums")[e]);
				};
				var c = Boot.getClass(v);
				if (c != null) {
					return ValueType.TClass(c);
				};
				return ValueType.TObject;
				break
			case "string":
				return ValueType.TClass(String);
				break
			case "undefined":
				return ValueType.TNull;
				break
			default:
			return ValueType.TUnknown;
			
		};
	}
	
	/**
	Returns a list of the constructor arguments of enum instance `e`.
	
	If `e` has no arguments, the result is [].
	
	Otherwise the result are the values that were used as arguments to `e`,
	in the order of their declaration.
	
	If `e` is null, the result is unspecified.
	*/
	static enumParameters(e) {
		var enm = Register.global("$hxEnums")[e.__enum__];
		var params = enm.__constructs__[e._hx_index].__params__;
		if (params != null) {
			var _g = [];
			var _g1 = 0;
			while (_g1 < params.length) {
				var p = params[_g1];
				++_g1;
				_g.push(e[p]);
			};
			return _g;
		} else {
			return [];
		};
	}
	static get __name__() {
		return "Type"
	}
	get __class__() {
		return Type
	}
}

