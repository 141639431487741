import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Transform = Register.global("$hxClasses")["tink.state.internal._Transform.Transform"] = 
class Transform {
	static plain(f) {
		return f;
	}
	static get __name__() {
		return "tink.state.internal._Transform.Transform_Impl_"
	}
	get __class__() {
		return Transform
	}
}

