import {Portion} from "../url/Portion.js"
import {DefaultKeymaker} from "./Keymaker.js"
import {NamedWith} from "../core/Named.js"
import {Register} from "../../genes/Register.js"
import {Std} from "../../Std.js"

const $global = Register.$global

export const Builder5 = Register.global("$hxClasses")["tink.querystring.Builder5"] = 
class Builder5 extends Register.inherits() {
	new() {
	}
	stringify(data, keymaker) {
		if (keymaker == null) {
			keymaker = new DefaultKeymaker();
		};
		var buffer = [];
		this.process0("", buffer, keymaker, data);
		return buffer;
	}
	process0(prefix, buffer, keymaker, data) {
		var prefix1 = (prefix == "") ? "after" : keymaker.field(prefix, "after");
		var data1 = data._152;
		if (data1 != null) {
			var value = Std.string(data1.getTime());
			buffer.push(new NamedWith(Portion.ofString(prefix1), Portion.ofString(value)));
		};
		var prefix1 = (prefix == "") ? "before" : keymaker.field(prefix, "before");
		var data1 = data._153;
		if (data1 != null) {
			var value = Std.string(data1.getTime());
			buffer.push(new NamedWith(Portion.ofString(prefix1), Portion.ofString(value)));
		};
		var prefix1 = (prefix == "") ? "limit" : keymaker.field(prefix, "limit");
		var data1 = data._154;
		if (data1 != null) {
			buffer.push(new NamedWith(Portion.ofString(prefix1), Portion.ofString((data1 == null) ? "null" : "" + data1)));
		};
	}
	static get __name__() {
		return "tink.querystring.Builder5"
	}
	get __class__() {
		return Builder5
	}
}

