import {RemoteBase, RemoteEndpoint, ResponseReader} from "./Remote.js"
import {Single} from "../../streams/Stream.js"
import {Builder33} from "../../querystring/Builder33.js"
import {Parser73} from "../../json/Parser73.js"
import {HeaderField, HeaderValue} from "../../http/Header.js"
import {LazyConst} from "../../core/Lazy.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote74 = Register.global("$hxClasses")["tink.web.proxy.Remote74"] = 
class Remote74 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	get(region, language) {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [].concat(new Builder33().stringify({"_287": region, "_288": language})), "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pe))(new Parser73())));
	}
	static get __name__() {
		return "tink.web.proxy.Remote74"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote74
	}
}

