import {RemoteBase, RemoteEndpoint, ResponseReader} from "./Remote.js"
import {Single} from "../../streams/Stream.js"
import {Writer60} from "../../json/Writer60.js"
import {Parser64} from "../../json/Parser64.js"
import {HeaderField, HeaderValue} from "../../http/Header.js"
import {LazyConst} from "../../core/Lazy.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote72 = Register.global("$hxClasses")["tink.web.proxy.Remote72"] = 
class Remote72 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	add(id) {
		var __body__ = ByteChunk.of(Bytes.ofString(new Writer60().wc({"_280": id})));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [], "headers": [new HeaderField("content-type", "application/json"), new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "POST", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pi))(new Parser64())));
	}
	static get __name__() {
		return "tink.web.proxy.Remote72"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote72
	}
}

