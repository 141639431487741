import {Register} from "../../genes/Register.js"
import {GasReadingTools} from "./GasReading.js"
import {StringTools} from "../../StringTools.js"

const $global = Register.$global

export const GasAlarmStatus = Register.global("$hxClasses")["dasloop.types._AlertValue.GasAlarmStatus"] = 
class GasAlarmStatus {
	static isFirstAlarm(this1) {
		return (this1 & 1) != 0;
	}
	static isSecondAlarm(this1) {
		return (this1 & 2) != 0;
	}
	static isThirdAlarm(this1) {
		return (this1 & 4) != 0;
	}
	static isSTELAlarm(this1) {
		return (this1 & 8) != 0;
	}
	static isTWAAlarm(this1) {
		return (this1 & 16) != 0;
	}
	static isMinusAlarm(this1) {
		return (this1 & 32) != 0;
	}
	static isCumulativeAlarm(this1) {
		return (this1 & 64) != 0;
	}
	static isOverAlarm(this1) {
		return (this1 & 128) != 0;
	}
	static print(this1) {
		var types = [];
		if (GasAlarmStatus.isFirstAlarm(this1)) {
			types.push("1st Alarm");
		};
		if (GasAlarmStatus.isSecondAlarm(this1)) {
			types.push("2nd Alarm");
		};
		if (GasAlarmStatus.isThirdAlarm(this1)) {
			types.push("3rd Alarm");
		};
		if (GasAlarmStatus.isSTELAlarm(this1)) {
			types.push("STEL Alarm");
		};
		if (GasAlarmStatus.isTWAAlarm(this1)) {
			types.push("TWA Alarm");
		};
		if (GasAlarmStatus.isMinusAlarm(this1)) {
			types.push("Minus Alarm");
		};
		if (GasAlarmStatus.isCumulativeAlarm(this1)) {
			types.push("Cumulative Alarm");
		};
		if (GasAlarmStatus.isOverAlarm(this1)) {
			types.push("Over Alarm");
		};
		return types.join(" | ");
	}
	static get __name__() {
		return "dasloop.types._AlertValue.GasAlarmStatus_Impl_"
	}
	get __class__() {
		return GasAlarmStatus
	}
}


export const AlertKind = Register.global("$hxClasses")["dasloop.types._AlertValue.AlertKind"] = 
class AlertKind {
	static translate(this1, localizer) {
		switch (this1) {
			case 1:
				return localizer.panicButton();
				break
			case 2:
				return localizer.freeFall();
				break
			case 3:
				return localizer.bodyTempTooHigh();
				break
			case 4:
				return localizer.bodyTempTooLow();
				break
			case 5:
				return localizer.heartRateTooHigh();
				break
			case 6:
				return localizer.heartRateTooLow();
				break
			case 7:
				return localizer.heatStress();
				break
			case 8:
				return localizer.standstill();
				break
			case 9:
				return localizer.restrictedArea();
				break
			case 10:
				return localizer.uwbEnter();
				break
			case 11:
				return localizer.uwbExit();
				break
			case 12:
				return localizer.regularSignalMissing();
				break
			case 13:
				return localizer.currentTooHigh();
				break
			case 14:
				return localizer.currentTooLow();
				break
			case 15:
				return localizer.temperatureTooHigh();
				break
			case 16:
				return localizer.temperatureTooLow();
				break
			case 17:
				return localizer.gasConcentrationAbnormal();
				break
			case 18:
				return localizer.uwbEnterAuthMuteZone();
				break
			case 19:
				return localizer.uwbExitAuthMuteZone();
				break
			
		};
	}
	static get __name__() {
		return "dasloop.types._AlertValue.AlertKind_Impl_"
	}
	get __class__() {
		return AlertKind
	}
}


export const AlertValue = 
Register.global("$hxEnums")["dasloop.types.AlertValue"] = 
{
	__ename__: "dasloop.types.AlertValue",
	
	Legacy: Object.assign((value) => ({_hx_index: 0, __enum__: "dasloop.types.AlertValue", "value": value}), {_hx_name: "Legacy", __params__: ["value"]}),
	PanicButton: {_hx_name: "PanicButton", _hx_index: 1, __enum__: "dasloop.types.AlertValue"},
	FreeFall: Object.assign((freeFall) => ({_hx_index: 2, __enum__: "dasloop.types.AlertValue", "freeFall": freeFall}), {_hx_name: "FreeFall", __params__: ["freeFall"]}),
	BodyTempTooHigh: Object.assign((bodyTempTooHigh) => ({_hx_index: 3, __enum__: "dasloop.types.AlertValue", "bodyTempTooHigh": bodyTempTooHigh}), {_hx_name: "BodyTempTooHigh", __params__: ["bodyTempTooHigh"]}),
	BodyTempTooLow: Object.assign((bodyTempTooLow) => ({_hx_index: 4, __enum__: "dasloop.types.AlertValue", "bodyTempTooLow": bodyTempTooLow}), {_hx_name: "BodyTempTooLow", __params__: ["bodyTempTooLow"]}),
	HeartRateTooHigh: Object.assign((heartRateTooHigh) => ({_hx_index: 5, __enum__: "dasloop.types.AlertValue", "heartRateTooHigh": heartRateTooHigh}), {_hx_name: "HeartRateTooHigh", __params__: ["heartRateTooHigh"]}),
	HeartRateTooLow: Object.assign((heartRateTooLow) => ({_hx_index: 6, __enum__: "dasloop.types.AlertValue", "heartRateTooLow": heartRateTooLow}), {_hx_name: "HeartRateTooLow", __params__: ["heartRateTooLow"]}),
	HeatStress: Object.assign((heatStress) => ({_hx_index: 7, __enum__: "dasloop.types.AlertValue", "heatStress": heatStress}), {_hx_name: "HeatStress", __params__: ["heatStress"]}),
	Standstill: Object.assign((standstill) => ({_hx_index: 8, __enum__: "dasloop.types.AlertValue", "standstill": standstill}), {_hx_name: "Standstill", __params__: ["standstill"]}),
	RestrictedArea: Object.assign((restrictedArea) => ({_hx_index: 9, __enum__: "dasloop.types.AlertValue", "restrictedArea": restrictedArea}), {_hx_name: "RestrictedArea", __params__: ["restrictedArea"]}),
	UwbEnter: Object.assign((uwbEnter) => ({_hx_index: 10, __enum__: "dasloop.types.AlertValue", "uwbEnter": uwbEnter}), {_hx_name: "UwbEnter", __params__: ["uwbEnter"]}),
	UwbExit: Object.assign((uwbExit) => ({_hx_index: 11, __enum__: "dasloop.types.AlertValue", "uwbExit": uwbExit}), {_hx_name: "UwbExit", __params__: ["uwbExit"]}),
	RegularSignalMissing: Object.assign((regularSignalMissing) => ({_hx_index: 12, __enum__: "dasloop.types.AlertValue", "regularSignalMissing": regularSignalMissing}), {_hx_name: "RegularSignalMissing", __params__: ["regularSignalMissing"]}),
	CurrentTooHigh: Object.assign((currentTooHigh) => ({_hx_index: 13, __enum__: "dasloop.types.AlertValue", "currentTooHigh": currentTooHigh}), {_hx_name: "CurrentTooHigh", __params__: ["currentTooHigh"]}),
	CurrentTooLow: Object.assign((currentTooLow) => ({_hx_index: 14, __enum__: "dasloop.types.AlertValue", "currentTooLow": currentTooLow}), {_hx_name: "CurrentTooLow", __params__: ["currentTooLow"]}),
	TemperatureTooHigh: Object.assign((temperatureTooHigh) => ({_hx_index: 15, __enum__: "dasloop.types.AlertValue", "temperatureTooHigh": temperatureTooHigh}), {_hx_name: "TemperatureTooHigh", __params__: ["temperatureTooHigh"]}),
	TemperatureTooLow: Object.assign((temperatureTooLow) => ({_hx_index: 16, __enum__: "dasloop.types.AlertValue", "temperatureTooLow": temperatureTooLow}), {_hx_name: "TemperatureTooLow", __params__: ["temperatureTooLow"]}),
	GasConcentrationAbnormal: Object.assign((gasAlarmData) => ({_hx_index: 17, __enum__: "dasloop.types.AlertValue", "gasAlarmData": gasAlarmData}), {_hx_name: "GasConcentrationAbnormal", __params__: ["gasAlarmData"]}),
	UwbEnterAuthMuteZone: Object.assign((uwb) => ({_hx_index: 18, __enum__: "dasloop.types.AlertValue", "uwb": uwb}), {_hx_name: "UwbEnterAuthMuteZone", __params__: ["uwb"]}),
	UwbExitAuthMuteZone: Object.assign((uwb) => ({_hx_index: 19, __enum__: "dasloop.types.AlertValue", "uwb": uwb}), {_hx_name: "UwbExitAuthMuteZone", __params__: ["uwb"]})
}
AlertValue.__constructs__ = [AlertValue.Legacy, AlertValue.PanicButton, AlertValue.FreeFall, AlertValue.BodyTempTooHigh, AlertValue.BodyTempTooLow, AlertValue.HeartRateTooHigh, AlertValue.HeartRateTooLow, AlertValue.HeatStress, AlertValue.Standstill, AlertValue.RestrictedArea, AlertValue.UwbEnter, AlertValue.UwbExit, AlertValue.RegularSignalMissing, AlertValue.CurrentTooHigh, AlertValue.CurrentTooLow, AlertValue.TemperatureTooHigh, AlertValue.TemperatureTooLow, AlertValue.GasConcentrationAbnormal, AlertValue.UwbEnterAuthMuteZone, AlertValue.UwbExitAuthMuteZone]
AlertValue.__empty_constructs__ = [AlertValue.PanicButton]

export const AlertValueTools = Register.global("$hxClasses")["dasloop.types.AlertValueTools"] = 
class AlertValueTools {
	static toType(kind) {
		switch (kind._hx_index) {
			case 0:
				return kind.value;
				break
			case 1:
				return 1;
				break
			case 2:
				return 2;
				break
			case 3:
				return 3;
				break
			case 4:
				return 4;
				break
			case 5:
				return 5;
				break
			case 6:
				return 6;
				break
			case 7:
				return 7;
				break
			case 8:
				return 8;
				break
			case 9:
				return 9;
				break
			case 10:
				return 10;
				break
			case 11:
				return 11;
				break
			case 12:
				return 12;
				break
			case 13:
				return 13;
				break
			case 14:
				return 14;
				break
			case 15:
				return 15;
				break
			case 16:
				return 16;
				break
			case 17:
				return 17;
				break
			case 18:
				return 18;
				break
			case 19:
				return 19;
				break
			
		};
	}
	static translate(kind, localizer) {
		switch (kind._hx_index) {
			case 0:
				return AlertKind.translate(kind.value, localizer.legacy);
				break
			case 1:
				return localizer.panicButton();
				break
			case 2:
				var _g = kind.freeFall;
				return localizer.freeFall(_g.accelerationThreshold, _g.durationThreshold, _g.actualX, _g.actualY, _g.actualZ);
				break
			case 3:
				var _g = kind.bodyTempTooHigh;
				return localizer.bodyTempTooHigh(_g.threshold, _g.actual);
				break
			case 4:
				var _g = kind.bodyTempTooLow;
				return localizer.bodyTempTooLow(_g.threshold, _g.actual);
				break
			case 5:
				var _g = kind.heartRateTooHigh;
				if (_g.quality == null) {
					return localizer.heartRateTooHigh(_g.threshold, _g.actual);
				} else {
					return localizer.heartRateTooHighQ(_g.threshold, _g.actual, _g.quality);
				};
				break
			case 6:
				var _g = kind.heartRateTooLow;
				if (_g.quality == null) {
					return localizer.heartRateTooLow(_g.threshold, _g.actual);
				} else {
					return localizer.heartRateTooLowQ(_g.threshold, _g.actual, _g.quality);
				};
				break
			case 7:
				var _g = kind.heatStress;
				return localizer.heatStress(_g.threshold, _g.actual);
				break
			case 8:
				return localizer.standstill(kind.standstill.threshold);
				break
			case 9:
				return localizer.restrictedArea(kind.restrictedArea.area);
				break
			case 10:
				var _g = kind.uwbEnter;
				return localizer.uwbEnter(_g.device, StringTools.hex(_g.id, 6), _g.distance, (_g.serialNumber == null) ? "" : _g.serialNumber);
				break
			case 11:
				var _g = kind.uwbExit;
				return localizer.uwbExit(_g.device, StringTools.hex(_g.id, 6), _g.distance, (_g.serialNumber == null) ? "" : _g.serialNumber);
				break
			case 12:
				var _g = kind.regularSignalMissing;
				var seconds = _g.timeout / 1000 | 0;
				return localizer.regularSignalMissing((seconds < 60) ? localizer.seconds(seconds) : localizer.minutes(Math.round(seconds / 60)), _g.shutdownReason == 2);
				break
			case 13:
				var _g = kind.currentTooHigh;
				return localizer.currentTooHigh(_g.threshold, _g.actual);
				break
			case 14:
				var _g = kind.currentTooLow;
				return localizer.currentTooLow(_g.threshold, _g.actual);
				break
			case 15:
				var _g = kind.temperatureTooHigh;
				return localizer.temperatureTooHigh(_g.threshold, _g.actual);
				break
			case 16:
				var _g = kind.temperatureTooLow;
				return localizer.temperatureTooLow(_g.threshold, _g.actual);
				break
			case 17:
				var _g = kind.gasAlarmData;
				return localizer.gasConcentrationAbnormal(GasReadingTools.print(_g.reading), GasAlarmStatus.print(_g.alarmStatus));
				break
			case 18:
				var _g = kind.uwb;
				return localizer.uwbEnterAuthMuteZone(_g.device, StringTools.hex(_g.id, 6), _g.distance, (_g.serialNumber == null) ? "" : _g.serialNumber);
				break
			case 19:
				var _g = kind.uwb;
				return localizer.uwbExitAuthMuteZone(_g.device, StringTools.hex(_g.id, 6), _g.distance, (_g.serialNumber == null) ? "" : _g.serialNumber);
				break
			
		};
	}
	static get __name__() {
		return "dasloop.types.AlertValueTools"
	}
	get __class__() {
		return AlertValueTools
	}
}

