import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {ConstObservable, Observable} from "../../../tink/state/Observable.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Outcome} from "../../../tink/core/Outcome.js"
import {LazyConst} from "../../../tink/core/Lazy.js"
import {SyncFuture} from "../../../tink/core/Future.js"
import {TypedError} from "../../../tink/core/Error.js"
import {Register} from "../../../genes/Register.js"
import {TasksData} from "./TasksData.js"
import {Updatable} from "../../../coconut/ds/Updatable.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const TaskgroupData = Register.global("$hxClasses")["dasloop.app.data.TaskgroupData"] = 
class TaskgroupData extends Register.inherits() {
	new(__coco_init) {
		var _gthis = this;
		this.project = __coco_init.project;
		this.id = __coco_init.id;
		this.remote = __coco_init.remote;
		this.pubsub = __coco_init.pubsub;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new Updatable({"loader": function () {
			return _gthis.remote.v2().taskgroups().ofId(_gthis.id).get();
		}, "updater": function (current, patch) {
			return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(null, "NotImplemented", {"fileName": "src/dasloop/app/data/TaskgroupData.hx", "lineNumber": 18, "className": "dasloop.app.data.TaskgroupData", "methodName": "new"}))));
		}});
		AutoObservable.cur = before;
		this.data = ret;
		this.__coco_revision = State._new(0, null, null, null);
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new TasksData({"remote": _gthis.remote, "pubsub": _gthis.pubsub, "group": _gthis.id, "project": _gthis.project});
		AutoObservable.cur = before;
		this.tasks = ret;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"project": new ConstObservable(this.project), "id": new ConstObservable(this.id), "remote": new ConstObservable(this.remote), "pubsub": new ConstObservable(this.pubsub), "data": new ConstObservable(this.data), "tasks": new ConstObservable(this.tasks), "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	static get __name__() {
		return "dasloop.app.data.TaskgroupData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return TaskgroupData
	}
}

