import {Lazy, LazyConst} from "./Lazy.js"
import {SyncFuture} from "./Future.js"
import {TypedError} from "./Error.js"
import {Exception} from "../../haxe/Exception.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

/**
Representation of the outcome of any kind of operation that can fail.

Values of this type automatically use the extension methods defined in `OutcomeTools`.
*/
export const Outcome = 
Register.global("$hxEnums")["tink.core.Outcome"] = 
{
	__ename__: "tink.core.Outcome",
	
	Success: Object.assign((data) => ({_hx_index: 0, __enum__: "tink.core.Outcome", "data": data}), {_hx_name: "Success", __params__: ["data"]}),
	Failure: Object.assign((failure) => ({_hx_index: 1, __enum__: "tink.core.Outcome", "failure": failure}), {_hx_name: "Failure", __params__: ["failure"]})
}
Outcome.__constructs__ = [Outcome.Success, Outcome.Failure]
Outcome.__empty_constructs__ = []

export const OutcomeTools = Register.global("$hxClasses")["tink.core.OutcomeTools"] = 
class OutcomeTools {
	
	/**
	*  Extracts the value if the outcome is `Success`, throws the `Failure` contents otherwise
	*/
	static sure(outcome) {
		switch (outcome._hx_index) {
			case 0:
				return outcome.data;
				break
			case 1:
				var _g = outcome.failure;
				var _g1 = TypedError.asError(_g);
				if (_g1 == null) {
					throw Exception.thrown(_g);
				} else {
					return _g1.throwSelf();
				};
				break
			
		};
	}
	
	/**
	*  Extracts the value if the outcome is `Success`, uses the fallback value otherwise
	*/
	static or(outcome, fallback) {
		switch (outcome._hx_index) {
			case 0:
				return outcome.data;
				break
			case 1:
				return Lazy.get(fallback);
				break
			
		};
	}
	
	/**
	*  Transforms the outcome with a transform function
	*  Different from `flatMap`, the transform function of `map` returns a plain value
	*/
	static map(outcome, transform) {
		switch (outcome._hx_index) {
			case 0:
				return Outcome.Success(transform(outcome.data));
				break
			case 1:
				return Outcome.Failure(outcome.failure);
				break
			
		};
	}
	
	/**
	*  Returns `true` if the outcome is `Success`
	*/
	static isSuccess(outcome) {
		if (outcome._hx_index == 0) {
			return true;
		} else {
			return false;
		};
	}
	
	/**
	*  Transforms the outcome with a transform function
	*  Different from `map`, the transform function of `flatMap` returns an `Outcome`
	*/
	static flatMap(o, mapper) {
		return OutcomeMapper.apply(mapper, o);
	}
	static next(outcome, f) {
		switch (outcome._hx_index) {
			case 0:
				return f(outcome.data);
				break
			case 1:
				return new SyncFuture(new LazyConst(Outcome.Failure(outcome.failure)));
				break
			
		};
	}
	static get __name__() {
		return "tink.core.OutcomeTools"
	}
	get __class__() {
		return OutcomeTools
	}
}


export const OutcomeMapper = Register.global("$hxClasses")["tink.core._Outcome.OutcomeMapper"] = 
class OutcomeMapper {
	static _new(f) {
		return {"f": f};
	}
	static apply(this1, o) {
		return this1.f(o);
	}
	static withSameError(f) {
		return OutcomeMapper._new(function (o) {
			switch (o._hx_index) {
				case 0:
					return f(o.data);
					break
				case 1:
					return Outcome.Failure(o.failure);
					break
				
			};
		});
	}
	static get __name__() {
		return "tink.core._Outcome.OutcomeMapper_Impl_"
	}
	get __class__() {
		return OutcomeMapper
	}
}

