import {Lines} from "./Lines.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Liness = Register.global("$hxClasses")["geojson.util._Liness.Liness"] = 
class Liness {
	static is(v) {
		if (!((v) instanceof Array)) {
			return false;
		};
		if (v.length == 0) {
			return false;
		};
		var _g = 0;
		var _g1 = v;
		while (_g < _g1.length) if (!Lines.is(_g1[_g++])) {
			return false;
		};
		return true;
	}
	static get __name__() {
		return "geojson.util._Liness.Liness_Impl_"
	}
	get __class__() {
		return Liness
	}
}

