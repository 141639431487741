import {Writer12} from "./Writer12.js"
import {BasicParser, JsonString} from "./Parser.js"
import {TypedError} from "../core/Error.js"
import {Exception} from "../../haxe/Exception.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Parser20 = Register.global("$hxClasses")["tink.json.Parser20"] = 
class Parser20 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	pb() {
		var _gthis = this;
		var v_age = null;
		var v_company = null;
		var hasv_company = false;
		var v_dateOfBirth = null;
		var v_division = null;
		var hasv_division = false;
		var v_email = null;
		var v_gender = null;
		var hasv_gender = false;
		var v_id = null;
		var hasv_id = false;
		var v_name = null;
		var hasv_name = false;
		var v_phone = null;
		var v_project = null;
		var hasv_project = false;
		var v_registrationNumber = null;
		var hasv_registrationNumber = false;
		var v_remarks = null;
		var hasv_remarks = false;
		var v_trade = null;
		var hasv_trade = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "age":
						v_age = (this.E("null")) ? null : JsonString.i(this.r());
						break
					case "company":
						v_company = JsonString.s(this.m());
						hasv_company = true;
						break
					case "dateOfBirth":
						v_dateOfBirth = (this.E("null")) ? null : JsonString.s(this.m());
						break
					case "division":
						v_division = JsonString.s(this.m());
						hasv_division = true;
						break
					case "email":
						v_email = (this.E("null")) ? null : JsonString.s(this.m());
						break
					case "gender":
						var v = JsonString.s(this.m());
						switch (v) {
							case "female":case "male":case "unspecified":
								v_gender = v;
								break
							default:
							throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer12().wb(["male", "female", "unspecified"]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 469, "className": "tink.json.Parser20", "methodName": "process0"}));
							
						};
						hasv_gender = true;
						break
					case "id":
						v_id = JsonString.s(this.m());
						hasv_id = true;
						break
					case "name":
						v_name = JsonString.s(this.m());
						hasv_name = true;
						break
					case "phone":
						v_phone = (this.E("null")) ? null : JsonString.s(this.m());
						break
					case "project":
						v_project = JsonString.s(this.m());
						hasv_project = true;
						break
					case "registrationNumber":
						v_registrationNumber = JsonString.s(this.m());
						hasv_registrationNumber = true;
						break
					case "remarks":
						v_remarks = JsonString.s(this.m());
						hasv_remarks = true;
						break
					case "trade":
						v_trade = JsonString.s(this.m());
						hasv_trade = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"age": v_age, "company": (hasv_company) ? v_company : __missing__("company"), "dateOfBirth": v_dateOfBirth, "division": (hasv_division) ? v_division : __missing__("division"), "email": v_email, "gender": (hasv_gender) ? v_gender : __missing__("gender"), "id": (hasv_id) ? v_id : __missing__("id"), "name": (hasv_name) ? v_name : __missing__("name"), "phone": v_phone, "project": (hasv_project) ? v_project : __missing__("project"), "registrationNumber": (hasv_registrationNumber) ? v_registrationNumber : __missing__("registrationNumber"), "remarks": (hasv_remarks) ? v_remarks : __missing__("remarks"), "trade": (hasv_trade) ? v_trade : __missing__("trade")};
	}
	pc(source) {
		if (this.e.length > 0) {
			this.e = [];
		};
		this.g(source);
		this.F("[");
		var __ret = [];
		if (!this.E("]")) {
			while (true) {
				__ret.push(this.pb());
				if (!this.E(",")) {
					break;
				};
			};
			this.F("]");
		};
		var ret = __ret;
		var _g = 0;
		var _g1 = this.e;
		while (_g < _g1.length) _g1[_g++]();
		if (this.e.length > 0) {
			this.e = [];
		};
		return ret;
	}
	pd(source) {
		var _gthis = this;
		return TypedError.catchExceptions(function () {
			var ret = _gthis.pc(source);
			_gthis.h();
			if (_gthis.c < _gthis.d) {
				_gthis.D("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser20", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser20"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser20
	}
}

