import {AutoObservable, Computation} from "../../tink/state/internal/AutoObservable.js"
import {State} from "../../tink/state/State.js"
import {Observable} from "../../tink/state/Observable.js"
import {Callback} from "../../tink/core/Callback.js"
import React from "react"
import {Component} from "react"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const ViewBase = Register.global("$hxClasses")["coconut.react.ViewBase"] = 
class ViewBase extends Register.inherits(Component) {
	new(rendered, mounted, updated, unmounting) {
		Component.call(this);
		this.state = {"revision": 0};
		this.__rendered = rendered;
		this.__viewMounted = mounted;
		this.__viewUpdated = updated;
		this.__viewUnmounting = unmounting;
	}
	componentDidMount() {
		if (this.__viewMounted != null) {
			this.__viewMounted();
		};
	}
	componentDidUpdate(_, _1) {
		if (this.__viewUpdated != null) {
			this.__viewUpdated();
		};
	}
	componentWillUnmount() {
		var _g = this.__binding;
		if (_g != null) {
			this.__binding = null;
			_g.destroy();
		};
		if (this.__viewUnmounting != null) {
			this.__viewUnmounting();
		};
	}
	__getRender() {
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = this.__rendered.getValue();
		AutoObservable.cur = before;
		return ret;
	}
	shouldComponentUpdate(_, _1) {
		return this.__last != this.__getRender();
	}
	render() {
		if (this.__binding == null) {
			this.__binding = new Binding(this);
		};
		var _g = this.__last = this.__getRender();
		if (typeof(_g) == "undefined") {
			return null;
		} else {
			return _g;
		};
	}
	static get __name__() {
		return "coconut.react.ViewBase"
	}
	static get __super__() {
		return Component
	}
	get __class__() {
		return ViewBase
	}
}


;Object.defineProperty(ViewBase.prototype, "props", {"get": function () {
	return this.__props;
}, "set": function (attr) {
	if (attr != null) {
		this.__props = attr;
		if (this.__initAttributes) {
			this.__initAttributes(attr);
		};
	};
}})

export const View = Register.global("$hxClasses")["coconut.react.View"] = 
class View extends Register.inherits(ViewBase) {
	new(render, shouldUpdate, track, beforeRerender, rendered) {
		this.__au = [];
		this.__bc = [];
		this.__bu = [];
		var _gthis = this;
		var mounted;
		if (rendered != null) {
			var _g = rendered;
			var a1 = true;
			mounted = function () {
				_g(a1);
			};
		} else {
			mounted = null;
		};
		var updated;
		if (rendered != null) {
			var _g1 = rendered;
			var a11 = false;
			updated = function () {
				_g1(a11);
			};
		} else {
			updated = null;
		};
		var firstTime = true;
		var last = null;
		var hasBeforeRerender = beforeRerender != null;
		var hasUpdated = updated != null;
		var _coco_revision = State._new(0);
		var lastRev = State.get_value(_coco_revision);
		super.new(new AutoObservable(Computation.sync(function () {
			var curRev = State.get_value(_coco_revision);
			if (track != null) {
				track();
			};
			if (firstTime) {
				firstTime = false;
			} else {
				if (curRev == lastRev && shouldUpdate != null && !shouldUpdate()) {
					return last;
				};
				var hasCallbacks = _gthis.__bc.length > 0;
				if (hasBeforeRerender || hasCallbacks) {
					var before = AutoObservable.cur;
					AutoObservable.cur = null;
					if (hasBeforeRerender) {
						beforeRerender();
					};
					if (hasCallbacks) {
						var _g = 0;
						var _g1 = _gthis.__bc.splice(0, _gthis.__bc.length);
						while (_g < _g1.length) Callback.invoke(_g1[_g++], false);
					};
					AutoObservable.cur = before;
				};
			};
			lastRev = curRev;
			last = render();
			return last;
		}), null), mounted, function () {
			var hasCallbacks = _gthis.__au.length > 0;
			if (hasUpdated || hasCallbacks) {
				var before = AutoObservable.cur;
				AutoObservable.cur = null;
				if (hasUpdated) {
					updated();
				};
				if (hasCallbacks) {
					var _g = 0;
					var _g1 = _gthis.__au.splice(0, _gthis.__au.length);
					while (_g < _g1.length) Callback.invoke(_g1[_g++], null);
				};
				AutoObservable.cur = before;
			};
		}, function () {
			last = null;
			firstTime = true;
			_gthis.__beforeUnmount();
		});
		this._coco_revision = _coco_revision;
	}
	__beforeUnmount() {
		var _g = 0;
		var _g1 = this.__bu.splice(0, this.__bu.length);
		while (_g < _g1.length) {
			var c = _g1[_g];
			++_g;
			if (c != null) {
				c.cancel();
			};
		};
		var _g = 0;
		var _g1 = this.__bc.splice(0, this.__bu.length);
		while (_g < _g1.length) Callback.invoke(_g1[_g++], true);
	}
	static createFragment(attr, children) {
		var tmp = [React.Fragment, attr].concat(children);
		return React.createElement.apply(null, tmp);
	}
	static get __name__() {
		return "coconut.react.View"
	}
	static get __super__() {
		return ViewBase
	}
	get __class__() {
		return View
	}
}


View.TRE = (typeof Symbol === "function" && Symbol.for && Symbol.for("react.element")) || 0xeac7
export const Binding = Register.global("$hxClasses")["coconut.react._View.Binding"] = 
class Binding extends Register.inherits() {
	new(target) {
		this.target = target;
		var first = true;
		this.link = Observable.bind(target.__rendered, function (_) {
			if (first) {
				first = false;
			} else {
				target.setState({"revision": target.state.revision + 1});
			};
		});
	}
	destroy() {
		var this1 = this.link;
		if (this1 != null) {
			this1.cancel();
		};
	}
	static get __name__() {
		return "coconut.react._View.Binding"
	}
	get __class__() {
		return Binding
	}
}

