import {Register} from "../../genes/Register.js"
import {StringTools} from "../../StringTools.js"

const $global = Register.$global

export const Config = Register.global("$hxClasses")["dasloop.util.Config"] = 
class Config {
	static get __name__() {
		return "dasloop.util.Config"
	}
	get __class__() {
		return Config
	}
}


Config.ENVIRONMENT = "production"
Config.WEBSOCKET_SCHEME = (Config.ENVIRONMENT == "local") ? "ws" : "wss"
Config.HTTP_SCHEME = (Config.ENVIRONMENT == "local") ? "http" : "https"
Config.API_SERVER_HOSTNAME = (function($this) {var $r0
	var _this_r = new RegExp("(superadmin|my|dashboard)", "".split("u").join(""));
	
	$r0 = window.location.hostname.replace(_this_r, "api")
	return $r0})(this)
Config.IMAGE_PORT = (Config.ENVIRONMENT == "local") ? 80 : 443
Config.IMAGE_HOSTNAME = StringTools.replace(window.location.hostname, "dashboard", "image")
Config.IMAGE_URL = "" + Config.HTTP_SCHEME + "://" + Config.IMAGE_HOSTNAME + ":" + Config.IMAGE_PORT
Config.API_SERVER_PORT = (Config.ENVIRONMENT == "local") ? 80 : 443
Config.API_SERVER_PATH = "/v2"
Config.API_SERVER_URL = "" + Config.HTTP_SCHEME + "://" + Config.API_SERVER_HOSTNAME + ":" + Config.API_SERVER_PORT + Config.API_SERVER_PATH
Config.MQTT_WEBSOCKET_HOSTNAME = (function($this) {var $r0
	var _this_r = new RegExp("(superadmin|my|dashboard)", "".split("u").join(""));
	
	$r0 = window.location.hostname.replace(_this_r, "mqtt")
	return $r0})(this)
Config.MQTT_WEBSOCKET_PORT = (Config.ENVIRONMENT == "local") ? 80 : 443
Config.MQTT_WEBSOCKET_PATH = "/mqtt"
Config.MQTT_WEBSOCKET_URL = "" + Config.WEBSOCKET_SCHEME + "://" + Config.MQTT_WEBSOCKET_HOSTNAME + ":" + Config.MQTT_WEBSOCKET_PORT + Config.MQTT_WEBSOCKET_PATH
Config.KEYCLOAK_FRONTEND_HOSTNAME = (function($this) {var $r0
	var _this_r = new RegExp("(superadmin|my|dashboard)", "".split("u").join(""));
	
	$r0 = window.location.hostname.replace(_this_r, "auth")
	return $r0})(this)
Config.KEYCLOAK_FRONTEND_URL = "" + Config.HTTP_SCHEME + "://" + Config.KEYCLOAK_FRONTEND_HOSTNAME + "/auth"
Config.KEYCLOAK_REALM = "dasloop"
Config.KEYCLOAK_CLIENT_ID = "web"
Config.DASIOT_LIVECALL_SERVER_URL = "https://livecall.core.dasiot.site"