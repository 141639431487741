import {Outcome} from "../../tink/core/Outcome.js"
import {TypedError} from "../../tink/core/Error.js"
import {Register} from "../../genes/Register.js"
import {HxOverrides} from "../../HxOverrides.js"
import {EReg} from "../../EReg.js"

const $global = Register.$global

export const DeviceId = Register.global("$hxClasses")["dasloop.types._DeviceId.DeviceId"] = 
class DeviceId {
	static get_deviceType(this1) {
		var _g = HxOverrides.cca(this1, 0);
		var _g1 = HxOverrides.cca(this1, 1);
		var _g2 = HxOverrides.cca(this1, 2);
		if (_g == null) {
			var v = _g;
			return DeviceType.Unknown(String.fromCodePoint(v));
		} else {
			switch (_g) {
				case 65:
					if (_g2 == null) {
						var v = _g;
						return DeviceType.Unknown(String.fromCodePoint(v));
					} else {
						switch (_g2) {
							case 66:
								return DeviceType.DasaoaLocator(DasaoaLocatorType.Bluetooth);
								break
							case 85:
								return DeviceType.DasaoaLocator(DasaoaLocatorType.Uwb);
								break
							default:
							var v = _g;
							return DeviceType.Unknown(String.fromCodePoint(v));
							
						};
					};
					break
				case 66:
					return DeviceType.Dasbeacon;
					break
				case 69:
					if (_g1 == null) {
						var v = _g;
						return DeviceType.Unknown(String.fromCodePoint(v));
					} else if (_g1 == 66) {
						if (_g2 == null) {
							var v = _g;
							return DeviceType.Unknown(String.fromCodePoint(v));
						} else {
							switch (_g2) {
								case 80:
									return DeviceType.Daspower;
									break
								case 84:
									return DeviceType.Dastemp;
									break
								default:
								var v = _g;
								return DeviceType.Unknown(String.fromCodePoint(v));
								
							};
						};
					} else {
						var v = _g;
						return DeviceType.Unknown(String.fromCodePoint(v));
					};
					break
				case 76:
					return DeviceType.Dasloop;
					break
				case 80:
					return DeviceType.Dastrack;
					break
				case 84:
					return DeviceType.DasaoaTag;
					break
				default:
				var v = _g;
				return DeviceType.Unknown(String.fromCodePoint(v));
				
			};
		};
	}
	static get_connectionType(this1) {
		var _g = HxOverrides.cca(this1, 1);
		if (_g == null) {
			return ConnectionType.Unknown;
		} else {
			switch (_g) {
				case 66:
					return ConnectionType.Bluetooth;
					break
				case 76:
					return ConnectionType.Lora;
					break
				case 78:
					return ConnectionType.Nbiot;
					break
				case 83:
					return ConnectionType.Sigfox;
					break
				case 85:
					return ConnectionType.Uwb;
					break
				case 87:
					return ConnectionType.Wifi;
					break
				default:
				return ConnectionType.Unknown;
				
			};
		};
	}
	static get_model(this1) {
		var _g = HxOverrides.cca(this1, 0);
		var _g1 = HxOverrides.cca(this1, 2);
		if (_g1 == null) {
			return DeviceModel.Unknown;
		} else {
			switch (_g1) {
				case 66:
					if (_g != 65) {
						return DeviceModel.Basic;
					} else {
						return DeviceModel.Unknown;
					};
					break
				case 86:
					if (_g == null) {
						return DeviceModel.Unknown;
					} else if (_g == 76) {
						return DeviceModel.Camera;
					} else {
						return DeviceModel.Unknown;
					};
					break
				default:
				return DeviceModel.Unknown;
				
			};
		};
	}
	static get_language(this1) {
		var _g = HxOverrides.cca(this1, 3);
		if (_g == null) {
			return DeviceLanguage.Unknown;
		} else {
			switch (_g) {
				case 67:
					return DeviceLanguage.Cantonese;
					break
				case 74:
					return DeviceLanguage.Japanese;
					break
				case 77:
					return DeviceLanguage.Mandarin;
					break
				default:
				return DeviceLanguage.Unknown;
				
			};
		};
	}
	static parse(v) {
		if (!new EReg("^[A-Z]{7}\\d{6}$", "").match(v)) {
			return Outcome.Failure(new TypedError(null, "Invalid DeviceId format: \"" + v + "\"", {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/dasloop-types/0.0.0/git/02a6ec86579e2d19b4682cdd0da6ae12d74e26e2/src/dasloop/types/DeviceId.hx", "lineNumber": 127, "className": "dasloop.types._DeviceId.DeviceId_Impl_", "methodName": "parse"}));
		};
		return Outcome.Success(v);
	}
	static get __name__() {
		return "dasloop.types._DeviceId.DeviceId_Impl_"
	}
	get __class__() {
		return DeviceId
	}
}


export const DeviceType = 
Register.global("$hxEnums")["dasloop.types.DeviceType"] = 
{
	__ename__: "dasloop.types.DeviceType",
	
	Dasloop: {_hx_name: "Dasloop", _hx_index: 0, __enum__: "dasloop.types.DeviceType"},
	Dastrack: {_hx_name: "Dastrack", _hx_index: 1, __enum__: "dasloop.types.DeviceType"},
	Daspower: {_hx_name: "Daspower", _hx_index: 2, __enum__: "dasloop.types.DeviceType"},
	Dasbeacon: {_hx_name: "Dasbeacon", _hx_index: 3, __enum__: "dasloop.types.DeviceType"},
	Dastemp: {_hx_name: "Dastemp", _hx_index: 4, __enum__: "dasloop.types.DeviceType"},
	DasaoaTag: {_hx_name: "DasaoaTag", _hx_index: 5, __enum__: "dasloop.types.DeviceType"},
	DasaoaLocator: Object.assign((sub) => ({_hx_index: 6, __enum__: "dasloop.types.DeviceType", "sub": sub}), {_hx_name: "DasaoaLocator", __params__: ["sub"]}),
	Unknown: Object.assign((v) => ({_hx_index: 7, __enum__: "dasloop.types.DeviceType", "v": v}), {_hx_name: "Unknown", __params__: ["v"]})
}
DeviceType.__constructs__ = [DeviceType.Dasloop, DeviceType.Dastrack, DeviceType.Daspower, DeviceType.Dasbeacon, DeviceType.Dastemp, DeviceType.DasaoaTag, DeviceType.DasaoaLocator, DeviceType.Unknown]
DeviceType.__empty_constructs__ = [DeviceType.Dasloop, DeviceType.Dastrack, DeviceType.Daspower, DeviceType.Dasbeacon, DeviceType.Dastemp, DeviceType.DasaoaTag]

export const DasaoaLocatorType = 
Register.global("$hxEnums")["dasloop.types.DasaoaLocatorType"] = 
{
	__ename__: "dasloop.types.DasaoaLocatorType",
	
	Bluetooth: {_hx_name: "Bluetooth", _hx_index: 0, __enum__: "dasloop.types.DasaoaLocatorType"},
	Uwb: {_hx_name: "Uwb", _hx_index: 1, __enum__: "dasloop.types.DasaoaLocatorType"}
}
DasaoaLocatorType.__constructs__ = [DasaoaLocatorType.Bluetooth, DasaoaLocatorType.Uwb]
DasaoaLocatorType.__empty_constructs__ = [DasaoaLocatorType.Bluetooth, DasaoaLocatorType.Uwb]

export const ConnectionType = 
Register.global("$hxEnums")["dasloop.types.ConnectionType"] = 
{
	__ename__: "dasloop.types.ConnectionType",
	
	Wifi: {_hx_name: "Wifi", _hx_index: 0, __enum__: "dasloop.types.ConnectionType"},
	Nbiot: {_hx_name: "Nbiot", _hx_index: 1, __enum__: "dasloop.types.ConnectionType"},
	Sigfox: {_hx_name: "Sigfox", _hx_index: 2, __enum__: "dasloop.types.ConnectionType"},
	Bluetooth: {_hx_name: "Bluetooth", _hx_index: 3, __enum__: "dasloop.types.ConnectionType"},
	Lora: {_hx_name: "Lora", _hx_index: 4, __enum__: "dasloop.types.ConnectionType"},
	Uwb: {_hx_name: "Uwb", _hx_index: 5, __enum__: "dasloop.types.ConnectionType"},
	Unknown: {_hx_name: "Unknown", _hx_index: 6, __enum__: "dasloop.types.ConnectionType"}
}
ConnectionType.__constructs__ = [ConnectionType.Wifi, ConnectionType.Nbiot, ConnectionType.Sigfox, ConnectionType.Bluetooth, ConnectionType.Lora, ConnectionType.Uwb, ConnectionType.Unknown]
ConnectionType.__empty_constructs__ = [ConnectionType.Wifi, ConnectionType.Nbiot, ConnectionType.Sigfox, ConnectionType.Bluetooth, ConnectionType.Lora, ConnectionType.Uwb, ConnectionType.Unknown]

export const DeviceModel = 
Register.global("$hxEnums")["dasloop.types.DeviceModel"] = 
{
	__ename__: "dasloop.types.DeviceModel",
	
	Basic: {_hx_name: "Basic", _hx_index: 0, __enum__: "dasloop.types.DeviceModel"},
	Camera: {_hx_name: "Camera", _hx_index: 1, __enum__: "dasloop.types.DeviceModel"},
	Unknown: {_hx_name: "Unknown", _hx_index: 2, __enum__: "dasloop.types.DeviceModel"}
}
DeviceModel.__constructs__ = [DeviceModel.Basic, DeviceModel.Camera, DeviceModel.Unknown]
DeviceModel.__empty_constructs__ = [DeviceModel.Basic, DeviceModel.Camera, DeviceModel.Unknown]

export const DeviceLanguage = 
Register.global("$hxEnums")["dasloop.types.DeviceLanguage"] = 
{
	__ename__: "dasloop.types.DeviceLanguage",
	
	Mandarin: {_hx_name: "Mandarin", _hx_index: 0, __enum__: "dasloop.types.DeviceLanguage"},
	Cantonese: {_hx_name: "Cantonese", _hx_index: 1, __enum__: "dasloop.types.DeviceLanguage"},
	Japanese: {_hx_name: "Japanese", _hx_index: 2, __enum__: "dasloop.types.DeviceLanguage"},
	Unknown: {_hx_name: "Unknown", _hx_index: 3, __enum__: "dasloop.types.DeviceLanguage"}
}
DeviceLanguage.__constructs__ = [DeviceLanguage.Mandarin, DeviceLanguage.Cantonese, DeviceLanguage.Japanese, DeviceLanguage.Unknown]
DeviceLanguage.__empty_constructs__ = [DeviceLanguage.Mandarin, DeviceLanguage.Cantonese, DeviceLanguage.Japanese, DeviceLanguage.Unknown]
