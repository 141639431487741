import {BasicParser, JsonString} from "./Parser.js"
import {OutcomeTools} from "../core/Outcome.js"
import {TypedError} from "../core/Error.js"
import {Exception} from "../../haxe/Exception.js"
import {Register} from "../../genes/Register.js"
import {LocationSource} from "../../dasloop/types/LocationSource.js"
import {DeviceId} from "../../dasloop/types/DeviceId.js"
import {Std} from "../../Std.js"

const $global = Register.$global

export const Parser30 = Register.global("$hxClasses")["tink.json.Parser30"] = 
class Parser30 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	pb() {
		var _gthis = this;
		var v_date = null;
		var hasv_date = false;
		var v_device = null;
		var hasv_device = false;
		var v_id = null;
		var hasv_id = false;
		var v_point = null;
		var hasv_point = false;
		var v_project = null;
		var v_source = null;
		var hasv_source = false;
		var v_worker = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "date":
						v_date = new Date(JsonString.f(this.r()));
						hasv_date = true;
						break
					case "device":
						v_device = OutcomeTools.sure(DeviceId.parse(JsonString.s(this.m())));
						hasv_device = true;
						break
					case "id":
						v_id = JsonString.s(this.m());
						hasv_id = true;
						break
					case "point":
						this.F("[");
						var __ret = [];
						if (!this.E("]")) {
							while (true) {
								__ret.push(JsonString.f(this.r()));
								if (!this.E(",")) {
									break;
								};
							};
							this.F("]");
						};
						v_point = __ret;
						hasv_point = true;
						break
					case "project":
						v_project = (this.E("null")) ? null : JsonString.s(this.m());
						break
					case "source":
						v_source = this.pc();
						hasv_source = true;
						break
					case "worker":
						v_worker = (this.E("null")) ? null : JsonString.s(this.m());
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"date": (hasv_date) ? v_date : __missing__("date"), "device": (hasv_device) ? v_device : __missing__("device"), "id": (hasv_id) ? v_id : __missing__("id"), "point": (hasv_point) ? v_point : __missing__("point"), "project": v_project, "source": (hasv_source) ? v_source : __missing__("source"), "worker": v_worker};
	}
	pc() {
		var __ret = this.pd();
		var _g = __ret.fix;
		var _g1 = __ret.satellites;
		var _g2 = __ret.speed;
		var _g3 = __ret.strength;
		var _g4 = __ret.type;
		if (_g4 == null) {
			throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser30", "methodName": "process1"}));
		} else {
			switch (_g4) {
				case "aoa-ble":
					return LocationSource.AoaBle;
					break
				case "beacon":
					return LocationSource.Beacon;
					break
				case "gps":
					return LocationSource.Gps(_g, _g1, _g3, _g2);
					break
				default:
				throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser30", "methodName": "process1"}));
				
			};
		};
	}
	pd() {
		var _gthis = this;
		var v_fix = null;
		var v_satellites = null;
		var v_speed = null;
		var v_strength = null;
		var v_type = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "fix":
						v_fix = (this.E("null")) ? null : JsonString.i(this.r());
						break
					case "satellites":
						v_satellites = (this.E("null")) ? null : JsonString.i(this.r());
						break
					case "speed":
						v_speed = (this.E("null")) ? null : JsonString.i(this.r());
						break
					case "strength":
						v_strength = (this.E("null")) ? null : JsonString.i(this.r());
						break
					case "type":
						v_type = (this.E("null")) ? null : JsonString.s(this.m());
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {"fix": v_fix, "satellites": v_satellites, "speed": v_speed, "strength": v_strength, "type": v_type};
	}
	pe(source) {
		if (this.e.length > 0) {
			this.e = [];
		};
		this.g(source);
		this.F("[");
		var __ret = [];
		if (!this.E("]")) {
			while (true) {
				__ret.push(this.pb());
				if (!this.E(",")) {
					break;
				};
			};
			this.F("]");
		};
		var ret = __ret;
		var _g = 0;
		var _g1 = this.e;
		while (_g < _g1.length) _g1[_g++]();
		if (this.e.length > 0) {
			this.e = [];
		};
		return ret;
	}
	pf(source) {
		var _gthis = this;
		return TypedError.catchExceptions(function () {
			var ret = _gthis.pe(source);
			_gthis.h();
			if (_gthis.c < _gthis.d) {
				_gthis.D("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser30", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser30"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser30
	}
}

