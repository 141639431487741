import {FluentLocaleBase} from "./FluentProvider.js"
import {Register} from "../../genes/Register.js"
import {NavBarLocalizer} from "../../dasloop/app/localizer/NavBarLocalizer.js"

const $global = Register.$global

export const FluentLocale1 = Register.global("$hxClasses")["turnwing.provider.FluentLocale1"] = 
class FluentLocale1 extends Register.inherits(FluentLocaleBase) {
	new(__bundle__, __prefix__) {
		super.new(__bundle__, __prefix__);
	}
	summary() {
		return this.__exec__("summary", {});
	}
	loading() {
		return this.__exec__("loading", {});
	}
	static get __name__() {
		return "turnwing.provider.FluentLocale1"
	}
	static get __interfaces__() {
		return [NavBarLocalizer]
	}
	static get __super__() {
		return FluentLocaleBase
	}
	get __class__() {
		return FluentLocale1
	}
}

