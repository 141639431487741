import {DecoderBase} from "./Decoder.js"
import {Writer106} from "../json/Writer106.js"
import {Writer105} from "../json/Writer105.js"
import {TypedError} from "../core/Error.js"
import {Exception} from "../../haxe/Exception.js"
import {Register} from "../../genes/Register.js"
import {DeviceUpdate} from "../../dasloop/types/pubsub/DeviceUpdate.js"
import {LocationSource} from "../../dasloop/types/LocationSource.js"
import {GasReading} from "../../dasloop/types/GasReading.js"
import {AlertValue} from "../../dasloop/types/AlertValue.js"

const $global = Register.$global

export const Decoder1 = Register.global("$hxClasses")["tink.serialize.Decoder1"] = 
class Decoder1 extends Register.inherits(DecoderBase) {
	new() {
		super.new();
	}
	process0() {
		switch (this.dynInt()) {
			case 0:
				var tmp = new Date(this["float"]());
				var tmp1 = this.string();
				var _g = [];
				var _g1 = 0;
				var _g2 = this.dynInt();
				while (_g1 < _g2) {
					++_g1;
					_g.push(this["float"]());
				};
				return DeviceUpdate.Location({"date": tmp, "device": tmp1, "point": _g, "source": this.process1()});
				break
			case 1:
				return DeviceUpdate.Alert({"date": new Date(this["float"]()), "device": this.string(), "value": this.process2()});
				break
			case 2:
				var tmp = new Date(this["float"]());
				var tmp1 = this.string();
				var v = this.string();
				var tmp2;
				switch (v) {
					case "altitude":case "ambient_temperature":case "atmospheric_pressure":case "battery":case "battery_current":case "battery_voltage":case "body_temperature":case "gas_concentration_co":case "gas_concentration_co2":case "gas_concentration_h2s":case "gas_concentration_hc":case "gas_concentration_o2":case "gas_detector_status":case "general_report_interval":case "gps_altitude":case "gsm_level":case "heart_rate":case "heart_rate_quality":case "humidity":case "in_recess_area":case "in_sleep_area":case "live_call_room":case "lpwan_signal_strength":case "lpwan_signal_strength_rssi":case "lpwan_signal_strength_sni":case "module_recover_count":case "network_disconnect_count":case "network_report_cell_id":case "network_report_earfcn":case "network_report_lpwan_signal_strength":case "network_report_mccmnc":case "network_report_nw_provided_edrx":case "network_report_paging_time_window":case "network_report_psm_active_time":case "network_report_psm_periodic_tau":case "network_report_requested_edrx":case "network_report_status":case "network_report_tracking_area_code":case "network_report_wifi_ip_address":case "network_report_wifi_signal_strength":case "ping":case "raw_ambient_temperature_from_humidity_sensor":case "raw_ambient_temperature_from_infrared_sensor":case "raw_ambient_temperature_from_pressure_sensor":case "recess_area_detection_enabled":case "server_disconnect_count":case "short_report_period":case "shutdown":case "sleep_area_detection_enabled":case "special_area_detection_enabled":case "spo2_oxygen_level":case "standstill_token_count":case "standstill_token_duration":case "stationary_time":case "surface_temperature_from_infrared_sensor":case "uwb_detector_operating":case "uwb_detector_powered":case "wearing":case "wearing_time":case "wifi_signal_strength":
						tmp2 = v;
						break
					default:
					throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer106().wb(["altitude", "ambient_temperature", "atmospheric_pressure", "battery", "battery_current", "body_temperature", "general_report_interval", "short_report_period", "gps_altitude", "gsm_level", "heart_rate", "heart_rate_quality", "humidity", "in_recess_area", "in_sleep_area", "lpwan_signal_strength", "lpwan_signal_strength_rssi", "lpwan_signal_strength_sni", "module_recover_count", "network_disconnect_count", "server_disconnect_count", "raw_ambient_temperature_from_humidity_sensor", "raw_ambient_temperature_from_infrared_sensor", "raw_ambient_temperature_from_pressure_sensor", "recess_area_detection_enabled", "shutdown", "sleep_area_detection_enabled", "special_area_detection_enabled", "spo2_oxygen_level", "standstill_token_count", "standstill_token_duration", "stationary_time", "surface_temperature_from_infrared_sensor", "uwb_detector_operating", "uwb_detector_powered", "wearing", "wearing_time", "wifi_signal_strength", "network_report_status", "network_report_mccmnc", "network_report_tracking_area_code", "network_report_cell_id", "network_report_psm_active_time", "network_report_psm_periodic_tau", "network_report_requested_edrx", "network_report_nw_provided_edrx", "network_report_paging_time_window", "network_report_lpwan_signal_strength", "network_report_wifi_signal_strength", "network_report_wifi_ip_address", "network_report_earfcn", "battery_voltage", "live_call_room", "gas_concentration_hc", "gas_concentration_o2", "gas_concentration_h2s", "gas_concentration_co", "gas_concentration_co2", "gas_detector_status", "ping"]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_serialize/0.0.0-alpha.1/github/6e78bfe3e63be4afe907389bdd9954f3af111d41/src/tink/serialize/Decoder.macro.hx", "lineNumber": 126, "className": "tink.serialize.Decoder1", "methodName": "process0"}));
					
				};
				return DeviceUpdate.Reading({"date": tmp, "device": tmp1, "type": tmp2, "value": this["float"]()});
				break
			default:
			throw Exception.thrown("assert");
			
		};
	}
	process1() {
		switch (this.dynInt()) {
			case 0:
				return LocationSource.Gps((this.src.bytes[this.pos] == 255 && ++this.pos > 0) ? null : this.dynInt(), (this.src.bytes[this.pos] == 255 && ++this.pos > 0) ? null : this.dynInt(), (this.src.bytes[this.pos] == 255 && ++this.pos > 0) ? null : this.dynInt(), (this.src.bytes[this.pos] == 255 && ++this.pos > 0) ? null : this.dynInt());
				break
			case 1:
				return LocationSource.Beacon;
				break
			case 2:
				return LocationSource.AoaBle;
				break
			default:
			throw Exception.thrown("assert");
			
		};
	}
	process2() {
		switch (this.dynInt()) {
			case 0:
				var v = this.dynInt();
				var tmp;
				switch (v) {
					case 1:case 2:case 3:case 4:case 5:case 6:case 7:case 8:case 9:case 10:case 11:case 12:case 13:case 14:case 15:case 16:case 17:case 18:case 19:
						tmp = v;
						break
					default:
					throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer105().wb([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_serialize/0.0.0-alpha.1/github/6e78bfe3e63be4afe907389bdd9954f3af111d41/src/tink/serialize/Decoder.macro.hx", "lineNumber": 126, "className": "tink.serialize.Decoder1", "methodName": "process2"}));
					
				};
				return AlertValue.Legacy(tmp);
				break
			case 1:
				return AlertValue.PanicButton;
				break
			case 2:
				return AlertValue.FreeFall({"accelerationThreshold": this.dynInt(), "actualX": this.dynInt(), "actualY": this.dynInt(), "actualZ": this.dynInt(), "durationThreshold": this["float"]()});
				break
			case 3:
				return AlertValue.BodyTempTooHigh({"actual": this["float"](), "threshold": this["float"]()});
				break
			case 4:
				return AlertValue.BodyTempTooLow({"actual": this["float"](), "threshold": this["float"]()});
				break
			case 5:
				return AlertValue.HeartRateTooHigh({"actual": this.dynInt(), "quality": (this.src.bytes[this.pos] == 255 && ++this.pos > 0) ? null : this.dynInt(), "threshold": this.dynInt()});
				break
			case 6:
				return AlertValue.HeartRateTooLow({"actual": this.dynInt(), "quality": (this.src.bytes[this.pos] == 255 && ++this.pos > 0) ? null : this.dynInt(), "threshold": this.dynInt()});
				break
			case 7:
				return AlertValue.HeatStress({"actual": this.dynInt(), "threshold": this.dynInt()});
				break
			case 8:
				return AlertValue.Standstill({"threshold": this.dynInt()});
				break
			case 9:
				return AlertValue.RestrictedArea({"area": this.string()});
				break
			case 10:
				var v = this.dynInt();
				var tmp;
				switch (v) {
					case 0:case 1:
						tmp = v;
						break
					default:
					throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer105().wb([0, 1]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_serialize/0.0.0-alpha.1/github/6e78bfe3e63be4afe907389bdd9954f3af111d41/src/tink/serialize/Decoder.macro.hx", "lineNumber": 126, "className": "tink.serialize.Decoder1", "methodName": "process2"}));
					
				};
				return AlertValue.UwbEnter({"device": tmp, "distance": this.dynInt(), "id": this.dynInt(), "serialNumber": (this.src.bytes[this.pos] == 255 && ++this.pos > 0) ? null : this.string()});
				break
			case 11:
				var v = this.dynInt();
				var tmp;
				switch (v) {
					case 0:case 1:
						tmp = v;
						break
					default:
					throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer105().wb([0, 1]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_serialize/0.0.0-alpha.1/github/6e78bfe3e63be4afe907389bdd9954f3af111d41/src/tink/serialize/Decoder.macro.hx", "lineNumber": 126, "className": "tink.serialize.Decoder1", "methodName": "process2"}));
					
				};
				return AlertValue.UwbExit({"device": tmp, "distance": this.dynInt(), "id": this.dynInt(), "serialNumber": (this.src.bytes[this.pos] == 255 && ++this.pos > 0) ? null : this.string()});
				break
			case 12:
				return AlertValue.RegularSignalMissing({"shutdownReason": (this.src.bytes[this.pos] == 255 && ++this.pos > 0) ? null : this.dynInt(), "timeout": this.dynInt()});
				break
			case 13:
				return AlertValue.CurrentTooHigh({"actual": this["float"](), "threshold": this["float"]()});
				break
			case 14:
				return AlertValue.CurrentTooLow({"actual": this["float"](), "threshold": this["float"]()});
				break
			case 15:
				return AlertValue.TemperatureTooHigh({"actual": this["float"](), "threshold": this["float"]()});
				break
			case 16:
				return AlertValue.TemperatureTooLow({"actual": this["float"](), "threshold": this["float"]()});
				break
			case 17:
				return AlertValue.GasConcentrationAbnormal(this.process3());
				break
			case 18:
				return AlertValue.UwbEnterAuthMuteZone(this.process5());
				break
			case 19:
				return AlertValue.UwbExitAuthMuteZone(this.process5());
				break
			default:
			throw Exception.thrown("assert");
			
		};
	}
	process3() {
		return {"alarmStatus": this.dynInt(), "reading": this.process4()};
	}
	process4() {
		switch (this.dynInt()) {
			case 0:
				return GasReading.HC(this["float"]());
				break
			case 1:
				return GasReading.O2(this["float"]());
				break
			case 2:
				return GasReading.H2S(this["float"]());
				break
			case 3:
				return GasReading.CO(this["float"]());
				break
			case 4:
				return GasReading.CO2(this["float"]());
				break
			default:
			throw Exception.thrown("assert");
			
		};
	}
	process5() {
		var v = this.dynInt();
		var tmp;
		switch (v) {
			case 0:case 1:
				tmp = v;
				break
			default:
			throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer105().wb([0, 1]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_serialize/0.0.0-alpha.1/github/6e78bfe3e63be4afe907389bdd9954f3af111d41/src/tink/serialize/Decoder.macro.hx", "lineNumber": 126, "className": "tink.serialize.Decoder1", "methodName": "process5"}));
			
		};
		return {"device": tmp, "distance": this.dynInt(), "id": this.dynInt(), "serialNumber": (this.src.bytes[this.pos] == 255 && ++this.pos > 0) ? null : this.string()};
	}
	decode(data) {
		this.reset(data);
		var _g = [];
		var _g1 = 0;
		var _g2 = this.dynInt();
		while (_g1 < _g2) {
			++_g1;
			_g.push(this.process0());
		};
		return _g;
	}
	tryDecode(data) {
		var _gthis = this;
		return TypedError.catchExceptions(function () {
			return _gthis.decode(data);
		}, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_serialize/0.0.0-alpha.1/github/6e78bfe3e63be4afe907389bdd9954f3af111d41/src/tink/serialize/Decoder.macro.hx", "lineNumber": 182, "className": "tink.serialize.Decoder1", "methodName": "tryDecode"});
	}
	static get __name__() {
		return "tink.serialize.Decoder1"
	}
	static get __super__() {
		return DecoderBase
	}
	get __class__() {
		return Decoder1
	}
}

