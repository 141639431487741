import {RemoteBase, RemoteEndpoint, ResponseReader} from "./Remote.js"
import {Single} from "../../streams/Stream.js"
import {Parser47} from "../../json/Parser47.js"
import {HeaderField, HeaderValue} from "../../http/Header.js"
import {LazyConst} from "../../core/Lazy.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote62 = Register.global("$hxClasses")["tink.web.proxy.Remote62"] = 
class Remote62 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	get() {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [], "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pKb))(new Parser47())));
	}
	static get __name__() {
		return "tink.web.proxy.Remote62"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote62
	}
}

