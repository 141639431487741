import {Promise as Promise__1} from "../core/Promise.js"
import {Outcome} from "../core/Outcome.js"
import {LazyConst, Lazy} from "../core/Lazy.js"
import {SyncFuture} from "../core/Future.js"
import {Option} from "../../haxe/ds/Option.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const PromisedWith = 
Register.global("$hxEnums")["tink.state.PromisedWith"] = 
{
	__ename__: "tink.state.PromisedWith",
	
	Loading: {_hx_name: "Loading", _hx_index: 0, __enum__: "tink.state.PromisedWith"},
	Done: Object.assign((result) => ({_hx_index: 1, __enum__: "tink.state.PromisedWith", "result": result}), {_hx_name: "Done", __params__: ["result"]}),
	Failed: Object.assign((error) => ({_hx_index: 2, __enum__: "tink.state.PromisedWith", "error": error}), {_hx_name: "Failed", __params__: ["error"]})
}
PromisedWith.__constructs__ = [PromisedWith.Loading, PromisedWith.Done, PromisedWith.Failed]
PromisedWith.__empty_constructs__ = [PromisedWith.Loading]

export const PromisedTools = Register.global("$hxClasses")["tink.state.PromisedTools"] = 
class PromisedTools {
	static next(a, f) {
		switch (a._hx_index) {
			case 0:
				return Promise__1.fromNever(Promise__1.NEVER);
				break
			case 1:
				return f(a.result);
				break
			case 2:
				return new SyncFuture(new LazyConst(Outcome.Failure(a.error)));
				break
			
		};
	}
	static map(a, f) {
		switch (a._hx_index) {
			case 0:
				return PromisedWith.Loading;
				break
			case 1:
				return PromisedWith.Done(f(a.result));
				break
			case 2:
				return PromisedWith.Failed(a.error);
				break
			
		};
	}
	static flatMap(a, f) {
		switch (a._hx_index) {
			case 0:
				return PromisedWith.Loading;
				break
			case 1:
				return f(a.result);
				break
			case 2:
				return PromisedWith.Failed(a.error);
				break
			
		};
	}
	static toOption(p) {
		if (p._hx_index == 1) {
			return Option.Some(p.result);
		} else {
			return Option.None;
		};
	}
	static or(p, l) {
		if (p._hx_index == 1) {
			return p.result;
		} else {
			return Lazy.get(l);
		};
	}
	static orNull(p) {
		if (p._hx_index == 1) {
			return p.result;
		} else {
			return null;
		};
	}
	static all(p) {
		var ret = [];
		var p1 = Register.iter(p);
		while (p1.hasNext()) {
			var p = p1.next();
			switch (p._hx_index) {
				case 0:
					return PromisedWith.Loading;
					break
				case 1:
					ret.push(p.result);
					break
				case 2:
					return PromisedWith.Failed(p.error);
					break
				
			};
		};
		return PromisedWith.Done(ret);
	}
	static merge(a, b, combine) {
		switch (a._hx_index) {
			case 1:
				var _g = a.result;
				switch (b._hx_index) {
					case 1:
						return PromisedWith.Done(combine(_g, b.result));
						break
					case 2:
						return PromisedWith.Failed(b.error);
						break
					default:
					return PromisedWith.Loading;
					
				};
				break
			case 2:
				return PromisedWith.Failed(a.error);
				break
			default:
			if (b._hx_index == 2) {
				return PromisedWith.Failed(b.error);
			} else {
				return PromisedWith.Loading;
			};
			
		};
	}
	static get __name__() {
		return "tink.state.PromisedTools"
	}
	get __class__() {
		return PromisedTools
	}
}

