import {Register} from "../../../genes/Register.js"
import {StringTools} from "../../../StringTools.js"
import {HxOverrides} from "../../../HxOverrides.js"

const $global = Register.$global

export const FloatTools = Register.global("$hxClasses")["dasloop.types.util.FloatTools"] = 
class FloatTools {
	static roundTo(value, dp) {
		var multiplier = Math.pow(10, dp);
		return Math.round(value * multiplier) / multiplier;
	}
	static format(v, dp) {
		var multiplier = Math.pow(10, dp);
		var v1 = Math.round(v * multiplier) / multiplier;
		var i = v1 | 0;
		return "" + i + "." + HxOverrides.substr(StringTools.lpad("" + ((v1 - i) * multiplier | 0), "0", dp), 0, dp);
	}
	static get __name__() {
		return "dasloop.types.util.FloatTools"
	}
	get __class__() {
		return FloatTools
	}
}

