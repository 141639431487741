import {Portion} from "./Portion.js"
import {NamedWith} from "../core/Named.js"
import {StringMap} from "../../haxe/ds/StringMap.js"
import {EsMap} from "../../genes/util/EsMap.js"
import {Register} from "../../genes/Register.js"
import {HxOverrides} from "../../HxOverrides.js"

const $global = Register.$global

export const QueryStringParser = Register.global("$hxClasses")["tink.url._Query.QueryStringParser"] = 
class QueryStringParser extends Register.inherits() {
	new(s, sep, set, pos) {
		this.s = (s == null) ? "" : s;
		this.sep = sep;
		this.set = set;
		this.pos = pos;
	}
	hasNext() {
		return this.pos < this.s.length;
	}
	next() {
		var next = this.s.indexOf(this.sep, this.pos);
		if (next == -1) {
			next = this.s.length;
		};
		var split = this.s.indexOf(this.set, this.pos);
		var start = this.pos;
		this.pos = next + this.sep.length;
		if (split == -1 || split > next) {
			return new NamedWith(Portion.toString(QueryStringParser.trimmedSub(this.s, start, next)), Portion.ofString(""));
		} else {
			return new NamedWith(Portion.toString(QueryStringParser.trimmedSub(this.s, start, split)), QueryStringParser.trimmedSub(this.s, split + this.set.length, next));
		};
	}
	static trimmedSub(s, start, end) {
		if (start >= s.length) {
			return "";
		};
		while (s.charCodeAt(start) < 33) ++start;
		if (end < s.length - 1) {
			while (s.charCodeAt(end - 1) < 33) --end;
		};
		return s.substring(start, end);
	}
	static get __name__() {
		return "tink.url._Query.QueryStringParser"
	}
	get __class__() {
		return QueryStringParser
	}
}


export const Query = Register.global("$hxClasses")["tink.url._Query.Query"] = 
class Query {
	static with(this1, values) {
		var ret = [];
		var _g = [];
		var key = EsMap.adaptIterator(values.inst.keys());
		while (key.hasNext()) _g.push(key.next());
		var p = new QueryStringParser(this1, "&", "=", 0);
		while (p.hasNext()) {
			var p1 = p.next();
			var key = Portion.ofString(p1.name);
			if (values.inst.has(key)) {
				var name = Portion.ofString(p1.name);
				var key1 = Portion.ofString(p1.name);
				ret.push(name + "=" + values.inst.get(key1));
				HxOverrides.remove(_g, Portion.ofString(p1.name));
			} else {
				ret.push(Portion.ofString(p1.name) + "=" + p1.value);
			};
		};
		var _g1 = 0;
		while (_g1 < _g.length) {
			var name = _g[_g1];
			++_g1;
			ret.push(name + "=" + values.inst.get(name));
		};
		return ret.join("&");
	}
	static toMap(this1) {
		var _g = new StringMap();
		var p = new QueryStringParser(this1, "&", "=", 0);
		while (p.hasNext()) {
			var p1 = p.next();
			_g.inst.set(p1.name.toString(), p1.value);
		};
		return _g;
	}
	static get __name__() {
		return "tink.url._Query.Query_Impl_"
	}
	get __class__() {
		return Query
	}
}

