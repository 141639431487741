import {Register} from "./genes/Register.js"
import {Declarations} from "./cix/css/Declarations.js"

const $global = Register.$global

export const Cixe9079fd8879254bae04e00e7590f62bf53e34c3e71adc93feea855bbdd4cef2c = Register.global("$hxClasses")["Cixe9079fd8879254bae04e00e7590f62bf53e34c3e71adc93feea855bbdd4cef2c"] = 
class Cixe9079fd8879254bae04e00e7590f62bf53e34c3e71adc93feea855bbdd4cef2c extends Register.inherits() {
	new() {
		this.css = Declarations.add("cxqc", function () {
			return ".cxqc {\n  display: flex;\n  flex: 1;\n}";
		});
	}
	static get __name__() {
		return "Cixe9079fd8879254bae04e00e7590f62bf53e34c3e71adc93feea855bbdd4cef2c"
	}
	get __class__() {
		return Cixe9079fd8879254bae04e00e7590f62bf53e34c3e71adc93feea855bbdd4cef2c
	}
}


Cixe9079fd8879254bae04e00e7590f62bf53e34c3e71adc93feea855bbdd4cef2c.inst = new Cixe9079fd8879254bae04e00e7590f62bf53e34c3e71adc93feea855bbdd4cef2c()