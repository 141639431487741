import {Register} from "../genes/Register.js"
import {Reflect as Reflect__1} from "../Reflect.js"

const $global = Register.$global

export const Anon = Register.global("$hxClasses")["tink.Anon"] = 
class Anon {
	static getExistentFields(o) {
		var ret = {};
		var _g = 0;
		var _g1 = Reflect__1.fields(o);
		while (_g < _g1.length) ret[_g1[_g++]] = true;
		return ret;
	}
	static get __name__() {
		return "tink.Anon"
	}
	get __class__() {
		return Anon
	}
}

