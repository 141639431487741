import {ObjectMap} from "../../../haxe/ds/ObjectMap.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Annex = Register.global("$hxClasses")["coconut.data.helpers.Annex"] = 
class Annex extends Register.inherits() {
	new(target) {
		this.target = target;
		this.registry = new ObjectMap();
	}
	static get __name__() {
		return "coconut.data.helpers.Annex"
	}
	get __class__() {
		return Annex
	}
}

