import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {PromisedTools} from "../../../tink/state/Promised.js"
import {Observable, ConstObservable} from "../../../tink/state/Observable.js"
import {BlobSource} from "../../../tink/io/js/BlobSource.js"
import {RealSourceTools, Source} from "../../../tink/io/Source.js"
import {FetchResponse, Fetch} from "../../../tink/http/Fetch.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Promise as Promise__1} from "../../../tink/core/Promise.js"
import {Outcome} from "../../../tink/core/Outcome.js"
import {LazyConst, Lazy} from "../../../tink/core/Lazy.js"
import {SyncFuture, Future} from "../../../tink/core/Future.js"
import {TypedError} from "../../../tink/core/Error.js"
import {Callback} from "../../../tink/core/Callback.js"
import {Option} from "../../../haxe/ds/Option.js"
import {Register} from "../../../genes/Register.js"
import {Config} from "../../core/Config.js"
import {Subscriber} from "../remote/Subscriber.js"
import {WorkersData} from "./WorkersData.js"
import {WorkergroupsData} from "./WorkergroupsData.js"
import {TaskgroupsData} from "./TaskgroupsData.js"
import {StatisticsData} from "./StatisticsData.js"
import {ProjectLogsData} from "./ProjectLogsData.js"
import {PlantsData} from "./PlantsData.js"
import {MembersData} from "./MembersData.js"
import {FloorPlansData} from "./FloorPlansData.js"
import {EquipmentsData} from "./EquipmentsData.js"
import {EquipmentgroupsData} from "./EquipmentgroupsData.js"
import {DevicesData} from "./DevicesData.js"
import {DasiotsData} from "./DasiotsData.js"
import {ContactsData} from "./ContactsData.js"
import {CctvsData} from "./CctvsData.js"
import {BimsData} from "./BimsData.js"
import {BeaconsData} from "./BeaconsData.js"
import {AudiosData} from "./AudiosData.js"
import {AreasData} from "./AreasData.js"
import {AoaLocatorsData} from "./AoaLocatorsData.js"
import {VectorMigrationTools} from "../../VectorMigrationTools.js"
import {Updatable} from "../../../coconut/ds/Updatable.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"
import {Lambda} from "../../../Lambda.js"

const $global = Register.$global

export const ProjectData = Register.global("$hxClasses")["dasloop.app.data.ProjectData"] = 
class ProjectData extends Register.inherits() {
	new(__coco_init) {
		this._updatePerformed = Signal.trigger();
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.async(function () {
			return Promise__1.next(_gthis.remote.projects().ofId(_gthis.id).role(), function (o) {
				return new SyncFuture(new LazyConst(Outcome.Success(o.role)));
			});
		}), null);
		AutoObservable.cur = before;
		this.__coco_role = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return "" + Config.API_SERVER_URL + "/v2/projects/" + _gthis.id + "/avatar?v=" + State.get_value(_gthis.__coco_imageRevision);
		}), null);
		AutoObservable.cur = before;
		this.__coco_imageUrl = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return PromisedTools.or(PromisedTools.map(Observable.get_value(_gthis.__coco_data), function (v) {
				return v.heartbeatTimeout;
			}), new LazyConst({"normal": 0, "sleep": 0}));
		}), null);
		AutoObservable.cur = before;
		this.__coco_heartbeatTimeout = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return Observable.get_value(_gthis._data.__coco_data);
		}), null);
		AutoObservable.cur = before;
		this.__coco_data = ret;
		this.__coco_now = __coco_init.now;
		this.id = __coco_init.id;
		this.remote = __coco_init.remote;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new ProjectFeaturesData({"id": _gthis.id, "remote": _gthis.remote});
		AutoObservable.cur = before;
		this.features = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new ProjectPermissionsData({"id": _gthis.id, "remote": _gthis.remote});
		AutoObservable.cur = before;
		this.permissions = ret;
		var _g = __coco_init.initial;
		this.initial = (_g == null) ? Option.None : _g;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new Updatable({"loader": function () {
			return _gthis.remote.v2().projects().ofId(_gthis.id).getExtended();
		}, "updater": function (current, type) {
			return Promise__1.next(_gthis.remote.v2().projects().ofId(_gthis.id).update(type.patch), function (info) {
				var __o1 = current;
				return new SyncFuture(new LazyConst(Outcome.Success({"timezone": info.timezone, "startDate": info.startDate, "region": info.region, "number": info.number, "name": info.name, "id": info.id, "endDate": info.endDate, "contractor": info.contractor, "client": info.client, "boundary": info.boundary, "archived": info.archived, "ignoreAlerts": __o1.ignoreAlerts, "heartbeatTimeout": __o1.heartbeatTimeout, "gridSize": __o1.gridSize, "expiry": __o1.expiry, "elevation": __o1.elevation, "cameraPosition": __o1.cameraPosition})));
			});
		}, "cache": _gthis.initial});
		AutoObservable.cur = before;
		this._data = ret;
		this.__coco_imageRevision = State._new(new Date().getTime(), null, null, null);
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new MembersData({"project": _gthis.id, "remote": _gthis.remote});
		AutoObservable.cur = before;
		this.members = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new ContactsData({"api": _gthis.remote.v2().projects().ofId(_gthis.id).contacts()});
		AutoObservable.cur = before;
		this.contacts = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new PlantsData({"project": _gthis.id, "remote": _gthis.remote});
		AutoObservable.cur = before;
		this.plants = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AoaLocatorsData({"project": _gthis.id, "remote": _gthis.remote});
		AutoObservable.cur = before;
		this.aoaLocators = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new CctvsData({"project": _gthis.id, "remote": _gthis.remote});
		AutoObservable.cur = before;
		this.cctvs = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new EquipmentsData({"project": _gthis.id, "remote": _gthis.remote});
		AutoObservable.cur = before;
		this.equipments = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new EquipmentgroupsData({"project": _gthis.id, "remote": _gthis.remote});
		AutoObservable.cur = before;
		this.equipmentgroups = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new WorkersData({"project": _gthis.id, "remote": _gthis.remote});
		AutoObservable.cur = before;
		this.workers = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new WorkergroupsData({"project": _gthis.id, "remote": _gthis.remote});
		AutoObservable.cur = before;
		this.workergroups = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new DasiotsData({"project": _gthis.id, "remote": _gthis.remote});
		AutoObservable.cur = before;
		this.dasiots = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new DevicesData({"project": _gthis.id, "plants": _gthis.plants, "workers": _gthis.workers, "equipments": _gthis.equipments, "remote": _gthis.remote, "now": new AutoObservable(Computation.sync(function () {
			return Observable.get_value(_gthis.__coco_now);
		}), null), "heartbeatTimeout": new AutoObservable(Computation.sync(function () {
			return Observable.get_value(_gthis.__coco_heartbeatTimeout);
		}), null), "dasiots": _gthis.dasiots});
		AutoObservable.cur = before;
		this.devices = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AudiosData({"project": _gthis.id, "remote": _gthis.remote});
		AutoObservable.cur = before;
		this.audios = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AreasData({"project": _gthis.id, "remote": _gthis.remote});
		AutoObservable.cur = before;
		this.areas = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new BeaconsData({"project": _gthis.id, "remote": _gthis.remote});
		AutoObservable.cur = before;
		this.beacons = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new FloorPlansData({"project": _gthis.id, "remote": _gthis.remote});
		AutoObservable.cur = before;
		this.floorPlans = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new BimsData({"project": _gthis.id, "remote": _gthis.remote});
		AutoObservable.cur = before;
		this.bims = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new TaskgroupsData({"project": _gthis.id, "remote": _gthis.remote, "pubsub": Lazy.get(Subscriber.sub)});
		AutoObservable.cur = before;
		this.taskgroups = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new ProjectLogsData({"project": _gthis.id, "remote": _gthis.remote});
		AutoObservable.cur = before;
		this.logs = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new StatisticsData({"project": _gthis.id, "remote": _gthis.remote});
		AutoObservable.cur = before;
		this.statistics = ret;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"id": new ConstObservable(this.id), "remote": new ConstObservable(this.remote), "now": this.__coco_now, "features": new ConstObservable(this.features), "permissions": new ConstObservable(this.permissions), "initial": new ConstObservable(this.initial), "_data": new ConstObservable(this._data), "data": this.__coco_data, "heartbeatTimeout": this.__coco_heartbeatTimeout, "imageUrl": this.__coco_imageUrl, "role": this.__coco_role, "members": new ConstObservable(this.members), "contacts": new ConstObservable(this.contacts), "plants": new ConstObservable(this.plants), "aoaLocators": new ConstObservable(this.aoaLocators), "cctvs": new ConstObservable(this.cctvs), "equipments": new ConstObservable(this.equipments), "equipmentgroups": new ConstObservable(this.equipmentgroups), "workers": new ConstObservable(this.workers), "workergroups": new ConstObservable(this.workergroups), "dasiots": new ConstObservable(this.dasiots), "devices": new ConstObservable(this.devices), "audios": new ConstObservable(this.audios), "areas": new ConstObservable(this.areas), "beacons": new ConstObservable(this.beacons), "floorPlans": new ConstObservable(this.floorPlans), "bims": new ConstObservable(this.bims), "taskgroups": new ConstObservable(this.taskgroups), "logs": new ConstObservable(this.logs), "statistics": new ConstObservable(this.statistics), "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	updateAvatar(file) {
		var _gthis = this;
		var c = function (_) {
			_gthis._data.refresh(null);
			_gthis.set_imageRevision(new Date().getTime());
		};
		var c1 = function (o) {
			if (o._hx_index == 0) {
				Callback.invoke(c, o.data);
			};
		};
		return Future.map(Promise__1.noise(Promise__1.next(this.remote.v2().projects().ofId(this.id).updateAvatar(file.type), function (header) {
			var url = header.url;
			var header1 = header.method;
			var _g = [];
			var _this = header.fields;
			var _g1_current = 0;
			while (_g1_current < _this.length) _g.push(_this[_g1_current++]);
			return FetchResponse.all(Fetch.fetch(url, {"method": header1, "headers": _g, "body": RealSourceTools.idealize(new BlobSource(file.name, file, 0, 16777216), function (_) {
				return Source.EMPTY;
			})}));
		})), function (v) {
			Callback.invoke(c1, v);
			return v;
		});
	}
	createTasks(data) {
		return Promise__1.next(this.remote.v2().projects().ofId(this.id).tasks().create(data), function (tasks) {
			var _g = VectorMigrationTools.first(tasks);
			switch (_g._hx_index) {
				case 0:
					return new SyncFuture(new LazyConst(Outcome.Success(_g.v)));
					break
				case 1:
					return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(null, "No tasks created", {"fileName": "src/dasloop/app/data/ProjectData.hx", "lineNumber": 99, "className": "dasloop.app.data.ProjectData", "methodName": "createTasks"}))));
					break
				
			};
		});
	}
	getWeather(language) {
		var _gthis = this;
		return PromisedTools.next(Observable.get_value(this.__coco_data), function (v) {
			return _gthis.remote.v2().weather().get(v.region, language);
		});
	}
	getNews(language) {
		var _gthis = this;
		return PromisedTools.next(Observable.get_value(this.__coco_data), function (v) {
			return _gthis.remote.v2().news().get(v.region, language);
		});
	}
	set_imageRevision(param) {
		this._updatePerformed.handlers.invoke({"imageRevision": param});
		this.__coco_imageRevision.set(param);
		return param;
	}
	static get __name__() {
		return "dasloop.app.data.ProjectData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return ProjectData
	}
}


export const ProjectUpdateType = 
Register.global("$hxEnums")["dasloop.app.data.ProjectUpdateType"] = 
{
	__ename__: "dasloop.app.data.ProjectUpdateType",
	
	Data: Object.assign((patch) => ({_hx_index: 0, __enum__: "dasloop.app.data.ProjectUpdateType", "patch": patch}), {_hx_name: "Data", __params__: ["patch"]})
}
ProjectUpdateType.__constructs__ = [ProjectUpdateType.Data]
ProjectUpdateType.__empty_constructs__ = []

export const ProjectPermissionsData = Register.global("$hxClasses")["dasloop.app.data.ProjectPermissionsData"] = 
class ProjectPermissionsData extends Register.inherits() {
	new(__coco_init) {
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.async(function () {
			return _gthis.remote.v2().me().permissions(_gthis.id);
		}), null);
		AutoObservable.cur = before;
		this.__coco_permissions = ret;
		this.id = __coco_init.id;
		this.remote = __coco_init.remote;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"id": new ConstObservable(this.id), "remote": new ConstObservable(this.remote), "permissions": this.__coco_permissions, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	can(action, resource) {
		return PromisedTools.map(Observable.get_value(this.__coco_permissions), function (mapping) {
			var _g = mapping.inst.get(resource);
			if (_g == null) {
				return false;
			} else {
				return Lambda.exists(_g, function (v) {
					return v == action;
				});
			};
		});
	}
	static get __name__() {
		return "dasloop.app.data.ProjectPermissionsData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return ProjectPermissionsData
	}
}


export const ProjectFeaturesData = Register.global("$hxClasses")["dasloop.app.data.ProjectFeaturesData"] = 
class ProjectFeaturesData extends Register.inherits() {
	new(__coco_init) {
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.async(function () {
			return _gthis.remote.v2().projects().ofId(_gthis.id).features().list();
		}), null);
		AutoObservable.cur = before;
		this.__coco_features = ret;
		this.id = __coco_init.id;
		this.remote = __coco_init.remote;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"id": new ConstObservable(this.id), "remote": new ConstObservable(this.remote), "features": this.__coco_features, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	has(feature) {
		return PromisedTools.map(Observable.get_value(this.__coco_features), function (list) {
			return Lambda.exists(list, function (v) {
				return v.feature == feature;
			});
		});
	}
	static get __name__() {
		return "dasloop.app.data.ProjectFeaturesData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return ProjectFeaturesData
	}
}

