import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {PromisedTools, PromisedWith} from "../../../tink/state/Promised.js"
import {Observable, ConstObservable} from "../../../tink/state/Observable.js"
import {Vectorlike} from "../../../tink/pure/Vector.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Promise as Promise__1} from "../../../tink/core/Promise.js"
import {MPair} from "../../../tink/core/Pair.js"
import {Outcome, OutcomeTools} from "../../../tink/core/Outcome.js"
import {LazyConst, Lazy} from "../../../tink/core/Lazy.js"
import {SyncFuture, Future} from "../../../tink/core/Future.js"
import {Callback} from "../../../tink/core/Callback.js"
import {StringMap} from "../../../haxe/ds/StringMap.js"
import {Option} from "../../../haxe/ds/Option.js"
import {EsMap} from "../../../genes/util/EsMap.js"
import {Register} from "../../../genes/Register.js"
import {DoubleRangeModel} from "../../ui/map/widget/DoubleRangeSelector.js"
import {DeviceId, DeviceType, DeviceModel} from "../../types/DeviceId.js"
import {AlertValueTools} from "../../types/AlertValue.js"
import {DeviceLiveCallData} from "./DeviceLiveCallData.js"
import {VectorMigrationTools} from "../../VectorMigrationTools.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"
import {Reflect as Reflect__1} from "../../../Reflect.js"
import {Lambda} from "../../../Lambda.js"

const $global = Register.$global

export const Location = Register.global("$hxClasses")["dasloop.app.data._DeviceData.Location"] = 
class Location {
	static eq(this1, that) {
		if (this1.date.getTime() == that.date.getTime()) {
			return this1.source._hx_index == that.source._hx_index;
		} else {
			return false;
		};
	}
	static isReliable(this1) {
		var _g = this1.source;
		switch (_g._hx_index) {
			case 0:
				return _g.satellites > 3;
				break
			case 1:case 2:
				return true;
				break
			
		};
	}
	static to3D(this1, altitude) {
		var this2 = this1.point;
		return {"date": this1.date, "source": this1.source, "point": [this2[0], this2[1], altitude]};
	}
	static get __name__() {
		return "dasloop.app.data._DeviceData.Location_Impl_"
	}
	get __class__() {
		return Location
	}
}


export const Alert = Register.global("$hxClasses")["dasloop.app.data._DeviceData.Alert"] = 
class Alert {
	static eq(this1, that) {
		if (this1.date.getTime() == that.date.getTime()) {
			return this1.value._hx_index == that.value._hx_index;
		} else {
			return false;
		};
	}
	static get __name__() {
		return "dasloop.app.data._DeviceData.Alert_Impl_"
	}
	get __class__() {
		return Alert
	}
}


export const PushedData = Register.global("$hxClasses")["dasloop.app.data._DeviceData.PushedData"] = 
class PushedData extends Register.inherits() {
	new(init) {
		this._updatePerformed = Signal.trigger();
		var _gthis = this;
		this.__coco_locations = State._new([], null, null, null);
		this.__coco_alerts = State._new([], null, null, null);
		this.__coco_readings = State._new(new StringMap(), null, null, null);
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"locations": this.__coco_locations, "alerts": this.__coco_alerts, "readings": this.__coco_readings, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		init.push.listen(function (message) {
			switch (message._hx_index) {
				case 0:
					var _hx_tmp;
					_hx_tmp = message.location;
					var pushed = _hx_tmp;
					if (!Lambda.exists(State.get_value(_gthis.__coco_locations), function (v) {
						return Location.eq(v, pushed);
					}) && Location.isReliable(pushed)) {
						_gthis.set_locations(Vectorlike.ofSingle(pushed).concat(State.get_value(_gthis.__coco_locations)));
					};
					if (State.get_value(_gthis.__coco_locations).length > 300) {
						_gthis.set_locations(State.get_value(_gthis.__coco_locations).slice().slice(State.get_value(_gthis.__coco_locations).length - 300, State.get_value(_gthis.__coco_locations).length).slice());
					};
					break
				case 1:
					var _g = message.alert;
					var _hx_tmp;
					_hx_tmp = {"dismissed": false, "ignored": false, "value": _g.value, "date": _g.date};
					var pushed1 = _hx_tmp;
					if (!Lambda.exists(State.get_value(_gthis.__coco_alerts), function (v) {
						return Alert.eq(v, pushed1);
					})) {
						_gthis.set_alerts(Vectorlike.ofSingle(pushed1).concat(State.get_value(_gthis.__coco_alerts)));
					};
					break
				case 2:
					var _g = message.reading;
					var type = _g.type;
					var current = State.get_value(_gthis.__coco_readings).inst.get(type);
					if (current == null || current.date.getTime() < _g.date.getTime()) {
						var _gthis1 = _gthis;
						var this1 = State.get_value(_gthis.__coco_readings);
						var copied = new StringMap();
						copied.inst = new Map(this1.inst);
						var ret = copied;
						ret.inst.set(type, _g);
						_gthis1.set_readings(ret);
					};
					break
				
			};
		});
		AutoObservable.cur = before;
	}
	set_locations(param) {
		this._updatePerformed.handlers.invoke({"locations": param});
		this.__coco_locations.set(param);
		return param;
	}
	set_alerts(param) {
		this._updatePerformed.handlers.invoke({"alerts": param});
		this.__coco_alerts.set(param);
		return param;
	}
	set_readings(param) {
		this._updatePerformed.handlers.invoke({"readings": param});
		this.__coco_readings.set(param);
		return param;
	}
	static get __name__() {
		return "dasloop.app.data._DeviceData.PushedData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return PushedData
	}
}


export const Batcher = Register.global("$hxClasses")["dasloop.app.data._DeviceData.Batcher"] = 
class Batcher extends Register.inherits() {
	new(project, remote) {
		this.cachedAlerts = Promise__1.cache(function () {
			return Promise__1.next(remote.v2().projects().ofId(project).alerts().list({}), function (list) {
				return new SyncFuture(new LazyConst(Outcome.Success(new MPair(list, Future.delay(500, new LazyConst(null))))));
			});
		});
		this.cachedLocations = Promise__1.cache(function () {
			return Promise__1.next(remote.v2().projects().ofId(project).locations().listPerDevice(10, {}), function (list) {
				return new SyncFuture(new LazyConst(Outcome.Success(new MPair(list, Future.delay(500, new LazyConst(null))))));
			});
		});
		this.cachedReadings = Promise__1.cache(function () {
			return Promise__1.next(remote.v2().projects().ofId(project).readings().list({}), function (list) {
				return new SyncFuture(new LazyConst(Outcome.Success(new MPair(list, Future.delay(500, new LazyConst(null))))));
			});
		});
	}
	alerts(device) {
		return Promise__1.next(this.cachedAlerts(), function (list) {
			var _g = [];
			var _g1 = 0;
			var _g2 = list;
			while (_g1 < _g2.length) {
				var v = _g2[_g1];
				++_g1;
				if (v.device == device) {
					_g.push(v);
				};
			};
			return new SyncFuture(new LazyConst(Outcome.Success(_g)));
		});
	}
	locations(device) {
		return Promise__1.next(this.cachedLocations(), function (list) {
			var _g = [];
			var _g1 = 0;
			var _g2 = list;
			while (_g1 < _g2.length) {
				var v = _g2[_g1];
				++_g1;
				if (v.device == device) {
					_g.push(v);
				};
			};
			return new SyncFuture(new LazyConst(Outcome.Success(_g)));
		});
	}
	readings(device) {
		return Promise__1.next(this.cachedReadings(), function (list) {
			var _g = [];
			var _g1 = 0;
			var _g2 = list;
			while (_g1 < _g2.length) {
				var v = _g2[_g1];
				++_g1;
				if (v.device == device) {
					_g.push(v);
				};
			};
			return new SyncFuture(new LazyConst(Outcome.Success(_g)));
		});
	}
	static get(projectId, remote) {
		var _g = Batcher.map.inst.get(projectId);
		if (_g == null) {
			var v = new Batcher(projectId, remote);
			Batcher.map.inst.set(projectId, v);
			return v;
		} else {
			return _g;
		};
	}
	static get __name__() {
		return "dasloop.app.data._DeviceData.Batcher"
	}
	get __class__() {
		return Batcher
	}
}


Batcher.map = new StringMap()
export const PulledData = Register.global("$hxClasses")["dasloop.app.data._DeviceData.PulledData"] = 
class PulledData extends Register.inherits() {
	new(__coco_init) {
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.async(function () {
			return Promise__1.next(Batcher.get(_gthis.project, _gthis.remote).readings(_gthis.id), function (list) {
				var _g = new StringMap();
				var _g1 = 0;
				var _g2 = list;
				while (_g1 < _g2.length) {
					var v = _g2[_g1];
					++_g1;
					_g.inst.set(v.type, v);
				};
				return new SyncFuture(new LazyConst(Outcome.Success(_g)));
			});
		}), null);
		AutoObservable.cur = before;
		this.__coco_readings = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.async(function () {
			return Promise__1.next(Batcher.get(_gthis.project, _gthis.remote).locations(_gthis.id), function (list) {
				var result = new Array(list.length);
				var _g = 0;
				var _g1 = list.length;
				while (_g < _g1) {
					var i = _g++;
					var __o0 = list[i];
					result[i] = {"source": __o0.source, "point": __o0.point, "date": __o0.date};
				};
				var _g = [];
				var _g1 = 0;
				while (_g1 < result.length) {
					var v = result[_g1];
					++_g1;
					if (Location.isReliable(v)) {
						_g.push(v);
					};
				};
				return new SyncFuture(new LazyConst(Outcome.Success(_g)));
			});
		}), null);
		AutoObservable.cur = before;
		this.__coco_locations = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.async(function () {
			return Promise__1.next(Batcher.get(_gthis.project, _gthis.remote).alerts(_gthis.id), function (list) {
				var result = new Array(list.length);
				var _g = 0;
				var _g1 = list.length;
				while (_g < _g1) {
					var i = _g++;
					var __o0 = list[i];
					result[i] = {"value": __o0.value, "ignored": __o0.ignored, "dismissed": __o0.dismissed, "date": __o0.date};
				};
				return new SyncFuture(new LazyConst(Outcome.Success(result)));
			});
		}), null);
		AutoObservable.cur = before;
		this.__coco_alerts = ret;
		this.id = __coco_init.id;
		this.project = __coco_init.project;
		this.remote = __coco_init.remote;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"id": new ConstObservable(this.id), "project": new ConstObservable(this.project), "remote": new ConstObservable(this.remote), "alerts": this.__coco_alerts, "locations": this.__coco_locations, "readings": this.__coco_readings, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	static get __name__() {
		return "dasloop.app.data._DeviceData.PulledData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return PulledData
	}
}


export const TripData = Register.global("$hxClasses")["dasloop.app.data.TripData"] = 
class TripData extends Register.inherits() {
	new(init) {
		var _gthis = this;
		var __coco_init_range = new DoubleRangeModel();
		var __coco_init_id = init.id;
		var __coco_init_remote = init.remote;
		var __coco_init_pushed = init.pushed;
		var __coco_init_pulled = init.pulled;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			var _g = Observable.get_value(_gthis.range.outer.__coco_output);
			switch (_g._hx_index) {
				case 0:
					var _g1 = _g.data;
					var min = _g1.from.getTime();
					var max = _g1.to.getTime();
					var _g = [];
					var _g1 = 0;
					var _g2 = Observable.get_value(_gthis.__coco_locations);
					while (_g1 < _g2.length) {
						var loc = _g2[_g1];
						++_g1;
						var time = loc.date.getTime();
						if (time >= min && time <= max) {
							_g.push(loc);
						};
					};
					_g.sort(function (l1, l2) {
						return Reflect__1.compare(l1.date, l2.date);
					});
					var result = new Array(_g.length);
					var _g1 = 0;
					var _g2 = _g.length;
					while (_g1 < _g2) {
						var i = _g1++;
						var l = _g[i];
						result[i] = {"date": l.date, "point": l.point};
					};
					return result.slice();
					break
				case 1:
					return [];
					break
				
			};
		}), null);
		AutoObservable.cur = before;
		this.__coco_waypoints = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return DeviceData.locationFilterer(PromisedTools.or(Observable.get_value(_gthis.pulled.__coco_locations), new LazyConst([])).concat(State.get_value(_gthis.pushed.__coco_locations)).concat(PromisedTools.or(Observable.get_value(_gthis.__coco__locations), new LazyConst([]))).slice()).slice();
		}), null);
		AutoObservable.cur = before;
		this.__coco_locations = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.async(function () {
			return Promise__1.next(OutcomeTools.next(Observable.get_value(_gthis.range.outer.__coco_output), function (r) {
				return _gthis.remote.v2().devices().ofId(_gthis.id).locations({"from": r.from, "to": r.to, "limit": 2000});
			}), function (list) {
				var result = new Array(list.length);
				var _g = 0;
				var _g1 = list.length;
				while (_g < _g1) {
					var i = _g++;
					var __o0 = list[i];
					result[i] = {"source": __o0.source, "point": __o0.point, "date": __o0.date};
				};
				var _g = [];
				var _g1 = 0;
				while (_g1 < result.length) {
					var v = result[_g1];
					++_g1;
					if (Location.isReliable(v)) {
						_g.push(v);
					};
				};
				return new SyncFuture(new LazyConst(Outcome.Success(_g)));
			});
		}), null);
		AutoObservable.cur = before;
		this.__coco__locations = ret;
		this.range = __coco_init_range;
		this.id = __coco_init_id;
		this.remote = __coco_init_remote;
		this.pushed = __coco_init_pushed;
		this.pulled = __coco_init_pulled;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"range": new ConstObservable(this.range), "id": new ConstObservable(this.id), "remote": new ConstObservable(this.remote), "pushed": new ConstObservable(this.pushed), "pulled": new ConstObservable(this.pulled), "_locations": this.__coco__locations, "locations": this.__coco_locations, "waypoints": this.__coco_waypoints, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	static get __name__() {
		return "dasloop.app.data.TripData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return TripData
	}
}


export const DeviceData = Register.global("$hxClasses")["dasloop.app.data.DeviceData"] = 
class DeviceData extends Register.inherits() {
	new(init) {
		this._updatePerformed = Signal.trigger();
		var _gthis = this;
		var __coco_init_id = init.id;
		var __coco_init_project = init.project;
		var __coco_init_remote = init.remote;
		var __coco_init_heartbeatTimeout = init.heartbeatTimeout;
		var __coco_init_pushed = new PushedData({"push": init.push});
		var __coco_init_pulled = new PulledData({"id": init.id, "project": init.project, "remote": init.remote});
		var __coco_init_dasiots = init.dasiots;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var f = function (__tink_tmp2) {
			switch (DeviceId.get_deviceType(_gthis.id)._hx_index) {
				case 0:
					var _g = Observable.get_value(_gthis.__coco_readings).inst.get("battery");
					if (_g == null) {
						return Option.None;
					} else {
						return Option.Some(_g.value);
					};
					break
				case 2:case 4:
					var _g = Observable.get_value(_gthis.__coco_dasiotLatestInfo);
					if (_g == null) {
						if (__tink_tmp2._hx_index == 0) {
							return __tink_tmp2.v;
						} else {
							return Lazy.get(new LazyConst(Option.None));
						};
					} else if (_g.values.battery == null) {
						return Option.None;
					} else {
						return Option.Some(_g.values.battery);
					};
					break
				case 1:case 3:case 5:
					return Option.None;
					break
				case 6:
					return Option.None;
					break
				case 7:
					return Option.None;
					break
				
			};
		};
		var last = Option.None;
		var ret = new AutoObservable(function (_, _1) {
			var ret = f(last);
			last = Option.Some(ret);
			return ret;
		}, null);
		AutoObservable.cur = before;
		this.__coco_battery = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			var latest = null;
			var check = function (v) {
				var time = v.getTime();
				if (latest == null || time > latest) {
					latest = time;
				};
			};
			var reading = EsMap.adaptIterator(Observable.get_value(_gthis.__coco_readings).inst.values());
			while (reading.hasNext()) {
				var reading1 = reading.next();
				if (reading1.type == "shutdown") {
					check(reading1.date);
				};
			};
			if (latest == null) {
				return null;
			} else {
				return new Date(latest);
			};
		}), null);
		AutoObservable.cur = before;
		this.__coco_latestShutdownDate = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var f1 = function (__tink_tmp1) {
			switch (DeviceId.get_deviceType(_gthis.id)._hx_index) {
				case 2:case 4:
					var _g = Observable.get_value(_gthis.__coco_dasiotLatestInfo);
					if (_g == null) {
						return (__tink_tmp1._hx_index == 0) ? __tink_tmp1.v : null;
					} else {
						return _g.date;
					};
					break
				default:
				var latest = null;
				var check = function (v) {
					var time = v.getTime();
					if (latest == null || time > latest) {
						latest = time;
					};
				};
				var reading = EsMap.adaptIterator(Observable.get_value(_gthis.__coco_readings).inst.values());
				while (reading.hasNext()) {
					var reading1 = reading.next();
					if (reading1.type != "shutdown") {
						check(reading1.date);
					};
				};
				var _g = Observable.get_value(_gthis.__coco_latestLocation);
				if (_g != null) {
					check(_g.date);
				};
				if (latest == null) {
					return null;
				} else {
					return new Date(latest);
				};
				
			};
		};
		var last1 = Option.None;
		var ret = new AutoObservable(function (_, _1) {
			var ret = f1(last1);
			last1 = Option.Some(ret);
			return ret;
		}, null);
		AutoObservable.cur = before;
		this.__coco_latestUpdateDate = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			var latest = null;
			var check = function (v) {
				var time = v.getTime();
				if (latest == null || time > latest) {
					latest = time;
				};
			};
			var reading = EsMap.adaptIterator(Observable.get_value(_gthis.__coco_readings).inst.values());
			while (reading.hasNext()) check(reading.next().date);
			if (latest == null) {
				return null;
			} else {
				return new Date(latest);
			};
		}), null);
		AutoObservable.cur = before;
		this.__coco_latestReadingDate = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			if (Observable.get_value(_gthis.__coco_latestLocation) == null) {
				return null;
			} else {
				return Observable.get_value(_gthis.__coco_latestLocation).date;
			};
		}), null);
		AutoObservable.cur = before;
		this.__coco_latestLocationDate = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			if (Observable.get_value(_gthis.__coco_location) == null) {
				return null;
			} else {
				var compute = Observable.get_value(_gthis.__coco_location);
				var compute1;
				if (Observable.get_value(_gthis.__coco_location).source._hx_index == 0) {
					compute1 = 0;
				} else {
					var _g = Observable.get_value(_gthis.__coco_readings).inst.get("altitude");
					compute1 = (_g == null) ? 0 : _g.value;
				};
				return Location.to3D(compute, compute1);
			};
		}), null);
		AutoObservable.cur = before;
		this.__coco_location3D = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return Observable.get_value(_gthis.__coco_latestLocation);
		}), null);
		AutoObservable.cur = before;
		this.__coco_location = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			var _g = VectorMigrationTools.first(Observable.get_value(_gthis.__coco_locations), function (v) {
				switch (v.source._hx_index) {
					case 1:case 2:
						return true;
						break
					default:
					return false;
					
				};
			});
			var _g1 = VectorMigrationTools.first(Observable.get_value(_gthis.__coco_locations), function (v) {
				if (v.source._hx_index == 0) {
					return true;
				} else {
					return false;
				};
			});
			switch (_g._hx_index) {
				case 0:
					var _g2 = _g.v;
					switch (_g1._hx_index) {
						case 0:
							var _g = _g1.v;
							if (_g2.date.getTime() < _g.date.getTime() - 300000) {
								return _g;
							} else {
								return _g2;
							};
							break
						case 1:
							return _g2;
							break
						
					};
					break
				case 1:
					switch (_g1._hx_index) {
						case 0:
							return _g1.v;
							break
						case 1:
							return null;
							break
						
					};
					break
				
			};
		}), null);
		AutoObservable.cur = before;
		this.__coco_latestLocation = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			var pulledReadings = PromisedTools.or(Observable.get_value(_gthis.pulled.__coco_readings), new LazyConst(new StringMap()));
			var key = EsMap.adaptIterator(State.get_value(_gthis.pushed.__coco_readings).inst.keys());
			while (key.hasNext()) {
				var key1 = key.next();
				var pushedReading = State.get_value(_gthis.pushed.__coco_readings).inst.get(key1);
				var _g = pulledReadings.inst.get(key1);
				if ((_g == null) ? true : pushedReading.date.getTime() > _g.date.getTime()) {
					var copied = new StringMap();
					copied.inst = new Map(pulledReadings.inst);
					var ret = copied;
					ret.inst.set(key1, pushedReading);
					pulledReadings = ret;
				};
			};
			return pulledReadings;
		}), null);
		AutoObservable.cur = before;
		this.__coco_readings = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			var unique = DeviceData.deduplicate(PromisedTools.or(Observable.get_value(_gthis.pulled.__coco_alerts), new LazyConst([])).concat(State.get_value(_gthis.pushed.__coco_alerts)).slice(), function (v1, v2) {
				return Alert.eq(v1, v2);
			});
			unique.sort(function (v1, v2) {
				return Reflect__1.compare(v2.date, v1.date);
			});
			var _g = 0;
			var _g1 = State.get_value(_gthis.__coco_dismissedAlerts);
			while (_g < _g1.length) {
				var dismissed = [_g1[_g]];
				++_g;
				var _g2 = Lambda.findIndex(unique, (function (dismissed) {
					return function (item) {
						return Alert.eq(item, dismissed[0]);
					};
				})(dismissed));
				if (_g2 != -1) {
					unique[_g2] = dismissed[0];
				};
			};
			return unique.slice();
		}), null);
		AutoObservable.cur = before;
		this.__coco_alerts = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return DeviceData.locationFilterer(PromisedTools.or(Observable.get_value(_gthis.pulled.__coco_locations), new LazyConst([])).concat(State.get_value(_gthis.pushed.__coco_locations)).slice()).slice();
		}), null);
		AutoObservable.cur = before;
		this.__coco_locations = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			var _g = State.get_value(_gthis.__coco_heartbeatEnabledCache);
			if (_g == null) {
				return PromisedTools.map(Observable.get_value(_gthis.__coco_data), function (v) {
					return v.heartbeatEnabled;
				});
			} else {
				return PromisedWith.Done(_g);
			};
		}), null);
		AutoObservable.cur = before;
		this.__coco_heartbeatEnabled = ret;
		this.__coco_heartbeatTimeout = __coco_init_heartbeatTimeout;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.async(function () {
			State.get_value(_gthis.__coco_revision);
			var _g = State.get_value(_gthis.__coco_cache);
			if (_g == null) {
				return _gthis.remote.v2().devices().ofId(_gthis.id).get();
			} else {
				return new SyncFuture(new LazyConst(Outcome.Success(_g)));
			};
		}), null);
		AutoObservable.cur = before;
		this.__coco_data = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			if (DeviceId.get_deviceType(_gthis.id) != DeviceType.Dasloop || DeviceId.get_model(_gthis.id) != DeviceModel.Camera) {
				return Option.None;
			} else {
				return Option.Some(new DeviceLiveCallData({"device": _gthis.id, "remote": _gthis.remote, "project": _gthis.project}));
			};
		}), null);
		AutoObservable.cur = before;
		this.__coco_livecall = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return Lambda.find(Observable.get_value(_gthis.dasiots.__coco_latestInfo), function (reading) {
				return reading.device == _gthis.id;
			});
		}), null);
		AutoObservable.cur = before;
		this.__coco_dasiotLatestInfo = ret;
		this.id = __coco_init_id;
		this.project = __coco_init_project;
		this.remote = __coco_init_remote;
		this.dasiots = __coco_init_dasiots;
		this.pushed = __coco_init_pushed;
		this.pulled = __coco_init_pulled;
		this.__coco_revision = State._new(0, null, null, null);
		this.__coco_cache = State._new(null, null, null, null);
		this.__coco_heartbeatEnabledCache = State._new(null, null, null, null);
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new TripData({"id": _gthis.id, "remote": _gthis.remote, "pushed": _gthis.pushed, "pulled": _gthis.pulled});
		AutoObservable.cur = before;
		this.trip = ret;
		this.__coco_dismissedAlerts = State._new([], null, null, null);
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"id": new ConstObservable(this.id), "project": new ConstObservable(this.project), "remote": new ConstObservable(this.remote), "dasiots": new ConstObservable(this.dasiots), "pushed": new ConstObservable(this.pushed), "pulled": new ConstObservable(this.pulled), "dasiotLatestInfo": this.__coco_dasiotLatestInfo, "livecall": this.__coco_livecall, "data": this.__coco_data, "heartbeatTimeout": this.__coco_heartbeatTimeout, "heartbeatEnabledCache": this.__coco_heartbeatEnabledCache, "heartbeatEnabled": this.__coco_heartbeatEnabled, "locations": this.__coco_locations, "trip": new ConstObservable(this.trip), "dismissedAlerts": this.__coco_dismissedAlerts, "alerts": this.__coco_alerts, "readings": this.__coco_readings, "latestLocation": this.__coco_latestLocation, "location": this.__coco_location, "location3D": this.__coco_location3D, "latestLocationDate": this.__coco_latestLocationDate, "latestReadingDate": this.__coco_latestReadingDate, "latestUpdateDate": this.__coco_latestUpdateDate, "latestShutdownDate": this.__coco_latestShutdownDate, "battery": this.__coco_battery, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	isOnline(now) {
		switch (DeviceId.get_deviceType(this.id)._hx_index) {
			case 0:
				var _g = Observable.get_value(this.__coco_latestUpdateDate);
				var _g1 = Observable.get_value(this.__coco_latestShutdownDate);
				if (_g == null) {
					return false;
				} else if (_g1 == null) {
					return _g.getTime() > now.getTime() - this.offlineThreshold();
				} else if (_g.getTime() > _g1.getTime()) {
					return _g.getTime() > now.getTime() - this.offlineThreshold();
				} else {
					return false;
				};
				break
			case 1:
				var _g = Observable.get_value(this.__coco_readings).inst.get("uwb_detector_powered");
				if (_g == null) {
					return false;
				} else if (_g.value != 0) {
					return _g.date.getTime() > now.getTime() - this.offlineThreshold();
				} else {
					return false;
				};
				break
			case 2:case 4:
				var _g = Observable.get_value(this.__coco_latestUpdateDate);
				if (_g == null) {
					return false;
				} else {
					return _g.getTime() > now.getTime() - this.offlineThreshold();
				};
				break
			case 3:case 5:
				return false;
				break
			case 6:
				return false;
				break
			case 7:
				return false;
				break
			
		};
	}
	shouldShowInModel(now) {
		switch (DeviceId.get_deviceType(this.id)._hx_index) {
			case 0:
				if (Observable.get_value(this.__coco_latestLocationDate) != null) {
					return Observable.get_value(this.__coco_latestLocationDate).getTime() > now.getTime() - 660000;
				} else {
					return false;
				};
				break
			case 1:
				if (this.isOnline(now) && Observable.get_value(this.__coco_latestLocationDate) != null) {
					return Observable.get_value(this.__coco_latestLocationDate).getTime() > now.getTime() - 660000;
				} else {
					return false;
				};
				break
			case 2:case 3:case 4:case 5:
				return false;
				break
			case 6:
				return false;
				break
			case 7:
				return false;
				break
			
		};
	}
	dismissAlert(alert) {
		var v = State.get_value(this.__coco_dismissedAlerts);
		this.set_dismissedAlerts(Vectorlike.ofSingle({"date": alert.date, "value": alert.value, "ignored": false, "dismissed": true}).concat(v));
		return this.remote.v2().devices().ofId(this.id).dismissAlert(alert.date, AlertValueTools.toType(alert.value));
	}
	setHeartbeatEnabled(enabled) {
		var _gthis = this;
		var c = function (_) {
			_gthis.set_heartbeatEnabledCache(enabled);
		};
		var c1 = function (o) {
			if (o._hx_index == 0) {
				Callback.invoke(c, o.data);
			};
		};
		return Future.map(this.remote.v2().devices().ofId(this.id).setHeartbeatEnabled(enabled), function (v) {
			Callback.invoke(c1, v);
			return v;
		});
	}
	setProject(pid) {
		return this.remote.v2().devices().ofId(this.id).setProject(pid);
	}
	setWorker(wid) {
		return this.remote.v2().devices().ofId(this.id).setWorker(wid);
	}
	unsetWorker() {
		return this.remote.v2().devices().ofId(this.id).unsetWorker();
	}
	setPlant(pid) {
		return this.remote.v2().devices().ofId(this.id).setPlant(pid);
	}
	unsetPlant() {
		return this.remote.v2().devices().ofId(this.id).unsetPlant();
	}
	setEquipment(eid) {
		return this.remote.v2().devices().ofId(this.id).setEquipment(eid);
	}
	unsetEquipment() {
		return this.remote.v2().devices().ofId(this.id).unsetEquipment();
	}
	offlineThreshold() {
		switch (DeviceId.get_deviceType(this.id)._hx_index) {
			case 0:case 1:
				var interval;
				var _g = Observable.get_value(this.__coco_readings).inst.get("general_report_interval");
				var _g1 = Observable.get_value(this.__coco_readings).inst.get("short_report_period");
				if (_g == null) {
					if (_g1 != null && _g1.value > 0) {
						interval = _g1.value;
					} else if (_g != null && _g.value > 0) {
						interval = _g.value;
					} else {
						switch (DeviceId.get_connectionType(this.id)._hx_index) {
							case 0:case 1:
								interval = 300;
								break
							case 2:
								interval = 1800;
								break
							default:
							interval = 300;
							
						};
					};
				} else if (_g1 != null && _g1.value > 0) {
					interval = _g1.value;
				} else if (_g != null && _g.value > 0) {
					interval = _g.value;
				} else {
					switch (DeviceId.get_connectionType(this.id)._hx_index) {
						case 0:case 1:
							interval = 300;
							break
						case 2:
							interval = 1800;
							break
						default:
						interval = 300;
						
					};
				};
				var _g = Observable.get_value(this.__coco_readings).inst.get("special_area_detection_enabled");
				if ((_g == null) ? false : _g.value != 0) {
					var _g = Observable.get_value(this.__coco_readings).inst.get("in_sleep_area");
					if ((_g == null) ? false : _g.value != 0) {
						return -10000;
					} else {
						var _g = Observable.get_value(this.__coco_heartbeatTimeout).normal;
						if (_g == 0) {
							return Math.max(300000., interval * 1000 * 4);
						} else {
							return interval * 1000 * _g;
						};
					};
				} else {
					return Math.max(300000., interval * 1000 * 4);
				};
				break
			case 2:case 4:
				return 300000.;
				break
			case 3:case 5:
				return null;
				break
			case 6:
				return null;
				break
			case 7:
				return null;
				break
			
		};
	}
	getRecentHeartRateDate() {
		var _g = Observable.get_value(this.__coco_readings).inst.get("heart_rate");
		if (_g == null) {
			return Option.None;
		} else if (_g.value == 0) {
			return Option.None;
		} else {
			return Option.Some(_g.date);
		};
	}
	getRecentBodyTemperatureDate() {
		var _g = Observable.get_value(this.__coco_readings).inst.get("body_temperature");
		if (_g == null) {
			return Option.None;
		} else {
			return Option.Some(_g.date);
		};
	}
	getStandstillTokenCount() {
		var _g = Observable.get_value(this.__coco_readings).inst.get("standstill_token_count");
		if (_g == null) {
			return Option.None;
		} else {
			return Option.Some(_g.value | 0);
		};
	}
	getStandstillTokenExpiration() {
		var _g = Observable.get_value(this.__coco_readings).inst.get("standstill_token_duration");
		if (_g == null) {
			return Option.None;
		} else {
			return Option.Some(new Date(_g.date.getTime() + _g.value * 1000));
		};
	}
	isSpecialAreaDetectionEnabled() {
		var _g = Observable.get_value(this.__coco_readings).inst.get("special_area_detection_enabled");
		if (_g == null) {
			return false;
		} else {
			return _g.value != 0;
		};
	}
	isInRecessArea() {
		var _g = Observable.get_value(this.__coco_readings).inst.get("in_recess_area");
		if (_g == null) {
			return Option.None;
		} else if (_g.value == 0) {
			return Option.None;
		} else {
			return Option.Some(_g.date);
		};
	}
	isInSleepArea() {
		var _g = Observable.get_value(this.__coco_readings).inst.get("in_sleep_area");
		if (_g == null) {
			return Option.None;
		} else if (_g.value == 0) {
			return Option.None;
		} else {
			return Option.Some(_g.date);
		};
	}
	isWearing() {
		var _g = Observable.get_value(this.__coco_readings).inst.get("wearing");
		if (_g == null) {
			return false;
		} else {
			return _g.value == 1;
		};
	}
	getLiveCallConfig() {
		return this.remote.v2().devices().ofId(this.id).getLiveCallCredentials();
	}
	set_revision(param) {
		this._updatePerformed.handlers.invoke({"revision": param});
		this.__coco_revision.set(param);
		return param;
	}
	set_cache(param) {
		this._updatePerformed.handlers.invoke({"cache": param});
		this.__coco_cache.set(param);
		return param;
	}
	set_heartbeatEnabledCache(param) {
		this._updatePerformed.handlers.invoke({"heartbeatEnabledCache": param});
		this.__coco_heartbeatEnabledCache.set(param);
		return param;
	}
	set_dismissedAlerts(param) {
		this._updatePerformed.handlers.invoke({"dismissedAlerts": param});
		this.__coco_dismissedAlerts.set(param);
		return param;
	}
	static deduplicate(data, eq) {
		var deduplicated = [];
		var _g = 0;
		while (_g < data.length) {
			var item = [data[_g]];
			++_g;
			if (!Lambda.exists(deduplicated, (function (item) {
				return function (v) {
					return eq(v, item[0]);
				};
			})(item))) {
				deduplicated.push(item[0]);
			};
		};
		return deduplicated;
	}
	static locationFilterer(data) {
		var unique = [];
		var uniqueDates = [];
		var _g = 0;
		while (_g < data.length) {
			var each = data[_g];
			++_g;
			var currentDate = each.date.getTime();
			var _g1 = uniqueDates.indexOf(currentDate);
			if (_g1 == -1) {
				uniqueDates.push(currentDate);
				unique.push(each);
			} else if (each.source._hx_index != 0) {
				unique[_g1] = each;
			};
		};
		unique.sort(function (v1, v2) {
			return Reflect__1.compare(v2.date, v1.date);
		});
		return unique;
	}
	static get __name__() {
		return "dasloop.app.data.DeviceData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return DeviceData
	}
}

