import {IncomingResponse} from "../Response.js"
import {IncomingRequest, IncomingRequestHeader, IncomingRequestBody} from "../Request.js"
import {ClientObject} from "../Client.js"
import {Outcome} from "../../core/Outcome.js"
import {LazyConst} from "../../core/Lazy.js"
import {Future, SyncFuture} from "../../core/Future.js"
import {Url} from "../../Url.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const LocalContainerClient = Register.global("$hxClasses")["tink.http.clients.LocalContainerClient"] = 
class LocalContainerClient extends Register.inherits() {
	new(container) {
		this.container = container;
	}
	request(req) {
		var this1 = req.header.url;
		return Future.flatMap(this.container.serve(new IncomingRequest("127.0.0.1", new IncomingRequestHeader(req.header.method, Url.fromString((this1.query == null) ? this1.path : ((this1.path == null) ? "null" : this1.path) + "?" + ((this1.query == null) ? "null" : this1.query)), "HTTP/1.1", req.header.fields), IncomingRequestBody.Plain(req.body))), function (res) {
			return new SyncFuture(new LazyConst(Outcome.Success(new IncomingResponse(res.header, res.body))));
		});
	}
	static get __name__() {
		return "tink.http.clients.LocalContainerClient"
	}
	static get __interfaces__() {
		return [ClientObject]
	}
	get __class__() {
		return LocalContainerClient
	}
}

