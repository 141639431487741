import {Portion} from "../url/Portion.js"
import {DefaultKeymaker} from "./Keymaker.js"
import {DefaultBuffer} from "./Builder.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Builder14 = Register.global("$hxClasses")["tink.querystring.Builder14"] = 
class Builder14 extends Register.inherits() {
	new() {
	}
	stringify(data, keymaker) {
		if (keymaker == null) {
			keymaker = new DefaultKeymaker();
		};
		var buffer = [];
		this.process0("", buffer, keymaker, data);
		return DefaultBuffer.flush(buffer);
	}
	process0(prefix, buffer, keymaker, data) {
		var prefix1 = (prefix == "") ? "mode" : keymaker.field(prefix, "mode");
		var data1 = data.mode;
		if (data1 != null) {
			buffer.push(Portion.ofString(prefix1) + "=" + Portion.ofString(data1));
		};
	}
	static get __name__() {
		return "tink.querystring.Builder14"
	}
	get __class__() {
		return Builder14
	}
}

