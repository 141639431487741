import {AutoObservable, Computation} from "../../tink/state/internal/AutoObservable.js"
import {PromisedWith} from "../../tink/state/Promised.js"
import {Observable} from "../../tink/state/Observable.js"
import {Option} from "../../haxe/ds/Option.js"
import {Register} from "../../genes/Register.js"
import {Spinner} from "./general/Spinner.js"
import {ErrorMessage} from "./general/ErrorMessage.js"
import {Slot} from "../../coconut/ui/internal/Slot.js"
import {View} from "../../coconut/react/View.js"

const $global = Register.$global

export const ViewLoader = Register.global("$hxClasses")["dasloop.ui.ViewLoader"] = 
class ViewLoader extends Register.inherits(View) {
	new(__coco_data_) {
		var _gthis = this;
		this.__coco_renderView = new Slot(this, null, null);
		this.__coco_renderLoading = new Slot(this, null, new AutoObservable(Computation.sync(function () {
			return function () {
				return Spinner.fromHxx({}, {});
			};
		}), null));
		this.__coco_renderError = new Slot(this, null, new AutoObservable(Computation.sync(function () {
			return function (e) {
				var compute = new AutoObservable(Computation.sync(function () {
					return e;
				}), null);
				return ErrorMessage.fromHxx({}, {"error": compute});
			};
		}), null));
		this.__coco_result = new AutoObservable(Computation.async(function () {
			return _gthis.renderView();
		}), null);
		var f = function (__tink_tmp20) {
			if (Observable.get_value(_gthis.__coco_result)._hx_index == 0) {
				switch (__tink_tmp20._hx_index) {
					case 0:
						return __tink_tmp20.v;
						break
					case 1:
						return PromisedWith.Loading;
						break
					
				};
			} else {
				return Observable.get_value(_gthis.__coco_result);
			};
		};
		var last = Option.None;
		this.__coco_cached = new AutoObservable(function (_, _1) {
			var ret = f(last);
			last = Option.Some(ret);
			return ret;
		}, null);
		this.__initAttributes(__coco_data_);
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	renderView() {
		return (Observable.get_value(this.__coco_renderView))();
	}
	renderLoading() {
		return (Observable.get_value(this.__coco_renderLoading))();
	}
	renderError(e) {
		return (Observable.get_value(this.__coco_renderError))(e);
	}
	__coco_render() {
		var _g = Observable.get_value(this.__coco_cached);
		switch (_g._hx_index) {
			case 0:
				return this.renderLoading();
				break
			case 1:
				return _g.result;
				break
			case 2:
				return this.renderError(_g.error);
				break
			
		};
	}
	__initAttributes(attributes) {
		this.__coco_renderView.setData(attributes.renderView);
		this.__coco_renderLoading.setData(attributes.renderLoading);
		this.__coco_renderError.setData(attributes.renderError);
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": ViewLoader, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "dasloop.ui.ViewLoader"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return ViewLoader
	}
}

