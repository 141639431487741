import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {ConstObservable, Observable} from "../../../tink/state/Observable.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Register} from "../../../genes/Register.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const WeeklyData = Register.global("$hxClasses")["dasloop.app.data.WeeklyData"] = 
class WeeklyData extends Register.inherits() {
	new(__coco_init) {
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.async(function () {
			return _gthis.remote.projects().ofId(State.get_value(_gthis.__coco_project)).stats().weeklyTotal();
		}), null);
		AutoObservable.cur = before;
		this.__coco_stat = ret;
		this.__coco_project = State._new(__coco_init.project, null, null, null);
		this.remote = __coco_init.remote;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"project": this.__coco_project, "remote": new ConstObservable(this.remote), "stat": this.__coco_stat, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	static get __name__() {
		return "dasloop.app.data.WeeklyData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return WeeklyData
	}
}

