import {Writer23} from "./Writer23.js"
import {BasicParser, JsonString} from "./Parser.js"
import {TypedError} from "../core/Error.js"
import {Exception} from "../../haxe/Exception.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Parser35 = Register.global("$hxClasses")["tink.json.Parser35"] = 
class Parser35 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	pb() {
		var _gthis = this;
		var v_active = false;
		var hasv_active = false;
		var v_area = null;
		var hasv_area = false;
		var v_elevation = .0;
		var hasv_elevation = false;
		var v_height = .0;
		var hasv_height = false;
		var v_id = null;
		var hasv_id = false;
		var v_name = null;
		var hasv_name = false;
		var v_project = null;
		var hasv_project = false;
		var v_shape = null;
		var hasv_shape = false;
		var v_type = null;
		var hasv_type = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "active":
						v_active = this.G();
						hasv_active = true;
						break
					case "area":
						this.F("[");
						var __ret = [];
						if (!this.E("]")) {
							while (true) {
								this.F("[");
								var __ret1 = [];
								if (!this.E("]")) {
									while (true) {
										this.F("[");
										var __ret2 = [];
										if (!this.E("]")) {
											while (true) {
												this.F("[");
												var __ret3 = [];
												if (!this.E("]")) {
													while (true) {
														__ret3.push(JsonString.f(this.r()));
														if (!this.E(",")) {
															break;
														};
													};
													this.F("]");
												};
												__ret2.push(__ret3);
												if (!this.E(",")) {
													break;
												};
											};
											this.F("]");
										};
										__ret1.push(__ret2);
										if (!this.E(",")) {
											break;
										};
									};
									this.F("]");
								};
								__ret.push(__ret1);
								if (!this.E(",")) {
									break;
								};
							};
							this.F("]");
						};
						v_area = __ret;
						hasv_area = true;
						break
					case "elevation":
						v_elevation = (this.E("null")) ? null : JsonString.f(this.r());
						hasv_elevation = true;
						break
					case "height":
						v_height = (this.E("null")) ? null : JsonString.f(this.r());
						hasv_height = true;
						break
					case "id":
						v_id = JsonString.s(this.m());
						hasv_id = true;
						break
					case "name":
						v_name = JsonString.s(this.m());
						hasv_name = true;
						break
					case "project":
						v_project = JsonString.s(this.m());
						hasv_project = true;
						break
					case "shape":
						this.F("[");
						var __ret4 = [];
						if (!this.E("]")) {
							while (true) {
								this.F("[");
								var __ret5 = [];
								if (!this.E("]")) {
									while (true) {
										this.F("[");
										var __ret6 = [];
										if (!this.E("]")) {
											while (true) {
												this.F("[");
												var __ret7 = [];
												if (!this.E("]")) {
													while (true) {
														__ret7.push(JsonString.f(this.r()));
														if (!this.E(",")) {
															break;
														};
													};
													this.F("]");
												};
												__ret6.push(__ret7);
												if (!this.E(",")) {
													break;
												};
											};
											this.F("]");
										};
										__ret5.push(__ret6);
										if (!this.E(",")) {
											break;
										};
									};
									this.F("]");
								};
								__ret4.push(__ret5);
								if (!this.E(",")) {
									break;
								};
							};
							this.F("]");
						};
						v_shape = __ret4;
						hasv_shape = true;
						break
					case "type":
						var v = JsonString.s(this.m());
						switch (v) {
							case "boundary":case "region":case "restricted":
								v_type = v;
								break
							default:
							throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer23().wb(["boundary", "region", "restricted"]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 469, "className": "tink.json.Parser35", "methodName": "process0"}));
							
						};
						hasv_type = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"active": (hasv_active) ? v_active : __missing__("active"), "area": (hasv_area) ? v_area : __missing__("area"), "elevation": (hasv_elevation) ? v_elevation : __missing__("elevation"), "height": (hasv_height) ? v_height : __missing__("height"), "id": (hasv_id) ? v_id : __missing__("id"), "name": (hasv_name) ? v_name : __missing__("name"), "project": (hasv_project) ? v_project : __missing__("project"), "shape": (hasv_shape) ? v_shape : __missing__("shape"), "type": (hasv_type) ? v_type : __missing__("type")};
	}
	pc(source) {
		if (this.e.length > 0) {
			this.e = [];
		};
		this.g(source);
		this.F("[");
		var __ret = [];
		if (!this.E("]")) {
			while (true) {
				__ret.push(this.pb());
				if (!this.E(",")) {
					break;
				};
			};
			this.F("]");
		};
		var ret = __ret;
		var _g = 0;
		var _g1 = this.e;
		while (_g < _g1.length) _g1[_g++]();
		if (this.e.length > 0) {
			this.e = [];
		};
		return ret;
	}
	pd(source) {
		var _gthis = this;
		return TypedError.catchExceptions(function () {
			var ret = _gthis.pc(source);
			_gthis.h();
			if (_gthis.c < _gthis.d) {
				_gthis.D("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser35", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser35"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser35
	}
}

