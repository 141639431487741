import {Subscriber} from "./Subscriber.js"
import {MqttBase} from "./Mqtt.js"
import {StringCache} from "../Cache.js"
import {Topic} from "../../mqtt/Topic.js"
import {Decoder1} from "../../../tink/serialize/Decoder1.js"
import {Decoder0} from "../../../tink/serialize/Decoder0.js"
import {Parser97} from "../../../tink/json/Parser97.js"
import {Parser96} from "../../../tink/json/Parser96.js"
import {Register} from "../../../genes/Register.js"
import {Subscribers} from "../../../dasloop/types/pubsub/Public.js"

const $global = Register.$global

export const Subscribers0 = Register.global("$hxClasses")["why.pubsub.mqtt.Subscribers0"] = 
class Subscribers0 extends Register.inherits(MqttBase) {
	new(client) {
		this.__cache_project = new StringCache();
		this.__cache_task = new StringCache();
		super.new(client);
	}
	task(id, encoding) {
		var _gthis = this;
		return this.__cache_task.get("" + id + "|" + encoding, function (_) {
			var _gthis1 = _gthis.client;
			var tmp = "v1/" + encoding + "/tasks/" + id;
			var tmp1;
			switch (encoding) {
				case "json":
					tmp1 = function (v) {
						return new Parser96().pO(v.toString());
					};
					break
				case "tink":
					tmp1 = function (v) {
						return new Decoder0().tryDecode(v.toBytes());
					};
					break
				
			};
			return new Subscriber(_gthis1, {"topic": Topic.sanitize(tmp), "qos": 1, "metadata": function (_) {
				return null;
			}, "unserialize": tmp1});
		});
	}
	project(id, encoding) {
		var _gthis = this;
		return this.__cache_project.get("" + id + "|" + encoding, function (_) {
			var _gthis1 = _gthis.client;
			var tmp = "v1/" + encoding + "/projects/" + id + "/device_updates";
			var tmp1;
			switch (encoding) {
				case "json":
					tmp1 = function (v) {
						return new Parser97().po(v.toString());
					};
					break
				case "tink":
					tmp1 = function (v) {
						return new Decoder1().tryDecode(v.toBytes());
					};
					break
				
			};
			return new Subscriber(_gthis1, {"topic": Topic.sanitize(tmp), "qos": 1, "metadata": function (_) {
				return null;
			}, "unserialize": tmp1});
		});
	}
	static get __name__() {
		return "why.pubsub.mqtt.Subscribers0"
	}
	static get __interfaces__() {
		return [Subscribers]
	}
	static get __super__() {
		return MqttBase
	}
	get __class__() {
		return Subscribers0
	}
}

