import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const ChunkBase = Register.global("$hxClasses")["tink.chunk.ChunkBase"] = 
class ChunkBase {
	flatten(into) {
	}
	static get __name__() {
		return "tink.chunk.ChunkBase"
	}
	get __class__() {
		return ChunkBase
	}
}

