import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {PromisedTools} from "../../../tink/state/Promised.js"
import {Observable, ConstObservable} from "../../../tink/state/Observable.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Promise as Promise__1} from "../../../tink/core/Promise.js"
import {Outcome} from "../../../tink/core/Outcome.js"
import {LazyConst} from "../../../tink/core/Lazy.js"
import {Future, SyncFuture} from "../../../tink/core/Future.js"
import {Callback} from "../../../tink/core/Callback.js"
import {Anon} from "../../../tink/Anon.js"
import {Register} from "../../../genes/Register.js"
import {StringCollection} from "../../../coconut/ds/Collection.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const ContactsData = Register.global("$hxClasses")["dasloop.app.data.ContactsData"] = 
class ContactsData extends Register.inherits() {
	new(__coco_init) {
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return PromisedTools.map(Observable.get_value(_gthis.collection.__coco_list), function (contacts) {
				var result = new Array(contacts.length);
				var _g = 0;
				var _g1 = contacts.length;
				while (_g < _g1) {
					var i = _g++;
					result[i] = State.get_value(contacts[i].__coco_data);
				};
				return result;
			});
		}), null);
		AutoObservable.cur = before;
		this.__coco_data = ret;
		this.api = __coco_init.api;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new StringCollection({"fetch": (o=>Register.bind(o, o.list))(_gthis.api), "extractKey": function (raw) {
			return raw.id;
		}, "createItem": function (id, init) {
			return new ContactData({"data": (init._hx_index == 0) ? init.v : null});
		}, "updateItem": function (data, raw) {
			data.refresh(raw);
		}});
		AutoObservable.cur = before;
		this.collection = ret;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"api": new ConstObservable(this.api), "collection": new ConstObservable(this.collection), "data": this.__coco_data, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	set(contacts) {
		var c = Register.bind(this, this.refresh);
		var c1 = function (o) {
			if (o._hx_index == 0) {
				Callback.invoke(c, o.data);
			};
		};
		return Future.map(this.api.set(contacts), function (v) {
			Callback.invoke(c1, v);
			return v;
		});
	}
	refresh() {
		this.collection.refresh();
	}
	static get __name__() {
		return "dasloop.app.data.ContactsData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return ContactsData
	}
}


export const ContactData = Register.global("$hxClasses")["dasloop.app.data.ContactData"] = 
class ContactData extends Register.inherits() {
	new(__coco_init) {
		this._updatePerformed = Signal.trigger();
		this.__coco_data = State._new(__coco_init.data, null, null, null);
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"data": this.__coco_data, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	refresh(data) {
		return Promise__1.next(this.__cocoupdate(new SyncFuture(new LazyConst(Outcome.Success({"data": data})))), function (_) {
			return new SyncFuture(new LazyConst(Outcome.Success(null)));
		});
	}
	__cocoupdate(ret) {
		var _gthis = this;
		var sync = true;
		var done = false;
		ret.handle(function (o) {
			done = true;
			if (!sync) {
				_gthis.__coco_transitionCount.set(State.get_value(_gthis.__coco_transitionCount) - 1);
			};
			switch (o._hx_index) {
				case 0:
					var _g = o.data;
					if (Anon.getExistentFields(_g).data) {
						_gthis.__coco_data.set(_g.data);
					};
					_gthis._updatePerformed.handlers.invoke(_g);
					break
				case 1:
					_gthis.errorTrigger.handlers.invoke(o.failure);
					break
				
			};
		});
		if (!done) {
			sync = false;
		};
		if (!sync) {
			this.__coco_transitionCount.set(State.get_value(this.__coco_transitionCount) + 1);
		};
		return ret;
	}
	static get __name__() {
		return "dasloop.app.data.ContactData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return ContactData
	}
}

