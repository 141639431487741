import {LazyConst, Lazy} from "../core/Lazy.js"
import {SyncFuture} from "../core/Future.js"
import {Register} from "../../genes/Register.js"
import {Std} from "../../Std.js"

const $global = Register.$global

export const WorkerObject = function() {};
WorkerObject.__isInterface__ = true;

export const EagerWorker = Register.global("$hxClasses")["tink.io._Worker.EagerWorker"] = 
class EagerWorker extends Register.inherits() {
	new() {
	}
	work(task) {
		return new SyncFuture(new LazyConst(Lazy.get(task)));
	}
	static get __name__() {
		return "tink.io._Worker.EagerWorker"
	}
	static get __interfaces__() {
		return [WorkerObject]
	}
	get __class__() {
		return EagerWorker
	}
}


export const Worker = Register.global("$hxClasses")["tink.io._Worker.Worker"] = 
class Worker {
	static ensure(this1) {
		if (this1 == null) {
			return Worker.get();
		} else {
			return this1;
		};
	}
	static get() {
		return Worker.pool[Std.random(Worker.pool.length)];
	}
	static work(this1, task) {
		return this1.work(task);
	}
	static get __name__() {
		return "tink.io._Worker.Worker_Impl_"
	}
	get __class__() {
		return Worker
	}
}


Worker.EAGER = new EagerWorker()
Worker.pool = [Worker.EAGER]