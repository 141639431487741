import {Register} from "../../genes/Register.js"
import {StringTools} from "../../StringTools.js"
import {HxOverrides} from "../../HxOverrides.js"

const $global = Register.$global

export const Path = Register.global("$hxClasses")["tink.url._Path.Path"] = 
class Path {
	static parts(this1) {
		var _g = [];
		var _g1 = 0;
		var _g2 = this1.split("/");
		while (_g1 < _g2.length) {
			var p = _g2[_g1];
			++_g1;
			if (p != "") {
				_g.push(p);
			};
		};
		return _g;
	}
	static join(this1, that) {
		if (that == "") {
			return this1;
		} else if (that.charAt(0) == "/") {
			return that;
		} else if (this1.charAt(this1.length - 1) == "/") {
			return Path.ofString(this1 + that);
		} else {
			var _g = this1.lastIndexOf("/");
			if (_g == -1) {
				return that;
			} else {
				return Path.ofString(HxOverrides.substr(this1, 0, _g + 1) + ((that == null) ? "null" : that));
			};
		};
	}
	static ofString(s) {
		return Path.normalize(s);
	}
	static normalize(s) {
		s = StringTools.trim(StringTools.replace(s, "\\", "/"));
		if (s == ".") {
			return "./";
		};
		var isDir = StringTools.endsWith(s, "/..") || StringTools.endsWith(s, "/") || StringTools.endsWith(s, "/.");
		var parts = [];
		var isAbsolute = StringTools.startsWith(s, "/");
		var up = 0;
		var _g = 0;
		var _g1 = s.split("/");
		while (_g < _g1.length) {
			var _g2 = StringTools.trim(_g1[_g++]);
			switch (_g2) {
				case "":
					break
				case ".":
					break
				case "..":
					if (parts.pop() == null) {
						++up;
					};
					break
				default:
				parts.push(_g2);
				
			};
		};
		if (isAbsolute) {
			parts.unshift("");
		} else {
			var _g = 0;
			var _g1 = up;
			while (_g < _g1) {
				++_g;
				parts.unshift("..");
			};
		};
		if (isDir) {
			parts.push("");
		};
		return parts.join("/");
	}
	static get __name__() {
		return "tink.url._Path.Path_Impl_"
	}
	get __class__() {
		return Path
	}
}

