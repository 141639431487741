import {RemoteBase, RemoteEndpoint, ResponseReader} from "./Remote.js"
import {Single} from "../../streams/Stream.js"
import {Builder5} from "../../querystring/Builder5.js"
import {Parser26} from "../../json/Parser26.js"
import {HeaderField, HeaderValue} from "../../http/Header.js"
import {LazyConst} from "../../core/Lazy.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote39 = Register.global("$hxClasses")["tink.web.proxy.Remote39"] = 
class Remote39 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	list(query) {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [].concat(new Builder5().stringify({"_152": query.after, "_153": query.before, "_154": query.limit})), "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser26())));
	}
	static get __name__() {
		return "tink.web.proxy.Remote39"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote39
	}
}

