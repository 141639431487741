import {BasicWriter} from "./Writer.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Writer41 = Register.global("$hxClasses")["tink.json.Writer41"] = 
class Writer41 extends Register.inherits(BasicWriter) {
	new() {
		super.new();
	}
	wb(value) {
		this.f("{");
		var value1 = value._91;
		this.e("\"name\":");
		this.j(value1);
		var _g = value._90;
		if (_g != null) {
			this.f(",");
			this.e("\"contacts\":");
			if (_g == null) {
				this.e("null");
			} else {
				this.f("[");
				var first = true;
				var _g1 = 0;
				while (_g1 < _g.length) {
					var value = _g[_g1++];
					if (first) {
						first = false;
					} else {
						this.f(",");
					};
					this.wc(value);
				};
				this.f("]");
			};
		};
		this.f("}");
	}
	wc(value) {
		this.f("{");
		var value1 = value.language;
		this.e("\"language\":");
		this.j(value1);
		var value1 = value.name;
		this.f(",");
		this.e("\"name\":");
		this.j(value1);
		var value1 = value.role;
		this.f(",");
		this.e("\"role\":");
		this.j(value1);
		var _g = value.email;
		if (_g != null) {
			this.f(",");
			this.e("\"email\":");
			if (_g == null) {
				this.e("null");
			} else {
				this.j(_g);
			};
		};
		var _g = value.phone;
		if (_g != null) {
			this.f(",");
			this.e("\"phone\":");
			if (_g == null) {
				this.e("null");
			} else {
				this.j(_g);
			};
		};
		var _g = value.sms;
		if (_g != null) {
			this.f(",");
			this.e("\"sms\":");
			if (_g == null) {
				this.e("null");
			} else {
				this.j(_g);
			};
		};
		var _g = value.telegram;
		if (_g != null) {
			this.f(",");
			this.e("\"telegram\":");
			if (_g == null) {
				this.e("null");
			} else {
				this.j(_g);
			};
		};
		var _g = value.whatsapp;
		if (_g != null) {
			this.f(",");
			this.e("\"whatsapp\":");
			if (_g == null) {
				this.e("null");
			} else {
				this.j(_g);
			};
		};
		this.f("}");
	}
	wd(value) {
		this.d();
		this.wb(value);
		return this.b.toString();
	}
	static get __name__() {
		return "tink.json.Writer41"
	}
	static get __super__() {
		return BasicWriter
	}
	get __class__() {
		return Writer41
	}
}

