import {BasicParser, JsonString} from "./Parser.js"
import {TypedError} from "../core/Error.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Parser73 = Register.global("$hxClasses")["tink.json.Parser73"] = 
class Parser73 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	pb() {
		var _gthis = this;
		var v_date = null;
		var hasv_date = false;
		var v_humidity = .0;
		var hasv_humidity = false;
		var v_icon = null;
		var hasv_icon = false;
		var v_messages = null;
		var hasv_messages = false;
		var v_temperature = null;
		var hasv_temperature = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "date":
						v_date = new Date(JsonString.f(this.r()));
						hasv_date = true;
						break
					case "humidity":
						v_humidity = JsonString.f(this.r());
						hasv_humidity = true;
						break
					case "icon":
						v_icon = JsonString.s(this.m());
						hasv_icon = true;
						break
					case "messages":
						this.F("[");
						var __ret = [];
						if (!this.E("]")) {
							while (true) {
								__ret.push(JsonString.s(this.m()));
								if (!this.E(",")) {
									break;
								};
							};
							this.F("]");
						};
						v_messages = __ret;
						hasv_messages = true;
						break
					case "temperature":
						v_temperature = this.pc();
						hasv_temperature = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"date": (hasv_date) ? v_date : __missing__("date"), "humidity": (hasv_humidity) ? v_humidity : __missing__("humidity"), "icon": (hasv_icon) ? v_icon : __missing__("icon"), "messages": (hasv_messages) ? v_messages : __missing__("messages"), "temperature": (hasv_temperature) ? v_temperature : __missing__("temperature")};
	}
	pc() {
		var _gthis = this;
		var v_current = .0;
		var hasv_current = false;
		var v_max = .0;
		var hasv_max = false;
		var v_min = .0;
		var hasv_min = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "current":
						v_current = JsonString.f(this.r());
						hasv_current = true;
						break
					case "max":
						v_max = JsonString.f(this.r());
						hasv_max = true;
						break
					case "min":
						v_min = JsonString.f(this.r());
						hasv_min = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"current": (hasv_current) ? v_current : __missing__("current"), "max": (hasv_max) ? v_max : __missing__("max"), "min": (hasv_min) ? v_min : __missing__("min")};
	}
	pd(source) {
		if (this.e.length > 0) {
			this.e = [];
		};
		this.g(source);
		var ret = this.pb();
		var _g = 0;
		var _g1 = this.e;
		while (_g < _g1.length) _g1[_g++]();
		if (this.e.length > 0) {
			this.e = [];
		};
		return ret;
	}
	pe(source) {
		var _gthis = this;
		return TypedError.catchExceptions(function () {
			var ret = _gthis.pd(source);
			_gthis.h();
			if (_gthis.c < _gthis.d) {
				_gthis.D("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser73", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser73"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser73
	}
}

