import {Register} from "../genes/Register.js"
import {HxOverrides} from "../HxOverrides.js"
import {DateTools} from "../DateTools.js"

const $global = Register.$global

export const Timezone = Register.global("$hxClasses")["why._Timezone.Timezone"] = 
class Timezone {
	
	/**
	* Format the given date (in caller's timezone) to this timezone
	* For example, on a machine with local timezone at GMT+8,
	* `Timezone.UTC.formatDate(new Date(2000,0,1,0,0,0), '%F %T')` will give "1999-12-31 16:00:00"
	*/
	static formatDate(this1, local, format) {
		var target = Timezone.getDate(this1, local);
		if (format == null) {
			return HxOverrides.dateStr(target);
		} else {
			return DateTools.format(target, format);
		};
	}
	static getDate(this1, local) {
		var this2 = (this1 + new Date().getTimezoneOffset() * 60) / 0.001;
		return new Date(local.getTime() + this2);
	}
	static get __name__() {
		return "why._Timezone.Timezone_Impl_"
	}
	get __class__() {
		return Timezone
	}
}

