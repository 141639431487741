import {RemoteBase, RemoteEndpoint, ResponseReader} from "./Remote.js"
import {Single} from "../../streams/Stream.js"
import {Builder0} from "../../querystring/Builder0.js"
import {Writer36} from "../../json/Writer36.js"
import {Parser52} from "../../json/Parser52.js"
import {Parser5} from "../../json/Parser5.js"
import {RealSourceTools} from "../../io/Source.js"
import {HeaderField, HeaderValue} from "../../http/Header.js"
import {Promise as Promise__1} from "../../core/Promise.js"
import {Outcome} from "../../core/Outcome.js"
import {LazyConst} from "../../core/Lazy.js"
import {SyncFuture} from "../../core/Future.js"
import {TypedError} from "../../core/Error.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote10 = Register.global("$hxClasses")["tink.web.proxy.Remote10"] = 
class Remote10 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	get() {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [], "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser52())));
	}
	permissions(project) {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["permissions"], "query": [].concat(new Builder0().stringify({"_51": project})), "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pc))(new Parser5())));
	}
	setName(name) {
		var __body__ = ByteChunk.of(Bytes.ofString(new Writer36().wc({"_52": name})));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["name"], "query": [], "headers": [new HeaderField("content-type", "application/json"), new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength()))].concat([])}), this.client, "PUT", new Single(new LazyConst(__body__)), function (header, body) {
			if (header.statusCode >= 400) {
				return Promise__1.next(RealSourceTools.all(body), function (chunk) {
					return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(header.statusCode, chunk.toString(), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_web/0.3.0/github/5f481ef34ae04a352700ac2c9682e6c96c27bbf5/src/tink/web/macros/Proxify.hx", "lineNumber": 173, "className": "tink.web.proxy.Remote10", "methodName": "setName"}))));
				});
			} else {
				return Promise__1.NOISE;
			};
		});
	}
	static get __name__() {
		return "tink.web.proxy.Remote10"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote10
	}
}

