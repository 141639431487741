import {Remote24} from "./Remote24.js"
import {RemoteBase, RemoteEndpoint} from "./Remote.js"
import {Portion} from "../../url/Portion.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote23 = Register.global("$hxClasses")["tink.web.proxy.Remote23"] = 
class Remote23 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	ofId(id) {
		return new Remote24(this.client, RemoteEndpoint.sub(this.endpoint, {"path": [Portion.ofString(id)], "query": [], "headers": [].concat([])}));
	}
	static get __name__() {
		return "tink.web.proxy.Remote23"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote23
	}
}

