import {Subscribers0} from "./Subscribers0.js"
import {Publishers0} from "./Publishers0.js"
import {MqttBase} from "./Mqtt.js"
import {PubSub} from "../../PubSub.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Mqtt0 = Register.global("$hxClasses")["why.pubsub.mqtt.Mqtt0"] = 
class Mqtt0 extends Register.inherits(MqttBase) {
	new(client) {
		super.new(client);
		this.publishers = new Publishers0(client);
		this.subscribers = new Subscribers0(client);
	}
	static get __name__() {
		return "why.pubsub.mqtt.Mqtt0"
	}
	static get __interfaces__() {
		return [PubSub]
	}
	static get __super__() {
		return MqttBase
	}
	get __class__() {
		return Mqtt0
	}
}

