import {Writer9} from "./Writer9.js"
import {BasicParser, JsonString} from "./Parser.js"
import {TypedError} from "../core/Error.js"
import {Exception} from "../../haxe/Exception.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Parser14 = Register.global("$hxClasses")["tink.json.Parser14"] = 
class Parser14 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	pb() {
		var _gthis = this;
		var v_id = null;
		var hasv_id = false;
		var v_name = null;
		var hasv_name = false;
		var v_streamUrl = null;
		var hasv_streamUrl = false;
		var v_type = null;
		var hasv_type = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "id":
						v_id = JsonString.s(this.m());
						hasv_id = true;
						break
					case "name":
						v_name = JsonString.s(this.m());
						hasv_name = true;
						break
					case "streamUrl":
						v_streamUrl = JsonString.s(this.m());
						hasv_streamUrl = true;
						break
					case "type":
						var v = JsonString.s(this.m());
						switch (v) {
							case "360":case "normal":
								v_type = v;
								break
							default:
							throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer9().wb(["normal", "360"]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 469, "className": "tink.json.Parser14", "methodName": "process0"}));
							
						};
						hasv_type = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"id": (hasv_id) ? v_id : __missing__("id"), "name": (hasv_name) ? v_name : __missing__("name"), "streamUrl": (hasv_streamUrl) ? v_streamUrl : __missing__("streamUrl"), "type": (hasv_type) ? v_type : __missing__("type")};
	}
	pc(source) {
		if (this.e.length > 0) {
			this.e = [];
		};
		this.g(source);
		this.F("[");
		var __ret = [];
		if (!this.E("]")) {
			while (true) {
				__ret.push(this.pb());
				if (!this.E(",")) {
					break;
				};
			};
			this.F("]");
		};
		var ret = __ret;
		var _g = 0;
		var _g1 = this.e;
		while (_g < _g1.length) _g1[_g++]();
		if (this.e.length > 0) {
			this.e = [];
		};
		return ret;
	}
	pd(source) {
		var _gthis = this;
		return TypedError.catchExceptions(function () {
			var ret = _gthis.pc(source);
			_gthis.h();
			if (_gthis.c < _gthis.d) {
				_gthis.D("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser14", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser14"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser14
	}
}

