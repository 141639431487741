import {Remote47} from "./Remote47.js"
import {RemoteBase, RemoteEndpoint, ResponseReader} from "./Remote.js"
import {Single} from "../../streams/Stream.js"
import {Writer27} from "../../json/Writer27.js"
import {Writer24} from "../../json/Writer24.js"
import {Parser36} from "../../json/Parser36.js"
import {HeaderField, HeaderValue} from "../../http/Header.js"
import {LazyConst} from "../../core/Lazy.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote46 = Register.global("$hxClasses")["tink.web.proxy.Remote46"] = 
class Remote46 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	get() {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [], "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser36())));
	}
	update(body) {
		var __body__ = ByteChunk.of(Bytes.ofString(new Writer24().wc({"_182": body.area, "_183": body.elevation, "_184": body.height, "_185": body.name, "_186": body.type})));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [], "headers": [new HeaderField("content-type", "application/json"), new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "PUT", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser36())));
	}
	setActive(active) {
		var __body__ = ByteChunk.of(Bytes.ofString(new Writer27().wc({"_187": active})));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["active"], "query": [], "headers": [new HeaderField("content-type", "application/json"), new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "PUT", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser36())));
	}
	workers() {
		return new Remote47(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["workers"], "query": [], "headers": [].concat([])}));
	}
	static get __name__() {
		return "tink.web.proxy.Remote46"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote46
	}
}

