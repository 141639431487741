import {Transform} from "../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../tink/state/internal/AutoObservable.js"
import {State} from "../../tink/state/State.js"
import {PromisedTools} from "../../tink/state/Promised.js"
import {ConstObservable, Observable} from "../../tink/state/Observable.js"
import {Signal} from "../../tink/core/Signal.js"
import {Outcome} from "../../tink/core/Outcome.js"
import {LazyConst} from "../../tink/core/Lazy.js"
import {SyncFuture, Future} from "../../tink/core/Future.js"
import {Option} from "../../haxe/ds/Option.js"
import {Register} from "../../genes/Register.js"
import {Annex} from "../data/helpers/Annex.js"
import {Model} from "../data/Model.js"

const $global = Register.$global

export const Updatable = Register.global("$hxClasses")["coconut.ds.Updatable"] = 
class Updatable extends Register.inherits() {
	new(__coco_init) {
		this._updatePerformed = Signal.trigger();
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.async(function () {
			State.get_value(_gthis.__coco_revision);
			var _g = State.get_value(_gthis.__coco_cache);
			switch (_g._hx_index) {
				case 0:
					return new SyncFuture(new LazyConst(Outcome.Success(_g.v)));
					break
				case 1:
					return _gthis.loader();
					break
				
			};
		}), null);
		AutoObservable.cur = before;
		this.__coco_data = ret;
		this.loader = __coco_init.loader;
		this.updater = __coco_init.updater;
		this.__coco_revision = State._new(0, null, null, null);
		var _g = __coco_init.cache;
		this.__coco_cache = State._new((_g == null) ? Option.None : _g, null, null, null);
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"loader": new ConstObservable(this.loader), "updater": new ConstObservable(this.updater), "data": this.__coco_data, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	refresh(cache) {
		if (cache == null) {
			cache = Option.None;
		};
		this.set_cache(cache);
		this.set_revision(State.get_value(this.__coco_revision) + 1);
	}
	update(patch) {
		var _gthis = this;
		var _g = this.updater;
		var a2 = patch;
		var ret = function (a1) {
			return _g(a1, a2);
		};
		var ret1 = Future.flatMap(Observable.getNext(this.observables.data, null, function (v) {
			return PromisedTools.toOption(v);
		}), ret);
		ret1.handle(function (o) {
			switch (o._hx_index) {
				case 0:
					_gthis.refresh(Option.Some(o.data));
					break
				case 1:
					break
				
			};
		});
		return ret1;
	}
	set_revision(param) {
		this._updatePerformed.handlers.invoke({"revision": param});
		this.__coco_revision.set(param);
		return param;
	}
	set_cache(param) {
		this._updatePerformed.handlers.invoke({"cache": param});
		this.__coco_cache.set(param);
		return param;
	}
	static get __name__() {
		return "coconut.ds.Updatable"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return Updatable
	}
}

