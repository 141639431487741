import {Transform} from "../../../tink/state/internal/Transform.js"
import {State} from "../../../tink/state/State.js"
import {Observable} from "../../../tink/state/Observable.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Promise as Promise__1} from "../../../tink/core/Promise.js"
import {Outcome} from "../../../tink/core/Outcome.js"
import {LazyConst} from "../../../tink/core/Lazy.js"
import {SyncFuture} from "../../../tink/core/Future.js"
import {Anon} from "../../../tink/Anon.js"
import {Register} from "../../../genes/Register.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const LanguageData = Register.global("$hxClasses")["dasloop.app.data.LanguageData"] = 
class LanguageData extends Register.inherits() {
	new() {
		this._updatePerformed = Signal.trigger();
		var _g = window.localStorage.getItem("language");
		this.__coco_code = State._new((_g == null) ? (window.location.hostname.indexOf(".cn.") != -1) ? "zh-cn" : "en" : _g, null, null, null);
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"code": this.__coco_code, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	setLanguage(v) {
		window.localStorage.setItem("language", v);
		return Promise__1.next(this.__cocoupdate(new SyncFuture(new LazyConst(Outcome.Success({"code": v})))), function (_) {
			return new SyncFuture(new LazyConst(Outcome.Success(null)));
		});
	}
	__cocoupdate(ret) {
		var _gthis = this;
		var sync = true;
		var done = false;
		ret.handle(function (o) {
			done = true;
			if (!sync) {
				_gthis.__coco_transitionCount.set(State.get_value(_gthis.__coco_transitionCount) - 1);
			};
			switch (o._hx_index) {
				case 0:
					var _g = o.data;
					if (Anon.getExistentFields(_g).code) {
						_gthis.__coco_code.set(_g.code);
					};
					_gthis._updatePerformed.handlers.invoke(_g);
					break
				case 1:
					_gthis.errorTrigger.handlers.invoke(o.failure);
					break
				
			};
		});
		if (!done) {
			sync = false;
		};
		if (!sync) {
			this.__coco_transitionCount.set(State.get_value(this.__coco_transitionCount) + 1);
		};
		return ret;
	}
	static get __name__() {
		return "dasloop.app.data.LanguageData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return LanguageData
	}
}

