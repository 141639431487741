import {Remote72} from "./Remote72.js"
import {Remote70} from "./Remote70.js"
import {Remote65} from "./Remote65.js"
import {RemoteBase, RemoteEndpoint, ResponseReader} from "./Remote.js"
import {Single} from "../../streams/Stream.js"
import {Parser3} from "../../json/Parser3.js"
import {HeaderField, HeaderValue} from "../../http/Header.js"
import {LazyConst} from "../../core/Lazy.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote7 = Register.global("$hxClasses")["tink.web.proxy.Remote7"] = 
class Remote7 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	role() {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["role"], "query": [], "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser3())));
	}
	devices() {
		return new Remote72(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["devices"], "query": [], "headers": [].concat([])}));
	}
	areas() {
		return new Remote70(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["areas"], "query": [], "headers": [].concat([])}));
	}
	stats() {
		return new Remote65(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["stats"], "query": [], "headers": [].concat([])}));
	}
	static get __name__() {
		return "tink.web.proxy.Remote7"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote7
	}
}

