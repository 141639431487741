import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {PromisedTools} from "../../../tink/state/Promised.js"
import {Observable, ConstObservable} from "../../../tink/state/Observable.js"
import {Vector} from "../../../tink/pure/Vector.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Promise as Promise__1} from "../../../tink/core/Promise.js"
import {Outcome} from "../../../tink/core/Outcome.js"
import {LazyConst} from "../../../tink/core/Lazy.js"
import {SyncFuture} from "../../../tink/core/Future.js"
import {Option} from "../../../haxe/ds/Option.js"
import {Register} from "../../../genes/Register.js"
import {EquipmentgroupData} from "./EquipmentgroupData.js"
import {StringCollection} from "../../../coconut/ds/Collection.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const EquipmentgroupsData = Register.global("$hxClasses")["dasloop.app.data.EquipmentgroupsData"] = 
class EquipmentgroupsData extends Register.inherits() {
	new(__coco_init) {
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return PromisedTools.map(PromisedTools.flatMap(Observable.get_value(_gthis.collection.__coco_list), function (list) {
				var result = new Array(list.length);
				var _g = 0;
				var _g1 = list.length;
				while (_g < _g1) {
					var i = _g++;
					result[i] = Observable.get_value(list[i].__coco_data);
				};
				return PromisedTools.all(result);
			}), Vector.fromArray);
		}), null);
		AutoObservable.cur = before;
		this.__coco_info = ret;
		this.project = __coco_init.project;
		this.remote = __coco_init.remote;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new StringCollection({"fetch": function () {
			return _gthis.remote.v2().projects().ofId(_gthis.project).equipmentgroups().list();
		}, "extractKey": function (raw) {
			return raw.id;
		}, "createItem": function (id, raw) {
			return new EquipmentgroupData({"id": id, "project": _gthis.project, "remote": _gthis.remote});
		}, "updateItem": function (data, raw) {
			data._data.refresh(Option.Some(raw));
			data.set_revision(State.get_value(data.__coco_revision) + 1);
		}});
		AutoObservable.cur = before;
		this.collection = ret;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"project": new ConstObservable(this.project), "remote": new ConstObservable(this.remote), "collection": new ConstObservable(this.collection), "info": this.__coco_info, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	create(name, contacts) {
		return Promise__1.noise(this.remote.v2().projects().ofId(this.project).equipmentgroups().create({"name": name, "contacts": contacts.slice()}));
	}
	getEquipmentgroupEquipments() {
		var api = this.remote.v2().projects().ofId(this.project).equipmentgroups();
		return Promise__1.next(api.list(), function (groups) {
			var _g = [];
			var _g1 = 0;
			var _g2 = groups;
			while (_g1 < _g2.length) _g.push(api.ofId(_g2[_g1++].id).equipments().list());
			return Promise__1.next(Promise__1.inParallel(_g), function (gpsEqs) {
				var _g = [];
				var _g4_current = 0;
				var _g4_array = groups;
				while (_g4_current < _g4_array.length) {
					var _g5_value = _g4_array[_g4_current];
					var group = _g5_value.id;
					var group1 = _g5_value.name;
					var group2 = _g5_value.isDefault;
					var _g1 = [];
					var _g2 = 0;
					var _g3 = gpsEqs[_g4_current++];
					while (_g2 < _g3.length) {
						var equipment = _g3[_g2];
						++_g2;
						_g1.push({"id": equipment.id, "name": equipment.name});
					};
					_g.push({"id": group, "name": group1, "isDefault": group2, "equipments": _g1});
				};
				return new SyncFuture(new LazyConst(Outcome.Success(_g)));
			});
		});
	}
	static get __name__() {
		return "dasloop.app.data.EquipmentgroupsData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return EquipmentgroupsData
	}
}

