import {RemoteBase, RemoteEndpoint, ResponseReader} from "./Remote.js"
import {Single} from "../../streams/Stream.js"
import {Builder21} from "../../querystring/Builder21.js"
import {Writer40} from "../../json/Writer40.js"
import {Parser55} from "../../json/Parser55.js"
import {IncomingResponse} from "../../http/Response.js"
import {HeaderField, HeaderValue} from "../../http/Header.js"
import {Outcome} from "../../core/Outcome.js"
import {LazyConst} from "../../core/Lazy.js"
import {SyncFuture} from "../../core/Future.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote68 = Register.global("$hxClasses")["tink.web.proxy.Remote68"] = 
class Remote68 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	preview(query) {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["preview"], "query": [].concat(new Builder21().stringify({"_242": query.language, "_243": query.text})), "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength()))].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), function (header, body) {
			return new SyncFuture(new LazyConst(Outcome.Success(new IncomingResponse(header, body))));
		});
	}
	synthesize(body) {
		var __body__ = ByteChunk.of(Bytes.ofString(new Writer40().wc({"_244": body.language, "_245": body.name, "_246": body.text})));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [], "headers": [new HeaderField("content-type", "application/json"), new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "POST", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser55())));
	}
	static get __name__() {
		return "tink.web.proxy.Remote68"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote68
	}
}

