import {BasicParser, JsonString} from "./Parser.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Parser29 = Register.global("$hxClasses")["tink.json.Parser29"] = 
class Parser29 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	pb(source) {
		if (this.e.length > 0) {
			this.e = [];
		};
		this.g(source);
		var ret = JsonString.i(this.r());
		var _g = 0;
		var _g1 = this.e;
		while (_g < _g1.length) _g1[_g++]();
		if (this.e.length > 0) {
			this.e = [];
		};
		return ret;
	}
	static get __name__() {
		return "tink.json.Parser29"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser29
	}
}

