import {Source} from "./Source.js"
import {StringMap} from "../../haxe/ds/StringMap.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const CachedSource = Register.global("$hxClasses")["turnwing.source.CachedSource"] = 
class CachedSource extends Register.inherits() {
	new(getKey, source, cache) {
		this.getKey = getKey;
		this.source = source;
		this.cache = (cache == null) ? new StringMap() : cache;
	}
	fetch(language) {
		var key = this.getKey(language);
		var _g = this.cache.inst.get(key);
		if (_g == null) {
			var this1 = this.cache;
			var v = this.source.fetch(language);
			this1.inst.set(key, v);
			return v;
		} else {
			return _g;
		};
	}
	static get __name__() {
		return "turnwing.source.CachedSource"
	}
	static get __interfaces__() {
		return [Source]
	}
	get __class__() {
		return CachedSource
	}
}

