import {BasicWriter} from "./Writer.js"
import {Base64} from "../../haxe/crypto/Base64.js"
import {Register} from "../../genes/Register.js"
import {EntryValueJson} from "../../dasloop/types/firmware/config/Schema.js"

const $global = Register.$global

export const Writer39 = Register.global("$hxClasses")["tink.json.Writer39"] = 
class Writer39 extends Register.inherits(BasicWriter) {
	new() {
		super.new();
	}
	wb(value) {
		this.f("{");
		var value1 = value._236;
		this.e("\"expiry\":");
		this.h(value1.getTime());
		var value1 = value._237;
		this.f(",");
		this.e("\"force\":");
		this.i(value1);
		var value1 = value._238;
		this.f(",");
		this.e("\"name\":");
		this.j(value1);
		var value1 = value._239;
		this.f(",");
		this.e("\"priority\":");
		this.g(value1);
		var value1 = value._240;
		this.f(",");
		this.e("\"sets\":");
		this.f("[");
		var first = true;
		var _g = 0;
		while (_g < value1.length) {
			var value2 = value1[_g++];
			if (first) {
				first = false;
			} else {
				this.f(",");
			};
			this.wc(value2);
		};
		this.f("]");
		var value1 = value._241;
		this.f(",");
		this.e("\"timezone\":");
		this.h(value1 / 60);
		this.f("}");
	}
	wc(value) {
		this.f("{");
		var value1 = value.devices;
		this.e("\"devices\":");
		this.f("[");
		var first = true;
		var _g = 0;
		while (_g < value1.length) {
			var value2 = value1[_g++];
			if (first) {
				first = false;
			} else {
				this.f(",");
			};
			this.j(value2);
		};
		this.f("]");
		var value1 = value.task;
		this.f(",");
		this.e("\"task\":");
		this.wd(value1);
		this.f("}");
	}
	wd(value) {
		switch (value._hx_index) {
			case 0:
				this.e("{\"UpdateFirmware\":{");
				this.e("\"params\"");
				this.f(":");
				this.we(value.params);
				this.e("}}");
				break
			case 1:
				this.e("{\"UpdateAudio\":{");
				this.e("\"params\"");
				this.f(":");
				this.wf(value.params);
				this.e("}}");
				break
			case 2:
				this.e("{\"UpdateConfig\":{");
				this.e("\"params\"");
				this.f(":");
				this.wg(value.params);
				this.e("}}");
				break
			case 3:
				this.e("{\"LocateDevice\":{");
				this.e("\"params\"");
				this.f(":");
				this.wl(value.params);
				this.e("}}");
				break
			case 4:
				this.e("{\"PlayAudio\":{");
				this.e("\"params\"");
				this.f(":");
				this.wm(value.params);
				this.e("}}");
				break
			case 5:
				this.e("{\"GenerateAlarm\":{");
				this.e("\"params\"");
				this.f(":");
				this.wn(value.params);
				this.e("}}");
				break
			case 6:
				this.e("\"GetConfig\"");
				break
			case 7:
				this.e("\"GetHardwareReport\"");
				break
			case 8:
				this.e("\"GetAudio\"");
				break
			
		};
	}
	we(value) {
		this.f("{");
		var value1 = value.firmwareVersion;
		this.e("\"firmwareVersion\":");
		this.j(value1);
		this.f("}");
	}
	wf(value) {
		this.f("{");
		var value1 = value.tracks;
		this.e("\"tracks\":");
		var value = value1;
		this.f("[");
		var first = true;
		var _g = 0;
		while (_g < value.length) {
			var value1 = value[_g++];
			if (first) {
				first = false;
			} else {
				this.f(",");
			};
			this.g(value1);
		};
		this.f("]");
		this.f("}");
	}
	wg(value) {
		this.f("{");
		var value1 = value.entries;
		this.e("\"entries\":");
		var value2 = value1;
		this.f("[");
		var first = true;
		var _g = 0;
		while (_g < value2.length) {
			var value1 = value2[_g++];
			if (first) {
				first = false;
			} else {
				this.f(",");
			};
			this.wh(value1);
		};
		this.f("]");
		var value1 = value.reset;
		this.f(",");
		this.e("\"reset\":");
		this.i(value1);
		var _g = value.schemaVersion;
		if (_g != null) {
			this.f(",");
			this.e("\"schemaVersion\":");
			if (_g == null) {
				this.e("null");
			} else {
				this.j(_g);
			};
		};
		this.f("}");
	}
	wh(value) {
		this.f("{");
		var value1 = value.name;
		this.e("\"name\":");
		this.wi(value1);
		var value1 = value.value;
		this.f(",");
		this.e("\"value\":");
		this.wj(EntryValueJson.wrap(value1));
		this.f("}");
	}
	wi(value) {
		switch (value._hx_index) {
			case 0:
				this.e("\"SecurityKey\"");
				break
			case 1:
				this.e("\"SerialNumber\"");
				break
			case 2:
				this.e("\"HardwareVersion\"");
				break
			case 3:
				this.e("\"BatteryVoltageOffset\"");
				break
			case 4:
				this.e("\"HsiTrimmingValue\"");
				break
			case 5:
				this.e("\"MsiTrimmingValue\"");
				break
			case 6:
				this.e("\"PressureOffset\"");
				break
			case 7:
				this.e("\"HumidityOffset\"");
				break
			case 8:
				this.e("\"ReservedSection1\"");
				break
			case 9:
				this.e("\"OperationFlags\"");
				break
			case 10:
				this.e("\"GeneralReportPeriod\"");
				break
			case 11:
				this.e("\"GeneralReportPeriodSleepArea\"");
				break
			case 12:
				this.e("\"BeaconsScanPeriod\"");
				break
			case 13:
				this.e("\"HeartRateReadPeriod\"");
				break
			case 14:
				this.e("\"BodyTemperatureReadPeriod\"");
				break
			case 15:
				this.e("\"PressureReadPeriod\"");
				break
			case 16:
				this.e("\"HumidityReadPeriod\"");
				break
			case 17:
				this.e("\"BeaconsScanTimeout\"");
				break
			case 18:
				this.e("\"AccelerometerLogFrequency\"");
				break
			case 19:
				this.e("\"StationaryAlertInterval\"");
				break
			case 20:
				this.e("\"StationaryLocalAlertInterval\"");
				break
			case 21:
				this.e("\"StationaryOffInterval\"");
				break
			case 22:
				this.e("\"SensorRemoteAlertInterval\"");
				break
			case 23:
				this.e("\"SensorLocalAlertInterval\"");
				break
			case 24:
				this.e("\"BatteryDetectInterval\"");
				break
			case 25:
				this.e("\"LowBatteryThresholdHi\"");
				break
			case 26:
				this.e("\"LowBatteryThresholdLo\"");
				break
			case 27:
				this.e("\"PositionLogPeriod\"");
				break
			case 28:
				this.e("\"SensorsLogPeriod\"");
				break
			case 29:
				this.e("\"CommSignalLogPeriod\"");
				break
			case 30:
				this.e("\"BuzzerFrequency\"");
				break
			case 31:
				this.e("\"BuzzerVolumeLevel\"");
				break
			case 32:
				this.e("\"LedBrightless\"");
				break
			case 33:
				this.e("\"PutOffBuzzerAlertInterval\"");
				break
			case 34:
				this.e("\"BeaconSignalOffset\"");
				break
			case 35:
				this.e("\"DetectPutOffByHrDelay\"");
				break
			case 36:
				this.e("\"LowBatteryThresholdOffFanLed\"");
				break
			case 37:
				this.e("\"BeaconOfRecessArea\"");
				break
			case 38:
				this.e("\"BeaconOfSleepArea\"");
				break
			case 39:
				this.e("\"TokensForDisablingStationaryAlerts\"");
				break
			case 40:
				this.e("\"TokenActivePeriod\"");
				break
			case 41:
				this.e("\"TokensRecoverTime\"");
				break
			case 42:
				this.e("\"DeviceLocked\"");
				break
			case 43:
				this.e("\"BeaconOfMaintenanceArea\"");
				break
			case 44:
				this.e("\"ChargingMaintenanceTimeout\"");
				break
			case 45:
				this.e("\"NetworkReportPeriod\"");
				break
			case 46:
				this.e("\"NetworkReportPeriodSleepArea\"");
				break
			case 47:
				this.e("\"BleAoaPacketInterval\"");
				break
			case 48:
				this.e("\"BleAoaPacketTxPower\"");
				break
			case 49:
				this.e("\"BleAdvertiseInterval\"");
				break
			case 50:
				this.e("\"BleFlags\"");
				break
			case 51:
				this.e("\"OperationFlags2\"");
				break
			case 52:
				this.e("\"ShortReportPeriod\"");
				break
			case 53:
				this.e("\"ShortReportPeriodSleepArea\"");
				break
			case 54:
				this.e("\"ReservedSection2\"");
				break
			case 55:
				this.e("\"HeartRateHighFilterTime\"");
				break
			case 56:
				this.e("\"HeartRateHighThreshold\"");
				break
			case 57:
				this.e("\"HeartRateLowThreshold\"");
				break
			case 58:
				this.e("\"HeartRateLowFilterTime\"");
				break
			case 59:
				this.e("\"BodyTemperatureFilterTime\"");
				break
			case 60:
				this.e("\"BodyTemperatureLowFilterTime\"");
				break
			case 61:
				this.e("\"BodyTemperatureHighFilterTime\"");
				break
			case 62:
				this.e("\"BodyTemperatureLowThreshold\"");
				break
			case 63:
				this.e("\"BodyTemperatureHighThreshold\"");
				break
			case 64:
				this.e("\"HeartRateQualityThreshold\"");
				break
			case 65:
				this.e("\"StationaryDetectThreshold\"");
				break
			case 66:
				this.e("\"WakeupDetectThreshold\"");
				break
			case 67:
				this.e("\"FreeFallDetectThreshold\"");
				break
			case 68:
				this.e("\"FreeFallDetectDuration\"");
				break
			case 69:
				this.e("\"HeatStressAlertThreshold\"");
				break
			case 70:
				this.e("\"WorkaroundBenchmarkQualityThres\"");
				break
			case 71:
				this.e("\"WorkaroundBenchmarkHRThres\"");
				break
			case 72:
				this.e("\"WorkaroundBenchmarkCheckInterval\"");
				break
			case 73:
				this.e("\"WorkaroundBenchmarkRecordCount\"");
				break
			case 74:
				this.e("\"WorkaroundBenchmarkFalseLimit\"");
				break
			case 75:
				this.e("\"ReservedSection3\"");
				break
			case 76:
				this.e("\"ReservedSection3_2\"");
				break
			case 77:
				this.e("\"WiFiApSsid\"");
				break
			case 78:
				this.e("\"WiFiApPassword\"");
				break
			case 79:
				this.e("\"Apn\"");
				break
			case 80:
				this.e("\"ApnUserName\"");
				break
			case 81:
				this.e("\"ApnPassword\"");
				break
			case 82:
				this.e("\"ServerDomainName\"");
				break
			case 83:
				this.e("\"ServerIpAddress\"");
				break
			case 84:
				this.e("\"ServerDomainNamePort\"");
				break
			case 85:
				this.e("\"ServerPort\"");
				break
			case 86:
				this.e("\"WiFiServerDomainName\"");
				break
			case 87:
				this.e("\"WiFiServerIpAddress\"");
				break
			case 88:
				this.e("\"WiFiServerDomainNamePort\"");
				break
			case 89:
				this.e("\"WiFiServerPort\"");
				break
			case 90:
				this.e("\"CommunicationGuardInterval\"");
				break
			case 91:
				this.e("\"CommunicationBand\"");
				break
			case 92:
				this.e("\"NbIotPsmRequestedPeriodicTau\"");
				break
			case 93:
				this.e("\"NbIotPsmRequestedActiveTime\"");
				break
			case 94:
				this.e("\"NbIotEdrxValue\"");
				break
			case 95:
				this.e("\"NbIotNetworkWaitTime\"");
				break
			case 96:
				this.e("\"NbIotServerWaitTime\"");
				break
			case 97:
				this.e("\"NbIotMinimumSignal\"");
				break
			case 98:
				this.e("\"WiFiServerWaitTime\"");
				break
			case 99:
				this.e("\"WiFiConnectApPeriod\"");
				break
			case 100:
				this.e("\"WiFiConnectApTimeout\"");
				break
			case 101:
				this.e("\"LoraAppKey\"");
				break
			case 102:
				this.e("\"LoraAppEui\"");
				break
			case 103:
				this.e("\"LoraNetworkWaitTime\"");
				break
			case 104:
				this.e("\"LoraServerWaitTime\"");
				break
			case 105:
				this.e("\"LoraDataRateTxPower\"");
				break
			case 106:
				this.e("\"LoraCommunicationBand\"");
				break
			case 107:
				this.e("\"LoraPingSlot\"");
				break
			case 108:
				this.e("\"CommunicationFlags\"");
				break
			case 109:
				this.e("\"ReservedSection4\"");
				break
			case 110:
				this.e("\"GpsFlags\"");
				break
			case 111:
				this.e("\"AgpsFeatures\"");
				break
			case 112:
				this.e("\"GpsFixCheckPeriod\"");
				break
			case 113:
				this.e("\"GpsOnDuration\"");
				break
			case 114:
				this.e("\"GpsNoFixOffDuration\"");
				break
			case 115:
				this.e("\"GpsFixCheckPeriodSleepArea\"");
				break
			case 116:
				this.e("\"GpsOnDurationSleepArea\"");
				break
			case 117:
				this.e("\"GpsNoFixOffDurationSleepArea\"");
				break
			case 118:
				this.e("\"ReservedSection5\"");
				break
			case 119:
				this.e("\"ReservedSection5_2\"");
				break
			case 120:
				this.e("\"Geofence\"");
				break
			
		};
	}
	wj(value) {
		switch (value._hx_index) {
			case 0:
				this.e("{\"JInteger\":{");
				this.e("\"type\"");
				this.f(":");
				this.wk(value.type);
				this.e(",\"v\"");
				this.f(":");
				this.g(value.v);
				this.e("}}");
				break
			case 1:
				this.e("{\"JText\":{");
				this.e("\"type\"");
				this.f(":");
				this.wk(value.type);
				this.e(",\"v\"");
				this.f(":");
				this.j(value.v);
				this.e("}}");
				break
			case 2:
				this.e("{\"JBinary\":{");
				this.e("\"type\"");
				this.f(":");
				this.wk(value.type);
				this.e(",\"v\"");
				this.f(":");
				this.j(Base64.encode(value.v.toBytes()));
				this.e("}}");
				break
			case 3:
				this.e("{\"JSequence\":{");
				this.e("\"type\"");
				this.f(":");
				this.wk(value.type);
				this.e(",\"size\"");
				this.f(":");
				this.g(value.size);
				this.e(",\"v\"");
				this.f(":");
				var value1 = value.v;
				this.f("[");
				var first = true;
				var _g = 0;
				while (_g < value1.length) {
					var value = value1[_g++];
					if (first) {
						first = false;
					} else {
						this.f(",");
					};
					this.wj(value);
				};
				this.f("]");
				this.e("}}");
				break
			
		};
	}
	wk(value) {
		switch (value._hx_index) {
			case 0:
				this.e("\"JInt8\"");
				break
			case 1:
				this.e("\"JUInt8\"");
				break
			case 2:
				this.e("\"JUInt16\"");
				break
			case 3:
				this.e("\"JInt16\"");
				break
			case 4:
				this.e("{\"JChar\":{");
				this.e("\"size\"");
				this.f(":");
				this.g(value.size);
				this.e("}}");
				break
			case 5:
				this.e("{\"JByte\":{");
				this.e("\"size\"");
				this.f(":");
				this.g(value.size);
				this.e("}}");
				break
			case 6:
				this.e("{\"JArray\":{");
				this.e("\"type\"");
				this.f(":");
				this.wk(value.type);
				this.e(",\"size\"");
				this.f(":");
				this.g(value.size);
				this.e("}}");
				break
			
		};
	}
	wl(value) {
		this.f("{");
		var value1 = value.buzzer;
		this.e("\"buzzer\":");
		this.i(value1);
		var value1 = value.led;
		this.f(",");
		this.e("\"led\":");
		this.i(value1);
		var value1 = value.vibrator;
		this.f(",");
		this.e("\"vibrator\":");
		this.i(value1);
		this.f("}");
	}
	wm(value) {
		this.f("{");
		var value1 = value.delay;
		this.e("\"delay\":");
		this.g(value1);
		var value1 = value.id;
		this.f(",");
		this.e("\"id\":");
		this.g(value1);
		var value1 = value.repeats;
		this.f(",");
		this.e("\"repeats\":");
		this.g(value1);
		this.f("}");
	}
	wn(value) {
		this.f("{");
		var value1 = value.duration;
		this.e("\"duration\":");
		this.g(value1);
		var value1 = value.light;
		this.f(",");
		this.e("\"light\":");
		this.i(value1);
		var value1 = value.sound;
		this.f(",");
		this.e("\"sound\":");
		this.i(value1);
		this.f("}");
	}
	wo(value) {
		this.d();
		this.wb(value);
		return this.b.toString();
	}
	static get __name__() {
		return "tink.json.Writer39"
	}
	static get __super__() {
		return BasicWriter
	}
	get __class__() {
		return Writer39
	}
}

