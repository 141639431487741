import {Revision} from "./Revision.js"
import {OwnedDisposable} from "../../core/Disposable.js"
import {CallbackList, ListCell, Callback, LinkPair, SimpleLink} from "../../core/Callback.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Invalidator = Register.global("$hxClasses")["tink.state.internal.Invalidator"] = 
class Invalidator extends Register.inherits() {
	new() {
		this.list = new CallbackList();
		this.observers = new Map();
		this.revision = Revision._new();
	}
	canFire() {
		return this.list.disposeHandlers != null;
	}
	getRevision() {
		return this.revision;
	}
	onInvalidate(i) {
		var _gthis = this;
		if (this.observers.get(i) || this.list.disposeHandlers == null) {
			return null;
		} else {
			this.observers.set(i, true);
			var _this = this.list;
			var this1;
			if (_this.disposeHandlers == null) {
				this1 = null;
			} else {
				var node = new ListCell(function (_) {
					i.invalidate();
				}, _this);
				_this.cells.push(node);
				if (_this.used++ == 0) {
					var fn = _this.onfill;
					if (Callback.depth < 500) {
						Callback.depth++;
						fn();
						Callback.depth--;
					} else {
						Callback.defer(fn);
					};
				};
				this1 = node;
			};
			return new LinkPair(this1, new SimpleLink(function () {
				return _gthis.observers["delete"](i);
			}));
		};
	}
	fire() {
		this.revision = Revision._new();
		this.list.invoke(null);
	}
	static get __name__() {
		return "tink.state.internal.Invalidator"
	}
	static get __interfaces__() {
		return [OwnedDisposable]
	}
	get __class__() {
		return Invalidator
	}
}


export const Invalidatable = function() {};
Invalidatable.__isInterface__ = true;
