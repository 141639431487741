import {RemoteBase, RemoteEndpoint, ResponseReader} from "./Remote.js"
import {Single} from "../../streams/Stream.js"
import {Builder26} from "../../querystring/Builder26.js"
import {Builder25} from "../../querystring/Builder25.js"
import {Builder24} from "../../querystring/Builder24.js"
import {Builder23} from "../../querystring/Builder23.js"
import {Builder22} from "../../querystring/Builder22.js"
import {Parser60} from "../../json/Parser60.js"
import {Parser59} from "../../json/Parser59.js"
import {Parser58} from "../../json/Parser58.js"
import {Parser57} from "../../json/Parser57.js"
import {Parser56} from "../../json/Parser56.js"
import {HeaderField, HeaderValue} from "../../http/Header.js"
import {NamedWith} from "../../core/Named.js"
import {LazyConst} from "../../core/Lazy.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote69 = Register.global("$hxClasses")["tink.web.proxy.Remote69"] = 
class Remote69 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	getEnergyByEquipment(query) {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["energy"], "query": [new NamedWith("by", "equipment")].concat(new Builder24().stringify({"_247": query.from, "_248": query.resolution, "_249": query.timezone, "_250": query.to})), "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pc))(new Parser58())));
	}
	getEnergyByTime(query) {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["energy"], "query": [new NamedWith("by", "time")].concat(new Builder25().stringify({"_251": query.from, "_252": query.resolution, "_253": query.timezone, "_254": query.to})), "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pc))(new Parser59())));
	}
	getEnergy(query) {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["energy"], "query": [].concat(new Builder22().stringify({"_255": query.from, "_256": query.resolution, "_257": query.timezone, "_258": query.to})), "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pc))(new Parser56())));
	}
	getTemperatureByEquipment(query) {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["temperature"], "query": [new NamedWith("by", "equipment")].concat(new Builder26().stringify({"_259": query.from, "_260": query.resolution, "_261": query.timezone, "_262": query.to})), "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pc))(new Parser60())));
	}
	getTemperature(query) {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["temperature"], "query": [].concat(new Builder23().stringify({"_263": query.from, "_264": query.resolution, "_265": query.timezone, "_266": query.to})), "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pc))(new Parser57())));
	}
	static get __name__() {
		return "tink.web.proxy.Remote69"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote69
	}
}

