import {ConstObservable, Observable} from "../../../tink/state/Observable.js"
import {ClassName} from "../../../tink/domspec/ClassName.js"
import {Register} from "../../../genes/Register.js"
import {Slot} from "../../../coconut/ui/internal/Slot.js"
import {View} from "../../../coconut/react/View.js"
import {Html} from "../../../coconut/react/Html.js"

const $global = Register.$global

export const Spinner = Register.global("$hxClasses")["dasloop.ui.general.Spinner"] = 
class Spinner extends Register.inherits(View) {
	new(__coco_data_) {
		var _gthis = this;
		this.__coco_inverted = new Slot(this, null, new ConstObservable(false));
		this.__coco_size = new Slot(this, null, new ConstObservable("small"));
		this.__initAttributes(__coco_data_);
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	__coco_render() {
		return Html.h("div", {"className": ClassName.ofString("ui active " + Observable.get_value(this.__coco_size) + " centered inline loader"), "style": (Observable.get_value(this.__coco_inverted)) ? {"color": "white"} : null}, null);
	}
	__initAttributes(attributes) {
		this.__coco_inverted.setData(attributes.inverted);
		this.__coco_size.setData(attributes.size);
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": Spinner, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "dasloop.ui.general.Spinner"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return Spinner
	}
}

