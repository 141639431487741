import {Client} from "../Client.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Outcome} from "../../../tink/core/Outcome.js"
import {LazyConst} from "../../../tink/core/Lazy.js"
import {SyncFuture} from "../../../tink/core/Future.js"
import {TypedError} from "../../../tink/core/Error.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const BaseClient = Register.global("$hxClasses")["why.mqtt.client.BaseClient"] = 
class BaseClient extends Register.inherits() {
	new() {
		this.messageReceived = this.messageReceivedTrigger = Signal.trigger();
		this.disconnected = this.disconnectedTrigger = Signal.trigger();
	}
	connect() {
		if (this.get_active()) {
			return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(409, "Already attempted to connect", {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/why-mqtt/0.0.0/github/faa1984e85120a0680809ae8cb093b0000e303a4/src/why/mqtt/client/BaseClient.hx", "lineNumber": 21, "className": "why.mqtt.client.BaseClient", "methodName": "connect"}))));
		} else {
			return this.doConnect();
		};
	}
	subscribe(topic, options) {
		if (!this.get_active()) {
			return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(null, "Client not active, call connect() first", {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/why-mqtt/0.0.0/github/faa1984e85120a0680809ae8cb093b0000e303a4/src/why/mqtt/client/BaseClient.hx", "lineNumber": 29, "className": "why.mqtt.client.BaseClient", "methodName": "subscribe"}))));
		} else {
			return this.doSubscribe(topic, options);
		};
	}
	static get __name__() {
		return "why.mqtt.client.BaseClient"
	}
	static get __interfaces__() {
		return [Client]
	}
	get __class__() {
		return BaseClient
	}
}

