import {Observable} from "../../../tink/state/Observable.js"
import {Register} from "../../../genes/Register.js"
import {Slot} from "../../../coconut/ui/internal/Slot.js"
import {View} from "../../../coconut/react/View.js"
import {Html} from "../../../coconut/react/Html.js"

const $global = Register.$global

export const ProjectExpiryGuard = Register.global("$hxClasses")["dasloop.app.ui.ProjectExpiryGuard"] = 
class ProjectExpiryGuard extends Register.inherits(View) {
	new(__coco_data_) {
		var _gthis = this;
		this.__coco_localizer = new Slot(this, null, null);
		this.__coco_project = new Slot(this, null, null);
		this.__coco_children = new Slot(this, null, null);
		this.__initAttributes(__coco_data_);
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	__coco_render() {
		var __r = [];
		switch (Observable.get_value(Observable.get_value(this.__coco_project).__coco_data)._hx_index) {
			case 0:
				__r.push(Html.h("div", {"className": "ui active centered inline loader"}, null));
				break
			case 1:
				var _g = 0;
				var _g1 = Observable.get_value(this.__coco_children);
				while (_g < ((_g1 == null) ? 0 : _g1.length)) {
					var _0 = (_g1 == null) ? null : _g1[_g];
					++_g;
					__r.push(_0);
				};
				break
			case 2:
				break
			
		};
		return View.createFragment({}, __r);
	}
	__initAttributes(attributes) {
		this.__coco_localizer.setData(attributes.localizer);
		this.__coco_project.setData(attributes.project);
		this.__coco_children.setData(attributes.children);
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": ProjectExpiryGuard, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "dasloop.app.ui.ProjectExpiryGuard"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return ProjectExpiryGuard
	}
}

