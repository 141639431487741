import {Register} from "./genes/Register.js"

const $global = Register.$global

/**
The Reflect API is a way to manipulate values dynamically through an
abstract interface in an untyped manner. Use with care.

@see https://haxe.org/manual/std-reflection.html
*/
export const Reflect = Register.global("$hxClasses")["Reflect"] = 
class Reflect {
	
	/**
	Returns the value of the field named `field` on object `o`.
	
	If `o` is not an object or has no field named `field`, the result is
	null.
	
	If the field is defined as a property, its accessors are ignored. Refer
	to `Reflect.getProperty` for a function supporting property accessors.
	
	If `field` is null, the result is unspecified.
	*/
	static field(o, field) {
		try {
			return o[field];
		}catch (_g) {
			return null;
		};
	}
	
	/**
	Returns the value of the field named `field` on object `o`, taking
	property getter functions into account.
	
	If the field is not a property, this function behaves like
	`Reflect.field`, but might be slower.
	
	If `o` or `field` are null, the result is unspecified.
	*/
	static getProperty(o, field) {
		var tmp;
		if (o == null) {
			return null;
		} else {
			var tmp1;
			if (o.__properties__) {
				tmp = o.__properties__["get_" + field];
				tmp1 = tmp;
			} else {
				tmp1 = false;
			};
			if (tmp1) {
				return o[tmp]();
			} else {
				return o[field];
			};
		};
	}
	
	/**
	Sets the field named `field` of object `o` to value `value`, taking
	property setter functions into account.
	
	If the field is not a property, this function behaves like
	`Reflect.setField`, but might be slower.
	
	If `field` is null, the result is unspecified.
	*/
	static setProperty(o, field, value) {
		var tmp;
		var tmp1;
		if (o.__properties__) {
			tmp = o.__properties__["set_" + field];
			tmp1 = tmp;
		} else {
			tmp1 = false;
		};
		if (tmp1) {
			o[tmp](value);
		} else {
			o[field] = value;
		};
	}
	
	/**
	Returns the fields of structure `o`.
	
	This method is only guaranteed to work on anonymous structures. Refer to
	`Type.getInstanceFields` for a function supporting class instances.
	
	If `o` is null, the result is unspecified.
	*/
	static fields(o) {
		var a = [];
		if (o != null) {
			var hasOwnProperty = Object.prototype.hasOwnProperty;
			for( var f in o ) {;
			if (f != "__id__" && f != "hx__closures__" && hasOwnProperty.call(o, f)) {
				a.push(f);
			};
			};
		};
		return a;
	}
	
	/**
	Returns true if `f` is a function, false otherwise.
	
	If `f` is null, the result is false.
	*/
	static isFunction(f) {
		if (typeof(f) == "function") {
			return !(f.__name__ || f.__ename__);
		} else {
			return false;
		};
	}
	
	/**
	Compares `a` and `b`.
	
	If `a` is less than `b`, the result is negative. If `b` is less than
	`a`, the result is positive. If `a` and `b` are equal, the result is 0.
	
	This function is only defined if `a` and `b` are of the same type.
	
	If that type is a function, the result is unspecified and
	`Reflect.compareMethods` should be used instead.
	
	For all other types, the result is 0 if `a` and `b` are equal. If they
	are not equal, the result depends on the type and is negative if:
	
	- Numeric types: a is less than b
	- String: a is lexicographically less than b
	- Other: unspecified
	
	If `a` and `b` are null, the result is 0. If only one of them is null,
	the result is unspecified.
	*/
	static compare(a, b) {
		if (a == b) {
			return 0;
		} else if (a > b) {
			return 1;
		} else {
			return -1;
		};
	}
	
	/**
	Compares the functions `f1` and `f2`.
	
	If `f1` or `f2` are null, the result is false.
	If `f1` or `f2` are not functions, the result is unspecified.
	
	Otherwise the result is true if `f1` and the `f2` are physically equal,
	false otherwise.
	
	If `f1` or `f2` are member method closures, the result is true if they
	are closures of the same method on the same object value, false otherwise.
	*/
	static compareMethods(f1, f2) {
		if (f1 == f2) {
			return true;
		};
		if (!Reflect.isFunction(f1) || !Reflect.isFunction(f2)) {
			return false;
		};
		if (f1.scope == f2.scope && f1.method == f2.method) {
			return f1.method != null;
		} else {
			return false;
		};
	}
	
	/**
	Tells if `v` is an enum value.
	
	The result is true if `v` is of type EnumValue, i.e. an enum
	constructor.
	
	Otherwise, including if `v` is null, the result is false.
	*/
	static isEnumValue(v) {
		if (v != null) {
			return v.__enum__ != null;
		} else {
			return false;
		};
	}
	
	/**
	Copies the fields of structure `o`.
	
	This is only guaranteed to work on anonymous structures.
	
	If `o` is null, the result is `null`.
	*/
	static copy(o) {
		if (o == null) {
			return null;
		};
		var o2 = {};
		var _g = 0;
		var _g1 = Reflect.fields(o);
		while (_g < _g1.length) {
			var f = _g1[_g];
			++_g;
			o2[f] = Reflect.field(o, f);
		};
		return o2;
	}
	static get __name__() {
		return "Reflect"
	}
	get __class__() {
		return Reflect
	}
}

