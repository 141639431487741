import {Single} from "../../streams/Stream.js"
import {Worker} from "../../io/Worker.js"
import {IdealSourceTools} from "../../io/Source.js"
import {IncomingResponse, ResponseHeaderBase} from "../Response.js"
import {ClientObject} from "../Client.js"
import {Outcome} from "../../core/Outcome.js"
import {LazyConst, LazyFunc} from "../../core/Lazy.js"
import {Future} from "../../core/Future.js"
import {TypedError} from "../../core/Error.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Url} from "../../Url.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {HttpJs} from "../../../haxe/http/HttpJs.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const StdClient = Register.global("$hxClasses")["tink.http.clients.StdClient"] = 
class StdClient extends Register.inherits() {
	new(worker) {
		this.worker = Worker.ensure(worker);
	}
	request(req) {
		var _gthis = this;
		return Future.async(function (cb) {
			var r = new HttpJs(Url.toString(req.header.url));
			var send = function (post) {
				var code = 200;
				r.onStatus = function (c) {
					code = c;
				};
				var headers = function () {
					return [];
				};
				r.onError = function (msg) {
					if (code == 200) {
						code = 500;
					};
					Worker.work(_gthis.worker, new LazyConst(true)).handle(function () {
						cb(Outcome.Failure(new TypedError(code, msg, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_http/0.10.0/github/49088ee6bb9d69b1e0f57931a13e836132e3fc02/src/tink/http/clients/StdClient.hx", "lineNumber": 44, "className": "tink.http.clients.StdClient", "methodName": "request"})));
					});
				};
				r.onData = function (data) {
					Worker.work(_gthis.worker, new LazyConst(true)).handle(function () {
						var cb1 = cb;
						var fields = headers();
						cb1(Outcome.Success(new IncomingResponse(new ResponseHeaderBase(code, "OK", fields, "HTTP/1.1"), new Single(new LazyConst(ByteChunk.of(Bytes.ofString(data)))))));
					});
				};
				Worker.work(_gthis.worker, new LazyFunc(function () {
					r.request(post);
				}));
			};
			var _this = req.header.fields;
			var _g_current = 0;
			while (_g_current < _this.length) {
				var h = _this[_g_current++];
				r.setHeader(h.name, h.value);
			};
			switch (req.header.method) {
				case "GET":case "HEAD":case "OPTIONS":
					send(false);
					break
				default:
				IdealSourceTools.all(req.body).handle(function (bytes) {
					r.setPostData(bytes.toString());
					send(true);
				});
				
			};
		});
	}
	static get __name__() {
		return "tink.http.clients.StdClient"
	}
	static get __interfaces__() {
		return [ClientObject]
	}
	get __class__() {
		return StdClient
	}
}

