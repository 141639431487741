import {Promise as Promise__1} from "../core/Promise.js"
import {Outcome} from "../core/Outcome.js"
import {LazyConst} from "../core/Lazy.js"
import {SyncFuture} from "../core/Future.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const ClientObject = function() {};
ClientObject.__isInterface__ = true;

export const CustomClient = Register.global("$hxClasses")["tink.http._Client.CustomClient"] = 
class CustomClient extends Register.inherits() {
	new(preprocessors, postprocessors, real) {
		this.preprocessors = preprocessors;
		this.postprocessors = postprocessors;
		this.real = real;
	}
	pipe(value, transforms, index) {
		if (index == null) {
			index = 0;
		};
		if (transforms != null && index < transforms.length) {
			var _g = Register.bind(this, this.pipe);
			var transforms1 = transforms;
			var index1 = index + 1;
			var tmp = function (value) {
				return _g(value, transforms1, index1);
			};
			return Promise__1.next(transforms[index](value), tmp);
		} else {
			return new SyncFuture(new LazyConst(Outcome.Success(value)));
		};
	}
	request(req) {
		var _gthis = this;
		return Promise__1.next(this.pipe(req, this.preprocessors), function (req) {
			var tmp = _gthis.real.request(req);
			var _g = Register.bind(_gthis, _gthis.pipe);
			var transforms;
			if (_gthis.postprocessors == null) {
				transforms = null;
			} else {
				var _g1 = [];
				var _g2 = 0;
				var _g3 = _gthis.postprocessors;
				while (_g2 < _g3.length) _g1.push(_g3[_g2++](req));
				transforms = _g1;
			};
			return Promise__1.next(tmp, function (value) {
				return _g(value, transforms);
			});
		});
	}
	static concat(a, b) {
		if (a == null) {
			return b;
		} else if (b == null) {
			return a;
		} else {
			return a.concat(b);
		};
	}
	static create(c, preprocessors, postprocessors) {
		var _g = (((c) instanceof CustomClient)) ? c : null;
		if (_g == null) {
			return new CustomClient(preprocessors, postprocessors, c);
		} else {
			var v = _g;
			return new CustomClient(CustomClient.concat(preprocessors, v.preprocessors), CustomClient.concat(v.postprocessors, postprocessors), v.real);
		};
	}
	static get __name__() {
		return "tink.http._Client.CustomClient"
	}
	static get __interfaces__() {
		return [ClientObject]
	}
	get __class__() {
		return CustomClient
	}
}

