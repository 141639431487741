import {Outcome} from "../../tink/core/Outcome.js"
import {TypedError} from "../../tink/core/Error.js"
import {Register} from "../../genes/Register.js"
import {EReg} from "../../EReg.js"

const $global = Register.$global

export const UwbTagId = Register.global("$hxClasses")["dasloop.types._UwbTagId.UwbTagId"] = 
class UwbTagId {
	static make(v) {
		var v1 = v.toUpperCase();
		if (new EReg("^[A-F0-9]{6}$", "").match(v1)) {
			return Outcome.Success(v1);
		} else {
			return Outcome.Failure(new TypedError(null, "Expected 6 hex characters", {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/dasloop-types/0.0.0/git/02a6ec86579e2d19b4682cdd0da6ae12d74e26e2/src/dasloop/types/UwbTagId.hx", "lineNumber": 15, "className": "dasloop.types._UwbTagId.UwbTagId_Impl_", "methodName": "make"}));
		};
	}
	static get __name__() {
		return "dasloop.types._UwbTagId.UwbTagId_Impl_"
	}
	get __class__() {
		return UwbTagId
	}
}

