import {Register} from "../../genes/Register.js"

const $global = Register.$global

/**
Helper that converts between floating point and binary representation.
Always works in low-endian encoding.
*/
export const FPHelper = Register.global("$hxClasses")["haxe.io.FPHelper"] = 
class FPHelper {
	static i64ToDouble(low, high) {
		FPHelper.helper.setInt32(0, low, true);
		FPHelper.helper.setInt32(4, high, true);
		return FPHelper.helper.getFloat64(0, true);
	}
	static get __name__() {
		return "haxe.io.FPHelper"
	}
	get __class__() {
		return FPHelper
	}
}


FPHelper.helper = new DataView(new ArrayBuffer(8))