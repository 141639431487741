import {FluentLocaleBase} from "./FluentProvider.js"
import {Register} from "../../genes/Register.js"
import {ProjectExpiryGuardLocalizer} from "../../dasloop/app/localizer/ProjectExpiryGuardLocalizer.js"

const $global = Register.$global

export const FluentLocale127 = Register.global("$hxClasses")["turnwing.provider.FluentLocale127"] = 
class FluentLocale127 extends Register.inherits(FluentLocaleBase) {
	new(__bundle__, __prefix__) {
		super.new(__bundle__, __prefix__);
	}
	static get __name__() {
		return "turnwing.provider.FluentLocale127"
	}
	static get __interfaces__() {
		return [ProjectExpiryGuardLocalizer]
	}
	static get __super__() {
		return FluentLocaleBase
	}
	get __class__() {
		return FluentLocale127
	}
}

