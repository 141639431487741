import {Remote56} from "./Remote56.js"
import {RemoteBase, RemoteEndpoint, ResponseReader} from "./Remote.js"
import {Single} from "../../streams/Stream.js"
import {Builder29} from "../../querystring/Builder29.js"
import {Writer31} from "../../json/Writer31.js"
import {Parser43} from "../../json/Parser43.js"
import {Parser24} from "../../json/Parser24.js"
import {HeaderField, HeaderValue} from "../../http/Header.js"
import {LazyConst} from "../../core/Lazy.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote55 = Register.global("$hxClasses")["tink.web.proxy.Remote55"] = 
class Remote55 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	places() {
		return new Remote56(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["places"], "query": [], "headers": [].concat([])}));
	}
	get() {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [], "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser43())));
	}
	uploadModel(mime) {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["model"], "query": [].concat(new Builder29().stringify({"_208": mime})), "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "PUT", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser24())));
	}
	update(body) {
		var __body__ = ByteChunk.of(Bytes.ofString(new Writer31().wc({"_209": body.center, "_210": body.name, "_211": body.rotation, "_212": body.scale, "_213": body.type})));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [], "headers": [new HeaderField("content-type", "application/json"), new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "PUT", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser43())));
	}
	static get __name__() {
		return "tink.web.proxy.Remote55"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote55
	}
}

