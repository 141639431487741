import {Remote76} from "./Remote76.js"
import {Remote75} from "./Remote75.js"
import {Remote74} from "./Remote74.js"
import {Remote61} from "./Remote61.js"
import {Remote58} from "./Remote58.js"
import {Remote54} from "./Remote54.js"
import {Remote51} from "./Remote51.js"
import {Remote5} from "./Remote5.js"
import {Remote48} from "./Remote48.js"
import {Remote45} from "./Remote45.js"
import {Remote40} from "./Remote40.js"
import {Remote3} from "./Remote3.js"
import {Remote28} from "./Remote28.js"
import {Remote23} from "./Remote23.js"
import {Remote16} from "./Remote16.js"
import {Remote14} from "./Remote14.js"
import {Remote10} from "./Remote10.js"
import {RemoteBase, RemoteEndpoint} from "./Remote.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote2 = Register.global("$hxClasses")["tink.web.proxy.Remote2"] = 
class Remote2 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	me() {
		return new Remote10(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["me"], "query": [], "headers": [].concat([])}));
	}
	areas() {
		return new Remote45(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["areas"], "query": [], "headers": [].concat([])}));
	}
	beacons() {
		return new Remote48(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["beacons"], "query": [], "headers": [].concat([])}));
	}
	bims() {
		return new Remote54(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["bims"], "query": [], "headers": [].concat([])}));
	}
	devices() {
		return new Remote40(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["devices"], "query": [], "headers": [].concat([])}));
	}
	equipments() {
		return new Remote23(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["equipments"], "query": [], "headers": [].concat([])}));
	}
	floorplans() {
		return new Remote51(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["floorplans"], "query": [], "headers": [].concat([])}));
	}
	plants() {
		return new Remote14(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["plants"], "query": [], "headers": [].concat([])}));
	}
	projects() {
		return new Remote5(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["projects"], "query": [], "headers": [].concat([])}));
	}
	taskgroups() {
		return new Remote58(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["taskgroups"], "query": [], "headers": [].concat([])}));
	}
	tasks() {
		return new Remote61(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["tasks"], "query": [], "headers": [].concat([])}));
	}
	weather() {
		return new Remote74(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["weather"], "query": [], "headers": [].concat([])}));
	}
	news() {
		return new Remote75(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["news"], "query": [], "headers": [].concat([])}));
	}
	workers() {
		return new Remote28(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["workers"], "query": [], "headers": [].concat([])}));
	}
	aoaLocators() {
		return new Remote16(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["aoa_locators"], "query": [], "headers": [].concat([])}));
	}
	uwbTags() {
		return new Remote3(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["uwb_tags"], "query": [], "headers": [].concat([])}));
	}
	wistron() {
		return new Remote76(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["wistron"], "query": [], "headers": [].concat([])}));
	}
	static get __name__() {
		return "tink.web.proxy.Remote2"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote2
	}
}

