import {Input} from "./Input.js"
import {Error as Error__1} from "./Error.js"
import {Eof} from "./Eof.js"
import {Exception} from "../Exception.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const BytesInput = Register.global("$hxClasses")["haxe.io.BytesInput"] = 
class BytesInput extends Register.inherits(Input) {
	new(b, pos, len) {
		if (pos == null) {
			pos = 0;
		};
		if (len == null) {
			len = b.length - pos;
		};
		if (pos < 0 || len < 0 || pos + len > b.length) {
			throw Exception.thrown(Error__1.OutsideBounds);
		};
		this.b = b.b;
		this.pos = pos;
		this.len = len;
		this.totlen = len;
	}
	set_position(p) {
		if (p < 0) {
			p = 0;
		} else if (p > this.totlen) {
			p = this.totlen;
		};
		this.len = this.totlen - p;
		return this.pos = p;
	}
	readByte() {
		if (this.len == 0) {
			throw Exception.thrown(new Eof());
		};
		this.len--;
		return this.b[this.pos++];
	}
	static get __name__() {
		return "haxe.io.BytesInput"
	}
	static get __super__() {
		return Input
	}
	get __class__() {
		return BytesInput
	}
}

