import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {Observable, ConstObservable} from "../../../tink/state/Observable.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Promise as Promise__1} from "../../../tink/core/Promise.js"
import {Outcome} from "../../../tink/core/Outcome.js"
import {LazyConst} from "../../../tink/core/Lazy.js"
import {SyncFuture} from "../../../tink/core/Future.js"
import {Option} from "../../../haxe/ds/Option.js"
import {Register} from "../../../genes/Register.js"
import {Updatable} from "../../../coconut/ds/Updatable.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const AreaData = Register.global("$hxClasses")["dasloop.app.data.AreaData"] = 
class AreaData extends Register.inherits() {
	new(__coco_init) {
		this._updatePerformed = Signal.trigger();
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return Observable.get_value(_gthis._data.__coco_data);
		}), null);
		AutoObservable.cur = before;
		this.__coco_data = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.async(function () {
			State.get_value(_gthis.__coco_workersRevision);
			return _gthis.remote.v2().areas().ofId(_gthis.id).workers().list();
		}), null);
		AutoObservable.cur = before;
		this.__coco_workers = ret;
		this.id = __coco_init.id;
		this.remote = __coco_init.remote;
		var _g = __coco_init.initial;
		this.initial = (_g == null) ? Option.None : _g;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new Updatable({"loader": function () {
			return _gthis.remote.v2().areas().ofId(_gthis.id).get();
		}, "updater": function (current, patch) {
			switch (patch._hx_index) {
				case 0:
					return _gthis.remote.v2().areas().ofId(_gthis.id).update(patch.patch);
					break
				case 1:
					var v = current;
					return Promise__1.next(_gthis.remote.v2().areas().ofId(_gthis.id).workers().add(patch.id, patch.authorized), function (_) {
						return new SyncFuture(new LazyConst(Outcome.Success(v)));
					});
					break
				case 2:
					var v1 = current;
					return Promise__1.next(_gthis.remote.v2().areas().ofId(_gthis.id).workers().remove(patch.id), function (_) {
						return new SyncFuture(new LazyConst(Outcome.Success(v1)));
					});
					break
				case 3:
					return _gthis.remote.v2().areas().ofId(_gthis.id).setActive(true);
					break
				case 4:
					return _gthis.remote.v2().areas().ofId(_gthis.id).setActive(false);
					break
				
			};
		}, "cache": _gthis.initial});
		AutoObservable.cur = before;
		this._data = ret;
		this.__coco_workersRevision = State._new(0, null, null, null);
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"id": new ConstObservable(this.id), "remote": new ConstObservable(this.remote), "initial": new ConstObservable(this.initial), "_data": new ConstObservable(this._data), "workersRevision": this.__coco_workersRevision, "workers": this.__coco_workers, "data": this.__coco_data, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	set_workersRevision(param) {
		this._updatePerformed.handlers.invoke({"workersRevision": param});
		this.__coco_workersRevision.set(param);
		return param;
	}
	static get __name__() {
		return "dasloop.app.data.AreaData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return AreaData
	}
}


export const AreaPatch = 
Register.global("$hxEnums")["dasloop.app.data.AreaPatch"] = 
{
	__ename__: "dasloop.app.data.AreaPatch",
	
	Data: Object.assign((patch) => ({_hx_index: 0, __enum__: "dasloop.app.data.AreaPatch", "patch": patch}), {_hx_name: "Data", __params__: ["patch"]}),
	AddWorker: Object.assign((id, authorized) => ({_hx_index: 1, __enum__: "dasloop.app.data.AreaPatch", "id": id, "authorized": authorized}), {_hx_name: "AddWorker", __params__: ["id", "authorized"]}),
	RemoveWorker: Object.assign((id) => ({_hx_index: 2, __enum__: "dasloop.app.data.AreaPatch", "id": id}), {_hx_name: "RemoveWorker", __params__: ["id"]}),
	Activate: {_hx_name: "Activate", _hx_index: 3, __enum__: "dasloop.app.data.AreaPatch"},
	Deactivate: {_hx_name: "Deactivate", _hx_index: 4, __enum__: "dasloop.app.data.AreaPatch"}
}
AreaPatch.__constructs__ = [AreaPatch.Data, AreaPatch.AddWorker, AreaPatch.RemoveWorker, AreaPatch.Activate, AreaPatch.Deactivate]
AreaPatch.__empty_constructs__ = [AreaPatch.Activate, AreaPatch.Deactivate]
