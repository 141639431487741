import {Path} from "../../url/Path.js"
import {RealSourceTools} from "../../io/Source.js"
import {OutgoingRequest, OutgoingRequestHeader} from "../../http/Request.js"
import {Promise as Promise__1} from "../../core/Promise.js"
import {Outcome} from "../../core/Outcome.js"
import {LazyConst} from "../../core/Lazy.js"
import {SyncFuture} from "../../core/Future.js"
import {TypedError} from "../../core/Error.js"
import {Url} from "../../Url.js"
import {Register} from "../../../genes/Register.js"
import {HxOverrides} from "../../../HxOverrides.js"

const $global = Register.$global

export const Scheme = Register.global("$hxClasses")["tink.web.proxy._Remote.Scheme"] = 
class Scheme {
	static fromString(s) {
		var s1;
		if (s == null) {
			s1 = "";
		} else {
			var _g = s.indexOf(":");
			s1 = (_g == -1) ? s + ":" : HxOverrides.substr(s, 0, _g + 1);
		};
		return s1;
	}
	static get __name__() {
		return "tink.web.proxy._Remote.Scheme_Impl_"
	}
	get __class__() {
		return Scheme
	}
}


export const RemoteEndpoint = Register.global("$hxClasses")["tink.web.proxy._Remote.RemoteEndpoint"] = 
class RemoteEndpoint {
	static _new(host, pathSuffix, scheme) {
		return {"host": host, "pathSuffix": pathSuffix, "scheme": Scheme.fromString(scheme)};
	}
	static concat(a, b) {
		if (a == null) {
			return b;
		} else if (b == null) {
			return a;
		} else {
			return a.concat(b);
		};
	}
	static sub(this1, options) {
		return {"host": this1.host, "scheme": this1.scheme, "pathSuffix": this1.pathSuffix, "headers": RemoteEndpoint.concat(this1.headers, options.headers), "query": RemoteEndpoint.concat(this1.query, options.query), "path": RemoteEndpoint.concat(this1.path, options.path)};
	}
	static uri(this1) {
		var _g = this1.path;
		return "/" + ((_g == null) ? "" : Path.normalize(_g.join("/"))) + ((this1.pathSuffix == null) ? "" : this1.pathSuffix) + ((this1.query == null) ? "null" : QueryParams.toString(this1.query));
	}
	static request(this1, client, method, body, reader) {
		return Promise__1.next(client.request(new OutgoingRequest(new OutgoingRequestHeader(method, Url.fromString("" + this1.scheme + "//" + ((this1.host == null) ? "null" : this1.host) + RemoteEndpoint.uri(this1)), null, this1.headers), body)), function (response) {
			return (ResponseReader.withHeader(reader, response.header))(response.body);
		});
	}
	static get __name__() {
		return "tink.web.proxy._Remote.RemoteEndpoint_Impl_"
	}
	get __class__() {
		return RemoteEndpoint
	}
}


export const ResponseReader = Register.global("$hxClasses")["tink.web.proxy._Remote.ResponseReader"] = 
class ResponseReader {
	static withHeader(this1, header) {
		var _g = this1;
		var a1 = header;
		return function (a2) {
			return _g(a1, a2);
		};
	}
	static ofStringReader(read) {
		return function (header, body) {
			return Promise__1.next(RealSourceTools.all(body), function (chunk) {
				if (header.statusCode >= 400) {
					return new SyncFuture(new LazyConst(Outcome.Failure(TypedError.withData(header.statusCode, header.reason, chunk.toString(), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_web/0.3.0/github/5f481ef34ae04a352700ac2c9682e6c96c27bbf5/src/tink/web/proxy/Remote.hx", "lineNumber": 202, "className": "tink.web.proxy._Remote.ResponseReader_Impl_", "methodName": "ofStringReader"}))));
				} else {
					return new SyncFuture(new LazyConst(read(chunk.toString())));
				};
			});
		};
	}
	static get __name__() {
		return "tink.web.proxy._Remote.ResponseReader_Impl_"
	}
	get __class__() {
		return ResponseReader
	}
}


export const QueryParams = Register.global("$hxClasses")["tink.web.proxy._Remote.QueryParams"] = 
class QueryParams {
	static toString(this1) {
		if (this1 == null) {
			return "";
		} else if (this1.length == 0) {
			return "";
		} else {
			var ret = [];
			var _g = 0;
			while (_g < this1.length) {
				var p = this1[_g];
				++_g;
				ret.push(p.name + "=" + p.value);
			};
			return "?" + ((ret == null) ? "null" : ret.join("&"));
		};
	}
	static get __name__() {
		return "tink.web.proxy._Remote.QueryParams_Impl_"
	}
	get __class__() {
		return QueryParams
	}
}


export const RemoteBase = Register.global("$hxClasses")["tink.web.proxy.RemoteBase"] = 
class RemoteBase extends Register.inherits() {
	new(client, endpoint) {
		this.client = client;
		this.endpoint = endpoint;
	}
	static get __name__() {
		return "tink.web.proxy.RemoteBase"
	}
	get __class__() {
		return RemoteBase
	}
}

