import {LinkObject, SimpleLink, CallbackLink} from "../Callback.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const SubscriptionObject = function() {};
SubscriptionObject.__isInterface__ = true;

export const SimpleSubscription = Register.global("$hxClasses")["tink.core.ext.SimpleSubscription"] = 
class SimpleSubscription extends Register.inherits(SimpleLink) {
	new(f, error) {
		super.new((f == null) ? CallbackLink.noop : (o=>Register.bind(o, o.cancel))(f));
		this.error = error;
	}
	static get __name__() {
		return "tink.core.ext.SimpleSubscription"
	}
	static get __interfaces__() {
		return [SubscriptionObject]
	}
	static get __super__() {
		return SimpleLink
	}
	get __class__() {
		return SimpleSubscription
	}
}

