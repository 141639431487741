import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {PromisedTools} from "../../../tink/state/Promised.js"
import {Observable, ConstObservable} from "../../../tink/state/Observable.js"
import {Vector, Vectorlike} from "../../../tink/pure/Vector.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Promise as Promise__1} from "../../../tink/core/Promise.js"
import {Outcome} from "../../../tink/core/Outcome.js"
import {LazyConst} from "../../../tink/core/Lazy.js"
import {SyncFuture} from "../../../tink/core/Future.js"
import {Option} from "../../../haxe/ds/Option.js"
import {Register} from "../../../genes/Register.js"
import {AreaData} from "./AreaData.js"
import {StringCollection} from "../../../coconut/ds/Collection.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const AreasData = Register.global("$hxClasses")["dasloop.app.data.AreasData"] = 
class AreasData extends Register.inherits() {
	new(__coco_init) {
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return PromisedTools.map(PromisedTools.flatMap(Observable.get_value(_gthis.collection.__coco_list), function (list) {
				var result = new Array(list.length);
				var _g = 0;
				var _g1 = list.length;
				while (_g < _g1) {
					var i = _g++;
					result[i] = Observable.get_value(list[i].__coco_data);
				};
				return PromisedTools.all(result);
			}), Vector.fromArray);
		}), null);
		AutoObservable.cur = before;
		this.__coco_data = ret;
		this.project = __coco_init.project;
		this.remote = __coco_init.remote;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new StringCollection({"fetch": function () {
			return _gthis.remote.v2().projects().ofId(_gthis.project).areas().list();
		}, "extractKey": function (raw) {
			return raw.id;
		}, "createItem": function (id, init) {
			return new AreaData({"id": id, "remote": _gthis.remote, "initial": init});
		}, "updateItem": function (data, raw) {
			data._data.refresh(Option.Some(raw));
		}});
		AutoObservable.cur = before;
		this.collection = ret;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"project": new ConstObservable(this.project), "remote": new ConstObservable(this.remote), "collection": new ConstObservable(this.collection), "data": this.__coco_data, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	create(data) {
		var _gthis = this;
		var ret = Promise__1.next(this.remote.v2().projects().ofId(this.project).areas().create(data), function (area) {
			var data = _gthis.collection.map.get(area.id, null);
			data._data.refresh(Option.Some(area));
			return new SyncFuture(new LazyConst(Outcome.Success(data)));
		});
		ret.handle(function (o) {
			var _g = Observable.get_value(_gthis.collection.__coco_list);
			if (o._hx_index == 0) {
				if (_g._hx_index == 1) {
					_gthis.collection.refresh(Option.Some(_g.result.concat(Vectorlike.ofSingle(o.data))));
				};
			};
		});
		return ret;
	}
	delete(id) {
		var _gthis = this;
		var ret = this.remote.projects().ofId(this.project).areas().ofId(id)["delete"]();
		ret.handle(function (o) {
			var _g = Observable.get_value(_gthis.collection.__coco_list);
			if (o._hx_index == 0) {
				if (_g._hx_index == 1) {
					var _gthis1 = _gthis.collection;
					var _g1 = [];
					var _g2 = 0;
					var _g3 = _g.result;
					while (_g2 < _g3.length) {
						var v = _g3[_g2];
						++_g2;
						if (v.id != id) {
							_g1.push(v);
						};
					};
					_gthis1.refresh(Option.Some(_g1));
				};
			};
		});
		return ret;
	}
	static get __name__() {
		return "dasloop.app.data.AreasData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return AreasData
	}
}

