import {ChunkObject} from "./ChunkObject.js"
import {ChunkBase} from "./ChunkBase.js"
import {Chunk} from "../Chunk.js"
import {Bytes} from "../../haxe/io/Bytes.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const CompoundChunk = Register.global("$hxClasses")["tink.chunk.CompoundChunk"] = 
class CompoundChunk extends Register.inherits(ChunkBase) {
	new() {
	}
	getLength() {
		return this.length;
	}
	flatten(into) {
		var _g = 0;
		var _g1 = this.chunks;
		while (_g < _g1.length) _g1[_g++].flatten(into);
	}
	blitTo(target, offset) {
		var _g = 0;
		var _g1 = this.chunks.length;
		while (_g < _g1) {
			var i = _g++;
			this.chunks[i].blitTo(target, offset + this.offsets[i]);
		};
	}
	toString() {
		return this.toBytes().toString();
	}
	toBytes() {
		var ret = new Bytes(new ArrayBuffer(this.length));
		this.blitTo(ret, 0);
		return ret;
	}
	static asCompound(c) {
		if (((c) instanceof CompoundChunk)) {
			return c;
		} else {
			return null;
		};
	}
	static cons(a, b) {
		var _g = a.getLength();
		var _g1 = b.getLength();
		if (_g == 0) {
			if (_g1 == 0) {
				return Chunk.EMPTY;
			} else {
				return b;
			};
		} else if (_g1 == 0) {
			return a;
		} else {
			var _g = CompoundChunk.asCompound(a);
			var _g1 = CompoundChunk.asCompound(b);
			if (_g == null) {
				if (_g1 == null) {
					return CompoundChunk.create([a, b], 2);
				} else if (_g1.depth < 100) {
					return CompoundChunk.create([a, b], _g1.depth + 1);
				} else {
					var flat = [];
					_g1.flatten(flat);
					b.flatten(flat);
					return CompoundChunk.create(flat, 2);
				};
			} else if (_g1 == null) {
				if (_g.depth < 100) {
					return CompoundChunk.create([a, b], _g.depth + 1);
				} else {
					var flat = [];
					_g.flatten(flat);
					b.flatten(flat);
					return CompoundChunk.create(flat, 2);
				};
			} else {
				var depth = (_g.depth > _g1.depth) ? _g.depth : _g1.depth;
				return CompoundChunk.create(_g.chunks.concat(_g1.chunks), depth);
			};
		};
	}
	static create(chunks, depth) {
		var ret = new CompoundChunk();
		var offsets = [0];
		var length = 0;
		var _g = 0;
		while (_g < chunks.length) offsets.push(length += chunks[_g++].getLength());
		ret.chunks = chunks;
		ret.offsets = offsets;
		ret.length = length;
		ret.depth = depth;
		return ret;
	}
	static get __name__() {
		return "tink.chunk.CompoundChunk"
	}
	static get __interfaces__() {
		return [ChunkObject]
	}
	static get __super__() {
		return ChunkBase
	}
	get __class__() {
		return CompoundChunk
	}
}

