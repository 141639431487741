import {AutoObservable, Computation} from "../../tink/state/internal/AutoObservable.js"
import {Observable} from "../../tink/state/Observable.js"
import {Url} from "../../tink/Url.js"
import {Location} from "../../spectatory/Location.js"
import {Register} from "../../genes/Register.js"
import {Slot} from "../ui/internal/Slot.js"
import {View} from "../react/View.js"
import {Html} from "../react/Html.js"

const $global = Register.$global

export const History = Register.global("$hxClasses")["coconut.router.History"] = 
class History extends Register.inherits() {
	new() {
	}
	push(url) {
		var url1 = Url.toString(this.resolve(url));
		window.history.pushState(null, null, url1);
		window.scroll({"top": 0});
	}
	resolve(url) {
		return Url.resolve(Url.fromString(window.location.href), url);
	}
	static get __name__() {
		return "coconut.router.History"
	}
	get __class__() {
		return History
	}
}


History.INST = new History()
export const Router = Register.global("$hxClasses")["coconut.router.Router"] = 
class Router extends Register.inherits(View) {
	new(__coco_data_) {
		var _gthis = this;
		this.__coco_renderScreen = new Slot(this, null, null);
		this.__coco_urlToRoute = new Slot(this, null, null);
		this.__coco_isExternalLink = new Slot(this, null, new AutoObservable(Computation.sync(function () {
			return function (href) {
				return href.indexOf("//") >= 0;
			};
		}), null));
		this.__coco_current = new AutoObservable(Computation.sync(function () {
			return _gthis.urlToRoute(Url.fromString(Observable.get_value(Location.href)));
		}), null);
		this.__initAttributes(__coco_data_);
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	renderScreen(a0) {
		return (Observable.get_value(this.__coco_renderScreen))(a0);
	}
	urlToRoute(a0) {
		return (Observable.get_value(this.__coco_urlToRoute))(a0);
	}
	isExternalLink(href) {
		return (Observable.get_value(this.__coco_isExternalLink))(href);
	}
	__coco_render() {
		var children = [this.renderScreen(Observable.get_value(this.__coco_current))];
		return Html.h("div", {"onClick": Register.bind(this, this.onClick)}, children);
	}
	onClick(e) {
		var _g = e.target.closest("a");
		if (_g != null) {
			if (!e.defaultPrevented && e.button == 0 && Router.isTargetingSelf(_g.getAttribute("target")) && !(e.metaKey || e.altKey || e.ctrlKey || e.shiftKey)) {
				var _g1 = _g.getAttribute("href");
				if (_g1 != null) {
					if (!this.isExternalLink(_g1)) {
						e.preventDefault();
						History.INST.push(Url.fromString(_g1));
					};
				};
			};
		};
	}
	__initAttributes(attributes) {
		this.__coco_renderScreen.setData(attributes.renderScreen);
		this.__coco_urlToRoute.setData(attributes.urlToRoute);
		this.__coco_isExternalLink.setData(attributes.isExternalLink);
	}
	static isTargetingSelf(target) {
		if (target != null) {
			return target == "self";
		} else {
			return true;
		};
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": Router, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "coconut.router.Router"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return Router
	}
}

