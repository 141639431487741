import {BasicWriter} from "./Writer.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Writer70 = Register.global("$hxClasses")["tink.json.Writer70"] = 
class Writer70 extends Register.inherits(BasicWriter) {
	new() {
		super.new();
	}
	wb(value) {
		this.f("{");
		var value1 = value._0;
		this.e("\"boundary\":");
		var value2 = value1;
		this.f("[");
		var first = true;
		var _g = 0;
		while (_g < value2.length) {
			var value1 = value2[_g++];
			if (first) {
				first = false;
			} else {
				this.f(",");
			};
			var value3 = value1;
			this.f("[");
			var first1 = true;
			var _g1 = 0;
			while (_g1 < value3.length) {
				var value4 = value3[_g1++];
				if (first1) {
					first1 = false;
				} else {
					this.f(",");
				};
				var value5 = value4;
				this.f("[");
				var first2 = true;
				var _g2 = 0;
				while (_g2 < value5.length) {
					var value6 = value5[_g2++];
					if (first2) {
						first2 = false;
					} else {
						this.f(",");
					};
					var value7 = value6;
					this.f("[");
					var first3 = true;
					var _g3 = 0;
					while (_g3 < value7.length) {
						var value8 = value7[_g3++];
						if (first3) {
							first3 = false;
						} else {
							this.f(",");
						};
						this.h(value8);
					};
					this.f("]");
				};
				this.f("]");
			};
			this.f("]");
		};
		this.f("]");
		var value1 = value._1;
		this.f(",");
		this.e("\"client\":");
		this.j(value1);
		var value1 = value._2;
		this.f(",");
		this.e("\"contacts\":");
		var value2 = value1;
		this.f("[");
		var first = true;
		var _g = 0;
		while (_g < value2.length) {
			var value1 = value2[_g++];
			if (first) {
				first = false;
			} else {
				this.f(",");
			};
			this.wc(value1);
		};
		this.f("]");
		var value1 = value._3;
		this.f(",");
		this.e("\"contractor\":");
		this.j(value1);
		var value1 = value._4;
		this.f(",");
		this.e("\"endDate\":");
		this.h(value1.getTime());
		var value1 = value._5;
		this.f(",");
		this.e("\"name\":");
		this.j(value1);
		var value1 = value._6;
		this.f(",");
		this.e("\"number\":");
		this.j(value1);
		var value1 = value._7;
		this.f(",");
		this.e("\"region\":");
		this.j(value1);
		var value1 = value._8;
		this.f(",");
		this.e("\"startDate\":");
		this.h(value1.getTime());
		var value1 = value._9;
		this.f(",");
		this.e("\"timezone\":");
		this.h(value1 / 60);
		this.f("}");
	}
	wc(value) {
		this.f("{");
		var value1 = value.language;
		this.e("\"language\":");
		this.j(value1);
		var value1 = value.name;
		this.f(",");
		this.e("\"name\":");
		this.j(value1);
		var value1 = value.role;
		this.f(",");
		this.e("\"role\":");
		this.j(value1);
		var _g = value.email;
		if (_g != null) {
			this.f(",");
			this.e("\"email\":");
			if (_g == null) {
				this.e("null");
			} else {
				this.j(_g);
			};
		};
		var _g = value.phone;
		if (_g != null) {
			this.f(",");
			this.e("\"phone\":");
			if (_g == null) {
				this.e("null");
			} else {
				this.j(_g);
			};
		};
		var _g = value.sms;
		if (_g != null) {
			this.f(",");
			this.e("\"sms\":");
			if (_g == null) {
				this.e("null");
			} else {
				this.j(_g);
			};
		};
		var _g = value.telegram;
		if (_g != null) {
			this.f(",");
			this.e("\"telegram\":");
			if (_g == null) {
				this.e("null");
			} else {
				this.j(_g);
			};
		};
		var _g = value.whatsapp;
		if (_g != null) {
			this.f(",");
			this.e("\"whatsapp\":");
			if (_g == null) {
				this.e("null");
			} else {
				this.j(_g);
			};
		};
		this.f("}");
	}
	wd(value) {
		this.d();
		this.wb(value);
		return this.b.toString();
	}
	static get __name__() {
		return "tink.json.Writer70"
	}
	static get __super__() {
		return BasicWriter
	}
	get __class__() {
		return Writer70
	}
}

