import {FutureTrigger} from "./Future.js"
import {Disposable, OwnedDisposable} from "./Disposable.js"
import {CallbackLink, CallbackList, ListCell, Callback} from "./Callback.js"
import {Option} from "../../haxe/ds/Option.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Signal = Register.global("$hxClasses")["tink.core._Signal.Signal"] = 
class Signal {
	
	/**
	*  Gets the next emitted value as a Future
	*/
	static nextTime(this1, condition) {
		return Signal.pickNext(this1, function (v) {
			if (condition == null || condition(v)) {
				return Option.Some(v);
			} else {
				return Option.None;
			};
		});
	}
	
	/**
	* Creates a future that yields the next value matched by the provided selector.
	*/
	static pickNext(this1, selector) {
		var ret = new FutureTrigger();
		var link = null;
		link = this1.listen(function (v) {
			var _g = selector(v);
			switch (_g._hx_index) {
				case 0:
					ret.trigger(_g.v);
					break
				case 1:
					break
				
			};
		});
		ret.handle((link == null) ? CallbackLink.noop : (o=>Register.bind(o, o.cancel))(link));
		return ret;
	}
	
	/**
	*  Creates a new `SignalTrigger`
	*/
	static trigger() {
		return new SignalTrigger();
	}
	static get __name__() {
		return "tink.core._Signal.Signal_Impl_"
	}
	get __class__() {
		return Signal
	}
}


export const SignalObject = function() {};
SignalObject.__isInterface__ = true;

export const SignalTrigger = Register.global("$hxClasses")["tink.core.SignalTrigger"] = 
class SignalTrigger extends Register.inherits() {
	new() {
		this.handlers = new CallbackList();
	}
	
	/**
	*  Emits a value for this signal
	*/
	trigger(event) {
		this.handlers.invoke(event);
	}
	listen(cb) {
		var _this = this.handlers;
		if (_this.disposeHandlers == null) {
			return null;
		} else {
			var node = new ListCell(cb, _this);
			_this.cells.push(node);
			if (_this.used++ == 0) {
				var fn = _this.onfill;
				if (Callback.depth < 500) {
					Callback.depth++;
					fn();
					Callback.depth--;
				} else {
					Callback.defer(fn);
				};
			};
			return node;
		};
	}
	static get __name__() {
		return "tink.core.SignalTrigger"
	}
	static get __interfaces__() {
		return [OwnedDisposable, SignalObject]
	}
	get __class__() {
		return SignalTrigger
	}
}

