import {Exception} from "./haxe/Exception.js"
import {Register} from "./genes/Register.js"

const $global = Register.$global

export const HxOverrides = Register.global("$hxClasses")["HxOverrides"] = 
class HxOverrides {
	static dateStr(date) {
		var m = date.getMonth() + 1;
		var d = date.getDate();
		var h = date.getHours();
		var mi = date.getMinutes();
		var s = date.getSeconds();
		return date.getFullYear() + "-" + ((m < 10) ? "0" + m : "" + m) + "-" + ((d < 10) ? "0" + d : "" + d) + " " + ((h < 10) ? "0" + h : "" + h) + ":" + ((mi < 10) ? "0" + mi : "" + mi) + ":" + ((s < 10) ? "0" + s : "" + s);
	}
	static strDate(s) {
		switch (s.length) {
			case 8:
				var k = s.split(":");
				var d = new Date();
				d["setTime"](0);
				d["setUTCHours"](k[0]);
				d["setUTCMinutes"](k[1]);
				d["setUTCSeconds"](k[2]);
				return d;
				break
			case 10:
				var k = s.split("-");
				return new Date(k[0], k[1] - 1, k[2], 0, 0, 0);
				break
			case 19:
				var k = s.split(" ");
				var y = k[0].split("-");
				var t = k[1].split(":");
				return new Date(y[0], y[1] - 1, y[2], t[0], t[1], t[2]);
				break
			default:
			throw Exception.thrown("Invalid date format : " + s);
			
		};
	}
	static cca(s, index) {
		var x = s.charCodeAt(index);
		if (x != x) {
			return undefined;
		};
		return x;
	}
	static substr(s, pos, len) {
		if (len == null) {
			len = s.length;
		} else if (len < 0) {
			if (pos == 0) {
				len = s.length + len;
			} else {
				return "";
			};
		};
		return s.substr(pos, len);
	}
	static remove(a, obj) {
		var i = a.indexOf(obj);
		if (i == -1) {
			return false;
		};
		a.splice(i, 1);
		return true;
	}
	static now() {
		return Date.now();
	}
	static get __name__() {
		return "HxOverrides"
	}
	get __class__() {
		return HxOverrides
	}
}


;((typeof(performance) != "undefined") ? typeof(performance.now) == "function" : false) ? HxOverrides.now = performance.now.bind(performance) : null
