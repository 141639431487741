import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {ConstObservable, Observable} from "../../../tink/state/Observable.js"
import {Vector} from "../../../tink/pure/Vector.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Promise as Promise__1, Next} from "../../../tink/core/Promise.js"
import {Outcome} from "../../../tink/core/Outcome.js"
import {LazyConst} from "../../../tink/core/Lazy.js"
import {SyncFuture, Future} from "../../../tink/core/Future.js"
import {Callback} from "../../../tink/core/Callback.js"
import {Option} from "../../../haxe/ds/Option.js"
import {Register} from "../../../genes/Register.js"
import {WorkergroupData} from "./WorkergroupData.js"
import {StringCollection} from "../../../coconut/ds/Collection.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const WorkergroupsData = Register.global("$hxClasses")["dasloop.app.data.WorkergroupsData"] = 
class WorkergroupsData extends Register.inherits() {
	new(__coco_init) {
		var _gthis = this;
		this.project = __coco_init.project;
		this.remote = __coco_init.remote;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new StringCollection({"fetch": function () {
			var api = _gthis.remote.v2().projects().ofId(_gthis.project).workergroups();
			return Promise__1.next(Promise__1.next(api.list(), function (groups) {
				var _g = [];
				var _g1 = 0;
				var _g2 = groups;
				while (_g1 < _g2.length) {
					var group = [_g2[_g1]];
					++_g1;
					_g.push(Promise__1.next(api.ofId(group[0].id).contacts().list(), (function (group) {
						return function (contacts) {
							var __o0 = group[0];
							return new SyncFuture(new LazyConst(Outcome.Success({"contacts": contacts, "project": __o0.project, "name": __o0.name, "isDefault": __o0.isDefault, "id": __o0.id})));
						};
					})(group)));
				};
				return Promise__1.inParallel(_g);
			}), Next.ofSafeSync(Vector.fromArray));
		}, "extractKey": function (raw) {
			return raw.id;
		}, "createItem": function (id, raw) {
			return new WorkergroupData({"id": id, "project": _gthis.project, "remote": _gthis.remote});
		}, "updateItem": function (data, raw) {
			data.data.refresh(Option.Some(raw));
		}});
		AutoObservable.cur = before;
		this.collection = ret;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"project": new ConstObservable(this.project), "remote": new ConstObservable(this.remote), "collection": new ConstObservable(this.collection), "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	refresh() {
		this.collection.refresh();
	}
	create(data) {
		var api = this.remote.v2().projects().ofId(this.project).workergroups();
		var c = Register.bind(this, this.refresh);
		var c1 = function (o) {
			if (o._hx_index == 0) {
				Callback.invoke(c, o.data);
			};
		};
		return Future.map(Promise__1.next(api.create(data.name), function (group) {
			var v = group;
			return Promise__1.next(api.ofId(group.id).contacts().set(data.contacts), function (_) {
				return new SyncFuture(new LazyConst(Outcome.Success(v)));
			});
		}), function (v) {
			Callback.invoke(c1, v);
			return v;
		});
	}
	static get __name__() {
		return "dasloop.app.data.WorkergroupsData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return WorkergroupsData
	}
}

