import {FluentProviderBase} from "./FluentProvider.js"
import {FluentLocale0} from "./FluentLocale0.js"
import {Outcome} from "../../tink/core/Outcome.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const FluentProvider0 = Register.global("$hxClasses")["turnwing.provider.FluentProvider0"] = 
class FluentProvider0 extends Register.inherits(FluentProviderBase) {
	new(source, opt) {
		super.new(source, opt);
	}
	validate(ctx) {
		var validations = [["contactUs"], ["productUrl"]];
		var _g = 0;
		while (_g < validations.length) {
			var v = validations[_g];
			++_g;
			var _g1 = this.validateMessage(ctx, v[0], v[1] || []);
			switch (_g1._hx_index) {
				case 0:
					return Outcome.Failure(_g1.v);
					break
				case 1:
					break
				
			};
		};
		return Outcome.Success(ctx.bundle);
	}
	make(bundle) {
		return new FluentLocale0(bundle, "");
	}
	static get __name__() {
		return "turnwing.provider.FluentProvider0"
	}
	static get __super__() {
		return FluentProviderBase
	}
	get __class__() {
		return FluentProvider0
	}
}

