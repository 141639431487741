import {Single} from "../../streams/Stream.js"
import {Source, IdealSourceTools} from "../../io/Source.js"
import {IncomingResponse, ResponseHeaderBase} from "../Response.js"
import {HeaderField} from "../Header.js"
import {ClientObject} from "../Client.js"
import {Outcome} from "../../core/Outcome.js"
import {LazyConst} from "../../core/Lazy.js"
import {Future} from "../../core/Future.js"
import {TypedError} from "../../core/Error.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Url} from "../../Url.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Timer} from "../../../haxe/Timer.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const JsClient = Register.global("$hxClasses")["tink.http.clients.JsClient"] = 
class JsClient extends Register.inherits() {
	new(credentials) {
		this.credentials = false;
		if (credentials) {
			this.credentials = true;
		};
	}
	request(req) {
		var _gthis = this;
		return Future.async(function (cb) {
			var _g = req.header.url.scheme;
			if (_g == null) {
				var http = new XMLHttpRequest();
				http.open(req.header.method, Url.toString(req.header.url));
				http.withCredentials = _gthis.credentials;
				http.responseType = "arraybuffer";
				var _this = req.header.fields;
				var _g_current = 0;
				while (_g_current < _this.length) {
					var header = _this[_g_current++];
					switch (header.name) {
						case "content-length":case "host":
							break
						default:
						http.setRequestHeader(header.name, header.value);
						
					};
				};
				http.onreadystatechange = function () {
					if (http.readyState == 4) {
						if (http.status != 0) {
							var headers;
							var _g = http.getAllResponseHeaders();
							if (_g == null) {
								headers = [];
							} else {
								var _g1 = [];
								var _g2 = 0;
								var _g3 = _g.split("\r\n");
								while (_g2 < _g3.length) {
									var line = _g3[_g2];
									++_g2;
									if (line != "") {
										_g1.push(HeaderField.ofString(line));
									};
								};
								headers = _g1;
							};
							var _g = http.response;
							cb(Outcome.Success(new IncomingResponse(new ResponseHeaderBase(http.status, http.statusText, headers, "HTTP/1.1"), (_g == null) ? Source.EMPTY : new Single(new LazyConst(ByteChunk.of(Bytes.ofData(_g)))))));
						} else {
							var _g = cb;
							var a1 = Outcome.Failure(TypedError.withData(502, "XMLHttpRequest Error", {"request": req, "error": "Status code is zero"}, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_http/0.10.0/github/49088ee6bb9d69b1e0f57931a13e836132e3fc02/src/tink/http/clients/JsClient.hx", "lineNumber": 55, "className": "tink.http.clients.JsClient", "methodName": "request"}));
							Timer.delay(function () {
								_g(a1);
							}, 1);
						};
					};
				};
				http.onerror = function (e) {
					cb(Outcome.Failure(TypedError.withData(502, "XMLHttpRequest Error", {"request": req, "error": e}, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_http/0.10.0/github/49088ee6bb9d69b1e0f57931a13e836132e3fc02/src/tink/http/clients/JsClient.hx", "lineNumber": 61, "className": "tink.http.clients.JsClient", "methodName": "request"})));
				};
				if (req.header.method == "GET") {
					http.send();
				} else {
					IdealSourceTools.all(req.body).handle(function (chunk) {
						http.send(new Int8Array(chunk.toBytes().b.bufferValue));
					});
				};
			} else {
				switch (_g) {
					case "http":case "https":
						var http1 = new XMLHttpRequest();
						http1.open(req.header.method, Url.toString(req.header.url));
						http1.withCredentials = _gthis.credentials;
						http1.responseType = "arraybuffer";
						var _this = req.header.fields;
						var _g_current = 0;
						while (_g_current < _this.length) {
							var header = _this[_g_current++];
							switch (header.name) {
								case "content-length":case "host":
									break
								default:
								http1.setRequestHeader(header.name, header.value);
								
							};
						};
						http1.onreadystatechange = function () {
							if (http1.readyState == 4) {
								if (http1.status != 0) {
									var headers;
									var _g = http1.getAllResponseHeaders();
									if (_g == null) {
										headers = [];
									} else {
										var _g1 = [];
										var _g2 = 0;
										var _g3 = _g.split("\r\n");
										while (_g2 < _g3.length) {
											var line = _g3[_g2];
											++_g2;
											if (line != "") {
												_g1.push(HeaderField.ofString(line));
											};
										};
										headers = _g1;
									};
									var _g = http1.response;
									cb(Outcome.Success(new IncomingResponse(new ResponseHeaderBase(http1.status, http1.statusText, headers, "HTTP/1.1"), (_g == null) ? Source.EMPTY : new Single(new LazyConst(ByteChunk.of(Bytes.ofData(_g)))))));
								} else {
									var _g = cb;
									var a1 = Outcome.Failure(TypedError.withData(502, "XMLHttpRequest Error", {"request": req, "error": "Status code is zero"}, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_http/0.10.0/github/49088ee6bb9d69b1e0f57931a13e836132e3fc02/src/tink/http/clients/JsClient.hx", "lineNumber": 55, "className": "tink.http.clients.JsClient", "methodName": "request"}));
									Timer.delay(function () {
										_g(a1);
									}, 1);
								};
							};
						};
						http1.onerror = function (e) {
							cb(Outcome.Failure(TypedError.withData(502, "XMLHttpRequest Error", {"request": req, "error": e}, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_http/0.10.0/github/49088ee6bb9d69b1e0f57931a13e836132e3fc02/src/tink/http/clients/JsClient.hx", "lineNumber": 61, "className": "tink.http.clients.JsClient", "methodName": "request"})));
						};
						if (req.header.method == "GET") {
							http1.send();
						} else {
							IdealSourceTools.all(req.body).handle(function (chunk) {
								http1.send(new Int8Array(chunk.toBytes().b.bufferValue));
							});
						};
						break
					default:
					cb(Outcome.Failure(new TypedError(400, "Missing Scheme (expected http/https) in URL: " + Url.toString(req.header.url), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_http/0.10.0/github/49088ee6bb9d69b1e0f57931a13e836132e3fc02/src/tink/http/clients/Helpers.hx", "lineNumber": 16, "className": "tink.http.clients.Helpers", "methodName": "missingSchemeError"})));
					
				};
			};
		});
	}
	static get __name__() {
		return "tink.http.clients.JsClient"
	}
	static get __interfaces__() {
		return [ClientObject]
	}
	get __class__() {
		return JsClient
	}
}

