import {Writer19} from "./Writer19.js"
import {BasicParser, JsonString} from "./Parser.js"
import {OutcomeTools} from "../core/Outcome.js"
import {TypedError} from "../core/Error.js"
import {Exception} from "../../haxe/Exception.js"
import {Register} from "../../genes/Register.js"
import {DeviceId} from "../../dasloop/types/DeviceId.js"

const $global = Register.$global

export const Parser26 = Register.global("$hxClasses")["tink.json.Parser26"] = 
class Parser26 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	pb() {
		var _gthis = this;
		var v_date = null;
		var hasv_date = false;
		var v_device = null;
		var hasv_device = false;
		var v_id = null;
		var hasv_id = false;
		var v_project = null;
		var v_type = null;
		var hasv_type = false;
		var v_value = .0;
		var hasv_value = false;
		var v_worker = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "date":
						v_date = new Date(JsonString.f(this.r()));
						hasv_date = true;
						break
					case "device":
						v_device = OutcomeTools.sure(DeviceId.parse(JsonString.s(this.m())));
						hasv_device = true;
						break
					case "id":
						v_id = JsonString.s(this.m());
						hasv_id = true;
						break
					case "project":
						v_project = (this.E("null")) ? null : JsonString.s(this.m());
						break
					case "type":
						var v = JsonString.s(this.m());
						switch (v) {
							case "altitude":case "ambient_temperature":case "atmospheric_pressure":case "battery":case "battery_current":case "battery_voltage":case "body_temperature":case "gas_concentration_co":case "gas_concentration_co2":case "gas_concentration_h2s":case "gas_concentration_hc":case "gas_concentration_o2":case "gas_detector_status":case "general_report_interval":case "gps_altitude":case "gsm_level":case "heart_rate":case "heart_rate_quality":case "humidity":case "in_recess_area":case "in_sleep_area":case "live_call_room":case "lpwan_signal_strength":case "lpwan_signal_strength_rssi":case "lpwan_signal_strength_sni":case "module_recover_count":case "network_disconnect_count":case "network_report_cell_id":case "network_report_earfcn":case "network_report_lpwan_signal_strength":case "network_report_mccmnc":case "network_report_nw_provided_edrx":case "network_report_paging_time_window":case "network_report_psm_active_time":case "network_report_psm_periodic_tau":case "network_report_requested_edrx":case "network_report_status":case "network_report_tracking_area_code":case "network_report_wifi_ip_address":case "network_report_wifi_signal_strength":case "ping":case "raw_ambient_temperature_from_humidity_sensor":case "raw_ambient_temperature_from_infrared_sensor":case "raw_ambient_temperature_from_pressure_sensor":case "recess_area_detection_enabled":case "server_disconnect_count":case "short_report_period":case "shutdown":case "sleep_area_detection_enabled":case "special_area_detection_enabled":case "spo2_oxygen_level":case "standstill_token_count":case "standstill_token_duration":case "stationary_time":case "surface_temperature_from_infrared_sensor":case "uwb_detector_operating":case "uwb_detector_powered":case "wearing":case "wearing_time":case "wifi_signal_strength":
								v_type = v;
								break
							default:
							throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer19().wb(["altitude", "ambient_temperature", "atmospheric_pressure", "battery", "battery_current", "body_temperature", "general_report_interval", "short_report_period", "gps_altitude", "gsm_level", "heart_rate", "heart_rate_quality", "humidity", "in_recess_area", "in_sleep_area", "lpwan_signal_strength", "lpwan_signal_strength_rssi", "lpwan_signal_strength_sni", "module_recover_count", "network_disconnect_count", "server_disconnect_count", "raw_ambient_temperature_from_humidity_sensor", "raw_ambient_temperature_from_infrared_sensor", "raw_ambient_temperature_from_pressure_sensor", "recess_area_detection_enabled", "shutdown", "sleep_area_detection_enabled", "special_area_detection_enabled", "spo2_oxygen_level", "standstill_token_count", "standstill_token_duration", "stationary_time", "surface_temperature_from_infrared_sensor", "uwb_detector_operating", "uwb_detector_powered", "wearing", "wearing_time", "wifi_signal_strength", "network_report_status", "network_report_mccmnc", "network_report_tracking_area_code", "network_report_cell_id", "network_report_psm_active_time", "network_report_psm_periodic_tau", "network_report_requested_edrx", "network_report_nw_provided_edrx", "network_report_paging_time_window", "network_report_lpwan_signal_strength", "network_report_wifi_signal_strength", "network_report_wifi_ip_address", "network_report_earfcn", "battery_voltage", "live_call_room", "gas_concentration_hc", "gas_concentration_o2", "gas_concentration_h2s", "gas_concentration_co", "gas_concentration_co2", "gas_detector_status", "ping"]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 469, "className": "tink.json.Parser26", "methodName": "process0"}));
							
						};
						hasv_type = true;
						break
					case "value":
						v_value = JsonString.f(this.r());
						hasv_value = true;
						break
					case "worker":
						v_worker = (this.E("null")) ? null : JsonString.s(this.m());
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"date": (hasv_date) ? v_date : __missing__("date"), "device": (hasv_device) ? v_device : __missing__("device"), "id": (hasv_id) ? v_id : __missing__("id"), "project": v_project, "type": (hasv_type) ? v_type : __missing__("type"), "value": (hasv_value) ? v_value : __missing__("value"), "worker": v_worker};
	}
	pc(source) {
		if (this.e.length > 0) {
			this.e = [];
		};
		this.g(source);
		this.F("[");
		var __ret = [];
		if (!this.E("]")) {
			while (true) {
				__ret.push(this.pb());
				if (!this.E(",")) {
					break;
				};
			};
			this.F("]");
		};
		var ret = __ret;
		var _g = 0;
		var _g1 = this.e;
		while (_g < _g1.length) _g1[_g++]();
		if (this.e.length > 0) {
			this.e = [];
		};
		return ret;
	}
	pd(source) {
		var _gthis = this;
		return TypedError.catchExceptions(function () {
			var ret = _gthis.pc(source);
			_gthis.h();
			if (_gthis.c < _gthis.d) {
				_gthis.D("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser26", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser26"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser26
	}
}

