import {BasicWriter} from "./Writer.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Writer10 = Register.global("$hxClasses")["tink.json.Writer10"] = 
class Writer10 extends Register.inherits(BasicWriter) {
	new() {
		super.new();
	}
	wb(value) {
		this.f("{");
		var value1 = value._93;
		this.e("\"location\":");
		var value2 = value1;
		this.f("[");
		var first = true;
		var _g = 0;
		while (_g < value2.length) {
			var value1 = value2[_g++];
			if (first) {
				first = false;
			} else {
				this.f(",");
			};
			this.h(value1);
		};
		this.f("]");
		var value1 = value._98;
		this.f(",");
		this.e("\"name\":");
		this.j(value1);
		var value1 = value._99;
		this.f(",");
		this.e("\"voltage\":");
		this.h(value1);
		var _g = value._94;
		if (_g != null) {
			this.f(",");
			this.e("\"maxCurrent\":");
			if (_g == null) {
				this.e("null");
			} else {
				this.h(_g);
			};
		};
		var _g = value._95;
		if (_g != null) {
			this.f(",");
			this.e("\"maxTemperature\":");
			if (_g == null) {
				this.e("null");
			} else {
				this.h(_g);
			};
		};
		var _g = value._96;
		if (_g != null) {
			this.f(",");
			this.e("\"minCurrent\":");
			if (_g == null) {
				this.e("null");
			} else {
				this.h(_g);
			};
		};
		var _g = value._97;
		if (_g != null) {
			this.f(",");
			this.e("\"minTemperature\":");
			if (_g == null) {
				this.e("null");
			} else {
				this.h(_g);
			};
		};
		this.f("}");
	}
	wc(value) {
		this.d();
		this.wb(value);
		return this.b.toString();
	}
	static get __name__() {
		return "tink.json.Writer10"
	}
	static get __super__() {
		return BasicWriter
	}
	get __class__() {
		return Writer10
	}
}

