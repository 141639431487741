import {Register} from "./genes/Register.js"
import {Declarations} from "./cix/css/Declarations.js"

const $global = Register.$global

export const Cix8c188ea2938884a4dbd527cf4fd887a52a1c1220796c5610b8ae61eaa90c4619 = Register.global("$hxClasses")["Cix8c188ea2938884a4dbd527cf4fd887a52a1c1220796c5610b8ae61eaa90c4619"] = 
class Cix8c188ea2938884a4dbd527cf4fd887a52a1c1220796c5610b8ae61eaa90c4619 extends Register.inherits() {
	new() {
		this.css = Declarations.add("cxpc", function () {
			return ".cxpc {\n  tab-size: 2;\n}\n\n.cxpc summary {\n  list-style: none;\n  text-align: center;\n  cursor: pointer;\n}";
		});
	}
	static get __name__() {
		return "Cix8c188ea2938884a4dbd527cf4fd887a52a1c1220796c5610b8ae61eaa90c4619"
	}
	get __class__() {
		return Cix8c188ea2938884a4dbd527cf4fd887a52a1c1220796c5610b8ae61eaa90c4619
	}
}


Cix8c188ea2938884a4dbd527cf4fd887a52a1c1220796c5610b8ae61eaa90c4619.inst = new Cix8c188ea2938884a4dbd527cf4fd887a52a1c1220796c5610b8ae61eaa90c4619()