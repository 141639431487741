import {Register} from "../../genes/Register.js"
import {StringTools} from "../../StringTools.js"
import {Reflect as Reflect__1} from "../../Reflect.js"

const $global = Register.$global

export const ClassName = Register.global("$hxClasses")["tink.domspec._ClassName.ClassName"] = 
class ClassName {
	static add(this1, that) {
		var _g = that;
		return (this1 == null) ? _g : (_g == null) ? this1 : "" + this1 + " " + _g;
	}
	static when(this1, cond) {
		return (cond) ? this1 : "";
	}
	static ofArray(parts) {
		var f = ClassName.ofString;
		var result = new Array(parts.length);
		var _g = 0;
		var _g1 = parts.length;
		while (_g < _g1) {
			var i = _g++;
			result[i] = f(parts[i]);
		};
		return result.join(" ");
	}
	static ofDynamicAccess(parts) {
		var _g = [];
		var _g1 = 0;
		var _g2 = Reflect__1.fields(parts);
		while (_g1 < _g2.length) {
			var c = _g2[_g1];
			++_g1;
			if (parts[c]) {
				_g.push(ClassName.ofString(c));
			};
		};
		return ClassName.ofArray(_g);
	}
	static ofString(s) {
		if (s == null) {
			return null;
		} else {
			return StringTools.trim(s);
		};
	}
	static get __name__() {
		return "tink.domspec._ClassName.ClassName_Impl_"
	}
	get __class__() {
		return ClassName
	}
}

