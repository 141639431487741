import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Representation = Register.global("$hxClasses")["tink.json._Representation.Representation"] = 
class Representation {
	static get(this1) {
		return this1;
	}
	static get __name__() {
		return "tink.json._Representation.Representation_Impl_"
	}
	get __class__() {
		return Representation
	}
}

