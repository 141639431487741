import {Empty, Reducer, ReductionStep} from "../streams/Stream.js"
import {Outcome} from "../core/Outcome.js"
import {LazyConst} from "../core/Lazy.js"
import {SyncFuture, Future} from "../core/Future.js"
import {Chunk} from "../Chunk.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Source = Register.global("$hxClasses")["tink.io._Source.Source"] = 
class Source {
	static chunked(this1) {
		return this1;
	}
	static concatAll(s) {
		return s.reduce(Chunk.EMPTY, Reducer.ofSafe(function (res, cur) {
			return new SyncFuture(new LazyConst(ReductionStep.Progress(Chunk.concat(res, cur))));
		}));
	}
	static get __name__() {
		return "tink.io._Source.Source_Impl_"
	}
	get __class__() {
		return Source
	}
}


Source.EMPTY = Empty.inst
export const RealSourceTools = Register.global("$hxClasses")["tink.io.RealSourceTools"] = 
class RealSourceTools {
	static all(s) {
		return Future.map(Source.concatAll(s), function (o) {
			switch (o._hx_index) {
				case 1:
					return Outcome.Failure(o.error);
					break
				case 2:
					return Outcome.Success(o.result);
					break
				
			};
		});
	}
	static idealize(s, rescue) {
		return Source.chunked(s).idealize(rescue);
	}
	static get __name__() {
		return "tink.io.RealSourceTools"
	}
	get __class__() {
		return RealSourceTools
	}
}


export const IdealSourceTools = Register.global("$hxClasses")["tink.io.IdealSourceTools"] = 
class IdealSourceTools {
	static all(s) {
		return Future.map(Source.concatAll(s), function (o) {
			return o.result;
		});
	}
	static get __name__() {
		return "tink.io.IdealSourceTools"
	}
	get __class__() {
		return IdealSourceTools
	}
}

