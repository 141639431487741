import {Source} from "./Source.js"
import {FetchResponse, Fetch} from "../../tink/http/Fetch.js"
import {Promise as Promise__1} from "../../tink/core/Promise.js"
import {Outcome} from "../../tink/core/Outcome.js"
import {LazyConst} from "../../tink/core/Lazy.js"
import {SyncFuture} from "../../tink/core/Future.js"
import {Url} from "../../tink/Url.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const WebStringSource = Register.global("$hxClasses")["turnwing.source.WebStringSource"] = 
class WebStringSource extends Register.inherits() {
	new(getUrl) {
		this.getUrl = getUrl;
	}
	fetch(language) {
		return Promise__1.next(FetchResponse.all(Fetch.fetch(Url.fromString(this.getUrl(language)))), function (res) {
			return new SyncFuture(new LazyConst(Outcome.Success(res.body.toString())));
		});
	}
	static get __name__() {
		return "turnwing.source.WebStringSource"
	}
	static get __interfaces__() {
		return [Source]
	}
	get __class__() {
		return WebStringSource
	}
}

