import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {PromisedTools} from "../../../tink/state/Promised.js"
import {Observable, ConstObservable} from "../../../tink/state/Observable.js"
import {Vector} from "../../../tink/pure/Vector.js"
import {PromisesContainer} from "../../../tink/core/ext/Promises.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Promise as Promise__1, Next} from "../../../tink/core/Promise.js"
import {Outcome} from "../../../tink/core/Outcome.js"
import {LazyConst} from "../../../tink/core/Lazy.js"
import {Future, SyncFuture} from "../../../tink/core/Future.js"
import {Option} from "../../../haxe/ds/Option.js"
import {Register} from "../../../genes/Register.js"
import {DateTools} from "../../types/util/DateTools.js"
import {EquipmentData} from "./EquipmentData.js"
import {DaspowerTools} from "./DaspowerTools.js"
import {StringCollection} from "../../../coconut/ds/Collection.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const EquipmentsData = Register.global("$hxClasses")["dasloop.app.data.EquipmentsData"] = 
class EquipmentsData extends Register.inherits() {
	new(__coco_init) {
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return PromisedTools.map(PromisedTools.flatMap(Observable.get_value(_gthis.collection.__coco_list), function (list) {
				var result = new Array(list.length);
				var _g = 0;
				var _g1 = list.length;
				while (_g < _g1) {
					var i = _g++;
					result[i] = Observable.get_value(list[i].__coco_data);
				};
				return PromisedTools.all(result);
			}), Vector.fromArray);
		}), null);
		AutoObservable.cur = before;
		this.__coco_info = ret;
		this.project = __coco_init.project;
		var _g = __coco_init.group;
		this.group = (_g == null) ? null : _g;
		this.remote = __coco_init.remote;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new StringCollection({"fetch": function () {
			var api = _gthis.remote.v2().projects().ofId(_gthis.project);
			if (_gthis.group == null) {
				return api.equipments().list();
			} else {
				return api.equipmentgroups().ofId(_gthis.group).equipments().list();
			};
		}, "extractKey": function (raw) {
			return raw.id;
		}, "createItem": function (id, init) {
			return new EquipmentData({"id": id, "remote": _gthis.remote, "initial": init});
		}, "updateItem": function (data, raw) {
			data._data.refresh(Option.Some(raw));
		}});
		AutoObservable.cur = before;
		this.collection = ret;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"project": new ConstObservable(this.project), "group": new ConstObservable(this.group), "remote": new ConstObservable(this.remote), "collection": new ConstObservable(this.collection), "info": this.__coco_info, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	add(data) {
		return this.remote.v2().projects().ofId(this.project).equipments().create(data);
	}
	getUsageValue(from, to, aggregate) {
		return this.remote.v2().projects().ofId(this.project).equipments().statistics().getEnergyByEquipment({"from": from, "to": to, "resolution": aggregate, "timezone": -new Date().getTimezoneOffset() * 60});
	}
	getTotalUsage() {
		var __obj_yesterdayUsage;
		var __obj_todayUsage;
		var __obj_thisMonthUsage;
		var __obj_lastMonthUsage;
		__obj_thisMonthUsage = Promise__1.next(this.getUsageValue(DateTools.monthStart(new Date()), new Date(), 4), Next.ofSafeSync(DaspowerTools.sumEnergy));
		__obj_lastMonthUsage = Promise__1.next(this.getUsageValue(DateTools.previousMonthStart(new Date()), DateTools.previousMonthEnd(new Date()), 4), Next.ofSafeSync(DaspowerTools.sumEnergy));
		__obj_todayUsage = Promise__1.next(this.getUsageValue(DateTools.todayStart(new Date()), new Date(), 3), Next.ofSafeSync(DaspowerTools.sumEnergy));
		__obj_yesterdayUsage = Promise__1.next(this.getUsageValue(DateTools.yesterdayStart(new Date()), DateTools.yesterdayEnd(new Date()), 3), Next.ofSafeSync(DaspowerTools.sumEnergy));
		return Future.irreversible(function (cb) {
			var __ctx = new PromisesContainer(cb, 4);
			__obj_lastMonthUsage.handle(__ctx.handle(function (r, v) {
				r.lastMonthUsage = v;
			}));
			__obj_thisMonthUsage.handle(__ctx.handle(function (r, v) {
				r.thisMonthUsage = v;
			}));
			__obj_todayUsage.handle(__ctx.handle(function (r, v) {
				r.todayUsage = v;
			}));
			__obj_yesterdayUsage.handle(__ctx.handle(function (r, v) {
				r.yesterdayUsage = v;
			}));
		});
	}
	getUsageHistory(from, to, aggregate) {
		var timezone = -new Date().getTimezoneOffset() * 60;
		return Promise__1.next(this.remote.v2().projects().ofId(this.project).equipments().statistics().getEnergyByTime({"from": from, "to": to, "resolution": aggregate, "timezone": timezone}), function (data) {
			return new SyncFuture(new LazyConst(Outcome.Success(DaspowerTools.toPowerChartData(from, to, aggregate, timezone, data))));
		});
	}
	getTemperatureByEquipment(from, to, aggregate) {
		return this.remote.v2().projects().ofId(this.project).equipments().statistics().getTemperatureByEquipment({"from": from, "to": to, "resolution": aggregate, "timezone": -new Date().getTimezoneOffset() * 60});
	}
	static get __name__() {
		return "dasloop.app.data.EquipmentsData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return EquipmentsData
	}
}

