import {BasicParser, JsonString} from "./Parser.js"
import {TypedError} from "../core/Error.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Parser18 = Register.global("$hxClasses")["tink.json.Parser18"] = 
class Parser18 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	pb() {
		var _gthis = this;
		var v_id = null;
		var hasv_id = false;
		var v_isDefault = false;
		var hasv_isDefault = false;
		var v_name = null;
		var hasv_name = false;
		var v_project = null;
		var hasv_project = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "id":
						v_id = JsonString.s(this.m());
						hasv_id = true;
						break
					case "isDefault":
						v_isDefault = this.G();
						hasv_isDefault = true;
						break
					case "name":
						v_name = JsonString.s(this.m());
						hasv_name = true;
						break
					case "project":
						v_project = JsonString.s(this.m());
						hasv_project = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"id": (hasv_id) ? v_id : __missing__("id"), "isDefault": (hasv_isDefault) ? v_isDefault : __missing__("isDefault"), "name": (hasv_name) ? v_name : __missing__("name"), "project": (hasv_project) ? v_project : __missing__("project")};
	}
	pc(source) {
		if (this.e.length > 0) {
			this.e = [];
		};
		this.g(source);
		this.F("[");
		var __ret = [];
		if (!this.E("]")) {
			while (true) {
				__ret.push(this.pb());
				if (!this.E(",")) {
					break;
				};
			};
			this.F("]");
		};
		var ret = __ret;
		var _g = 0;
		var _g1 = this.e;
		while (_g < _g1.length) _g1[_g++]();
		if (this.e.length > 0) {
			this.e = [];
		};
		return ret;
	}
	pd(source) {
		var _gthis = this;
		return TypedError.catchExceptions(function () {
			var ret = _gthis.pc(source);
			_gthis.h();
			if (_gthis.c < _gthis.d) {
				_gthis.D("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser18", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser18"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser18
	}
}

