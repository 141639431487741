import {BasicWriter} from "./Writer.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Writer61 = Register.global("$hxClasses")["tink.json.Writer61"] = 
class Writer61 extends Register.inherits(BasicWriter) {
	new() {
		super.new();
	}
	wb(value) {
		this.d();
		this.f("[");
		var first = true;
		var _g = 0;
		while (_g < value.length) {
			var value1 = value[_g++];
			if (first) {
				first = false;
			} else {
				this.f(",");
			};
			this.g(value1);
		};
		this.f("]");
		return this.b.toString();
	}
	static get __name__() {
		return "tink.json.Writer61"
	}
	static get __super__() {
		return BasicWriter
	}
	get __class__() {
		return Writer61
	}
}

