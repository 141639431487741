import {FPHelper} from "./FPHelper.js"
import {NotImplementedException} from "../exceptions/NotImplementedException.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

/**
An Input is an abstract reader. See other classes in the `haxe.io` package
for several possible implementations.

All functions which read data throw `Eof` when the end of the stream
is reached.
*/
export const Input = Register.global("$hxClasses")["haxe.io.Input"] = 
class Input {
	
	/**
	Read and return one byte.
	*/
	readByte() {
		throw new NotImplementedException(null, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//versions/4.2.5/std/haxe/io/Input.hx", "lineNumber": 53, "className": "haxe.io.Input", "methodName": "readByte"});
	}
	
	/**
	Read a 64-bit double-precision floating point number.
	
	Endianness is specified by the `bigEndian` property.
	*/
	readDouble() {
		var i1 = this.readInt32();
		var i2 = this.readInt32();
		if (this.bigEndian) {
			return FPHelper.i64ToDouble(i2, i1);
		} else {
			return FPHelper.i64ToDouble(i1, i2);
		};
	}
	
	/**
	Read a 32-bit signed integer.
	
	Endianness is specified by the `bigEndian` property.
	*/
	readInt32() {
		var ch1 = this.readByte();
		var ch2 = this.readByte();
		var ch3 = this.readByte();
		var ch4 = this.readByte();
		if (this.bigEndian) {
			return ch4 | ch3 << 8 | ch2 << 16 | ch1 << 24;
		} else {
			return ch1 | ch2 << 8 | ch3 << 16 | ch4 << 24;
		};
	}
	static get __name__() {
		return "haxe.io.Input"
	}
	get __class__() {
		return Input
	}
}

