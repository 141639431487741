import {Transform} from "../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../tink/state/internal/AutoObservable.js"
import {State} from "../../tink/state/State.js"
import {ConstObservable, Observable} from "../../tink/state/Observable.js"
import {Signal} from "../../tink/core/Signal.js"
import {Promise as Promise__1} from "../../tink/core/Promise.js"
import {Outcome} from "../../tink/core/Outcome.js"
import {LazyConst} from "../../tink/core/Lazy.js"
import {SyncFuture} from "../../tink/core/Future.js"
import {Option} from "../../haxe/ds/Option.js"
import {Register} from "../../genes/Register.js"
import {IntDict, StringDict} from "./Dict.js"
import {Annex} from "../data/helpers/Annex.js"
import {Model} from "../data/Model.js"

const $global = Register.$global

export const ICollection = function() {};
ICollection.__isInterface__ = true;

export const IntCollection = Register.global("$hxClasses")["coconut.ds.IntCollection"] = 
class IntCollection extends Register.inherits() {
	new(__coco_init) {
		this._updatePerformed = Signal.trigger();
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.async(function () {
			State.get_value(_gthis.__coco_revision);
			var _g = State.get_value(_gthis.__coco_cache);
			switch (_g._hx_index) {
				case 0:
					return new SyncFuture(new LazyConst(Outcome.Success(_g.v)));
					break
				case 1:
					return Promise__1.next(_gthis.fetch(), function (list) {
						var result = new Array(list.length);
						var _g = 0;
						var _g1 = list.length;
						while (_g < _g1) {
							var i = _g++;
							var data = list[i];
							var k = _gthis.extractKey(data);
							var item = _gthis.map.get(k, null);
							_gthis.updateItem(item, data);
							result[i] = item;
						};
						return new SyncFuture(new LazyConst(Outcome.Success(result)));
					});
					break
				
			};
		}), null);
		AutoObservable.cur = before;
		this.__coco_list = ret;
		this.__coco_revision = State._new(0, null, null, null);
		this.fetch = __coco_init.fetch;
		this.createItem = __coco_init.createItem;
		this.updateItem = __coco_init.updateItem;
		this.extractKey = __coco_init.extractKey;
		var _g = __coco_init.cache;
		this.__coco_cache = State._new((_g == null) ? Option.None : _g, null, null, null);
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var _g = _gthis.createItem;
		var a2 = Option.None;
		var ret = new IntDict({"factory": function (a1) {
			return _g(a1, a2);
		}});
		AutoObservable.cur = before;
		this.map = ret;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"fetch": new ConstObservable(this.fetch), "createItem": new ConstObservable(this.createItem), "updateItem": new ConstObservable(this.updateItem), "extractKey": new ConstObservable(this.extractKey), "list": this.__coco_list, "map": new ConstObservable(this.map), "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	refresh(cache) {
		if (cache == null) {
			cache = Option.None;
		};
		this.set_cache(cache);
		this.set_revision(State.get_value(this.__coco_revision) + 1);
	}
	set_revision(param) {
		this._updatePerformed.handlers.invoke({"revision": param});
		this.__coco_revision.set(param);
		return param;
	}
	set_cache(param) {
		this._updatePerformed.handlers.invoke({"cache": param});
		this.__coco_cache.set(param);
		return param;
	}
	static get __name__() {
		return "coconut.ds.IntCollection"
	}
	static get __interfaces__() {
		return [ICollection, Model]
	}
	get __class__() {
		return IntCollection
	}
}


export const StringCollection = Register.global("$hxClasses")["coconut.ds.StringCollection"] = 
class StringCollection extends Register.inherits() {
	new(__coco_init) {
		this._updatePerformed = Signal.trigger();
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.async(function () {
			State.get_value(_gthis.__coco_revision);
			var _g = State.get_value(_gthis.__coco_cache);
			switch (_g._hx_index) {
				case 0:
					return new SyncFuture(new LazyConst(Outcome.Success(_g.v)));
					break
				case 1:
					return Promise__1.next(_gthis.fetch(), function (list) {
						var result = new Array(list.length);
						var _g = 0;
						var _g1 = list.length;
						while (_g < _g1) {
							var i = _g++;
							var data = list[i];
							var k = _gthis.extractKey(data);
							var item = _gthis.map.get(k, null);
							_gthis.updateItem(item, data);
							result[i] = item;
						};
						return new SyncFuture(new LazyConst(Outcome.Success(result)));
					});
					break
				
			};
		}), null);
		AutoObservable.cur = before;
		this.__coco_list = ret;
		this.__coco_revision = State._new(0, null, null, null);
		this.fetch = __coco_init.fetch;
		this.createItem = __coco_init.createItem;
		this.updateItem = __coco_init.updateItem;
		this.extractKey = __coco_init.extractKey;
		var _g = __coco_init.cache;
		this.__coco_cache = State._new((_g == null) ? Option.None : _g, null, null, null);
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var _g = _gthis.createItem;
		var a2 = Option.None;
		var ret = new StringDict({"factory": function (a1) {
			return _g(a1, a2);
		}});
		AutoObservable.cur = before;
		this.map = ret;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"fetch": new ConstObservable(this.fetch), "createItem": new ConstObservable(this.createItem), "updateItem": new ConstObservable(this.updateItem), "extractKey": new ConstObservable(this.extractKey), "list": this.__coco_list, "map": new ConstObservable(this.map), "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	refresh(cache) {
		if (cache == null) {
			cache = Option.None;
		};
		this.set_cache(cache);
		this.set_revision(State.get_value(this.__coco_revision) + 1);
	}
	set_revision(param) {
		this._updatePerformed.handlers.invoke({"revision": param});
		this.__coco_revision.set(param);
		return param;
	}
	set_cache(param) {
		this._updatePerformed.handlers.invoke({"cache": param});
		this.__coco_cache.set(param);
		return param;
	}
	static get __name__() {
		return "coconut.ds.StringCollection"
	}
	static get __interfaces__() {
		return [ICollection, Model]
	}
	get __class__() {
		return StringCollection
	}
}

