import {Invalidatable} from "./Invalidatable.js"
import {AutoObservable} from "./AutoObservable.js"
import {Schedulable, Scheduler} from "../Scheduler.js"
import {LinkObject, Callback} from "../../core/Callback.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Binding = Register.global("$hxClasses")["tink.state.internal.Binding"] = 
class Binding extends Register.inherits() {
	new(data, value, cb, scheduler, comparator) {
		this.last = null;
		this.status = 0;
		this.data = data;
		this.cb = cb;
		this.scheduler = (scheduler == null) ? Scheduler.direct : scheduler;
		var this1 = data.getComparator();
		var tmp;
		var _g = comparator;
		if (this1 == null) {
			tmp = _g;
		} else if (_g == null) {
			tmp = this1;
		} else {
			var c1 = this1;
			var c2 = _g;
			tmp = function (a, b) {
				if (!c1(a, b)) {
					return c2(a, b);
				} else {
					return true;
				};
			};
		};
		this.comparator = tmp;
		this.link = data.onInvalidate(this);
		Callback.invoke(cb, this.last = value);
	}
	cancel() {
		var this1 = this.link;
		if (this1 != null) {
			this1.cancel();
		};
		this.status = 2;
	}
	invalidate() {
		if (this.status == 0) {
			this.status = 1;
			this.scheduler.schedule(this);
		};
	}
	run() {
		switch (this.status) {
			case 1:
				this.status = 0;
				var prev = this.last;
				var next = this.last = this.data.getValue();
				var canFire = this.data.canFire();
				var this1 = this.comparator;
				if (!((this1 == null) ? prev == next : this1(prev, next))) {
					Callback.invoke(this.cb, next);
				};
				if (!canFire) {
					this.cancel();
					this.data = null;
					this.cb = null;
					this.comparator = null;
				};
				break
			case 0:case 2:
				break
			
		};
	}
	static create(o, cb, scheduler, comparator) {
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = o.getValue();
		AutoObservable.cur = before;
		if (o.canFire()) {
			return new Binding(o, ret, cb, scheduler, comparator);
		} else {
			Callback.invoke(cb, ret);
			return null;
		};
	}
	static get __name__() {
		return "tink.state.internal.Binding"
	}
	static get __interfaces__() {
		return [LinkObject, Schedulable, Invalidatable]
	}
	get __class__() {
		return Binding
	}
}

