import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const MqttBase = Register.global("$hxClasses")["why.pubsub.mqtt.MqttBase"] = 
class MqttBase extends Register.inherits() {
	new(client) {
		this.client = client;
	}
	static get __name__() {
		return "why.pubsub.mqtt.MqttBase"
	}
	get __class__() {
		return MqttBase
	}
}

