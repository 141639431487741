import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const DateTools = Register.global("$hxClasses")["dasloop.types.util.DateTools"] = 
class DateTools {
	static previousMonthStart(date) {
		return new Date(date.getFullYear(), date.getMonth() - 1, 1, 0, 0, 0);
	}
	static previousMonthEnd(date) {
		return DateTools.monthStart(date);
	}
	static yearStart(date) {
		return new Date(date.getFullYear(), 0, 1, 0, 0, 0);
	}
	static yearEnd(date) {
		return new Date(date.getFullYear() + 1, 0, 1, 0, 0, 0);
	}
	static monthStart(date) {
		return new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
	}
	static monthEnd(date) {
		return new Date(date.getFullYear(), date.getMonth() + 1, 1, 0, 0, 0);
	}
	static dayStart(date) {
		return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
	}
	static dayEnd(date) {
		return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0, 0);
	}
	static todayStart(date) {
		return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
	}
	static yesterdayStart(date) {
		return new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1, 0, 0, 0);
	}
	static yesterdayEnd(date) {
		return DateTools.dayStart(date);
	}
	static get __name__() {
		return "dasloop.types.util.DateTools"
	}
	get __class__() {
		return DateTools
	}
}

