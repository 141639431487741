import {Coordinates} from "./Coordinates.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Line = Register.global("$hxClasses")["geojson.util._Line.Line"] = 
class Line {
	static is(v) {
		if (!((v) instanceof Array)) {
			return false;
		};
		if (v.length == 0) {
			return false;
		};
		var _g = 0;
		var _g1 = v;
		while (_g < _g1.length) if (!Coordinates.is(_g1[_g++])) {
			return false;
		};
		return true;
	}
	static get __name__() {
		return "geojson.util._Line.Line_Impl_"
	}
	get __class__() {
		return Line
	}
}

