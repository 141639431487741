import {Writer61} from "./Writer61.js"
import {Writer35} from "./Writer35.js"
import {Writer19} from "./Writer19.js"
import {BasicParser, JsonString} from "./Parser.js"
import {OutcomeTools} from "../core/Outcome.js"
import {TypedError} from "../core/Error.js"
import {Exception} from "../../haxe/Exception.js"
import {Register} from "../../genes/Register.js"
import {DeviceId} from "../../dasloop/types/DeviceId.js"

const $global = Register.$global

export const Parser64 = Register.global("$hxClasses")["tink.json.Parser64"] = 
class Parser64 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	pb() {
		var _gthis = this;
		var v_alerts = null;
		var v_expiry = null;
		var v_id = null;
		var hasv_id = false;
		var v_locations = null;
		var v_plant = null;
		var v_readings = null;
		var v_worker = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "alerts":
						if (this.E("null")) {
							v_alerts = null;
						} else {
							this.F("[");
							var __ret = [];
							if (!this.E("]")) {
								while (true) {
									__ret.push(this.pc());
									if (!this.E(",")) {
										break;
									};
								};
								this.F("]");
							};
							v_alerts = __ret;
						};
						break
					case "expiry":
						v_expiry = (this.E("null")) ? null : new Date(JsonString.f(this.r()));
						break
					case "id":
						v_id = OutcomeTools.sure(DeviceId.parse(JsonString.s(this.m())));
						hasv_id = true;
						break
					case "locations":
						if (this.E("null")) {
							v_locations = null;
						} else {
							this.F("[");
							var __ret1 = [];
							if (!this.E("]")) {
								while (true) {
									__ret1.push(this.pd());
									if (!this.E(",")) {
										break;
									};
								};
								this.F("]");
							};
							v_locations = __ret1;
						};
						break
					case "plant":
						v_plant = (this.E("null")) ? null : JsonString.s(this.m());
						break
					case "readings":
						if (this.E("null")) {
							v_readings = null;
						} else {
							this.F("[");
							var __ret2 = [];
							if (!this.E("]")) {
								while (true) {
									__ret2.push(this.pe());
									if (!this.E(",")) {
										break;
									};
								};
								this.F("]");
							};
							v_readings = __ret2;
						};
						break
					case "worker":
						v_worker = (this.E("null")) ? null : this.pf();
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"alerts": v_alerts, "expiry": v_expiry, "id": (hasv_id) ? v_id : __missing__("id"), "locations": v_locations, "plant": v_plant, "readings": v_readings, "worker": v_worker};
	}
	pc() {
		var _gthis = this;
		var v_date = null;
		var hasv_date = false;
		var v_dismissed = false;
		var hasv_dismissed = false;
		var v_ignored = false;
		var hasv_ignored = false;
		var v_type = 0;
		var hasv_type = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "date":
						v_date = new Date(JsonString.f(this.r()));
						hasv_date = true;
						break
					case "dismissed":
						v_dismissed = this.G();
						hasv_dismissed = true;
						break
					case "ignored":
						v_ignored = this.G();
						hasv_ignored = true;
						break
					case "type":
						var v = JsonString.i(this.r());
						switch (v) {
							case 1:case 2:case 4:case 8:case 16:case 64:case 128:case 256:case 512:case 1024:case 65536:
								v_type = v;
								break
							default:
							throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer61().wb([1, 2, 4, 8, 16, 64, 128, 256, 512, 1024, 65536]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 469, "className": "tink.json.Parser64", "methodName": "process1"}));
							
						};
						hasv_type = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"date": (hasv_date) ? v_date : __missing__("date"), "dismissed": (hasv_dismissed) ? v_dismissed : __missing__("dismissed"), "ignored": (hasv_ignored) ? v_ignored : __missing__("ignored"), "type": (hasv_type) ? v_type : __missing__("type")};
	}
	pd() {
		var _gthis = this;
		var v_date = null;
		var hasv_date = false;
		var v_latitude = .0;
		var hasv_latitude = false;
		var v_longitude = .0;
		var hasv_longitude = false;
		var v_range = 0;
		var hasv_range = false;
		var v_strength = null;
		var v_type = null;
		var hasv_type = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "date":
						v_date = new Date(JsonString.f(this.r()));
						hasv_date = true;
						break
					case "latitude":
						v_latitude = JsonString.f(this.r());
						hasv_latitude = true;
						break
					case "longitude":
						v_longitude = JsonString.f(this.r());
						hasv_longitude = true;
						break
					case "range":
						v_range = JsonString.i(this.r());
						hasv_range = true;
						break
					case "strength":
						v_strength = (this.E("null")) ? null : JsonString.i(this.r());
						break
					case "type":
						v_type = JsonString.s(this.m());
						hasv_type = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"date": (hasv_date) ? v_date : __missing__("date"), "latitude": (hasv_latitude) ? v_latitude : __missing__("latitude"), "longitude": (hasv_longitude) ? v_longitude : __missing__("longitude"), "range": (hasv_range) ? v_range : __missing__("range"), "strength": v_strength, "type": (hasv_type) ? v_type : __missing__("type")};
	}
	pe() {
		var _gthis = this;
		var v_date = null;
		var hasv_date = false;
		var v_type = null;
		var hasv_type = false;
		var v_value = .0;
		var hasv_value = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "date":
						v_date = new Date(JsonString.f(this.r()));
						hasv_date = true;
						break
					case "type":
						var v = JsonString.s(this.m());
						switch (v) {
							case "altitude":case "ambient_temperature":case "atmospheric_pressure":case "battery":case "battery_current":case "battery_voltage":case "body_temperature":case "gas_concentration_co":case "gas_concentration_co2":case "gas_concentration_h2s":case "gas_concentration_hc":case "gas_concentration_o2":case "gas_detector_status":case "general_report_interval":case "gps_altitude":case "gsm_level":case "heart_rate":case "heart_rate_quality":case "humidity":case "in_recess_area":case "in_sleep_area":case "live_call_room":case "lpwan_signal_strength":case "lpwan_signal_strength_rssi":case "lpwan_signal_strength_sni":case "module_recover_count":case "network_disconnect_count":case "network_report_cell_id":case "network_report_earfcn":case "network_report_lpwan_signal_strength":case "network_report_mccmnc":case "network_report_nw_provided_edrx":case "network_report_paging_time_window":case "network_report_psm_active_time":case "network_report_psm_periodic_tau":case "network_report_requested_edrx":case "network_report_status":case "network_report_tracking_area_code":case "network_report_wifi_ip_address":case "network_report_wifi_signal_strength":case "ping":case "raw_ambient_temperature_from_humidity_sensor":case "raw_ambient_temperature_from_infrared_sensor":case "raw_ambient_temperature_from_pressure_sensor":case "recess_area_detection_enabled":case "server_disconnect_count":case "short_report_period":case "shutdown":case "sleep_area_detection_enabled":case "special_area_detection_enabled":case "spo2_oxygen_level":case "standstill_token_count":case "standstill_token_duration":case "stationary_time":case "surface_temperature_from_infrared_sensor":case "uwb_detector_operating":case "uwb_detector_powered":case "wearing":case "wearing_time":case "wifi_signal_strength":
								v_type = v;
								break
							default:
							throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer19().wb(["altitude", "ambient_temperature", "atmospheric_pressure", "battery", "battery_current", "body_temperature", "general_report_interval", "short_report_period", "gps_altitude", "gsm_level", "heart_rate", "heart_rate_quality", "humidity", "in_recess_area", "in_sleep_area", "lpwan_signal_strength", "lpwan_signal_strength_rssi", "lpwan_signal_strength_sni", "module_recover_count", "network_disconnect_count", "server_disconnect_count", "raw_ambient_temperature_from_humidity_sensor", "raw_ambient_temperature_from_infrared_sensor", "raw_ambient_temperature_from_pressure_sensor", "recess_area_detection_enabled", "shutdown", "sleep_area_detection_enabled", "special_area_detection_enabled", "spo2_oxygen_level", "standstill_token_count", "standstill_token_duration", "stationary_time", "surface_temperature_from_infrared_sensor", "uwb_detector_operating", "uwb_detector_powered", "wearing", "wearing_time", "wifi_signal_strength", "network_report_status", "network_report_mccmnc", "network_report_tracking_area_code", "network_report_cell_id", "network_report_psm_active_time", "network_report_psm_periodic_tau", "network_report_requested_edrx", "network_report_nw_provided_edrx", "network_report_paging_time_window", "network_report_lpwan_signal_strength", "network_report_wifi_signal_strength", "network_report_wifi_ip_address", "network_report_earfcn", "battery_voltage", "live_call_room", "gas_concentration_hc", "gas_concentration_o2", "gas_concentration_h2s", "gas_concentration_co", "gas_concentration_co2", "gas_detector_status", "ping"]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 469, "className": "tink.json.Parser64", "methodName": "process3"}));
							
						};
						hasv_type = true;
						break
					case "value":
						v_value = JsonString.f(this.r());
						hasv_value = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"date": (hasv_date) ? v_date : __missing__("date"), "type": (hasv_type) ? v_type : __missing__("type"), "value": (hasv_value) ? v_value : __missing__("value")};
	}
	pf() {
		var _gthis = this;
		var v_age = 0;
		var hasv_age = false;
		var v_company = null;
		var hasv_company = false;
		var v_contacts = null;
		var hasv_contacts = false;
		var v_division = null;
		var hasv_division = false;
		var v_email = null;
		var v_gender = null;
		var hasv_gender = false;
		var v_id = null;
		var hasv_id = false;
		var v_imageUrl = null;
		var hasv_imageUrl = false;
		var v_name = null;
		var hasv_name = false;
		var v_phone = null;
		var hasv_phone = false;
		var v_registrationNumber = null;
		var hasv_registrationNumber = false;
		var v_remarks = null;
		var hasv_remarks = false;
		var v_trade = null;
		var hasv_trade = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "age":
						v_age = JsonString.i(this.r());
						hasv_age = true;
						break
					case "company":
						v_company = JsonString.s(this.m());
						hasv_company = true;
						break
					case "contacts":
						this.F("[");
						var __ret = [];
						if (!this.E("]")) {
							while (true) {
								__ret.push(this.pg());
								if (!this.E(",")) {
									break;
								};
							};
							this.F("]");
						};
						v_contacts = __ret;
						hasv_contacts = true;
						break
					case "division":
						v_division = JsonString.s(this.m());
						hasv_division = true;
						break
					case "email":
						v_email = (this.E("null")) ? null : JsonString.s(this.m());
						break
					case "gender":
						var v = JsonString.s(this.m());
						switch (v) {
							case "female":case "male":case "unspecified":
								v_gender = v;
								break
							default:
							throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer35().wb(["male", "female", "unspecified"]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 469, "className": "tink.json.Parser64", "methodName": "process4"}));
							
						};
						hasv_gender = true;
						break
					case "id":
						v_id = JsonString.s(this.m());
						hasv_id = true;
						break
					case "imageUrl":
						v_imageUrl = JsonString.s(this.m());
						hasv_imageUrl = true;
						break
					case "name":
						v_name = JsonString.s(this.m());
						hasv_name = true;
						break
					case "phone":
						v_phone = JsonString.s(this.m());
						hasv_phone = true;
						break
					case "registrationNumber":
						v_registrationNumber = JsonString.s(this.m());
						hasv_registrationNumber = true;
						break
					case "remarks":
						v_remarks = JsonString.s(this.m());
						hasv_remarks = true;
						break
					case "trade":
						v_trade = JsonString.s(this.m());
						hasv_trade = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"age": (hasv_age) ? v_age : __missing__("age"), "company": (hasv_company) ? v_company : __missing__("company"), "contacts": (hasv_contacts) ? v_contacts : __missing__("contacts"), "division": (hasv_division) ? v_division : __missing__("division"), "email": v_email, "gender": (hasv_gender) ? v_gender : __missing__("gender"), "id": (hasv_id) ? v_id : __missing__("id"), "imageUrl": (hasv_imageUrl) ? v_imageUrl : __missing__("imageUrl"), "name": (hasv_name) ? v_name : __missing__("name"), "phone": (hasv_phone) ? v_phone : __missing__("phone"), "registrationNumber": (hasv_registrationNumber) ? v_registrationNumber : __missing__("registrationNumber"), "remarks": (hasv_remarks) ? v_remarks : __missing__("remarks"), "trade": (hasv_trade) ? v_trade : __missing__("trade")};
	}
	pg() {
		var _gthis = this;
		var v_email = null;
		var v_language = null;
		var hasv_language = false;
		var v_name = null;
		var hasv_name = false;
		var v_phone = null;
		var v_role = null;
		var hasv_role = false;
		var v_whatsapp = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "email":
						v_email = (this.E("null")) ? null : JsonString.s(this.m());
						break
					case "language":
						v_language = JsonString.s(this.m());
						hasv_language = true;
						break
					case "name":
						v_name = JsonString.s(this.m());
						hasv_name = true;
						break
					case "phone":
						v_phone = (this.E("null")) ? null : JsonString.s(this.m());
						break
					case "role":
						v_role = JsonString.s(this.m());
						hasv_role = true;
						break
					case "whatsapp":
						v_whatsapp = (this.E("null")) ? null : JsonString.s(this.m());
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"email": v_email, "language": (hasv_language) ? v_language : __missing__("language"), "name": (hasv_name) ? v_name : __missing__("name"), "phone": v_phone, "role": (hasv_role) ? v_role : __missing__("role"), "whatsapp": v_whatsapp};
	}
	ph(source) {
		if (this.e.length > 0) {
			this.e = [];
		};
		this.g(source);
		var ret = this.pb();
		var _g = 0;
		var _g1 = this.e;
		while (_g < _g1.length) _g1[_g++]();
		if (this.e.length > 0) {
			this.e = [];
		};
		return ret;
	}
	pi(source) {
		var _gthis = this;
		return TypedError.catchExceptions(function () {
			var ret = _gthis.ph(source);
			_gthis.h();
			if (_gthis.c < _gthis.d) {
				_gthis.D("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser64", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser64"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser64
	}
}

