import {Portion} from "../url/Portion.js"
import {DefaultKeymaker} from "./Keymaker.js"
import {NamedWith} from "../core/Named.js"
import {Register} from "../../genes/Register.js"
import {Std} from "../../Std.js"

const $global = Register.$global

export const Builder23 = Register.global("$hxClasses")["tink.querystring.Builder23"] = 
class Builder23 extends Register.inherits() {
	new() {
	}
	stringify(data, keymaker) {
		if (keymaker == null) {
			keymaker = new DefaultKeymaker();
		};
		var buffer = [];
		this.process0("", buffer, keymaker, data);
		return buffer;
	}
	process0(prefix, buffer, keymaker, data) {
		var prefix1 = (prefix == "") ? "from" : keymaker.field(prefix, "from");
		var value = Std.string(data._263.getTime());
		buffer.push(new NamedWith(Portion.ofString(prefix1), Portion.ofString(value)));
		var prefix1 = (prefix == "") ? "resolution" : keymaker.field(prefix, "resolution");
		var value = Std.string(data._264);
		buffer.push(new NamedWith(Portion.ofString(prefix1), Portion.ofString(value)));
		this.process1((prefix == "") ? "timezone" : keymaker.field(prefix, "timezone"), buffer, keymaker, data._265);
		var prefix1 = (prefix == "") ? "to" : keymaker.field(prefix, "to");
		var value = Std.string(data._266.getTime());
		buffer.push(new NamedWith(Portion.ofString(prefix1), Portion.ofString(value)));
	}
	process1(prefix, buffer, keymaker, data) {
		var value = Std.string(data / 60);
		buffer.push(new NamedWith(Portion.ofString(prefix), Portion.ofString(value)));
	}
	static get __name__() {
		return "tink.querystring.Builder23"
	}
	get __class__() {
		return Builder23
	}
}

