import {Observable} from "../../../tink/state/Observable.js"
import {Register} from "../../../genes/Register.js"
import {Slot} from "../../../coconut/ui/internal/Slot.js"
import {View} from "../../../coconut/react/View.js"
import {Html} from "../../../coconut/react/Html.js"
import {Std} from "../../../Std.js"

const $global = Register.$global

export const ErrorMessage = Register.global("$hxClasses")["dasloop.ui.general.ErrorMessage"] = 
class ErrorMessage extends Register.inherits(View) {
	new(__coco_data_) {
		var _gthis = this;
		this.__coco_error = new Slot(this, null, null);
		this.__coco_title = new Slot(this, null, null);
		this.__initAttributes(__coco_data_);
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	__coco_render() {
		var __r = [];
		if (Observable.get_value(this.__coco_title) != null) {
			var children = [Observable.get_value(this.__coco_title)];
			__r.push(Html.h("div", {"className": "header"}, children));
		};
		if (Observable.get_value(this.__coco_error).data == null) {
			var children = [this.renderSummary()];
			__r.push(Html.h("p", {}, children));
		} else {
			var __r1 = [];
			var children = [this.renderSummary()];
			var children1 = [Html.h("p", {"style": {"display": "inline-block"}}, children)];
			__r1.push(Html.h("summary", {}, children1));
			var children = [Std.string(Observable.get_value(this.__coco_error).data)];
			__r1.push(Html.h("span", {"style": {"whiteSpace": "pre-wrap"}}, children));
			__r.push(Html.h("details", {}, __r1));
		};
		var children = [Html.h("div", {"className": "content"}, __r)];
		return Html.h("div", {"className": "ui icon visible error message"}, children);
	}
	renderSummary() {
		var __r = [];
		__r.push(Html.h("i", {"className": "exclamation triangle icon"}, null));
		__r.push(Observable.get_value(this.__coco_error).message);
		return View.createFragment({}, __r);
	}
	__initAttributes(attributes) {
		this.__coco_error.setData(attributes.error);
		this.__coco_title.setData(attributes.title);
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": ErrorMessage, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "dasloop.ui.general.ErrorMessage"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return ErrorMessage
	}
}

