import {MultiPolygon} from "../../../geojson/MultiPolygon.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Boundary = Register.global("$hxClasses")["dasloop.core.types._Boundary.Boundary"] = 
class Boundary {
	static fromRepresentation(rep) {
		return MultiPolygon.fromRepresentation(rep);
	}
	static get __name__() {
		return "dasloop.core.types._Boundary.Boundary_Impl_"
	}
	get __class__() {
		return Boundary
	}
}

