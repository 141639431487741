import {NodeIterator, List} from "./List.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Mapping = Register.global("$hxClasses")["tink.pure._Mapping.Mapping"] = 
class Mapping {
	
	/**
	Returns true if `key` has a mapping, false otherwise.
	*/
	static exists(this1, key) {
		var _g = new NodeIterator(this1);
		while (_g.list.length > 0) {
			var p = _g.next();
			if (p.condensed != null) {
				return p.condensed.exists(key);
			} else if (p.key == key) {
				return p.isset;
			};
		};
		return false;
	}
	
	/**
	Returns the value for `key`.
	*/
	static get(this1, key) {
		var _g = new NodeIterator(this1);
		while (_g.list.length > 0) {
			var p = _g.next();
			if (p.condensed != null) {
				return p.condensed.get(key);
			} else if (p.key == key) {
				return p.value;
			};
		};
		return null;
	}
	
	/**
	Clone this `Mapping` and add the specified key-value pair
	*/
	static with(this1, key, value) {
		return List.prepend(this1, {"key": key, "isset": true, "value": value, "condensed": null});
	}
	static get __name__() {
		return "tink.pure._Mapping.Mapping_Impl_"
	}
	get __class__() {
		return Mapping
	}
}

