import {MqttBase} from "./Mqtt.js"
import {Register} from "../../../genes/Register.js"
import {Publishers} from "../../../dasloop/types/pubsub/Public.js"

const $global = Register.$global

export const Publishers0 = Register.global("$hxClasses")["why.pubsub.mqtt.Publishers0"] = 
class Publishers0 extends Register.inherits(MqttBase) {
	new(client) {
		super.new(client);
	}
	static get __name__() {
		return "why.pubsub.mqtt.Publishers0"
	}
	static get __interfaces__() {
		return [Publishers]
	}
	static get __super__() {
		return MqttBase
	}
	get __class__() {
		return Publishers0
	}
}

