import {Transform} from "../tink/state/internal/Transform.js"
import {State} from "../tink/state/State.js"
import {Observable} from "../tink/state/Observable.js"
import {Url} from "../tink/Url.js"
import {Register} from "../genes/Register.js"

const $global = Register.$global

export const Location = Register.global("$hxClasses")["spectatory.Location"] = 
class Location {
	static get __name__() {
		return "spectatory.Location"
	}
	get __class__() {
		return Location
	}
}


Location.href = (function($this) {var $r0
	var s = State._new(window.location.href);
	
			(function () {
				if ( typeof window.CustomEvent === "function" ) return;
				function CustomEvent ( event, params ) {
					params = params || { bubbles: false, cancelable: false, detail: undefined };
					var evt = document.createEvent( "CustomEvent" );
					evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
					return evt;
				}
				CustomEvent.prototype = window.Event.prototype;
				window.CustomEvent = CustomEvent;
			})();
		;
	var update = function (href) {
		if (href != State.get_value(s)) {
			s.set(href);
		};
	};
	var oldPushState = (o=>Register.bind(o, o.pushState))(window.history);
	window.history.pushState = function (data, title, url) {
		oldPushState(data, title, url);
		update(window.location.href);
	};
	var oldReplaceState = (o=>Register.bind(o, o.replaceState))(window.history);
	window.history.replaceState = function (data, title, url) {
		oldReplaceState(data, title, url);
		update(window.location.href);
	};
	window.addEventListener("popstate", function (_) {
		update(window.location.href);
	});
	window.addEventListener("hashchange", function (e) {
		update(e.newURL);
	});
	
	$r0 = s
	return $r0})(this)
Location.url = Observable.map(Location.href, Transform.plain(function (href) {
	return Url.parse(href);
}))