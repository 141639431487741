import {Register} from "../genes/Register.js"
import {Std} from "../Std.js"

const $global = Register.$global

export const Toast = Register.global("$hxClasses")["why._Toast.Toast"] = 
class Toast {
	static success(this1, message, duration) {
		if (duration == null) {
			duration = Duration.Long;
		};
		this1.show({"title": message, "type": ToastType.Success, "duration": duration});
	}
	static outcome(this1, outcome, successMessage, duration, opt) {
		if (duration == null) {
			duration = Duration.Long;
		};
		switch (outcome._hx_index) {
			case 0:
				Toast.success(this1, successMessage, duration);
				break
			case 1:
				Toast.error(this1, outcome.failure, duration, opt);
				break
			
		};
	}
	static error(this1, e, duration, opt) {
		if (duration == null) {
			duration = Duration.Long;
		};
		this1.show({"title": e.message + ((opt == null || !opt.includeDetails || e.data == null) ? "" : " " + Std.string(e.data)), "type": ToastType.Error, "duration": duration});
	}
	static get __name__() {
		return "why._Toast.Toast_Impl_"
	}
	get __class__() {
		return Toast
	}
}


export const ToastObject = function() {};
ToastObject.__isInterface__ = true;

export const Duration = 
Register.global("$hxEnums")["why.Duration"] = 
{
	__ename__: "why.Duration",
	
	Short: {_hx_name: "Short", _hx_index: 0, __enum__: "why.Duration"},
	Long: {_hx_name: "Long", _hx_index: 1, __enum__: "why.Duration"},
	Custom: Object.assign((ms) => ({_hx_index: 2, __enum__: "why.Duration", "ms": ms}), {_hx_name: "Custom", __params__: ["ms"]}),
	Indefinite: {_hx_name: "Indefinite", _hx_index: 3, __enum__: "why.Duration"}
}
Duration.__constructs__ = [Duration.Short, Duration.Long, Duration.Custom, Duration.Indefinite]
Duration.__empty_constructs__ = [Duration.Short, Duration.Long, Duration.Indefinite]

export const ToastType = 
Register.global("$hxEnums")["why.ToastType"] = 
{
	__ename__: "why.ToastType",
	
	Success: {_hx_name: "Success", _hx_index: 0, __enum__: "why.ToastType"},
	Warning: {_hx_name: "Warning", _hx_index: 1, __enum__: "why.ToastType"},
	Info: {_hx_name: "Info", _hx_index: 2, __enum__: "why.ToastType"},
	Error: {_hx_name: "Error", _hx_index: 3, __enum__: "why.ToastType"}
}
ToastType.__constructs__ = [ToastType.Success, ToastType.Warning, ToastType.Info, ToastType.Error]
ToastType.__empty_constructs__ = [ToastType.Success, ToastType.Warning, ToastType.Info, ToastType.Error]
