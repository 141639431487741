import {Portion} from "../url/Portion.js"
import {ParserBase, Field, FieldTools} from "./Parser.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Parser1 = Register.global("$hxClasses")["tink.querystring.Parser1"] = 
class Parser1 extends Register.inherits(ParserBase) {
	new(onError, pos) {
		super.new(onError, pos);
	}
	getName(p) {
		return p.name;
	}
	getValue(p) {
		return p.value;
	}
	parse(input) {
		this.init(input, Register.bind(this, this.getName), Register.bind(this, this.getValue));
		return this.process0("", Field.Sub(this.root));
	}
	process0(name, field) {
		return {"hash": Portion.toString(FieldTools.getValue(FieldTools.getSub(field).inst.get("hash")))};
	}
	static get __name__() {
		return "tink.querystring.Parser1"
	}
	static get __super__() {
		return ParserBase
	}
	get __class__() {
		return Parser1
	}
}

