import {Provider} from "./Provider.js"
import {Promise as Promise__1, Next} from "../../tink/core/Promise.js"
import {Outcome} from "../../tink/core/Outcome.js"
import {TypedError} from "../../tink/core/Error.js"
import {Option} from "../../haxe/ds/Option.js"
import {Exception} from "../../haxe/Exception.js"
import {Register} from "../../genes/Register.js"
import {Type, ValueType} from "../../Type.js"
import {Reflect as Reflect__1} from "../../Reflect.js"
import {FluentResource, FluentBundle} from "@fluent/bundle"

const $global = Register.$global

export const FluentLocaleBase = Register.global("$hxClasses")["turnwing.provider.FluentLocaleBase"] = 
class FluentLocaleBase extends Register.inherits() {
	new(bundle, prefix) {
		this.__bundle__ = bundle;
		this.__prefix__ = prefix;
	}
	__exec__(id, params) {
		var this1 = this.__prefix__;
		return this.__bundle__.formatPattern(this.__bundle__.getMessage((this1 == "") ? id : this1 + "-" + id).value, this.__sanitize__(params));
	}
	__sanitize__(params) {
		var ret = {};
		var _g_keys = Reflect__1.fields(params);
		var _g_index = 0;
		while (_g_index < _g_keys.length) {
			var key = _g_keys[_g_index++];
			var _g1_value = params[key];
			ret[key] = (Type["typeof"](_g1_value) == ValueType.TBool) ? (_g1_value) ? 1 : 0 : _g1_value;
		};
		return ret;
	}
	static get __name__() {
		return "turnwing.provider.FluentLocaleBase"
	}
	get __class__() {
		return FluentLocaleBase
	}
}


export const FluentProviderBase = Register.global("$hxClasses")["turnwing.provider.FluentProviderBase"] = 
class FluentProviderBase extends Register.inherits() {
	new(source, opt) {
		this.source = source;
		if (opt != null) {
			this.opt = opt;
		};
	}
	prepare(language) {
		var _g = Register.bind(this, this.bundle);
		var language1 = language;
		var tmp = function (ftl) {
			return _g(language1, ftl);
		};
		return Promise__1.next(Promise__1.next(this.source.fetch(language), Next.ofSafe(tmp)), Next.ofSafeSync(Register.bind(this, this.make)));
	}
	bundle(language, ftl) {
		if (ftl == null) {
			return Outcome.Failure(new TypedError(null, "Empty ftl data", {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/turnwing/2.0.0/github/e372a2980cad86d4c0d0daf94e67e8587be8d40f/src/turnwing/provider/FluentProvider.hx", "lineNumber": 40, "className": "turnwing.provider.FluentProviderBase", "methodName": "bundle"}));
		} else {
			return this.validate(new FluentContext(ftl, language, this.opt));
		};
	}
	validate(ctx) {
		throw Exception.thrown("abstract");
	}
	validateMessage(ctx, id, suppliedVariables) {
		var _g = ctx.bundle.getMessage(id);
		if (_g == null) {
			return Option.Some(TypedError.withData(null, "Missing Message \"" + id + "\"", {"source": ctx.source}, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/turnwing/2.0.0/github/e372a2980cad86d4c0d0daf94e67e8587be8d40f/src/turnwing/provider/FluentProvider.hx", "lineNumber": 148, "className": "turnwing.provider.FluentContext", "methodName": "makeError"}));
		} else {
			return this.validatePattern(ctx, _g.value, "Message \"" + id + "\"", suppliedVariables, true);
		};
	}
	validatePattern(ctx, pattern, location, suppliedVariables, root) {
		if (root == null) {
			root = false;
		};
		if (((pattern) instanceof Array)) {
			var _g = 0;
			var _g1 = pattern;
			while (_g < _g1.length) {
				var element = _g1[_g];
				++_g;
				var _g2 = element.type;
				if (_g2 != null) {
					switch (_g2) {
						case "func":
							break
						case "mesg":
							break
						case "narg":
							break
						case "num":
							break
						case "select":
							var select = element;
							if (root || select.selector.type != "var") {
								var _g3 = this.validatePattern(ctx, [select.selector], location, suppliedVariables);
								switch (_g3._hx_index) {
									case 0:
										return Option.Some(_g3.v);
										break
									case 1:
										break
									
								};
							};
							var _g4 = FluentProviderBase.exprToSyntax(select.selector);
							var name = (_g4 == null) ? "selector" : _g4;
							var _g5 = 0;
							var _g6 = select.variants;
							while (_g5 < _g6.length) {
								var v = _g6[_g5];
								++_g5;
								var _g7 = this.validatePattern(ctx, v.value, "" + location + " : " + name + " -> [" + FluentProviderBase.exprToSyntax(v.key) + "]", suppliedVariables);
								switch (_g7._hx_index) {
									case 0:
										return Option.Some(_g7.v);
										break
									case 1:
										break
									
								};
							};
							break
						case "str":
							break
						case "term":
							var term = element;
							if (!ctx.bundle._terms.has("-" + term.name)) {
								return Option.Some(TypedError.withData(null, "Term \"" + term.name + "\" does not exist. (Required by " + location + ")", {"source": ctx.source}, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/turnwing/2.0.0/github/e372a2980cad86d4c0d0daf94e67e8587be8d40f/src/turnwing/provider/FluentProvider.hx", "lineNumber": 148, "className": "turnwing.provider.FluentContext", "methodName": "makeError"}));
							};
							break
						case "var":
							var variable = element;
							if (suppliedVariables.indexOf(variable.name) == -1) {
								return Option.Some(TypedError.withData(null, "Superfluous variable \"" + variable.name + "\". (Not provided in the Locale interface)", {"source": ctx.source}, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/turnwing/2.0.0/github/e372a2980cad86d4c0d0daf94e67e8587be8d40f/src/turnwing/provider/FluentProvider.hx", "lineNumber": 148, "className": "turnwing.provider.FluentContext", "methodName": "makeError"}));
							};
							break
						
					};
				};
			};
		};
		return Option.None;
	}
	make(bundle) {
		throw Exception.thrown("abstract");
	}
	static exprToSyntax(e) {
		var _g = e.type;
		if (_g == null) {
			return null;
		} else {
			switch (_g) {
				case "mesg":
					return e.name;
					break
				case "num":
					return e.value + "";
					break
				case "str":
					return e.value;
					break
				case "term":
					return "-" + e.name;
					break
				case "var":
					return "$" + e.name;
					break
				default:
				return null;
				
			};
		};
	}
	static get __name__() {
		return "turnwing.provider.FluentProviderBase"
	}
	static get __interfaces__() {
		return [Provider]
	}
	get __class__() {
		return FluentProviderBase
	}
}


export const FluentContext = Register.global("$hxClasses")["turnwing.provider.FluentContext"] = 
class FluentContext extends Register.inherits() {
	new(ftl, language, opt) {
		this.source = ftl;
		this.resource = new FluentResource(ftl);
		this.bundle = new FluentBundle(language, opt);
		this.bundle.addResource(this.resource);
	}
	static get __name__() {
		return "turnwing.provider.FluentContext"
	}
	get __class__() {
		return FluentContext
	}
}

