import {Writer22} from "./Writer22.js"
import {Writer21} from "./Writer21.js"
import {Writer20} from "./Writer20.js"
import {Writer19} from "./Writer19.js"
import {Parser29} from "./Parser29.js"
import {Parser28} from "./Parser28.js"
import {BasicParser, JsonString} from "./Parser.js"
import {OutcomeTools} from "../core/Outcome.js"
import {TypedError} from "../core/Error.js"
import {Exception} from "../../haxe/Exception.js"
import {Register} from "../../genes/Register.js"
import {DeviceUpdate} from "../../dasloop/types/pubsub/DeviceUpdate.js"
import {LocationSource} from "../../dasloop/types/LocationSource.js"
import {GasReading} from "../../dasloop/types/GasReading.js"
import {DeviceId} from "../../dasloop/types/DeviceId.js"
import {AlertValue} from "../../dasloop/types/AlertValue.js"
import {Std} from "../../Std.js"

const $global = Register.$global

export const Parser97 = Register.global("$hxClasses")["tink.json.Parser97"] = 
class Parser97 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	pb() {
		var _gthis = this;
		var v_alert = null;
		var v_location = null;
		var v_reading = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "alert":
						v_alert = (this.E("null")) ? null : this.pc();
						break
					case "location":
						v_location = (this.E("null")) ? null : this.pj();
						break
					case "reading":
						v_reading = (this.E("null")) ? null : this.pm();
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {"alert": v_alert, "location": v_location, "reading": v_reading};
	}
	pc() {
		var _gthis = this;
		var v_date = null;
		var hasv_date = false;
		var v_device = null;
		var hasv_device = false;
		var v_value = null;
		var hasv_value = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "date":
						v_date = new Date(JsonString.f(this.r()));
						hasv_date = true;
						break
					case "device":
						v_device = JsonString.s(this.m());
						hasv_device = true;
						break
					case "value":
						v_value = this.pd();
						hasv_value = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"date": (hasv_date) ? v_date : __missing__("date"), "device": (hasv_device) ? v_device : __missing__("device"), "value": (hasv_value) ? v_value : __missing__("value")};
	}
	pd() {
		var __ret = this.pe();
		var _g = __ret.accelerationThreshold;
		var _g1 = __ret.actual;
		var _g2 = __ret.actualX;
		var _g3 = __ret.actualY;
		var _g4 = __ret.actualZ;
		var _g5 = __ret.area;
		var _g6 = __ret.device;
		var _g7 = __ret.distance;
		var _g8 = __ret.durationThreshold;
		var _g9 = __ret.gasAlarmData;
		var _g10 = __ret.id;
		var _g11 = __ret.legacy;
		var _g12 = __ret.quality;
		var _g13 = __ret.serialNumber;
		var _g14 = __ret.shutdownReason;
		var _g15 = __ret.threshold;
		var _g16 = __ret.timeout;
		var _g17 = __ret.type;
		var _g18 = __ret.uwb;
		var _g19 = __ret.value;
		if (_g11 == null) {
			if (_g17 == null) {
				throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
			} else {
				switch (_g17) {
					case 1:
						return AlertValue.PanicButton;
						break
					case 2:
						if (_g != null && _g2 != null && _g3 != null && _g4 != null && _g8 != null) {
							return AlertValue.FreeFall({"accelerationThreshold": _g, "actualX": _g2, "actualY": _g3, "actualZ": _g4, "durationThreshold": _g8});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 3:
						if (_g1 != null && _g15 != null) {
							return AlertValue.BodyTempTooHigh({"actual": new Parser28().pb(_g1), "threshold": new Parser28().pb(_g15)});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 4:
						if (_g1 != null && _g15 != null) {
							return AlertValue.BodyTempTooLow({"actual": new Parser28().pb(_g1), "threshold": new Parser28().pb(_g15)});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 5:
						if (_g1 != null && _g15 != null) {
							return AlertValue.HeartRateTooHigh({"actual": new Parser29().pb(_g1), "quality": _g12, "threshold": new Parser29().pb(_g15)});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 6:
						if (_g1 != null && _g15 != null) {
							return AlertValue.HeartRateTooLow({"actual": new Parser29().pb(_g1), "quality": _g12, "threshold": new Parser29().pb(_g15)});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 7:
						if (_g1 != null && _g15 != null) {
							return AlertValue.HeatStress({"actual": new Parser29().pb(_g1), "threshold": new Parser29().pb(_g15)});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 8:
						if (_g15 != null) {
							return AlertValue.Standstill({"threshold": new Parser29().pb(_g15)});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 9:
						if (_g5 != null) {
							return AlertValue.RestrictedArea({"area": _g5});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 10:
						if (_g6 != null && _g7 != null && _g10 != null) {
							return AlertValue.UwbEnter({"device": _g6, "distance": _g7, "id": _g10, "serialNumber": _g13});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 11:
						if (_g6 != null && _g7 != null && _g10 != null) {
							return AlertValue.UwbExit({"device": _g6, "distance": _g7, "id": _g10, "serialNumber": _g13});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 12:
						if (_g16 != null) {
							return AlertValue.RegularSignalMissing({"shutdownReason": _g14, "timeout": _g16});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 13:
						if (_g1 != null && _g15 != null) {
							return AlertValue.CurrentTooHigh({"actual": new Parser28().pb(_g1), "threshold": new Parser28().pb(_g15)});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 14:
						if (_g1 != null && _g15 != null) {
							return AlertValue.CurrentTooLow({"actual": new Parser28().pb(_g1), "threshold": new Parser28().pb(_g15)});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 15:
						if (_g1 != null && _g15 != null) {
							return AlertValue.TemperatureTooHigh({"actual": new Parser28().pb(_g1), "threshold": new Parser28().pb(_g15)});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 16:
						if (_g1 != null && _g15 != null) {
							return AlertValue.TemperatureTooLow({"actual": new Parser28().pb(_g1), "threshold": new Parser28().pb(_g15)});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 17:
						if (_g9 != null) {
							return AlertValue.GasConcentrationAbnormal(_g9);
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 18:
						if (_g18 != null) {
							return AlertValue.UwbEnterAuthMuteZone(_g18);
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 19:
						if (_g18 != null) {
							return AlertValue.UwbExitAuthMuteZone(_g18);
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					default:
					throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
					
				};
			};
		} else if (_g11 == true) {
			if (_g19 != null) {
				return AlertValue.Legacy(_g19);
			} else if (_g17 == null) {
				throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
			} else {
				switch (_g17) {
					case 1:
						return AlertValue.PanicButton;
						break
					case 2:
						if (_g != null && _g2 != null && _g3 != null && _g4 != null && _g8 != null) {
							return AlertValue.FreeFall({"accelerationThreshold": _g, "actualX": _g2, "actualY": _g3, "actualZ": _g4, "durationThreshold": _g8});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 3:
						if (_g1 != null && _g15 != null) {
							return AlertValue.BodyTempTooHigh({"actual": new Parser28().pb(_g1), "threshold": new Parser28().pb(_g15)});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 4:
						if (_g1 != null && _g15 != null) {
							return AlertValue.BodyTempTooLow({"actual": new Parser28().pb(_g1), "threshold": new Parser28().pb(_g15)});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 5:
						if (_g1 != null && _g15 != null) {
							return AlertValue.HeartRateTooHigh({"actual": new Parser29().pb(_g1), "quality": _g12, "threshold": new Parser29().pb(_g15)});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 6:
						if (_g1 != null && _g15 != null) {
							return AlertValue.HeartRateTooLow({"actual": new Parser29().pb(_g1), "quality": _g12, "threshold": new Parser29().pb(_g15)});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 7:
						if (_g1 != null && _g15 != null) {
							return AlertValue.HeatStress({"actual": new Parser29().pb(_g1), "threshold": new Parser29().pb(_g15)});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 8:
						if (_g15 != null) {
							return AlertValue.Standstill({"threshold": new Parser29().pb(_g15)});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 9:
						if (_g5 != null) {
							return AlertValue.RestrictedArea({"area": _g5});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 10:
						if (_g6 != null && _g7 != null && _g10 != null) {
							return AlertValue.UwbEnter({"device": _g6, "distance": _g7, "id": _g10, "serialNumber": _g13});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 11:
						if (_g6 != null && _g7 != null && _g10 != null) {
							return AlertValue.UwbExit({"device": _g6, "distance": _g7, "id": _g10, "serialNumber": _g13});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 12:
						if (_g16 != null) {
							return AlertValue.RegularSignalMissing({"shutdownReason": _g14, "timeout": _g16});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 13:
						if (_g1 != null && _g15 != null) {
							return AlertValue.CurrentTooHigh({"actual": new Parser28().pb(_g1), "threshold": new Parser28().pb(_g15)});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 14:
						if (_g1 != null && _g15 != null) {
							return AlertValue.CurrentTooLow({"actual": new Parser28().pb(_g1), "threshold": new Parser28().pb(_g15)});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 15:
						if (_g1 != null && _g15 != null) {
							return AlertValue.TemperatureTooHigh({"actual": new Parser28().pb(_g1), "threshold": new Parser28().pb(_g15)});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 16:
						if (_g1 != null && _g15 != null) {
							return AlertValue.TemperatureTooLow({"actual": new Parser28().pb(_g1), "threshold": new Parser28().pb(_g15)});
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 17:
						if (_g9 != null) {
							return AlertValue.GasConcentrationAbnormal(_g9);
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 18:
						if (_g18 != null) {
							return AlertValue.UwbEnterAuthMuteZone(_g18);
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					case 19:
						if (_g18 != null) {
							return AlertValue.UwbExitAuthMuteZone(_g18);
						} else {
							throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
						};
						break
					default:
					throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
					
				};
			};
		} else if (_g17 == null) {
			throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
		} else {
			switch (_g17) {
				case 1:
					return AlertValue.PanicButton;
					break
				case 2:
					if (_g != null && _g2 != null && _g3 != null && _g4 != null && _g8 != null) {
						return AlertValue.FreeFall({"accelerationThreshold": _g, "actualX": _g2, "actualY": _g3, "actualZ": _g4, "durationThreshold": _g8});
					} else {
						throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
					};
					break
				case 3:
					if (_g1 != null && _g15 != null) {
						return AlertValue.BodyTempTooHigh({"actual": new Parser28().pb(_g1), "threshold": new Parser28().pb(_g15)});
					} else {
						throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
					};
					break
				case 4:
					if (_g1 != null && _g15 != null) {
						return AlertValue.BodyTempTooLow({"actual": new Parser28().pb(_g1), "threshold": new Parser28().pb(_g15)});
					} else {
						throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
					};
					break
				case 5:
					if (_g1 != null && _g15 != null) {
						return AlertValue.HeartRateTooHigh({"actual": new Parser29().pb(_g1), "quality": _g12, "threshold": new Parser29().pb(_g15)});
					} else {
						throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
					};
					break
				case 6:
					if (_g1 != null && _g15 != null) {
						return AlertValue.HeartRateTooLow({"actual": new Parser29().pb(_g1), "quality": _g12, "threshold": new Parser29().pb(_g15)});
					} else {
						throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
					};
					break
				case 7:
					if (_g1 != null && _g15 != null) {
						return AlertValue.HeatStress({"actual": new Parser29().pb(_g1), "threshold": new Parser29().pb(_g15)});
					} else {
						throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
					};
					break
				case 8:
					if (_g15 != null) {
						return AlertValue.Standstill({"threshold": new Parser29().pb(_g15)});
					} else {
						throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
					};
					break
				case 9:
					if (_g5 != null) {
						return AlertValue.RestrictedArea({"area": _g5});
					} else {
						throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
					};
					break
				case 10:
					if (_g6 != null && _g7 != null && _g10 != null) {
						return AlertValue.UwbEnter({"device": _g6, "distance": _g7, "id": _g10, "serialNumber": _g13});
					} else {
						throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
					};
					break
				case 11:
					if (_g6 != null && _g7 != null && _g10 != null) {
						return AlertValue.UwbExit({"device": _g6, "distance": _g7, "id": _g10, "serialNumber": _g13});
					} else {
						throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
					};
					break
				case 12:
					if (_g16 != null) {
						return AlertValue.RegularSignalMissing({"shutdownReason": _g14, "timeout": _g16});
					} else {
						throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
					};
					break
				case 13:
					if (_g1 != null && _g15 != null) {
						return AlertValue.CurrentTooHigh({"actual": new Parser28().pb(_g1), "threshold": new Parser28().pb(_g15)});
					} else {
						throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
					};
					break
				case 14:
					if (_g1 != null && _g15 != null) {
						return AlertValue.CurrentTooLow({"actual": new Parser28().pb(_g1), "threshold": new Parser28().pb(_g15)});
					} else {
						throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
					};
					break
				case 15:
					if (_g1 != null && _g15 != null) {
						return AlertValue.TemperatureTooHigh({"actual": new Parser28().pb(_g1), "threshold": new Parser28().pb(_g15)});
					} else {
						throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
					};
					break
				case 16:
					if (_g1 != null && _g15 != null) {
						return AlertValue.TemperatureTooLow({"actual": new Parser28().pb(_g1), "threshold": new Parser28().pb(_g15)});
					} else {
						throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
					};
					break
				case 17:
					if (_g9 != null) {
						return AlertValue.GasConcentrationAbnormal(_g9);
					} else {
						throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
					};
					break
				case 18:
					if (_g18 != null) {
						return AlertValue.UwbEnterAuthMuteZone(_g18);
					} else {
						throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
					};
					break
				case 19:
					if (_g18 != null) {
						return AlertValue.UwbExitAuthMuteZone(_g18);
					} else {
						throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
					};
					break
				default:
				throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process2"}));
				
			};
		};
	}
	pe() {
		var _gthis = this;
		var v_accelerationThreshold = null;
		var v_actual = null;
		var v_actualX = null;
		var v_actualY = null;
		var v_actualZ = null;
		var v_area = null;
		var v_device = null;
		var v_distance = null;
		var v_durationThreshold = null;
		var v_gasAlarmData = null;
		var v_id = null;
		var v_legacy = null;
		var v_quality = null;
		var v_serialNumber = null;
		var v_shutdownReason = null;
		var v_threshold = null;
		var v_timeout = null;
		var v_type = null;
		var v_uwb = null;
		var v_value = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "accelerationThreshold":
						v_accelerationThreshold = (this.E("null")) ? null : JsonString.i(this.r());
						break
					case "actual":
						v_actual = this.y();
						break
					case "actualX":
						v_actualX = (this.E("null")) ? null : JsonString.i(this.r());
						break
					case "actualY":
						v_actualY = (this.E("null")) ? null : JsonString.i(this.r());
						break
					case "actualZ":
						v_actualZ = (this.E("null")) ? null : JsonString.i(this.r());
						break
					case "area":
						v_area = (this.E("null")) ? null : JsonString.s(this.m());
						break
					case "device":
						if (this.E("null")) {
							v_device = null;
						} else {
							var v = JsonString.i(this.r());
							switch (v) {
								case 0:case 1:
									v_device = v;
									break
								default:
								throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer21().wb([0, 1]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 469, "className": "tink.json.Parser97", "methodName": "process3"}));
								
							};
						};
						break
					case "distance":
						v_distance = (this.E("null")) ? null : JsonString.i(this.r());
						break
					case "durationThreshold":
						v_durationThreshold = (this.E("null")) ? null : JsonString.f(this.r());
						break
					case "gasAlarmData":
						v_gasAlarmData = (this.E("null")) ? null : this.pf();
						break
					case "id":
						v_id = (this.E("null")) ? null : JsonString.i(this.r());
						break
					case "legacy":
						v_legacy = (this.E("null")) ? null : this.G();
						break
					case "quality":
						v_quality = (this.E("null")) ? null : JsonString.i(this.r());
						break
					case "serialNumber":
						v_serialNumber = (this.E("null")) ? null : OutcomeTools.sure(DeviceId.parse(JsonString.s(this.m())));
						break
					case "shutdownReason":
						v_shutdownReason = (this.E("null")) ? null : JsonString.i(this.r());
						break
					case "threshold":
						v_threshold = this.y();
						break
					case "timeout":
						v_timeout = (this.E("null")) ? null : JsonString.i(this.r());
						break
					case "type":
						if (this.E("null")) {
							v_type = null;
						} else {
							var v1 = JsonString.i(this.r());
							switch (v1) {
								case 1:case 2:case 3:case 4:case 5:case 6:case 7:case 8:case 9:case 10:case 11:case 12:case 13:case 14:case 15:case 16:case 17:case 18:case 19:
									v_type = v1;
									break
								default:
								throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v1 + ". Accepted values are: " + new Writer20().wb([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 469, "className": "tink.json.Parser97", "methodName": "process3"}));
								
							};
						};
						break
					case "uwb":
						v_uwb = (this.E("null")) ? null : this.pi();
						break
					case "value":
						if (this.E("null")) {
							v_value = null;
						} else {
							var v2 = JsonString.i(this.r());
							switch (v2) {
								case 1:case 2:case 3:case 4:case 5:case 6:case 7:case 8:case 9:case 10:case 11:case 12:case 13:case 14:case 15:case 16:case 17:case 18:case 19:
									v_value = v2;
									break
								default:
								throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v2 + ". Accepted values are: " + new Writer20().wb([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 469, "className": "tink.json.Parser97", "methodName": "process3"}));
								
							};
						};
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {"accelerationThreshold": v_accelerationThreshold, "actual": v_actual, "actualX": v_actualX, "actualY": v_actualY, "actualZ": v_actualZ, "area": v_area, "device": v_device, "distance": v_distance, "durationThreshold": v_durationThreshold, "gasAlarmData": v_gasAlarmData, "id": v_id, "legacy": v_legacy, "quality": v_quality, "serialNumber": v_serialNumber, "shutdownReason": v_shutdownReason, "threshold": v_threshold, "timeout": v_timeout, "type": v_type, "uwb": v_uwb, "value": v_value};
	}
	pf() {
		var _gthis = this;
		var v_alarmStatus = 0;
		var hasv_alarmStatus = false;
		var v_reading = null;
		var hasv_reading = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "alarmStatus":
						v_alarmStatus = JsonString.i(this.r());
						hasv_alarmStatus = true;
						break
					case "reading":
						v_reading = this.pg();
						hasv_reading = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"alarmStatus": (hasv_alarmStatus) ? v_alarmStatus : __missing__("alarmStatus"), "reading": (hasv_reading) ? v_reading : __missing__("reading")};
	}
	pg() {
		var __ret = this.ph();
		var _g = __ret.percent;
		var _g1 = __ret.ppm;
		var _g2 = __ret.type;
		if (_g2 == null) {
			throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process5"}));
		} else {
			switch (_g2) {
				case 0:
					if (_g != null) {
						return GasReading.HC(_g);
					} else {
						throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process5"}));
					};
					break
				case 1:
					if (_g != null) {
						return GasReading.O2(_g);
					} else {
						throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process5"}));
					};
					break
				case 2:
					if (_g1 != null) {
						return GasReading.H2S(_g1);
					} else {
						throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process5"}));
					};
					break
				case 3:
					if (_g1 != null) {
						return GasReading.CO(_g1);
					} else {
						throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process5"}));
					};
					break
				case 4:
					if (_g1 != null) {
						return GasReading.CO2(_g1);
					} else {
						throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process5"}));
					};
					break
				default:
				throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process5"}));
				
			};
		};
	}
	ph() {
		var _gthis = this;
		var v_percent = null;
		var v_ppm = null;
		var v_type = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "percent":
						v_percent = (this.E("null")) ? null : JsonString.f(this.r());
						break
					case "ppm":
						v_ppm = (this.E("null")) ? null : JsonString.f(this.r());
						break
					case "type":
						if (this.E("null")) {
							v_type = null;
						} else {
							var v = JsonString.i(this.r());
							switch (v) {
								case 0:case 1:case 2:case 3:case 4:
									v_type = v;
									break
								default:
								throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer22().wb([0, 1, 2, 3, 4]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 469, "className": "tink.json.Parser97", "methodName": "process6"}));
								
							};
						};
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {"percent": v_percent, "ppm": v_ppm, "type": v_type};
	}
	pi() {
		var _gthis = this;
		var v_device = 0;
		var hasv_device = false;
		var v_distance = 0;
		var hasv_distance = false;
		var v_id = 0;
		var hasv_id = false;
		var v_serialNumber = null;
		var hasv_serialNumber = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "device":
						var v = JsonString.i(this.r());
						switch (v) {
							case 0:case 1:
								v_device = v;
								break
							default:
							throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer21().wb([0, 1]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 469, "className": "tink.json.Parser97", "methodName": "process7"}));
							
						};
						hasv_device = true;
						break
					case "distance":
						v_distance = JsonString.i(this.r());
						hasv_distance = true;
						break
					case "id":
						v_id = JsonString.i(this.r());
						hasv_id = true;
						break
					case "serialNumber":
						v_serialNumber = (this.E("null")) ? null : OutcomeTools.sure(DeviceId.parse(JsonString.s(this.m())));
						hasv_serialNumber = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"device": (hasv_device) ? v_device : __missing__("device"), "distance": (hasv_distance) ? v_distance : __missing__("distance"), "id": (hasv_id) ? v_id : __missing__("id"), "serialNumber": (hasv_serialNumber) ? v_serialNumber : __missing__("serialNumber")};
	}
	pj() {
		var _gthis = this;
		var v_date = null;
		var hasv_date = false;
		var v_device = null;
		var hasv_device = false;
		var v_point = null;
		var hasv_point = false;
		var v_source = null;
		var hasv_source = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "date":
						v_date = new Date(JsonString.f(this.r()));
						hasv_date = true;
						break
					case "device":
						v_device = JsonString.s(this.m());
						hasv_device = true;
						break
					case "point":
						this.F("[");
						var __ret = [];
						if (!this.E("]")) {
							while (true) {
								__ret.push(JsonString.f(this.r()));
								if (!this.E(",")) {
									break;
								};
							};
							this.F("]");
						};
						v_point = __ret;
						hasv_point = true;
						break
					case "source":
						v_source = this.pk();
						hasv_source = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"date": (hasv_date) ? v_date : __missing__("date"), "device": (hasv_device) ? v_device : __missing__("device"), "point": (hasv_point) ? v_point : __missing__("point"), "source": (hasv_source) ? v_source : __missing__("source")};
	}
	pk() {
		var __ret = this.pl();
		var _g = __ret.fix;
		var _g1 = __ret.satellites;
		var _g2 = __ret.speed;
		var _g3 = __ret.strength;
		var _g4 = __ret.type;
		if (_g4 == null) {
			throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process9"}));
		} else {
			switch (_g4) {
				case "aoa-ble":
					return LocationSource.AoaBle;
					break
				case "beacon":
					return LocationSource.Beacon;
					break
				case "gps":
					return LocationSource.Gps(_g, _g1, _g3, _g2);
					break
				default:
				throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser97", "methodName": "process9"}));
				
			};
		};
	}
	pl() {
		var _gthis = this;
		var v_fix = null;
		var v_satellites = null;
		var v_speed = null;
		var v_strength = null;
		var v_type = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "fix":
						v_fix = (this.E("null")) ? null : JsonString.i(this.r());
						break
					case "satellites":
						v_satellites = (this.E("null")) ? null : JsonString.i(this.r());
						break
					case "speed":
						v_speed = (this.E("null")) ? null : JsonString.i(this.r());
						break
					case "strength":
						v_strength = (this.E("null")) ? null : JsonString.i(this.r());
						break
					case "type":
						v_type = (this.E("null")) ? null : JsonString.s(this.m());
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {"fix": v_fix, "satellites": v_satellites, "speed": v_speed, "strength": v_strength, "type": v_type};
	}
	pm() {
		var _gthis = this;
		var v_date = null;
		var hasv_date = false;
		var v_device = null;
		var hasv_device = false;
		var v_type = null;
		var hasv_type = false;
		var v_value = .0;
		var hasv_value = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "date":
						v_date = new Date(JsonString.f(this.r()));
						hasv_date = true;
						break
					case "device":
						v_device = JsonString.s(this.m());
						hasv_device = true;
						break
					case "type":
						var v = JsonString.s(this.m());
						switch (v) {
							case "altitude":case "ambient_temperature":case "atmospheric_pressure":case "battery":case "battery_current":case "battery_voltage":case "body_temperature":case "gas_concentration_co":case "gas_concentration_co2":case "gas_concentration_h2s":case "gas_concentration_hc":case "gas_concentration_o2":case "gas_detector_status":case "general_report_interval":case "gps_altitude":case "gsm_level":case "heart_rate":case "heart_rate_quality":case "humidity":case "in_recess_area":case "in_sleep_area":case "live_call_room":case "lpwan_signal_strength":case "lpwan_signal_strength_rssi":case "lpwan_signal_strength_sni":case "module_recover_count":case "network_disconnect_count":case "network_report_cell_id":case "network_report_earfcn":case "network_report_lpwan_signal_strength":case "network_report_mccmnc":case "network_report_nw_provided_edrx":case "network_report_paging_time_window":case "network_report_psm_active_time":case "network_report_psm_periodic_tau":case "network_report_requested_edrx":case "network_report_status":case "network_report_tracking_area_code":case "network_report_wifi_ip_address":case "network_report_wifi_signal_strength":case "ping":case "raw_ambient_temperature_from_humidity_sensor":case "raw_ambient_temperature_from_infrared_sensor":case "raw_ambient_temperature_from_pressure_sensor":case "recess_area_detection_enabled":case "server_disconnect_count":case "short_report_period":case "shutdown":case "sleep_area_detection_enabled":case "special_area_detection_enabled":case "spo2_oxygen_level":case "standstill_token_count":case "standstill_token_duration":case "stationary_time":case "surface_temperature_from_infrared_sensor":case "uwb_detector_operating":case "uwb_detector_powered":case "wearing":case "wearing_time":case "wifi_signal_strength":
								v_type = v;
								break
							default:
							throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer19().wb(["altitude", "ambient_temperature", "atmospheric_pressure", "battery", "battery_current", "body_temperature", "general_report_interval", "short_report_period", "gps_altitude", "gsm_level", "heart_rate", "heart_rate_quality", "humidity", "in_recess_area", "in_sleep_area", "lpwan_signal_strength", "lpwan_signal_strength_rssi", "lpwan_signal_strength_sni", "module_recover_count", "network_disconnect_count", "server_disconnect_count", "raw_ambient_temperature_from_humidity_sensor", "raw_ambient_temperature_from_infrared_sensor", "raw_ambient_temperature_from_pressure_sensor", "recess_area_detection_enabled", "shutdown", "sleep_area_detection_enabled", "special_area_detection_enabled", "spo2_oxygen_level", "standstill_token_count", "standstill_token_duration", "stationary_time", "surface_temperature_from_infrared_sensor", "uwb_detector_operating", "uwb_detector_powered", "wearing", "wearing_time", "wifi_signal_strength", "network_report_status", "network_report_mccmnc", "network_report_tracking_area_code", "network_report_cell_id", "network_report_psm_active_time", "network_report_psm_periodic_tau", "network_report_requested_edrx", "network_report_nw_provided_edrx", "network_report_paging_time_window", "network_report_lpwan_signal_strength", "network_report_wifi_signal_strength", "network_report_wifi_ip_address", "network_report_earfcn", "battery_voltage", "live_call_room", "gas_concentration_hc", "gas_concentration_o2", "gas_concentration_h2s", "gas_concentration_co", "gas_concentration_co2", "gas_detector_status", "ping"]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 469, "className": "tink.json.Parser97", "methodName": "process11"}));
							
						};
						hasv_type = true;
						break
					case "value":
						v_value = JsonString.f(this.r());
						hasv_value = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"date": (hasv_date) ? v_date : __missing__("date"), "device": (hasv_device) ? v_device : __missing__("device"), "type": (hasv_type) ? v_type : __missing__("type"), "value": (hasv_value) ? v_value : __missing__("value")};
	}
	pn(source) {
		if (this.e.length > 0) {
			this.e = [];
		};
		this.g(source);
		this.F("[");
		var __ret = [];
		if (!this.E("]")) {
			while (true) {
				var json = this.pb();
				var tmp;
				var _g = json.alert;
				var _g1 = json.location;
				var _g2 = json.reading;
				if (_g1 != null) {
					tmp = DeviceUpdate.Location(_g1);
				} else if (_g != null) {
					tmp = DeviceUpdate.Alert(_g);
				} else if (_g2 != null) {
					tmp = DeviceUpdate.Reading(_g2);
				} else {
					throw Exception.thrown("Cannot parse" + Std.string(json));
				};
				__ret.push(tmp);
				if (!this.E(",")) {
					break;
				};
			};
			this.F("]");
		};
		var _g = 0;
		var _g1 = this.e;
		while (_g < _g1.length) _g1[_g++]();
		if (this.e.length > 0) {
			this.e = [];
		};
		return __ret;
	}
	po(source) {
		var _gthis = this;
		return TypedError.catchExceptions(function () {
			var ret = _gthis.pn(source);
			_gthis.h();
			if (_gthis.c < _gthis.d) {
				_gthis.D("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser97", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser97"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser97
	}
}

