import {ObservableObject} from "./internal/ObservableObject.js"
import {Invalidator} from "./internal/Invalidatable.js"
import {AutoObservable} from "./internal/AutoObservable.js"
import {Scheduler} from "./Scheduler.js"
import {Observable} from "./Observable.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const State = Register.global("$hxClasses")["tink.state._State.State"] = 
class State {
	static get_value(this1) {
		var ret = this1.getValue();
		if (AutoObservable.cur != null && this1.canFire()) {
			AutoObservable.cur.subscribeTo(this1, ret);
		};
		return ret;
	}
	static set_value(this1, param) {
		this1.set(param);
		return param;
	}
	static _new(value, comparator, guard, onStatusChange) {
		return (guard == null) ? new SimpleState(value, comparator, onStatusChange) : new GuardedState(value, guard, comparator, onStatusChange);
	}
	static compound(source, update, comparator) {
		return new CompoundState(source, update, comparator);
	}
	static get __name__() {
		return "tink.state._State.State_Impl_"
	}
	get __class__() {
		return State
	}
}


export const StateObject = function() {};
StateObject.__isInterface__ = true;

export const SimpleState = Register.global("$hxClasses")["tink.state._State.SimpleState"] = 
class SimpleState extends Register.inherits(Invalidator) {
	new(value, comparator, onStatusChange) {
		super.new();
		this.value = value;
		this.comparator = comparator;
		if (onStatusChange != null) {
			var _g = onStatusChange;
			var a1 = false;
			var tmp = function () {
				_g(a1);
			};
			this.list.ondrain = tmp;
			var _g1 = onStatusChange;
			var a11 = true;
			var tmp = function () {
				_g1(a11);
			};
			this.list.onfill = tmp;
		};
	}
	getValue() {
		return this.value;
	}
	getComparator() {
		return this.comparator;
	}
	set(value) {
		if (Observable.isUpdating && Scheduler.direct.queue == null) {
			Register.$global.console.warn("Updating state in a binding");
		};
		var this1 = this.comparator;
		var b = this.value;
		if (!((this1 == null) ? value == b : this1(value, b))) {
			this.value = value;
			this.fire();
		};
		return value;
	}
	static get __name__() {
		return "tink.state._State.SimpleState"
	}
	static get __interfaces__() {
		return [StateObject]
	}
	static get __super__() {
		return Invalidator
	}
	get __class__() {
		return SimpleState
	}
}


export const GuardedState = Register.global("$hxClasses")["tink.state._State.GuardedState"] = 
class GuardedState extends Register.inherits(SimpleState) {
	new(value, guard, comparator, onStatusChange) {
		this.guardApplied = false;
		super.new(value, comparator, onStatusChange);
		this.guard = guard;
	}
	getValue() {
		if (!this.guardApplied) {
			this.guardApplied = true;
			return this.value = this.guard(this.value);
		} else {
			return this.value;
		};
	}
	set(value) {
		if (!this.guardApplied) {
			this.guardApplied = true;
			this.value = this.guard(this.value);
		};
		return super.set(this.guard(value));
	}
	static get __name__() {
		return "tink.state._State.GuardedState"
	}
	static get __super__() {
		return SimpleState
	}
	get __class__() {
		return GuardedState
	}
}


export const CompoundState = Register.global("$hxClasses")["tink.state._State.CompoundState"] = 
class CompoundState extends Register.inherits() {
	new(data, set, comparator) {
		this.data = data;
		this.update = set;
		this.comparator = comparator;
	}
	canFire() {
		return this.data.canFire();
	}
	getRevision() {
		return this.data.getRevision();
	}
	getValue() {
		return this.data.getValue();
	}
	onInvalidate(i) {
		return this.data.onInvalidate(i);
	}
	set(value) {
		this.update(value);
		return value;
	}
	getComparator() {
		return this.comparator;
	}
	static get __name__() {
		return "tink.state._State.CompoundState"
	}
	static get __interfaces__() {
		return [StateObject]
	}
	get __class__() {
		return CompoundState
	}
}

