import {TypedError} from "../core/Error.js"
import {Annex} from "../core/Annex.js"
import {Register} from "../../genes/Register.js"
import {StringTools} from "../../StringTools.js"
import {HxOverrides} from "../../HxOverrides.js"

const $global = Register.$global

export const JsonString = Register.global("$hxClasses")["tink.json._Parser.JsonString"] = 
class JsonString {
	static n(raw, min, max) {
		return raw.substring(min, max);
	}
	static i(this1) {
		return parseInt(this1);
	}
	static f(this1) {
		return parseFloat(this1);
	}
	static s(this1) {
		if (this1.indexOf("\\") == -1) {
			return this1;
		} else {
			return JSON.parse("\"" + this1 + "\"");
		};
	}
	static get __name__() {
		return "tink.json._Parser.JsonString_Impl_"
	}
	get __class__() {
		return JsonString
	}
}


export const BasicParser = Register.global("$hxClasses")["tink.json.BasicParser"] = 
class BasicParser extends Register.inherits() {
	new() {
		this.e = new Array();
		this.a = new Annex(this);
	}
	g(source) {
		this.c = 0;
		this.d = source.length;
		this.b = source;
		this.h();
	}
	h() {
		while (this.b.charCodeAt(this.c) < 33) this.c++;
	}
	m() {
		this.F("\"", true, false, "string");
		return this.n();
	}
	n() {
		return this.v(this.o(), this.c - 1);
	}
	o() {
		var start = this.c;
		while (true) {
			var _g = this.b.indexOf(BasicParser.j, this.c);
			if (_g == -1) {
				this.D("unterminated string", start);
			} else {
				this.c = _g + 1;
				var p = this.c - 2;
				while (this.b.charCodeAt(p) == 92) --p;
				if ((p - this.c & 1) == 0) {
					break;
				};
			};
		};
		return start;
	}
	r() {
		var $char = this.b.charCodeAt(this.c);
		if ($char == 46 || $char == 45 || $char < 58 && $char > 47) {
			return this.s();
		} else {
			return this.D("number expected");
		};
	}
	s() {
		return this.v(this.u(this.b.charCodeAt(this.c++)), this.c);
	}
	t(start) {
		return this.D("Invalid number " + this.b.substring(start, this.c), start);
	}
	u(c) {
		var start = this.c - 1;
		var minus = c == 45;
		var digit = !minus;
		var zero = c == 48;
		var point = false;
		var e = false;
		var pm = false;
		var end = false;
		while (this.c < this.d) {
			c = this.w();
			switch (c) {
				case 43:case 45:
					if (!e || pm) {
						this.t(start);
					};
					digit = false;
					pm = true;
					break
				case 46:
					if (minus || point) {
						this.t(start);
					};
					digit = false;
					point = true;
					break
				case 48:
					if (zero && !point) {
						this.t(start);
					};
					if (minus) {
						minus = false;
						zero = true;
					};
					digit = true;
					break
				case 49:case 50:case 51:case 52:case 53:case 54:case 55:case 56:case 57:
					if (zero && !point) {
						this.t(start);
					};
					if (minus) {
						minus = false;
					};
					digit = true;
					zero = false;
					break
				case 69:case 101:
					if (minus || zero || e) {
						this.t(start);
					};
					digit = false;
					e = true;
					break
				default:
				if (!digit) {
					this.t(start);
				};
				this.c--;
				end = true;
				
			};
			if (end) {
				break;
			};
		};
		return start;
	}
	v(from, to) {
		return JsonString.n(this.b, from, to);
	}
	w() {
		return this.b.charCodeAt(this.c++);
	}
	y() {
		var start = this.c;
		this.B();
		return this.b.substring(start, this.c);
	}
	A() {
		if (this.E("]")) {
			return;
		};
		while (true) {
			this.B();
			if (!this.E(",")) {
				break;
			};
		};
		this.F("]", true, false);
	}
	B() {
		var _g = this.w();
		switch (_g) {
			case 34:
				this.o();
				break
			case 91:
				this.A();
				break
			case 102:
				this.F("alse", false, false);
				break
			case 110:
				this.F("ull", false, false);
				break
			case 116:
				this.F("rue", false, false);
				break
			case 123:
				if (this.E("}")) {
					return;
				};
				while (true) {
					if (this.w() != 34) {
						this.D("expected string", this.c - 1);
					};
					this.o();
					this.F(":");
					this.B();
					if (!this.E(",")) {
						break;
					};
				};
				this.F("}", true, false);
				break
			default:
			if (_g == 46 || _g == 45 || _g < 58 && _g > 47) {
				this.u(_g);
			} else {
				this.C(_g);
			};
			
		};
	}
	C(c) {
		return this.D("invalid char " + StringTools.hex(c, 2), this.c - 1);
	}
	D(s, pos, end) {
		if (pos == null) {
			pos = -1;
		};
		if (end == null) {
			end = -1;
		};
		if (pos == -1) {
			pos = this.c;
			end = pos;
		} else if (end == -1) {
			end = this.c;
		};
		if (end <= pos) {
			end = pos + 1;
		};
		var clip = function (s, maxLength, left) {
			if (s.length > maxLength) {
				if (left) {
					return "... " + HxOverrides.substr(s, s.length - maxLength, null);
				} else {
					return HxOverrides.substr(s, 0, maxLength) + " ...";
				};
			} else {
				return s;
			};
		};
		var center = pos + end >> 1;
		return TypedError.withData(422, s + (" at " + ((end > pos + 1) ? "characters " + pos + " - " + end : "character " + pos) + " in " + (clip(this.b.substring(0, pos), 20, true) + "  ---->  " + clip(this.b.substring(pos, center), 20, false) + clip(this.b.substring(center, end), 20, true) + "  <----  " + clip(this.b.substring(end, this.d), 20, false))), {"source": this.b, "start": pos, "end": end}, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/Parser.hx", "lineNumber": 517, "className": "tink.json.BasicParser", "methodName": "die"}).throwSelf();
	}
	E(s, skipBefore, skipAfter) {
		if (skipBefore == null) {
			skipBefore = true;
		};
		if (skipAfter == null) {
			skipAfter = true;
		};
		if (skipBefore) {
			this.h();
		};
		var l = s.length;
		var found = this.b.substring(this.c, l + this.c) == s;
		if (found) {
			this.c += l;
		};
		if (skipAfter) {
			this.h();
		};
		return found;
	}
	F(s, skipBefore, skipAfter, expected) {
		if (skipBefore == null) {
			skipBefore = true;
		};
		if (skipAfter == null) {
			skipAfter = true;
		};
		if (expected == null) {
			expected = s;
		};
		if (!this.E(s, skipBefore, skipAfter)) {
			return this.D("Expected " + expected);
		} else {
			return null;
		};
	}
	G() {
		if (this.E("true")) {
			return true;
		} else if (this.E("false")) {
			return false;
		} else {
			return this.D("expected boolean value");
		};
	}
	static get __name__() {
		return "tink.json.BasicParser"
	}
	get __class__() {
		return BasicParser
	}
}


BasicParser.j = String.fromCodePoint(34)