import {Source, RealSourceTools} from "../io/Source.js"
import {StdClient} from "./clients/StdClient.js"
import {LocalContainerClient} from "./clients/LocalContainerClient.js"
import {JsClient} from "./clients/JsClient.js"
import {OutgoingRequest, OutgoingRequestHeader} from "./Request.js"
import {Message} from "./Message.js"
import {CustomClient} from "./Client.js"
import {Promise as Promise__1} from "../core/Promise.js"
import {Outcome} from "../core/Outcome.js"
import {LazyConst} from "../core/Lazy.js"
import {Future, SyncFuture} from "../core/Future.js"
import {TypedError} from "../core/Error.js"
import {Url} from "../Url.js"
import {EnumValueMap} from "../../haxe/ds/EnumValueMap.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Fetch = Register.global("$hxClasses")["tink.http.Fetch"] = 
class Fetch {
	static fetch(url, options) {
		return Future.async(function (cb) {
			var method = "GET";
			var headers = null;
			var body = Source.EMPTY;
			var type = ClientType.Default;
			var followRedirect = true;
			if (options != null) {
				if (options.method != null) {
					method = options.method;
				};
				if (options.headers != null) {
					headers = options.headers;
				};
				if (options.body != null) {
					body = options.body;
				};
				if (options.client != null) {
					type = options.client;
				};
				if (options.followRedirect == false) {
					followRedirect = false;
				};
			};
			var client = Fetch.getClient(type);
			if (options != null && options.augment != null) {
				var pipeline = options.augment;
				client = CustomClient.create(client, pipeline.before, pipeline.after);
			};
			client.request(new OutgoingRequest(new OutgoingRequestHeader(method, url, null, headers), body)).handle(function (res) {
				switch (res._hx_index) {
					case 0:
						var _g = res.data;
						var _g1 = _g.header.statusCode;
						switch (_g1) {
							case 301:case 302:case 303:case 307:case 308:
								var code = _g1;
								if (followRedirect) {
									Promise__1.next(new SyncFuture(new LazyConst(_g.header.byName("location".toLowerCase()))), function (location) {
										var this1 = Url.resolve(url, Url.fromString(location));
										var this2;
										if (code == 303) {
											var __o0 = options;
											var __tink_tmp8 = {"method": "GET"};
											var _g = __o0.headers;
											if (_g != null) {
												__tink_tmp8.headers = _g;
											};
											var _g = __o0.followRedirect;
											if (_g != null) {
												__tink_tmp8.followRedirect = _g;
											};
											var _g = __o0.client;
											if (_g != null) {
												__tink_tmp8.client = _g;
											};
											var _g = __o0.body;
											if (_g != null) {
												__tink_tmp8.body = _g;
											};
											var _g = __o0.augment;
											if (_g != null) {
												__tink_tmp8.augment = _g;
											};
											this2 = __tink_tmp8;
										} else {
											this2 = options;
										};
										return Fetch.fetch(this1, this2);
									}).handle(cb);
								} else {
									cb(Outcome.Success(_g));
								};
								break
							default:
							cb(Outcome.Success(_g));
							
						};
						break
					case 1:
						cb(Outcome.Failure(res.failure));
						break
					
				};
			});
		});
	}
	static getClient(type) {
		if (!Fetch.cache.exists(type)) {
			var c;
			switch (type._hx_index) {
				case 0:
					c = new JsClient();
					break
				case 1:
					c = new LocalContainerClient(type.container);
					break
				case 2:
					c = new StdClient();
					break
				case 3:
					c = type.v;
					break
				
			};
			Fetch.cache.set(type, c);
		};
		return Fetch.cache.get(type);
	}
	static get __name__() {
		return "tink.http.Fetch"
	}
	get __class__() {
		return Fetch
	}
}


Fetch.cache = new EnumValueMap()
export const ClientType = 
Register.global("$hxEnums")["tink.http.ClientType"] = 
{
	__ename__: "tink.http.ClientType",
	
	Default: {_hx_name: "Default", _hx_index: 0, __enum__: "tink.http.ClientType"},
	Local: Object.assign((container) => ({_hx_index: 1, __enum__: "tink.http.ClientType", "container": container}), {_hx_name: "Local", __params__: ["container"]}),
	StdLib: {_hx_name: "StdLib", _hx_index: 2, __enum__: "tink.http.ClientType"},
	Custom: Object.assign((v) => ({_hx_index: 3, __enum__: "tink.http.ClientType", "v": v}), {_hx_name: "Custom", __params__: ["v"]})
}
ClientType.__constructs__ = [ClientType.Default, ClientType.Local, ClientType.StdLib, ClientType.Custom]
ClientType.__empty_constructs__ = [ClientType.Default, ClientType.StdLib]

export const FetchResponse = Register.global("$hxClasses")["tink.http._Fetch.FetchResponse"] = 
class FetchResponse {
	static all(this1) {
		return Promise__1.next(this1, function (r) {
			return Promise__1.next(RealSourceTools.all(r.body), function (chunk) {
				if (r.header.statusCode >= 400) {
					return new SyncFuture(new LazyConst(Outcome.Failure(TypedError.withData(r.header.statusCode, r.header.reason, chunk.toString(), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_http/0.10.0/github/49088ee6bb9d69b1e0f57931a13e836132e3fc02/src/tink/http/Fetch.hx", "lineNumber": 125, "className": "tink.http._Fetch.FetchResponse_Impl_", "methodName": "all"}))));
				} else {
					return new SyncFuture(new LazyConst(Outcome.Success(new Message(r.header, chunk))));
				};
			});
		});
	}
	static get __name__() {
		return "tink.http._Fetch.FetchResponse_Impl_"
	}
	get __class__() {
		return FetchResponse
	}
}

