import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Message = Register.global("$hxClasses")["why.mqtt.Message"] = 
class Message extends Register.inherits() {
	new(topic, payload, qos, retain) {
		if (qos == null) {
			qos = 0;
		};
		if (retain == null) {
			retain = false;
		};
		this.topic = topic;
		this.payload = payload;
		this.qos = qos;
		this.retain = retain;
	}
	static get __name__() {
		return "why.mqtt.Message"
	}
	get __class__() {
		return Message
	}
}

