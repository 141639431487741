import {Writer35} from "./Writer35.js"
import {Writer34} from "./Writer34.js"
import {Writer33} from "./Writer33.js"
import {BasicParser, JsonString} from "./Parser.js"
import {TypedError} from "../core/Error.js"
import {Exception} from "../../haxe/Exception.js"
import {Coordinates} from "../../geojson/util/Coordinates.js"
import {Register} from "../../genes/Register.js"
import {Boundary} from "../../dasloop/core/types/Boundary.js"
import {Std} from "../../Std.js"

const $global = Register.$global

export const Parser51 = Register.global("$hxClasses")["tink.json.Parser51"] = 
class Parser51 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	pb() {
		var _gthis = this;
		var v_alerts = null;
		var hasv_alerts = false;
		var v_project = null;
		var hasv_project = false;
		var v_sensors = null;
		var hasv_sensors = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "alerts":
						this.F("[");
						var __ret = [];
						if (!this.E("]")) {
							while (true) {
								__ret.push(JsonString.i(this.r()));
								if (!this.E(",")) {
									break;
								};
							};
							this.F("]");
						};
						v_alerts = __ret;
						hasv_alerts = true;
						break
					case "project":
						v_project = this.pc();
						hasv_project = true;
						break
					case "sensors":
						this.F("[");
						var __ret1 = [];
						if (!this.E("]")) {
							while (true) {
								__ret1.push(JsonString.i(this.r()));
								if (!this.E(",")) {
									break;
								};
							};
							this.F("]");
						};
						v_sensors = __ret1;
						hasv_sensors = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"alerts": (hasv_alerts) ? v_alerts : __missing__("alerts"), "project": (hasv_project) ? v_project : __missing__("project"), "sensors": (hasv_sensors) ? v_sensors : __missing__("sensors")};
	}
	pc() {
		var _gthis = this;
		var v_archived = false;
		var hasv_archived = false;
		var v_areas = null;
		var hasv_areas = false;
		var v_avatarUrl = null;
		var hasv_avatarUrl = false;
		var v_bims = null;
		var hasv_bims = false;
		var v_cameraPosition = null;
		var v_client = null;
		var hasv_client = false;
		var v_contacts = null;
		var hasv_contacts = false;
		var v_contractor = null;
		var hasv_contractor = false;
		var v_elevation = .0;
		var hasv_elevation = false;
		var v_endDate = null;
		var hasv_endDate = false;
		var v_expiry = null;
		var hasv_expiry = false;
		var v_floorPlans = null;
		var hasv_floorPlans = false;
		var v_gridSize = null;
		var v_id = null;
		var hasv_id = false;
		var v_name = null;
		var hasv_name = false;
		var v_number = null;
		var hasv_number = false;
		var v_startDate = null;
		var hasv_startDate = false;
		var v_timezone = 0;
		var hasv_timezone = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "archived":
						v_archived = this.G();
						hasv_archived = true;
						break
					case "areas":
						this.F("[");
						var __ret = [];
						if (!this.E("]")) {
							while (true) {
								__ret.push(this.pd());
								if (!this.E(",")) {
									break;
								};
							};
							this.F("]");
						};
						v_areas = __ret;
						hasv_areas = true;
						break
					case "avatarUrl":
						v_avatarUrl = (this.E("null")) ? null : JsonString.s(this.m());
						hasv_avatarUrl = true;
						break
					case "bims":
						this.F("[");
						var __ret1 = [];
						if (!this.E("]")) {
							while (true) {
								__ret1.push(this.ph());
								if (!this.E(",")) {
									break;
								};
							};
							this.F("]");
						};
						v_bims = __ret1;
						hasv_bims = true;
						break
					case "cameraPosition":
						if (this.E("null")) {
							v_cameraPosition = null;
						} else {
							this.F("[");
							var __ret2 = [];
							if (!this.E("]")) {
								while (true) {
									__ret2.push(JsonString.f(this.r()));
									if (!this.E(",")) {
										break;
									};
								};
								this.F("]");
							};
							v_cameraPosition = __ret2;
						};
						break
					case "client":
						v_client = JsonString.s(this.m());
						hasv_client = true;
						break
					case "contacts":
						this.F("[");
						var __ret3 = [];
						if (!this.E("]")) {
							while (true) {
								__ret3.push(this.pg());
								if (!this.E(",")) {
									break;
								};
							};
							this.F("]");
						};
						v_contacts = __ret3;
						hasv_contacts = true;
						break
					case "contractor":
						v_contractor = JsonString.s(this.m());
						hasv_contractor = true;
						break
					case "elevation":
						v_elevation = JsonString.f(this.r());
						hasv_elevation = true;
						break
					case "endDate":
						v_endDate = new Date(JsonString.f(this.r()));
						hasv_endDate = true;
						break
					case "expiry":
						v_expiry = (this.E("null")) ? null : new Date(JsonString.f(this.r()));
						hasv_expiry = true;
						break
					case "floorPlans":
						this.F("[");
						var __ret4 = [];
						if (!this.E("]")) {
							while (true) {
								__ret4.push(this.pj());
								if (!this.E(",")) {
									break;
								};
							};
							this.F("]");
						};
						v_floorPlans = __ret4;
						hasv_floorPlans = true;
						break
					case "gridSize":
						v_gridSize = (this.E("null")) ? null : JsonString.i(this.r());
						break
					case "id":
						v_id = JsonString.s(this.m());
						hasv_id = true;
						break
					case "name":
						v_name = JsonString.s(this.m());
						hasv_name = true;
						break
					case "number":
						v_number = JsonString.s(this.m());
						hasv_number = true;
						break
					case "startDate":
						v_startDate = new Date(JsonString.f(this.r()));
						hasv_startDate = true;
						break
					case "timezone":
						v_timezone = JsonString.i(this.r());
						hasv_timezone = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"archived": (hasv_archived) ? v_archived : __missing__("archived"), "areas": (hasv_areas) ? v_areas : __missing__("areas"), "avatarUrl": (hasv_avatarUrl) ? v_avatarUrl : __missing__("avatarUrl"), "bims": (hasv_bims) ? v_bims : __missing__("bims"), "cameraPosition": v_cameraPosition, "client": (hasv_client) ? v_client : __missing__("client"), "contacts": (hasv_contacts) ? v_contacts : __missing__("contacts"), "contractor": (hasv_contractor) ? v_contractor : __missing__("contractor"), "elevation": (hasv_elevation) ? v_elevation : __missing__("elevation"), "endDate": (hasv_endDate) ? v_endDate : __missing__("endDate"), "expiry": (hasv_expiry) ? v_expiry : __missing__("expiry"), "floorPlans": (hasv_floorPlans) ? v_floorPlans : __missing__("floorPlans"), "gridSize": v_gridSize, "id": (hasv_id) ? v_id : __missing__("id"), "name": (hasv_name) ? v_name : __missing__("name"), "number": (hasv_number) ? v_number : __missing__("number"), "startDate": (hasv_startDate) ? v_startDate : __missing__("startDate"), "timezone": (hasv_timezone) ? v_timezone : __missing__("timezone")};
	}
	pd() {
		var _gthis = this;
		var v_active = false;
		var hasv_active = false;
		var v_area = null;
		var hasv_area = false;
		var v_authorizedWorkers = null;
		var hasv_authorizedWorkers = false;
		var v_elevation = null;
		var v_height = null;
		var v_id = null;
		var hasv_id = false;
		var v_name = null;
		var hasv_name = false;
		var v_project = null;
		var hasv_project = false;
		var v_type = null;
		var hasv_type = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "active":
						v_active = this.G();
						hasv_active = true;
						break
					case "area":
						var __start__1 = this.c;
						var rep = this.pe();
						try {
							v_area = Boundary.fromRepresentation(rep);
						}catch (_g) {
							v_area = this.D(Std.string(Exception.caught(_g).unwrap()), __start__1);
						};
						hasv_area = true;
						break
					case "authorizedWorkers":
						this.F("[");
						var __ret = [];
						if (!this.E("]")) {
							while (true) {
								__ret.push(this.pf());
								if (!this.E(",")) {
									break;
								};
							};
							this.F("]");
						};
						v_authorizedWorkers = __ret;
						hasv_authorizedWorkers = true;
						break
					case "elevation":
						v_elevation = (this.E("null")) ? null : JsonString.f(this.r());
						break
					case "height":
						v_height = (this.E("null")) ? null : JsonString.f(this.r());
						break
					case "id":
						v_id = JsonString.s(this.m());
						hasv_id = true;
						break
					case "name":
						v_name = JsonString.s(this.m());
						hasv_name = true;
						break
					case "project":
						v_project = JsonString.s(this.m());
						hasv_project = true;
						break
					case "type":
						var v = JsonString.s(this.m());
						switch (v) {
							case "boundary":case "region":case "restricted":
								v_type = v;
								break
							default:
							throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer34().wb(["boundary", "region", "restricted"]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 469, "className": "tink.json.Parser51", "methodName": "process2"}));
							
						};
						hasv_type = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"active": (hasv_active) ? v_active : __missing__("active"), "area": (hasv_area) ? v_area : __missing__("area"), "authorizedWorkers": (hasv_authorizedWorkers) ? v_authorizedWorkers : __missing__("authorizedWorkers"), "elevation": v_elevation, "height": v_height, "id": (hasv_id) ? v_id : __missing__("id"), "name": (hasv_name) ? v_name : __missing__("name"), "project": (hasv_project) ? v_project : __missing__("project"), "type": (hasv_type) ? v_type : __missing__("type")};
	}
	pe() {
		var _gthis = this;
		var v_coordinates = null;
		var hasv_coordinates = false;
		var v_type = null;
		var hasv_type = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "coordinates":
						this.F("[");
						var __ret = [];
						if (!this.E("]")) {
							while (true) {
								this.F("[");
								var __ret1 = [];
								if (!this.E("]")) {
									while (true) {
										this.F("[");
										var __ret2 = [];
										if (!this.E("]")) {
											while (true) {
												var __start__1 = this.c;
												this.F("[");
												var __ret3 = [];
												if (!this.E("]")) {
													while (true) {
														__ret3.push(JsonString.f(this.r()));
														if (!this.E(",")) {
															break;
														};
													};
													this.F("]");
												};
												var v_coordinates1;
												try {
													v_coordinates1 = Coordinates.fromRepresentation(__ret3);
												}catch (_g) {
													v_coordinates1 = this.D(Std.string(Exception.caught(_g).unwrap()), __start__1);
												};
												__ret2.push(v_coordinates1);
												if (!this.E(",")) {
													break;
												};
											};
											this.F("]");
										};
										__ret1.push(__ret2);
										if (!this.E(",")) {
											break;
										};
									};
									this.F("]");
								};
								__ret.push(__ret1);
								if (!this.E(",")) {
									break;
								};
							};
							this.F("]");
						};
						v_coordinates = __ret;
						hasv_coordinates = true;
						break
					case "type":
						v_type = JsonString.s(this.m());
						hasv_type = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"coordinates": (hasv_coordinates) ? v_coordinates : __missing__("coordinates"), "type": (hasv_type) ? v_type : __missing__("type")};
	}
	pf() {
		var _gthis = this;
		var v_age = 0;
		var hasv_age = false;
		var v_company = null;
		var hasv_company = false;
		var v_contacts = null;
		var hasv_contacts = false;
		var v_division = null;
		var hasv_division = false;
		var v_email = null;
		var v_gender = null;
		var hasv_gender = false;
		var v_id = null;
		var hasv_id = false;
		var v_imageUrl = null;
		var hasv_imageUrl = false;
		var v_name = null;
		var hasv_name = false;
		var v_phone = null;
		var hasv_phone = false;
		var v_registrationNumber = null;
		var hasv_registrationNumber = false;
		var v_remarks = null;
		var hasv_remarks = false;
		var v_trade = null;
		var hasv_trade = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "age":
						v_age = JsonString.i(this.r());
						hasv_age = true;
						break
					case "company":
						v_company = JsonString.s(this.m());
						hasv_company = true;
						break
					case "contacts":
						this.F("[");
						var __ret = [];
						if (!this.E("]")) {
							while (true) {
								__ret.push(this.pg());
								if (!this.E(",")) {
									break;
								};
							};
							this.F("]");
						};
						v_contacts = __ret;
						hasv_contacts = true;
						break
					case "division":
						v_division = JsonString.s(this.m());
						hasv_division = true;
						break
					case "email":
						v_email = (this.E("null")) ? null : JsonString.s(this.m());
						break
					case "gender":
						var v = JsonString.s(this.m());
						switch (v) {
							case "female":case "male":case "unspecified":
								v_gender = v;
								break
							default:
							throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer35().wb(["male", "female", "unspecified"]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 469, "className": "tink.json.Parser51", "methodName": "process4"}));
							
						};
						hasv_gender = true;
						break
					case "id":
						v_id = JsonString.s(this.m());
						hasv_id = true;
						break
					case "imageUrl":
						v_imageUrl = JsonString.s(this.m());
						hasv_imageUrl = true;
						break
					case "name":
						v_name = JsonString.s(this.m());
						hasv_name = true;
						break
					case "phone":
						v_phone = JsonString.s(this.m());
						hasv_phone = true;
						break
					case "registrationNumber":
						v_registrationNumber = JsonString.s(this.m());
						hasv_registrationNumber = true;
						break
					case "remarks":
						v_remarks = JsonString.s(this.m());
						hasv_remarks = true;
						break
					case "trade":
						v_trade = JsonString.s(this.m());
						hasv_trade = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"age": (hasv_age) ? v_age : __missing__("age"), "company": (hasv_company) ? v_company : __missing__("company"), "contacts": (hasv_contacts) ? v_contacts : __missing__("contacts"), "division": (hasv_division) ? v_division : __missing__("division"), "email": v_email, "gender": (hasv_gender) ? v_gender : __missing__("gender"), "id": (hasv_id) ? v_id : __missing__("id"), "imageUrl": (hasv_imageUrl) ? v_imageUrl : __missing__("imageUrl"), "name": (hasv_name) ? v_name : __missing__("name"), "phone": (hasv_phone) ? v_phone : __missing__("phone"), "registrationNumber": (hasv_registrationNumber) ? v_registrationNumber : __missing__("registrationNumber"), "remarks": (hasv_remarks) ? v_remarks : __missing__("remarks"), "trade": (hasv_trade) ? v_trade : __missing__("trade")};
	}
	pg() {
		var _gthis = this;
		var v_email = null;
		var v_language = null;
		var hasv_language = false;
		var v_name = null;
		var hasv_name = false;
		var v_phone = null;
		var v_role = null;
		var hasv_role = false;
		var v_whatsapp = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "email":
						v_email = (this.E("null")) ? null : JsonString.s(this.m());
						break
					case "language":
						v_language = JsonString.s(this.m());
						hasv_language = true;
						break
					case "name":
						v_name = JsonString.s(this.m());
						hasv_name = true;
						break
					case "phone":
						v_phone = (this.E("null")) ? null : JsonString.s(this.m());
						break
					case "role":
						v_role = JsonString.s(this.m());
						hasv_role = true;
						break
					case "whatsapp":
						v_whatsapp = (this.E("null")) ? null : JsonString.s(this.m());
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"email": v_email, "language": (hasv_language) ? v_language : __missing__("language"), "name": (hasv_name) ? v_name : __missing__("name"), "phone": v_phone, "role": (hasv_role) ? v_role : __missing__("role"), "whatsapp": v_whatsapp};
	}
	ph() {
		var _gthis = this;
		var v_center = null;
		var hasv_center = false;
		var v_id = null;
		var hasv_id = false;
		var v_name = null;
		var hasv_name = false;
		var v_project = null;
		var hasv_project = false;
		var v_rotation = .0;
		var hasv_rotation = false;
		var v_scale = .0;
		var hasv_scale = false;
		var v_type = null;
		var hasv_type = false;
		var v_url = null;
		var hasv_url = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "center":
						v_center = this.pi();
						hasv_center = true;
						break
					case "id":
						v_id = JsonString.s(this.m());
						hasv_id = true;
						break
					case "name":
						v_name = JsonString.s(this.m());
						hasv_name = true;
						break
					case "project":
						v_project = JsonString.s(this.m());
						hasv_project = true;
						break
					case "rotation":
						v_rotation = JsonString.f(this.r());
						hasv_rotation = true;
						break
					case "scale":
						v_scale = JsonString.f(this.r());
						hasv_scale = true;
						break
					case "type":
						var v = JsonString.s(this.m());
						switch (v) {
							case "fbx":case "glb":
								v_type = v;
								break
							default:
							throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer33().wb(["fbx", "glb"]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 469, "className": "tink.json.Parser51", "methodName": "process6"}));
							
						};
						hasv_type = true;
						break
					case "url":
						v_url = JsonString.s(this.m());
						hasv_url = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"center": (hasv_center) ? v_center : __missing__("center"), "id": (hasv_id) ? v_id : __missing__("id"), "name": (hasv_name) ? v_name : __missing__("name"), "project": (hasv_project) ? v_project : __missing__("project"), "rotation": (hasv_rotation) ? v_rotation : __missing__("rotation"), "scale": (hasv_scale) ? v_scale : __missing__("scale"), "type": (hasv_type) ? v_type : __missing__("type"), "url": (hasv_url) ? v_url : __missing__("url")};
	}
	pi() {
		var _gthis = this;
		var v_altitude = .0;
		var hasv_altitude = false;
		var v_latitude = .0;
		var hasv_latitude = false;
		var v_longitude = .0;
		var hasv_longitude = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "altitude":
						v_altitude = JsonString.f(this.r());
						hasv_altitude = true;
						break
					case "latitude":
						v_latitude = JsonString.f(this.r());
						hasv_latitude = true;
						break
					case "longitude":
						v_longitude = JsonString.f(this.r());
						hasv_longitude = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"altitude": (hasv_altitude) ? v_altitude : __missing__("altitude"), "latitude": (hasv_latitude) ? v_latitude : __missing__("latitude"), "longitude": (hasv_longitude) ? v_longitude : __missing__("longitude")};
	}
	pj() {
		var _gthis = this;
		var v_altitude = .0;
		var hasv_altitude = false;
		var v_bottomLeft = null;
		var hasv_bottomLeft = false;
		var v_bottomRight = null;
		var hasv_bottomRight = false;
		var v_id = null;
		var hasv_id = false;
		var v_imageUrl = null;
		var hasv_imageUrl = false;
		var v_level = 0;
		var hasv_level = false;
		var v_name = null;
		var hasv_name = false;
		var v_project = null;
		var hasv_project = false;
		var v_topLeft = null;
		var hasv_topLeft = false;
		var v_topRight = null;
		var hasv_topRight = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "altitude":
						v_altitude = JsonString.f(this.r());
						hasv_altitude = true;
						break
					case "bottomLeft":
						v_bottomLeft = this.pk();
						hasv_bottomLeft = true;
						break
					case "bottomRight":
						v_bottomRight = this.pk();
						hasv_bottomRight = true;
						break
					case "id":
						v_id = JsonString.s(this.m());
						hasv_id = true;
						break
					case "imageUrl":
						v_imageUrl = JsonString.s(this.m());
						hasv_imageUrl = true;
						break
					case "level":
						v_level = JsonString.i(this.r());
						hasv_level = true;
						break
					case "name":
						v_name = JsonString.s(this.m());
						hasv_name = true;
						break
					case "project":
						v_project = JsonString.s(this.m());
						hasv_project = true;
						break
					case "topLeft":
						v_topLeft = this.pk();
						hasv_topLeft = true;
						break
					case "topRight":
						v_topRight = this.pk();
						hasv_topRight = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"altitude": (hasv_altitude) ? v_altitude : __missing__("altitude"), "bottomLeft": (hasv_bottomLeft) ? v_bottomLeft : __missing__("bottomLeft"), "bottomRight": (hasv_bottomRight) ? v_bottomRight : __missing__("bottomRight"), "id": (hasv_id) ? v_id : __missing__("id"), "imageUrl": (hasv_imageUrl) ? v_imageUrl : __missing__("imageUrl"), "level": (hasv_level) ? v_level : __missing__("level"), "name": (hasv_name) ? v_name : __missing__("name"), "project": (hasv_project) ? v_project : __missing__("project"), "topLeft": (hasv_topLeft) ? v_topLeft : __missing__("topLeft"), "topRight": (hasv_topRight) ? v_topRight : __missing__("topRight")};
	}
	pk() {
		var _gthis = this;
		var v_latitude = .0;
		var hasv_latitude = false;
		var v_longitude = .0;
		var hasv_longitude = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "latitude":
						v_latitude = JsonString.f(this.r());
						hasv_latitude = true;
						break
					case "longitude":
						v_longitude = JsonString.f(this.r());
						hasv_longitude = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"latitude": (hasv_latitude) ? v_latitude : __missing__("latitude"), "longitude": (hasv_longitude) ? v_longitude : __missing__("longitude")};
	}
	pl(source) {
		if (this.e.length > 0) {
			this.e = [];
		};
		this.g(source);
		var ret = this.pb();
		var _g = 0;
		var _g1 = this.e;
		while (_g < _g1.length) _g1[_g++]();
		if (this.e.length > 0) {
			this.e = [];
		};
		return ret;
	}
	pm(source) {
		var _gthis = this;
		return TypedError.catchExceptions(function () {
			var ret = _gthis.pl(source);
			_gthis.h();
			if (_gthis.c < _gthis.d) {
				_gthis.D("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser51", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser51"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser51
	}
}

