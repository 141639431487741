import {ObservableObject} from "../../../tink/state/internal/ObservableObject.js"
import {Invalidatable, Invalidator} from "../../../tink/state/internal/Invalidatable.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Slot = Register.global("$hxClasses")["coconut.ui.internal.Slot"] = 
class Slot extends Register.inherits(Invalidator) {
	new(owner, comparator, defaultData, toString) {
		var _gthis = this;
		super.new();
		this.comparator = comparator;
		this.data = this.defaultData = defaultData;
		this.list.ondrain = function () {
			var this1 = _gthis.link;
			if (this1 != null) {
				this1.cancel();
			};
		};
		this.list.onfill = function () {
			_gthis.heatup();
		};
	}
	heatup() {
		if (this.data != null) {
			this.link = this.data.onInvalidate(this);
		};
	}
	invalidate() {
		this.fire();
	}
	getComparator() {
		return this.comparator;
	}
	getRevision() {
		var ret = this.revision;
		if (this.data != null) {
			var b = this.data.getRevision();
			if (!(ret > b)) {
				ret = b;
			};
		};
		if (this.defaultData != null) {
			var b = this.defaultData.getRevision();
			if (!(ret > b)) {
				ret = b;
			};
		};
		return ret;
	}
	getValue() {
		var _g = this.data;
		var _g1 = this.defaultData;
		var _hx_tmp;
		if (_g == null) {
			if (_g1 == null) {
				return null;
			} else {
				return _g1.getValue();
			};
		} else if (_g1 == null) {
			return _g.getValue();
		} else {
			_hx_tmp = _g.getValue();
			if (_hx_tmp == null) {
				return _g1.getValue();
			} else {
				return _hx_tmp;
			};
		};
	}
	setData(data) {
		if (data == null) {
			data = this.defaultData;
		};
		if (data == this.data) {
			return;
		};
		this.data = data;
		if (this.list.used > 0) {
			var this1 = this.link;
			if (this1 != null) {
				this1.cancel();
			};
			this.heatup();
		};
		this.fire();
	}
	static get __name__() {
		return "coconut.ui.internal.Slot"
	}
	static get __interfaces__() {
		return [ObservableObject, Invalidatable]
	}
	static get __super__() {
		return Invalidator
	}
	get __class__() {
		return Slot
	}
}

