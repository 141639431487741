import {RemoteBase, RemoteEndpoint, ResponseReader} from "./Remote.js"
import {Single} from "../../streams/Stream.js"
import {Writer45} from "../../json/Writer45.js"
import {Parser21} from "../../json/Parser21.js"
import {Parser20} from "../../json/Parser20.js"
import {HeaderField, HeaderValue} from "../../http/Header.js"
import {LazyConst} from "../../core/Lazy.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote25 = Register.global("$hxClasses")["tink.web.proxy.Remote25"] = 
class Remote25 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	create(body) {
		var __body__ = ByteChunk.of(Bytes.ofString(new Writer45().wd({"_101": body.age, "_102": body.company, "_103": body.contacts, "_104": body.dateOfBirth, "_105": body.division, "_106": body.email, "_107": body.gender, "_108": body.name, "_109": body.phone, "_110": body.registrationNumber, "_111": body.remarks, "_112": body.trade})));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [], "headers": [new HeaderField("content-type", "application/json"), new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "POST", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser21())));
	}
	list() {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [], "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser20())));
	}
	static get __name__() {
		return "tink.web.proxy.Remote25"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote25
	}
}

