import {BasicParser, JsonString} from "./Parser.js"
import {TypedError} from "../core/Error.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Parser10 = Register.global("$hxClasses")["tink.json.Parser10"] = 
class Parser10 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	pb() {
		var _gthis = this;
		var v_expiry = null;
		var hasv_expiry = false;
		var v_id = null;
		var hasv_id = false;
		var v_mainContractor = null;
		var hasv_mainContractor = false;
		var v_model = null;
		var hasv_model = false;
		var v_name = null;
		var hasv_name = false;
		var v_permitIssuer = null;
		var hasv_permitIssuer = false;
		var v_permitNumber = null;
		var hasv_permitNumber = false;
		var v_project = null;
		var hasv_project = false;
		var v_remarks = null;
		var hasv_remarks = false;
		var v_safeWorkingLoad = .0;
		var hasv_safeWorkingLoad = false;
		var v_serialNumber = null;
		var hasv_serialNumber = false;
		var v_subContractor = null;
		var hasv_subContractor = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "expiry":
						v_expiry = new Date(JsonString.f(this.r()));
						hasv_expiry = true;
						break
					case "id":
						v_id = JsonString.s(this.m());
						hasv_id = true;
						break
					case "mainContractor":
						v_mainContractor = (this.E("null")) ? null : JsonString.s(this.m());
						hasv_mainContractor = true;
						break
					case "model":
						v_model = JsonString.s(this.m());
						hasv_model = true;
						break
					case "name":
						v_name = JsonString.s(this.m());
						hasv_name = true;
						break
					case "permitIssuer":
						v_permitIssuer = JsonString.s(this.m());
						hasv_permitIssuer = true;
						break
					case "permitNumber":
						v_permitNumber = JsonString.s(this.m());
						hasv_permitNumber = true;
						break
					case "project":
						v_project = JsonString.s(this.m());
						hasv_project = true;
						break
					case "remarks":
						v_remarks = (this.E("null")) ? null : JsonString.s(this.m());
						hasv_remarks = true;
						break
					case "safeWorkingLoad":
						v_safeWorkingLoad = JsonString.f(this.r());
						hasv_safeWorkingLoad = true;
						break
					case "serialNumber":
						v_serialNumber = JsonString.s(this.m());
						hasv_serialNumber = true;
						break
					case "subContractor":
						v_subContractor = (this.E("null")) ? null : JsonString.s(this.m());
						hasv_subContractor = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"expiry": (hasv_expiry) ? v_expiry : __missing__("expiry"), "id": (hasv_id) ? v_id : __missing__("id"), "mainContractor": (hasv_mainContractor) ? v_mainContractor : __missing__("mainContractor"), "model": (hasv_model) ? v_model : __missing__("model"), "name": (hasv_name) ? v_name : __missing__("name"), "permitIssuer": (hasv_permitIssuer) ? v_permitIssuer : __missing__("permitIssuer"), "permitNumber": (hasv_permitNumber) ? v_permitNumber : __missing__("permitNumber"), "project": (hasv_project) ? v_project : __missing__("project"), "remarks": (hasv_remarks) ? v_remarks : __missing__("remarks"), "safeWorkingLoad": (hasv_safeWorkingLoad) ? v_safeWorkingLoad : __missing__("safeWorkingLoad"), "serialNumber": (hasv_serialNumber) ? v_serialNumber : __missing__("serialNumber"), "subContractor": (hasv_subContractor) ? v_subContractor : __missing__("subContractor")};
	}
	pc(source) {
		if (this.e.length > 0) {
			this.e = [];
		};
		this.g(source);
		this.F("[");
		var __ret = [];
		if (!this.E("]")) {
			while (true) {
				__ret.push(this.pb());
				if (!this.E(",")) {
					break;
				};
			};
			this.F("]");
		};
		var ret = __ret;
		var _g = 0;
		var _g1 = this.e;
		while (_g < _g1.length) _g1[_g++]();
		if (this.e.length > 0) {
			this.e = [];
		};
		return ret;
	}
	pd(source) {
		var _gthis = this;
		return TypedError.catchExceptions(function () {
			var ret = _gthis.pc(source);
			_gthis.h();
			if (_gthis.c < _gthis.d) {
				_gthis.D("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser10", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser10"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser10
	}
}

