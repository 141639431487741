import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const List = Register.global("$hxClasses")["tink.pure._List.List"] = 
class List {
	static prepend(this1, value) {
		if (this1 == null) {
			return new Node(1, value);
		} else {
			return new Node(this1.length + 1, value, [this1]);
		};
	}
	static fromArray(i) {
		var ret = null;
		var len = 0;
		var pos = i.length;
		while (pos-- > 0) ret = new Node(++len, i[pos], (ret == null) ? Node.EMPTY : [ret]);
		return ret;
	}
	static get __name__() {
		return "tink.pure._List.List_Impl_"
	}
	get __class__() {
		return List
	}
}


export const Node = Register.global("$hxClasses")["tink.pure._List.Node"] = 
class Node extends Register.inherits() {
	new(length, value, tails) {
		this.value = value;
		this.length = length;
		this.tails = (tails == null) ? Node.EMPTY : tails;
	}
	static get __name__() {
		return "tink.pure._List.Node"
	}
	get __class__() {
		return Node
	}
}


Node.EMPTY = []
export const NodeIterator = Register.global("$hxClasses")["tink.pure.NodeIterator"] = 
class NodeIterator extends Register.inherits() {
	new(node) {
		this.list = [];
		if (node != null) {
			this.list.push(node);
		};
	}
	hasNext() {
		return this.list.length > 0;
	}
	next() {
		var _g = this.list.pop();
		if (_g == null) {
			return null;
		} else {
			var _g1 = -_g.tails.length;
			while (_g1 < 0) this.list.push(_g.tails[-(_g1++) - 1]);
			return _g.value;
		};
	}
	static get __name__() {
		return "tink.pure.NodeIterator"
	}
	get __class__() {
		return NodeIterator
	}
}

