import {Observable} from "./Observable.js"
import {TypedError} from "../core/Error.js"
import {Timer} from "../../haxe/Timer.js"
import {Exception} from "../../haxe/Exception.js"
import {Register} from "../../genes/Register.js"
import {HxOverrides} from "../../HxOverrides.js"

const $global = Register.$global

export const SchedulerObject = function() {};
SchedulerObject.__isInterface__ = true;

export const DirectScheduler = Register.global("$hxClasses")["tink.state._Scheduler.DirectScheduler"] = 
class DirectScheduler extends Register.inherits() {
	new() {
		this.queue = null;
	}
	atomically(s, forceNow) {
		var _g = this.queue;
		if (_g == null) {
			this.queue = [];
			TypedError.tryFinally(Register.bind(s, s.run), Register.bind(this, this.processQueue));
		} else if (forceNow) {
			s.run();
		} else {
			_g.push(s);
		};
	}
	processQueue() {
		var error = null;
		var _g = 0;
		var _g1 = this.queue;
		while (_g < _g1.length) {
			var s = _g1[_g++];
			try {
				var wasUpdating = [Observable.isUpdating];
				Observable.isUpdating = true;
				TypedError.tryFinally(Register.bind(s, s.run), (function (wasUpdating) {
					return function () {
						Observable.isUpdating = wasUpdating[0];
					};
				})(wasUpdating));
			}catch (_g2) {
				error = Exception.caught(_g2);
			};
		};
		this.queue = null;
		if (error != null) {
			throw Exception.thrown(error);
		};
	}
	progress(_) {
		return false;
	}
	schedule(s) {
		if (this.queue != null) {
			this.queue.push(s);
		} else {
			var wasUpdating = Observable.isUpdating;
			Observable.isUpdating = true;
			TypedError.tryFinally(Register.bind(s, s.run), function () {
				Observable.isUpdating = wasUpdating;
			});
		};
	}
	static get __name__() {
		return "tink.state._Scheduler.DirectScheduler"
	}
	static get __interfaces__() {
		return [SchedulerObject]
	}
	get __class__() {
		return DirectScheduler
	}
}


export const Scheduler = Register.global("$hxClasses")["tink.state._Scheduler.Scheduler"] = 
class Scheduler {
	static atomically(f, forceNow) {
		Scheduler.direct.atomically(JustOnce.call(f), forceNow);
	}
	static batched(run) {
		return new BatchScheduler(run);
	}
	static batcher() {
		var later = function (fn) {
			Timer.delay(fn, 10);
		};
		var later1;
		try {
			later1 = ((o=>Register.bind(o, o.requestAnimationFrame))(window) != null) ? function (fn) {
				return window.requestAnimationFrame(fn);
			} : later;
		}catch (_g) {
			later1 = later;
		};
		var asap = function (fn) {
			later1(fn);
		};
		var asap1;
		try {
			var p = Promise.resolve(42);
			asap1 = function (fn) {
				return p.then(fn);
			};
		}catch (_g) {
			asap1 = asap;
		};
		return function (b, isRerun) {
			var _g = Register.bind(b, b.progress);
			var maxSeconds = .01;
			((isRerun) ? later1 : asap1)(function () {
				return _g(maxSeconds);
			});
		};
	}
	static get __name__() {
		return "tink.state._Scheduler.Scheduler_Impl_"
	}
	get __class__() {
		return Scheduler
	}
}


Register.createStatic(Scheduler, "direct", function () { return new DirectScheduler() })
export const BatchScheduler = Register.global("$hxClasses")["tink.state._Scheduler.BatchScheduler"] = 
class BatchScheduler extends Register.inherits() {
	new(run) {
		this.scheduled = false;
		this.queue = [];
		this.run = run;
	}
	progress(maxSeconds) {
		var _gthis = this;
		var wasUpdating = Observable.isUpdating;
		Observable.isUpdating = true;
		return TypedError.tryFinally(function () {
			var end = HxOverrides.now() / 1000 + maxSeconds;
			while (true) {
				var old = _gthis.queue;
				_gthis.queue = [];
				var _g = 0;
				while (_g < old.length) old[_g++].run();
				if (!(_gthis.queue.length > 0 && HxOverrides.now() / 1000 < end)) {
					break;
				};
			};
			if (_gthis.queue.length > 0) {
				_gthis.run(_gthis, true);
				return true;
			} else {
				return _gthis.scheduled = false;
			};
		}, function () {
			Observable.isUpdating = wasUpdating;
		});
	}
	schedule(s) {
		this.queue.push(s);
		if (!this.scheduled) {
			this.scheduled = true;
			this.run(this, false);
		};
	}
	static get __name__() {
		return "tink.state._Scheduler.BatchScheduler"
	}
	static get __interfaces__() {
		return [SchedulerObject]
	}
	get __class__() {
		return BatchScheduler
	}
}


export const Schedulable = function() {};
Schedulable.__isInterface__ = true;

export const JustOnce = Register.global("$hxClasses")["tink.state._Scheduler.JustOnce"] = 
class JustOnce extends Register.inherits() {
	new() {
	}
	run() {
		var f = this.f;
		this.f = null;
		JustOnce.pool.push(this);
		f();
	}
	static call(f) {
		var _g = JustOnce.pool.pop();
		var ret = (_g == null) ? new JustOnce() : _g;
		ret.f = f;
		return ret;
	}
	static get __name__() {
		return "tink.state._Scheduler.JustOnce"
	}
	static get __interfaces__() {
		return [Schedulable]
	}
	get __class__() {
		return JustOnce
	}
}


JustOnce.pool = []