import {RemoteBase, RemoteEndpoint, ResponseReader} from "./Remote.js"
import {Single} from "../../streams/Stream.js"
import {Builder3} from "../../querystring/Builder3.js"
import {Parser27} from "../../json/Parser27.js"
import {HeaderField, HeaderValue} from "../../http/Header.js"
import {LazyConst} from "../../core/Lazy.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote37 = Register.global("$hxClasses")["tink.web.proxy.Remote37"] = 
class Remote37 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	list(query) {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [].concat(new Builder3().stringify({"_143": query.after, "_144": query.before, "_145": query.includeAbnormal, "_146": query.limit})), "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pj))(new Parser27())));
	}
	static get __name__() {
		return "tink.web.proxy.Remote37"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote37
	}
}

