import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {Observable, ConstObservable} from "../../../tink/state/Observable.js"
import {BlobSource} from "../../../tink/io/js/BlobSource.js"
import {RealSourceTools, Source} from "../../../tink/io/Source.js"
import {FetchResponse, Fetch} from "../../../tink/http/Fetch.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Promise as Promise__1} from "../../../tink/core/Promise.js"
import {Outcome} from "../../../tink/core/Outcome.js"
import {LazyConst} from "../../../tink/core/Lazy.js"
import {SyncFuture, Future} from "../../../tink/core/Future.js"
import {TypedError} from "../../../tink/core/Error.js"
import {Callback} from "../../../tink/core/Callback.js"
import {Path} from "../../../haxe/io/Path.js"
import {Option} from "../../../haxe/ds/Option.js"
import {Register} from "../../../genes/Register.js"
import {Config} from "../../core/Config.js"
import {Updatable} from "../../../coconut/ds/Updatable.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const FloorPlanData = Register.global("$hxClasses")["dasloop.app.data.FloorPlanData"] = 
class FloorPlanData extends Register.inherits() {
	new(__coco_init) {
		this._updatePerformed = Signal.trigger();
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return Observable.get_value(_gthis._data.__coco_data);
		}), null);
		AutoObservable.cur = before;
		this.__coco_data = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return "" + Config.API_SERVER_URL + "/v2/floorplans/" + _gthis.id + "/image?v=" + State.get_value(_gthis.__coco_imageRevision);
		}), null);
		AutoObservable.cur = before;
		this.__coco_imageUrl = ret;
		this.id = __coco_init.id;
		this.remote = __coco_init.remote;
		var _g = __coco_init.initial;
		this.initial = (_g == null) ? Option.None : _g;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new Updatable({"loader": function () {
			return _gthis.remote.v2().floorplans().ofId(_gthis.id).get();
		}, "updater": function (_, patch) {
			return _gthis.remote.v2().floorplans().ofId(_gthis.id).update(patch);
		}, "cache": _gthis.initial});
		AutoObservable.cur = before;
		this._data = ret;
		this.__coco_imageRevision = State._new(new Date().getTime(), null, null, null);
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"id": new ConstObservable(this.id), "remote": new ConstObservable(this.remote), "initial": new ConstObservable(this.initial), "_data": new ConstObservable(this._data), "imageUrl": this.__coco_imageUrl, "data": this.__coco_data, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	updateImage(file) {
		var _gthis = this;
		var mime;
		var _g = Path.extension(file.name).toLowerCase();
		switch (_g) {
			case "gif":
				mime = "image/gif";
				break
			case "jpeg":case "jpg":
				mime = "image/jpeg";
				break
			case "png":
				mime = "image/png";
				break
			default:
			return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(null, "Unsupported file extension: " + _g, {"fileName": "src/dasloop/app/data/FloorPlanData.hx", "lineNumber": 32, "className": "dasloop.app.data.FloorPlanData", "methodName": "updateImage"}))));
			
		};
		var c = function (_) {
			_gthis.set_imageRevision(new Date().getTime());
		};
		var c1 = function (o) {
			if (o._hx_index == 0) {
				Callback.invoke(c, o.data);
			};
		};
		return Promise__1.noise(Future.map(Promise__1.next(this.remote.v2().floorplans().ofId(this.id).uploadImage(mime), function (header) {
			var header1 = header.url;
			var header2 = header.method;
			var _g = [];
			var _this = header.fields;
			var _g2_current = 0;
			while (_g2_current < _this.length) _g.push(_this[_g2_current++]);
			return FetchResponse.all(Fetch.fetch(header1, {"method": header2, "headers": _g, "body": RealSourceTools.idealize(new BlobSource("Floor Plan Image " + file.name, file, 0, 16777216), function (_) {
				return Source.EMPTY;
			})}));
		}), function (v) {
			Callback.invoke(c1, v);
			return v;
		}));
	}
	set_imageRevision(param) {
		this._updatePerformed.handlers.invoke({"imageRevision": param});
		this.__coco_imageRevision.set(param);
		return param;
	}
	static get __name__() {
		return "dasloop.app.data.FloorPlanData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return FloorPlanData
	}
}

