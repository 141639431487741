import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const DefaultBuffer = Register.global("$hxClasses")["tink.querystring._Builder.DefaultBuffer"] = 
class DefaultBuffer {
	static flush(this1) {
		return this1.join("&");
	}
	static get __name__() {
		return "tink.querystring._Builder.DefaultBuffer_Impl_"
	}
	get __class__() {
		return DefaultBuffer
	}
}

