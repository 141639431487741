import {Timezone} from "../../why/Timezone.js"
import {Register} from "../../genes/Register.js"
import {DateTools} from "./util/DateTools.js"

const $global = Register.$global

export const AggregationType = Register.global("$hxClasses")["dasloop.types._AggregationType.AggregationType"] = 
class AggregationType {
	static translate(this1, localizer) {
		switch (this1) {
			case 0:
				return localizer.second();
				break
			case 1:
				return localizer.minute();
				break
			case 2:
				return localizer.hour();
				break
			case 3:
				return localizer.day();
				break
			case 4:
				return localizer.month();
				break
			case 5:
				return localizer.year();
				break
			
		};
	}
	static getDuration(this1, from, to, start, timezone) {
		var interval = AggregationType.getInterval(this1, start, timezone);
		var fromTime = from.getTime();
		var toTime = to.getTime();
		var startTime = start.getTime();
		var endTime = startTime + interval;
		if (fromTime > startTime) {
			startTime = fromTime;
		};
		if (toTime < endTime) {
			endTime = toTime;
		};
		return endTime - startTime;
	}
	static getInterval(this1, date, timezone) {
		switch (this1) {
			case 0:
				return 1000.;
				break
			case 1:
				return 60000.;
				break
			case 2:
				return 3600000.;
				break
			case 3:
				return 86400000.;
				break
			case 4:
				var this1 = Timezone.getDate(timezone, date);
				var start = DateTools.monthStart(this1);
				return DateTools.monthEnd(this1).getTime() - start.getTime();
				break
			case 5:
				var this1 = Timezone.getDate(timezone, date);
				var start = DateTools.yearStart(this1);
				return DateTools.yearEnd(this1).getTime() - start.getTime();
				break
			
		};
	}
	static get __name__() {
		return "dasloop.types._AggregationType.AggregationType_Impl_"
	}
	get __class__() {
		return AggregationType
	}
}

