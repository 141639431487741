import {Annex} from "../core/Annex.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const BasicWriter = Register.global("$hxClasses")["tink.json.BasicWriter"] = 
class BasicWriter extends Register.inherits() {
	new() {
		this.a = new Annex(this);
	}
	d() {
		this.b = "";
	}
	e(s) {
		this.b += s;
	}
	f(c) {
		this.b += c;
	}
	g(v) {
		this.e((v == null) ? "null" : "" + v);
	}
	h(v) {
		this.e((v == null) ? "null" : "" + v);
	}
	i(b) {
		this.e((b) ? "true" : "false");
	}
	j(s) {
		this.e(JSON.stringify(s));
	}
	static get __name__() {
		return "tink.json.BasicWriter"
	}
	get __class__() {
		return BasicWriter
	}
}

