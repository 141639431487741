import {StringMap} from "../../haxe/ds/StringMap.js"
import {Register} from "../../genes/Register.js"
import {Runtime} from "./Runtime.js"

const $global = Register.$global

export const Declarations = Register.global("$hxClasses")["cix.css.Declarations"] = 
class Declarations {
	static add(className, css) {
		if (Declarations.declared == null) {
			Declarations.declared = new StringMap();
		};
		if (!Declarations.declared.inst.get(className)) {
			Runtime.addRule(className, css());
			Declarations.declared.inst.set(className, true);
		};
		return className;
	}
	static get __name__() {
		return "cix.css.Declarations"
	}
	get __class__() {
		return Declarations
	}
}

