import {Revision} from "./Revision.js"
import {ObservableObject} from "./ObservableObject.js"
import {Invalidatable, Invalidator} from "./Invalidatable.js"
import {PromisedWith} from "../Promised.js"
import {Observable} from "../Observable.js"
import {Option} from "../../../haxe/ds/Option.js"
import {Exception} from "../../../haxe/Exception.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Derived = function() {};
Derived.__isInterface__ = true;

export const AutoObservable = Register.global("$hxClasses")["tink.state.internal.AutoObservable"] = 
class AutoObservable extends Register.inherits(Invalidator) {
	new(compute, comparator) {
		this.sync = true;
		this.waiting = false;
		this.dependencies = new Map();
		this.last = null;
		this.status = 0;
		this.hot = false;
		var _gthis = this;
		super.new();
		this.compute = compute;
		this.comparator = comparator;
		this.list.onfill = function () {
			_gthis.getValue();
			_gthis.getRevision();
			if (_gthis.subscriptions != null) {
				var _g = 0;
				var _g1 = _gthis.subscriptions;
				while (_g < _g1.length) {
					var s = _g1[_g];
					++_g;
					s.link = s.source.onInvalidate(s.owner);
				};
			};
			_gthis.hot = true;
		};
		this.list.ondrain = function () {
			_gthis.hot = false;
			if (_gthis.subscriptions != null) {
				var _g = 0;
				var _g1 = _gthis.subscriptions;
				while (_g < _g1.length) {
					var this1 = _g1[_g++].link;
					if (this1 != null) {
						this1.cancel();
					};
				};
			};
		};
	}
	getRevision() {
		if (this.hot) {
			return this.revision;
		};
		if (this.subscriptions == null) {
			this.getValue();
		};
		var _g = 0;
		var _g1 = this.subscriptions;
		while (_g < _g1.length) if (_g1[_g++].source.getRevision() > this.revision) {
			return this.revision = Revision._new();
		};
		return this.revision;
	}
	subsValid() {
		if (this.subscriptions == null) {
			return false;
		};
		var _g = 0;
		var _g1 = this.subscriptions;
		while (_g < _g1.length) {
			var s = _g1[_g];
			++_g;
			if (s.source.getRevision() != s.lastRev) {
				return false;
			};
		};
		return true;
	}
	isValid() {
		if (this.status != 0) {
			if (!this.hot) {
				return this.subsValid();
			} else {
				return true;
			};
		} else {
			return false;
		};
	}
	getComparator() {
		return this.comparator;
	}
	getValue() {
		var _gthis = this;
		var doCompute = function () {
			_gthis.status = 1;
			if (_gthis.subscriptions != null) {
				var _g = 0;
				var _g1 = _gthis.subscriptions;
				while (_g < _g1.length) _g1[_g++].used = false;
			};
			_gthis.subscriptions = [];
			_gthis.sync = true;
			var before = AutoObservable.cur;
			AutoObservable.cur = _gthis;
			var ret = _gthis.compute(_gthis);
			AutoObservable.cur = before;
			_gthis.last = ret;
			_gthis.sync = false;
			if (_gthis.subscriptions.length == 0 && !_gthis.waiting) {
				_gthis.list.dispose();
				_gthis.observers.clear();
			};
		};
		var prevSubs = this.subscriptions;
		var count = 0;
		while (!this.isValid()) if (++count == Observable.MAX_ITERATIONS) {
			throw Exception.thrown("no result after " + Observable.MAX_ITERATIONS + " attempts");
		} else if (this.subscriptions != null) {
			var valid = true;
			var _g = 0;
			var _g1 = this.subscriptions;
			while (_g < _g1.length) {
				var s = _g1[_g];
				++_g;
				var nextRev = s.source.getRevision();
				var tmp;
				if (nextRev == s.lastRev) {
					tmp = false;
				} else {
					s.lastRev = nextRev;
					var before = s.last;
					s.last = s.source.getValue();
					var this1 = s.source.getComparator();
					var a = s.last;
					tmp = !((this1 == null) ? a == before : this1(a, before));
				};
				if (tmp) {
					valid = false;
					break;
				};
			};
			if (valid) {
				this.status = 1;
			} else {
				doCompute();
				if (prevSubs != null) {
					var _g2 = 0;
					while (_g2 < prevSubs.length) {
						var s1 = prevSubs[_g2];
						++_g2;
						if (!s1.used) {
							if (this.hot) {
								var this2 = s1.link;
								if (this2 != null) {
									this2.cancel();
								};
							};
							this.dependencies["delete"](s1.source);
						};
					};
				};
			};
		} else {
			doCompute();
		};
		return this.last;
	}
	update(value) {
		if (!this.sync) {
			this.waiting = false;
			this.last = value;
			this.fire();
			if (this.subscriptions.length == 0) {
				this.list.dispose();
				this.observers.clear();
			};
		};
	}
	subscribeTo(source, cur) {
		var _g = this.dependencies.get(source);
		if (_g == null) {
			var sub = new SubscriptionTo(source, cur, this);
			this.dependencies.set(source, sub);
			this.subscriptions.push(sub);
		} else if (!_g.used) {
			_g.used = true;
			_g.last = cur;
			this.subscriptions.push(_g);
		};
	}
	invalidate() {
		if (this.status == 1) {
			this.status = 0;
			this.fire();
		};
	}
	static get __name__() {
		return "tink.state.internal.AutoObservable"
	}
	static get __interfaces__() {
		return [ObservableObject, Derived, Invalidatable]
	}
	static get __super__() {
		return Invalidator
	}
	get __class__() {
		return AutoObservable
	}
}


export const Computation = Register.global("$hxClasses")["tink.state.internal._AutoObservable.Computation"] = 
class Computation {
	static asyncWithLast(f) {
		var link = null;
		var last = Option.None;
		var ret = PromisedWith.Loading;
		return function (a, _) {
			a.waiting = true;
			ret = PromisedWith.Loading;
			var prev = link;
			link = f(last).handle(function (o) {
				var a1 = a;
				switch (o._hx_index) {
					case 0:
						var _g = o.data;
						last = Option.Some(_g);
						ret = PromisedWith.Done(_g);
						break
					case 1:
						ret = PromisedWith.Failed(o.failure);
						break
					
				};
				a1.update(ret);
			});
			if (prev != null) {
				prev.cancel();
			};
			return ret;
		};
	}
	static async(f) {
		var link = null;
		var ret = PromisedWith.Loading;
		return function (a, _) {
			a.waiting = true;
			ret = PromisedWith.Loading;
			var prev = link;
			link = f().handle(function (o) {
				var a1 = a;
				switch (o._hx_index) {
					case 0:
						ret = PromisedWith.Done(o.data);
						break
					case 1:
						ret = PromisedWith.Failed(o.failure);
						break
					
				};
				a1.update(ret);
			});
			if (prev != null) {
				prev.cancel();
			};
			return ret;
		};
	}
	static sync(f) {
		return function (_, _1) {
			return f();
		};
	}
	static get __name__() {
		return "tink.state.internal._AutoObservable.Computation_Impl_"
	}
	get __class__() {
		return Computation
	}
}


export const SubscriptionTo = Register.global("$hxClasses")["tink.state.internal._AutoObservable.SubscriptionTo"] = 
class SubscriptionTo extends Register.inherits() {
	new(source, cur, owner) {
		this.used = true;
		this.source = source;
		this.last = cur;
		this.lastRev = source.getRevision();
		this.owner = owner;
		if (owner.hot) {
			this.link = this.source.onInvalidate(this.owner);
		};
	}
	static get __name__() {
		return "tink.state.internal._AutoObservable.SubscriptionTo"
	}
	get __class__() {
		return SubscriptionTo
	}
}

