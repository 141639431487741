import {Writer0} from "./Writer0.js"
import {BasicParser, JsonString} from "./Parser.js"
import {TypedError} from "../core/Error.js"
import {Exception} from "../../haxe/Exception.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Parser6 = Register.global("$hxClasses")["tink.json.Parser6"] = 
class Parser6 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	pb() {
		var _gthis = this;
		var v_archived = false;
		var hasv_archived = false;
		var v_boundary = null;
		var hasv_boundary = false;
		var v_cameraPosition = null;
		var hasv_cameraPosition = false;
		var v_client = null;
		var hasv_client = false;
		var v_contractor = null;
		var hasv_contractor = false;
		var v_elevation = .0;
		var hasv_elevation = false;
		var v_endDate = null;
		var hasv_endDate = false;
		var v_expiry = null;
		var hasv_expiry = false;
		var v_gridSize = 0;
		var hasv_gridSize = false;
		var v_heartbeatTimeout = null;
		var hasv_heartbeatTimeout = false;
		var v_id = null;
		var hasv_id = false;
		var v_ignoreAlerts = false;
		var hasv_ignoreAlerts = false;
		var v_name = null;
		var hasv_name = false;
		var v_number = null;
		var hasv_number = false;
		var v_region = null;
		var hasv_region = false;
		var v_startDate = null;
		var hasv_startDate = false;
		var v_timezone = .0;
		var hasv_timezone = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "archived":
						v_archived = this.G();
						hasv_archived = true;
						break
					case "boundary":
						this.F("[");
						var __ret = [];
						if (!this.E("]")) {
							while (true) {
								this.F("[");
								var __ret1 = [];
								if (!this.E("]")) {
									while (true) {
										this.F("[");
										var __ret2 = [];
										if (!this.E("]")) {
											while (true) {
												this.F("[");
												var __ret3 = [];
												if (!this.E("]")) {
													while (true) {
														__ret3.push(JsonString.f(this.r()));
														if (!this.E(",")) {
															break;
														};
													};
													this.F("]");
												};
												__ret2.push(__ret3);
												if (!this.E(",")) {
													break;
												};
											};
											this.F("]");
										};
										__ret1.push(__ret2);
										if (!this.E(",")) {
											break;
										};
									};
									this.F("]");
								};
								__ret.push(__ret1);
								if (!this.E(",")) {
									break;
								};
							};
							this.F("]");
						};
						v_boundary = __ret;
						hasv_boundary = true;
						break
					case "cameraPosition":
						if (this.E("null")) {
							v_cameraPosition = null;
						} else {
							this.F("[");
							var __ret4 = [];
							if (!this.E("]")) {
								while (true) {
									__ret4.push(JsonString.f(this.r()));
									if (!this.E(",")) {
										break;
									};
								};
								this.F("]");
							};
							v_cameraPosition = __ret4;
						};
						hasv_cameraPosition = true;
						break
					case "client":
						v_client = JsonString.s(this.m());
						hasv_client = true;
						break
					case "contractor":
						v_contractor = JsonString.s(this.m());
						hasv_contractor = true;
						break
					case "elevation":
						v_elevation = JsonString.f(this.r());
						hasv_elevation = true;
						break
					case "endDate":
						v_endDate = new Date(JsonString.f(this.r()));
						hasv_endDate = true;
						break
					case "expiry":
						v_expiry = (this.E("null")) ? null : new Date(JsonString.f(this.r()));
						hasv_expiry = true;
						break
					case "gridSize":
						v_gridSize = (this.E("null")) ? null : JsonString.i(this.r());
						hasv_gridSize = true;
						break
					case "heartbeatTimeout":
						v_heartbeatTimeout = this.pc();
						hasv_heartbeatTimeout = true;
						break
					case "id":
						v_id = JsonString.s(this.m());
						hasv_id = true;
						break
					case "ignoreAlerts":
						v_ignoreAlerts = this.G();
						hasv_ignoreAlerts = true;
						break
					case "name":
						v_name = JsonString.s(this.m());
						hasv_name = true;
						break
					case "number":
						v_number = JsonString.s(this.m());
						hasv_number = true;
						break
					case "region":
						var v = JsonString.s(this.m());
						switch (v) {
							case "CHC":case "HKG":case "HSC":case "KHH":case "KUL":case "NGT":case "NTP":case "RMQ":case "SIT":case "SYD":case "TNN":case "TPE":case "TYN":case "TYO":
								v_region = v;
								break
							default:
							throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer0().wb(["HKG", "TPE", "RMQ", "CHC", "TYN", "HSC", "NTP", "TNN", "KHH", "KUL", "TYO", "SYD", "NGT", "SIT"]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 469, "className": "tink.json.Parser6", "methodName": "process0"}));
							
						};
						hasv_region = true;
						break
					case "startDate":
						v_startDate = new Date(JsonString.f(this.r()));
						hasv_startDate = true;
						break
					case "timezone":
						v_timezone = JsonString.f(this.r()) * 60;
						hasv_timezone = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"archived": (hasv_archived) ? v_archived : __missing__("archived"), "boundary": (hasv_boundary) ? v_boundary : __missing__("boundary"), "cameraPosition": (hasv_cameraPosition) ? v_cameraPosition : __missing__("cameraPosition"), "client": (hasv_client) ? v_client : __missing__("client"), "contractor": (hasv_contractor) ? v_contractor : __missing__("contractor"), "elevation": (hasv_elevation) ? v_elevation : __missing__("elevation"), "endDate": (hasv_endDate) ? v_endDate : __missing__("endDate"), "expiry": (hasv_expiry) ? v_expiry : __missing__("expiry"), "gridSize": (hasv_gridSize) ? v_gridSize : __missing__("gridSize"), "heartbeatTimeout": (hasv_heartbeatTimeout) ? v_heartbeatTimeout : __missing__("heartbeatTimeout"), "id": (hasv_id) ? v_id : __missing__("id"), "ignoreAlerts": (hasv_ignoreAlerts) ? v_ignoreAlerts : __missing__("ignoreAlerts"), "name": (hasv_name) ? v_name : __missing__("name"), "number": (hasv_number) ? v_number : __missing__("number"), "region": (hasv_region) ? v_region : __missing__("region"), "startDate": (hasv_startDate) ? v_startDate : __missing__("startDate"), "timezone": (hasv_timezone) ? v_timezone : __missing__("timezone")};
	}
	pc() {
		var _gthis = this;
		var v_normal = .0;
		var hasv_normal = false;
		var v_sleep = .0;
		var hasv_sleep = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "normal":
						v_normal = JsonString.f(this.r());
						hasv_normal = true;
						break
					case "sleep":
						v_sleep = JsonString.f(this.r());
						hasv_sleep = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"normal": (hasv_normal) ? v_normal : __missing__("normal"), "sleep": (hasv_sleep) ? v_sleep : __missing__("sleep")};
	}
	pd(source) {
		if (this.e.length > 0) {
			this.e = [];
		};
		this.g(source);
		var ret = this.pb();
		var _g = 0;
		var _g1 = this.e;
		while (_g < _g1.length) _g1[_g++]();
		if (this.e.length > 0) {
			this.e = [];
		};
		return ret;
	}
	pe(source) {
		var _gthis = this;
		return TypedError.catchExceptions(function () {
			var ret = _gthis.pd(source);
			_gthis.h();
			if (_gthis.c < _gthis.d) {
				_gthis.D("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser6", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser6"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser6
	}
}

