import {Outcome} from "../tink/core/Outcome.js"
import {SuspendableFuture} from "../tink/core/Future.js"
import {TypedError} from "../tink/core/Error.js"
import {Register} from "../genes/Register.js"
import {Config} from "./util/Config.js"
import {App} from "./App.js"

const $global = Register.$global

export const Main = Register.global("$hxClasses")["dasloop.Main"] = 
class Main {
	static main() {
		var script = window.document.createElement("script");
		script.onload = function () {
			var keycloak = new Keycloak({"url": Config.KEYCLOAK_FRONTEND_URL, "realm": Config.KEYCLOAK_REALM, "clientId": Config.KEYCLOAK_CLIENT_ID});
			var f = function (resolve, reject) {
				keycloak.init({"onLoad": "login-required"}).then(function (authenticated) {
					if (authenticated) {
						resolve(keycloak);
					} else {
						reject(new TypedError(null, "not authenticated", {"fileName": "src/dasloop/Main.hx", "lineNumber": 27, "className": "dasloop.Main", "methodName": "main"}));
					};
				})["catch"](function (e) {
					reject(TypedError.withData(500, e.message, e, {"fileName": "src/dasloop/Main.hx", "lineNumber": 28, "className": "dasloop.Main", "methodName": "main"}));
				});
			};
			var f1 = function (res, rej) {
				f(res, rej);
				return null;
			};
			App.main(new SuspendableFuture(function (cb) {
				return f1(function (v) {
					cb(Outcome.Success(v));
				}, function (e) {
					cb(Outcome.Failure(e));
				});
			}));
		};
		script.src = "" + Config.KEYCLOAK_FRONTEND_URL + "/js/keycloak.js";
		window.document.body.appendChild(script);
	}
	static get __name__() {
		return "dasloop.Main"
	}
	get __class__() {
		return Main
	}
}

