import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {ConstObservable, Observable} from "../../../tink/state/Observable.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Future} from "../../../tink/core/Future.js"
import {Callback} from "../../../tink/core/Callback.js"
import {Register} from "../../../genes/Register.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const ProjectLogsData = Register.global("$hxClasses")["dasloop.app.data.ProjectLogsData"] = 
class ProjectLogsData extends Register.inherits() {
	new(__coco_init) {
		this._updatePerformed = Signal.trigger();
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.async(function () {
			State.get_value(_gthis.__coco_revision);
			return _gthis.remote.v2().projects().ofId(_gthis.project).logs().list();
		}), null);
		AutoObservable.cur = before;
		this.__coco_list = ret;
		this.project = __coco_init.project;
		this.remote = __coco_init.remote;
		this.__coco_revision = State._new(0, null, null, null);
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"project": new ConstObservable(this.project), "remote": new ConstObservable(this.remote), "list": this.__coco_list, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	add(content) {
		var c = Register.bind(this, this.refresh);
		var c1 = function (o) {
			if (o._hx_index == 0) {
				Callback.invoke(c, o.data);
			};
		};
		return Future.map(this.remote.v2().projects().ofId(this.project).logs().create({"content": content}), function (v) {
			Callback.invoke(c1, v);
			return v;
		});
	}
	refresh() {
		this.set_revision(State.get_value(this.__coco_revision) + 1);
	}
	set_revision(param) {
		this._updatePerformed.handlers.invoke({"revision": param});
		this.__coco_revision.set(param);
		return param;
	}
	static get __name__() {
		return "dasloop.app.data.ProjectLogsData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return ProjectLogsData
	}
}

