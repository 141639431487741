import {LazyFunc} from "../../tink/core/Lazy.js"
import * as React from "react"
import {Component} from "react"
import {Register} from "../../genes/Register.js"
import {ImplicitContext} from "../ui/internal/ImplicitContext.js"
import {View} from "./View.js"

const $global = Register.$global

export const Implicit = Register.global("$hxClasses")["coconut.react.Implicit"] = 
class Implicit extends Register.inherits(Component) {
	new() {
		var _gthis = this;
		Component.call(this);
		this.__coco_context = new ImplicitContext(new LazyFunc(function () {
			return _gthis.context;
		}));
	}
	render() {
		this.__coco_context.update(this.props.defaults);
		return React.createElement(Implicit.contextType.Provider, {"value": this.__coco_context}, View.createFragment({}, this.props.children));
	}
	static fromHxx(attr) {
		return React.createElement(Implicit, attr);
	}
	static get __name__() {
		return "coconut.react.Implicit"
	}
	static get __super__() {
		return Component
	}
	get __class__() {
		return Implicit
	}
}


Implicit.contextType = React.createContext(new ImplicitContext())