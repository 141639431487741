import {Subscriber as Subscriber__1} from "../Subscriber.js"
import {Envelope as Envelope__1} from "../Envelope.js"
import {Topic} from "../../mqtt/Topic.js"
import {SimpleSubscription} from "../../../tink/core/ext/Subscription.js"
import {Signal} from "../../../tink/core/Signal.js"
import {LazyFunc} from "../../../tink/core/Lazy.js"
import {SimpleLink} from "../../../tink/core/Callback.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Subscriber = Register.global("$hxClasses")["why.pubsub.mqtt.Subscriber"] = 
class Subscriber extends Register.inherits() {
	new(client, config) {
		this.client = client;
		this.config = config;
	}
	subscribe(handler) {
		var _gthis = this;
		var errors = Signal.trigger();
		var binding1 = null;
		binding1 = this.client.subscribe(this.config.topic, this.config).handle(function (o) {
			switch (o._hx_index) {
				case 0:
					binding1 = o.data;
					break
				case 1:
					errors.handlers.invoke(o.failure);
					break
				
			};
		});
		var binding2 = this.client.messageReceived.listen(function (message) {
			if (Topic.match(_gthis.config.topic, message.topic)) {
				var _g = _gthis.config.unserialize;
				var a1 = message.payload;
				var binding2 = new LazyFunc(function () {
					return _g(a1);
				});
				var _g1 = _gthis.config.metadata;
				var a11 = message;
				var binding21 = new LazyFunc(function () {
					return _g1(a11);
				});
				handler(new Envelope(message.payload, binding2, binding21));
			};
		});
		return new SimpleSubscription(new SimpleLink(function () {
			if (binding1 != null) {
				binding1.cancel();
			};
			if (binding2 != null) {
				binding2.cancel();
			};
		}), Signal.trigger());
	}
	static get __name__() {
		return "why.pubsub.mqtt.Subscriber"
	}
	static get __interfaces__() {
		return [Subscriber__1]
	}
	get __class__() {
		return Subscriber
	}
}


export const Envelope = Register.global("$hxClasses")["why.pubsub.mqtt.Envelope"] = 
class Envelope extends Register.inherits() {
	new(raw, content, metadata) {
		this.raw = raw;
		this.content = content;
		this.metadata = metadata;
	}
	static get __name__() {
		return "why.pubsub.mqtt.Envelope"
	}
	static get __interfaces__() {
		return [Envelope__1]
	}
	get __class__() {
		return Envelope
	}
}

