import {BlobSource} from "../../../tink/io/js/BlobSource.js"
import {RealSourceTools, Source} from "../../../tink/io/Source.js"
import {FetchResponse, Fetch} from "../../../tink/http/Fetch.js"
import {Promise as Promise__1} from "../../../tink/core/Promise.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const BimTools = Register.global("$hxClasses")["dasloop.app.util.BimTools"] = 
class BimTools {
	static uploadModel(remote, projectId, bimId, file) {
		return Promise__1.noise(Promise__1.next(remote.v2().projects().ofId(projectId).bims().ofId(bimId).uploadModel("application/octet-stream"), function (header) {
			var url = header.url;
			var header1 = header.method;
			var _g = [];
			var _this = header.fields;
			var _g1_current = 0;
			while (_g1_current < _this.length) _g.push(_this[_g1_current++]);
			return FetchResponse.all(Fetch.fetch(url, {"method": header1, "headers": _g, "body": RealSourceTools.idealize(new BlobSource(file.name, file, 0, 16777216), function (_) {
				return Source.EMPTY;
			})}));
		}));
	}
	static get __name__() {
		return "dasloop.app.util.BimTools"
	}
	get __class__() {
		return BimTools
	}
}

