import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Computable = function() {};
Computable.__isInterface__ = true;

export const LazyObject = function() {};
LazyObject.__isInterface__ = true;

export const LazyConst = Register.global("$hxClasses")["tink.core._Lazy.LazyConst"] = 
class LazyConst extends Register.inherits() {
	new(value) {
		this.value = value;
	}
	isComputed() {
		return true;
	}
	get() {
		return this.value;
	}
	compute() {
	}
	underlying() {
		return null;
	}
	static get __name__() {
		return "tink.core._Lazy.LazyConst"
	}
	static get __interfaces__() {
		return [LazyObject]
	}
	get __class__() {
		return LazyConst
	}
}


export const Lazy = Register.global("$hxClasses")["tink.core._Lazy.Lazy"] = 
class Lazy {
	static get(this1) {
		this1.compute();
		return this1.get();
	}
	static get __name__() {
		return "tink.core._Lazy.Lazy_Impl_"
	}
	get __class__() {
		return Lazy
	}
}


export const LazyFunc = Register.global("$hxClasses")["tink.core._Lazy.LazyFunc"] = 
class LazyFunc extends Register.inherits() {
	new(f, from) {
		this.f = f;
		this.from = from;
	}
	underlying() {
		return this.from;
	}
	isComputed() {
		return this.f == null;
	}
	get() {
		return this.result;
	}
	compute() {
		var _g = this.f;
		if (_g != null) {
			this.f = null;
			var _g1 = this.from;
			if (_g1 != null) {
				var cur = _g1;
				this.from = null;
				var stack = [];
				while (cur != null && !cur.isComputed()) {
					stack.push(cur);
					cur = cur.underlying();
				};
				stack.reverse();
				var _g1 = 0;
				while (_g1 < stack.length) stack[_g1++].compute();
			};
			this.result = _g();
		};
	}
	static get __name__() {
		return "tink.core._Lazy.LazyFunc"
	}
	static get __interfaces__() {
		return [LazyObject]
	}
	get __class__() {
		return LazyFunc
	}
}

