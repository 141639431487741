import {Outcome} from "./Outcome.js"
import {Lazy, LazyFunc, LazyConst} from "./Lazy.js"
import {TypedError} from "./Error.js"
import {Callback, CallbackLinkRef, LinkPair, CallbackLink, CallbackList, ListCell} from "./Callback.js"
import {Timer} from "../../haxe/Timer.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const FutureObject = Register.global("$hxClasses")["tink.core._Future.FutureObject"] = 
class FutureObject extends Register.inherits() {
	new() {
	}
	getStatus() {
		return FutureStatus.NeverEver;
	}
	handle(callback) {
		return null;
	}
	eager() {
	}
	static get __name__() {
		return "tink.core._Future.FutureObject"
	}
	get __class__() {
		return FutureObject
	}
}


export const SyncFuture = Register.global("$hxClasses")["tink.core._Future.SyncFuture"] = 
class SyncFuture extends Register.inherits(() => FutureObject, true) {
	new(value) {
		super.new();
		this.value = value;
	}
	getStatus() {
		return FutureStatus.Ready(this.value);
	}
	handle(cb) {
		Callback.invoke(cb, Lazy.get(this.value));
		return null;
	}
	eager() {
		if (!this.value.isComputed()) {
			Lazy.get(this.value);
		};
	}
	static get __name__() {
		return "tink.core._Future.SyncFuture"
	}
	static get __super__() {
		return FutureObject
	}
	get __class__() {
		return SyncFuture
	}
}


export const Future = Register.global("$hxClasses")["tink.core._Future.Future"] = 
class Future {
	static never() {
		return Future.NEVER_INST;
	}
	
	/**
	*  Creates a new future by applying a transform function to the result.
	*  Different from `flatMap`, the transform function of `map` returns a sync value
	*/
	static map(this1, f, gather) {
		var _g = this1.getStatus();
		switch (_g._hx_index) {
			case 3:
				var this2 = _g.result;
				var f1 = f;
				return new SyncFuture(new LazyFunc(function () {
					return f1(this2.get());
				}, this2));
				break
			case 4:
				return Future.never();
				break
			default:
			return new SuspendableFuture(function (fire) {
				return this1.handle(function (v) {
					fire(f(v));
				});
			});
			
		};
	}
	
	/**
	*  Creates a new future by applying a transform function to the result.
	*  Different from `map`, the transform function of `flatMap` returns a `Future`
	*/
	static flatMap(this1, next, gather) {
		var _g = this1.getStatus();
		switch (_g._hx_index) {
			case 3:
				var l = _g.result;
				return new SuspendableFuture(function (fire) {
					return next(Lazy.get(l)).handle(function (v) {
						fire(v);
					});
				});
				break
			case 4:
				return Future.never();
				break
			default:
			return new SuspendableFuture(function ($yield) {
				var inner = new CallbackLinkRef();
				return new LinkPair(this1.handle(function (v) {
					var param = next(v).handle($yield);
					var this1 = inner.link;
					if (this1 != null) {
						this1.cancel();
					};
					inner.link = param;
				}), inner);
			});
			
		};
	}
	
	/**
	*  Casts a js Promise into a Surprise
	*/
	static ofJsPromise(promise, transformError) {
		return Future.irreversible(function (cb) {
			promise.then(function (a) {
				var _g = cb;
				var a1 = Outcome.Success(a);
				Callback.defer(function () {
					_g(a1);
				});
			}, function (e) {
				var cb1 = cb;
				var tmp;
				if (transformError == null) {
					var e1 = e;
					tmp = TypedError.withData(500, e1.message, e1, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_core/2.1.1/github/414a0538630f9e2b2103253f9998003ca7158ec4/src/tink/core/Future.hx", "lineNumber": 176, "className": "tink.core._Future.Future_Impl_", "methodName": "ofJsPromise"});
				} else {
					tmp = transformError(e);
				};
				cb1(Outcome.Failure(tmp));
			});
		});
	}
	static processMany(a, concurrency, fn, lift) {
		if (a.length == 0) {
			return new SyncFuture(new LazyConst(lift(Outcome.Success([]))));
		} else {
			return new SuspendableFuture(function ($yield) {
				var links = new Array();
				var _g = [];
				var _g1 = 0;
				while (_g1 < a.length) {
					++_g1;
					_g.push(null);
				};
				var ret = _g;
				var index = 0;
				var pending = 0;
				var done = false;
				var concurrency1;
				if (concurrency == null) {
					concurrency1 = a.length;
				} else {
					var v = concurrency;
					concurrency1 = (v < 1) ? 1 : (v > a.length) ? a.length : v;
				};
				var fireWhenReady = function () {
					if (index == ret.length) {
						if (pending == 0) {
							var v = lift(Outcome.Success(ret));
							done = true;
							$yield(v);
							return true;
						} else {
							return false;
						};
					} else {
						return false;
					};
				};
				var step = null;
				step = function () {
					if (!done && !fireWhenReady()) {
						while (index < ret.length) {
							index += 1;
							var index1 = [index - 1];
							var p = a[index1[0]];
							var check = [(function (index) {
								return function (o) {
									var _g = fn(o);
									switch (_g._hx_index) {
										case 0:
											ret[index[0]] = _g.data;
											fireWhenReady();
											break
										case 1:
											var _g1 = _g.failure;
											var _g = 0;
											while (_g < links.length) {
												var l = links[_g];
												++_g;
												if (l != null) {
													l.cancel();
												};
											};
											var v = lift(Outcome.Failure(_g1));
											done = true;
											$yield(v);
											break
										
									};
								};
							})(index1)];
							var _g = p.getStatus();
							if (_g._hx_index == 3) {
								var _hx_tmp;
								_hx_tmp = Lazy.get(_g.result);
								check[0](_hx_tmp);
								if (!done) {
									continue;
								};
							} else {
								pending += 1;
								links.push(p.handle((function (check) {
									return function (o) {
										pending -= 1;
										check[0](o);
										if (!done) {
											step();
										};
									};
								})(check)));
							};
							break;
						};
					};
				};
				var _g = 0;
				var _g1 = concurrency1;
				while (_g < _g1) {
					++_g;
					step();
				};
				return CallbackLink.fromMany(links);
			});
		};
	}
	static async(init, lazy) {
		if (lazy == null) {
			lazy = false;
		};
		var ret = Future.irreversible(init);
		if (lazy) {
			return ret;
		} else {
			ret.eager();
			return ret;
		};
	}
	
	/**
	* Creates an irreversible future:
	* `init` gets called, when the first handler is registered or `eager()` is called.
	* The future is never suspended again. When possible, use `new Future()` instead.
	*/
	static irreversible(init) {
		return new SuspendableFuture(function ($yield) {
			init($yield);
			return null;
		});
	}
	static delay(ms, value) {
		var this1 = Future.irreversible(function (cb) {
			Timer.delay(function () {
				cb(Lazy.get(value));
			}, ms);
		});
		this1.eager();
		return this1;
	}
	static get __name__() {
		return "tink.core._Future.Future_Impl_"
	}
	get __class__() {
		return Future
	}
}


Register.createStatic(Future, "NEVER_INST", function () { return new FutureObject() })
export const FutureStatus = 
Register.global("$hxEnums")["tink.core.FutureStatus"] = 
{
	__ename__: "tink.core.FutureStatus",
	
	Suspended: {_hx_name: "Suspended", _hx_index: 0, __enum__: "tink.core.FutureStatus"},
	Awaited: {_hx_name: "Awaited", _hx_index: 1, __enum__: "tink.core.FutureStatus"},
	EagerlyAwaited: {_hx_name: "EagerlyAwaited", _hx_index: 2, __enum__: "tink.core.FutureStatus"},
	Ready: Object.assign((result) => ({_hx_index: 3, __enum__: "tink.core.FutureStatus", "result": result}), {_hx_name: "Ready", __params__: ["result"]}),
	NeverEver: {_hx_name: "NeverEver", _hx_index: 4, __enum__: "tink.core.FutureStatus"}
}
FutureStatus.__constructs__ = [FutureStatus.Suspended, FutureStatus.Awaited, FutureStatus.EagerlyAwaited, FutureStatus.Ready, FutureStatus.NeverEver]
FutureStatus.__empty_constructs__ = [FutureStatus.Suspended, FutureStatus.Awaited, FutureStatus.EagerlyAwaited, FutureStatus.NeverEver]

export const FutureTrigger = Register.global("$hxClasses")["tink.core.FutureTrigger"] = 
class FutureTrigger extends Register.inherits(() => FutureObject, true) {
	new() {
		this.status = FutureStatus.Awaited;
		super.new();
		this.list = new CallbackList(true);
	}
	getStatus() {
		return this.status;
	}
	handle(callback) {
		var _g = this.status;
		if (_g._hx_index == 3) {
			Callback.invoke(callback, Lazy.get(_g.result));
			return null;
		} else {
			var _this = this.list;
			if (_this.disposeHandlers == null) {
				return null;
			} else {
				var node = new ListCell(callback, _this);
				_this.cells.push(node);
				if (_this.used++ == 0) {
					var fn = _this.onfill;
					if (Callback.depth < 500) {
						Callback.depth++;
						fn();
						Callback.depth--;
					} else {
						Callback.defer(fn);
					};
				};
				return node;
			};
		};
	}
	
	/**
	*  Triggers a value for this future
	*/
	trigger(result) {
		if (this.status._hx_index == 3) {
			return false;
		} else {
			this.status = FutureStatus.Ready(new LazyConst(result));
			this.list.invoke(result);
			return true;
		};
	}
	static get __name__() {
		return "tink.core.FutureTrigger"
	}
	static get __super__() {
		return FutureObject
	}
	get __class__() {
		return FutureTrigger
	}
}


export const SuspendableFuture = Register.global("$hxClasses")["tink.core._Future.SuspendableFuture"] = 
class SuspendableFuture extends Register.inherits(() => FutureObject, true) {
	new(wakeup) {
		this.status = FutureStatus.Suspended;
		var _gthis = this;
		super.new();
		this.wakeup = wakeup;
		this.callbacks = new CallbackList(true);
		this.callbacks.ondrain = function () {
			if (_gthis.status == FutureStatus.Awaited) {
				_gthis.status = FutureStatus.Suspended;
				var this1 = _gthis.link;
				if (this1 != null) {
					this1.cancel();
				};
				_gthis.link = null;
			};
		};
		this.callbacks.onfill = function () {
			if (_gthis.status == FutureStatus.Suspended) {
				_gthis.status = FutureStatus.Awaited;
				_gthis.arm();
			};
		};
	}
	getStatus() {
		return this.status;
	}
	trigger(value) {
		if (this.status._hx_index != 3) {
			this.status = FutureStatus.Ready(new LazyConst(value));
			var link = this.link;
			this.link = null;
			this.wakeup = null;
			this.callbacks.invoke(value);
			if (link != null) {
				link.cancel();
			};
		};
	}
	handle(callback) {
		var _g = this.status;
		if (_g._hx_index == 3) {
			Callback.invoke(callback, Lazy.get(_g.result));
			return null;
		} else {
			var _this = this.callbacks;
			if (_this.disposeHandlers == null) {
				return null;
			} else {
				var node = new ListCell(callback, _this);
				_this.cells.push(node);
				if (_this.used++ == 0) {
					var fn = _this.onfill;
					if (Callback.depth < 500) {
						Callback.depth++;
						fn();
						Callback.depth--;
					} else {
						Callback.defer(fn);
					};
				};
				return node;
			};
		};
	}
	arm() {
		var _gthis = this;
		this.link = this.wakeup(function (x) {
			_gthis.trigger(x);
		});
	}
	eager() {
		switch (this.status._hx_index) {
			case 0:
				this.status = FutureStatus.EagerlyAwaited;
				this.arm();
				break
			case 1:
				this.status = FutureStatus.EagerlyAwaited;
				break
			default:
			
		};
	}
	static get __name__() {
		return "tink.core._Future.SuspendableFuture"
	}
	static get __super__() {
		return FutureObject
	}
	get __class__() {
		return SuspendableFuture
	}
}

