import {Option} from "../haxe/ds/Option.js"
import {Register} from "../genes/Register.js"
import {Lambda} from "../Lambda.js"

const $global = Register.$global

export const VectorMigrationTools = Register.global("$hxClasses")["dasloop.VectorMigrationTools"] = 
class VectorMigrationTools {
	static first(v, f) {
		if (f == null) {
			if (v.length > 0) {
				return Option.Some(v[0]);
			} else {
				return Option.None;
			};
		};
		var _g = Lambda.find(v, f);
		if (_g == null) {
			return Option.None;
		} else {
			return Option.Some(_g);
		};
	}
	static get __name__() {
		return "dasloop.VectorMigrationTools"
	}
	get __class__() {
		return VectorMigrationTools
	}
}

