import {Outcome} from "../../../tink/core/Outcome.js"
import {TypedError} from "../../../tink/core/Error.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const TaskResult = 
Register.global("$hxEnums")["dasloop.types.task.TaskResult"] = 
{
	__ename__: "dasloop.types.task.TaskResult",
	
	UpdateFirmware: Object.assign((result) => ({_hx_index: 0, __enum__: "dasloop.types.task.TaskResult", "result": result}), {_hx_name: "UpdateFirmware", __params__: ["result"]}),
	UpdateAudio: Object.assign((result) => ({_hx_index: 1, __enum__: "dasloop.types.task.TaskResult", "result": result}), {_hx_name: "UpdateAudio", __params__: ["result"]}),
	GetConfig: Object.assign((result) => ({_hx_index: 2, __enum__: "dasloop.types.task.TaskResult", "result": result}), {_hx_name: "GetConfig", __params__: ["result"]}),
	GetHardwareReport: Object.assign((result) => ({_hx_index: 3, __enum__: "dasloop.types.task.TaskResult", "result": result}), {_hx_name: "GetHardwareReport", __params__: ["result"]}),
	UpdateConfig: Object.assign((result) => ({_hx_index: 4, __enum__: "dasloop.types.task.TaskResult", "result": result}), {_hx_name: "UpdateConfig", __params__: ["result"]}),
	PlayAudio: Object.assign((result) => ({_hx_index: 5, __enum__: "dasloop.types.task.TaskResult", "result": result}), {_hx_name: "PlayAudio", __params__: ["result"]}),
	GenerateAlarm: Object.assign((result) => ({_hx_index: 6, __enum__: "dasloop.types.task.TaskResult", "result": result}), {_hx_name: "GenerateAlarm", __params__: ["result"]}),
	LocateDevice: Object.assign((result) => ({_hx_index: 7, __enum__: "dasloop.types.task.TaskResult", "result": result}), {_hx_name: "LocateDevice", __params__: ["result"]}),
	GetAudio: Object.assign((result) => ({_hx_index: 8, __enum__: "dasloop.types.task.TaskResult", "result": result}), {_hx_name: "GetAudio", __params__: ["result"]})
}
TaskResult.__constructs__ = [TaskResult.UpdateFirmware, TaskResult.UpdateAudio, TaskResult.GetConfig, TaskResult.GetHardwareReport, TaskResult.UpdateConfig, TaskResult.PlayAudio, TaskResult.GenerateAlarm, TaskResult.LocateDevice, TaskResult.GetAudio]
TaskResult.__empty_constructs__ = []

export const TaskResultTools = Register.global("$hxClasses")["dasloop.types.task.TaskResultTools"] = 
class TaskResultTools {
	static toOutcome(result) {
		switch (result._hx_index) {
			case 0:
				var _g = result.result.error;
				if (_g != null) {
					return Outcome.Failure(new TypedError(null, _g, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/dasloop-types/0.0.0/git/02a6ec86579e2d19b4682cdd0da6ae12d74e26e2/src/dasloop/types/task/TaskResult.hx", "lineNumber": 75, "className": "dasloop.types.task.TaskResultTools", "methodName": "toOutcome"}));
				} else {
					return Outcome.Success(null);
				};
				break
			case 1:
				var _g = result.result.error;
				if (_g != null) {
					return Outcome.Failure(new TypedError(null, _g, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/dasloop-types/0.0.0/git/02a6ec86579e2d19b4682cdd0da6ae12d74e26e2/src/dasloop/types/task/TaskResult.hx", "lineNumber": 76, "className": "dasloop.types.task.TaskResultTools", "methodName": "toOutcome"}));
				} else {
					return Outcome.Success(null);
				};
				break
			case 5:
				var _g = result.result.result;
				if (_g == null) {
					return Outcome.Success(null);
				} else if (_g == false) {
					return Outcome.Failure(new TypedError(null, "Failed", {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/dasloop-types/0.0.0/git/02a6ec86579e2d19b4682cdd0da6ae12d74e26e2/src/dasloop/types/task/TaskResult.hx", "lineNumber": 77, "className": "dasloop.types.task.TaskResultTools", "methodName": "toOutcome"}));
				} else {
					return Outcome.Success(null);
				};
				break
			case 7:
				var _g = result.result.result;
				if (_g == null) {
					return Outcome.Success(null);
				} else if (_g == false) {
					return Outcome.Failure(new TypedError(null, "Failed", {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/dasloop-types/0.0.0/git/02a6ec86579e2d19b4682cdd0da6ae12d74e26e2/src/dasloop/types/task/TaskResult.hx", "lineNumber": 78, "className": "dasloop.types.task.TaskResultTools", "methodName": "toOutcome"}));
				} else {
					return Outcome.Success(null);
				};
				break
			default:
			return Outcome.Success(null);
			
		};
	}
	static get __name__() {
		return "dasloop.types.task.TaskResultTools"
	}
	get __class__() {
		return TaskResultTools
	}
}

