import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {ConstObservable, Observable} from "../../../tink/state/Observable.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Promise as Promise__1} from "../../../tink/core/Promise.js"
import {Outcome} from "../../../tink/core/Outcome.js"
import {LazyConst} from "../../../tink/core/Lazy.js"
import {SyncFuture} from "../../../tink/core/Future.js"
import {Register} from "../../../genes/Register.js"
import {Updatable} from "../../../coconut/ds/Updatable.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const UserData = Register.global("$hxClasses")["dasloop.app.data.UserData"] = 
class UserData extends Register.inherits() {
	new(__coco_init) {
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.async(function () {
			return _gthis.getProfile();
		}), null);
		AutoObservable.cur = before;
		this.__coco_profile = ret;
		this.remote = __coco_init.remote;
		this.getProfile = __coco_init.getProfile;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new Updatable({"loader": function () {
			return _gthis.remote.v2().me().get();
		}, "updater": function (current, patch) {
			var _g = patch.value;
			var v = {"name": _g, "id": current.id, "email": current.email, "admin": current.admin};
			return Promise__1.next(_gthis.remote.v2().me().setName(_g), function (_) {
				return new SyncFuture(new LazyConst(Outcome.Success(v)));
			});
		}});
		AutoObservable.cur = before;
		this.data = ret;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"remote": new ConstObservable(this.remote), "getProfile": new ConstObservable(this.getProfile), "profile": this.__coco_profile, "data": new ConstObservable(this.data), "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	static get __name__() {
		return "dasloop.app.data.UserData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return UserData
	}
}


export const UserPatch = 
Register.global("$hxEnums")["dasloop.app.data.UserPatch"] = 
{
	__ename__: "dasloop.app.data.UserPatch",
	
	Name: Object.assign((value) => ({_hx_index: 0, __enum__: "dasloop.app.data.UserPatch", "value": value}), {_hx_name: "Name", __params__: ["value"]})
}
UserPatch.__constructs__ = [UserPatch.Name]
UserPatch.__empty_constructs__ = []
