import {BasicParser, JsonString} from "./Parser.js"
import {MPair} from "../core/Pair.js"
import {TypedError} from "../core/Error.js"
import {StringMap} from "../../haxe/ds/StringMap.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Parser57 = Register.global("$hxClasses")["tink.json.Parser57"] = 
class Parser57 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	pb(source) {
		if (this.e.length > 0) {
			this.e = [];
		};
		this.g(source);
		this.F("[");
		this.F("[");
		var __ret = [];
		if (!this.E("]")) {
			while (true) {
				__ret.push(new Date(JsonString.f(this.r())));
				if (!this.E(",")) {
					break;
				};
			};
			this.F("]");
		};
		var _e0 = __ret;
		this.F(",");
		this.F("[");
		var __ret = new StringMap();
		if (!this.E("]")) {
			while (true) {
				this.F("[");
				var _e01 = JsonString.s(this.m());
				this.F(",");
				this.F("[");
				var __ret1 = [];
				if (!this.E("]")) {
					while (true) {
						__ret1.push((this.E("null")) ? null : JsonString.f(this.r()));
						if (!this.E(",")) {
							break;
						};
					};
					this.F("]");
				};
				__ret.inst.set(_e01, __ret1);
				this.F("]");
				if (!this.E(",")) {
					break;
				};
			};
			this.F("]");
		};
		var __ret1 = new MPair(_e0, __ret);
		this.F("]");
		var _g = 0;
		var _g1 = this.e;
		while (_g < _g1.length) _g1[_g++]();
		if (this.e.length > 0) {
			this.e = [];
		};
		return __ret1;
	}
	pc(source) {
		var _gthis = this;
		return TypedError.catchExceptions(function () {
			var ret = _gthis.pb(source);
			_gthis.h();
			if (_gthis.c < _gthis.d) {
				_gthis.D("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser57", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser57"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser57
	}
}

