import {Register} from "./genes/Register.js"
import {Declarations} from "./cix/css/Declarations.js"

const $global = Register.$global

export const Cix8a7a536db507182d422b08be93b69a2740cbb68222ab3d0141dae7690c5cc619 = Register.global("$hxClasses")["Cix8a7a536db507182d422b08be93b69a2740cbb68222ab3d0141dae7690c5cc619"] = 
class Cix8a7a536db507182d422b08be93b69a2740cbb68222ab3d0141dae7690c5cc619 extends Register.inherits() {
	new() {
		this.css = Declarations.add("cx6c", function () {
			return ".cx6c {\n  display: flex;\n  justify-content: center;\n}\n\n.cx6c .datetime {\n  flex-shrink: 0;\n}\n\n.cx6c .separator {\n  text-align: center;\n  flex-basis: 3rem;\n  flex-shrink: 1;\n}";
		});
	}
	static get __name__() {
		return "Cix8a7a536db507182d422b08be93b69a2740cbb68222ab3d0141dae7690c5cc619"
	}
	get __class__() {
		return Cix8a7a536db507182d422b08be93b69a2740cbb68222ab3d0141dae7690c5cc619
	}
}


Cix8a7a536db507182d422b08be93b69a2740cbb68222ab3d0141dae7690c5cc619.inst = new Cix8a7a536db507182d422b08be93b69a2740cbb68222ab3d0141dae7690c5cc619()