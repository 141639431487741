import {BasicParser, JsonString} from "./Parser.js"
import {OutcomeTools} from "../core/Outcome.js"
import {TypedError} from "../core/Error.js"
import {Register} from "../../genes/Register.js"
import {UwbTagId} from "../../dasloop/types/UwbTagId.js"
import {DeviceId} from "../../dasloop/types/DeviceId.js"

const $global = Register.$global

export const Parser1 = Register.global("$hxClasses")["tink.json.Parser1"] = 
class Parser1 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	pb() {
		var _gthis = this;
		var v_device = null;
		var hasv_device = false;
		var v_id = null;
		var hasv_id = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "device":
						v_device = (this.E("null")) ? null : OutcomeTools.sure(DeviceId.parse(JsonString.s(this.m())));
						hasv_device = true;
						break
					case "id":
						v_id = OutcomeTools.sure(UwbTagId.make(JsonString.s(this.m())));
						hasv_id = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"device": (hasv_device) ? v_device : __missing__("device"), "id": (hasv_id) ? v_id : __missing__("id")};
	}
	pc(source) {
		if (this.e.length > 0) {
			this.e = [];
		};
		this.g(source);
		var ret = this.pb();
		var _g = 0;
		var _g1 = this.e;
		while (_g < _g1.length) _g1[_g++]();
		if (this.e.length > 0) {
			this.e = [];
		};
		return ret;
	}
	pd(source) {
		var _gthis = this;
		return TypedError.catchExceptions(function () {
			var ret = _gthis.pc(source);
			_gthis.h();
			if (_gthis.c < _gthis.d) {
				_gthis.D("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser1", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser1"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser1
	}
}

