import {Message} from "./Message.js"
import {Header, HeaderField, HeaderValue} from "./Header.js"
import {Url} from "../Url.js"
import {Option} from "../../haxe/ds/Option.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const RequestHeader = Register.global("$hxClasses")["tink.http.RequestHeader"] = 
class RequestHeader extends Register.inherits(Header) {
	new(method, url, protocol, fields) {
		if (protocol == null) {
			protocol = "HTTP/1.1";
		};
		this.method = method;
		this.url = url;
		this.protocol = protocol;
		super.new(fields);
	}
	static get __name__() {
		return "tink.http.RequestHeader"
	}
	static get __super__() {
		return Header
	}
	get __class__() {
		return RequestHeader
	}
}


export const IncomingRequestHeader = Register.global("$hxClasses")["tink.http.IncomingRequestHeader"] = 
class IncomingRequestHeader extends Register.inherits(RequestHeader) {
	new(method, url, protocol, fields) {
		super.new(method, url, protocol, fields);
	}
	static get __name__() {
		return "tink.http.IncomingRequestHeader"
	}
	static get __super__() {
		return RequestHeader
	}
	get __class__() {
		return IncomingRequestHeader
	}
}


export const OutgoingRequestHeader = Register.global("$hxClasses")["tink.http.OutgoingRequestHeader"] = 
class OutgoingRequestHeader extends Register.inherits(RequestHeader) {
	new(method, url, protocol, fields) {
		if (protocol == null) {
			protocol = "HTTP/1.1";
		};
		var _g = OutgoingRequestHeader.extractAuth(url);
		if (_g._hx_index == 0) {
			var _g1 = _g.v;
			url = _g1.url;
			fields = fields.concat(_g1.headers);
		};
		super.new(method, url, protocol, fields);
	}
	concat(fields) {
		return new OutgoingRequestHeader(this.method, this.url, this.protocol, this.fields.concat(fields));
	}
	static extractAuth(url) {
		var _g = url.auth;
		if (_g == null) {
			return Option.None;
		} else {
			var tmp = [new HeaderField("authorization", HeaderValue.basicAuth((_g == null) ? null : _g.split(":")[0], (_g == null) ? null : _g.split(":")[1]))];
			var url1 = url.scheme;
			var _g = [];
			var _g1 = 0;
			var _g2 = url.hosts;
			while (_g1 < _g2.length) _g.push(_g2[_g1++]);
			return Option.Some({"headers": tmp, "url": Url.make({"scheme": url1, "hosts": _g, "path": url.path, "query": url.query})});
		};
	}
	static get __name__() {
		return "tink.http.OutgoingRequestHeader"
	}
	static get __super__() {
		return RequestHeader
	}
	get __class__() {
		return OutgoingRequestHeader
	}
}


export const OutgoingRequest = Register.global("$hxClasses")["tink.http.OutgoingRequest"] = 
class OutgoingRequest extends Register.inherits(Message) {
	new(header, body) {
		super.new(header, body);
	}
	static get __name__() {
		return "tink.http.OutgoingRequest"
	}
	static get __super__() {
		return Message
	}
	get __class__() {
		return OutgoingRequest
	}
}


export const IncomingRequest = Register.global("$hxClasses")["tink.http.IncomingRequest"] = 
class IncomingRequest extends Register.inherits(Message) {
	new(clientIp, header, body) {
		this.clientIp = clientIp;
		super.new(header, body);
	}
	static get __name__() {
		return "tink.http.IncomingRequest"
	}
	static get __super__() {
		return Message
	}
	get __class__() {
		return IncomingRequest
	}
}


export const IncomingRequestBody = 
Register.global("$hxEnums")["tink.http.IncomingRequestBody"] = 
{
	__ename__: "tink.http.IncomingRequestBody",
	
	Plain: Object.assign((source) => ({_hx_index: 0, __enum__: "tink.http.IncomingRequestBody", "source": source}), {_hx_name: "Plain", __params__: ["source"]}),
	Parsed: Object.assign((parts) => ({_hx_index: 1, __enum__: "tink.http.IncomingRequestBody", "parts": parts}), {_hx_name: "Parsed", __params__: ["parts"]})
}
IncomingRequestBody.__constructs__ = [IncomingRequestBody.Plain, IncomingRequestBody.Parsed]
IncomingRequestBody.__empty_constructs__ = []
