import {BasicWriter} from "./Writer.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Writer53 = Register.global("$hxClasses")["tink.json.Writer53"] = 
class Writer53 extends Register.inherits(BasicWriter) {
	new() {
		super.new();
	}
	wb(value) {
		this.f("{");
		var value1 = value._177;
		this.e("\"area\":");
		var value2 = value1;
		this.f("[");
		var first = true;
		var _g = 0;
		while (_g < value2.length) {
			var value1 = value2[_g++];
			if (first) {
				first = false;
			} else {
				this.f(",");
			};
			var value3 = value1;
			this.f("[");
			var first1 = true;
			var _g1 = 0;
			while (_g1 < value3.length) {
				var value4 = value3[_g1++];
				if (first1) {
					first1 = false;
				} else {
					this.f(",");
				};
				var value5 = value4;
				this.f("[");
				var first2 = true;
				var _g2 = 0;
				while (_g2 < value5.length) {
					var value6 = value5[_g2++];
					if (first2) {
						first2 = false;
					} else {
						this.f(",");
					};
					var value7 = value6;
					this.f("[");
					var first3 = true;
					var _g3 = 0;
					while (_g3 < value7.length) {
						var value8 = value7[_g3++];
						if (first3) {
							first3 = false;
						} else {
							this.f(",");
						};
						this.h(value8);
					};
					this.f("]");
				};
				this.f("]");
			};
			this.f("]");
		};
		this.f("]");
		var value1 = value._178;
		this.f(",");
		this.e("\"elevation\":");
		if (value1 == null) {
			this.e("null");
		} else {
			this.h(value1);
		};
		var value1 = value._179;
		this.f(",");
		this.e("\"height\":");
		if (value1 == null) {
			this.e("null");
		} else {
			this.h(value1);
		};
		var value1 = value._180;
		this.f(",");
		this.e("\"name\":");
		this.j(value1);
		var value1 = value._181;
		this.f(",");
		this.e("\"type\":");
		this.j(value1);
		this.f("}");
	}
	wc(value) {
		this.d();
		this.wb(value);
		return this.b.toString();
	}
	static get __name__() {
		return "tink.json.Writer53"
	}
	static get __super__() {
		return BasicWriter
	}
	get __class__() {
		return Writer53
	}
}

