import {Writer32} from "./Writer32.js"
import {BasicParser, JsonString} from "./Parser.js"
import {MPair} from "../core/Pair.js"
import {TypedError} from "../core/Error.js"
import {ByteChunk} from "../chunk/ByteChunk.js"
import {Option} from "../../haxe/ds/Option.js"
import {Base64} from "../../haxe/crypto/Base64.js"
import {Exception} from "../../haxe/Exception.js"
import {Register} from "../../genes/Register.js"
import {TaskStatus} from "../../dasloop/types/task/TaskStatus.js"
import {TaskResult} from "../../dasloop/types/task/TaskResult.js"
import {TaskDefinition} from "../../dasloop/types/task/TaskDefinition.js"
import {TaskAction, GenerateAlarmAction, GetAudioAction, GetConfigAction, GetHardwareReportAction, LocateDeviceAction, PlayAudioAction, UpdateAudioAction, UpdateConfigAction, UpdateFirmwareAction} from "../../dasloop/types/task/TaskAction.js"
import {EntryValueJson, EntryName, JsonValue, JsonType} from "../../dasloop/types/firmware/config/Schema.js"
import {Std} from "../../Std.js"

const $global = Register.$global

export const Parser47 = Register.global("$hxClasses")["tink.json.Parser47"] = 
class Parser47 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	pb() {
		var _gthis = this;
		var v_action = null;
		var hasv_action = false;
		var v_createDate = null;
		var hasv_createDate = false;
		var v_definition = null;
		var hasv_definition = false;
		var v_device = null;
		var hasv_device = false;
		var v_force = false;
		var hasv_force = false;
		var v_group = null;
		var hasv_group = false;
		var v_id = null;
		var hasv_id = false;
		var v_progress = null;
		var hasv_progress = false;
		var v_status = null;
		var hasv_status = false;
		var v_updateDate = null;
		var hasv_updateDate = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "action":
						v_action = Option.Some(this.pc());
						hasv_action = true;
						break
					case "createDate":
						v_createDate = new Date(JsonString.f(this.r()));
						hasv_createDate = true;
						break
					case "definition":
						v_definition = this.pK();
						hasv_definition = true;
						break
					case "device":
						v_device = JsonString.s(this.m());
						hasv_device = true;
						break
					case "force":
						v_force = this.G();
						hasv_force = true;
						break
					case "group":
						v_group = JsonString.s(this.m());
						hasv_group = true;
						break
					case "id":
						v_id = JsonString.s(this.m());
						hasv_id = true;
						break
					case "progress":
						this.F("[");
						var _e0 = JsonString.f(this.r());
						this.F(",");
						var this1 = new MPair(_e0, this.pkb());
						this.F("]");
						v_progress = this1;
						hasv_progress = true;
						break
					case "status":
						v_status = this.pnb();
						hasv_status = true;
						break
					case "updateDate":
						v_updateDate = new Date(JsonString.f(this.r()));
						hasv_updateDate = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		var tmp = (hasv_createDate) ? v_createDate : __missing__("createDate");
		var tmp1 = (hasv_definition) ? v_definition : __missing__("definition");
		var tmp2 = (hasv_device) ? v_device : __missing__("device");
		var tmp3 = (hasv_force) ? v_force : __missing__("force");
		var tmp4 = (hasv_group) ? v_group : __missing__("group");
		var tmp5 = (hasv_id) ? v_id : __missing__("id");
		var tmp6 = (hasv_progress) ? v_progress : __missing__("progress");
		var tmp7 = (hasv_status) ? v_status : __missing__("status");
		var tmp8 = (hasv_updateDate) ? v_updateDate : __missing__("updateDate");
		return {"action": (hasv_action) ? v_action : Option.None, "createDate": tmp, "definition": tmp1, "device": tmp2, "force": tmp3, "group": tmp4, "id": tmp5, "progress": tmp6, "status": tmp7, "updateDate": tmp8};
	}
	pc() {
		var __ret = this.pd();
		var _g = __ret.GenerateAlarm;
		var _g1 = __ret.GetAudio;
		var _g2 = __ret.GetConfig;
		var _g3 = __ret.GetHardwareReport;
		var _g4 = __ret.LocateDevice;
		var _g5 = __ret.PlayAudio;
		var _g6 = __ret.UpdateAudio;
		var _g7 = __ret.UpdateConfig;
		var _g8 = __ret.UpdateFirmware;
		if (_g8 != null) {
			return TaskAction.UpdateFirmware(_g8.action);
		} else if (_g6 != null) {
			return TaskAction.UpdateAudio(_g6.action);
		} else if (_g2 != null) {
			return TaskAction.GetConfig(_g2.action);
		} else if (_g3 != null) {
			return TaskAction.GetHardwareReport(_g3.action);
		} else if (_g7 != null) {
			return TaskAction.UpdateConfig(_g7.action);
		} else if (_g4 != null) {
			return TaskAction.LocateDevice(_g4.action);
		} else if (_g5 != null) {
			return TaskAction.PlayAudio(_g5.action);
		} else if (_g != null) {
			return TaskAction.GenerateAlarm(_g.action);
		} else if (_g1 != null) {
			return TaskAction.GetAudio(_g1.action);
		} else {
			throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser47", "methodName": "process1"}));
		};
	}
	pd() {
		var _gthis = this;
		var v_GenerateAlarm = null;
		var v_GetAudio = null;
		var v_GetConfig = null;
		var v_GetHardwareReport = null;
		var v_LocateDevice = null;
		var v_PlayAudio = null;
		var v_UpdateAudio = null;
		var v_UpdateConfig = null;
		var v_UpdateFirmware = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "GenerateAlarm":
						v_GenerateAlarm = (this.E("null")) ? null : this.pe();
						break
					case "GetAudio":
						v_GetAudio = (this.E("null")) ? null : this.ph();
						break
					case "GetConfig":
						v_GetConfig = (this.E("null")) ? null : this.pl();
						break
					case "GetHardwareReport":
						v_GetHardwareReport = (this.E("null")) ? null : this.po();
						break
					case "LocateDevice":
						v_LocateDevice = (this.E("null")) ? null : this.pq();
						break
					case "PlayAudio":
						v_PlayAudio = (this.E("null")) ? null : this.pv();
						break
					case "UpdateAudio":
						v_UpdateAudio = (this.E("null")) ? null : this.px();
						break
					case "UpdateConfig":
						v_UpdateConfig = (this.E("null")) ? null : this.pB();
						break
					case "UpdateFirmware":
						v_UpdateFirmware = (this.E("null")) ? null : this.pG();
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {"GenerateAlarm": v_GenerateAlarm, "GetAudio": v_GetAudio, "GetConfig": v_GetConfig, "GetHardwareReport": v_GetHardwareReport, "LocateDevice": v_LocateDevice, "PlayAudio": v_PlayAudio, "UpdateAudio": v_UpdateAudio, "UpdateConfig": v_UpdateConfig, "UpdateFirmware": v_UpdateFirmware};
	}
	pe() {
		var _gthis = this;
		var v_action = null;
		var hasv_action = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "action") {
					v_action = this.pf();
					hasv_action = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"action": (hasv_action) ? v_action : __missing__("action")};
	}
	pf() {
		if (this.E("\"")) {
			var _g = JsonString.s(this.n());
			if (_g == "GenerateAlarm") {
				return GenerateAlarmAction.GenerateAlarm;
			} else {
				throw Exception.thrown(new TypedError(422, "Invalid constructor " + _g, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 443, "className": "tink.json.Parser47", "methodName": "process4"}));
			};
		} else {
			throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(this.pg()), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser47", "methodName": "process4"}));
		};
	}
	pg() {
		var _gthis = this;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				JsonString.s(this.m());
				this.F(":");
				this.B();
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {};
	}
	ph() {
		var _gthis = this;
		var v_action = null;
		var hasv_action = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "action") {
					v_action = this.pi();
					hasv_action = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"action": (hasv_action) ? v_action : __missing__("action")};
	}
	pi() {
		if (this.E("\"")) {
			var _g = JsonString.s(this.n());
			switch (_g) {
				case "SetEngineeringMode":
					return GetAudioAction.SetEngineeringMode;
					break
				case "UnsetEngineeringMode":
					return GetAudioAction.UnsetEngineeringMode;
					break
				default:
				throw Exception.thrown(new TypedError(422, "Invalid constructor " + _g, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 443, "className": "tink.json.Parser47", "methodName": "process7"}));
				
			};
		} else {
			var __ret = this.pj();
			var _g = __ret.GetAudio;
			if (_g != null) {
				return GetAudioAction.GetAudio(_g.offset, _g.length);
			} else {
				throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser47", "methodName": "process7"}));
			};
		};
	}
	pj() {
		var _gthis = this;
		var v_GetAudio = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "GetAudio") {
					v_GetAudio = (this.E("null")) ? null : this.pk();
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {"GetAudio": v_GetAudio};
	}
	pk() {
		var _gthis = this;
		var v_length = 0;
		var hasv_length = false;
		var v_offset = 0;
		var hasv_offset = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "length":
						v_length = JsonString.i(this.r());
						hasv_length = true;
						break
					case "offset":
						v_offset = JsonString.i(this.r());
						hasv_offset = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"length": (hasv_length) ? v_length : __missing__("length"), "offset": (hasv_offset) ? v_offset : __missing__("offset")};
	}
	pl() {
		var _gthis = this;
		var v_action = null;
		var hasv_action = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "action") {
					v_action = this.pm();
					hasv_action = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"action": (hasv_action) ? v_action : __missing__("action")};
	}
	pm() {
		if (this.E("\"")) {
			var _g = JsonString.s(this.n());
			switch (_g) {
				case "GetConfigSize":
					return GetConfigAction.GetConfigSize;
					break
				case "GetFirmwareVersion":
					return GetConfigAction.GetFirmwareVersion;
					break
				default:
				throw Exception.thrown(new TypedError(422, "Invalid constructor " + _g, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 443, "className": "tink.json.Parser47", "methodName": "process11"}));
				
			};
		} else {
			var __ret = this.pn();
			var _g = __ret.GetConfig;
			if (_g != null) {
				return GetConfigAction.GetConfig(_g.offset, _g.length);
			} else {
				throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser47", "methodName": "process11"}));
			};
		};
	}
	pn() {
		var _gthis = this;
		var v_GetConfig = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "GetConfig") {
					v_GetConfig = (this.E("null")) ? null : this.pk();
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {"GetConfig": v_GetConfig};
	}
	po() {
		var _gthis = this;
		var v_action = null;
		var hasv_action = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "action") {
					v_action = this.pp();
					hasv_action = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"action": (hasv_action) ? v_action : __missing__("action")};
	}
	pp() {
		if (this.E("\"")) {
			var _g = JsonString.s(this.n());
			if (_g == "GetHardwareReport") {
				return GetHardwareReportAction.GetHardwareReport;
			} else {
				throw Exception.thrown(new TypedError(422, "Invalid constructor " + _g, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 443, "className": "tink.json.Parser47", "methodName": "process14"}));
			};
		} else {
			throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(this.pg()), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser47", "methodName": "process14"}));
		};
	}
	pq() {
		var _gthis = this;
		var v_action = null;
		var hasv_action = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "action") {
					v_action = this.pr();
					hasv_action = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"action": (hasv_action) ? v_action : __missing__("action")};
	}
	pr() {
		if (this.E("\"")) {
			var _g = JsonString.s(this.n());
			switch (_g) {
				case "SetBuzzer":
					return LocateDeviceAction.SetBuzzer;
					break
				case "SetEngineeringMode":
					return LocateDeviceAction.SetEngineeringMode;
					break
				case "SetLed":
					return LocateDeviceAction.SetLed;
					break
				case "SetVibrator":
					return LocateDeviceAction.SetVibrator;
					break
				case "UnsetEngineeringMode":
					return LocateDeviceAction.UnsetEngineeringMode;
					break
				default:
				throw Exception.thrown(new TypedError(422, "Invalid constructor " + _g, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 443, "className": "tink.json.Parser47", "methodName": "process16"}));
				
			};
		} else {
			var __ret = this.ps();
			var _g = __ret.Recover;
			var _g1 = __ret.SetUwbAlarm;
			if (_g1 != null) {
				return LocateDeviceAction.SetUwbAlarm(_g1.light, _g1.sound);
			} else if (_g != null) {
				return LocateDeviceAction.Recover(_g.next);
			} else {
				throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser47", "methodName": "process16"}));
			};
		};
	}
	ps() {
		var _gthis = this;
		var v_Recover = null;
		var v_SetUwbAlarm = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "Recover":
						v_Recover = (this.E("null")) ? null : this.pt();
						break
					case "SetUwbAlarm":
						v_SetUwbAlarm = (this.E("null")) ? null : this.pu();
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {"Recover": v_Recover, "SetUwbAlarm": v_SetUwbAlarm};
	}
	pt() {
		var _gthis = this;
		var v_next = null;
		var hasv_next = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "next") {
					v_next = this.pr();
					hasv_next = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"next": (hasv_next) ? v_next : __missing__("next")};
	}
	pu() {
		var _gthis = this;
		var v_light = false;
		var hasv_light = false;
		var v_sound = false;
		var hasv_sound = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "light":
						v_light = this.G();
						hasv_light = true;
						break
					case "sound":
						v_sound = this.G();
						hasv_sound = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"light": (hasv_light) ? v_light : __missing__("light"), "sound": (hasv_sound) ? v_sound : __missing__("sound")};
	}
	pv() {
		var _gthis = this;
		var v_action = null;
		var hasv_action = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "action") {
					v_action = this.pw();
					hasv_action = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"action": (hasv_action) ? v_action : __missing__("action")};
	}
	pw() {
		if (this.E("\"")) {
			var _g = JsonString.s(this.n());
			if (_g == "PlayAudio") {
				return PlayAudioAction.PlayAudio;
			} else {
				throw Exception.thrown(new TypedError(422, "Invalid constructor " + _g, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 443, "className": "tink.json.Parser47", "methodName": "process21"}));
			};
		} else {
			throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(this.pg()), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser47", "methodName": "process21"}));
		};
	}
	px() {
		var _gthis = this;
		var v_action = null;
		var hasv_action = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "action") {
					v_action = this.py();
					hasv_action = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"action": (hasv_action) ? v_action : __missing__("action")};
	}
	py() {
		if (this.E("\"")) {
			var _g = JsonString.s(this.n());
			switch (_g) {
				case "GetDeviceInfo":
					return UpdateAudioAction.GetDeviceInfo;
					break
				case "PatchChecksum":
					return UpdateAudioAction.PatchChecksum;
					break
				case "RequestReset":
					return UpdateAudioAction.RequestReset;
					break
				case "SetEngineeringMode":
					return UpdateAudioAction.SetEngineeringMode;
					break
				default:
				throw Exception.thrown(new TypedError(422, "Invalid constructor " + _g, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 443, "className": "tink.json.Parser47", "methodName": "process23"}));
				
			};
		} else {
			var __ret = this.pz();
			var _g = __ret.Pause;
			var _g1 = __ret.Recover;
			var _g2 = __ret.SendAudio;
			var _g3 = __ret.WaitForRecover;
			if (_g2 != null) {
				return UpdateAudioAction.SendAudio(_g2.offset, _g2.length);
			} else if (_g1 != null) {
				return UpdateAudioAction.Recover(_g1.next);
			} else if (_g != null) {
				return UpdateAudioAction.Pause(_g.next);
			} else if (_g3 != null) {
				return UpdateAudioAction.WaitForRecover(_g3.next);
			} else {
				throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser47", "methodName": "process23"}));
			};
		};
	}
	pz() {
		var _gthis = this;
		var v_Pause = null;
		var v_Recover = null;
		var v_SendAudio = null;
		var v_WaitForRecover = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "Pause":
						v_Pause = (this.E("null")) ? null : this.pA();
						break
					case "Recover":
						v_Recover = (this.E("null")) ? null : this.pA();
						break
					case "SendAudio":
						v_SendAudio = (this.E("null")) ? null : this.pk();
						break
					case "WaitForRecover":
						v_WaitForRecover = (this.E("null")) ? null : this.pA();
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {"Pause": v_Pause, "Recover": v_Recover, "SendAudio": v_SendAudio, "WaitForRecover": v_WaitForRecover};
	}
	pA() {
		var _gthis = this;
		var v_next = null;
		var hasv_next = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "next") {
					v_next = this.py();
					hasv_next = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"next": (hasv_next) ? v_next : __missing__("next")};
	}
	pB() {
		var _gthis = this;
		var v_action = null;
		var hasv_action = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "action") {
					v_action = this.pC();
					hasv_action = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"action": (hasv_action) ? v_action : __missing__("action")};
	}
	pC() {
		if (this.E("\"")) {
			var _g = JsonString.s(this.n());
			switch (_g) {
				case "GetFirmwareVersion":
					return UpdateConfigAction.GetFirmwareVersion;
					break
				case "RequestReset":
					return UpdateConfigAction.RequestReset;
					break
				case "SetEngineeringMode":
					return UpdateConfigAction.SetEngineeringMode;
					break
				default:
				throw Exception.thrown(new TypedError(422, "Invalid constructor " + _g, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 443, "className": "tink.json.Parser47", "methodName": "process27"}));
				
			};
		} else {
			var __ret = this.pD();
			var _g = __ret.Recover;
			var _g1 = __ret.UpdateConfig;
			if (_g1 != null) {
				return UpdateConfigAction.UpdateConfig(_g1.index);
			} else if (_g != null) {
				return UpdateConfigAction.Recover(_g.next);
			} else {
				throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser47", "methodName": "process27"}));
			};
		};
	}
	pD() {
		var _gthis = this;
		var v_Recover = null;
		var v_UpdateConfig = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "Recover":
						v_Recover = (this.E("null")) ? null : this.pE();
						break
					case "UpdateConfig":
						v_UpdateConfig = (this.E("null")) ? null : this.pF();
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {"Recover": v_Recover, "UpdateConfig": v_UpdateConfig};
	}
	pE() {
		var _gthis = this;
		var v_next = null;
		var hasv_next = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "next") {
					v_next = this.pC();
					hasv_next = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"next": (hasv_next) ? v_next : __missing__("next")};
	}
	pF() {
		var _gthis = this;
		var v_index = 0;
		var hasv_index = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "index") {
					v_index = JsonString.i(this.r());
					hasv_index = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"index": (hasv_index) ? v_index : __missing__("index")};
	}
	pG() {
		var _gthis = this;
		var v_action = null;
		var hasv_action = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "action") {
					v_action = this.pH();
					hasv_action = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"action": (hasv_action) ? v_action : __missing__("action")};
	}
	pH() {
		if (this.E("\"")) {
			var _g = JsonString.s(this.n());
			switch (_g) {
				case "CheckFirmwareVersion":
					return UpdateFirmwareAction.CheckFirmwareVersion;
					break
				case "GetConfig":
					return UpdateFirmwareAction.GetConfig;
					break
				case "GetDeviceInfo":
					return UpdateFirmwareAction.GetDeviceInfo;
					break
				case "PredefineConfig":
					return UpdateFirmwareAction.PredefineConfig;
					break
				case "RequestUpdateFirmware":
					return UpdateFirmwareAction.RequestUpdateFirmware;
					break
				case "SetEngineeringMode":
					return UpdateFirmwareAction.SetEngineeringMode;
					break
				default:
				throw Exception.thrown(new TypedError(422, "Invalid constructor " + _g, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 443, "className": "tink.json.Parser47", "methodName": "process32"}));
				
			};
		} else {
			var __ret = this.pI();
			var _g = __ret.Pause;
			var _g1 = __ret.Recover;
			var _g2 = __ret.SendFirmware;
			var _g3 = __ret.WaitForRecover;
			if (_g2 != null) {
				return UpdateFirmwareAction.SendFirmware(_g2.offset, _g2.length);
			} else if (_g1 != null) {
				return UpdateFirmwareAction.Recover(_g1.next);
			} else if (_g != null) {
				return UpdateFirmwareAction.Pause(_g.next);
			} else if (_g3 != null) {
				return UpdateFirmwareAction.WaitForRecover(_g3.next);
			} else {
				throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser47", "methodName": "process32"}));
			};
		};
	}
	pI() {
		var _gthis = this;
		var v_Pause = null;
		var v_Recover = null;
		var v_SendFirmware = null;
		var v_WaitForRecover = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "Pause":
						v_Pause = (this.E("null")) ? null : this.pJ();
						break
					case "Recover":
						v_Recover = (this.E("null")) ? null : this.pJ();
						break
					case "SendFirmware":
						v_SendFirmware = (this.E("null")) ? null : this.pk();
						break
					case "WaitForRecover":
						v_WaitForRecover = (this.E("null")) ? null : this.pJ();
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {"Pause": v_Pause, "Recover": v_Recover, "SendFirmware": v_SendFirmware, "WaitForRecover": v_WaitForRecover};
	}
	pJ() {
		var _gthis = this;
		var v_next = null;
		var hasv_next = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "next") {
					v_next = this.pH();
					hasv_next = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"next": (hasv_next) ? v_next : __missing__("next")};
	}
	pK() {
		if (this.E("\"")) {
			var _g = JsonString.s(this.n());
			switch (_g) {
				case "GetAudio":
					return TaskDefinition.GetAudio;
					break
				case "GetConfig":
					return TaskDefinition.GetConfig;
					break
				case "GetHardwareReport":
					return TaskDefinition.GetHardwareReport;
					break
				default:
				throw Exception.thrown(new TypedError(422, "Invalid constructor " + _g, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 443, "className": "tink.json.Parser47", "methodName": "process35"}));
				
			};
		} else {
			var __ret = this.pL();
			var _g = __ret.GenerateAlarm;
			var _g1 = __ret.LocateDevice;
			var _g2 = __ret.PlayAudio;
			var _g3 = __ret.UpdateAudio;
			var _g4 = __ret.UpdateConfig;
			var _g5 = __ret.UpdateFirmware;
			if (_g5 != null) {
				return TaskDefinition.UpdateFirmware(_g5.params);
			} else if (_g3 != null) {
				return TaskDefinition.UpdateAudio(_g3.params);
			} else if (_g4 != null) {
				return TaskDefinition.UpdateConfig(_g4.params);
			} else if (_g1 != null) {
				return TaskDefinition.LocateDevice(_g1.params);
			} else if (_g2 != null) {
				return TaskDefinition.PlayAudio(_g2.params);
			} else if (_g != null) {
				return TaskDefinition.GenerateAlarm(_g.params);
			} else {
				throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser47", "methodName": "process35"}));
			};
		};
	}
	pL() {
		var _gthis = this;
		var v_GenerateAlarm = null;
		var v_LocateDevice = null;
		var v_PlayAudio = null;
		var v_UpdateAudio = null;
		var v_UpdateConfig = null;
		var v_UpdateFirmware = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "GenerateAlarm":
						v_GenerateAlarm = (this.E("null")) ? null : this.pM();
						break
					case "LocateDevice":
						v_LocateDevice = (this.E("null")) ? null : this.pO();
						break
					case "PlayAudio":
						v_PlayAudio = (this.E("null")) ? null : this.pQ();
						break
					case "UpdateAudio":
						v_UpdateAudio = (this.E("null")) ? null : this.pS();
						break
					case "UpdateConfig":
						v_UpdateConfig = (this.E("null")) ? null : this.pU();
						break
					case "UpdateFirmware":
						v_UpdateFirmware = (this.E("null")) ? null : this.pib();
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {"GenerateAlarm": v_GenerateAlarm, "LocateDevice": v_LocateDevice, "PlayAudio": v_PlayAudio, "UpdateAudio": v_UpdateAudio, "UpdateConfig": v_UpdateConfig, "UpdateFirmware": v_UpdateFirmware};
	}
	pM() {
		var _gthis = this;
		var v_params = null;
		var hasv_params = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "params") {
					v_params = this.pN();
					hasv_params = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"params": (hasv_params) ? v_params : __missing__("params")};
	}
	pN() {
		var _gthis = this;
		var v_duration = 0;
		var hasv_duration = false;
		var v_light = false;
		var hasv_light = false;
		var v_sound = false;
		var hasv_sound = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "duration":
						v_duration = JsonString.i(this.r());
						hasv_duration = true;
						break
					case "light":
						v_light = this.G();
						hasv_light = true;
						break
					case "sound":
						v_sound = this.G();
						hasv_sound = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"duration": (hasv_duration) ? v_duration : __missing__("duration"), "light": (hasv_light) ? v_light : __missing__("light"), "sound": (hasv_sound) ? v_sound : __missing__("sound")};
	}
	pO() {
		var _gthis = this;
		var v_params = null;
		var hasv_params = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "params") {
					v_params = this.pP();
					hasv_params = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"params": (hasv_params) ? v_params : __missing__("params")};
	}
	pP() {
		var _gthis = this;
		var v_buzzer = false;
		var hasv_buzzer = false;
		var v_led = false;
		var hasv_led = false;
		var v_vibrator = false;
		var hasv_vibrator = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "buzzer":
						v_buzzer = this.G();
						hasv_buzzer = true;
						break
					case "led":
						v_led = this.G();
						hasv_led = true;
						break
					case "vibrator":
						v_vibrator = this.G();
						hasv_vibrator = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"buzzer": (hasv_buzzer) ? v_buzzer : __missing__("buzzer"), "led": (hasv_led) ? v_led : __missing__("led"), "vibrator": (hasv_vibrator) ? v_vibrator : __missing__("vibrator")};
	}
	pQ() {
		var _gthis = this;
		var v_params = null;
		var hasv_params = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "params") {
					v_params = this.pR();
					hasv_params = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"params": (hasv_params) ? v_params : __missing__("params")};
	}
	pR() {
		var _gthis = this;
		var v_delay = 0;
		var hasv_delay = false;
		var v_id = 0;
		var hasv_id = false;
		var v_repeats = 0;
		var hasv_repeats = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "delay":
						v_delay = JsonString.i(this.r());
						hasv_delay = true;
						break
					case "id":
						v_id = JsonString.i(this.r());
						hasv_id = true;
						break
					case "repeats":
						v_repeats = JsonString.i(this.r());
						hasv_repeats = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"delay": (hasv_delay) ? v_delay : __missing__("delay"), "id": (hasv_id) ? v_id : __missing__("id"), "repeats": (hasv_repeats) ? v_repeats : __missing__("repeats")};
	}
	pS() {
		var _gthis = this;
		var v_params = null;
		var hasv_params = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "params") {
					v_params = this.pT();
					hasv_params = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"params": (hasv_params) ? v_params : __missing__("params")};
	}
	pT() {
		var _gthis = this;
		var v_tracks = null;
		var hasv_tracks = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "tracks") {
					this.F("[");
					var __ret = [];
					if (!this.E("]")) {
						while (true) {
							__ret.push(JsonString.i(this.r()));
							if (!this.E(",")) {
								break;
							};
						};
						this.F("]");
					};
					v_tracks = __ret;
					hasv_tracks = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"tracks": (hasv_tracks) ? v_tracks : __missing__("tracks")};
	}
	pU() {
		var _gthis = this;
		var v_params = null;
		var hasv_params = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "params") {
					v_params = this.pV();
					hasv_params = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"params": (hasv_params) ? v_params : __missing__("params")};
	}
	pV() {
		var _gthis = this;
		var v_entries = null;
		var hasv_entries = false;
		var v_reset = false;
		var hasv_reset = false;
		var v_schemaVersion = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "entries":
						this.F("[");
						var __ret = [];
						if (!this.E("]")) {
							while (true) {
								__ret.push(this.pW());
								if (!this.E(",")) {
									break;
								};
							};
							this.F("]");
						};
						v_entries = __ret;
						hasv_entries = true;
						break
					case "reset":
						v_reset = this.G();
						hasv_reset = true;
						break
					case "schemaVersion":
						if (this.E("null")) {
							v_schemaVersion = null;
						} else {
							var v = JsonString.s(this.m());
							switch (v) {
								case "0004.0R2":case "0005.0R0":case "0005.0R2":case "0006.0R5":case "0006.0R7":case "0006.0R8":case "0007.0R0":case "0007.0R2":case "0007.0R5":case "0007.0R6":case "0008.0R0":case "0008.0R4":case "0008.0R6":case "0008.0R9":case "0009.0R0":case "0009.0R5":
									v_schemaVersion = v;
									break
								default:
								throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer32().wb(["0004.0R2", "0005.0R0", "0005.0R2", "0006.0R5", "0006.0R7", "0006.0R8", "0007.0R0", "0007.0R2", "0007.0R5", "0007.0R6", "0008.0R0", "0008.0R4", "0008.0R6", "0008.0R9", "0009.0R0", "0009.0R5"]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 469, "className": "tink.json.Parser47", "methodName": "process46"}));
								
							};
						};
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"entries": (hasv_entries) ? v_entries : __missing__("entries"), "reset": (hasv_reset) ? v_reset : __missing__("reset"), "schemaVersion": v_schemaVersion};
	}
	pW() {
		var _gthis = this;
		var v_name = null;
		var hasv_name = false;
		var v_value = null;
		var hasv_value = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "name":
						v_name = this.pX();
						hasv_name = true;
						break
					case "value":
						v_value = EntryValueJson.unwrap(this.pY());
						hasv_value = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"name": (hasv_name) ? v_name : __missing__("name"), "value": (hasv_value) ? v_value : __missing__("value")};
	}
	pX() {
		if (this.E("\"")) {
			var _g = JsonString.s(this.n());
			switch (_g) {
				case "AccelerometerLogFrequency":
					return EntryName.AccelerometerLogFrequency;
					break
				case "AgpsFeatures":
					return EntryName.AgpsFeatures;
					break
				case "Apn":
					return EntryName.Apn;
					break
				case "ApnPassword":
					return EntryName.ApnPassword;
					break
				case "ApnUserName":
					return EntryName.ApnUserName;
					break
				case "BatteryDetectInterval":
					return EntryName.BatteryDetectInterval;
					break
				case "BatteryVoltageOffset":
					return EntryName.BatteryVoltageOffset;
					break
				case "BeaconOfMaintenanceArea":
					return EntryName.BeaconOfMaintenanceArea;
					break
				case "BeaconOfRecessArea":
					return EntryName.BeaconOfRecessArea;
					break
				case "BeaconOfSleepArea":
					return EntryName.BeaconOfSleepArea;
					break
				case "BeaconSignalOffset":
					return EntryName.BeaconSignalOffset;
					break
				case "BeaconsScanPeriod":
					return EntryName.BeaconsScanPeriod;
					break
				case "BeaconsScanTimeout":
					return EntryName.BeaconsScanTimeout;
					break
				case "BleAdvertiseInterval":
					return EntryName.BleAdvertiseInterval;
					break
				case "BleAoaPacketInterval":
					return EntryName.BleAoaPacketInterval;
					break
				case "BleAoaPacketTxPower":
					return EntryName.BleAoaPacketTxPower;
					break
				case "BleFlags":
					return EntryName.BleFlags;
					break
				case "BodyTemperatureFilterTime":
					return EntryName.BodyTemperatureFilterTime;
					break
				case "BodyTemperatureHighFilterTime":
					return EntryName.BodyTemperatureHighFilterTime;
					break
				case "BodyTemperatureHighThreshold":
					return EntryName.BodyTemperatureHighThreshold;
					break
				case "BodyTemperatureLowFilterTime":
					return EntryName.BodyTemperatureLowFilterTime;
					break
				case "BodyTemperatureLowThreshold":
					return EntryName.BodyTemperatureLowThreshold;
					break
				case "BodyTemperatureReadPeriod":
					return EntryName.BodyTemperatureReadPeriod;
					break
				case "BuzzerFrequency":
					return EntryName.BuzzerFrequency;
					break
				case "BuzzerVolumeLevel":
					return EntryName.BuzzerVolumeLevel;
					break
				case "ChargingMaintenanceTimeout":
					return EntryName.ChargingMaintenanceTimeout;
					break
				case "CommSignalLogPeriod":
					return EntryName.CommSignalLogPeriod;
					break
				case "CommunicationBand":
					return EntryName.CommunicationBand;
					break
				case "CommunicationFlags":
					return EntryName.CommunicationFlags;
					break
				case "CommunicationGuardInterval":
					return EntryName.CommunicationGuardInterval;
					break
				case "DetectPutOffByHrDelay":
					return EntryName.DetectPutOffByHrDelay;
					break
				case "DeviceLocked":
					return EntryName.DeviceLocked;
					break
				case "FreeFallDetectDuration":
					return EntryName.FreeFallDetectDuration;
					break
				case "FreeFallDetectThreshold":
					return EntryName.FreeFallDetectThreshold;
					break
				case "GeneralReportPeriod":
					return EntryName.GeneralReportPeriod;
					break
				case "GeneralReportPeriodSleepArea":
					return EntryName.GeneralReportPeriodSleepArea;
					break
				case "Geofence":
					return EntryName.Geofence;
					break
				case "GpsFixCheckPeriod":
					return EntryName.GpsFixCheckPeriod;
					break
				case "GpsFixCheckPeriodSleepArea":
					return EntryName.GpsFixCheckPeriodSleepArea;
					break
				case "GpsFlags":
					return EntryName.GpsFlags;
					break
				case "GpsNoFixOffDuration":
					return EntryName.GpsNoFixOffDuration;
					break
				case "GpsNoFixOffDurationSleepArea":
					return EntryName.GpsNoFixOffDurationSleepArea;
					break
				case "GpsOnDuration":
					return EntryName.GpsOnDuration;
					break
				case "GpsOnDurationSleepArea":
					return EntryName.GpsOnDurationSleepArea;
					break
				case "HardwareVersion":
					return EntryName.HardwareVersion;
					break
				case "HeartRateHighFilterTime":
					return EntryName.HeartRateHighFilterTime;
					break
				case "HeartRateHighThreshold":
					return EntryName.HeartRateHighThreshold;
					break
				case "HeartRateLowFilterTime":
					return EntryName.HeartRateLowFilterTime;
					break
				case "HeartRateLowThreshold":
					return EntryName.HeartRateLowThreshold;
					break
				case "HeartRateQualityThreshold":
					return EntryName.HeartRateQualityThreshold;
					break
				case "HeartRateReadPeriod":
					return EntryName.HeartRateReadPeriod;
					break
				case "HeatStressAlertThreshold":
					return EntryName.HeatStressAlertThreshold;
					break
				case "HsiTrimmingValue":
					return EntryName.HsiTrimmingValue;
					break
				case "HumidityOffset":
					return EntryName.HumidityOffset;
					break
				case "HumidityReadPeriod":
					return EntryName.HumidityReadPeriod;
					break
				case "LedBrightless":
					return EntryName.LedBrightless;
					break
				case "LoraAppEui":
					return EntryName.LoraAppEui;
					break
				case "LoraAppKey":
					return EntryName.LoraAppKey;
					break
				case "LoraCommunicationBand":
					return EntryName.LoraCommunicationBand;
					break
				case "LoraDataRateTxPower":
					return EntryName.LoraDataRateTxPower;
					break
				case "LoraNetworkWaitTime":
					return EntryName.LoraNetworkWaitTime;
					break
				case "LoraPingSlot":
					return EntryName.LoraPingSlot;
					break
				case "LoraServerWaitTime":
					return EntryName.LoraServerWaitTime;
					break
				case "LowBatteryThresholdHi":
					return EntryName.LowBatteryThresholdHi;
					break
				case "LowBatteryThresholdLo":
					return EntryName.LowBatteryThresholdLo;
					break
				case "LowBatteryThresholdOffFanLed":
					return EntryName.LowBatteryThresholdOffFanLed;
					break
				case "MsiTrimmingValue":
					return EntryName.MsiTrimmingValue;
					break
				case "NbIotEdrxValue":
					return EntryName.NbIotEdrxValue;
					break
				case "NbIotMinimumSignal":
					return EntryName.NbIotMinimumSignal;
					break
				case "NbIotNetworkWaitTime":
					return EntryName.NbIotNetworkWaitTime;
					break
				case "NbIotPsmRequestedActiveTime":
					return EntryName.NbIotPsmRequestedActiveTime;
					break
				case "NbIotPsmRequestedPeriodicTau":
					return EntryName.NbIotPsmRequestedPeriodicTau;
					break
				case "NbIotServerWaitTime":
					return EntryName.NbIotServerWaitTime;
					break
				case "NetworkReportPeriod":
					return EntryName.NetworkReportPeriod;
					break
				case "NetworkReportPeriodSleepArea":
					return EntryName.NetworkReportPeriodSleepArea;
					break
				case "OperationFlags":
					return EntryName.OperationFlags;
					break
				case "OperationFlags2":
					return EntryName.OperationFlags2;
					break
				case "PositionLogPeriod":
					return EntryName.PositionLogPeriod;
					break
				case "PressureOffset":
					return EntryName.PressureOffset;
					break
				case "PressureReadPeriod":
					return EntryName.PressureReadPeriod;
					break
				case "PutOffBuzzerAlertInterval":
					return EntryName.PutOffBuzzerAlertInterval;
					break
				case "ReservedSection1":
					return EntryName.ReservedSection1;
					break
				case "ReservedSection2":
					return EntryName.ReservedSection2;
					break
				case "ReservedSection3":
					return EntryName.ReservedSection3;
					break
				case "ReservedSection3_2":
					return EntryName.ReservedSection3_2;
					break
				case "ReservedSection4":
					return EntryName.ReservedSection4;
					break
				case "ReservedSection5":
					return EntryName.ReservedSection5;
					break
				case "ReservedSection5_2":
					return EntryName.ReservedSection5_2;
					break
				case "SecurityKey":
					return EntryName.SecurityKey;
					break
				case "SensorLocalAlertInterval":
					return EntryName.SensorLocalAlertInterval;
					break
				case "SensorRemoteAlertInterval":
					return EntryName.SensorRemoteAlertInterval;
					break
				case "SensorsLogPeriod":
					return EntryName.SensorsLogPeriod;
					break
				case "SerialNumber":
					return EntryName.SerialNumber;
					break
				case "ServerDomainName":
					return EntryName.ServerDomainName;
					break
				case "ServerDomainNamePort":
					return EntryName.ServerDomainNamePort;
					break
				case "ServerIpAddress":
					return EntryName.ServerIpAddress;
					break
				case "ServerPort":
					return EntryName.ServerPort;
					break
				case "ShortReportPeriod":
					return EntryName.ShortReportPeriod;
					break
				case "ShortReportPeriodSleepArea":
					return EntryName.ShortReportPeriodSleepArea;
					break
				case "StationaryAlertInterval":
					return EntryName.StationaryAlertInterval;
					break
				case "StationaryDetectThreshold":
					return EntryName.StationaryDetectThreshold;
					break
				case "StationaryLocalAlertInterval":
					return EntryName.StationaryLocalAlertInterval;
					break
				case "StationaryOffInterval":
					return EntryName.StationaryOffInterval;
					break
				case "TokenActivePeriod":
					return EntryName.TokenActivePeriod;
					break
				case "TokensForDisablingStationaryAlerts":
					return EntryName.TokensForDisablingStationaryAlerts;
					break
				case "TokensRecoverTime":
					return EntryName.TokensRecoverTime;
					break
				case "WakeupDetectThreshold":
					return EntryName.WakeupDetectThreshold;
					break
				case "WiFiApPassword":
					return EntryName.WiFiApPassword;
					break
				case "WiFiApSsid":
					return EntryName.WiFiApSsid;
					break
				case "WiFiConnectApPeriod":
					return EntryName.WiFiConnectApPeriod;
					break
				case "WiFiConnectApTimeout":
					return EntryName.WiFiConnectApTimeout;
					break
				case "WiFiServerDomainName":
					return EntryName.WiFiServerDomainName;
					break
				case "WiFiServerDomainNamePort":
					return EntryName.WiFiServerDomainNamePort;
					break
				case "WiFiServerIpAddress":
					return EntryName.WiFiServerIpAddress;
					break
				case "WiFiServerPort":
					return EntryName.WiFiServerPort;
					break
				case "WiFiServerWaitTime":
					return EntryName.WiFiServerWaitTime;
					break
				case "WorkaroundBenchmarkCheckInterval":
					return EntryName.WorkaroundBenchmarkCheckInterval;
					break
				case "WorkaroundBenchmarkFalseLimit":
					return EntryName.WorkaroundBenchmarkFalseLimit;
					break
				case "WorkaroundBenchmarkHRThres":
					return EntryName.WorkaroundBenchmarkHRThres;
					break
				case "WorkaroundBenchmarkQualityThres":
					return EntryName.WorkaroundBenchmarkQualityThres;
					break
				case "WorkaroundBenchmarkRecordCount":
					return EntryName.WorkaroundBenchmarkRecordCount;
					break
				default:
				throw Exception.thrown(new TypedError(422, "Invalid constructor " + _g, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 443, "className": "tink.json.Parser47", "methodName": "process48"}));
				
			};
		} else {
			throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(this.pg()), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser47", "methodName": "process48"}));
		};
	}
	pY() {
		var __ret = this.pZ();
		var _g = __ret.JBinary;
		var _g1 = __ret.JInteger;
		var _g2 = __ret.JSequence;
		var _g3 = __ret.JText;
		if (_g1 != null) {
			return JsonValue.JInteger(_g1.type, _g1.v);
		} else if (_g3 != null) {
			return JsonValue.JText(_g3.type, _g3.v);
		} else if (_g != null) {
			return JsonValue.JBinary(_g.type, _g.v);
		} else if (_g2 != null) {
			return JsonValue.JSequence(_g2.type, _g2.size, _g2.v);
		} else {
			throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser47", "methodName": "process49"}));
		};
	}
	pZ() {
		var _gthis = this;
		var v_JBinary = null;
		var v_JInteger = null;
		var v_JSequence = null;
		var v_JText = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "JBinary":
						v_JBinary = (this.E("null")) ? null : this.pab();
						break
					case "JInteger":
						v_JInteger = (this.E("null")) ? null : this.pfb();
						break
					case "JSequence":
						v_JSequence = (this.E("null")) ? null : this.pgb();
						break
					case "JText":
						v_JText = (this.E("null")) ? null : this.phb();
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {"JBinary": v_JBinary, "JInteger": v_JInteger, "JSequence": v_JSequence, "JText": v_JText};
	}
	pab() {
		var _gthis = this;
		var v_type = null;
		var hasv_type = false;
		var v_v = null;
		var hasv_v = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "type":
						v_type = this.pbb();
						hasv_type = true;
						break
					case "v":
						v_v = ByteChunk.of(Base64.decode(JsonString.s(this.m())));
						hasv_v = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"type": (hasv_type) ? v_type : __missing__("type"), "v": (hasv_v) ? v_v : __missing__("v")};
	}
	pbb() {
		if (this.E("\"")) {
			var _g = JsonString.s(this.n());
			switch (_g) {
				case "JInt16":
					return JsonType.JInt16;
					break
				case "JInt8":
					return JsonType.JInt8;
					break
				case "JUInt16":
					return JsonType.JUInt16;
					break
				case "JUInt8":
					return JsonType.JUInt8;
					break
				default:
				throw Exception.thrown(new TypedError(422, "Invalid constructor " + _g, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 443, "className": "tink.json.Parser47", "methodName": "process52"}));
				
			};
		} else {
			var __ret = this.pcb();
			var _g = __ret.JArray;
			var _g1 = __ret.JByte;
			var _g2 = __ret.JChar;
			if (_g2 != null) {
				return JsonType.JChar(_g2.size);
			} else if (_g1 != null) {
				return JsonType.JByte(_g1.size);
			} else if (_g != null) {
				return JsonType.JArray(_g.type, _g.size);
			} else {
				throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser47", "methodName": "process52"}));
			};
		};
	}
	pcb() {
		var _gthis = this;
		var v_JArray = null;
		var v_JByte = null;
		var v_JChar = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "JArray":
						v_JArray = (this.E("null")) ? null : this.pdb();
						break
					case "JByte":
						v_JByte = (this.E("null")) ? null : this.peb();
						break
					case "JChar":
						v_JChar = (this.E("null")) ? null : this.peb();
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {"JArray": v_JArray, "JByte": v_JByte, "JChar": v_JChar};
	}
	pdb() {
		var _gthis = this;
		var v_size = 0;
		var hasv_size = false;
		var v_type = null;
		var hasv_type = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "size":
						v_size = JsonString.i(this.r());
						hasv_size = true;
						break
					case "type":
						v_type = this.pbb();
						hasv_type = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"size": (hasv_size) ? v_size : __missing__("size"), "type": (hasv_type) ? v_type : __missing__("type")};
	}
	peb() {
		var _gthis = this;
		var v_size = 0;
		var hasv_size = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "size") {
					v_size = JsonString.i(this.r());
					hasv_size = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"size": (hasv_size) ? v_size : __missing__("size")};
	}
	pfb() {
		var _gthis = this;
		var v_type = null;
		var hasv_type = false;
		var v_v = 0;
		var hasv_v = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "type":
						v_type = this.pbb();
						hasv_type = true;
						break
					case "v":
						v_v = JsonString.i(this.r());
						hasv_v = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"type": (hasv_type) ? v_type : __missing__("type"), "v": (hasv_v) ? v_v : __missing__("v")};
	}
	pgb() {
		var _gthis = this;
		var v_size = 0;
		var hasv_size = false;
		var v_type = null;
		var hasv_type = false;
		var v_v = null;
		var hasv_v = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "size":
						v_size = JsonString.i(this.r());
						hasv_size = true;
						break
					case "type":
						v_type = this.pbb();
						hasv_type = true;
						break
					case "v":
						this.F("[");
						var __ret = [];
						if (!this.E("]")) {
							while (true) {
								__ret.push(this.pY());
								if (!this.E(",")) {
									break;
								};
							};
							this.F("]");
						};
						v_v = __ret;
						hasv_v = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"size": (hasv_size) ? v_size : __missing__("size"), "type": (hasv_type) ? v_type : __missing__("type"), "v": (hasv_v) ? v_v : __missing__("v")};
	}
	phb() {
		var _gthis = this;
		var v_type = null;
		var hasv_type = false;
		var v_v = null;
		var hasv_v = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "type":
						v_type = this.pbb();
						hasv_type = true;
						break
					case "v":
						v_v = JsonString.s(this.m());
						hasv_v = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"type": (hasv_type) ? v_type : __missing__("type"), "v": (hasv_v) ? v_v : __missing__("v")};
	}
	pib() {
		var _gthis = this;
		var v_params = null;
		var hasv_params = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "params") {
					v_params = this.pjb();
					hasv_params = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"params": (hasv_params) ? v_params : __missing__("params")};
	}
	pjb() {
		var _gthis = this;
		var v_firmwareVersion = null;
		var hasv_firmwareVersion = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "firmwareVersion") {
					v_firmwareVersion = JsonString.s(this.m());
					hasv_firmwareVersion = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"firmwareVersion": (hasv_firmwareVersion) ? v_firmwareVersion : __missing__("firmwareVersion")};
	}
	pkb() {
		if (this.E("\"")) {
			var _g = JsonString.s(this.n());
			if (_g == "None") {
				return Option.None;
			} else {
				throw Exception.thrown(new TypedError(422, "Invalid constructor " + _g, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 443, "className": "tink.json.Parser47", "methodName": "process61"}));
			};
		} else {
			var __ret = this.plb();
			var _g = __ret.Some;
			if (_g != null) {
				return Option.Some(_g.v);
			} else {
				throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser47", "methodName": "process61"}));
			};
		};
	}
	plb() {
		var _gthis = this;
		var v_Some = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "Some") {
					v_Some = (this.E("null")) ? null : this.pmb();
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {"Some": v_Some};
	}
	pmb() {
		var _gthis = this;
		var v_v = .0;
		var hasv_v = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "v") {
					v_v = JsonString.f(this.r());
					hasv_v = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"v": (hasv_v) ? v_v : __missing__("v")};
	}
	pnb() {
		if (this.E("\"")) {
			var _g = JsonString.s(this.n());
			if (_g == "Pending") {
				return TaskStatus.Pending;
			} else {
				throw Exception.thrown(new TypedError(422, "Invalid constructor " + _g, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 443, "className": "tink.json.Parser47", "methodName": "process64"}));
			};
		} else {
			var __ret = this.pob();
			var _g = __ret.Cancelled;
			var _g1 = __ret.Expired;
			var _g2 = __ret.Finished;
			if (_g2 != null) {
				return TaskStatus.Finished(_g2.date, _g2.result);
			} else if (_g != null) {
				return TaskStatus.Cancelled(_g.date);
			} else if (_g1 != null) {
				return TaskStatus.Expired(_g1.date);
			} else {
				throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser47", "methodName": "process64"}));
			};
		};
	}
	pob() {
		var _gthis = this;
		var v_Cancelled = null;
		var v_Expired = null;
		var v_Finished = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "Cancelled":
						v_Cancelled = (this.E("null")) ? null : this.ppb();
						break
					case "Expired":
						v_Expired = (this.E("null")) ? null : this.ppb();
						break
					case "Finished":
						v_Finished = (this.E("null")) ? null : this.pqb();
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {"Cancelled": v_Cancelled, "Expired": v_Expired, "Finished": v_Finished};
	}
	ppb() {
		var _gthis = this;
		var v_date = null;
		var hasv_date = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "date") {
					v_date = new Date(JsonString.f(this.r()));
					hasv_date = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"date": (hasv_date) ? v_date : __missing__("date")};
	}
	pqb() {
		var _gthis = this;
		var v_date = null;
		var hasv_date = false;
		var v_result = null;
		var hasv_result = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "date":
						v_date = new Date(JsonString.f(this.r()));
						hasv_date = true;
						break
					case "result":
						v_result = this.prb();
						hasv_result = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"date": (hasv_date) ? v_date : __missing__("date"), "result": (hasv_result) ? v_result : __missing__("result")};
	}
	prb() {
		var __ret = this.psb();
		var _g = __ret.GenerateAlarm;
		var _g1 = __ret.GetAudio;
		var _g2 = __ret.GetConfig;
		var _g3 = __ret.GetHardwareReport;
		var _g4 = __ret.LocateDevice;
		var _g5 = __ret.PlayAudio;
		var _g6 = __ret.UpdateAudio;
		var _g7 = __ret.UpdateConfig;
		var _g8 = __ret.UpdateFirmware;
		if (_g8 != null) {
			return TaskResult.UpdateFirmware(_g8.result);
		} else if (_g6 != null) {
			return TaskResult.UpdateAudio(_g6.result);
		} else if (_g2 != null) {
			return TaskResult.GetConfig(_g2.result);
		} else if (_g3 != null) {
			return TaskResult.GetHardwareReport(_g3.result);
		} else if (_g7 != null) {
			return TaskResult.UpdateConfig(_g7.result);
		} else if (_g5 != null) {
			return TaskResult.PlayAudio(_g5.result);
		} else if (_g != null) {
			return TaskResult.GenerateAlarm(_g.result);
		} else if (_g4 != null) {
			return TaskResult.LocateDevice(_g4.result);
		} else if (_g1 != null) {
			return TaskResult.GetAudio(_g1.result);
		} else {
			throw Exception.thrown(new TypedError(422, "Cannot process " + Std.string(__ret), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 432, "className": "tink.json.Parser47", "methodName": "process68"}));
		};
	}
	psb() {
		var _gthis = this;
		var v_GenerateAlarm = null;
		var v_GetAudio = null;
		var v_GetConfig = null;
		var v_GetHardwareReport = null;
		var v_LocateDevice = null;
		var v_PlayAudio = null;
		var v_UpdateAudio = null;
		var v_UpdateConfig = null;
		var v_UpdateFirmware = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "GenerateAlarm":
						v_GenerateAlarm = (this.E("null")) ? null : this.ptb();
						break
					case "GetAudio":
						v_GetAudio = (this.E("null")) ? null : this.pvb();
						break
					case "GetConfig":
						v_GetConfig = (this.E("null")) ? null : this.pyb();
						break
					case "GetHardwareReport":
						v_GetHardwareReport = (this.E("null")) ? null : this.pAb();
						break
					case "LocateDevice":
						v_LocateDevice = (this.E("null")) ? null : this.pDb();
						break
					case "PlayAudio":
						v_PlayAudio = (this.E("null")) ? null : this.pEb();
						break
					case "UpdateAudio":
						v_UpdateAudio = (this.E("null")) ? null : this.pFb();
						break
					case "UpdateConfig":
						v_UpdateConfig = (this.E("null")) ? null : this.pHb();
						break
					case "UpdateFirmware":
						v_UpdateFirmware = (this.E("null")) ? null : this.pIb();
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		return {"GenerateAlarm": v_GenerateAlarm, "GetAudio": v_GetAudio, "GetConfig": v_GetConfig, "GetHardwareReport": v_GetHardwareReport, "LocateDevice": v_LocateDevice, "PlayAudio": v_PlayAudio, "UpdateAudio": v_UpdateAudio, "UpdateConfig": v_UpdateConfig, "UpdateFirmware": v_UpdateFirmware};
	}
	ptb() {
		var _gthis = this;
		var v_result = null;
		var hasv_result = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "result") {
					v_result = this.pub();
					hasv_result = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"result": (hasv_result) ? v_result : __missing__("result")};
	}
	pub() {
		var _gthis = this;
		var v_result = false;
		var hasv_result = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "result") {
					v_result = (this.E("null")) ? null : this.G();
					hasv_result = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"result": (hasv_result) ? v_result : __missing__("result")};
	}
	pvb() {
		var _gthis = this;
		var v_result = null;
		var hasv_result = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "result") {
					v_result = this.pwb();
					hasv_result = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"result": (hasv_result) ? v_result : __missing__("result")};
	}
	pwb() {
		var _gthis = this;
		var v_summary = null;
		var hasv_summary = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "summary") {
					if (this.E("null")) {
						v_summary = null;
					} else {
						this.F("[");
						var __ret = [];
						if (!this.E("]")) {
							while (true) {
								__ret.push(this.pxb());
								if (!this.E(",")) {
									break;
								};
							};
							this.F("]");
						};
						v_summary = __ret;
					};
					hasv_summary = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"summary": (hasv_summary) ? v_summary : __missing__("summary")};
	}
	pxb() {
		var _gthis = this;
		var v_id = 0;
		var hasv_id = false;
		var v_offset = 0;
		var hasv_offset = false;
		var v_size = 0;
		var hasv_size = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "id":
						v_id = JsonString.i(this.r());
						hasv_id = true;
						break
					case "offset":
						v_offset = JsonString.i(this.r());
						hasv_offset = true;
						break
					case "size":
						v_size = JsonString.i(this.r());
						hasv_size = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"id": (hasv_id) ? v_id : __missing__("id"), "offset": (hasv_offset) ? v_offset : __missing__("offset"), "size": (hasv_size) ? v_size : __missing__("size")};
	}
	pyb() {
		var _gthis = this;
		var v_result = null;
		var hasv_result = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "result") {
					v_result = this.pzb();
					hasv_result = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"result": (hasv_result) ? v_result : __missing__("result")};
	}
	pzb() {
		var _gthis = this;
		var v_content = null;
		var hasv_content = false;
		var v_firmwareVersion = null;
		var hasv_firmwareVersion = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "content":
						v_content = ByteChunk.of(Base64.decode(JsonString.s(this.m())));
						hasv_content = true;
						break
					case "firmwareVersion":
						v_firmwareVersion = (this.E("null")) ? null : JsonString.s(this.m());
						hasv_firmwareVersion = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"content": (hasv_content) ? v_content : __missing__("content"), "firmwareVersion": (hasv_firmwareVersion) ? v_firmwareVersion : __missing__("firmwareVersion")};
	}
	pAb() {
		var _gthis = this;
		var v_result = null;
		var hasv_result = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "result") {
					v_result = this.pBb();
					hasv_result = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"result": (hasv_result) ? v_result : __missing__("result")};
	}
	pBb() {
		var _gthis = this;
		var v_hardwareReport = null;
		var hasv_hardwareReport = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "hardwareReport") {
					v_hardwareReport = (this.E("null")) ? null : this.pCb();
					hasv_hardwareReport = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"hardwareReport": (hasv_hardwareReport) ? v_hardwareReport : __missing__("hardwareReport")};
	}
	pCb() {
		var _gthis = this;
		var v_batteryCurrent = .0;
		var hasv_batteryCurrent = false;
		var v_batteryLevel = .0;
		var hasv_batteryLevel = false;
		var v_batteryVoltage = .0;
		var hasv_batteryVoltage = false;
		var v_bootCount = 0;
		var hasv_bootCount = false;
		var v_bootResultFlags = 0;
		var hasv_bootResultFlags = false;
		var v_chipId = null;
		var hasv_chipId = false;
		var v_chipRevId = null;
		var hasv_chipRevId = false;
		var v_chipUid = null;
		var hasv_chipUid = false;
		var v_communicationDeviceModel = null;
		var hasv_communicationDeviceModel = false;
		var v_communicationDeviceUid = null;
		var hasv_communicationDeviceUid = false;
		var v_hardwareVersion = null;
		var hasv_hardwareVersion = false;
		var v_modules = 0;
		var hasv_modules = false;
		var v_rawBatteryVoltage = .0;
		var hasv_rawBatteryVoltage = false;
		var v_sdcardSize = .0;
		var hasv_sdcardSize = false;
		var v_simId = null;
		var hasv_simId = false;
		var v_statusFlags = 0;
		var hasv_statusFlags = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "batteryCurrent":
						v_batteryCurrent = JsonString.f(this.r());
						hasv_batteryCurrent = true;
						break
					case "batteryLevel":
						v_batteryLevel = JsonString.f(this.r());
						hasv_batteryLevel = true;
						break
					case "batteryVoltage":
						v_batteryVoltage = JsonString.f(this.r());
						hasv_batteryVoltage = true;
						break
					case "bootCount":
						v_bootCount = JsonString.i(this.r());
						hasv_bootCount = true;
						break
					case "bootResultFlags":
						v_bootResultFlags = JsonString.i(this.r());
						hasv_bootResultFlags = true;
						break
					case "chipId":
						v_chipId = ByteChunk.of(Base64.decode(JsonString.s(this.m())));
						hasv_chipId = true;
						break
					case "chipRevId":
						v_chipRevId = ByteChunk.of(Base64.decode(JsonString.s(this.m())));
						hasv_chipRevId = true;
						break
					case "chipUid":
						v_chipUid = ByteChunk.of(Base64.decode(JsonString.s(this.m())));
						hasv_chipUid = true;
						break
					case "communicationDeviceModel":
						v_communicationDeviceModel = ByteChunk.of(Base64.decode(JsonString.s(this.m())));
						hasv_communicationDeviceModel = true;
						break
					case "communicationDeviceUid":
						v_communicationDeviceUid = ByteChunk.of(Base64.decode(JsonString.s(this.m())));
						hasv_communicationDeviceUid = true;
						break
					case "hardwareVersion":
						v_hardwareVersion = JsonString.s(this.m());
						hasv_hardwareVersion = true;
						break
					case "modules":
						v_modules = JsonString.i(this.r());
						hasv_modules = true;
						break
					case "rawBatteryVoltage":
						v_rawBatteryVoltage = JsonString.f(this.r());
						hasv_rawBatteryVoltage = true;
						break
					case "sdcardSize":
						v_sdcardSize = JsonString.f(this.r());
						hasv_sdcardSize = true;
						break
					case "simId":
						v_simId = ByteChunk.of(Base64.decode(JsonString.s(this.m())));
						hasv_simId = true;
						break
					case "statusFlags":
						v_statusFlags = JsonString.i(this.r());
						hasv_statusFlags = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"batteryCurrent": (hasv_batteryCurrent) ? v_batteryCurrent : __missing__("batteryCurrent"), "batteryLevel": (hasv_batteryLevel) ? v_batteryLevel : __missing__("batteryLevel"), "batteryVoltage": (hasv_batteryVoltage) ? v_batteryVoltage : __missing__("batteryVoltage"), "bootCount": (hasv_bootCount) ? v_bootCount : __missing__("bootCount"), "bootResultFlags": (hasv_bootResultFlags) ? v_bootResultFlags : __missing__("bootResultFlags"), "chipId": (hasv_chipId) ? v_chipId : __missing__("chipId"), "chipRevId": (hasv_chipRevId) ? v_chipRevId : __missing__("chipRevId"), "chipUid": (hasv_chipUid) ? v_chipUid : __missing__("chipUid"), "communicationDeviceModel": (hasv_communicationDeviceModel) ? v_communicationDeviceModel : __missing__("communicationDeviceModel"), "communicationDeviceUid": (hasv_communicationDeviceUid) ? v_communicationDeviceUid : __missing__("communicationDeviceUid"), "hardwareVersion": (hasv_hardwareVersion) ? v_hardwareVersion : __missing__("hardwareVersion"), "modules": (hasv_modules) ? v_modules : __missing__("modules"), "rawBatteryVoltage": (hasv_rawBatteryVoltage) ? v_rawBatteryVoltage : __missing__("rawBatteryVoltage"), "sdcardSize": (hasv_sdcardSize) ? v_sdcardSize : __missing__("sdcardSize"), "simId": (hasv_simId) ? v_simId : __missing__("simId"), "statusFlags": (hasv_statusFlags) ? v_statusFlags : __missing__("statusFlags")};
	}
	pDb() {
		var _gthis = this;
		var v_result = null;
		var hasv_result = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "result") {
					v_result = this.pub();
					hasv_result = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"result": (hasv_result) ? v_result : __missing__("result")};
	}
	pEb() {
		var _gthis = this;
		var v_result = null;
		var hasv_result = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "result") {
					v_result = this.pub();
					hasv_result = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"result": (hasv_result) ? v_result : __missing__("result")};
	}
	pFb() {
		var _gthis = this;
		var v_result = null;
		var hasv_result = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "result") {
					v_result = this.pGb();
					hasv_result = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"result": (hasv_result) ? v_result : __missing__("result")};
	}
	pGb() {
		var _gthis = this;
		var v_error = null;
		var hasv_error = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "error") {
					v_error = (this.E("null")) ? null : JsonString.s(this.m());
					hasv_error = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"error": (hasv_error) ? v_error : __missing__("error")};
	}
	pHb() {
		var _gthis = this;
		var v_result = null;
		var hasv_result = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "result") {
					v_result = this.pGb();
					hasv_result = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"result": (hasv_result) ? v_result : __missing__("result")};
	}
	pIb() {
		var _gthis = this;
		var v_result = null;
		var hasv_result = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				if (lhs == "result") {
					v_result = this.pGb();
					hasv_result = true;
				} else {
					this.B();
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"result": (hasv_result) ? v_result : __missing__("result")};
	}
	pJb(source) {
		if (this.e.length > 0) {
			this.e = [];
		};
		this.g(source);
		var ret = this.pb();
		var _g = 0;
		var _g1 = this.e;
		while (_g < _g1.length) _g1[_g++]();
		if (this.e.length > 0) {
			this.e = [];
		};
		return ret;
	}
	pKb(source) {
		var _gthis = this;
		return TypedError.catchExceptions(function () {
			var ret = _gthis.pJb(source);
			_gthis.h();
			if (_gthis.c < _gthis.d) {
				_gthis.D("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser47", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser47"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser47
	}
}

