import {Remote17} from "./Remote17.js"
import {RemoteBase, RemoteEndpoint, ResponseReader} from "./Remote.js"
import {Portion} from "../../url/Portion.js"
import {Single} from "../../streams/Stream.js"
import {Parser12} from "../../json/Parser12.js"
import {HeaderField, HeaderValue} from "../../http/Header.js"
import {NamedWith} from "../../core/Named.js"
import {LazyConst} from "../../core/Lazy.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote16 = Register.global("$hxClasses")["tink.web.proxy.Remote16"] = 
class Remote16 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	ofHash(hash) {
		return new Remote17(this.client, RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [new NamedWith("hash", Portion.ofString(hash))], "headers": [].concat([])}));
	}
	ofId(id) {
		return new Remote17(this.client, RemoteEndpoint.sub(this.endpoint, {"path": [Portion.ofString(id)], "query": [], "headers": [].concat([])}));
	}
	list() {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [], "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser12())));
	}
	static get __name__() {
		return "tink.web.proxy.Remote16"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote16
	}
}

