import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const DeviceUpdate = 
Register.global("$hxEnums")["dasloop.types.pubsub.DeviceUpdate"] = 
{
	__ename__: "dasloop.types.pubsub.DeviceUpdate",
	
	Location: Object.assign((location) => ({_hx_index: 0, __enum__: "dasloop.types.pubsub.DeviceUpdate", "location": location}), {_hx_name: "Location", __params__: ["location"]}),
	Alert: Object.assign((alert) => ({_hx_index: 1, __enum__: "dasloop.types.pubsub.DeviceUpdate", "alert": alert}), {_hx_name: "Alert", __params__: ["alert"]}),
	Reading: Object.assign((reading) => ({_hx_index: 2, __enum__: "dasloop.types.pubsub.DeviceUpdate", "reading": reading}), {_hx_name: "Reading", __params__: ["reading"]})
}
DeviceUpdate.__constructs__ = [DeviceUpdate.Location, DeviceUpdate.Alert, DeviceUpdate.Reading]
DeviceUpdate.__empty_constructs__ = []
