import {Promise as Promise__1} from "../tink/core/Promise.js"
import {Outcome} from "../tink/core/Outcome.js"
import {LazyConst} from "../tink/core/Lazy.js"
import {SyncFuture} from "../tink/core/Future.js"
import {StringMap} from "../haxe/ds/StringMap.js"
import {Register} from "../genes/Register.js"

const $global = Register.$global

export const Manager = Register.global("$hxClasses")["turnwing.Manager"] = 
class Manager extends Register.inherits() {
	new(provider) {
		this.provider = provider;
		this.locales = new StringMap();
	}
	get(language, forceRefresh) {
		if (forceRefresh == null) {
			forceRefresh = false;
		};
		var _gthis = this;
		if (forceRefresh || !this.locales.inst.has(language)) {
			return Promise__1.next(this.provider.prepare(language), function (locale) {
				_gthis.locales.inst.set(language, locale);
				return new SyncFuture(new LazyConst(Outcome.Success(locale)));
			});
		} else {
			return new SyncFuture(new LazyConst(Outcome.Success(this.locales.inst.get(language))));
		};
	}
	static get __name__() {
		return "turnwing.Manager"
	}
	get __class__() {
		return Manager
	}
}

