import {Portion} from "../url/Portion.js"
import {DefaultKeymaker} from "./Keymaker.js"
import {NamedWith} from "../core/Named.js"
import {Register} from "../../genes/Register.js"
import {Std} from "../../Std.js"

const $global = Register.$global

export const Builder7 = Register.global("$hxClasses")["tink.querystring.Builder7"] = 
class Builder7 extends Register.inherits() {
	new() {
	}
	stringify(data, keymaker) {
		if (keymaker == null) {
			keymaker = new DefaultKeymaker();
		};
		var buffer = [];
		this.process0("", buffer, keymaker, data);
		return buffer;
	}
	process0(prefix, buffer, keymaker, data) {
		var prefix1 = (prefix == "") ? "from" : keymaker.field(prefix, "from");
		var data1 = data._155;
		if (data1 != null) {
			var value = Std.string(data1.getTime());
			buffer.push(new NamedWith(Portion.ofString(prefix1), Portion.ofString(value)));
		};
		var prefix1 = (prefix == "") ? "limit" : keymaker.field(prefix, "limit");
		var data1 = data._156;
		if (data1 != null) {
			buffer.push(new NamedWith(Portion.ofString(prefix1), Portion.ofString((data1 == null) ? "null" : "" + data1)));
		};
		var prefix1 = (prefix == "") ? "to" : keymaker.field(prefix, "to");
		var data1 = data._157;
		if (data1 != null) {
			var value = Std.string(data1.getTime());
			buffer.push(new NamedWith(Portion.ofString(prefix1), Portion.ofString(value)));
		};
	}
	static get __name__() {
		return "tink.querystring.Builder7"
	}
	get __class__() {
		return Builder7
	}
}

