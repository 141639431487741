import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {Observable, ConstObservable} from "../../../tink/state/Observable.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Promise as Promise__1} from "../../../tink/core/Promise.js"
import {Outcome} from "../../../tink/core/Outcome.js"
import {LazyConst} from "../../../tink/core/Lazy.js"
import {SyncFuture} from "../../../tink/core/Future.js"
import {Option} from "../../../haxe/ds/Option.js"
import {Register} from "../../../genes/Register.js"
import {Config} from "../../util/Config.js"
import {Updatable} from "../../../coconut/ds/Updatable.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const AudioData = Register.global("$hxClasses")["dasloop.app.data.AudioData"] = 
class AudioData extends Register.inherits() {
	new(__coco_init) {
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return Observable.get_value(_gthis._data.__coco_data);
		}), null);
		AutoObservable.cur = before;
		this.__coco_data = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return "" + Config.API_SERVER_URL + "/audios/" + _gthis.id + "/wav";
		}), null);
		AutoObservable.cur = before;
		this.__coco_wavUrl = ret;
		this.project = __coco_init.project;
		this.id = __coco_init.id;
		this.remote = __coco_init.remote;
		var _g = __coco_init.initial;
		this.initial = (_g == null) ? Option.None : _g;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new Updatable({"loader": function () {
			return _gthis.remote.v2().projects().ofId(_gthis.project).audios().ofId(_gthis.id);
		}, "updater": function (current, patch) {
			switch (patch._hx_index) {
				case 0:
					return Promise__1.next(_gthis.remote.v2().projects().ofId(_gthis.project).audios().enable(_gthis.id), function (_) {
						var __o0 = current;
						return new SyncFuture(new LazyConst(Outcome.Success({"enabled": true, "name": __o0.name, "id": __o0.id, "duration": __o0.duration, "content": __o0.content})));
					});
					break
				case 1:
					return Promise__1.next(_gthis.remote.v2().projects().ofId(_gthis.project).audios().disable(_gthis.id), function (_) {
						var __o0 = current;
						return new SyncFuture(new LazyConst(Outcome.Success({"enabled": false, "name": __o0.name, "id": __o0.id, "duration": __o0.duration, "content": __o0.content})));
					});
					break
				
			};
		}, "cache": _gthis.initial});
		AutoObservable.cur = before;
		this._data = ret;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"project": new ConstObservable(this.project), "id": new ConstObservable(this.id), "remote": new ConstObservable(this.remote), "initial": new ConstObservable(this.initial), "_data": new ConstObservable(this._data), "wavUrl": this.__coco_wavUrl, "data": this.__coco_data, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	static get __name__() {
		return "dasloop.app.data.AudioData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return AudioData
	}
}


export const AudioPatch = 
Register.global("$hxEnums")["dasloop.app.data.AudioPatch"] = 
{
	__ename__: "dasloop.app.data.AudioPatch",
	
	Enable: {_hx_name: "Enable", _hx_index: 0, __enum__: "dasloop.app.data.AudioPatch"},
	Disable: {_hx_name: "Disable", _hx_index: 1, __enum__: "dasloop.app.data.AudioPatch"}
}
AudioPatch.__constructs__ = [AudioPatch.Enable, AudioPatch.Disable]
AudioPatch.__empty_constructs__ = [AudioPatch.Enable, AudioPatch.Disable]
