import {Path} from "./url/Path.js"
import {Host} from "./url/Host.js"
import {Register} from "../genes/Register.js"
import {StringTools} from "../StringTools.js"
import {Std} from "../Std.js"
import {Reflect as Reflect__1} from "../Reflect.js"
import {HxOverrides} from "../HxOverrides.js"
import {EReg} from "../EReg.js"

const $global = Register.$global

export const Url = Register.global("$hxClasses")["tink._Url.Url"] = 
class Url {
	static resolve(this1, that) {
		if (that.scheme != null) {
			return that;
		} else if (that.hosts[0] != null) {
			if (that.scheme != null) {
				return that;
			} else {
				var copy = Reflect__1.copy(that);
				copy.scheme = this1.scheme;
				return copy;
			};
		} else {
			var parts = {"path": Path.join(this1.path, that.path), "payload": "", "scheme": this1.scheme, "query": that.query, "auth": this1.auth, "hosts": this1.hosts, "hash": that.hash};
			Url.makePayload(parts);
			return parts;
		};
	}
	static makePayload(parts) {
		var payload = "";
		var _g = parts.auth;
		var _g1 = parts.hosts;
		if (_g == null) {
			if (_g1.length != 0) {
				payload = "" + ("//" + _g1.join(","));
			};
		} else if (_g1.length == 0) {
			payload = "" + ("//" + ((_g == null) ? "null" : (_g == null) ? "" : "" + _g + "@"));
		} else {
			payload = "" + ("//" + ((_g == null) ? "null" : (_g == null) ? "" : "" + _g + "@") + _g1.join(","));
		};
		payload += (parts.path == null) ? "null" : parts.path;
		var _g = parts.query;
		if (_g != null) {
			payload += "?" + ((_g == null) ? "null" : _g);
		};
		var _g = parts.hash;
		if (_g != null) {
			payload += "#" + _g;
		};
		parts.payload = payload.toString();
	}
	static toString(this1) {
		if (this1.scheme == null) {
			return this1.payload;
		} else {
			return "" + this1.scheme + ":" + this1.payload;
		};
	}
	static fromString(s) {
		return Url.parse(s);
	}
	static noop(_) {
	}
	static parse(s, onError) {
		while (true) {
			if (s == null) {
				s = "";
				onError = null;
				continue;
			};
			if (onError == null) {
				onError = Url.noop;
			};
			s = StringTools.trim(s);
			if (StringTools.startsWith(s, "data:")) {
				return {"scheme": "data", "payload": HxOverrides.substr(s, 5, null), "hosts": []};
			};
			var FORMAT = new EReg("^(([a-zA-Z][a-zA-Z0-9\\-+.]*):)?((//(([^@/]+)@)?([^/?#]*))?([^?#]*)(\\?([^#]*))?(#(.*))?)$", "");
			var HOST = new EReg("^(\\[(.*)\\]|([^:]*))(:(.*))?$", "");
			FORMAT.match(s);
			var hosts;
			var _g = FORMAT.matched(7);
			if (_g == null) {
				hosts = [];
			} else {
				var _g1 = [];
				var _g2 = 0;
				var _g3 = _g.split(",");
				while (_g2 < _g3.length) {
					var host = _g3[_g2];
					++_g2;
					HOST.match(host);
					var host1;
					var _g4 = HOST.matched(3);
					var _g5 = HOST.matched(2);
					if (_g5 == null) {
						host1 = _g4;
					} else if (_g4 == null) {
						host1 = "[" + _g5 + "]";
					} else {
						onError("invalid host " + host);
						host1 = null;
					};
					var port;
					var _g6 = HOST.matched(5);
					if (_g6 == null) {
						port = null;
					} else {
						var _g7 = Std.parseInt(_g6);
						if (_g7 == null) {
							onError("invalid port " + _g6);
							port = null;
						} else {
							port = _g7;
						};
					};
					_g1.push(Host._new(host1, port));
				};
				hosts = _g1;
			};
			var path = FORMAT.matched(8);
			if (hosts.length > 0 && path.charAt(0) != "/") {
				path = "/" + path;
			};
			return {"scheme": FORMAT.matched(2), "payload": FORMAT.matched(3), "hosts": hosts, "auth": FORMAT.matched(6), "path": Path.ofString(path), "query": FORMAT.matched(10), "hash": FORMAT.matched(12)};
		};
	}
	static make(parts) {
		var parts1 = {"payload": "", "path": parts.path, "query": parts.query, "hosts": parts.hosts, "auth": parts.auth, "scheme": parts.scheme, "hash": parts.hash};
		Url.makePayload(parts1);
		return parts1;
	}
	static get __name__() {
		return "tink._Url.Url_Impl_"
	}
	get __class__() {
		return Url
	}
}

