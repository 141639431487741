import {BasicWriter} from "./Writer.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Writer47 = Register.global("$hxClasses")["tink.json.Writer47"] = 
class Writer47 extends Register.inherits(BasicWriter) {
	new() {
		super.new();
	}
	wb(value) {
		this.f("{");
		var value1 = value._193;
		this.e("\"id\":");
		this.j(value1);
		this.f("}");
	}
	wc(value) {
		this.d();
		this.wb(value);
		return this.b.toString();
	}
	static get __name__() {
		return "tink.json.Writer47"
	}
	static get __super__() {
		return BasicWriter
	}
	get __class__() {
		return Writer47
	}
}

