import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {ConstObservable, Observable} from "../../../tink/state/Observable.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Outcome} from "../../../tink/core/Outcome.js"
import {LazyConst} from "../../../tink/core/Lazy.js"
import {SyncFuture} from "../../../tink/core/Future.js"
import {TypedError} from "../../../tink/core/Error.js"
import {Option} from "../../../haxe/ds/Option.js"
import {Register} from "../../../genes/Register.js"
import {UwbTagData} from "./UwbTagData.js"
import {StringCollection} from "../../../coconut/ds/Collection.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const UwbTagsData = Register.global("$hxClasses")["dasloop.app.data.UwbTagsData"] = 
class UwbTagsData extends Register.inherits() {
	new(__coco_init) {
		var _gthis = this;
		this.remote = __coco_init.remote;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new StringCollection({"fetch": function () {
			return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(501, "NotImplemented", {"fileName": "src/dasloop/app/data/UwbTagsData.hx", "lineNumber": 10, "className": "dasloop.app.data.UwbTagsData", "methodName": "new"}))));
		}, "extractKey": function (raw) {
			return raw.id;
		}, "createItem": function (id, init) {
			return new UwbTagData({"id": id, "remote": _gthis.remote, "initial": init});
		}, "updateItem": function (data, raw) {
			data._data.refresh(Option.Some(raw));
		}});
		AutoObservable.cur = before;
		this.collection = ret;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"remote": new ConstObservable(this.remote), "collection": new ConstObservable(this.collection), "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	static get __name__() {
		return "dasloop.app.data.UwbTagsData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return UwbTagsData
	}
}

