import {Remote27} from "./Remote27.js"
import {RemoteBase, RemoteEndpoint, ResponseReader} from "./Remote.js"
import {Portion} from "../../url/Portion.js"
import {Single} from "../../streams/Stream.js"
import {Writer43} from "../../json/Writer43.js"
import {Parser25} from "../../json/Parser25.js"
import {Parser19} from "../../json/Parser19.js"
import {HeaderField, HeaderValue} from "../../http/Header.js"
import {LazyConst} from "../../core/Lazy.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote26 = Register.global("$hxClasses")["tink.web.proxy.Remote26"] = 
class Remote26 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	create(name) {
		var __body__ = ByteChunk.of(Bytes.ofString(new Writer43().wc({"_113": name})));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [], "headers": [new HeaderField("content-type", "application/json"), new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "POST", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser19())));
	}
	list() {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [], "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser25())));
	}
	ofId(id) {
		return new Remote27(this.client, RemoteEndpoint.sub(this.endpoint, {"path": [Portion.ofString(id)], "query": [], "headers": [].concat([])}));
	}
	static get __name__() {
		return "tink.web.proxy.Remote26"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote26
	}
}

