import {Remote9} from "./Remote9.js"
import {Remote67} from "./Remote67.js"
import {Remote64} from "./Remote64.js"
import {Remote63} from "./Remote63.js"
import {Remote57} from "./Remote57.js"
import {Remote53} from "./Remote53.js"
import {Remote50} from "./Remote50.js"
import {Remote48} from "./Remote48.js"
import {Remote44} from "./Remote44.js"
import {Remote43} from "./Remote43.js"
import {Remote39} from "./Remote39.js"
import {Remote38} from "./Remote38.js"
import {Remote37} from "./Remote37.js"
import {Remote35} from "./Remote35.js"
import {Remote34} from "./Remote34.js"
import {Remote26} from "./Remote26.js"
import {Remote25} from "./Remote25.js"
import {Remote20} from "./Remote20.js"
import {Remote19} from "./Remote19.js"
import {Remote18} from "./Remote18.js"
import {Remote16} from "./Remote16.js"
import {Remote13} from "./Remote13.js"
import {Remote12} from "./Remote12.js"
import {Remote11} from "./Remote11.js"
import {RemoteBase, RemoteEndpoint, ResponseReader} from "./Remote.js"
import {Single} from "../../streams/Stream.js"
import {Builder32} from "../../querystring/Builder32.js"
import {Writer5} from "../../json/Writer5.js"
import {Parser7} from "../../json/Parser7.js"
import {Parser6} from "../../json/Parser6.js"
import {Parser24} from "../../json/Parser24.js"
import {HeaderField, HeaderValue} from "../../http/Header.js"
import {NamedWith} from "../../core/Named.js"
import {LazyConst} from "../../core/Lazy.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote8 = Register.global("$hxClasses")["tink.web.proxy.Remote8"] = 
class Remote8 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	getExtended() {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [new NamedWith("extended", "")], "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pe))(new Parser6())));
	}
	update(body) {
		var __body__ = ByteChunk.of(Bytes.ofString(new Writer5().wc({"_39": body.boundary, "_40": body.client, "_41": body.contractor, "_42": body.endDate, "_43": body.name, "_44": body.number, "_45": body.region, "_46": body.startDate, "_47": body.timezone})));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [], "headers": [new HeaderField("content-type", "application/json"), new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "PUT", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser7())));
	}
	updateAvatar(mime) {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["avatar"], "query": [].concat(new Builder32().stringify({"_49": mime})), "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "PUT", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser24())));
	}
	alerts() {
		return new Remote37(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["alerts"], "query": [], "headers": [].concat([])}));
	}
	areas() {
		return new Remote44(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["areas"], "query": [], "headers": [].concat([])}));
	}
	audios() {
		return new Remote43(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["audios"], "query": [], "headers": [].concat([])}));
	}
	beacons() {
		return new Remote48(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["beacons"], "query": [], "headers": [].concat([])}));
	}
	bims() {
		return new Remote53(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["bims"], "query": [], "headers": [].concat([])}));
	}
	dasiots() {
		return new Remote35(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["dasiots"], "query": [], "headers": [].concat([])}));
	}
	contacts() {
		return new Remote12(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["contacts"], "query": [], "headers": [].concat([])}));
	}
	devices() {
		return new Remote34(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["devices"], "query": [], "headers": [].concat([])}));
	}
	equipmentgroups() {
		return new Remote20(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["equipmentgroups"], "query": [], "headers": [].concat([])}));
	}
	equipments() {
		return new Remote19(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["equipments"], "query": [], "headers": [].concat([])}));
	}
	features() {
		return new Remote9(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["features"], "query": [], "headers": [].concat([])}));
	}
	floorplans() {
		return new Remote50(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["floorplans"], "query": [], "headers": [].concat([])}));
	}
	ipcams() {
		return new Remote18(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["ipcams"], "query": [], "headers": [].concat([])}));
	}
	locations() {
		return new Remote38(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["locations"], "query": [], "headers": [].concat([])}));
	}
	logs() {
		return new Remote63(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["logs"], "query": [], "headers": [].concat([])}));
	}
	members() {
		return new Remote11(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["members"], "query": [], "headers": [].concat([])}));
	}
	plants() {
		return new Remote13(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["plants"], "query": [], "headers": [].concat([])}));
	}
	readings() {
		return new Remote39(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["readings"], "query": [], "headers": [].concat([])}));
	}
	statistics() {
		return new Remote64(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["statistics"], "query": [], "headers": [].concat([])}));
	}
	taskgroups() {
		return new Remote57(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["taskgroups"], "query": [], "headers": [].concat([])}));
	}
	tasks() {
		return new Remote67(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["tasks"], "query": [], "headers": [].concat([])}));
	}
	workergroups() {
		return new Remote26(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["workergroups"], "query": [], "headers": [].concat([])}));
	}
	workers() {
		return new Remote25(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["workers"], "query": [], "headers": [].concat([])}));
	}
	aoaLocators() {
		return new Remote16(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["aoa_locators"], "query": [], "headers": [].concat([])}));
	}
	static get __name__() {
		return "tink.web.proxy.Remote8"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote8
	}
}

