import {Signal} from "../../../tink/core/Signal.js"
import {Lazy} from "../../../tink/core/Lazy.js"
import {StringMap} from "../../../haxe/ds/StringMap.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Subscriber = Register.global("$hxClasses")["dasloop.app.remote.Subscriber"] = 
class Subscriber {
	static subscribe2(projectId, deviceId, remote) {
		if (!Subscriber.DEVICES.inst.has(deviceId)) {
			var v = Signal.trigger();
			Subscriber.DEVICES.inst.set(deviceId, v);
			var trigger = v;
			Lazy.get(Subscriber.sub).subscribers.project(projectId, "tink").subscribe(function (envelop) {
				var _g = Lazy.get(envelop.content);
				if (_g._hx_index == 0) {
					var _g1 = _g.data;
					var _g = 0;
					while (_g < _g1.length) {
						var message = _g1[_g];
						++_g;
						if (Subscriber.isOfDevice(message, deviceId)) {
							trigger.handlers.invoke(message);
						};
					};
				};
			});
		};
		return Subscriber.DEVICES.inst.get(deviceId);
	}
	static isOfDevice(notification, deviceId) {
		switch (notification._hx_index) {
			case 0:
				return notification.location.device == deviceId;
				break
			case 1:
				return notification.alert.device == deviceId;
				break
			case 2:
				return notification.reading.device == deviceId;
				break
			
		};
	}
	static get __name__() {
		return "dasloop.app.remote.Subscriber"
	}
	get __class__() {
		return Subscriber
	}
}


Subscriber.DEVICES = new StringMap()