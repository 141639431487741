import {StringMap} from "../../haxe/ds/StringMap.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Runtime = Register.global("$hxClasses")["cix.css.Runtime"] = 
class Runtime {
	static addRule(id, css) {
		if (Runtime.indices == null) {
			Runtime.indices = new StringMap();
			var old = window.document.querySelector("head style#_cix_");
			if (old != null) {
				window.document.head.removeChild(old);
			};
			var style = window.document.createElement("style");
			style.id = "_cix_";
			window.document.head.appendChild(style);
			Runtime.sheet = style.sheet;
		};
		var tmp = Runtime.sheet;
		var _g = Runtime.indices.inst.get(id);
		var tmp1;
		if (_g == null) {
			var v = Runtime.sheet.cssRules.length;
			Runtime.indices.inst.set(id, v);
			tmp1 = v;
		} else {
			tmp1 = _g;
		};
		tmp.insertRule("@media all { " + css + " }", tmp1);
	}
	static get __name__() {
		return "cix.css.Runtime"
	}
	get __class__() {
		return Runtime
	}
}

