import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Vector = Register.global("$hxClasses")["tink.pure._Vector.Vector"] = 
class Vector {
	static fromVector(v) {
		return v;
	}
	static fromArray(a) {
		return a.slice();
	}
	static get __name__() {
		return "tink.pure._Vector.Vector_Impl_"
	}
	get __class__() {
		return Vector
	}
}


export const Vectorlike = Register.global("$hxClasses")["tink.pure._Vector.Vectorlike"] = 
class Vectorlike {
	static ofSingle(v) {
		return [v];
	}
	static get __name__() {
		return "tink.pure._Vector.Vectorlike_Impl_"
	}
	get __class__() {
		return Vectorlike
	}
}

