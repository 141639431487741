import {BasicWriter} from "./Writer.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Writer8 = Register.global("$hxClasses")["tink.json.Writer8"] = 
class Writer8 extends Register.inherits(BasicWriter) {
	new() {
		super.new();
	}
	wb(value) {
		this.f("{");
		var value1 = value._78;
		this.e("\"azimuth\":");
		this.h(value1);
		var value1 = value._79;
		this.f(",");
		this.e("\"environmentFactor\":");
		this.h(value1);
		var value1 = value._80;
		this.f(",");
		this.e("\"location\":");
		var value2 = value1;
		this.f("[");
		var first = true;
		var _g = 0;
		while (_g < value2.length) {
			var value1 = value2[_g++];
			if (first) {
				first = false;
			} else {
				this.f(",");
			};
			this.h(value1);
		};
		this.f("]");
		var value1 = value._81;
		this.f(",");
		this.e("\"name\":");
		this.j(value1);
		this.f("}");
	}
	wc(value) {
		this.d();
		this.wb(value);
		return this.b.toString();
	}
	static get __name__() {
		return "tink.json.Writer8"
	}
	static get __super__() {
		return BasicWriter
	}
	get __class__() {
		return Writer8
	}
}

