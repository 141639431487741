import {Register} from "./Register.js"

const $global = Register.$global

export const Genes = Register.global("$hxClasses")["genes.Genes"] = 
class Genes {
	static ignore(names, res) {
		return res;
	}
	static get __name__() {
		return "genes.Genes"
	}
	get __class__() {
		return Genes
	}
}

