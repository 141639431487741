import {Outcome, OutcomeTools} from "./Outcome.js"
import {LazyConst} from "./Lazy.js"
import {SyncFuture, Future} from "./Future.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Promise = Register.global("$hxClasses")["tink.core._Promise.Promise"] = 
class Promise {
	static never() {
		return Future.never();
	}
	static mapError(this1, f) {
		return Future.map(this1, function (o) {
			switch (o._hx_index) {
				case 0:
					return o;
					break
				case 1:
					return Outcome.Failure(f(o.failure));
					break
				
			};
		});
	}
	static noise(this1) {
		if (this1.getStatus()._hx_index == 4) {
			return Promise.never();
		} else {
			return Promise.next(this1, function (v) {
				return new SyncFuture(new LazyConst(Outcome.Success(null)));
			});
		};
	}
	static next(this1, f, gather) {
		return Future.flatMap(this1, function (o) {
			switch (o._hx_index) {
				case 0:
					return f(o.data);
					break
				case 1:
					return new SyncFuture(new LazyConst(Outcome.Failure(o.failure)));
					break
				
			};
		});
	}
	static fromNever(l) {
		return l;
	}
	static inParallel(a, concurrency) {
		return Promise.many(a, concurrency);
	}
	static many(a, concurrency) {
		return Future.processMany(a, concurrency, function (o) {
			return o;
		}, function (o) {
			return o;
		});
	}
	static inSequence(a) {
		return Promise.many(a, 1);
	}
	static cache(gen) {
		var p = null;
		return function () {
			var ret = p;
			if (ret == null) {
				var sync = false;
				ret = Promise.next(gen(), function (o) {
					o.b.handle(function (_) {
						sync = true;
						p = null;
					});
					return new SyncFuture(new LazyConst(Outcome.Success(o.a)));
				});
				if (!sync) {
					p = ret;
				};
			};
			return Future.map(ret, function (o) {
				if (!OutcomeTools.isSuccess(o)) {
					p = null;
				};
				return o;
			});
		};
	}
	static get __name__() {
		return "tink.core._Promise.Promise_Impl_"
	}
	get __class__() {
		return Promise
	}
}


Promise.NOISE = new SyncFuture(new LazyConst(Outcome.Success(null)))
Promise.NEVER = Promise.never()
export const Next = Register.global("$hxClasses")["tink.core._Promise.Next"] = 
class Next {
	static ofSafe(f) {
		return function (x) {
			return new SyncFuture(new LazyConst(f(x)));
		};
	}
	static ofSafeSync(f) {
		return function (x) {
			return new SyncFuture(new LazyConst(Outcome.Success(f(x))));
		};
	}
	static get __name__() {
		return "tink.core._Promise.Next_Impl_"
	}
	get __class__() {
		return Next
	}
}

