import {BasicParser, JsonString} from "./Parser.js"
import {TypedError} from "../core/Error.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Parser71 = Register.global("$hxClasses")["tink.json.Parser71"] = 
class Parser71 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	pb() {
		var _gthis = this;
		var v_iceServers = null;
		var hasv_iceServers = false;
		var v_token = null;
		var hasv_token = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "iceServers":
						this.F("[");
						var __ret = [];
						if (!this.E("]")) {
							while (true) {
								__ret.push(this.pc());
								if (!this.E(",")) {
									break;
								};
							};
							this.F("]");
						};
						v_iceServers = __ret;
						hasv_iceServers = true;
						break
					case "token":
						v_token = JsonString.s(this.m());
						hasv_token = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"iceServers": (hasv_iceServers) ? v_iceServers : __missing__("iceServers"), "token": (hasv_token) ? v_token : __missing__("token")};
	}
	pc() {
		var _gthis = this;
		var v_credential = null;
		var v_urls = null;
		var hasv_urls = false;
		var v_username = null;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "credential":
						v_credential = (this.E("null")) ? null : JsonString.s(this.m());
						break
					case "urls":
						v_urls = JsonString.s(this.m());
						hasv_urls = true;
						break
					case "username":
						v_username = (this.E("null")) ? null : JsonString.s(this.m());
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"credential": v_credential, "urls": (hasv_urls) ? v_urls : __missing__("urls"), "username": v_username};
	}
	pd(source) {
		if (this.e.length > 0) {
			this.e = [];
		};
		this.g(source);
		var ret = this.pb();
		var _g = 0;
		var _g1 = this.e;
		while (_g < _g1.length) _g1[_g++]();
		if (this.e.length > 0) {
			this.e = [];
		};
		return ret;
	}
	pe(source) {
		var _gthis = this;
		return TypedError.catchExceptions(function () {
			var ret = _gthis.pd(source);
			_gthis.h();
			if (_gthis.c < _gthis.d) {
				_gthis.D("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser71", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser71"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser71
	}
}

