import {Remote55} from "./Remote55.js"
import {RemoteBase, RemoteEndpoint, ResponseReader} from "./Remote.js"
import {Portion} from "../../url/Portion.js"
import {Single} from "../../streams/Stream.js"
import {Writer54} from "../../json/Writer54.js"
import {Parser63} from "../../json/Parser63.js"
import {Parser41} from "../../json/Parser41.js"
import {HeaderField, HeaderValue} from "../../http/Header.js"
import {LazyConst} from "../../core/Lazy.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote53 = Register.global("$hxClasses")["tink.web.proxy.Remote53"] = 
class Remote53 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	list() {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [], "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser41())));
	}
	create(body) {
		var __body__ = ByteChunk.of(Bytes.ofString(new Writer54().wc({"_203": body.center, "_204": body.name, "_205": body.rotation, "_206": body.scale, "_207": body.type})));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [], "headers": [new HeaderField("content-type", "application/json"), new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "POST", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser63())));
	}
	ofId(id) {
		return new Remote55(this.client, RemoteEndpoint.sub(this.endpoint, {"path": [Portion.ofString(id)], "query": [], "headers": [].concat([])}));
	}
	static get __name__() {
		return "tink.web.proxy.Remote53"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote53
	}
}

