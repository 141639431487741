import {BasicParser, JsonString} from "./Parser.js"
import {TypedError} from "../core/Error.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Parser48 = Register.global("$hxClasses")["tink.json.Parser48"] = 
class Parser48 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	pb() {
		var _gthis = this;
		var v_content = null;
		var hasv_content = false;
		var v_date = null;
		var hasv_date = false;
		var v_id = null;
		var hasv_id = false;
		var v_project = null;
		var hasv_project = false;
		var v_user = null;
		var hasv_user = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "content":
						v_content = JsonString.s(this.m());
						hasv_content = true;
						break
					case "date":
						v_date = new Date(JsonString.f(this.r()));
						hasv_date = true;
						break
					case "id":
						v_id = JsonString.s(this.m());
						hasv_id = true;
						break
					case "project":
						v_project = JsonString.s(this.m());
						hasv_project = true;
						break
					case "user":
						v_user = this.pc();
						hasv_user = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"content": (hasv_content) ? v_content : __missing__("content"), "date": (hasv_date) ? v_date : __missing__("date"), "id": (hasv_id) ? v_id : __missing__("id"), "project": (hasv_project) ? v_project : __missing__("project"), "user": (hasv_user) ? v_user : __missing__("user")};
	}
	pc() {
		var _gthis = this;
		var v_admin = false;
		var hasv_admin = false;
		var v_email = null;
		var hasv_email = false;
		var v_id = null;
		var hasv_id = false;
		var v_name = null;
		var hasv_name = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "admin":
						v_admin = this.G();
						hasv_admin = true;
						break
					case "email":
						v_email = JsonString.s(this.m());
						hasv_email = true;
						break
					case "id":
						v_id = JsonString.s(this.m());
						hasv_id = true;
						break
					case "name":
						v_name = (this.E("null")) ? null : JsonString.s(this.m());
						hasv_name = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"admin": (hasv_admin) ? v_admin : __missing__("admin"), "email": (hasv_email) ? v_email : __missing__("email"), "id": (hasv_id) ? v_id : __missing__("id"), "name": (hasv_name) ? v_name : __missing__("name")};
	}
	pd(source) {
		if (this.e.length > 0) {
			this.e = [];
		};
		this.g(source);
		this.F("[");
		var __ret = [];
		if (!this.E("]")) {
			while (true) {
				__ret.push(this.pb());
				if (!this.E(",")) {
					break;
				};
			};
			this.F("]");
		};
		var ret = __ret;
		var _g = 0;
		var _g1 = this.e;
		while (_g < _g1.length) _g1[_g++]();
		if (this.e.length > 0) {
			this.e = [];
		};
		return ret;
	}
	pe(source) {
		var _gthis = this;
		return TypedError.catchExceptions(function () {
			var ret = _gthis.pd(source);
			_gthis.h();
			if (_gthis.c < _gthis.d) {
				_gthis.D("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser48", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser48"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser48
	}
}

