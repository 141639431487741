import {Transform} from "../../tink/state/internal/Transform.js"
import {State} from "../../tink/state/State.js"
import {Observable} from "../../tink/state/Observable.js"
import {Mapping} from "../../tink/pure/Mapping.js"
import {Signal} from "../../tink/core/Signal.js"
import {Register} from "../../genes/Register.js"
import {Annex} from "../data/helpers/Annex.js"
import {Model} from "../data/Model.js"

const $global = Register.$global

export const IDict = function() {};
IDict.__isInterface__ = true;

export const IntDict = Register.global("$hxClasses")["coconut.ds.IntDict"] = 
class IntDict extends Register.inherits() {
	new(__coco_init) {
		this._updatePerformed = Signal.trigger();
		this.__coco_map = State._new(null, null, null, null);
		this.factory = __coco_init.factory;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	get(i, factory) {
		if (!Mapping.exists(State.get_value(this.__coco_map), i)) {
			var v = (factory == null) ? this.factory(i) : factory(i);
			this.set_map(Mapping["with"](State.get_value(this.__coco_map), i, v));
			return v;
		} else {
			return Mapping.get(State.get_value(this.__coco_map), i);
		};
	}
	set_map(param) {
		this._updatePerformed.handlers.invoke({"map": param});
		this.__coco_map.set(param);
		return param;
	}
	static get __name__() {
		return "coconut.ds.IntDict"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return IntDict
	}
}


export const StringDict = Register.global("$hxClasses")["coconut.ds.StringDict"] = 
class StringDict extends Register.inherits() {
	new(__coco_init) {
		this._updatePerformed = Signal.trigger();
		this.__coco_map = State._new(null, null, null, null);
		this.factory = __coco_init.factory;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	get(i, factory) {
		if (!Mapping.exists(State.get_value(this.__coco_map), i)) {
			var v = (factory == null) ? this.factory(i) : factory(i);
			this.set_map(Mapping["with"](State.get_value(this.__coco_map), i, v));
			return v;
		} else {
			return Mapping.get(State.get_value(this.__coco_map), i);
		};
	}
	exists(k) {
		return Mapping.exists(State.get_value(this.__coco_map), k);
	}
	set_map(param) {
		this._updatePerformed.handlers.invoke({"map": param});
		this.__coco_map.set(param);
		return param;
	}
	static get __name__() {
		return "coconut.ds.StringDict"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return StringDict
	}
}

