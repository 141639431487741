import {SimpleLink} from "../../tink/core/Callback.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Client = function() {};
Client.__isInterface__ = true;

export const Subscription = Register.global("$hxClasses")["why.mqtt.Subscription"] = 
class Subscription extends Register.inherits(SimpleLink) {
	new(f, topic, qos) {
		super.new(f);
		this.topic = topic;
		this.qos = qos;
	}
	static get __name__() {
		return "why.mqtt.Subscription"
	}
	static get __super__() {
		return SimpleLink
	}
	get __class__() {
		return Subscription
	}
}

