import {State} from "../../../tink/state/State.js"
import {Observable} from "../../../tink/state/Observable.js"
import {ClassName} from "../../../tink/domspec/ClassName.js"
import {Sidebar, Menu} from "semantic-ui-react"
import {ReactType} from "../../../react/ReactType.js"
import * as React from "react"
import {Register} from "../../../genes/Register.js"
import {Slot} from "../../../coconut/ui/internal/Slot.js"
import {View} from "../../../coconut/react/View.js"
import {Html} from "../../../coconut/react/Html.js"
import {Cixf9bfd0ab06a6f10d1549c21b3dd2652f8ac55ae90dae40947e9e798924ed3285} from "../../../Cixf9bfd0ab06a6f10d1549c21b3dd2652f8ac55ae90dae40947e9e798924ed3285.js"
import {Cixe9079fd8879254bae04e00e7590f62bf53e34c3e71adc93feea855bbdd4cef2c} from "../../../Cixe9079fd8879254bae04e00e7590f62bf53e34c3e71adc93feea855bbdd4cef2c.js"
import {Cix89f36f5f2f90f8ce599858f1044b551f5079e395533981daeb4959b3241ff239} from "../../../Cix89f36f5f2f90f8ce599858f1044b551f5079e395533981daeb4959b3241ff239.js"
import {Cix4ca3c6833cc6fd091c514fb2e8227c39246023164ee888767c1a403c124ee9d1} from "../../../Cix4ca3c6833cc6fd091c514fb2e8227c39246023164ee888767c1a403c124ee9d1.js"
import {Cix3f5d01c6c050fce1cf970e4de6cdf0ed62519da84f8064ad76adc2ff68977d49} from "../../../Cix3f5d01c6c050fce1cf970e4de6cdf0ed62519da84f8064ad76adc2ff68977d49.js"
import {Cix2660984a0b08f15c7b0d7d27f7c568471910bb0f9938615644c9602dac159f3e} from "../../../Cix2660984a0b08f15c7b0d7d27f7c568471910bb0f9938615644c9602dac159f3e.js"
import {Cix0d44e2dbbc9ee6f4702bc6e9a25128e599e8eb379eac0192f855184f0f463a32} from "../../../Cix0d44e2dbbc9ee6f4702bc6e9a25128e599e8eb379eac0192f855184f0f463a32.js"

const $global = Register.$global

export const Dashboard = Register.global("$hxClasses")["dasloop.ui.general.Dashboard"] = 
class Dashboard extends Register.inherits(View) {
	new(__coco_data_) {
		var _gthis = this;
		this.__coco_sidebar = new Slot(this, null, null);
		this.__coco_content = new Slot(this, null, null);
		this.__coco_navbar = new Slot(this, null, null);
		this.__coco_footer = new Slot(this, null, null);
		this.__coco_sidebarVisible = State._new(false, null);
		this.__initAttributes(__coco_data_);
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	sidebar(a0) {
		return (Observable.get_value(this.__coco_sidebar))(a0);
	}
	navbar(a0) {
		return (Observable.get_value(this.__coco_navbar))(a0);
	}
	__coco_render() {
		var _gthis = this;
		var __r = [];
		var props = {"visible": State.get_value(this.__coco_sidebarVisible), "className": "inverted vertical menu", "animation": "overlay", "onHide": function () {
			_gthis.__coco_sidebarVisible.set(false);
			return false;
		}};
		var __r1 = [];
		var _g = 0;
		var _g1 = this.sidebar({"hideSidebar": Register.bind(this, this.hideSidebar)});
		while (_g < ((_g1 == null) ? 0 : _g1.length)) {
			var _0 = (_g1 == null) ? null : _g1[_g];
			++_g;
			__r1.push(_0);
		};
		var tmp = [ReactType.fromComp(Sidebar), props].concat(__r1);
		__r.push(React.createElement.apply(React, tmp));
		var attributes = {"className": ClassName.add(ClassName.add(Dashboard.FLEX, Dashboard.FLEX_COLUMN), Dashboard.FILL_SCREEN_HEIGHT)};
		var __r1 = [];
		var __r2 = [];
		var props = {"className": ClassName.ofString("inverted attached vertical menu " + Dashboard.TABLET_HIDDEN + " " + Dashboard.NO_SHRINK)};
		var __r3 = [];
		var _g = 0;
		var _g1 = this.sidebar({"hideSidebar": Register.bind(this, this.hideSidebar)});
		while (_g < ((_g1 == null) ? 0 : _g1.length)) {
			var _0 = (_g1 == null) ? null : _g1[_g];
			++_g;
			__r3.push(_0);
		};
		var children = [ReactType.fromComp(Menu), props].concat(__r3);
		__r2.push(React.createElement.apply(React, children));
		var attributes1 = {"className": ClassName.add(Dashboard.FLEX, Dashboard.FLEX_COLUMN)};
		var __r3 = [];
		var _g = 0;
		var _g1 = this.navbar({"menuButtonClass": Dashboard.TABLET_VISIBLE, "toggleSidebar": Register.bind(this, this.toggleSidebar)});
		while (_g < ((_g1 == null) ? 0 : _g1.length)) {
			var _0 = (_g1 == null) ? null : _g1[_g];
			++_g;
			__r3.push(_0);
		};
		var attributes2 = {"className": ClassName.add(Dashboard.FLEX, Dashboard.FLEX_COLUMN), "style": {"backgroundColor": "#f5f5f5"}};
		var __r4 = [];
		var _g = 0;
		var _g1 = Observable.get_value(this.__coco_content);
		while (_g < ((_g1 == null) ? 0 : _g1.length)) {
			var _0 = (_g1 == null) ? null : _g1[_g];
			++_g;
			__r4.push(_0);
		};
		__r3.push(Html.h("div", attributes2, __r4));
		__r2.push(Html.h("div", attributes1, __r3));
		__r1.push(Html.h("div", {"className": Dashboard.FLEX}, __r2));
		var __r2 = [];
		var _g = 0;
		var _g1 = Observable.get_value(this.__coco_footer);
		while (_g < ((_g1 == null) ? 0 : _g1.length)) {
			var _0 = (_g1 == null) ? null : _g1[_g];
			++_g;
			__r2.push(_0);
		};
		__r1.push(Html.h("div", {"className": Dashboard.FOOTER}, __r2));
		var children = [Html.h("div", attributes, __r1)];
		var tmp = [ReactType.fromComp(Sidebar.Pusher), {}].concat(children);
		__r.push(React.createElement.apply(React, tmp));
		var tmp = [ReactType.fromComp(Sidebar.Pushable), {}].concat(__r);
		return React.createElement.apply(React, tmp);
	}
	hideSidebar() {
		this.__coco_sidebarVisible.set(false);
	}
	toggleSidebar() {
		var param = !State.get_value(this.__coco_sidebarVisible);
		this.__coco_sidebarVisible.set(param);
	}
	__initAttributes(attributes) {
		this.__coco_sidebar.setData(attributes.sidebar);
		this.__coco_content.setData(attributes.content);
		this.__coco_navbar.setData(attributes.navbar);
		this.__coco_footer.setData(attributes.footer);
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": Dashboard, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "dasloop.ui.general.Dashboard"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return Dashboard
	}
}


Dashboard.TABLET_HIDDEN = Cixf9bfd0ab06a6f10d1549c21b3dd2652f8ac55ae90dae40947e9e798924ed3285.inst.css
Dashboard.TABLET_VISIBLE = Cix2660984a0b08f15c7b0d7d27f7c568471910bb0f9938615644c9602dac159f3e.inst.css
Dashboard.FLEX = Cixe9079fd8879254bae04e00e7590f62bf53e34c3e71adc93feea855bbdd4cef2c.inst.css
Dashboard.FLEX_COLUMN = Cix89f36f5f2f90f8ce599858f1044b551f5079e395533981daeb4959b3241ff239.inst.css
Dashboard.NO_SHRINK = Cix4ca3c6833cc6fd091c514fb2e8227c39246023164ee888767c1a403c124ee9d1.inst.css
Dashboard.FILL_SCREEN_HEIGHT = Cix3f5d01c6c050fce1cf970e4de6cdf0ed62519da84f8064ad76adc2ff68977d49.inst.css
Dashboard.FOOTER = ClassName.add(Cix0d44e2dbbc9ee6f4702bc6e9a25128e599e8eb379eac0192f855184f0f463a32.inst.css, "ui inverted segment")