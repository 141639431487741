import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {ConstObservable, Observable} from "../../../tink/state/Observable.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Register} from "../../../genes/Register.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const DeviceLiveCallData = Register.global("$hxClasses")["dasloop.app.data.DeviceLiveCallData"] = 
class DeviceLiveCallData extends Register.inherits() {
	new(__coco_init) {
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.async(function () {
			return _gthis.remote.v2().projects().ofId(_gthis.project).devices().ofId(_gthis.device).getLiveCallRecordingUrls({});
		}), null);
		AutoObservable.cur = before;
		this.__coco_recordings = ret;
		this.device = __coco_init.device;
		this.project = __coco_init.project;
		this.remote = __coco_init.remote;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"device": new ConstObservable(this.device), "project": new ConstObservable(this.project), "remote": new ConstObservable(this.remote), "recordings": this.__coco_recordings, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	static get __name__() {
		return "dasloop.app.data.DeviceLiveCallData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return DeviceLiveCallData
	}
}

