import {NotyToast} from "../why/toast/NotyToast.js"
import {Mqtt0} from "../why/pubsub/mqtt/Mqtt0.js"
import {MqttJsClient} from "../why/mqtt/client/MqttJsClient.js"
import {KeepAliveClient} from "../why/mqtt/client/KeepAliveClient.js"
import {Toast} from "../why/Toast.js"
import {Remote0} from "../tink/web/proxy/Remote0.js"
import {RemoteEndpoint} from "../tink/web/proxy/Remote.js"
import {AutoObservable, Computation} from "../tink/state/internal/AutoObservable.js"
import {State} from "../tink/state/State.js"
import {Observable} from "../tink/state/Observable.js"
import {OutgoingRequest} from "../tink/http/Request.js"
import {HeaderField} from "../tink/http/Header.js"
import {CustomClient} from "../tink/http/Client.js"
import {PromisesContainer} from "../tink/core/ext/Promises.js"
import {Promise as Promise__1} from "../tink/core/Promise.js"
import {Outcome, OutcomeTools} from "../tink/core/Outcome.js"
import {LazyConst, LazyFunc} from "../tink/core/Lazy.js"
import {Future, SyncFuture} from "../tink/core/Future.js"
import {Timer} from "../haxe/Timer.js"
import {Register} from "../genes/Register.js"
import {Config as Config__1} from "./util/Config.js"
import {Config} from "./core/Config.js"
import {AppContainer} from "./app/ui/AppContainer.js"
import {Subscriber} from "./app/remote/Subscriber.js"
import {UserData} from "./app/data/UserData.js"
import {AppData} from "./app/data/AppData.js"
import {Renderer} from "../coconut/react/Renderer.js"
import {Std} from "../Std.js"
import {HxOverrides} from "../HxOverrides.js"

const $global = Register.$global

export const App = Register.global("$hxClasses")["dasloop.App"] = 
class App {
	static main(keycloak) {
		Observable.MAX_ITERATIONS = 500;
		Register.$global.console.log("Version: " + "92a5e58bed3740f4f18e24ef14290eb92b15a796");
		Register.$global.console.log("Build date: " + Std.string(new Date(1.722467036e+12)));
		if (!window.ResizeObserver) {
			var tmp = require;
			window.ResizeObserver = tmp("@juggle/resize-observer").ResizeObserver;
		};
		Toast.instance = new NotyToast();
		var getToken = function () {
			return Promise__1.next(keycloak, function (keycloak) {
				return Promise__1.next(Future.ofJsPromise(keycloak.updateToken(), null), function (_) {
					return new SyncFuture(new LazyConst(Outcome.Success(keycloak.token)));
				});
			});
		};
		var getToken1 = getToken;
		var client = null;
		if (client == null) {
			client = Config.HTTP_CLIENT;
		};
		var pipeline = {"before": [function (req) {
			return Promise__1.next(getToken1(), function (token) {
				return new SyncFuture(new LazyConst(Outcome.Success(new OutgoingRequest(req.header.concat([new HeaderField("authorization", "Bearer " + token)]), req.body))));
			});
		}]};
		var this1 = new Remote0(CustomClient.create(client, pipeline.before, pipeline.after), RemoteEndpoint.sub(RemoteEndpoint._new(Config.API_SERVER_HOST), {"headers": [new HeaderField("content-type", "application/json"), new HeaderField("accept", "application/json")]}));
		var remote = this1;
		var userId = Promise__1.next(Promise__1.next(keycloak, function (keycloak) {
			return Future.ofJsPromise(keycloak.loadUserProfile(), null);
		}), function (user) {
			return new SyncFuture(new LazyConst(Outcome.Success(user.id)));
		});
		Subscriber.sub = new LazyFunc(function () {
			var clientId = Promise__1.next(userId, function (uid) {
				return new SyncFuture(new LazyConst(Outcome.Success("" + uid + "-" + Std.random(16777216) + "-" + new Date().getTime())));
			});
			var mqtt1 = new KeepAliveClient(function () {
				var __obj_userId;
				var __obj_token;
				var __obj_clientId;
				__obj_userId = userId;
				__obj_clientId = clientId;
				__obj_token = getToken();
				return Promise__1.next(Future.irreversible(function (cb) {
					var __ctx = new PromisesContainer(cb, 3);
					__obj_clientId.handle(__ctx.handle(function (r, v) {
						r.clientId = v;
					}));
					__obj_token.handle(__ctx.handle(function (r, v) {
						r.token = v;
					}));
					__obj_userId.handle(__ctx.handle(function (r, v) {
						r.userId = v;
					}));
				}), function (o) {
					return new SyncFuture(new LazyConst(Outcome.Success(new MqttJsClient({"url": Config__1.MQTT_WEBSOCKET_URL, "clientId": o.clientId, "username": "dasloop/" + o.userId, "password": o.token, "cleanSession": false, "reconnectPeriod": 0, "keepAlive": 25}))));
				});
			});
			mqtt1.connect().handle(function (o) {
				Register.$global.console.log("" + HxOverrides.dateStr(new Date()) + ": MQTT: connect: " + Std.string(o));
			});
			mqtt1.reconnected.listen(function (o) {
				Register.$global.console.log("" + HxOverrides.dateStr(new Date()) + ": MQTT: reconnected");
			});
			return new Mqtt0(mqtt1);
		});
		var state = State._new(new Date());
		var timer = new Timer(5000);
		timer.run = function () {
			state.set(new Date());
		};
		var data = new AppData({"now": state, "user": new AutoObservable(Computation.sync(function () {
			return new UserData({"remote": remote, "getProfile": function () {
				return Promise__1.next(keycloak, function (keycloak) {
					return Promise__1.next(Future.ofJsPromise(keycloak.loadUserProfile(), null), function (profile) {
						return new SyncFuture(new LazyConst(Outcome.Success({"id": profile.id, "email": profile.email, "phone": null})));
					});
				});
			}});
		}), null), "remote": remote});
		var container = window.document.getElementById("app");
		var tmp = new AutoObservable(Computation.sync(function () {
			return data;
		}), null);
		var tmp1 = new AutoObservable(Computation.sync(function () {
			return function () {
				keycloak.handle(function (o) {
					try {
						OutcomeTools.sure(o).logout({"redirectUri": window.location.origin});
					}catch (_g) {
					};
				});
			};
		}), null);
		var tmp2 = new AutoObservable(Computation.sync(function () {
			return function (redirectUri) {
				keycloak.handle(function (o) {
					try {
						OutcomeTools.sure(o).login({"action": "UPDATE_PASSWORD", "redirectUri": redirectUri});
					}catch (_g) {
					};
				});
			};
		}), null);
		var tmp3 = new AutoObservable(Computation.sync(function () {
			return getToken;
		}), null);
		Renderer.mountInto(container, AppContainer.fromHxx({}, {"app": tmp, "logout": tmp1, "changePassword": tmp2, "getToken": tmp3}));
	}
	static get __name__() {
		return "dasloop.App"
	}
	get __class__() {
		return App
	}
}

