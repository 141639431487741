import {AutoObservable, Computation} from "../../tink/state/internal/AutoObservable.js"
import {Observable} from "../../tink/state/Observable.js"
import {Register} from "../../genes/Register.js"
import {Spinner} from "./general/Spinner.js"
import {ErrorMessage} from "./general/ErrorMessage.js"
import {Slot} from "../../coconut/ui/internal/Slot.js"
import {View} from "../../coconut/react/View.js"

const $global = Register.$global

export const PromisedView = Register.global("$hxClasses")["dasloop.ui.PromisedView"] = 
class PromisedView extends Register.inherits(View) {
	new(__coco_data_) {
		var _gthis = this;
		this.__coco_value = new Slot(this, null, null);
		this.__coco_renderDone = new Slot(this, null, null);
		this.__coco_renderError = new Slot(this, null, new AutoObservable(Computation.sync(function () {
			return function (e) {
				var compute = new AutoObservable(Computation.sync(function () {
					return e;
				}), null);
				return ErrorMessage.fromHxx({}, {"error": compute});
			};
		}), null));
		this.__coco_renderLoading = new Slot(this, null, new AutoObservable(Computation.sync(function () {
			return function () {
				return Spinner.fromHxx({}, {});
			};
		}), null));
		this.__initAttributes(__coco_data_);
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	renderDone(a0) {
		return (Observable.get_value(this.__coco_renderDone))(a0);
	}
	renderError(e) {
		return (Observable.get_value(this.__coco_renderError))(e);
	}
	renderLoading() {
		return (Observable.get_value(this.__coco_renderLoading))();
	}
	__coco_render() {
		var _g = Observable.get_value(this.__coco_value);
		switch (_g._hx_index) {
			case 0:
				return this.renderLoading();
				break
			case 1:
				return this.renderDone(_g.result);
				break
			case 2:
				return this.renderError(_g.error);
				break
			
		};
	}
	__initAttributes(attributes) {
		this.__coco_value.setData(attributes.value);
		this.__coco_renderDone.setData(attributes.renderDone);
		this.__coco_renderError.setData(attributes.renderError);
		this.__coco_renderLoading.setData(attributes.renderLoading);
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": PromisedView, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "dasloop.ui.PromisedView"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return PromisedView
	}
}

