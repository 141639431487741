import {RemoteBase, RemoteEndpoint, ResponseReader} from "./Remote.js"
import {Portion} from "../../url/Portion.js"
import {Single} from "../../streams/Stream.js"
import {Builder2} from "../../querystring/Builder2.js"
import {Parser26} from "../../json/Parser26.js"
import {HeaderField, HeaderValue} from "../../http/Header.js"
import {NamedWith} from "../../core/Named.js"
import {LazyConst} from "../../core/Lazy.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote36 = Register.global("$hxClasses")["tink.web.proxy.Remote36"] = 
class Remote36 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	listPerDevice(perDevice, query) {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": [], "query": [new NamedWith("per_device", Portion.ofString((perDevice == null) ? "null" : "" + perDevice))].concat(new Builder2().stringify({"_141": query.after, "_142": query.before})), "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser26())));
	}
	static get __name__() {
		return "tink.web.proxy.Remote36"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote36
	}
}

