import {Transform} from "../../../tink/state/internal/Transform.js"
import {AutoObservable, Computation} from "../../../tink/state/internal/AutoObservable.js"
import {State} from "../../../tink/state/State.js"
import {Observable, ConstObservable} from "../../../tink/state/Observable.js"
import {PromisesContainer} from "../../../tink/core/ext/Promises.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Promise as Promise__1} from "../../../tink/core/Promise.js"
import {OutcomeTools, Outcome} from "../../../tink/core/Outcome.js"
import {LazyConst} from "../../../tink/core/Lazy.js"
import {Future, SyncFuture} from "../../../tink/core/Future.js"
import {StringMap} from "../../../haxe/ds/StringMap.js"
import {Register} from "../../../genes/Register.js"
import {AggregationType} from "../../types/AggregationType.js"
import {EquipmentsData} from "./EquipmentsData.js"
import {Updatable} from "../../../coconut/ds/Updatable.js"
import {Annex} from "../../../coconut/data/helpers/Annex.js"
import {Model} from "../../../coconut/data/Model.js"

const $global = Register.$global

export const EquipmentgroupData = Register.global("$hxClasses")["dasloop.app.data.EquipmentgroupData"] = 
class EquipmentgroupData extends Register.inherits() {
	new(__coco_init) {
		this._updatePerformed = Signal.trigger();
		var _gthis = this;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.async(function () {
			State.get_value(_gthis.__coco_revision);
			return _gthis.remote.v2().projects().ofId(_gthis.project).equipmentgroups().ofId(_gthis.id).contacts().list();
		}), null);
		AutoObservable.cur = before;
		this.__coco_contacts = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new AutoObservable(Computation.sync(function () {
			return Observable.get_value(_gthis._data.__coco_data);
		}), null);
		AutoObservable.cur = before;
		this.__coco_data = ret;
		this.project = __coco_init.project;
		this.id = __coco_init.id;
		this.remote = __coco_init.remote;
		this.__coco_revision = State._new(0, null, null, null);
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new Updatable({"loader": function () {
			return _gthis.remote.v2().projects().ofId(_gthis.project).equipmentgroups().ofId(_gthis.id).get();
		}, "updater": function (current, patch) {
			return _gthis.remote.v2().projects().ofId(_gthis.project).equipmentgroups().ofId(_gthis.id).update(patch);
		}});
		AutoObservable.cur = before;
		this._data = ret;
		var before = AutoObservable.cur;
		AutoObservable.cur = null;
		var ret = new EquipmentsData({"remote": _gthis.remote, "group": _gthis.id, "project": _gthis.project});
		AutoObservable.cur = before;
		this.equipments = ret;
		this.__coco_transitionCount = State._new(0);
		this.errorTrigger = Signal.trigger();
		this.transitionErrors = this.errorTrigger;
		this.annex = new Annex(this);
		this.observables = {"project": new ConstObservable(this.project), "id": new ConstObservable(this.id), "remote": new ConstObservable(this.remote), "_data": new ConstObservable(this._data), "data": this.__coco_data, "equipments": new ConstObservable(this.equipments), "contacts": this.__coco_contacts, "isInTransition": Observable.map(this.__coco_transitionCount, Transform.plain(function (count) {
			return count > 0;
		}))};
	}
	addEquipments(equipmentIds) {
		var _gthis = this;
		var api = this.remote.v2().projects().ofId(this.project).equipmentgroups().ofId(this.id);
		var _g = [];
		var _g1 = 0;
		while (_g1 < equipmentIds.length) _g.push(api.addEquipment(equipmentIds[_g1++]));
		return Future.map(Promise__1.inParallel(_g), function (o) {
			if (OutcomeTools.isSuccess(o)) {
				_gthis.equipments.collection.refresh();
			};
			return o;
		});
	}
	removeEquipments(equipmentIds) {
		var _gthis = this;
		var api = this.remote.v2().projects().ofId(this.project).equipmentgroups().ofId(this.id);
		var _g = [];
		var _g1 = 0;
		while (_g1 < equipmentIds.length) _g.push(api.removeEquipment(equipmentIds[_g1++]));
		return Future.map(Promise__1.inParallel(_g), function (o) {
			if (OutcomeTools.isSuccess(o)) {
				_gthis.equipments.collection.refresh();
			};
			return o;
		});
	}
	update(data) {
		return Promise__1.noise(Promise__1.inParallel([Promise__1.noise(this._data.update({"name": data.name})), Promise__1.noise(this.remote.v2().projects().ofId(this.project).equipmentgroups().ofId(this.id).contacts().set(data.contacts))]));
	}
	getPowerStats(from, to, aggregate) {
		var timezone = -new Date().getTimezoneOffset() * 60;
		return Promise__1.next(this.remote.v2().projects().ofId(this.project).equipmentgroups().ofId(this.id).equipments().statistics().getEnergy({"from": from, "to": to, "resolution": aggregate, "timezone": timezone}), function (pair) {
			var dates = pair.a;
			var _g = new StringMap();
			var map = pair.b;
			var _g1_keys = map.keys();
			while (_g1_keys.hasNext()) {
				var key = _g1_keys.next();
				var _g2_value = map.get(key);
				var _g1 = [];
				var _g4_current = 0;
				var _g4_array = dates;
				while (_g4_current < _g4_array.length) {
					var _g5_value = _g4_array[_g4_current];
					var duration = AggregationType.getDuration(aggregate, from, to, _g5_value, timezone) * 0.001;
					var _g2 = _g2_value[_g4_current++];
					_g1.push({"date": _g5_value, "power": (_g2 == null) ? 0 : _g2 * 3600000 / duration, "duration": duration});
				};
				_g.inst.set(key, _g1);
			};
			return new SyncFuture(new LazyConst(Outcome.Success(_g)));
		});
	}
	getTemperature(from, to, aggregate) {
		var this1 = -new Date().getTimezoneOffset() * 60;
		return Promise__1.next(this.remote.v2().projects().ofId(this.project).equipmentgroups().ofId(this.id).equipments().statistics().getTemperature({"from": from, "to": to, "resolution": aggregate, "timezone": this1}), function (stats) {
			var _g = new StringMap();
			var this1 = stats.b;
			var _g1_keys = this1.keys();
			while (_g1_keys.hasNext()) {
				var key = _g1_keys.next();
				var _g2_value = this1.get(key);
				var _g1 = [];
				var _this = stats.a;
				var _g4_current = 0;
				while (_g4_current < _this.length) _g1.push({"date": _this[_g4_current++], "temperature": _g2_value[_g4_current - 1]});
				_g.inst.set(key, _g1);
			};
			return new SyncFuture(new LazyConst(Outcome.Success(_g)));
		});
	}
	getStats(from, to, aggregate) {
		var __obj_temperatureStats;
		var __obj_powerStats = this.getPowerStats(from, to, aggregate);
		__obj_temperatureStats = this.getTemperature(from, to, aggregate);
		return Future.irreversible(function (cb) {
			var __ctx = new PromisesContainer(cb, 2);
			__obj_powerStats.handle(__ctx.handle(function (r, v) {
				r.powerStats = v;
			}));
			__obj_temperatureStats.handle(__ctx.handle(function (r, v) {
				r.temperatureStats = v;
			}));
		});
	}
	set_revision(param) {
		this._updatePerformed.handlers.invoke({"revision": param});
		this.__coco_revision.set(param);
		return param;
	}
	static get __name__() {
		return "dasloop.app.data.EquipmentgroupData"
	}
	static get __interfaces__() {
		return [Model]
	}
	get __class__() {
		return EquipmentgroupData
	}
}

