import {Register} from "./genes/Register.js"
import {Declarations} from "./cix/css/Declarations.js"

const $global = Register.$global

export const Cix2660984a0b08f15c7b0d7d27f7c568471910bb0f9938615644c9602dac159f3e = Register.global("$hxClasses")["Cix2660984a0b08f15c7b0d7d27f7c568471910bb0f9938615644c9602dac159f3e"] = 
class Cix2660984a0b08f15c7b0d7d27f7c568471910bb0f9938615644c9602dac159f3e extends Register.inherits() {
	new() {
		this.css = Declarations.add("cxvc", function () {
			return "\n\n@media (min-width: 992px) {\n  .cxvc {\n    display: none !important;\n  }\n}";
		});
	}
	static get __name__() {
		return "Cix2660984a0b08f15c7b0d7d27f7c568471910bb0f9938615644c9602dac159f3e"
	}
	get __class__() {
		return Cix2660984a0b08f15c7b0d7d27f7c568471910bb0f9938615644c9602dac159f3e
	}
}


Cix2660984a0b08f15c7b0d7d27f7c568471910bb0f9938615644c9602dac159f3e.inst = new Cix2660984a0b08f15c7b0d7d27f7c568471910bb0f9938615644c9602dac159f3e()