import {RemoteBase, RemoteEndpoint, ResponseReader} from "./Remote.js"
import {Single} from "../../streams/Stream.js"
import {Builder9} from "../../querystring/Builder9.js"
import {Builder8} from "../../querystring/Builder8.js"
import {Parser50} from "../../json/Parser50.js"
import {Parser49} from "../../json/Parser49.js"
import {HeaderField, HeaderValue} from "../../http/Header.js"
import {LazyConst} from "../../core/Lazy.js"
import {ByteChunk} from "../../chunk/ByteChunk.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote64 = Register.global("$hxClasses")["tink.web.proxy.Remote64"] = 
class Remote64 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	readingPerHourPerWorker(query) {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["reading_per_hour_per_worker"], "query": [].concat(new Builder8().stringify({"_215": query.from, "_216": query.to})), "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser49())));
	}
	alertCountPerHour(query) {
		var __body__ = ByteChunk.of(Bytes.ofString(""));
		return RemoteEndpoint.request(RemoteEndpoint.sub(this.endpoint, {"path": ["alert_count_per_hour"], "query": [].concat(new Builder9().stringify({"_217": query.from, "_218": query.to})), "headers": [new HeaderField("content-length", HeaderValue.ofInt(__body__.getLength())), new HeaderField("accept", "application/json")].concat([])}), this.client, "GET", new Single(new LazyConst(__body__)), ResponseReader.ofStringReader((o=>Register.bind(o, o.pd))(new Parser50())));
	}
	static get __name__() {
		return "tink.web.proxy.Remote64"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote64
	}
}

