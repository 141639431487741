import {BasicParser, JsonString} from "./Parser.js"
import {TypedError} from "../core/Error.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Parser74 = Register.global("$hxClasses")["tink.json.Parser74"] = 
class Parser74 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	pb() {
		var _gthis = this;
		var v_date = null;
		var hasv_date = false;
		var v_source = null;
		var hasv_source = false;
		var v_title = null;
		var hasv_title = false;
		var v_url = null;
		var hasv_url = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "date":
						v_date = new Date(JsonString.f(this.r()));
						hasv_date = true;
						break
					case "source":
						v_source = this.pc();
						hasv_source = true;
						break
					case "title":
						v_title = JsonString.s(this.m());
						hasv_title = true;
						break
					case "url":
						v_url = JsonString.s(this.m());
						hasv_url = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"date": (hasv_date) ? v_date : __missing__("date"), "source": (hasv_source) ? v_source : __missing__("source"), "title": (hasv_title) ? v_title : __missing__("title"), "url": (hasv_url) ? v_url : __missing__("url")};
	}
	pc() {
		var _gthis = this;
		var v_name = null;
		var hasv_name = false;
		var v_url = null;
		var hasv_url = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "name":
						v_name = JsonString.s(this.m());
						hasv_name = true;
						break
					case "url":
						v_url = JsonString.s(this.m());
						hasv_url = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"name": (hasv_name) ? v_name : __missing__("name"), "url": (hasv_url) ? v_url : __missing__("url")};
	}
	pd(source) {
		if (this.e.length > 0) {
			this.e = [];
		};
		this.g(source);
		this.F("[");
		var __ret = [];
		if (!this.E("]")) {
			while (true) {
				__ret.push(this.pb());
				if (!this.E(",")) {
					break;
				};
			};
			this.F("]");
		};
		var ret = __ret;
		var _g = 0;
		var _g1 = this.e;
		while (_g < _g1.length) _g1[_g++]();
		if (this.e.length > 0) {
			this.e = [];
		};
		return ret;
	}
	pe(source) {
		var _gthis = this;
		return TypedError.catchExceptions(function () {
			var ret = _gthis.pd(source);
			_gthis.h();
			if (_gthis.c < _gthis.d) {
				_gthis.D("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser74", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser74"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser74
	}
}

