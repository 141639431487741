import {CompoundChunk} from "./chunk/CompoundChunk.js"
import {ChunkObject} from "./chunk/ChunkObject.js"
import {ChunkBase} from "./chunk/ChunkBase.js"
import {Bytes} from "../haxe/io/Bytes.js"
import {Register} from "../genes/Register.js"

const $global = Register.$global

export const EmptyChunk = Register.global("$hxClasses")["tink._Chunk.EmptyChunk"] = 
class EmptyChunk extends Register.inherits(ChunkBase) {
	new() {
	}
	getLength() {
		return 0;
	}
	blitTo(target, offset) {
	}
	toString() {
		return "";
	}
	toBytes() {
		return EmptyChunk.EMPTY;
	}
	static get __name__() {
		return "tink._Chunk.EmptyChunk"
	}
	static get __interfaces__() {
		return [ChunkObject]
	}
	static get __super__() {
		return ChunkBase
	}
	get __class__() {
		return EmptyChunk
	}
}


EmptyChunk.EMPTY = new Bytes(new ArrayBuffer(0))
export const Chunk = Register.global("$hxClasses")["tink._Chunk.Chunk"] = 
class Chunk {
	static concat(this1, that) {
		return CompoundChunk.cons(this1, that);
	}
	static get __name__() {
		return "tink._Chunk.Chunk_Impl_"
	}
	get __class__() {
		return Chunk
	}
}


Register.createStatic(Chunk, "EMPTY", function () { return new EmptyChunk() })