import {Register} from "../../../genes/Register.js"
import {AggregationType} from "../../types/AggregationType.js"
import {Lambda} from "../../../Lambda.js"

const $global = Register.$global

export const DaspowerTools = Register.global("$hxClasses")["dasloop.app.data.DaspowerTools"] = 
class DaspowerTools {
	static sumEnergy(stats) {
		return Lambda.fold(stats, function (v, r) {
			return r + v;
		}, 0);
	}
	static toPowerChartData(from, to, aggregate, timezone, data) {
		var _g = [];
		var _g1 = 0;
		var _g2 = data;
		while (_g1 < _g2.length) {
			var pair = _g2[_g1];
			++_g1;
			var start = pair.a;
			var energy = pair.b;
			var duration = AggregationType.getDuration(aggregate, from, to, start, timezone) * 0.001;
			_g.push({"date": start, "power": energy * 3600000 / duration, "duration": duration});
		};
		return _g;
	}
	static get __name__() {
		return "dasloop.app.data.DaspowerTools"
	}
	get __class__() {
		return DaspowerTools
	}
}

