import {BaseClient} from "./BaseClient.js"
import {Topic} from "../Topic.js"
import {Message} from "../Message.js"
import {Subscription} from "../Client.js"
import {Outcome} from "../../../tink/core/Outcome.js"
import {LazyConst} from "../../../tink/core/Lazy.js"
import {SyncFuture, SuspendableFuture} from "../../../tink/core/Future.js"
import {TypedError} from "../../../tink/core/Error.js"
import {SimpleLink, CallbackLink} from "../../../tink/core/Callback.js"
import {ByteChunk} from "../../../tink/chunk/ByteChunk.js"
import {Bytes} from "../../../haxe/io/Bytes.js"
import {Timer} from "../../../haxe/Timer.js"
import {Exception} from "../../../haxe/Exception.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const MqttJsClient = Register.global("$hxClasses")["why.mqtt.client.MqttJsClient"] = 
class MqttJsClient extends Register.inherits(BaseClient) {
	new(config) {
		super.new();
		this.config = config;
	}
	doConnect() {
		var _gthis = this;
		if (this["native"] != null) {
			return new SyncFuture(new LazyConst(Outcome.Failure(new TypedError(409, "Already attempted to connect", {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/why-mqtt/0.0.0/github/faa1984e85120a0680809ae8cb093b0000e303a4/src/why/mqtt/client/MqttJsClient.hx", "lineNumber": 20, "className": "why.mqtt.client.MqttJsClient", "methodName": "doConnect"}))));
		} else {
			var f = function (resolve, reject) {
				try {
					var _gthis1 = _gthis.config.url;
					var _gthis2 = _gthis.config.keepAlive;
					var _gthis3 = _gthis.config.clientId;
					var _gthis4 = _gthis.config.version;
					var _gthis5 = _gthis.config.cleanSession;
					var _gthis6 = _gthis.config.username;
					var _gthis7 = _gthis.config.password;
					var _g = _gthis.config.reconnectPeriod;
					var _g1 = _gthis.config.willMessage;
					var f = (_g1 == null) ? null : {"topic": _g1.topic, "payload": new Uint8Array(_g1.payload.toBytes().b.bufferValue), "qos": _g1.qos, "retain": _g1.retain};
					_gthis["native"] = mqtt.connect(_gthis1, {"keepalive": _gthis2, "clientId": _gthis3, "protocolVersion": _gthis4, "clean": _gthis5, "username": _gthis6, "password": _gthis7, "reconnectPeriod": (_g == null) ? 0 : _g, "will": f});
					var autoReconnect = _gthis.config.reconnectPeriod > 0;
					var initBindings = null;
					var onConnect = function (o) {
						var _g = resolve;
						var a1 = {"sessionPresent": o.sessionPresent};
						Timer.delay(function () {
							_g(a1);
						}, 0);
						if (initBindings != null) {
							initBindings.cancel();
						};
						var bindings = null;
						var onClose = function () {
							_gthis.disconnectedTrigger.handlers.invoke(null);
							if (!autoReconnect) {
								if (bindings != null) {
									bindings.cancel();
								};
							};
						};
						_gthis["native"].addListener("close", onClose);
						var onMessage = function (topic, payload, packet) {
							var _this = _gthis.messageReceivedTrigger;
							var event = new Message(topic, ByteChunk.of(Bytes.ofData(payload.buffer.slice(payload.byteOffset, payload.byteOffset + payload.byteLength))), packet.qos, packet.retain);
							_this.handlers.invoke(event);
						};
						_gthis["native"].addListener("message", onMessage);
						var _g1 = (o=>Register.bind(o, o.removeListener))(_gthis["native"]);
						var event = "close";
						var f = onClose;
						var this1 = new SimpleLink(function () {
							_g1(event, f);
						});
						var _g2 = (o=>Register.bind(o, o.removeListener))(_gthis["native"]);
						var event1 = "message";
						var f1 = onMessage;
						bindings = CallbackLink.fromMany([this1, new SimpleLink(function () {
							_g2(event1, f1);
						})]);
					};
					_gthis["native"].once("connect", onConnect);
					var onConnectFail = function (err) {
						if (!autoReconnect) {
							if (initBindings != null) {
								initBindings.cancel();
							};
							reject(TypedError.withData(500, err.message, err, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/why-mqtt/0.0.0/github/faa1984e85120a0680809ae8cb093b0000e303a4/src/why/mqtt/client/MqttJsClient.hx", "lineNumber": 74, "className": "why.mqtt.client.MqttJsClient", "methodName": "doConnect"}));
						};
					};
					_gthis["native"].once("error", onConnectFail);
					var _g = (o=>Register.bind(o, o.removeListener))(_gthis["native"]);
					var event = "connect";
					var f = onConnect;
					var this1 = new SimpleLink(function () {
						_g(event, f);
					});
					var _g1 = (o=>Register.bind(o, o.removeListener))(_gthis["native"]);
					var event1 = "error";
					var f1 = onConnectFail;
					initBindings = CallbackLink.fromMany([this1, new SimpleLink(function () {
						_g1(event1, f1);
					})]);
				}catch (_g2) {
					var _g3 = Exception.caught(_g2);
					reject(TypedError.withData(null, "Native driver failed to connect", _g3, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/why-mqtt/0.0.0/github/faa1984e85120a0680809ae8cb093b0000e303a4/src/why/mqtt/client/MqttJsClient.hx", "lineNumber": 83, "className": "why.mqtt.client.MqttJsClient", "methodName": "doConnect"}));
				};
				return null;
			};
			return new SuspendableFuture(function (cb) {
				return f(function (v) {
					cb(Outcome.Success(v));
				}, function (e) {
					cb(Outcome.Failure(e));
				});
			});
		};
	}
	doSubscribe(topic, options) {
		var _gthis = this;
		var f = function (resolve, reject) {
			_gthis["native"].subscribe(topic, options, function (err, granted) {
				if (err != null) {
					reject(TypedError.withData(500, err.message, err, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/why-mqtt/0.0.0/github/faa1984e85120a0680809ae8cb093b0000e303a4/src/why/mqtt/client/MqttJsClient.hx", "lineNumber": 109, "className": "why.mqtt.client.MqttJsClient", "methodName": "doSubscribe"}));
				} else {
					var _g = granted[0];
					if (_g == null) {
						reject(new TypedError(null, "Failed to subscribe (no grant)", {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/why-mqtt/0.0.0/github/faa1984e85120a0680809ae8cb093b0000e303a4/src/why/mqtt/client/MqttJsClient.hx", "lineNumber": 113, "className": "why.mqtt.client.MqttJsClient", "methodName": "doSubscribe"}));
					} else if (_g.qos == 128) {
						reject(new TypedError(null, "Failed to subscribe (SUBACK: 128)", {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/why-mqtt/0.0.0/github/faa1984e85120a0680809ae8cb093b0000e303a4/src/why/mqtt/client/MqttJsClient.hx", "lineNumber": 115, "className": "why.mqtt.client.MqttJsClient", "methodName": "doSubscribe"}));
					} else {
						var v = _g;
						resolve(new Subscription(function () {
							_gthis["native"].unsubscribe(v.topic);
						}, Topic.sanitize(v.topic), v.qos));
					};
				};
			});
			return null;
		};
		return new SuspendableFuture(function (cb) {
			return f(function (v) {
				cb(Outcome.Success(v));
			}, function (e) {
				cb(Outcome.Failure(e));
			});
		});
	}
	get_active() {
		return this["native"] != null;
	}
	static get __name__() {
		return "why.mqtt.client.MqttJsClient"
	}
	static get __super__() {
		return BaseClient
	}
	get __class__() {
		return MqttJsClient
	}
}

