import {Source} from "./Source.js"
import {Promise as Promise__1, Next} from "../../tink/core/Promise.js"
import {Outcome} from "../../tink/core/Outcome.js"
import {LazyConst} from "../../tink/core/Lazy.js"
import {SyncFuture} from "../../tink/core/Future.js"
import {Path} from "../../haxe/io/Path.js"
import {Register} from "../../genes/Register.js"
import {StringTools} from "../../StringTools.js"
import {EReg} from "../../EReg.js"

const $global = Register.$global

/**
* A custom extension of the Fluent FTL format.
* Supports a special "include" directive to import other .ftl files
*
* Syntax: `# @include <path> [into <prefix>]`
*
* `path` is a relative path (supports `..`) resolved against the current file
* - if ending with `.ftl` the path will be used as is
* - otherwise it is considered a directory, and the current language plus a `.ftl` extension will be appended to the path
*
* An optional prefix can be specified with the `into` syntax.
* In that case, every Fluent Message in the included file will be prefixed with the specified value plus a dash `-`,
* which is useful for nesting localizers with variables or properties while reusing existing ftl files.
*/
export const ExtendedFluentSource = Register.global("$hxClasses")["turnwing.source.ExtendedFluentSource"] = 
class ExtendedFluentSource extends Register.inherits() {
	new(path, getSource) {
		this.path = path;
		this.getSource = getSource;
	}
	fetch(language) {
		return this.load(this.path, language, [], []);
	}
	load(path, lang, prefixes, rootIncludes) {
		var _gthis = this;
		return Promise__1.next(this.getSource(function (language) {
			if (StringTools.endsWith(path, ".ftl")) {
				return path;
			} else {
				return Path.join([path, "" + language + ".ftl"]);
			};
		}).fetch(lang), function (src) {
			return _gthis.follow(src, path, lang, prefixes, rootIncludes);
		});
	}
	follow(source, path, lang, prefixes, rootIncludes) {
		var _gthis = this;
		var regex = new EReg("^# @include ([^ ]+)( into (\\w+))?$", "");
		var promises = [];
		var start = 0;
		var end = 0;
		var add = function (s, e) {
			if (e == null) {
				e = source.length;
			};
			var matched = regex.match(StringTools.trim(source.substring(s, e)));
			if (matched) {
				var rel = StringTools.trim(regex.matched(1));
				if (!StringTools.endsWith(rel, ".ftl")) {
					rel = Path.join([rel, "" + lang + ".ftl"]);
				};
				var newPrefixes;
				var _g = regex.matched(3);
				if (_g == null) {
					if (rootIncludes.indexOf(rel) == -1) {
						rootIncludes.push(rel);
					} else {
						return true;
					};
					newPrefixes = prefixes;
				} else {
					newPrefixes = prefixes.concat([_g]);
				};
				var sections = (StringTools.endsWith(path, ".ftl")) ? Path.directory(path) : path;
				var promises1 = promises;
				var add = _gthis.load(Path.normalize(Path.join([sections, rel])), lang, prefixes, rootIncludes);
				var _g = Register.bind(_gthis, _gthis.appendPrefixes);
				var prefixes1 = newPrefixes;
				promises1.push(Promise__1.next(add, Next.ofSafeSync(function (source) {
					return _g(source, prefixes1);
				})));
			};
			return matched;
		};
		var ended = false;
		while (true) {
			end = source.indexOf("\n", start + 1);
			if (!(end != -1)) {
				break;
			};
			var added = add(start, end);
			start = end;
			if (!added) {
				ended = true;
				break;
			};
		};
		if (!ended) {
			add(start);
		};
		return Promise__1.next(Promise__1.inParallel(promises), function (list) {
			return new SyncFuture(new LazyConst(Outcome.Success(source + "\n" + list.join("\n"))));
		});
	}
	appendPrefixes(source, prefixes) {
		if (prefixes.length == 0) {
			return source;
		};
		var syntax = (require)("@fluent/syntax");
		var resource = syntax.parse(source);
		var _g = 0;
		var _g1 = resource.body;
		while (_g < _g1.length) {
			var entry = _g1[_g];
			++_g;
			if (((entry) instanceof syntax.Message)) {
				entry.id = new syntax.Identifier(prefixes.concat([entry.id.name]).join("-"));
			};
		};
		return syntax.serialize(resource);
	}
	static get __name__() {
		return "turnwing.source.ExtendedFluentSource"
	}
	static get __interfaces__() {
		return [Source]
	}
	get __class__() {
		return ExtendedFluentSource
	}
}

