import {Writer6} from "./Writer6.js"
import {Writer1} from "./Writer1.js"
import {BasicParser, JsonString} from "./Parser.js"
import {TypedError} from "../core/Error.js"
import {Exception} from "../../haxe/Exception.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Parser72 = Register.global("$hxClasses")["tink.json.Parser72"] = 
class Parser72 extends Register.inherits(BasicParser) {
	new() {
		super.new();
	}
	pb() {
		var _gthis = this;
		var v_email = null;
		var hasv_email = false;
		var v_hidden = false;
		var hasv_hidden = false;
		var v_id = null;
		var hasv_id = false;
		var v_role = null;
		var hasv_role = false;
		var v_status = 0;
		var hasv_status = false;
		var __start__ = this.c;
		while (true) {
			var _g = this.w();
			var _hx_tmp;
			if (_g == 123 == true) {
				break;
			} else {
				_hx_tmp = _g < 33;
				if (_hx_tmp != true) {
					this.D("expected " + "{");
				};
			};
		};
		if (!this.E("}")) {
			while (true) {
				var lhs = JsonString.s(this.m());
				this.F(":");
				switch (lhs) {
					case "email":
						v_email = JsonString.s(this.m());
						hasv_email = true;
						break
					case "hidden":
						v_hidden = this.G();
						hasv_hidden = true;
						break
					case "id":
						v_id = JsonString.s(this.m());
						hasv_id = true;
						break
					case "role":
						var v = JsonString.s(this.m());
						switch (v) {
							case "admin":case "editor":case "owner":case "viewer":
								v_role = v;
								break
							default:
							throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v + ". Accepted values are: " + new Writer1().wb(["owner", "admin", "editor", "viewer"]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 469, "className": "tink.json.Parser72", "methodName": "process0"}));
							
						};
						hasv_role = true;
						break
					case "status":
						var v1 = JsonString.i(this.r());
						switch (v1) {
							case 0:case 1:
								v_status = v1;
								break
							default:
							throw Exception.thrown(new TypedError(422, "Unrecognized enum value: " + v1 + ". Accepted values are: " + new Writer6().wb([0, 1]), {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/GenReader.hx", "lineNumber": 469, "className": "tink.json.Parser72", "methodName": "process0"}));
							
						};
						hasv_status = true;
						break
					default:
					this.B();
					
				};
				if (!this.E(",", true, false)) {
					break;
				};
			};
			this.F("}");
		};
		var __missing__ = function (field) {
			return _gthis.D("missing field \"" + field + "\"", __start__);
		};
		return {"email": (hasv_email) ? v_email : __missing__("email"), "hidden": (hasv_hidden) ? v_hidden : __missing__("hidden"), "id": (hasv_id) ? v_id : __missing__("id"), "role": (hasv_role) ? v_role : __missing__("role"), "status": (hasv_status) ? v_status : __missing__("status")};
	}
	pc(source) {
		if (this.e.length > 0) {
			this.e = [];
		};
		this.g(source);
		var ret = this.pb();
		var _g = 0;
		var _g1 = this.e;
		while (_g < _g1.length) _g1[_g++]();
		if (this.e.length > 0) {
			this.e = [];
		};
		return ret;
	}
	pd(source) {
		var _gthis = this;
		return TypedError.catchExceptions(function () {
			var ret = _gthis.pc(source);
			_gthis.h();
			if (_gthis.c < _gthis.d) {
				_gthis.D("Invalid data after JSON document");
			};
			return ret;
		}, null, {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/tink_json/0.11.0/github/7dd309a8c4153eb7d89ab05d34d2fb7037b83681/src/tink/json/macros/Macro.hx", "lineNumber": 107, "className": "tink.json.Parser72", "methodName": "tryParse"});
	}
	static get __name__() {
		return "tink.json.Parser72"
	}
	static get __super__() {
		return BasicParser
	}
	get __class__() {
		return Parser72
	}
}

