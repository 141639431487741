import {FluentLocaleBase} from "./FluentProvider.js"
import {Register} from "../../genes/Register.js"
import {FooterLocalizer} from "../../dasloop/ui/localizer/FooterLocalizer.js"

const $global = Register.$global

export const FluentLocale0 = Register.global("$hxClasses")["turnwing.provider.FluentLocale0"] = 
class FluentLocale0 extends Register.inherits(FluentLocaleBase) {
	new(__bundle__, __prefix__) {
		super.new(__bundle__, __prefix__);
	}
	contactUs() {
		return this.__exec__("contactUs", {});
	}
	productUrl() {
		return this.__exec__("productUrl", {});
	}
	static get __name__() {
		return "turnwing.provider.FluentLocale0"
	}
	static get __interfaces__() {
		return [FooterLocalizer]
	}
	static get __super__() {
		return FluentLocaleBase
	}
	get __class__() {
		return FluentLocale0
	}
}

