import {BaseClient} from "./BaseClient.js"
import {Subscription} from "../Client.js"
import {Signal} from "../../../tink/core/Signal.js"
import {Promise as Promise__1} from "../../../tink/core/Promise.js"
import {Outcome} from "../../../tink/core/Outcome.js"
import {LazyConst} from "../../../tink/core/Lazy.js"
import {SyncFuture, Future} from "../../../tink/core/Future.js"
import {TypedError} from "../../../tink/core/Error.js"
import {CallbackLink, LinkPair, SimpleLink} from "../../../tink/core/Callback.js"
import {Register} from "../../../genes/Register.js"
import {HxOverrides} from "../../../HxOverrides.js"

const $global = Register.$global

export const KeepAliveClient = Register.global("$hxClasses")["why.mqtt.client.KeepAliveClient"] = 
class KeepAliveClient extends Register.inherits(BaseClient) {
	new(makeClient) {
		this.subscriptions = [];
		super.new();
		this.makeClient = makeClient;
		this.reconnected = this.reconnectedTrigger = Signal.trigger();
	}
	doConnect() {
		var _gthis = this;
		this.disconnecting = false;
		var abort = function () {
			_gthis.client = null;
			return new TypedError(410, "Closed", {"fileName": "/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/why-mqtt/0.0.0/github/faa1984e85120a0680809ae8cb093b0000e303a4/src/why/mqtt/client/KeepAliveClient.hx", "lineNumber": 57, "className": "why.mqtt.client.KeepAliveClient", "methodName": "doConnect"});
		};
		var tryConnect = null;
		tryConnect = function (delay, reconnecting) {
			if (delay == null) {
				delay = 100;
			};
			if (reconnecting == null) {
				reconnecting = false;
			};
			console.log("" + HxOverrides.dateStr(new Date()) + ": [KeepAliveClient] " + ("try connect: " + delay + ", " + ((reconnecting == null) ? "null" : "" + reconnecting)));
			if (_gthis.disconnecting) {
				return new SyncFuture(new LazyConst(Outcome.Failure(abort())));
			} else {
				return Promise__1.next(_gthis.client = _gthis.makeClient(), function (c) {
					if (_gthis.disconnecting) {
						return new SyncFuture(new LazyConst(Outcome.Failure(abort())));
					} else {
						var f = function (e) {
							if (e.code == 410) {
								return new SyncFuture(new LazyConst(Outcome.Failure(e)));
							} else {
								var nextDelay = delay * 2;
								if (nextDelay > 60000) {
									nextDelay = 60000;
								};
								return Future.flatMap(Future.delay(nextDelay, new LazyConst(null)), function (_) {
									return tryConnect(nextDelay, reconnecting);
								});
							};
						};
						return Future.flatMap(Promise__1.next(c.connect(), function (connack) {
							if (_gthis.disconnecting) {
								return new SyncFuture(new LazyConst(Outcome.Failure(abort())));
							} else {
								if (reconnecting) {
									_gthis.reconnectedTrigger.handlers.invoke(connack);
								};
								var this1 = c.messageReceived.listen((o=>Register.bind(o, o.trigger))(_gthis.messageReceivedTrigger));
								var this2 = Signal.nextTime(c.disconnected).handle(function (_) {
									var this1 = _gthis.binding;
									if (this1 != null) {
										this1.cancel();
									};
									var this1 = tryConnect(null, true).handle(function () {
									});
									_gthis.binding = this1;
								});
								_gthis.binding = CallbackLink.fromMany([this1, this2]);
								if (!connack.sessionPresent) {
									var v = connack;
									return Promise__1.next(_gthis.resubscribe(), function (_) {
										return new SyncFuture(new LazyConst(Outcome.Success(v)));
									});
								} else {
									return new SyncFuture(new LazyConst(Outcome.Success(connack)));
								};
							};
						}), function (o) {
							switch (o._hx_index) {
								case 0:
									return new SyncFuture(new LazyConst(o));
									break
								case 1:
									return f(o.failure);
									break
								
							};
						});
					};
				});
			};
		};
		return tryConnect();
	}
	doSubscribe(topic, options) {
		var _gthis = this;
		var subscription = {"topic": topic, "options": options};
		this.subscriptions.push(subscription);
		return Promise__1.next(Promise__1.next(this.client, function (c) {
			return c.subscribe(topic, options);
		}), function (sub) {
			var this1 = new LinkPair(sub, new SimpleLink(function () {
				return HxOverrides.remove(_gthis.subscriptions, subscription);
			}));
			return new SyncFuture(new LazyConst(Outcome.Success(new Subscription((this1 == null) ? CallbackLink.noop : Register.bind(this1, this1.cancel), sub.topic, sub.qos))));
		});
	}
	resubscribe() {
		var _gthis = this;
		return Promise__1.noise(Promise__1.next(this.client, function (c) {
			var _g = [];
			var _g1 = 0;
			var _g2 = _gthis.subscriptions;
			while (_g1 < _g2.length) {
				var sub = _g2[_g1];
				++_g1;
				_g.push(c.subscribe(sub.topic, sub.options));
			};
			return Promise__1.inParallel(_g);
		}));
	}
	get_active() {
		return this.client != null;
	}
	static get __name__() {
		return "why.mqtt.client.KeepAliveClient"
	}
	static get __super__() {
		return BaseClient
	}
	get __class__() {
		return KeepAliveClient
	}
}

