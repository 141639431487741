import {State} from "../../tink/state/State.js"
import {Observable} from "../../tink/state/Observable.js"
import {DateInput, DateTimeInput} from "semantic-ui-calendar-react"
import {ReactType} from "../../react/ReactType.js"
import * as React from "react"
import {Register} from "../../genes/Register.js"
import {Slot} from "../../coconut/ui/internal/Slot.js"
import {View} from "../../coconut/react/View.js"

const $global = Register.$global

export const DatePicker2 = Register.global("$hxClasses")["dasloop.ui.DatePicker2"] = 
class DatePicker2 extends Register.inherits(View) {
	new(__coco_data_) {
		var _gthis = this;
		this.__coco_className = new Slot(this, null, null);
		this.__coco_date = new Slot(this, null, null);
		this.__coco_maxDate = new Slot(this, null, null);
		this.__coco_minDate = new Slot(this, null, null);
		this.__initAttributes(__coco_data_);
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, null);
	}
	__coco_render() {
		var _gthis = this;
		switch (Observable.get_value(this.__coco_date).mode) {
			case 0:
				var props = {"className": Observable.get_value(this.__coco_className), "dateFormat": "YYYY-MM-DD", "value": State.get_value(Observable.get_value(this.__coco_date).__coco_input), "animation": "none", "duration": 0, "clearable": true, "closable": true, "maxDate": Observable.get_value(this.__coco_maxDate), "minDate": Observable.get_value(this.__coco_minDate), "preserveViewMode": false, "onChange": function (event, data) {
					console.log("/builds/dasloop/dasloop/.cache/haxe//haxe_libraries/dasloop-ui/0.0.0/git/6fa8443e2144bac1a02e0b9fd66593eb77a24a59/src/dasloop/ui/DatePicker2.hx:28:",data.value);
					Observable.get_value(_gthis.__coco_date).set_input(data.value);
					Observable.updateAll();
				}};
				var tmp = [ReactType.fromComp(DateInput), props].concat(null);
				return React.createElement.apply(React, tmp);
				break
			case 1:
				var props = {"className": Observable.get_value(this.__coco_className), "dateTimeFormat": "YYYY-MM-DD HH:mm:ss", "value": State.get_value(Observable.get_value(this.__coco_date).__coco_input), "animation": "none", "duration": 0, "clearable": true, "closable": true, "maxDate": Observable.get_value(this.__coco_maxDate), "minDate": Observable.get_value(this.__coco_minDate), "preserveViewMode": false, "onChange": function (event, data) {
					Observable.get_value(_gthis.__coco_date).set_input(data.value);
					Observable.updateAll();
				}};
				var tmp = [ReactType.fromComp(DateTimeInput), props].concat(null);
				return React.createElement.apply(React, tmp);
				break
			
		};
	}
	__initAttributes(attributes) {
		this.__coco_className.setData(attributes.className);
		this.__coco_date.setData(attributes.date);
		this.__coco_maxDate.setData(attributes.maxDate);
		this.__coco_minDate.setData(attributes.minDate);
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": DatePicker2, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "dasloop.ui.DatePicker2"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return DatePicker2
	}
}

