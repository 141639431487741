import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const TaskStatus = 
Register.global("$hxEnums")["dasloop.types.task.TaskStatus"] = 
{
	__ename__: "dasloop.types.task.TaskStatus",
	
	Pending: {_hx_name: "Pending", _hx_index: 0, __enum__: "dasloop.types.task.TaskStatus"},
	Finished: Object.assign((date, result) => ({_hx_index: 1, __enum__: "dasloop.types.task.TaskStatus", "date": date, "result": result}), {_hx_name: "Finished", __params__: ["date", "result"]}),
	Cancelled: Object.assign((date) => ({_hx_index: 2, __enum__: "dasloop.types.task.TaskStatus", "date": date}), {_hx_name: "Cancelled", __params__: ["date"]}),
	Expired: Object.assign((date) => ({_hx_index: 3, __enum__: "dasloop.types.task.TaskStatus", "date": date}), {_hx_name: "Expired", __params__: ["date"]})
}
TaskStatus.__constructs__ = [TaskStatus.Pending, TaskStatus.Finished, TaskStatus.Cancelled, TaskStatus.Expired]
TaskStatus.__empty_constructs__ = [TaskStatus.Pending]
