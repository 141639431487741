import {AutoObservable, Computation} from "../../tink/state/internal/AutoObservable.js"
import {Scheduler} from "../../tink/state/Scheduler.js"
import {ConstObservable, Observable} from "../../tink/state/Observable.js"
import {ClassName} from "../../tink/domspec/ClassName.js"
import {Register} from "../../genes/Register.js"
import {Slot} from "../../coconut/ui/internal/Slot.js"
import {View} from "../../coconut/react/View.js"
import {Html} from "../../coconut/react/Html.js"

const $global = Register.$global

export const RangeSlider = Register.global("$hxClasses")["dasloop.ui.RangeSlider"] = 
class RangeSlider extends Register.inherits(View) {
	new(__coco_data_) {
		var _gthis = this;
		this.__coco_className = new Slot(this, null, new ConstObservable(""));
		this.__coco_min = new Slot(this, null, null);
		this.__coco_max = new Slot(this, null, null);
		this.__coco_step = new Slot(this, null, new ConstObservable(0.1));
		this.__coco_lo = new Slot(this, null, null);
		this.__coco_hi = new Slot(this, null, null);
		this.__initAttributes(__coco_data_);
		super.new(function () {
			return _gthis.__coco_render();
		}, null, null, null, function (firstTime) {
			if (firstTime) {
				_gthis.viewDidMount();
			};
		});
	}
	__coco_render() {
		return Html.h("div", {"ref": Register.bind(this, this._coco_set_ref), "className": ClassName.ofString("ui range slider " + Observable.get_value(this.__coco_className))}, null);
	}
	viewDidMount() {
		var _gthis = this;
		var c = Observable.bind(new AutoObservable(Computation.sync(function () {
			return {"min": Observable.get_value(_gthis.__coco_min), "max": Observable.get_value(_gthis.__coco_max), "step": Observable.get_value(_gthis.__coco_step)};
		}), null), function (config) {
			$(_gthis.ref).slider({ min : config.min, max : config.max, start : Observable.get_value(_gthis.__coco_lo), end : Observable.get_value(_gthis.__coco_hi), step : config.step, onMove : function(v,thumb1,thumb2) {
	Scheduler.atomically(function() {
		_gthis.set_lo(thumb1);
		_gthis.set_hi(thumb2);
	});
}});
		});
		this.__bu.push(c);
		var c = Observable.bind(new AutoObservable(Computation.sync(function () {
			return {"lo": Observable.get_value(_gthis.__coco_lo), "hi": Observable.get_value(_gthis.__coco_hi)};
		}), null), function (v) {
			$(_gthis.ref).slider('set rangeValue', v.lo, v.hi, false);
		});
		this.__bu.push(c);
	}
	set_lo(param) {
		var _g = this.__coco_lo.data;
		if (_g != null) {
			_g.set(param);
		};
		return param;
	}
	set_hi(param) {
		var _g = this.__coco_hi.data;
		if (_g != null) {
			_g.set(param);
		};
		return param;
	}
	_coco_set_ref(param) {
		this.ref = param;
	}
	__initAttributes(attributes) {
		this.__coco_className.setData(attributes.className);
		this.__coco_min.setData(attributes.min);
		this.__coco_max.setData(attributes.max);
		this.__coco_step.setData(attributes.step);
		this.__coco_lo.setData(attributes.lo);
		this.__coco_hi.setData(attributes.hi);
	}
	static fromHxx(hxxMeta, attributes) {
		return {"$$typeof": View.TRE, "type": RangeSlider, "props": attributes, "key": hxxMeta.key, "ref": (!hxxMeta.ref) ? null : hxxMeta.ref};
	}
	static get __name__() {
		return "dasloop.ui.RangeSlider"
	}
	static get __super__() {
		return View
	}
	get __class__() {
		return RangeSlider
	}
}

