import {Register} from "./genes/Register.js"
import {Declarations} from "./cix/css/Declarations.js"

const $global = Register.$global

export const Cix4ca3c6833cc6fd091c514fb2e8227c39246023164ee888767c1a403c124ee9d1 = Register.global("$hxClasses")["Cix4ca3c6833cc6fd091c514fb2e8227c39246023164ee888767c1a403c124ee9d1"] = 
class Cix4ca3c6833cc6fd091c514fb2e8227c39246023164ee888767c1a403c124ee9d1 extends Register.inherits() {
	new() {
		this.css = Declarations.add("cxuc", function () {
			return ".cxuc {\n  flex-shrink: 0;\n}";
		});
	}
	static get __name__() {
		return "Cix4ca3c6833cc6fd091c514fb2e8227c39246023164ee888767c1a403c124ee9d1"
	}
	get __class__() {
		return Cix4ca3c6833cc6fd091c514fb2e8227c39246023164ee888767c1a403c124ee9d1
	}
}


Cix4ca3c6833cc6fd091c514fb2e8227c39246023164ee888767c1a403c124ee9d1.inst = new Cix4ca3c6833cc6fd091c514fb2e8227c39246023164ee888767c1a403c124ee9d1()