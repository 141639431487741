import {Register} from "./genes/Register.js"
import {Declarations} from "./cix/css/Declarations.js"

const $global = Register.$global

export const Cixf9bfd0ab06a6f10d1549c21b3dd2652f8ac55ae90dae40947e9e798924ed3285 = Register.global("$hxClasses")["Cixf9bfd0ab06a6f10d1549c21b3dd2652f8ac55ae90dae40947e9e798924ed3285"] = 
class Cixf9bfd0ab06a6f10d1549c21b3dd2652f8ac55ae90dae40947e9e798924ed3285 extends Register.inherits() {
	new() {
		this.css = Declarations.add("cxtc", function () {
			return "\n\n@media (max-width: 991px) {\n  .cxtc {\n    display: none !important;\n  }\n}";
		});
	}
	static get __name__() {
		return "Cixf9bfd0ab06a6f10d1549c21b3dd2652f8ac55ae90dae40947e9e798924ed3285"
	}
	get __class__() {
		return Cixf9bfd0ab06a6f10d1549c21b3dd2652f8ac55ae90dae40947e9e798924ed3285
	}
}


Cixf9bfd0ab06a6f10d1549c21b3dd2652f8ac55ae90dae40947e9e798924ed3285.inst = new Cixf9bfd0ab06a6f10d1549c21b3dd2652f8ac55ae90dae40947e9e798924ed3285()