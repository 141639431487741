import {BasicWriter} from "./Writer.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Writer28 = Register.global("$hxClasses")["tink.json.Writer28"] = 
class Writer28 extends Register.inherits(BasicWriter) {
	new() {
		super.new();
	}
	wb(value) {
		this.f("{");
		var value1 = value._191;
		this.e("\"name\":");
		this.j(value1);
		var value1 = value._192;
		this.f(",");
		this.e("\"position\":");
		var value = value1;
		this.f("[");
		var first = true;
		var _g = 0;
		while (_g < value.length) {
			var value1 = value[_g++];
			if (first) {
				first = false;
			} else {
				this.f(",");
			};
			this.h(value1);
		};
		this.f("]");
		this.f("}");
	}
	wc(value) {
		this.d();
		this.wb(value);
		return this.b.toString();
	}
	static get __name__() {
		return "tink.json.Writer28"
	}
	static get __super__() {
		return BasicWriter
	}
	get __class__() {
		return Writer28
	}
}

