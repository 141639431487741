import {Remote6} from "./Remote6.js"
import {Remote32} from "./Remote32.js"
import {Remote2} from "./Remote2.js"
import {Remote1} from "./Remote1.js"
import {RemoteBase, RemoteEndpoint} from "./Remote.js"
import {Register} from "../../../genes/Register.js"

const $global = Register.$global

export const Remote0 = Register.global("$hxClasses")["tink.web.proxy.Remote0"] = 
class Remote0 extends Register.inherits(RemoteBase) {
	new(client, endpoint) {
		super.new(client, endpoint);
	}
	projects() {
		return new Remote6(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["projects"], "query": [], "headers": [].concat([])}));
	}
	worker_certificates() {
		return new Remote32(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["worker_certificates"], "query": [], "headers": [].concat([])}));
	}
	me() {
		return new Remote1(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["me"], "query": [], "headers": [].concat([])}));
	}
	v2() {
		return new Remote2(this.client, RemoteEndpoint.sub(this.endpoint, {"path": ["v2"], "query": [], "headers": [].concat([])}));
	}
	static get __name__() {
		return "tink.web.proxy.Remote0"
	}
	static get __super__() {
		return RemoteBase
	}
	get __class__() {
		return Remote0
	}
}

