import {Outcome} from "./Outcome.js"
import {Exception} from "../../haxe/Exception.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const TypedError = Register.global("$hxClasses")["tink.core.TypedError"] = 
class TypedError extends Register.inherits() {
	new(code, message, pos) {
		if (code == null) {
			code = 500;
		};
		this.isTinkError = true;
		this.code = code;
		this.message = message;
		this.pos = pos;
		this.exceptionStack = [];
		this.callStack = [];
	}
	printPos() {
		return this.pos.className + "." + this.pos.methodName + ":" + this.pos.lineNumber;
	}
	toString() {
		var ret = "Error#" + this.code + ": " + this.message;
		if (this.pos != null) {
			ret += " @ " + this.printPos();
		};
		return ret;
	}
	throwSelf() {
		throw Exception.thrown(this);
	}
	static withData(code, message, data, pos) {
		return TypedError.typed(code, message, data, pos);
	}
	static typed(code, message, data, pos) {
		var ret = new TypedError(code, message, pos);
		ret.data = data;
		return ret;
	}
	static asError(v) {
		if (v != null && v.isTinkError) {
			return v;
		} else {
			return null;
		};
	}
	static catchExceptions(f, report, pos) {
		try {
			return Outcome.Success(f());
		}catch (_g) {
			var _g1 = Exception.caught(_g).unwrap();
			var e = TypedError.asError(_g1);
			return Outcome.Failure((e == null) ? (report == null) ? TypedError.withData(null, "Unexpected Error", _g1, pos) : report(_g1) : e);
		};
	}
	static tryFinally(f, cleanup) {
		try { return f(); } finally { cleanup(); };
		return null;
	}
	static get __name__() {
		return "tink.core.TypedError"
	}
	get __class__() {
		return TypedError
	}
}

