import {Error as Error__1} from "./Error.js"
import {Encoding} from "./Encoding.js"
import {Exception} from "../Exception.js"
import {Register} from "../../genes/Register.js"

const $global = Register.$global

export const Bytes = Register.global("$hxClasses")["haxe.io.Bytes"] = 
class Bytes extends Register.inherits() {
	new(data) {
		this.length = data.byteLength;
		this.b = new Uint8Array(data);
		this.b.bufferValue = data;
		data.hxBytes = this;
		data.bytes = this.b;
	}
	
	/**
	Copies `len` bytes from `src` into this instance.
	@param pos Zero-based location in `this` instance at which to start writing
	bytes.
	@param src Source `Bytes` instance from which to copy bytes.
	@param srcpos Zero-based location at `src` from which bytes will be copied.
	@param len Number of bytes to be copied.
	*/
	blit(pos, src, srcpos, len) {
		if (pos < 0 || srcpos < 0 || len < 0 || pos + len > this.length || srcpos + len > src.length) {
			throw Exception.thrown(Error__1.OutsideBounds);
		};
		if (srcpos == 0 && len == src.b.byteLength) {
			this.b.set(src.b, pos);
		} else {
			this.b.set(src.b.subarray(srcpos, srcpos + len), pos);
		};
	}
	
	/**
	Returns a new `Bytes` instance that contains a copy of `len` bytes of
	`this` instance, starting at index `pos`.
	*/
	sub(pos, len) {
		if (pos < 0 || len < 0 || pos + len > this.length) {
			throw Exception.thrown(Error__1.OutsideBounds);
		};
		return new Bytes(this.b.buffer.slice(pos + this.b.byteOffset, pos + this.b.byteOffset + len));
	}
	
	/**
	Returns the `len`-bytes long string stored at the given position `pos`,
	interpreted with the given `encoding` (UTF-8 by default).
	*/
	getString(pos, len, encoding) {
		if (pos < 0 || len < 0 || pos + len > this.length) {
			throw Exception.thrown(Error__1.OutsideBounds);
		};
		if (encoding == null) {
			encoding = Encoding.UTF8;
		};
		var s = "";
		var b = this.b;
		var i = pos;
		var max = pos + len;
		switch (encoding._hx_index) {
			case 0:
				while (i < max) {
					var c = b[i++];
					if (c < 128) {
						if (c == 0) {
							break;
						};
						s += String.fromCodePoint(c);
					} else if (c < 224) {
						var code = (c & 63) << 6 | b[i++] & 127;
						s += String.fromCodePoint(code);
					} else if (c < 240) {
						var code1 = (c & 31) << 12 | (b[i++] & 127) << 6 | b[i++] & 127;
						s += String.fromCodePoint(code1);
					} else {
						var u = (c & 15) << 18 | (b[i++] & 127) << 12 | (b[i++] & 127) << 6 | b[i++] & 127;
						s += String.fromCodePoint(u);
					};
				};
				break
			case 1:
				while (i < max) {
					var c = b[i++] | b[i++] << 8;
					s += String.fromCodePoint(c);
				};
				break
			
		};
		return s;
	}
	
	/**
	Returns a `String` representation of the bytes interpreted as UTF-8.
	*/
	toString() {
		return this.getString(0, this.length);
	}
	
	/**
	Returns the `Bytes` representation of the given `String`, using the
	specified encoding (UTF-8 by default).
	*/
	static ofString(s, encoding) {
		if (encoding == Encoding.RawNative) {
			var buf = new Uint8Array(s.length << 1);
			var _g = 0;
			var _g1 = s.length;
			while (_g < _g1) {
				var i = _g++;
				var c = s.charCodeAt(i);
				buf[i << 1] = c & 255;
				buf[i << 1 | 1] = c >> 8;
			};
			return new Bytes(buf.buffer);
		};
		var a = new Array();
		var i = 0;
		while (i < s.length) {
			var c = s.charCodeAt(i++);
			if (55296 <= c && c <= 56319) {
				c = c - 55232 << 10 | s.charCodeAt(i++) & 1023;
			};
			if (c <= 127) {
				a.push(c);
			} else if (c <= 2047) {
				a.push(192 | c >> 6);
				a.push(128 | c & 63);
			} else if (c <= 65535) {
				a.push(224 | c >> 12);
				a.push(128 | c >> 6 & 63);
				a.push(128 | c & 63);
			} else {
				a.push(240 | c >> 18);
				a.push(128 | c >> 12 & 63);
				a.push(128 | c >> 6 & 63);
				a.push(128 | c & 63);
			};
		};
		return new Bytes(new Uint8Array(a).buffer);
	}
	
	/**
	Returns the `Bytes` representation of the given `BytesData`.
	*/
	static ofData(b) {
		var hb = b.hxBytes;
		if (hb != null) {
			return hb;
		};
		return new Bytes(b);
	}
	static get __name__() {
		return "haxe.io.Bytes"
	}
	get __class__() {
		return Bytes
	}
}

